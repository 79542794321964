import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import "../Home/home-v3-styles.css";
import showToastError from '../../components/helpers/showToastError';
import { setAxiosAuthToken } from '../../components/users/LoginHelpers/Utils';
import ShortCutModal from '../../components/Shortcuts/Shortcuts';
import WebSocketComponent from '../Websocket/WebSocketComponent';
import UserTastePickingModal from '../../components/TastePicking/UserTastePickingModal';
import SideBar from './Sidebar';
import Header from './Header';
import SpreeCineShortAboutWelcomMessages from '../../components/SpreeCineShortAboutWelcomMessage/SpreeCineShortAboutWelcomMessage';



const LazySettingsComponent = lazy(() => import("../../components/settingsv2/SettingsMainModal"))
const LazySelectedContentDetail = lazy(() => import("../../components/content/SelectedContentDetial"))
const LazyEQuestionModal = lazy(() => import("../../components/EQModal/EQuestionModal"));



function BasePage({ currentUserUsername, currentUserProfileImage, pstsc, userOnPremium }) {
    let navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [authenticatedUsername, setAuthenticatedUsername] = useState("");
    const [ips, setIps] = useState(true);

    const [contentDetailPopup, setContentDetailPopup] = useState(false);
    const [currentSelectedDetailData, setCurrentSelectedDetailData] = useState({});

    const [isSearchModalOpen, setSearchModalOpen] = useState(false);
    const dropdownRef = useRef(null)

    const [unreadNotifications, setUnreadNotificationsCount] = useState(0);
    const [eqAvailable, setEQAvailable] = useState(false);

    const [isShortcutModalOpen, setShortModalOpen] = useState(false);
    const [keyPressTimestamp, setKeyPressTimestamp] = useState(null);

    const [isSettingsOpen, setSettingsOpen] = useState(false);

    const [isEQModalOpen, setEQModalOpen] = useState(false);

    const [isGenrePickingModalOpen, setGenrePickingModalOpen] = useState(false);



    // ? Shortcuts - START
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "i" && event.ctrlKey) {
                setShortModalOpen(true);
            } else if (event.altKey && event.key === "h") {
                navigate('/help');
            } else if (event.ctrlKey && event.key === "m") {
                navigate("/upshot-create");
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, []);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "s" && event.target.tagName !== "INPUT" && event.target.tagName !== "TEXTAREA") {
                setKeyPressTimestamp(Date.now());
            }
            if (event.key === "h" && keyPressTimestamp) {
                const currentTime = Date.now();
                if (currentTime - keyPressTimestamp <= 3000) {
                    navigate("/");
                }
                setKeyPressTimestamp(null);
            } else if (event.key === "u" && keyPressTimestamp) {
                const currentTime = Date.now();
                if (currentTime - keyPressTimestamp <= 3000) {
                    navigate("/upshots");
                }
            } else if (event.key === "w" && keyPressTimestamp) {
                const currentTime = Date.now();
                if (currentTime - keyPressTimestamp <= 3000) {
                    navigate(`/watch-later/${authenticatedUsername}`);
                }
            } else if (event.key === "t" && keyPressTimestamp) {
                const currentTime = Date.now();
                if (currentTime - keyPressTimestamp <= 3000) {
                    navigate(`/trending/?page=1`)
                }
            } else if (event.key === "d" && keyPressTimestamp) {
                const currentTime = Date.now();
                if (currentTime - keyPressTimestamp <= 3000) {
                    navigate(`/tournaments`)
                }
            } else if (event.key === "s" && keyPressTimestamp) {
                const currentTime = Date.now();
                if (currentTime - keyPressTimestamp <= 500) {
                    // setSearchModalOpen(true);
                    navigate("/search")
                }
            }
        };
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        }
    }, [keyPressTimestamp]);
    // ? Shortcuts - END

    const unsetCurrentUser = () => {
        setAxiosAuthToken("");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    };

    const logout = () => {
        axios
            .post("/api/v1/token/logout/")
            .then(() => {
                unsetCurrentUser()
                navigate("login/");
                showToastError("User logged out successfull", "success")
            })
            .catch(() => {
                unsetCurrentUser()
                showToastError("Something went wrong while logging out", "error")
            });
    }

    useEffect(() => {
        if (currentUserUsername) {
            setAuthenticatedUsername(currentUserUsername);
            if (!pstsc) {
                setGenrePickingModalOpen(true);
            }
        }
    }, [])

    const getUserConstants = () => {
        axios.get(`/api/users/user/constants/`).then((res) => {
            setIps(res.data.ips);
            localStorage.removeItem("iLI")
        }).catch(() => {
        })
    }

    useEffect(() => {
        const isUserLoggedIn = localStorage.getItem("iLI");
        if (isUserLoggedIn) {
            getUserConstants();
        }
    }, [localStorage.getItem("iLI")])

    const getNotificationsCount = () => {
        if (currentUserUsername) {
            axios.get("/api/notification/notification/count/").then((res) => {
                setUnreadNotificationsCount(res.data.unread_notifications);
            }).catch(() => {
            })
        } else {
        }
    }

    useEffect(() => {
        if (currentUserUsername) {
            getNotificationsCount();
        }
    }, [currentUserUsername])



    return (
        <>
            {
                location.pathname !== "/login/" && location.pathname !== "/signup/" && location.pathname !== "/reset-password/" &&
                <div ref={dropdownRef}>

                    <UserTastePickingModal isModalOpen={isGenrePickingModalOpen} closeModal={() => setGenrePickingModalOpen(false)} />

                    <Header
                        currentUserProfileImage={currentUserProfileImage}
                        unreadNotifications={unreadNotifications}
                        setSearchModalOpen={setSearchModalOpen}
                        isSearchModalOpen={isSearchModalOpen}
                        currentUserUsername={currentUserUsername}
                        setShortModalOpen={setShortModalOpen}
                        logout={logout}
                        dropdownRef={dropdownRef}
                        setUnreadNotificationsCount={setUnreadNotificationsCount}
                        setSettingsOpen={setSettingsOpen}
                        setEQModalOpen={setEQModalOpen}
                        t={t}
                        userOnPremium={userOnPremium}
                    />

                    <Suspense fallback={null}>
                        <LazySettingsComponent
                            isSettingsOpen={isSettingsOpen}
                            closeSettingsModal={() => setSettingsOpen(false)}
                            defaultPageToOpenOnOpen={"Appearance"}
                        />
                    </Suspense>

                    <SideBar
                        currentUserUsername={currentUserUsername}
                        eqAvailable={eqAvailable}
                        t={t}
                        setEQModalOpen={setEQModalOpen}
                    />

                    <Suspense fallback={null}>
                        <LazySelectedContentDetail
                            openContentDetailPopup={contentDetailPopup}
                            onHideContentDetailPopup={() => setContentDetailPopup(false)}
                            data={currentSelectedDetailData}
                        />
                    </Suspense>

                    <ShortCutModal
                        t={t}
                        isShortcutModalOpen={isShortcutModalOpen}
                        closeShortcutModal={() => setShortModalOpen(false)}
                    />

                    <Suspense fallback={null}>
                        <LazyEQuestionModal
                            isEQModalOpen={isEQModalOpen}
                            closeEQModal={() => setEQModalOpen(false)}
                        />
                    </Suspense>

                    {/* <Suspense fallback={null}> */}
                    <SpreeCineShortAboutWelcomMessages ips={ips} />
                    {/* </Suspense> */}

                    <WebSocketComponent
                        setUnreadNotificationsCount={setUnreadNotificationsCount}
                        setEQAvailable={setEQAvailable}
                        currentUserUsername={currentUserUsername}
                    // generalNotificationState={generalNotificationState}
                    // unreadNotificationsCount={generalNotificationState.unreadNotificationsCount}
                    />
                </div>
            }
        </>
    )
}

export default BasePage;