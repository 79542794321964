import * as React from "react";
const TournamentForPremiumUsers = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="65%"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="#02D1F8"
            opacity={1}
            stroke="none"
            d=" M309.002258,336.833282  C301.644928,336.881256 294.287567,336.929230 286.466003,336.447205  C286.001160,317.318665 286.039307,298.719940 285.953003,280.121826  C285.941681,277.683899 285.292419,275.248901 285.087463,272.506592  C288.026855,269.528381 289.983398,266.521576 288.552490,262.466400  C287.147125,258.483704 283.994751,256.979126 279.998596,256.389862  C280.001007,245.675995 280.002869,235.421310 280.446472,225.095703  C283.569702,224.975281 286.251251,224.925812 289.402222,224.921921  C294.934479,224.931473 299.997253,224.895447 305.060028,224.859436  C306.114441,224.911987 307.168823,224.964539 308.612183,225.549896  C309.001465,262.999542 309.001862,299.916412 309.002258,336.833282  z"
        />
        <path
            fill="#02B8F8"
            opacity={1}
            stroke="none"
            d=" M201.889709,225.954666  C203.957413,225.631424 206.025131,225.308167 208.976166,224.979218  C214.929962,224.934860 220.000427,224.896210 225.100281,224.924500  C225.129700,224.991425 224.997009,225.051514 224.996338,225.516602  C224.997101,239.141632 224.919525,252.302368 225.051422,265.460999  C225.087753,269.085663 225.749252,272.704071 226.050690,276.695801  C223.190399,282.493286 226.568100,285.991425 229.522125,289.822845  C228.781479,292.205811 228.067825,294.276184 228.052216,296.351807  C227.950760,309.845398 228.000992,323.340149 228.001572,336.834503  C220.311035,336.881714 212.620483,336.928955 204.465668,336.446594  C204.000870,301.885529 204.046768,267.853851 203.908722,233.822876  C203.898087,231.197449 202.593079,228.577286 201.889709,225.954666  z"
        />
        <path
            fill="#02D8F8"
            opacity={1}
            stroke="none"
            d=" M309.390503,336.904846  C309.001862,299.916412 309.001465,262.999542 309.001709,225.624603  C319.921021,226.148590 328.991882,235.889465 328.998566,246.690186  C329.012146,268.634125 328.437653,290.598602 329.233856,312.513611  C329.650421,323.978973 320.610321,335.513672 309.390503,336.904846  z"
        />
        <path
            fill="#02B2F8"
            opacity={1}
            stroke="none"
            d=" M201.513977,225.974045  C202.593079,228.577286 203.898087,231.197449 203.908722,233.822876  C204.046768,267.853851 204.000870,301.885529 204.002045,336.375092  C193.915665,335.931396 184.485458,323.853394 184.792313,313.752777  C185.468628,291.489441 184.996628,269.191406 185.005096,246.907516  C185.008728,237.339752 191.588699,228.828644 201.513977,225.974045  z"
        />
        <path
            fill="#02CAF8"
            opacity={1}
            stroke="none"
            d=" M284.938660,272.812561  C285.292419,275.248901 285.941681,277.683899 285.953003,280.121826  C286.039307,298.719940 286.001160,317.318665 286.002502,336.375244  C277.312500,336.880859 268.621796,336.928375 259.466217,336.447144  C259.000122,326.524506 259.135651,317.127167 258.899384,307.739227  C258.841980,305.459015 257.560547,303.209595 256.984070,300.638428  C266.397003,291.158112 275.667816,281.985321 284.938660,272.812561  z"
        />
        <path
            fill="#02BFF8"
            opacity={1}
            stroke="none"
            d=" M256.841949,300.946014  C257.560547,303.209595 258.841980,305.459015 258.899384,307.739227  C259.135651,317.127167 259.000122,326.524506 259.002502,336.376526  C248.978836,336.882172 238.953995,336.929688 228.465363,336.905853  C228.000992,323.340149 227.950760,309.845398 228.052216,296.351807  C228.067825,294.276184 228.781479,292.205811 229.783173,289.994934  C233.779846,293.216736 237.172180,296.570038 240.550095,299.937866  C247.173782,306.541687 249.739365,306.704224 256.841949,300.946014  z"
        />
        <path
            fill="#028CDE"
            opacity={1}
            stroke="none"
            d=" M442.029327,208.875153  C445.670776,208.581802 447.818756,210.490784 449.242065,213.626358  C451.448608,218.487518 453.738617,223.310791 455.932983,228.934464  C455.582001,232.175171 455.046265,234.630798 455.038910,237.088028  C454.975464,258.336365 455.007111,279.584991 455.010376,300.833557  C450.759094,298.469910 446.507782,296.106262 442.115845,292.951904  C441.993225,264.399170 442.011261,236.637161 442.029327,208.875153  z"
        />
        <path
            fill="#0259B7"
            opacity={1}
            stroke="none"
            d=" M305.041138,224.401230  C299.997253,224.895447 294.934479,224.931473 289.430267,224.464340  C288.994843,220.653839 289.109070,217.342545 288.987854,214.039871  C288.506104,200.916840 285.201538,189.160690 273.018188,181.394470  C273.351044,175.273697 273.686523,169.579742 274.021973,163.885788  C290.726959,168.571411 306.175690,188.878479 305.064270,206.957687  C304.717468,212.599014 305.018036,218.280121 305.041138,224.401230  z"
        />
        <path
            fill="#0180D6"
            opacity={1}
            stroke="none"
            d=" M441.839600,208.599030  C442.011261,236.637161 441.993225,264.399170 441.964722,292.637085  C438.577332,294.735779 435.200409,296.358521 431.210785,297.682068  C430.398743,295.415222 430.030457,293.447937 430.026184,291.479889  C429.983124,271.649292 430.056244,251.818207 429.917572,231.988510  C429.902679,229.859344 428.752808,227.738129 428.129395,225.613220  C429.903900,221.435806 431.108643,216.858917 433.657074,213.224121  C435.303040,210.876434 438.917542,209.908920 441.839600,208.599030  z"
        />
        <path
            fill="#016FCA"
            opacity={1}
            stroke="none"
            d=" M113.017487,173.894073  C110.801315,174.922592 108.678734,176.454636 106.351791,176.887100  C98.754349,178.299118 94.373573,172.427277 96.144196,163.731354  C97.440994,157.362473 98.618393,150.966125 99.647156,144.549652  C99.783813,143.697342 98.728851,142.653961 98.338455,141.104294  C98.921684,139.471512 99.783310,138.439987 99.800606,137.394485  C99.934341,129.312592 99.980698,121.226631 99.834854,113.145897  C99.815445,112.070908 98.563538,111.018166 97.882408,109.955116  C101.930313,109.297882 105.978218,108.640648 110.824478,108.124161  C112.081352,110.258751 112.927795,112.250557 112.938370,114.246780  C113.043709,134.128784 113.011131,154.011536 113.017487,173.894073  z"
        />
        <path
            fill="#0290E2"
            opacity={1}
            stroke="none"
            d=" M91.638824,302.887726  C87.506004,300.815338 83.373177,298.742950 79.319962,296.024841  C79.599815,293.417786 79.970619,291.456726 79.974213,289.494965  C80.015236,267.064209 79.968391,244.633240 80.046173,222.202667  C80.054626,219.763672 80.696533,217.326889 81.044609,214.889069  C84.487793,221.862473 87.930977,228.835861 91.689438,236.446075  C92.003143,239.409943 92.012253,241.737061 91.998314,244.064056  C91.880882,263.671967 91.759224,283.279846 91.638824,302.887726  z"
        />
        <path
            fill="#017DD4"
            opacity={1}
            stroke="none"
            d=" M113.362862,173.854675  C113.011131,154.011536 113.043709,134.128784 112.938370,114.246780  C112.927795,112.250557 112.081352,110.258751 111.253174,108.113495  C114.588730,99.991188 118.293930,92.020279 122.351273,84.208710  C123.135780,87.122986 123.932983,89.876518 123.943565,92.633072  C124.039833,117.699928 123.998917,142.767319 123.998116,167.834549  C120.568161,169.828125 117.138199,171.821701 113.362862,173.854675  z"
        />
        <path
            fill="#0287DB"
            opacity={1}
            stroke="none"
            d=" M81.018883,214.524353  C80.696533,217.326889 80.054626,219.763672 80.046173,222.202667  C79.968391,244.633240 80.015236,267.064209 79.974213,289.494965  C79.970619,291.456726 79.599815,293.417786 79.147125,295.713989  C76.484863,295.306824 74.075066,294.564819 71.316887,293.542358  C70.645981,290.139923 70.053055,287.018738 70.043716,283.895874  C69.969246,258.986725 70.005363,234.077271 70.007591,209.167908  C74.892197,207.525711 78.561974,209.193222 81.018883,214.524353  z"
        />
        <path
            fill="#029BEA"
            opacity={1}
            stroke="none"
            d=" M91.836044,303.188293  C91.759224,283.279846 91.880882,263.671967 91.998314,244.064056  C92.012253,241.737061 92.003143,239.409943 92.006851,236.624939  C95.870956,236.508438 99.732941,236.849869 103.807617,238.014618  C104.014130,246.253769 103.881706,253.673294 104.098198,261.082642  C104.147049,262.754456 105.411346,264.390717 106.116150,266.043365  C101.789139,270.679993 102.593208,275.896851 103.905457,281.466766  C105.051903,286.332977 105.208542,291.423676 106.053467,296.372345  C106.727280,300.318787 104.634979,303.435852 101.730858,304.357697  C98.877701,305.263306 95.295525,303.872162 91.836044,303.188293  z"
        />
        <path
            fill="#0297E7"
            opacity={1}
            stroke="none"
            d=" M455.338989,300.992249  C455.007111,279.584991 454.975464,258.336365 455.038910,237.088028  C455.046265,234.630798 455.582001,232.175171 455.955933,229.301208  C456.348145,229.228989 456.850983,229.532196 456.936646,229.926132  C458.039062,234.995026 461.897919,236.247421 466.649048,237.429245  C467.001129,260.331207 467.001831,282.585449 467.002502,304.839661  C462.375275,306.218719 459.235901,303.025787 455.338989,300.992249  z"
        />
        <path
            fill="#016FCA"
            opacity={1}
            stroke="none"
            d=" M367.108521,430.054749  C364.011047,434.005768 360.059937,433.791229 356.089539,431.955139  C352.337860,430.220184 350.946991,425.363892 352.067871,419.715759  C353.330963,413.351044 354.594513,406.984131 355.659485,400.585175  C355.800385,399.738464 354.772125,398.697174 354.204285,397.011658  C354.415131,388.554596 354.813446,380.833557 354.935333,373.108124  C354.972839,370.729462 354.255585,368.338837 353.883942,365.953705  C357.642151,365.310638 361.400330,364.667572 365.580902,364.552002  C366.001801,384.668915 365.967255,404.258484 366.047516,423.847595  C366.056000,425.918060 366.738647,427.985779 367.108521,430.054749  z"
        />
        <path
            fill="#016FCA"
            opacity={1}
            stroke="none"
            d=" M239.108459,486.054779  C236.010956,490.005829 232.059830,489.791199 228.089478,487.955048  C224.337784,486.220093 222.946991,481.363739 224.067886,475.715698  C225.331009,469.351013 226.594498,462.984070 227.659470,456.585114  C227.800385,455.738403 226.772079,454.697113 226.204269,453.011627  C226.415298,444.554596 226.813797,436.833527 226.935760,429.108124  C226.973312,426.729462 226.255722,424.338837 225.883911,421.953705  C229.642181,421.310638 233.400467,420.667603 237.581039,420.552002  C238.001862,440.668915 237.967331,460.258484 238.047516,479.847595  C238.056000,481.918091 238.738602,483.985779 239.108459,486.054779  z"
        />
        <path
            fill="#0B4392"
            opacity={1}
            stroke="none"
            d=" M225.070877,224.857574  C220.000427,224.896210 214.929962,224.934860 209.390686,224.923508  C209.293182,215.483231 208.776794,205.949783 210.262299,196.739197  C212.086090,185.431000 219.037659,176.755005 228.628601,170.428619  C228.986832,175.829407 228.814972,180.833801 229.099121,185.812180  C229.200455,187.587280 230.407013,189.299286 230.951660,191.359985  C224.433746,201.970978 224.929962,213.444916 225.070877,224.857574  z"
        />
        <path
            fill="#029BEA"
            opacity={1}
            stroke="none"
            d=" M162.110504,138.048386  C157.757294,142.699677 158.598282,147.946960 159.913483,153.516815  C161.028305,158.238083 161.120087,163.192322 162.062775,167.964813  C163.505249,175.267471 155.211838,179.609085 148.334259,175.068695  C148.600784,172.416595 148.970062,170.455124 148.974457,168.492844  C149.019348,148.384583 149.005066,128.276199 149.007294,108.167839  C152.763870,108.706848 156.520462,109.245850 160.640442,110.433197  C161.002350,116.528679 160.866989,121.980255 161.047073,127.421394  C161.164581,130.971573 161.740128,134.506577 162.110504,138.048386  z"
        />
        <path
            fill="#029BEA"
            opacity={1}
            stroke="none"
            d=" M289.832703,82.990524  C283.708496,88.502861 288.164520,94.857506 288.504456,100.842751  C288.715302,104.554977 289.308929,108.271835 290.049042,111.922073  C291.536133,119.256302 283.331116,123.554947 276.300781,119.063995  C276.523682,116.196457 276.962830,114.070831 276.968689,111.944008  C277.023865,92.018738 277.004852,72.093262 277.007263,52.167847  C280.433655,52.709644 283.860077,53.251442 287.645569,54.438507  C288.003510,62.669670 287.892090,70.258362 288.084625,77.839340  C288.128601,79.570084 289.222198,81.274170 289.832703,82.990524  z"
        />
        <path
            fill="#029BEA"
            opacity={1}
            stroke="none"
            d=" M417.832733,138.990509  C411.710449,144.500122 416.169128,150.853790 416.503784,156.837189  C416.692810,160.217392 417.460724,163.568283 418.016815,166.924271  C419.129578,173.639526 414.752625,178.145615 407.877991,177.004837  C407.066467,176.870193 406.232300,176.872116 405.190674,175.988770  C404.984253,152.833817 404.995758,130.500824 405.007263,108.167831  C408.537567,108.509590 412.067841,108.851341 415.809235,110.015511  C416.014191,118.655121 415.908722,126.474564 416.085114,134.287643  C416.120758,135.867615 417.222473,137.423523 417.832733,138.990509  z"
        />
        <path
            fill="#016FCA"
            opacity={1}
            stroke="none"
            d=" M53.009949,303.896912  C49.617371,303.692810 45.691521,304.497803 42.955227,303.051819  C38.682339,300.793762 39.728191,295.772980 40.150997,291.793365  C40.732300,286.321808 41.366634,280.689148 43.209457,275.568634  C44.821999,271.088013 41.317287,269.553650 40.318615,266.235901  C41.083019,264.071808 41.909378,262.416290 41.936790,260.747681  C42.061909,253.131042 41.962749,245.510696 41.944191,237.891693  C45.354702,237.270401 48.765217,236.649109 52.589691,236.555206  C53.005753,259.354034 53.007851,281.625458 53.009949,303.896912  z"
        />
        <path
            fill="#029BEA"
            opacity={1}
            stroke="none"
            d=" M157.007294,356.167847  C160.537582,356.509613 164.067856,356.851379 167.809235,358.015533  C168.013748,365.665283 167.899185,372.495087 168.051346,379.318909  C168.116348,382.234283 168.737625,385.137207 169.106140,388.045807  C164.997330,390.942596 166.892319,394.966858 167.323517,398.563873  C168.018219,404.359161 169.499374,410.080414 169.904510,415.884857  C170.367493,422.517639 167.520035,425.041595 160.882126,424.989502  C159.723923,424.980408 158.566483,424.872833 157.190750,423.988770  C156.984299,400.833832 156.995804,378.500824 157.007294,356.167847  z"
        />
        <path
            fill="#016FCA"
            opacity={1}
            stroke="none"
            d=" M366.103210,175.051743  C362.607849,178.103653 358.729370,177.746231 355.234497,175.521881  C351.069977,172.871353 351.642792,168.294006 352.157257,164.255127  C352.976135,157.826721 354.466888,151.484146 355.295044,145.056519  C355.466461,143.726044 353.982910,142.182312 353.192871,140.009216  C353.397156,129.484940 353.670624,119.688347 353.944122,109.891754  C357.354706,109.270447 360.765289,108.649132 364.589783,108.555222  C365.002502,129.002579 364.968903,148.922668 365.048370,168.842316  C365.056610,170.913498 365.735504,172.981995 366.103210,175.051743  z"
        />
        <path
            fill="#016FCA"
            opacity={1}
            stroke="none"
            d=" M118.081963,423.177399  C114.528412,426.018829 110.601379,425.821899 107.192062,423.479431  C102.819145,420.474915 103.667694,415.617828 104.339134,411.245087  C105.322754,404.839172 106.745644,398.501404 107.800072,392.104736  C107.912865,391.420441 106.819580,390.537323 106.155487,389.001770  C105.996628,378.137146 105.968079,368.017181 105.939529,357.897217  C109.351570,357.274078 112.763603,356.650940 116.589653,356.555176  C117.002480,376.680145 116.963722,396.277893 117.047920,415.875092  C117.058380,418.310638 117.721344,420.743378 118.081963,423.177399  z"
        />
        <path
            fill="#016FCA"
            opacity={1}
            stroke="none"
            d=" M420.038055,304.112732  C416.868744,303.710785 412.808868,304.342468 410.791138,302.603973  C408.929901,301.000366 408.502808,296.781860 408.777679,293.839752  C409.424683,286.916016 411.372009,280.091644 411.714813,273.173645  C411.834900,270.750336 408.800873,268.170715 407.306274,265.018768  C407.607697,255.641220 407.809998,246.907364 408.012268,238.173523  C411.737823,237.459778 415.463348,236.746033 419.596710,236.558746  C420.015686,259.427704 420.026886,281.770233 420.038055,304.112732  z"
        />
        <path
            fill="#016FCA"
            opacity={1}
            stroke="none"
            d=" M236.037338,120.109619  C232.600357,122.021996 229.334122,121.322937 226.568817,118.949409  C222.904510,115.804230 223.718323,111.397072 224.294037,107.388214  C225.192337,101.133186 226.913666,94.979126 227.509476,88.708481  C227.686081,86.849831 225.252823,84.743195 224.209045,82.063232  C224.605652,72.307716 224.808701,63.239151 225.011749,54.170586  C228.403839,53.458298 231.795929,52.746010 235.596313,52.559517  C236.015503,75.426750 236.026413,97.768181 236.037338,120.109619  z"
        />
        <path
            fill="#0178D0"
            opacity={1}
            stroke="none"
            d=" M420.398560,304.052612  C420.026886,281.770233 420.015686,259.427704 420.005920,236.627594  C421.686432,234.698135 423.644836,233.437332 424.963440,231.692932  C426.193542,230.065598 426.743713,227.924301 427.861298,225.811249  C428.752808,227.738129 429.902679,229.859344 429.917572,231.988510  C430.056244,251.818207 429.983124,271.649292 430.026184,291.479889  C430.030457,293.447937 430.398743,295.415222 430.849182,297.719177  C427.653168,300.034515 424.206116,302.013489 420.398560,304.052612  z"
        />
        <path
            fill="#0185DA"
            opacity={1}
            stroke="none"
            d=" M258.874146,110.060371  C255.894577,107.421883 254.892044,111.729950 252.346481,111.386955  C252.002655,85.629883 252.049408,60.306217 251.908951,34.983597  C251.897797,32.973984 250.589294,30.971571 249.883713,28.965811  C251.921280,24.920050 255.342957,23.764626 260.664215,25.962231  C260.399994,28.584139 260.030426,30.547119 260.027100,32.510715  C259.987091,56.290195 260.028961,80.069855 259.952148,103.849152  C259.945435,105.920708 259.249786,107.990021 258.874146,110.060371  z"
        />
        <path
            fill="#0185DA"
            opacity={1}
            stroke="none"
            d=" M124.335297,167.799835  C123.998917,142.767319 124.039833,117.699928 123.943565,92.633072  C123.932983,89.876518 123.135780,87.122986 122.514816,83.842896  C125.404655,80.636154 128.801865,79.601028 132.664261,81.962280  C132.399902,84.584160 132.030121,86.547127 132.026810,88.510712  C131.986755,112.290192 132.028595,136.069855 131.951736,159.849152  C131.945053,161.920670 131.249557,163.989975 130.874023,166.060303  C127.913170,163.377716 126.905975,167.801849 124.335297,167.799835  z"
        />
        <path
            fill="#0185DA"
            opacity={1}
            stroke="none"
            d=" M388.961670,167.107758  C386.565552,166.241745 384.190216,163.994141 381.377625,166.587189  C381.012756,140.677979 381.059021,115.180397 380.907837,89.683975  C380.894501,87.438309 379.589752,85.200294 378.884827,82.958725  C381.798950,80.401367 385.051880,79.785896 388.844666,82.044754  C389.001495,110.927628 388.981598,139.017700 388.961670,167.107758  z"
        />
        <path
            fill="#0185DA"
            opacity={1}
            stroke="none"
            d=" M140.961624,415.107758  C139.215500,414.663757 137.469376,414.219757 135.089615,413.686615  C133.970490,411.410919 133.072693,409.226196 133.062988,407.037537  C132.960419,383.915405 133.057327,360.792236 132.907043,337.670624  C132.892471,335.429047 131.589630,333.195831 130.884811,330.958740  C133.798981,328.401398 137.051895,327.785767 140.844727,330.044800  C141.001511,358.927673 140.981567,387.017700 140.961624,415.107758  z"
        />
        <path
            fill="#029BEA"
            opacity={1}
            stroke="none"
            d=" M407.090942,364.163330  C410.147797,364.699585 413.204651,365.235840 416.720032,366.367188  C417.465820,376.014587 417.753082,385.066864 418.040344,394.119171  C413.637329,398.762024 414.646606,404.035767 415.933136,409.540558  C416.961243,413.939758 417.278564,418.500824 418.025848,422.970917  C419.148041,429.683655 414.441254,434.353027 407.427582,432.446045  C407.032043,409.334869 407.061493,386.749115 407.090942,364.163330  z"
        />
        <path
            fill="#0287DB"
            opacity={1}
            stroke="none"
            d=" M390.005951,423.823303  C388.267517,422.889465 386.529083,421.955597 384.195007,420.702545  C383.399567,418.420074 383.028717,416.457001 383.025909,414.493439  C382.989166,388.717682 383.004395,362.941803 383.006592,337.165985  C387.021393,335.192444 389.614929,337.411896 391.805359,340.918304  C391.400635,343.584473 391.029572,345.549713 391.026062,347.515625  C390.985229,370.326355 391.032043,393.137299 390.956177,415.947876  C390.947449,418.574036 390.337280,421.198212 390.005951,423.823303  z"
        />
        <path
            fill="#0287DB"
            opacity={1}
            stroke="none"
            d=" M255.006577,393.165985  C259.021362,391.192444 261.614899,393.411926 263.805359,396.918304  C263.400665,399.584473 263.029572,401.549713 263.026062,403.515625  C262.985229,426.326355 263.032074,449.137329 262.956207,471.947876  C262.947479,474.574036 262.337250,477.198212 262.005951,479.823303  C260.267548,478.889435 258.529114,477.955566 256.195007,476.702515  C255.399582,474.420074 255.028717,472.457001 255.025925,470.493439  C254.989182,444.717682 255.004379,418.941803 255.006577,393.165985  z"
        />
        <path
            fill="#0180D6"
            opacity={1}
            stroke="none"
            d=" M382.642029,337.103210  C383.004395,362.941803 382.989166,388.717682 383.025909,414.493439  C383.028717,416.457001 383.399567,418.420074 383.850922,420.719330  C381.321777,422.702179 378.541077,424.349091 375.389404,425.582642  C375.011902,402.186432 375.036804,379.203430 374.952850,356.220856  C374.943909,353.776917 374.297943,351.335236 373.947540,348.892517  C376.145782,344.535309 376.472168,338.862518 382.642029,337.103210  z"
        />
        <path
            fill="#0180D6"
            opacity={1}
            stroke="none"
            d=" M254.642029,393.103210  C255.004379,418.941803 254.989182,444.717682 255.025925,470.493439  C255.028717,472.457001 255.399582,474.420074 255.850922,476.719299  C253.321777,478.702179 250.541046,480.349060 247.389389,481.582611  C247.012070,458.186462 247.037109,435.203522 246.953262,412.221008  C246.944351,409.777069 246.297974,407.335449 245.947311,404.892761  C248.145721,400.535522 248.472153,394.862610 254.642029,393.103210  z"
        />
        <path
            fill="#0180D6"
            opacity={1}
            stroke="none"
            d=" M69.659592,209.205231  C70.005363,234.077271 69.969246,258.986725 70.043716,283.895874  C70.053055,287.018738 70.645981,290.139923 71.001404,293.689850  C68.627518,295.406189 66.220741,296.694519 63.205925,297.682678  C62.398701,295.423248 62.032490,293.464478 62.026714,291.504608  C61.979778,275.573944 62.102013,259.642120 61.953167,243.712692  C61.888336,236.774551 61.309380,229.841232 60.963921,222.905716  C63.746475,218.351334 66.529030,213.796936 69.659592,209.205231  z"
        />
        <path
            fill="#028CDE"
            opacity={1}
            stroke="none"
            d=" M259.038055,110.377411  C259.249786,107.990021 259.945435,105.920708 259.952148,103.849152  C260.028961,80.069855 259.987091,56.290195 260.027100,32.510715  C260.030426,30.547119 260.399994,28.584139 260.851013,26.285439  C266.108368,28.925856 266.941650,34.403053 268.862061,39.908020  C268.476593,42.963577 268.039093,45.250412 268.032196,47.538548  C267.964386,70.071968 267.968109,92.605606 267.951538,115.139183  C265.035004,113.657608 262.118469,112.176033 259.038055,110.377411  z"
        />
        <path
            fill="#028CDE"
            opacity={1}
            stroke="none"
            d=" M131.037964,166.377350  C131.249557,163.989975 131.945053,161.920670 131.951736,159.849152  C132.028595,136.069855 131.986755,112.290192 132.026810,88.510712  C132.030121,86.547127 132.399902,84.584160 132.851059,82.285477  C138.108398,84.925911 138.941635,90.403107 140.862061,95.908020  C140.476395,98.963570 140.038635,101.250412 140.031754,103.538551  C139.964020,126.071991 139.967865,148.605652 139.951401,171.139236  C137.034897,169.657623 134.118393,168.176010 131.037964,166.377350  z"
        />
        <path
            fill="#028CDE"
            opacity={1}
            stroke="none"
            d=" M141.128571,415.407288  C140.981567,387.017700 141.001511,358.927673 141.030319,330.361633  C142.370865,331.322174 144.003082,332.582611 144.970108,334.232819  C146.839401,337.422791 148.333618,340.832550 149.931366,344.930542  C149.586319,348.166290 149.047455,350.621826 149.038925,353.079224  C148.969727,373.003693 149.032776,392.928558 148.950928,412.852905  C148.942413,414.922485 148.253586,416.989258 147.880417,419.057343  C145.685440,417.940491 143.490463,416.823639 141.128571,415.407288  z"
        />
        <path
            fill="#028CDE"
            opacity={1}
            stroke="none"
            d=" M389.128601,167.407288  C388.981598,139.017700 389.001495,110.927628 389.030273,82.361572  C390.370850,83.322128 392.003052,84.582588 392.970062,86.232811  C394.839386,89.422798 396.333649,92.832542 397.931396,96.930542  C397.586334,100.166290 397.047424,102.621841 397.038910,105.079239  C396.969757,125.003708 397.032776,144.928665 396.950928,164.853043  C396.942413,166.922638 396.253571,168.989426 395.880371,171.057526  C393.685425,169.940628 391.490509,168.823730 389.128601,167.407288  z"
        />
        <path
            fill="#017DD4"
            opacity={1}
            stroke="none"
            d=" M378.577454,83.129700  C379.589752,85.200294 380.894501,87.438309 380.907837,89.683975  C381.059021,115.180397 381.012756,140.677979 381.027161,166.652435  C378.718994,168.392441 376.403015,169.655273 373.638672,170.386200  C373.126770,148.130157 373.108368,126.405602 372.920929,104.682510  C372.904541,102.782532 371.777893,100.892136 371.166931,98.997284  C373.534637,93.765076 375.902344,88.532867 378.577454,83.129700  z"
        />
        <path
            fill="#017DD4"
            opacity={1}
            stroke="none"
            d=" M130.577469,331.129639  C131.589630,333.195831 132.892471,335.429047 132.907043,337.670624  C133.057327,360.792236 132.960419,383.915405 133.062988,407.037537  C133.072693,409.226196 133.970490,411.410919 134.781631,413.826477  C132.303696,415.616455 129.500107,417.177429 126.261551,418.388733  C125.551056,415.575195 125.044777,413.112030 125.036552,410.647247  C124.970947,391.004364 125.033928,371.361023 124.953423,351.718262  C124.944084,349.441650 124.299141,347.167664 123.948883,344.892456  C126.055962,340.361816 128.163040,335.831207 130.577469,331.129639  z"
        />
        <path
            fill="#029BEA"
            opacity={1}
            stroke="none"
            d=" M290.110443,450.048401  C285.767731,454.694733 286.548737,459.939667 287.921906,465.498291  C289.005768,469.885773 289.281708,474.467896 290.025146,478.945557  C291.148102,485.708954 286.430481,490.351166 279.427582,488.446045  C279.004059,465.334900 279.005554,442.749176 279.007050,420.163422  C282.076233,420.773804 285.145447,421.384186 288.609192,422.538055  C289.002197,428.528687 288.866699,433.980255 289.046783,439.421387  C289.164246,442.971588 289.739960,446.506592 290.110443,450.048401  z"
        />
        <path
            fill="#0178D0"
            opacity={1}
            stroke="none"
            d=" M373.633148,349.070923  C374.297943,351.335236 374.943909,353.776917 374.952850,356.220856  C375.036804,379.203430 375.011902,402.186432 375.026459,425.645874  C372.629547,427.409637 370.224670,428.696808 367.464172,430.019348  C366.738647,427.985779 366.056000,425.918060 366.047516,423.847595  C365.967255,404.258484 366.001801,384.668915 366.005371,364.621399  C368.444580,359.191986 370.881683,354.220642 373.633148,349.070923  z"
        />
        <path
            fill="#0178D0"
            opacity={1}
            stroke="none"
            d=" M245.632980,405.071106  C246.297974,407.335449 246.944351,409.777069 246.953262,412.221008  C247.037109,435.203522 247.012070,458.186462 247.026459,481.645905  C244.629562,483.409698 242.224655,484.696869 239.464096,486.019409  C238.738602,483.985779 238.056000,481.918091 238.047516,479.847595  C237.967331,460.258484 238.001862,440.668915 238.005554,420.621399  C240.444717,415.192047 242.881683,410.220734 245.632980,405.071106  z"
        />
        <path
            fill="#017DD4"
            opacity={1}
            stroke="none"
            d=" M249.576477,29.136059  C250.589294,30.971571 251.897797,32.973984 251.908951,34.983597  C252.049408,60.306217 252.002655,85.629883 252.007812,111.413864  C249.571960,113.162071 247.132065,114.449699 244.347443,115.326172  C244.001984,93.675667 244.035034,72.436188 243.954391,51.197147  C243.945145,48.762169 243.300705,46.329605 242.950790,43.895920  C245.056931,39.032715 247.163086,34.169514 249.576477,29.136059  z"
        />
        <path
            fill="#028EE0"
            opacity={1}
            stroke="none"
            d=" M262.325073,479.981323  C262.337250,477.198212 262.947479,474.574036 262.956207,471.947876  C263.032074,449.137329 262.985229,426.326355 263.026062,403.515625  C263.029572,401.549713 263.400665,399.584473 263.853455,397.283875  C266.738220,402.365173 269.370544,407.781525 271.802734,413.907806  C271.401398,416.579865 271.028961,418.541687 271.025299,420.504181  C270.985321,441.948486 271.002991,463.392883 271.004974,484.837280  C268.218048,483.271301 265.431152,481.705292 262.325073,479.981323  z"
        />
        <path
            fill="#028EE0"
            opacity={1}
            stroke="none"
            d=" M390.325073,423.981323  C390.337280,421.198212 390.947449,418.574036 390.956177,415.947876  C391.032043,393.137299 390.985229,370.326355 391.026062,347.515625  C391.029572,345.549713 391.400635,343.584473 391.853455,341.283875  C394.738220,346.365173 397.370544,351.781525 399.802734,357.907806  C399.401398,360.579865 399.028931,362.541687 399.025269,364.504181  C398.985321,385.948486 399.002960,407.392883 399.004974,428.837280  C396.218048,427.271271 393.431152,425.705292 390.325073,423.981323  z"
        />
        <path
            fill="#0293E4"
            opacity={1}
            stroke="none"
            d=" M140.116669,171.446976  C139.967865,148.605652 139.964020,126.071991 140.031754,103.538551  C140.038635,101.250412 140.476395,98.963570 140.880722,96.285759  C142.485306,98.531471 143.869476,101.199013 145.382462,103.791374  C146.238998,105.258957 147.306976,106.603119 148.643509,108.085358  C149.005066,128.276199 149.019348,148.384583 148.974457,168.492844  C148.970062,170.455124 148.600784,172.416595 148.148590,174.713257  C145.358170,173.950302 142.820053,172.852509 140.116669,171.446976  z"
        />
        <path
            fill="#0293E4"
            opacity={1}
            stroke="none"
            d=" M268.117493,115.447464  C267.968109,92.605606 267.964386,70.071968 268.032196,47.538548  C268.039093,45.250412 268.476593,42.963577 268.880737,40.285767  C270.485291,42.531471 271.869476,45.199009 273.382446,47.791374  C274.238983,49.258957 275.306976,50.603123 276.643494,52.085358  C277.004852,72.093262 277.023865,92.018738 276.968689,111.944008  C276.962830,114.070831 276.523682,116.196457 276.117859,118.712418  C273.394623,117.986717 270.839020,116.871231 268.117493,115.447464  z"
        />
        <path
            fill="#0178D0"
            opacity={1}
            stroke="none"
            d=" M60.651363,223.092865  C61.309380,229.841232 61.888336,236.774551 61.953167,243.712692  C62.102013,259.642120 61.979778,275.573944 62.026714,291.504608  C62.032490,293.464478 62.398701,295.423248 62.848839,297.718903  C59.967312,299.979767 56.834835,301.904297 53.356155,303.862854  C53.007851,281.625458 53.005753,259.354034 53.005287,236.624802  C54.990761,233.061295 57.028591,229.988113 58.928616,226.831955  C59.576809,225.755203 59.878685,224.469971 60.651363,223.092865  z"
        />
        <path
            fill="#0293E4"
            opacity={1}
            stroke="none"
            d=" M148.045990,419.373352  C148.253586,416.989258 148.942413,414.922485 148.950928,412.852905  C149.032776,392.928558 148.969727,373.003693 149.038925,353.079224  C149.047455,350.621826 149.586319,348.166290 149.966156,345.292480  C152.128616,348.584351 154.204346,352.293610 156.643692,356.085358  C156.995804,378.500824 156.984299,400.833832 156.960831,423.642883  C154.036423,422.642395 151.123993,421.165894 148.045990,419.373352  z"
        />
        <path
            fill="#0293E4"
            opacity={1}
            stroke="none"
            d=" M396.045990,171.373505  C396.253571,168.989426 396.942413,166.922638 396.950928,164.853043  C397.032776,144.928665 396.969757,125.003708 397.038910,105.079239  C397.047424,102.621841 397.586334,100.166290 397.966187,97.292480  C400.128632,100.584351 402.204346,104.293633 404.643616,108.085373  C404.995758,130.500824 404.984253,152.833817 404.960815,175.642853  C402.036438,174.642426 399.124023,173.165955 396.045990,171.373505  z"
        />
        <path
            fill="#0178D0"
            opacity={1}
            stroke="none"
            d=" M123.632469,345.080444  C124.299141,347.167664 124.944084,349.441650 124.953423,351.718262  C125.033928,371.361023 124.970947,391.004364 125.036552,410.647247  C125.044777,413.112030 125.551056,415.575195 125.914726,418.437012  C123.563751,420.162506 121.124626,421.490082 118.383728,422.997498  C117.721344,420.743378 117.058380,418.310638 117.047920,415.875092  C116.963722,396.277893 117.002480,376.680145 117.005325,356.624756  C119.110008,352.534088 121.213036,348.901245 123.632469,345.080444  z"
        />
        <path
            fill="#0178D0"
            opacity={1}
            stroke="none"
            d=" M371.004822,99.319199  C371.777893,100.892136 372.904541,102.782532 372.920929,104.682510  C373.108368,126.405602 373.126770,148.130157 373.252594,170.381577  C371.144257,172.269775 368.973602,173.630722 366.453064,175.021698  C365.735504,172.981995 365.056610,170.913498 365.048370,168.842316  C364.968903,148.922668 365.002502,129.002579 365.005371,108.624802  C366.952240,105.325027 368.897461,102.483070 371.004822,99.319199  z"
        />
        <path
            fill="#0178D0"
            opacity={1}
            stroke="none"
            d=" M242.639374,44.071426  C243.300705,46.329605 243.945145,48.762169 243.954391,51.197147  C244.035034,72.436188 244.001984,93.675667 244.003540,115.372711  C241.588013,117.228485 239.171631,118.626579 236.396301,120.067139  C236.026413,97.768181 236.015503,75.426750 236.006073,52.627716  C240.937653,51.781208 240.206070,46.875957 242.639374,44.071426  z"
        />
        <path
            fill="#0293E4"
            opacity={1}
            stroke="none"
            d=" M271.326447,484.992249  C271.002991,463.392883 270.985321,441.948486 271.025299,420.504181  C271.028961,418.541687 271.401398,416.579865 271.855774,414.283051  C274.567017,415.550812 273.467865,420.742462 278.619812,420.090515  C279.005554,442.749176 279.004059,465.334900 279.004761,488.378876  C276.553925,487.607117 274.100922,486.377167 271.326447,484.992249  z"
        />
        <path
            fill="#0293E4"
            opacity={1}
            stroke="none"
            d=" M399.326447,428.992249  C399.002960,407.392883 398.985321,385.948486 399.025269,364.504181  C399.028931,362.541687 399.401398,360.579865 399.855743,358.283051  C402.528748,359.636932 401.546356,364.854218 406.746582,364.089783  C407.061493,386.749115 407.032043,409.334869 407.004761,432.378876  C404.553925,431.607117 402.100922,430.377167 399.326447,428.992249  z"
        />
        <path
            fill="#029BEA"
            opacity={1}
            stroke="none"
            d=" M467.375427,304.908234  C467.001831,282.585449 467.001129,260.331207 467.001465,237.618591  C470.381744,237.441132 473.761017,237.722076 477.566650,238.525604  C477.992889,241.524338 477.871490,244.007690 478.018372,246.475082  C478.307953,251.340057 478.746246,256.196198 479.123047,261.055969  C477.551971,262.747467 476.105011,264.578522 474.387665,266.105591  C467.962830,271.818604 471.857391,278.518311 472.492920,284.888367  C472.896729,288.935699 473.612488,292.952972 473.980347,297.002594  C474.389343,301.504700 471.829102,303.882629 467.375427,304.908234  z"
        />
        <path
            fill="#08499B"
            opacity={1}
            stroke="none"
            d=" M231.109070,191.040100  C230.407013,189.299286 229.200455,187.587280 229.099121,185.812180  C228.814972,180.833801 228.986832,175.829407 228.968994,170.358490  C235.224792,164.068771 242.939392,161.894394 251.504059,161.495758  C251.557327,167.249420 251.283707,172.541046 250.677383,178.014160  C242.586288,180.460602 235.794998,184.173859 231.109070,191.040100  z"
        />
        <path
            fill="#0165C2"
            opacity={1}
            stroke="none"
            d=" M97.455009,109.969009  C98.563538,111.018166 99.815445,112.070908 99.834854,113.145897  C99.980698,121.226631 99.934341,129.312592 99.800606,137.394485  C99.783310,138.439987 98.921684,139.471512 98.170822,140.783051  C92.673042,135.610825 86.853867,130.606598 82.430557,124.578171  C78.957932,119.845413 80.102776,113.194191 86.850021,111.240936  C90.092232,110.302353 93.625923,110.370605 97.455009,109.969009  z"
        />
        <path
            fill="#064FA7"
            opacity={1}
            stroke="none"
            d=" M251.010101,177.832672  C251.283707,172.541046 251.557327,167.249420 251.920715,161.561600  C259.201996,161.866760 266.393494,162.568115 273.803497,163.577621  C273.686523,169.579742 273.351044,175.273697 272.662964,181.340225  C265.781738,177.282394 258.297241,178.099182 251.010101,177.832672  z"
        />
        <path
            fill="#0165C2"
            opacity={1}
            stroke="none"
            d=" M353.456482,365.968140  C354.255585,368.338837 354.972839,370.729462 354.935333,373.108124  C354.813446,380.833557 354.415131,388.554596 354.038025,396.694519  C349.423462,392.359161 345.177704,387.285278 340.273132,382.958099  C336.495392,379.625122 335.904358,375.362213 337.523438,371.857208  C338.624237,369.474152 342.796814,368.259430 345.816895,367.096802  C348.035187,366.242859 350.612000,366.320282 353.456482,365.968140  z"
        />
        <path
            fill="#0165C2"
            opacity={1}
            stroke="none"
            d=" M225.456451,421.968140  C226.255722,424.338837 226.973312,426.729462 226.935760,429.108124  C226.813797,436.833527 226.415298,444.554596 226.037979,452.694458  C221.423401,448.359161 217.177673,443.285248 212.273117,438.958099  C208.495377,435.625092 207.904404,431.362244 209.523438,427.857208  C210.624207,425.474182 214.796783,424.259430 217.816910,423.096832  C220.035187,422.242889 222.611984,422.320282 225.456451,421.968140  z"
        />
        <path
            fill="#0165C2"
            opacity={1}
            stroke="none"
            d=" M105.509811,357.933960  C105.968079,368.017181 105.996628,378.137146 105.989197,388.684692  C101.419327,384.354828 97.168701,379.276459 92.260017,374.943604  C88.475090,371.602692 87.902473,367.329041 89.532097,363.827667  C90.640358,361.446503 94.830193,360.252289 97.858826,359.094360  C100.083878,358.243652 102.660278,358.311951 105.509811,357.933960  z"
        />
        <path
            fill="#0165C2"
            opacity={1}
            stroke="none"
            d=" M353.514343,109.930618  C353.670624,119.688347 353.397156,129.484940 353.038696,139.699219  C347.957855,134.870499 342.726196,129.824432 338.043213,124.312233  C334.504608,120.147057 337.460663,112.407272 342.847778,111.208252  C346.186554,110.465126 349.667480,110.360634 353.514343,109.930618  z"
        />
        <path
            fill="#02A6F2"
            opacity={1}
            stroke="none"
            d=" M169.408691,387.889893  C168.737625,385.137207 168.116348,382.234283 168.051346,379.318909  C167.899185,372.495087 168.013748,365.665283 168.028259,358.361938  C171.601257,358.264862 175.337555,358.116699 178.673492,359.200653  C181.053040,359.973816 184.172058,362.099731 184.662628,364.167328  C185.320969,366.942017 184.664566,370.916992 182.972900,373.181885  C179.064270,378.414917 174.192429,382.928528 169.408691,387.889893  z"
        />
        <path
            fill="#02A6F2"
            opacity={1}
            stroke="none"
            d=" M417.976288,138.680939  C417.222473,137.423523 416.120758,135.867615 416.085114,134.287643  C415.908722,126.474564 416.014191,118.655121 416.028259,110.361938  C419.606934,110.265038 423.350616,110.113609 426.689850,111.202522  C429.068970,111.978355 432.184174,114.123878 432.667786,116.195717  C433.316803,118.976395 432.658264,122.983276 430.937195,125.206833  C427.212494,130.018982 422.454956,134.031693 417.976288,138.680939  z"
        />
        <path
            fill="#02A6F2"
            opacity={1}
            stroke="none"
            d=" M289.976288,82.680946  C289.222198,81.274170 288.128601,79.570084 288.084625,77.839340  C287.892090,70.258362 288.003510,62.669670 288.006470,54.625870  C291.742523,54.480431 295.602783,54.298939 299.183594,55.212654  C305.759186,56.890564 307.205536,64.321732 303.046478,69.270325  C299.111847,73.951843 294.457611,78.028610 289.976288,82.680946  z"
        />
        <path
            fill="#0165C2"
            opacity={1}
            stroke="none"
            d=" M41.514416,237.930573  C41.962749,245.510696 42.061909,253.131042 41.936790,260.747681  C41.909378,262.416290 41.083019,264.071808 40.254467,265.883545  C35.488689,261.740570 30.987837,257.548462 26.732124,253.120789  C23.572367,249.833359 23.557890,240.997360 30.824284,239.222107  C34.148026,238.410080 37.658730,238.363297 41.514416,237.930573  z"
        />
        <path
            fill="#02A6F2"
            opacity={1}
            stroke="none"
            d=" M106.419128,265.909363  C105.411346,264.390717 104.147049,262.754456 104.098198,261.082642  C103.881706,253.673294 104.014130,246.253769 104.028275,238.361938  C107.619011,238.265503 111.378616,238.107086 114.724724,239.206818  C117.100151,239.987503 120.200462,242.176926 120.669312,244.257629  C121.298798,247.051239 120.592834,251.022705 118.874878,253.284271  C115.387070,257.875732 110.835327,261.658966 106.419128,265.909363  z"
        />
        <path
            fill="#0165C2"
            opacity={1}
            stroke="none"
            d=" M224.588211,54.100121  C224.808701,63.239151 224.605652,72.307716 224.149902,81.710754  C219.541260,77.788223 215.074677,73.637352 210.860764,69.244118  C206.720856,64.928032 208.802979,56.875374 214.511398,55.325916  C217.617584,54.482788 220.939865,54.435753 224.588211,54.100121  z"
        />
        <path
            fill="#02A6F2"
            opacity={1}
            stroke="none"
            d=" M162.413910,137.914108  C161.740128,134.506577 161.164581,130.971573 161.047073,127.421394  C160.866989,121.980255 161.002350,116.528679 161.006012,110.623459  C164.580338,110.530586 168.294281,110.398125 171.694412,111.366966  C178.307877,113.251442 178.613449,121.686874 175.048248,125.400742  C171.015549,129.601608 166.834076,133.659668 162.413910,137.914108  z"
        />
        <path
            fill="#02A6F2"
            opacity={1}
            stroke="none"
            d=" M290.413849,449.914124  C289.739960,446.506592 289.164246,442.971588 289.046783,439.421387  C288.866699,433.980255 289.002197,428.528687 289.005981,422.623474  C292.580322,422.530579 296.294220,422.398163 299.694366,423.367004  C306.307678,425.251404 306.613556,433.686646 303.048279,437.400757  C299.015594,441.601654 294.834045,445.659637 290.413849,449.914124  z"
        />
        <path
            fill="#02A6F2"
            opacity={1}
            stroke="none"
            d=" M418.347229,393.977936  C417.753082,385.066864 417.465820,376.014587 417.093567,366.563843  C420.549042,366.520996 424.210419,366.424774 427.603119,367.332703  C433.432007,368.892578 435.221466,377.167938 430.901306,381.589539  C426.866882,385.718781 422.740173,389.757904 418.347229,393.977936  z"
        />
        <path
            fill="#0165C2"
            opacity={1}
            stroke="none"
            d=" M407.597168,238.102936  C407.809998,246.907364 407.607697,255.641220 407.153046,264.709656  C402.983215,261.236328 398.531097,257.817566 395.362274,253.464661  C393.645935,251.106964 393.029602,246.794205 393.946625,244.038818  C394.680634,241.833374 398.335785,240.344742 400.963776,239.112289  C402.801208,238.250549 405.093109,238.357849 407.597168,238.102936  z"
        />
        <path
            fill="#02A6F2"
            opacity={1}
            stroke="none"
            d=" M479.425507,260.919800  C478.746246,256.196198 478.307953,251.340057 478.018372,246.475082  C477.871490,244.007690 477.992889,241.524338 477.996185,238.587738  C479.407806,238.418427 480.810608,238.740891 482.225739,238.994690  C486.050079,239.680527 489.131012,241.900620 489.588745,245.557007  C489.897919,248.026840 488.491516,251.112305 486.968811,253.310196  C485.019928,256.123291 482.182495,258.320831 479.425507,260.919800  z"
        />
        <path
            fill="#000000"
            opacity={1}
            stroke="none"
            fillOpacity={0}
            d=" M250.677399,178.014160  C258.297241,178.099182 265.781738,177.282394 272.665588,181.767044  C285.201538,189.160690 288.506104,200.916840 288.987854,214.039871  C289.109070,217.342545 288.994843,220.653839 288.960815,224.418762  C286.251251,224.925812 283.569702,224.975281 279.982849,225.020325  C269.052856,225.066208 259.028168,225.116516 248.539856,225.092422  C240.383133,225.029175 232.690063,225.040344 224.997009,225.051514  C224.997009,225.051514 225.129700,224.991425 225.100281,224.924500  C224.929962,213.444916 224.433746,201.970978 230.951660,191.359985  C235.794998,184.173859 242.586288,180.460602 250.677399,178.014160  z"
        />
        <path
            fill="#02C7F8"
            opacity={1}
            stroke="none"
            d=" M249.003510,225.166840  C259.028168,225.116516 269.052856,225.066208 279.541138,225.091278  C280.002869,235.421310 280.001007,245.675995 279.663025,256.506561  C277.431946,258.378418 275.301727,259.433105 273.682526,261.011871  C266.538483,267.977753 259.567322,275.120575 252.464752,282.129669  C251.357574,283.222229 249.894455,283.954163 248.703171,284.323914  C248.874756,264.252258 248.939133,244.709549 249.003510,225.166840  z"
        />
        <path
            fill="#F9FEFF"
            opacity={1}
            stroke="none"
            d=" M248.595947,284.852844  C249.894455,283.954163 251.357574,283.222229 252.464752,282.129669  C259.567322,275.120575 266.538483,267.977753 273.682526,261.011871  C275.301727,259.433105 277.431946,258.378418 279.662537,256.965759  C283.994751,256.979126 287.147125,258.483704 288.552490,262.466400  C289.983398,266.521576 288.026855,269.528381 285.087463,272.506592  C275.667816,281.985321 266.397003,291.158112 256.984070,300.638428  C249.739365,306.704224 247.173782,306.541687 240.550095,299.937866  C237.172180,296.570038 233.779846,293.216736 230.132996,289.684753  C226.568100,285.991425 223.190399,282.493286 226.362350,276.508301  C231.872040,271.858093 235.109207,271.938538 239.694489,276.304413  C242.673798,279.141144 245.629608,282.002502 248.595947,284.852844  z"
        />
        <path
            fill="#02BFF8"
            opacity={1}
            stroke="none"
            d=" M248.703156,284.323914  C245.629608,282.002502 242.673798,279.141144 239.694489,276.304413  C235.109207,271.938538 231.872040,271.858093 226.435547,276.137878  C225.749252,272.704071 225.087753,269.085663 225.051422,265.460999  C224.919525,252.302368 224.997101,239.141632 224.996338,225.516602  C232.690063,225.040344 240.383133,225.029175 248.539841,225.092407  C248.939133,244.709549 248.874756,264.252258 248.703156,284.323914  z"
        />
    </svg>
);
export default TournamentForPremiumUsers;
