import LoadingLogo from "./LoadingLogo";
import SomethingWentWrong from "../helpers/SomethingWentWrongMessage";

function LoadingIfElseErrorSvg({ isLoading, errorString, classNamesForLoading, classNamesForError, loadingLogoBigOrSmall, retry }) {

    return (
        <>
            {
                loadingLogoBigOrSmall === "big" ?
                    <>
                        {
                            isLoading ?
                                <LoadingLogo type={"processing"} moveToTop={"250px"} />
                                :
                                <>
                                    {
                                        errorString !== "" &&
                                        <SomethingWentWrong retry={retry} />
                                    }
                                </>
                        }
                    </>
                    :
                    <>
                        {
                            isLoading ?
                                <svg className={classNamesForLoading} xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" /></svg>
                                :
                                <>
                                    {
                                        errorString !== "" ?
                                            <svg className={classNamesForError} xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                            :
                                            null
                                    }
                                </>
                        }
                    </>
            }
        </>
    )
}

export default LoadingIfElseErrorSvg;