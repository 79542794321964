import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "./styles/allReports.css"

import { useModMessagePopup } from "../../MainInterface/contexts/UserAdminMessage/UserAdminMessageProvider";
import { useBanModel } from "../../MainInterface/contexts/UserBan/UserBanModalProvider";
import { Link, useSearchParams, createSearchParams } from "react-router-dom";
import textTrimmer from "../helpers/stringCutter";

function UserAdminAllReports() {
    const params = useParams();
    const { openModMessageModal } = useModMessagePopup();
    const { openBanModel } = useBanModel();
    const [reportsState, setReportsState] = useState({
        isReportsLoading: false,
        reportsFetchError: "",

        fetchedReports: [],
    });
    const [openedMoreOption, setOpenedMoreOption] = useState([]);
    const [reportReviewStatus, setReportReviewStatus] = useState({})

    let [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [isFurtherAvailable, setIsFurtherAvailable] = useState(true);
    const [isBackAvailable, setIsBackAvailable] = useState(false);
    const navigate = useNavigate()

    // ! Content next page - START
    const urlNextParams = {
        page: currentPage + 1,
    };
    const nextOptions = {
        pathname: `/admin/dashboard/all-reports/${params.id}/`,
        search: `?${createSearchParams(urlNextParams)}`
    }
    const goToNextPage = () => {
        setCurrentPage(currentPage + 1)
        fetchReports(currentPage + 1)
        navigate(nextOptions, { replace: true });
    }
    // ! Content next page - END

    // ! Content next page - START
    const urlPrevParams = {
        page: currentPage - 1,
    };
    const PrevOptions = {
        pathname: `/admin/dashboard/all-reports/${params.id}/`,
        search: `?${createSearchParams(urlPrevParams)}`
    }
    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1)
        fetchReports(currentPage - 1)
        navigate(PrevOptions, { replace: true });
    }


    const openAdminMessagePopup = (clickedId, clickedType, clickedContentUserId) => {
        openModMessageModal(clickedId, clickedType, clickedContentUserId)
    }

    const openAdminBanModal = (userId) => {
        openBanModel(userId)
    }

    const openCloseMoreOptions = (index) => {
        const newOptionState = [...openedMoreOption];
        newOptionState[index] = !newOptionState[index];
        setOpenedMoreOption(newOptionState);
    }



    useEffect(() => {
        const currentPageFromUrl = searchParams.get("page");
        fetchReports(currentPageFromUrl);
    }, [])

    const fetchReports = (page) => {
        axios.get(`/dashboard/admin/all/reports/list/${params.id}/?page=${page}`).then((res) => {
            if (res.data.next !== null) {
                setIsFurtherAvailable(true);
            } else {
                setIsFurtherAvailable(false);
            }
            if (res.data.previous !== null) {
                setIsBackAvailable(true);
            } else {
                setIsBackAvailable(false);
            }
            setReportsState({
                isReportsLoading: false,
                reportsFetchError: "",
                fetchedReports: res.data.results
            })
        })
    }

    const updateReportStatus = (id, shortStatus, fullNameDisplay) => {
        axios.put(`/dashboard/admin/update/report/status/${id}/`, { status: shortStatus }).then((res) => {
            setReportsState(prevState => ({
                ...prevState,
                fetchedReports: prevState.fetchedReports.map(currentReport => {
                    if (currentReport.id === id) {
                        return {
                            ...currentReport,
                            status_display: fullNameDisplay
                        }
                    }
                    return currentReport;
                })
            }))
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <div className="reports-container">
                <h2>Submitted Reports</h2>
                <ul className="report-list">
                    {
                        reportsState.fetchedReports?.map((report, index) =>
                            <li className="report-item" key={report.id}>
                                <span className="flt-rgt">{report?.timestamp}</span>
                                <h3>{report?.reported_main_reason_display} <h2 className="clr-white">#{report.id}</h2></h3>
                                <div className="report-status-dropdown">
                                    <span className="review-button"><button className={`review-button ${report.status_display}`}>{report.status_display}</button></span>
                                    <div className="report-status-items">
                                        <span className="report-status-item" data-value="reviewed" onClick={() => updateReportStatus(report.id, "R", "Reviewed")}>Reviewed</span>
                                        <span className="report-status-item" data-value="pending" onClick={() => updateReportStatus(report.id, "P", "Pending")}>Pending</span>
                                        <span className="report-status-item" data-value="resolved" onClick={() => updateReportStatus(report.id, "RS", "Resolved")}>Resolved</span>
                                        <span className="report-status-item" data-value="later" onClick={() => updateReportStatus(report.id, "L", "Later")}>Later</span>
                                    </div>
                                </div>
                                <p>{report?.reported_second_step_display}</p>
                                <hr />
                                {
                                    report?.reported_third_step_detail !== null &&
                                    <>
                                        <b>More Info</b>
                                        <p>{report?.reported_third_step_detail}</p>
                                        <hr />
                                    </>
                                }
                                <b>From Backend</b>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Impact type</th>
                                            <th>Report Severity (10/)</th>
                                            <th>How effecting or why targeted?</th>
                                            <th>User completed the form?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{report?.impact_type_display}</td>
                                            <td>{report?.report_severity}</td>
                                            <td>{report?.how_effecting_or_why_targeted}</td>
                                            <td>{report.is_user_completed_the_form ? <>Yes</> : <>No</>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr />
                                {
                                    report.reportcopyright.length !== 0 &&
                                    <>
                                        <div className="report-list">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Reporting for?</th>
                                                        <th>Company name</th>
                                                        <th>Copyright individual type</th>
                                                        <th>Copyright owner name</th>
                                                        <th>Country</th>
                                                        <th>Descrive infringment</th>
                                                        <th>Description</th>
                                                        <th>Direct link of O/W</th>
                                                        <th>Link of R/C</th>
                                                        <th>Email</th>
                                                        <th>Address</th>
                                                        <th>Phone</th>
                                                        <th>Profile Link</th>
                                                        <th>Is Resolved?</th>
                                                        <th>Is Reviewed?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{report.reportcopyright[0].type_of_reporting_content}</td>
                                                        <td>{report.reportcopyright[0].company_name}</td>
                                                        <td>{report.reportcopyright[0].copyright_individual_type}</td>
                                                        <td>{report.reportcopyright[0].copyright_owner_full_name}</td>
                                                        <td>{report.reportcopyright[0].country}</td>
                                                        <td>{report.reportcopyright[0].describe_infringment}</td>
                                                        <td>{report.reportcopyright[0].description}</td>
                                                        <td><a href={report.reportcopyright[0].direct_link_of_original_work} target="_blank" rel="noopener noreferrer">{textTrimmer(report.reportcopyright[0].direct_link_of_original_work, 5)}</a></td>
                                                        <td><a href={report.reportcopyright[0].direct_link_of_reporting_content} target="_blank" rel="noopener noreferrer">{textTrimmer(report.reportcopyright[0].direct_link_of_reporting_content, 5)}</a></td>
                                                        <td>{report.reportcopyright[0].email}</td>
                                                        <td>{report.reportcopyright[0].full_address}</td>
                                                        <td>{report.reportcopyright[0].phone_number}</td>
                                                        <td><a href={report.reportcopyright[0].profile_link} target="_blank" rel="noopener noreferrer">{textTrimmer(report.reportcopyright[0].profile_link, 5)}</a></td>
                                                        <td className={`${report.reportcopyright[0].is_resolved ? "resolved" : "not-resolved"}`}>{report.reportcopyright[0].is_resolved ? <>Yes</> : <>No</>}</td>
                                                        <td className={`${report.reportcopyright[0].is_resolved ? "resolved" : "not-resolved"}`}>{report.reportcopyright[0].is_reviewed ? <>Yes</> : <>No</>}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                    </>
                                }

                                {
                                    report.type_of_content === "remark" ?
                                        <Link to={`/upshot/${report.additional_report_info[0]?.upshot_id}/?remark_id=${report.id}`} className="view-content-button">
                                            View Content
                                        </Link>
                                        :
                                        null
                                }

                                {
                                    report.type_of_content === "comment" ?
                                        <Link to={`/upshot/${report.additional_report_info[0]?.upshot_id}/?remark_id=${report.additional_report_info[0]?.remark_id}&comment_id=${report.comment}`} className="view-content-button">
                                            View Content
                                        </Link>
                                        :
                                        null
                                }

                                <svg onClick={() => openCloseMoreOptions(report.id)} className="make-text-cursor" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z" /></svg>
                                {
                                    openedMoreOption[report.id] &&
                                    <>
                                        {/* <button className="delete-content-button">Delete</button> */}
                                        {/* <button className="hide-content-button">Hide</button> */}
                                        <button className="delete-content-button" onClick={() => openAdminMessagePopup(report.type_of_content === "remark" ? report.remark : report.type_of_content === "profile" ? report.profile : report.type_of_content === "upshot" ? report.upshot : report.comment, report.type_of_content, report.reporting_of_user)}>Message User</button>
                                        <button className="hide-content-button" onClick={() => openAdminBanModal(report.reporting_of_user)}>Ban User</button>
                                    </>
                                }
                            </li>
                        )
                    }
                </ul>

                <div className="pagination-contet">
                    {
                        isFurtherAvailable &&
                        <button id="nextButton" onClick={goToNextPage}>Next &gt;</button>
                    }
                    <br />
                    {
                        isBackAvailable &&
                        <button id="prevButton" onClick={goToPreviousPage}>&lt; Previous</button>
                    }
                </div>
            </div>
        </>
    )
}

export default UserAdminAllReports;