import { useTranslation } from 'react-i18next';
import sanitize from 'sanitize-html';

import "./termsOfService.css";
import LegalPolicyHeader from "../LegalPolicyHeader";
import UserLegalReadablesActions from "../UserLegalReadablesActions";
import LayoutComponentForHelmet from '../../../../MainInterface/SEO/LayoutComponentForHelmet';

const LegalReadablesHTMLSanitizer = ({ content }) => {

    const sanitizeHTMLContent = (content) => {
        return sanitize(content, {
            allowedTags: ['b', 'i', 'em', 'strong', 'p', 'a', "br", "ul", "li", "small", "i"],
            allowedAttributes: {
                "a": ["href", "target"],
                "ul": ["className"]
            }
        });
    };


    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizeHTMLContent(content) }} />
    );
};

function TermsOfService() {
    const { t, i18n } = useTranslation('MyComponent');

    i18n.addResourceBundle('en', 'MyComponent', {
        termsOfServiceOverview: `Welcome to our platform! The following Terms of Service ("Terms") are applicable to and govern your access to and use of specific new features of our services, referred to as "Features."
        These Terms are supplementary to our User Agreement and Premium and Virtual Goods Agreement (collectively referred to as "SpreeCine Terms"), which you have already accepted.
        The SpreeCine Terms include definitions for certain terms found in these Terms of Service.`,
        tosAcceptance: `By accessing or using any Feature, you acknowledge and agree to adhere to the Terms of Service.
        <br />
        If you are accepting these Terms on behalf of a third party, such as your employer, you affirm that you are an authorized representative and possess the authority to legally bind that third party to these Terms. Furthermore, any reference to "you" within the Terms of Service also includes this third party.
        <br />
        <br />
        It is essential to recognize that the Terms of Service and any Feature may be modified at any time, for any reason or without reason, at our sole discretion, without prior notification or liability to our platform (subject to applicable law). We advise you to periodically review the Terms of Service for any updates, as it is your responsibility to do so.
        <br />
        <br />
        If you do not agree to abide by the Terms of Service, then you are not authorized to access or utilize any Feature.`,
        AcceptanceHeading: "Acceptance",
        EligibilityHeading: "Eligibility",
        eligibilityHeader: "You can access and use our Features if:",
        eligibilityLiFirst: "You are at least 18 years old or You're of the required legal age in your country.",
        eligibilityLiSecond: "You're not subject to trade restrictions or embargoes and are not on any prohibited lists.",
        eligibilityLiThird: "Your Account is in good standing and hasn't violated our SpreeCine Terms.",
        eligibilityLiFourth: "You're not barred from using Features by applicable laws.",
        eligibilityPar2: "By using any Feature, you confirm that you meet these criteria and agree to follow the Terms of Service and relevant laws.",
        eligibilityPar3: "We have the right to block, suspend, or terminate access to any Feature at any time, for any reason, without notice or liability, and you may lose access to the Feature.",
        accHeading: "Account Suspension or Termination",
    });

    i18n.addResourceBundle('es', 'MyComponent', {
        termsOfServiceOverview: `¡Bienvenidos a nuestra plataforma! Los siguientes Términos de servicio ("Términos") se aplican y rigen su acceso y uso de nuevas funciones específicas de nuestros servicios, denominadas "Funciones".
        Estos Términos son complementarios de nuestro Acuerdo de usuario y Acuerdo de productos virtuales y premium (denominados colectivamente "Términos de SpreeCine"), que usted ya ha aceptado.
        Los Términos de SpreeCine incluyen definiciones de ciertos términos que se encuentran en estos Términos de servicio.`,
        tosAcceptance: `Al acceder o utilizar cualquier función, usted reconoce y acepta cumplir con los Términos de servicio..
        <br />
        Si acepta estos Términos en nombre de un tercero, como su empleador, afirma que es un representante autorizado y posee la autoridad para vincular legalmente a ese tercero a estos Términos. Además, cualquier referencia a "usted" en los Términos de servicio también incluye a este tercero.
        <br />
        <br />
        Es esencial reconocer que los Términos de Servicio y cualquier Característica pueden modificarse en cualquier momento, por cualquier motivo o sin motivo, a nuestro exclusivo criterio, sin notificación previa ni responsabilidad para nuestra plataforma (sujeto a la ley aplicable). Le recomendamos que revise periódicamente los Términos de servicio para detectar actualizaciones, ya que es su responsabilidad hacerlo.
        <br />
        <br />
        Si no acepta cumplir con los Términos de servicio, entonces no está autorizado a acceder ni utilizar ninguna función.`,
        AcceptanceHeading: "Aceptación",
        EligibilityHeading: "Elegibilidad",
        eligibilityHeader: "Puede acceder y utilizar nuestras funciones de:",
        eligibilityLiFirst: "Tienes al menos 18 años o tienes la edad legal requerida en tu país.",
        eligibilityLiSecond: "No está sujeto a restricciones comerciales ni embargos y no está en ninguna lista prohibida.",
        eligibilityLiThird: "Su cuenta está al día y no ha violado nuestros Términos de SpreeCine.",
        eligibilityLiFourth: "Las leyes aplicables no le impiden utilizar las funciones.",
        eligibilityPar2: "Al utilizar cualquier Función, usted confirma que cumple con estos criterios y acepta seguir los Términos de servicio y las leyes pertinentes.",
        eligibilityPar3: "Tenemos derecho a bloquear, suspender o cancelar el acceso a cualquier Función en cualquier momento, por cualquier motivo, sin previo aviso ni responsabilidad, y usted puede perder el acceso a la Función.",
        accHeading: "Suspensión o cancelación de cuenta",
    });

    return (
        <LayoutComponentForHelmet pageTitle={"Terms of Service - SpreeCine"}>
            <div className="terms-of-service">

                <LegalPolicyHeader includeAppName header="Terms of Service" />
                <UserLegalReadablesActions i18n={i18n} />

                <p className="tos-overview">
                    {t('termsOfServiceOverview')}
                </p>

                <h2 className="tos-subheader">{t("AcceptanceHeading")}</h2>
                <p className="tos-explaination">
                    <LegalReadablesHTMLSanitizer content={t("tosAcceptance")} />
                </p>

                <h2 className="tos-subheader">{t("EligibilityHeading")}</h2>
                <p className="tos-ul-header">
                    {t("eligibilityHeader")}
                </p>

                <ul className="tos-ul">
                    <li>{t("eligibilityLiFirst")}</li>
                    <li>{t("eligibilityLiSecond")}</li>
                    <li>{t("eligibilityLiThird")}</li>
                    <li>{t("eligibilityLiFourth")}</li>
                </ul>

                <p className="tos-ul-header">
                    {t("eligibilityPar2")}
                    <br />
                    <br />
                    {t("eligibilityPar3")}
                </p>

                {/* <li>Subscriptions</li> */}
                <h2 className="tos-subheader">{t("accHeading")}</h2>
                <p className="tos-explaination">
                    In accordance with the provisions articulated in the User Agreement and our Content Policy, strict compliance with the regulations governing our Services is imperative.
                    Non-compliance may constitute a breach of the Terms of Service, potentially resulting in a temporary or permanent suspension from our Services, and the removal of your posts and comments.
                    In such instances, there may be a forfeiture of privileges associated with Features, which encompass Paid Services, subscriptions.
                    It is crucial to note that no compensation will be extended to mitigate the consequences of such actions.
                </p>

                <h2 className="tos-subheader">Your Acknowledgments</h2>

                <h2 className="tos-subheader">Disclaimers and Limitation of Liability</h2>
                <p className="tos-explaination">
                    To the maximum extent allowed by applicable law, all Features of SpreeCine are provided on an "as is," "as available," and "with all faults" basis. The SpreeCine Entities do not make any express or implied representations, warranties, or endorsements of any nature (including, but not limited to, fitness for a particular purpose, non-infringement, merchantability, or title) regarding them or the Services, which encompasses Vault and its features, including their operation, availability, and security.
                </p>

                <h2 className="tos-subheader">Modification or Discontinuation</h2>
                <p className="tos-explaination">
                    When you use any SpreeCine Feature, please be aware that these features may be terminated at our discretion, for any reason or without reason, without prior notice or liability on the part of SpreeCine. We retain the right to amend, suspend, or terminate any SpreeCine Feature, whether in full or in part, at any time, with or without notice to you. Any forthcoming releases, updates, or additional functionalities of any SpreeCine Feature will be subject to the prevailing Previews Terms, which may be periodically updated. It is understood that we shall not be held liable to you or any third party for any alterations, suspensions, or discontinuations of any SpreeCine Feature, whether in full or in part. Additionally, no refunds will be provided if any SpreeCine Feature becomes unusable on or through the Services.
                </p>

                <h2 className="tos-subheader">Refund Policy</h2>
                <p className="tos-explaination">
                    You have instant access to and usage of SpreeCine Features that are Paid Services when you make a purchase through our platform. Consequently, all such purchases are considered final, non-refundable, and non-returnable to the fullest extent allowed by the law. There is no obligation on our part to issue refunds or credits for partially used billing periods.
                    <br />
                    <br />
                    For individuals residing in the EEA, United Kingdom, or Switzerland, you possess the right to withdraw from the Previews Terms within a 14-calendar day period, without the need to provide reasons. This withdrawal window commences 14 calendar days from the date when the Previews Terms are concluded. To exercise your right of withdrawal, you must formally request this from SpreeCine.

                    <br />
                    <br />
                    It's essential to note, however, that your right of withdrawal is forfeited when you purchase SpreeCine Features that constitute Paid Services and encompass either (a) a license for Virtual Goods or Verified Virtual Goods, or (b) a subscription. These Paid Services are made available to you as soon as the Virtual Good or Verified Virtual Good is credited to your Account or Vault, or when the subscription commences. You expressly request and consent to receiving these Paid Services in this manner immediately upon purchase, at which point your right to withdraw is relinquished.
                </p>

                <h2 className="tos-subheader">Miscellaneous</h2>
                <p className="tos-explaination">
                    The SpreeCine Terms, which encompass our User Agreement and SpreeCine Premium and Virtual Goods Agreement, are hereby integrated into and constitute an integral component of these Previews Terms by reference. In the event of any conflict between the SpreeCine Terms and these Previews Terms, these Previews Terms take precedence concerning your access to, purchase, and utilization of SpreeCine Features.
                </p>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default TermsOfService;