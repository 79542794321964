import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./tastePicking.css";
import languages from "./languages";
import textTrimmer from "../../components/helpers/stringCutter";
import { defaultPlaceholderImageIfNotPresentAny } from "../constants/constants";

function UserTastePickingModal({ isModalOpen, closeModal }) {
    const { t } = useTranslation();
    const [selectedGenres, setSelectedGenres] = useState([]);

    const [errors, setErrors] = useState({
        languageError: false,
        genreError: false,

        postError: false,
    })

    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false);

    const [isContentPickingModalOpen, setContentPickingModalOpen] = useState(false);

    const addClickedGenreToList = (item) => {
        if (selectedGenres.includes(item)) {
            setSelectedGenres(selectedGenres.filter(value => value !== item));
        } else {
            setSelectedGenres([...selectedGenres, item]);
        }
    }

    const genres = [
        "Action",
        "Adventure",
        "Animation",
        "Comedy",
        "Crime",
        "Documentary",
        "Family",
        "Fantasy",
        "History",
        "Music",
        "Mystery",
        "Romance",
        "Science Fiction",
        "Thriller",
        "War",
        "Western",
    ]

    const saveUserTasteGlobal = () => {

        const areInputsValid = [selectedLanguage].every((input) => input.trim() !== "") && selectedGenres.length >= 5;
        if (areInputsValid) {
            setErrors({
                languageError: false,
                genreError: false,
                postError: false,
            })
            const dataToPost = ({
                selectedGenres: selectedGenres,
                language: selectedLanguage,
            })
            axios.post(`/api/user/t/save/user/global/taste/`, dataToPost).then((res) => {
                if (!JSON.parse((res.headers["x-profile-c-t-s-c"]).toLowerCase())) {
                    openContentPickingModal();
                    closeModal();
                };
                setErrors({
                    postError: false
                })
            }).catch(() => {
                setErrors({
                    postError: true
                })
            })
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                languageError: selectedLanguage.trim() === "",
                genreError: selectedGenres.length < 5,
            }))
        }

    }

    const openContentPickingModal = () => {
        setContentPickingModalOpen(true);
    }

    return (
        <>
            <ContentPickingTasteModal
                isContentPickingModalOpen={isContentPickingModalOpen}
                closeContentPickingModal={() => setContentPickingModalOpen(false)}
            />
            {
                isModalOpen &&
                <>
                    <div className="backdrop"></div>
                    <div className="interests-container">
                        <div className="interests">
                            <div className="genre-header">
                                <svg className="flt-rgt" onClick={closeModal} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                                <h1>{t("Pick Genres")}</h1>
                            </div>
                            <div className="err-message">
                                {
                                    errors.genreError ?
                                        <>{t("Select at least 5 genres")}</>
                                        :
                                        null
                                }
                            </div>
                            <div className="interests-list">
                                {
                                    genres.map((value, index) => (
                                        <span key={value} className={`interest ${selectedGenres.includes(value) && "added"}`} onClick={() => addClickedGenreToList(value)}>{value}</span>
                                    ))
                                }
                            </div>
                            <div className={`language-dropdown-button ${isLanguageDropdownOpen && "dropdown-open"}`} onClick={() => setLanguageDropdownOpen(!isLanguageDropdownOpen)}>
                                {selectedLanguage !== "" ? <>{selectedLanguage}</> : <>{t("Select Preferred Language")}</>}
                                {/* Select Preferred Language */}
                                <svg className="dropdown-arrow" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                                    <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
                                </svg>
                                <div className="language-list" id="dropdown-items">
                                    {
                                        languages.map((value, index) =>
                                            <div className="l-item" key={value} onClick={() => setSelectedLanguage(value)}>
                                                <span>{value}</span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="err-message">
                                {
                                    errors.languageError ?
                                        <>Choose your preferred language</>
                                        :
                                        null
                                }
                            </div>
                            {
                                errors.postError &&
                                <div className="err-message">{t("Something went wrong while saving. Let's try again later")}</div>
                            }
                            <button className="submit-button" onClick={saveUserTasteGlobal}>{t("Submit")}</button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}



function ContentPickingTasteModal({ isContentPickingModalOpen, closeContentPickingModal }) {
    const { t } = useTranslation();
    const [selectedContent, setSelectedContent] = useState([]);
    const [selectedContentToView, setSelectedContentToView] = useState([]);
    const [fetchedContentState, setFetchedContentState] = useState({
        fetchedContent: [],

        isLoading: false,
        fetchError: "",
    })

    const addContentToSelectedContent = (item, mediaType) => {
        if (selectedContentToView.some(selectedContent => selectedContent.id === item.id)) {
            setSelectedContentToView(prevSelectedContents =>
                prevSelectedContents.filter(selectedContent => selectedContent.id !== item.id)
            );
        } else {
            setSelectedContentToView(prevSelectedContents => [...prevSelectedContents, item]);
        }

        // const newItem = { [item.media_type]: item.id };

        // if (selectedContent.some(stateItem => stateItem[item.media_type])) {
        //     setSelectedContent(prevSelectedContents =>
        //         prevSelectedContents.map(stateItem =>
        //             stateItem[item.media_type] ? { ...stateItem, [item.media_type]: item.id } : stateItem
        //         )
        //     );
        // } else {
        //     setSelectedContent(prevState => [...prevState, newItem]);
        // }

        const existingDictionaryIndex = selectedContent.findIndex(dict => dict[item.id] !== undefined);

        if (existingDictionaryIndex !== -1) {
            const existingDictionary = selectedContent[existingDictionaryIndex];

            if (!existingDictionary[mediaType]) {
                const updatedDictionary = { ...existingDictionary, [mediaType]: item.id };
                const updatedState = [...selectedContent];
                updatedState[existingDictionaryIndex] = updatedDictionary;
                setSelectedContent(updatedState);
            }
        } else {
            setSelectedContent(prevState => [...prevState, { [mediaType]: item.id }]);
        }
    }

    useEffect(() => {
        if (isContentPickingModalOpen) {
            setFetchedContentState({
                isLoading: true,
                fetchError: "",
            })
            axios.get("/api/user/t/get/taste/genre/contents").then((res) => {
                setFetchedContentState({
                    fetchedContent: res.data.message,

                    isLoading: false,
                    fetchError: "",
                })
            }).catch(() => {
                setFetchedContentState({
                    isLoading: false,
                    fetchError: "Something went wrong",
                })
            })
        }
    }, [isContentPickingModalOpen])

    const saveContentTaste = () => {
        const dataToPost = ({
            pickedContent: selectedContent
        })
        axios.post(`/api/user/t/save/user/global/taste/`, dataToPost).then(() => {
            closeContentPickingModal();
        }).catch(() => {
        })
    }

    return (
        <>
            {
                isContentPickingModalOpen &&
                <div className="taste-picking-modal">
                    <div className="taste-picking-modal-content">
                        <span className="close-btn" onClick={closeContentPickingModal}>✖</span>
                        <ul>
                            {
                                fetchedContentState.fetchedContent?.map((value) =>
                                    <li onClick={() => addContentToSelectedContent(value, value.media_type)}>
                                        {
                                            value.poster_path ?
                                                <img src={`https://image.tmdb.org/t/p/w200/${value.poster_path}`} alt="Image 1" />
                                                :
                                                <img src={defaultPlaceholderImageIfNotPresentAny} alt="Image 1" />
                                        }
                                        <span className="taste-picking-content-title">{value.original_title ? <>{textTrimmer(value.original_title, 15)}</> : <>{textTrimmer(value.original_name, 15)}</>}</span>
                                        {
                                            selectedContentToView.map((re) =>
                                                <>
                                                    {value.id === re.id &&
                                                        <div className="icon-container">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                                                <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                                                            </svg>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        }
                                    </li>
                                )
                            }
                        </ul>
                        <div className="gcp-btn-container">
                            <button className="submit-button" onClick={saveContentTaste}>{t("Submit")}</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default UserTastePickingModal;