import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal } from '@mantine/core';
import axios from 'axios';

import CopyrightAdditionalInfoModal from './CopyrightAdditionalInfo';

function CopyrightReportModal({ isCopyrightReportModalOpen, setCopyrightReportModalOpen, setMainReportShow, selectedReason, selectedReasonStepTwo, setSelectedReasonStepTwo }) {
    const { t } = useTranslation();
    const [selectedCopyrightReason, setCopyrightSelectedReason] = useState("");
    const [isAdditionalInfoModalInfo, setAdditionalInfoModalOpen] = useState(false);
    const [copyrightFieldState, setCopyrightFieldState] = useState({
        copyrightOnwerName: "",
        companyName: "",
        email: "",
        fullAddress: "",
        country: "",
        phNumber: 0,
        description: "",
        directLinkOfOriginalWork: "",
        directLinkOfReportingContent: "",
        profileLink: "",
        describeInfringment: "",
    })
    const [copyrightReportSubmitState, setCopyrightReportSubmitState] = useState({
        isReportSaveLoading: false,
        reportSaveError: "",
    })

    const updateSelectedReportReason = (value) => {
        if (value !== undefined && value !== null) {
            setCopyrightSelectedReason(value);
            setSelectedReasonStepTwo(value);
        }
    }

    const backToMainReportModal = () => {
        setCopyrightReportModalOpen(false);
        setMainReportShow(true);
        setCopyrightSelectedReason("");
    }

    const openCopyrightAdditionalModal = () => {
        setAdditionalInfoModalOpen(true);
        setCopyrightReportModalOpen(false);
    }

    const sendCopyrightDataToBackend = () => {
        setCopyrightReportSubmitState({
            isReportSaveLoading: true,
            reportSaveError: "",
        })
        axios.get(`/get-current-user/`).then((res) => {
            const dataForReport = ({
                reported_by: res.data.USER_ID,
                reported_main_reason: selectedReason,
                reported_second_step: selectedReasonStepTwo,
            })
            axios.post(`/report/create/`, dataForReport).then((res) => {
                const dataForCopyrightReportCreation = ({
                    report: res.data.ID,
                    copyright_individual_type: "CMPNY",
                    copyright_owner_full_name: copyrightFieldState.copyrightOnwerName,
                    company_name: copyrightFieldState.companyName,
                    email: copyrightFieldState.email,
                    full_address: copyrightFieldState.fullAddress,
                    country: copyrightFieldState.country,
                    phone_number: copyrightFieldState.phNumber,
                    description: copyrightFieldState.description,
                    reporting_reason: "STLWRK",
                    direct_link_of_original_work: copyrightFieldState.directLinkOfOriginalWork,
                    direct_link_of_reporting_content: copyrightFieldState.directLinkOfReportingContent,
                    profile_link: copyrightFieldState.profileLink,
                    describe_infringment: copyrightFieldState.describeInfringment,
                })
                axios.post(`/report/create/copyright/`, dataForCopyrightReportCreation).then((res) => {
                    setCopyrightReportSubmitState({
                        isReportSaveLoading: false,
                        reportSaveError: "",
                    })
                }).catch(() => {
                    setCopyrightReportSubmitState({
                        isReportSaveLoading: false,
                        reportSaveError: "Something went Wrong!",
                    })
                })
            }).catch(() => {
                setCopyrightReportSubmitState({
                    isReportSaveLoading: false,
                    reportSaveError: "Something went Wrong!",
                })
            })
        }).catch(() => {
            setCopyrightReportSubmitState({
                isReportSaveLoading: false,
                reportSaveError: "Something went Wrong!",
            })
        })
    }

    return (
        <>
            <Modal opened={isCopyrightReportModalOpen} onClose={backToMainReportModal} radius="10px" size="xl" title={t("Copyright infringement")} centered>
                <div className="report-area">
                    <form onClick={(e) => updateSelectedReportReason(e.target.getAttribute("data-value"))}>
                        <ul>
                            <li className={`${selectedCopyrightReason === "CPYRGTMTRL" && `selected`}`} data-value="CPYRGTMTRL">{t("Unauthorized use of copyrighted materials")}</li>
                            <li className={`${selectedCopyrightReason === "CPYRGTTRDUSE" && `selected`}`} data-value="CPYRGTTRDUSE">{t("Unauthorized trademark use")}</li>
                            <Button variant='filled' color='rgb(103, 103, 222)' fullWidth onClick={openCopyrightAdditionalModal} disabled={selectedCopyrightReason === "" ? true : false}>{t("I'm sure")}</Button>
                        </ul>
                    </form>
                </div>
            </Modal>

            <CopyrightAdditionalInfoModal
                isAdditionalInfoModalInfo={isAdditionalInfoModalInfo}
                setAdditionalInfoModalOpen={setAdditionalInfoModalOpen}
                setMainReportShow={setMainReportShow}

                copyrightFieldState={copyrightFieldState}
                setCopyrightFieldState={setCopyrightFieldState}

                sendCopyrightDataToBackend={sendCopyrightDataToBackend}

                selectedCopyrightReason={selectedCopyrightReason}
            />
        </>
    )
}

export default CopyrightReportModal;