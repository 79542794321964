import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import './notification.css';

function completeTheMessageDangerFromShortcut(messageDangerTypeShortcut) {
    if (messageDangerTypeShortcut === "G") {
        return "Green"
    } else if (messageDangerTypeShortcut === "O") {
        return "Orange"
    } else if (messageDangerTypeShortcut === "R") {
        return "Red"
    } else {
        return "Orange"
    }
}

function getAppropriateHeaderFromShortcut(messageTypeShortcut) {
    if (messageTypeShortcut === "W") {
        return "Administration Warning"
    } else if (messageTypeShortcut === "G") {
        return "Greetings from the Administration!"
    } else if (messageTypeShortcut === "I") {
        return "Important Information!"
    } else if (messageTypeShortcut === "U") {
        return "Update!"
    } else {
        return "Unknown!"
    }
}

function NotificationDetail() {
    const params = useParams();
    const [notificationDataState, setNotificationDataState] = useState({
        isNotificationLoading: false,
        notificationFetchError: "",

        fetchedDataOfNotification: {},
    })

    useEffect(() => {
        fetchNotificationDetail();
    }, [])

    const fetchNotificationDetail = () => {
        setNotificationDataState(prevState => ({
            ...prevState,
            isNotificationLoading: true,
            notificationFetchError: "",
        }))
        axios.get(`/notification-detail/${params.notificationId}/`).then((res) => {
            setNotificationDataState(prevState => ({
                ...prevState,
                fetchedDataOfNotification: res.data.message,
                isNotificationLoading: false,
                notificationFetchError: "",
            }))
        }).catch(() => {
            setNotificationDataState(prevState => ({
                ...prevState,
                isNotificationLoading: false,
                notificationFetchError: "Something went Wrong!",
            }))
        })
    }

    return (
        <div className={`message-container ${notificationDataState.fetchedDataOfNotification.message_danger_weight?.toLowerCase()}-weight-message`}>
            <h1>{getAppropriateHeaderFromShortcut(notificationDataState.fetchedDataOfNotification.message_type)}</h1>
            <small className="notification-message-from"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M680-280q25 0 42.5-17.5T740-340q0-25-17.5-42.5T680-400q-25 0-42.5 17.5T620-340q0 25 17.5 42.5T680-280Zm0 120q31 0 57-14.5t42-38.5q-22-13-47-20t-52-7q-27 0-52 7t-47 20q16 24 42 38.5t57 14.5ZM480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v227q-19-8-39-14.5t-41-9.5v-147l-240-90-240 90v188q0 47 12.5 94t35 89.5Q310-290 342-254t71 60q11 32 29 61t41 52q-1 0-1.5.5t-1.5.5Zm200 0q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80ZM480-494Z" /></svg> From Administration</small>
            <small className="notification-message-timestamp" title={notificationDataState.fetchedDataOfNotification.send_timestamp}>{notificationDataState.fetchedDataOfNotification.natural_time}</small>
            <hr />
            <p>{notificationDataState.fetchedDataOfNotification.message}</p>
            <hr />
            <small className="admin-message-message-reply-button">Some misunderstanding is going on?</small><small className="admin-message-message-reply-button text-hover-blue cursor-pointer">Explain it here.</small>
        </div>
    )
}

export default NotificationDetail;