import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";

import LoadingIfElseErrorSvg from '../../animations/LoadingIfElseErrorSvg';
import ResetPasswordSuccessComponent from "./ResetPasswordSuccessComponent"
import LayoutComponentForHelmet from '../../../MainInterface/SEO/LayoutComponentForHelmet';

function ResetPasswordConfirm() {
    const { t } = useTranslation();
    const { uid, token } = useParams();
    const [resetPasswordPostState, setResetPasswordPostState] = useState({
        newPassword1: "",
        newPassword2: "",
        password1Error: "",
        password2Error: "",
        passwordErrorValidated: "",

        passwordPostError: "",
        isPasswordPostLoading: false,
        success: false,
    });

    const onResetPasswordInputFieldChange = (e) => {
        setResetPasswordPostState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const changePasswordOnClick = () => {
        setResetPasswordPostState(prevState => ({
            ...prevState,
            passwordPostError: "",
            isPasswordPostLoading: true,
            success: false,
        }))

        const data = {
            uid: uid,
            token: token,
            new_password: resetPasswordPostState.newPassword1,
            re_new_password: resetPasswordPostState.newPassword2,
        };

        axios.post("/api/v1/users/reset_password_confirm/", data).then((res) => {
            setResetPasswordPostState(prevState => ({
                ...prevState,
                passwordPostError: "",
                isPasswordPostLoading: false,
                success: true,
            }))
        }).catch((err) => {
            if (err.response && err.response.data.hasOwnProperty("non_field_errors")) {
                setResetPasswordPostState(prevState => ({
                    ...prevState,
                    passwordErrorValidated: err?.response?.data["non_field_errors"],
                    isPasswordPostLoading: false,
                    success: false,
                }))
            } else {
                setResetPasswordPostState(prevState => ({
                    ...prevState,
                    passwordErrorValidated: "",
                    isPasswordPostLoading: false,
                    success: false,
                }))
            }
            if (err.response && err.response.data.hasOwnProperty("new_password")) {
                setResetPasswordPostState(prevState => ({
                    ...prevState,
                    password1Error: err.response.data["new_password"] ? err.response.data["new_password"] : "",
                    isPasswordPostLoading: false,
                    success: false,
                }))
            } else {
                setResetPasswordPostState(prevState => ({
                    ...prevState,
                    password1Error: "",
                    isPasswordPostLoading: false,
                    success: false,
                }))
            }
            if (err.response && err.response.data.hasOwnProperty("re_new_password")) {
                setResetPasswordPostState(prevState => ({
                    ...prevState,
                    password2Error: err.response.data["re_new_password"] ? err.response.data["re_new_password"] : "",
                    isPasswordPostLoading: false,
                    success: false,
                }))
            } else {
                setResetPasswordPostState(prevState => ({
                    ...prevState,
                    password2Error: "",
                    isPasswordPostLoading: false,
                    success: false,
                }))
            }
            if (err.response && err.response.data.hasOwnProperty("token")) {
                setResetPasswordPostState(prevState => ({
                    ...prevState,
                    passwordErrorValidated: "Password reset link is expired. Request another reset link",
                    isPasswordPostLoading: false,
                    success: false,
                }))
            } else {
                setResetPasswordPostState(prevState => ({
                    ...prevState,
                    passwordErrorValidated: "",
                    isPasswordPostLoading: false,
                    success: false,
                }))
            }
        })
    }

    if (resetPasswordPostState.success) {
        return <ResetPasswordSuccessComponent type={"PASSWORD_RESET_SET_SUCCESS"} />
    }

    return (
        <LayoutComponentForHelmet pageTitle={`Reset Password - SpreeCine`}>
            <div className="user-gettin-main-container">
                <div className="user-getting-left">
                    <div className="left-container-data-login">
                        <h1>{t("Picked from our Great content")}</h1>
                        <div className="user-getting-login-side-content">
                        </div>
                    </div>
                </div>
                <div className="user-getting-right">
                    <form action="#" method="POST" className='login-form'>
                        <div className="login-container-v2">
                            <div className="login-content">
                                <h1 className="welcome-text">{t("Reset Password")}</h1>
                                <input
                                    type="password"
                                    placeholder={t("New Password")}
                                    name='newPassword1'
                                    className="user-login-signup-input-field"
                                    value={resetPasswordPostState.newPassword1}
                                    onChange={(e) => onResetPasswordInputFieldChange(e)}
                                />
                                {
                                    resetPasswordPostState.password1Error ?
                                        <div className='err-message font-size-14px'>{resetPasswordPostState.password1Error}</div>
                                        :
                                        null
                                }
                                <input
                                    type="password"
                                    placeholder={t("New Password again")}
                                    name='newPassword2'
                                    className="user-login-signup-input-field"
                                    value={resetPasswordPostState.newPassword2}
                                    onChange={(e) => onResetPasswordInputFieldChange(e)}
                                />
                                {
                                    resetPasswordPostState.password2Error ?
                                        <div className='err-message font-size-14px'>{resetPasswordPostState.password2Error}</div>
                                        :
                                        null
                                }
                                {
                                    resetPasswordPostState.passwordErrorValidated ?
                                        <div className='err-message font-size-14px'>{resetPasswordPostState.passwordErrorValidated}</div>
                                        :
                                        null
                                }

                                <div className="login-button-container">
                                    <button type="button" className="main-button" onClick={changePasswordOnClick}>
                                        {t("Send mail")}
                                        <LoadingIfElseErrorSvg isLoading={resetPasswordPostState.isPasswordPostLoading} errorString={resetPasswordPostState.passwordPostError} classNamesForLoading={"retry rotate"} classNamesForError={"svg-inline-top mg-left-5px fill-black"} loadingLogoBigOrSmall={"small"} retry={changePasswordOnClick} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default ResetPasswordConfirm;