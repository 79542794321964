import { useTranslation } from "react-i18next";

function CannotViewUserBlockedYou({ blockedByUsername }) {
    const { t } = useTranslation();

    return (
        <div className="blocked-container">
            <h1>{t("You are blocked by user")}</h1>
            <p>{t("Sorry, but you have been blocked by")} <span className='fw-700'>{blockedByUsername}</span> {t("and cannot access this content")}.</p>
        </div>
    )
}

export default CannotViewUserBlockedYou;