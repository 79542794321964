import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import "./about.css";
import LayoutComponentForHelmet from "../../../MainInterface/SEO/LayoutComponentForHelmet";

function AboutUs() {

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const sectionHeights = [
            0,
            document.getElementById('section-1').clientHeight,
            document.getElementById('section-2').clientHeight * 2,
        ];

        const newActiveSection = sectionHeights.findIndex((height) => scrollPosition < height);
        setActiveSection(newActiveSection + 1);
    };

    const scrollToSection = (section) => {
        const targetElement = document.getElementById(`section-${section}`);
        if (targetElement) {
            const targetY = targetElement.getBoundingClientRect().top + window.scrollY;
            const initialY = window.scrollY;
            const duration = 500;
            const startTime = performance.now();

            function scrollAnimation(currentTime) {
                const elapsedTime = currentTime - startTime;

                window.scrollTo(0, easeInOut(elapsedTime, initialY, targetY - initialY, duration));

                if (elapsedTime < duration) {
                    requestAnimationFrame(scrollAnimation);
                }
            }

            function easeInOut(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b;
            }

            requestAnimationFrame(scrollAnimation);
        }
    };


    const setActiveSection = (section) => {
        // Logic to update the component state based on the active section. BLANK FOR NOW.
    };

    return (
        <LayoutComponentForHelmet pageTitle={"About Us - SpreeCine"}>
            <div className='abs-page-main'>
                <div className="abs-page" id="section-1">
                    <div className="about-container">
                        <h1>Welcome to <span className="highlight">SpreeCine</span></h1>
                        <p>
                            Have you ever wondered "<span className='abs-section-1-q-line'>what happens after the hero goes missing in a film?</span>" Or maybe you've thought to yourself, "<span className='abs-section-1-q-line'>I could come up with a better plot than this!</span>" And do you ever wonder "<span className='abs-section-1-q-line'>What Red and Andy could have said to each other when they reunited at the beach after a significant period of time?</span>"

                            If you're looking for answers or want to unleash your creativity and design your own plot, then you've come to the right place! SpreeCine is the perfect platform for you.
                            <br />
                            <br />
                            At SpreeCine, you can engage in lively discussions and delve deep into the plots, thoughts, and endings of movies and TV shows. It doesn't matter if you're a casual viewer or a dedicated critic, everyone is welcome here!
                        </p>
                        <a href="#section-2" onClick={() => scrollToSection(2)} className="abs-button scroll-down">Next</a>
                    </div>
                </div>

                <div className="abs-page" id="section-2">
                    <div className="features-container">
                        <h1>Features</h1>
                        <p>Explore exciting features such as personalized recommendations, community discussions, and more, like.</p>
                        <ul>
                            <li>Post UpShots to encourage users to engage in conversations regarding your posted topic of movies and television shows.</li>
                            <li>Share by posting your personal analysis of plots, endings, and thoughts in UpShot's Remarks.</li>
                            <li>Users have the ability to give awards to other users for their exceptional and fitting remarks.</li>
                            <li>Engage in the StoryLine Tournament on a regular basis for the opportunity to emerge victorious by providing the most captivating plot.</li>
                            <li>Stay updated on movies and TV shows by <b>Watching</b> for monitoring discussions and reviews regarding their storylines and insights.</li>
                            <li>Invite additional users to join your Audience in order to demonstrate your exceptional Director abilities and more.</li>
                            <li>Enjoy more features with <Link to={"/premium"} className='text-underline'>SpreeCine Premium</Link>.</li>
                        </ul>
                        <a href="#section-3" onClick={() => scrollToSection(3)} className="abs-button scroll-down">Next</a>
                    </div>
                </div>

                <div className="abs-page" id="section-3">
                    <div className="contact-container-abs">
                        <h1>Contact Us</h1>
                        <p>Have questions or suggestions? <Link to={"/contact"} className='text-underline '>Reach out to us!</Link> Connect with our team and be a part of shaping the future
                            of SpreeCine.</p>
                        <a href="#section-4" onClick={() => scrollToSection(4)} className="abs-button scroll-down">Next</a>
                    </div>
                </div>

                <div className="abs-page" id="section-4">
                    <div className="contact-container-abs">
                        <h1>Credits</h1>
                        <p>
                            <div className='abt-crds-fnl-line'>
                                Thanks to our Wonderful Team to helping and contributing building SpreeCine.
                            </div>
                            <hr />
                            <div className='abt-crds-lgl-line'>
                                "This product uses the TMDB API but is not endorsed or certified by TMDB."
                            </div>
                            <img src='/about/credits/tmdbLogo.png' />
                        </p>
                        <a href="#section-1" onClick={() => scrollToSection(1)} className="abs-button scroll-down">Go to Top</a>
                    </div>
                </div>
            </div>
        </LayoutComponentForHelmet>
    );
};

export default AboutUs;