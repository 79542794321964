import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import axios from "axios";

// import Cookies from 'js-cookie';
// import CryptoJS from 'crypto-js';


import "./search.css";
import textTrimmer from "../../components/helpers/stringCutter";
import ReleaseDateNaturalTimeDisplay from "../../components/helpers/formatContentReleaseDate";
import SelectedContentDetail from "../../components/content/SelectedContentDetial";
import ThemeContext from "../../components/appearance/ThemeContext";
import UpShotListDetailContainerBox from "../../components/upshot/UpShotList/UpShotListDetailContainerBox"
// import { COOKIE_KEYS, setEncryptedCookie, getDecryptedCookie, updateEncryptedCookie } from '../../components/helpers/cookieHandler/cookieEncryption';
import { GlobalLoginRequiredModalContext } from "../../MainInterface/contexts/LoginRequired/LoginRequiredModal";
import RemarkContainer from "../../components/upshot/RemarkContainer";
import UsersListSingleComponent from "../../components/users/UsersList/UsersList";
import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";
import { defaultPlaceholderImageIfNotPresentAny } from "../constants/constants";

function SearchPage({ currentUserUsername }) {
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { theme } = useContext(ThemeContext);
    const { openModal } = useContext(GlobalLoginRequiredModalContext);
    const [query, setQuery] = useState('');
    const [activeTab, setActiveTab] = useState(1);
    const [searchResults, setSearchResults] = useState({
        isSearchError: "",

        fetchedUpShots: [],
        isUpShotNextPageAvailable: false,
        isUpShotPreviousPageAvailable: false,
        currentUpShotPage: 1,
        hasUpShotResults: false,
        isUpShotSearchLoading: false,

        fetchedRemarks: [],
        isRemarkNextPageAvailable: false,
        isRemarkPreviousPageAvailable: false,
        currentRemarkPage: 1,
        hasRemarkResults: false,
        isRemarkSearchLoading: false,

        fetchedMovies: [],
        hasMovieResults: false,
        isMovieSearchLoading: false,

        fetchedUsers: [],
        isUserNextPageAvailable: false,
        isUserPreviousPageAvailable: false,
        currentUserPage: 1,
        hasUsersResults: false,
        isUserSearchLoading: false,

        fetchedTournaments: [],
        isTournamentNextPageAvailable: false,
        isTournamentPreviousPageAvailable: false,
        currentTournamentPage: 1,
        hasTournamentsResults: false,
        isTournamentSearchLoading: false,
    })
    const [contentPaginationState, setContentPaginationState] = useState({
        currentPage: 1,
        totalPages: 0,
        hasNextPage: false,
        hasPreviousPage: false,
    })
    const [showAll, setShowAll] = useState({});

    const [contentDetailPopup, setContentDetailPopup] = useState(false);
    const [currentSelectedDetailData, setCurrentSelectedDetailData] = useState({});

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    useEffect(() => {
        if (currentUserUsername) {
            setIsUserAuthenticated(true);
        } else {
            openModal();
            setIsUserAuthenticated(false);
        }
        const queryString = searchParams.get("q");
        setQuery(queryString);
    }, [location.search]);

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (query) {
                searchQueryParamsFromDB(activeTab, query);
            }
        }, 500);

        return () => clearTimeout(typingTimeout);
    }, [query]);

    const searchQueryParamsFromDB = (activeTab, queryString) => {
        if (activeTab === 1) {
            searchQueryParamsForUpShots(queryString, searchResults.currentUpShotPage);
        } else if (activeTab === 2) {
            searchQueryParamsForRemarks(queryString, searchResults.currentRemarkPage);
        } else if (activeTab === 3) {
            searchQueryParamsForContent(queryString, contentPaginationState.currentPage);
        } else if (activeTab === 4) {
            searchQueryParamsForUsers(queryString, searchResults.currentUserPage);
        } else if (activeTab === 5) {
            searchQueryParamsForTournaments(queryString, searchResults.currentTournamentPage);
        }
    }

    const searchQueryParamsForUpShots = (queryString, page) => {
        setSearchResults(prevState => ({
            ...prevState,
            hasUpShotResults: false,
            isUpShotSearchLoading: true
        }));
        axios.get(`/api/search/search_u/?query=${queryString}&page=${page}`).then((res) => {
            setSearchResults(prevState => ({
                ...prevState,
                fetchedUpShots: res.data.results,
                isUpShotNextPageAvailable: res.data.next !== null ? true : false,
                isUpShotPreviousPageAvailable: res.data.previous !== null ? true : false,
                hasUpShotResults: true,
                isUpShotSearchLoading: false
            }));
        }).catch(() => {
            setSearchResults(prevState => ({
                ...prevState,
                hasUpShotResults: false,
                isUpShotSearchLoading: false
            }));
        })
    }
    const goToUpShotNextPreviousPage = (page) => {
        setSearchResults(prevState => ({
            ...prevState,
            currentUpShotPage: page,
        }));
        searchQueryParamsForUpShots(query, page);
    }

    const searchQueryParamsForRemarks = (queryString, page) => {
        setSearchResults(prevState => ({
            ...prevState,
            hasRemarkResults: false,
            isRemarkSearchLoading: true
        }));
        axios.get(`/api/search/search_r/?query=${queryString}&page=${page}`).then((res) => {
            setSearchResults(prevState => ({
                ...prevState,
                fetchedRemarks: res.data.results,
                isRemarkNextPageAvailable: res.data.next !== null ? true : false,
                isRemarkPreviousPageAvailable: res.data.previous !== null ? true : false,
                hasRemarkResults: true,
                isRemarkSearchLoading: false
            }));
        }).catch(() => {
            setSearchResults(prevState => ({
                ...prevState,
                hasRemarkResults: false,
                isRemarkSearchLoading: false
            }));
        })
    }

    const goToRemarkNextPreviousPage = (page) => {
        setSearchResults(prevState => ({
            ...prevState,
            currentRemarkPage: page,
        }));
        searchQueryParamsForRemarks(query, page);
    }

    const goToNextPage = (page) => {
        setContentPaginationState(prevState => ({
            ...prevState,
            currentPage: page
        }));
        searchQueryParamsForContent(query, page);
    }

    const goToPreviousPage = (page) => {
        setContentPaginationState(prevState => ({
            ...prevState,
            currentPage: page
        }));
        searchQueryParamsForContent(query, page);
    }

    const searchQueryParamsForContent = (queryString, page) => {
        setSearchResults(prevState => ({
            ...prevState,
            hasMovieResults: false,
            isMovieSearchLoading: true
        }));
        if (queryString) {
            axios.get(`/api/search/combined-search/?query=${queryString}&page=${page}`).then((res) => {
                console.log(res);
                setContentPaginationState(prevState => ({
                    ...prevState,
                    currentPage: res.data.current_page,
                    totalPages: res.data.total_pages,
                    hasNextPage: res.data.has_next_page,
                    hasPreviousPage: res.data.has_previous_page,
                }))
                setSearchResults(prevState => ({
                    ...prevState,
                    fetchedMovies: res.data.message,
                    hasMovieResults: true,
                    isMovieSearchLoading: false
                }));
                // if (res.data.message.length >= 1) {
                //     const getPreSavedSearches = getDecryptedCookie(COOKIE_KEYS.SEARCH_LIMIT);
                //     if (getPreSavedSearches === null) {
                //         const searchContentData = { "sC": 1 }
                //         setEncryptedCookie(COOKIE_KEYS.SEARCH_LIMIT, searchContentData)
                //     } else {
                //         const getData = getPreSavedSearches;
                //         if (getData.sC >= 2) {
                //             openModal();
                //         }
                //         const searchContentData = { "sC": getData.sC + 1 }
                //         updateEncryptedCookie(COOKIE_KEYS.SEARCH_LIMIT, searchContentData)
                //     }
                // }
            }).catch(() => {
                setSearchResults(prevState => ({
                    ...prevState,
                    hasMovieResults: false,
                    isMovieSearchLoading: false
                }));
            })
        }
    }

    const searchQueryParamsForUsers = (queryString, currentPage) => {
        setSearchResults(prevState => ({
            ...prevState,
            hasUsersResults: false,
            isUserSearchLoading: true
        }));
        axios.get(`/api/search/search_ur/?query=${queryString}&page=${currentPage}`).then((res) => {
            setSearchResults(prevState => ({
                ...prevState,
                fetchedUsers: res.data.results,
                isUserNextPageAvailable: res.data.next !== null ? true : false,
                isUserPreviousPageAvailable: res.data.previous !== null ? true : false,
                hasUsersResults: true,
                isUserSearchLoading: false
            }));
        }).catch(() => {
            setSearchResults(prevState => ({
                ...prevState,
                hasUsersResults: false,
                isUserSearchLoading: false
            }));
        })
    }
    const goToNextPreviousUsersPage = (page) => {
        setSearchResults(prevState => ({
            ...prevState,
            currentUserPage: page,
        }));
        searchQueryParamsForUsers(query, page);
    }

    const searchQueryParamsForTournaments = (queryString, page) => {
        setSearchResults(prevState => ({
            ...prevState,
            hasTournamentsResults: false,
            isTournamentSearchLoading: true
        }));
        axios.get(`/api/search/search_t/?query=${queryString}&page=${page}`).then((res) => {
            setSearchResults(prevState => ({
                ...prevState,
                fetchedTournaments: res.data.results,
                isTournamentNextPageAvailable: res.data.next !== null ? true : false,
                isTournamentPreviousPageAvailable: res.data.previous !== null ? true : false,
                hasTournamentsResults: true,
                isTournamentSearchLoading: false
            }));
        }).catch(() => {
            setSearchResults(prevState => ({
                ...prevState,
                hasTournamentsResults: false,
                isTournamentSearchLoading: false
            }));
        })
    }
    const goToNextPreviousTournamentPage = (page) => {
        setSearchResults(prevState => ({
            ...prevState,
            currentTournamentPage: page,
        }));
        searchQueryParamsForTournaments(query, page);
    }

    const changeTab = (tabNumber) => {
        if (tabNumber !== activeTab) {
            setActiveTab(tabNumber);
            searchQueryParamsFromDB(tabNumber, query)
        }
    };

    // const toggleShowAll = (blogId) => {
    //     setShowAll(prevState => ({
    //         ...prevState,
    //         [blogId]: !prevState[blogId]
    //     }));
    // };

    const renderTabs = () => {
        const tabs = ['UpShots', 'Remarks', 'Movies', "Users", "Tournaments"];

        return tabs.map((tab, index) => (
            <div
                key={index}
                className={`search-tab ${activeTab === index + 1 ? 'search-active search-tab-now-active' : ''}`}
                onClick={() => changeTab(index + 1)}
            >
                {t(tab)}
            </div>
        ));
    };

    const openDetailPopupAndSetData = (s) => {
        setContentDetailPopup(true);
        setCurrentSelectedDetailData(s)
    }

    if (!query) {
        return (
            <header className="search-header">{t("Try to search something...")}</header>
        )
    }

    // if (isUserAuthenticated) {
    //     return;
    // }

    return (
        <LayoutComponentForHelmet pageTitle={`Search - SpreeCine`}>
            <SelectedContentDetail
                openContentDetailPopup={contentDetailPopup}
                onHideContentDetailPopup={() => setContentDetailPopup(false)}
                data={currentSelectedDetailData}
                theme={theme}
            />

            <div className="search-tabs-container">
                <div className="search-tabs">
                    {renderTabs()}
                </div>

                <div className="search-content">
                    <div className={`search-tab-pane ${activeTab === 1 ? 'search-active' : ''}`}>
                        {
                            searchResults?.fetchedUpShots?.map((upshot, index) =>
                                <div className="upshots-list-v2" key={upshot.id}>
                                    <UpShotListDetailContainerBox
                                        index={index}
                                        res={upshot}
                                        remarkInUpShot={[]}
                                    />
                                </div>
                            )
                        }
                        {
                            !searchResults.isUpShotSearchLoading ?
                                <div className="trending-pagination:container">
                                    {
                                        searchResults.isUpShotPreviousPageAvailable ?
                                            <div className="trending-pagination:number arrow" onClick={() => goToUpShotNextPreviousPage(searchResults.currentUpShotPage - 1)}>
                                                <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-240H80v-480h80v480Zm320 0L240-480l240-240 56 56-143 144h487v80H393l144 144-57 56Z" /></svg>
                                                <span className="arrow:text">Previous</span>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        searchResults.isUpShotNextPageAvailable ?
                                            <div className="pagination:number arrow" onClick={() => goToUpShotNextPreviousPage(searchResults.currentUpShotPage + 1)}>
                                                <span className="arrow:text">Next</span>
                                                <svg className='svg-fill-white svg-inline' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M800-240v-480h80v480h-80Zm-320 0-57-56 144-144H80v-80h487L424-664l56-56 240 240-240 240Z" /></svg>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className={`search-tab-pane ${activeTab === 2 ? 'search-active' : ''}`}>
                        {
                            searchResults?.fetchedRemarks?.map((remark, index) =>
                                <div className="remark-list" key={remark.id}>
                                    <RemarkContainer remark={remark} />
                                </div>
                            )
                        }
                        {
                            !searchResults.isRemarkSearchLoading ?
                                <div className="trending-pagination:container">
                                    {
                                        searchResults.isRemarkPreviousPageAvailable ?
                                            <div className="trending-pagination:number arrow" onClick={() => goToRemarkNextPreviousPage(searchResults.currentRemarkPage - 1)}>
                                                <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-240H80v-480h80v480Zm320 0L240-480l240-240 56 56-143 144h487v80H393l144 144-57 56Z" /></svg>
                                                <span className="arrow:text">Previous</span>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        searchResults.isRemarkNextPageAvailable ?
                                            <div className="pagination:number arrow" onClick={() => goToRemarkNextPreviousPage(searchResults.currentRemarkPage + 1)}>
                                                <span className="arrow:text">Next</span>
                                                <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M800-240v-480h80v480h-80Zm-320 0-57-56 144-144H80v-80h487L424-664l56-56 240 240-240 240Z" /></svg>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className={`search-tab-pane ${activeTab === 3 ? 'search-active' : ''}`}>
                        <ul className="content-list-container">
                            {
                                searchResults?.fetchedMovies?.map((content) =>
                                    <li key={content.id}>
                                        <div className="content-image">
                                            {
                                                content.poster_path !== undefined && content.poster_path !== null ?
                                                    <img src={`https://image.tmdb.org/t/p/w200${content.poster_path}`} alt="Image 1" />
                                                    :
                                                    <img src={defaultPlaceholderImageIfNotPresentAny} alt="Image 1" />
                                            }
                                        </div>
                                        <div className="content-content-main">
                                            <h3>{content.name ? <>{content.name} ({content.original_name})</> : content.original_title}</h3>
                                            <p>{content.overview && textTrimmer(content.overview, 50)}</p>
                                            {
                                                content.release_date !== "" &&
                                                <>
                                                    {
                                                        content.media_type === "movie"
                                                            ?
                                                            <span><ReleaseDateNaturalTimeDisplay date={content.release_date} /></span>
                                                            :
                                                            <span>First air date {content.first_air_date}</span>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className="content-platform-icons">
                                            <span>{content.media_type === "movie" ? "Movie" : content.media_type === "tv" ? "TV" : content.media_type === "person" ? "Person" : ""}</span>
                                        </div>
                                        <span className="content-go-now" onClick={() => openDetailPopupAndSetData(content)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                                                <path d="M480-200v-360H120v-80h440v440h-80Zm200-200v-360H320v-80h440v440h-80Z" />
                                            </svg>
                                        </span>
                                    </li>
                                )
                            }
                        </ul>
                        {
                            !searchResults.isMovieSearchLoading ?
                                <div className="trending-pagination:container">

                                    {
                                        contentPaginationState.hasPreviousPage ?
                                            <div className="trending-pagination:number arrow" onClick={() => goToPreviousPage(contentPaginationState.currentPage - 1)}>
                                                <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-240H80v-480h80v480Zm320 0L240-480l240-240 56 56-143 144h487v80H393l144 144-57 56Z" /></svg>
                                                <span className="arrow:text">Previous</span>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        contentPaginationState.currentPage > 1 &&
                                        <div className="trending-pagination:number">
                                            <span>{contentPaginationState.currentPage - 1}</span>
                                        </div>
                                    }

                                    <div className="trending-pagination:number trending-pagination:active">
                                        {contentPaginationState.currentPage}
                                    </div>

                                    <div className="pagination:number">
                                        <span>{contentPaginationState.totalPages}</span>
                                    </div>

                                    {
                                        contentPaginationState.hasNextPage ?
                                            <div className="pagination:number arrow" onClick={() => goToNextPage(contentPaginationState.currentPage + 1)}>
                                                <span className="arrow:text">Next</span>
                                                <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M800-240v-480h80v480h-80Zm-320 0-57-56 144-144H80v-80h487L424-664l56-56 240 240-240 240Z" /></svg>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                : null
                        }

                    </div>
                    <div className={`search-tab-pane ${activeTab === 4 ? 'search-active' : ''}`}>
                        <div className="user-list-container">
                            <ul className="styled-list">
                                {
                                    searchResults?.fetchedUsers?.map((user) =>
                                        <UsersListSingleComponent userId={user.id} profileImage={user.user_profile_image} username={user.username} userFullName={user.user_profile_full_name} userJoinedDate={user.user_joined_date} />
                                    )
                                }
                            </ul>
                            {
                                !searchResults.isUserSearchLoading ?
                                    <div className="trending-pagination:container">
                                        {
                                            searchResults.isUserPreviousPageAvailable ?
                                                <div className="trending-pagination:number arrow" onClick={() => goToNextPreviousUsersPage(searchResults.currentUserPage - 1)}>
                                                    <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-240H80v-480h80v480Zm320 0L240-480l240-240 56 56-143 144h487v80H393l144 144-57 56Z" /></svg>
                                                    <span className="arrow:text">Previous</span>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            searchResults.isUserNextPageAvailable ?
                                                <div className="pagination:number arrow" onClick={() => goToNextPreviousUsersPage(searchResults.currentUserPage + 1)}>
                                                    <span className="arrow:text">Next</span>
                                                    <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M800-240v-480h80v480h-80Zm-320 0-57-56 144-144H80v-80h487L424-664l56-56 240 240-240 240Z" /></svg>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className={`search-tab-pane ${activeTab === 5 ? 'search-active' : ''}`}>
                        <div className="searched-tournament-list">
                            {
                                searchResults?.fetchedTournaments?.map((tournament) =>
                                    <div className="searched-tournament-item" key={tournament.id}>
                                        <div className="searched-tournament-info">
                                            <h3>{tournament.room_name}</h3>
                                            <p><span>{t("Date")}:</span> {tournament.tournament_created_date}</p>
                                            <p><span>{t("Tournament Active Duration")}:</span> {tournament.remaining_time}</p>
                                        </div>
                                        {tournament?.tv_movie_data && Object.keys(tournament?.tv_movie_data).length > 0 && (
                                            Object.keys(tournament?.tv_movie_data)?.map((keyName, i) => (
                                                <div className="quoted-info">
                                                    <img src={`https://image.tmdb.org/t/p/w200/${tournament?.tv_movie_data[keyName].poster_path}`} alt="Tournament 1 Image" />
                                                    <div className="quoted-text">
                                                        {tournament?.tv_movie_data[keyName].original_title}
                                                        <br />
                                                        <small>{textTrimmer(tournament?.tv_movie_data[keyName].content_plot, 150)}</small>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                        <span className={`tournament-status ${tournament.tournament_status.className}`}>{t(tournament.tournament_status.status)}</span>
                                        <Link to={`/tournament/discussion/${tournament.id}/`} className="searched-tournament-register-button">{t("Enter Tournament")}</Link>
                                    </div>
                                )
                            }

                            {
                                !searchResults.isTournamentSearchLoading ?
                                    <div className="trending-pagination:container">
                                        {
                                            searchResults.isTournamentPreviousPageAvailable ?
                                                <div className="trending-pagination:number arrow" onClick={() => goToNextPreviousTournamentPage(searchResults.currentTournamentPage - 1)}>
                                                    <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-240H80v-480h80v480Zm320 0L240-480l240-240 56 56-143 144h487v80H393l144 144-57 56Z" /></svg>
                                                    <span className="arrow:text">Previous</span>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            searchResults.isTournamentNextPageAvailable ?
                                                <div className="pagination:number arrow" onClick={() => goToNextPreviousTournamentPage(searchResults.currentTournamentPage + 1)}>
                                                    <span className="arrow:text">Next</span>
                                                    <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M800-240v-480h80v480h-80Zm-320 0-57-56 144-144H80v-80h487L424-664l56-56 240 240-240 240Z" /></svg>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default SearchPage;