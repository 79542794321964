import React, { useState } from "react";
import { useEffect } from "react";

import "./info-popup.css";

function InfoPopup({ steps, browserStorageInfoValidationKey }) {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [closingTime, setClosingTime] = useState(10);
    const totalSteps = steps.length;

    const nextStep = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
            setClosingTime(10);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
            setClosingTime(10);
        }
    };

    const closePopup = () => {
        setPopupOpen(false);
    }

    useEffect(() => {
        const getPopupStatusFromLocalStorage = localStorage.getItem(browserStorageInfoValidationKey);
        if (getPopupStatusFromLocalStorage === null) {
            setPopupOpen(true);
            runDelayedFunction()
        } else {
            if (!JSON.parse(localStorage.getItem(browserStorageInfoValidationKey))) {
                setPopupOpen(true)
                runDelayedFunction()
            }
        }
    }, [])

    const runDelayedFunction = () => {
        setTimeout(() => {
            localStorage.setItem(browserStorageInfoValidationKey, true);
        }, 2000);
    };

    useEffect(() => {
        if (isPopupOpen) {
            const timer = setTimeout(() => {
                setClosingTime((prevTime) => prevTime - 1);
            }, 1000);

            if (closingTime === 0) {
                closePopup();
            }
            return () => clearTimeout(timer)
        }
    }, [isPopupOpen, closingTime])

    useEffect(() => {
        if (isPopupOpen) {
            const initialTimer = setTimeout(() => {
                closePopup();
            }, closingTime * 1000);

            return () => clearTimeout(initialTimer);
        }
    }, [isPopupOpen, closingTime])

    return (
        <>
            <div className={`info-popup-container ${isPopupOpen ? 'active' : ''}`}>
                {/* <div className="info-popup-title">Step {currentStep} of {totalSteps}</div> */}
                <div className="info-popup-content">
                    {/* {steps.map((content, index) => (
                        <p key={index} style={{ display: currentStep === index + 1 ? 'block' : 'none' }}>{content}</p>
                    ))} */}
                    {steps.map((step, index) => (
                        <div key={index} style={{ display: currentStep === index + 1 ? 'block' : 'none' }}>
                            <h2>{step.title}</h2>
                            <p>{step.description}</p>
                        </div>
                    ))}
                </div>
                <div className="popup-dots">
                    {[...Array(totalSteps)].map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${currentStep === index + 1 ? 'active-dot' : ''}`}
                        ></span>
                    ))}
                </div>
                {isPopupOpen && <span className="closing-text"><small>Closing in {closingTime} seconds</small></span>}
                <div className="info-popup-button-container">
                    {currentStep < totalSteps ? <button className="info-popup-button" onClick={nextStep}>Next</button> : <button className="info-popup-button info-popup-close" onClick={closePopup}>Close</button>}
                    {currentStep > 1 && <button className="info-popup-button" onClick={prevStep}>Previous</button>}
                </div>
            </div>
        </>
    )
}

export default InfoPopup;