import { Menu, Item, Separator, Submenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { Link } from "react-router-dom";

import ThemeContext from '../appearance/ThemeContext';
import { useContext } from 'react';
import { useTranslation } from "react-i18next";

const RemarkContextMenu = ({ id, userId, userUsername, data, openQuickShareModal, openShareModal, openReportModal, isContentUserRequestUser, addMeInUserAudience, editContent, deleteContent, menuContentType, isQuickShareDisabled, isEditDisabled, editLabel, deleteLabel }) => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext)
    // function displayMenu(e) {
    //     // put whatever custom logic you need
    //     // you can even decide to not display the Menu
    //     show({
    //         event: e,
    //     });
    // }

    return (
        <div>
            <Menu id={`${menuContentType}_${id}`}>
                {
                    !isQuickShareDisabled
                    &&
                    <Item onClick={openQuickShareModal}>
                        {t("Quick Share")}
                    </Item>
                }
                <Item onClick={openShareModal}>
                    {t("Share")}
                </Item>
                {
                    !isContentUserRequestUser &&
                    <Item onClick={addMeInUserAudience}>
                        <span onClick={() => addMeInUserAudience(data.remarked_by, data.current_user, data.profile_audience_visibility)}>Be in "User's" Audience</span>
                    </Item>
                }
                {
                    !isEditDisabled &&
                    <>
                        {
                            isContentUserRequestUser ?
                                <Item onClick={editContent}>
                                    {t("Edit")} {editLabel}
                                </Item>
                                :
                                null
                        }
                    </>
                }
                {
                    isContentUserRequestUser ?
                        <Item onClick={deleteContent} className='hover-red'>
                            {t("Delete")} {deleteLabel}
                        </Item>
                        :
                        null
                }
                {
                    sessionStorage.getItem("aamt") !== null && sessionStorage.getItem("aamt").length === 13 ?
                        <Item><Link to={`/admin/dashboard/user/${sessionStorage.getItem("aamt")}/${userId}/${userUsername}/`}>Moderation Tools</Link></Item>
                        :
                        null
                }
                <Separator />
                <Item onClick={openReportModal} className='hover-light-blue'>
                    {t("Report")} {deleteLabel}
                </Item>
            </Menu>
        </div>
    );
}

export default RemarkContextMenu;