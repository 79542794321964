import * as React from "react";
const UniqueUsersIcon = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="60%"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="#00000"
            opacity={1}
            stroke="none"
            d=" M0.999999,331.468658  C1.661162,330.427612 2.728157,329.968964 2.925059,329.266449  C11.174297,299.835754 30.879667,282.377686 60.029865,275.232086  C65.862534,273.802307 72.179016,274.130096 78.277809,274.047821  C90.395248,273.884308 102.516464,273.999451 115.397446,273.999451  C107.904846,291.654388 108.969048,309.693787 108.985855,327.638306  C109.027176,371.765686 108.947525,415.893311 109.074249,460.020355  C109.088120,464.851166 110.129555,469.678986 110.751785,474.998962  C105.184303,474.998962 99.720070,474.998993 94.255836,474.998993  C79.602203,474.998993 64.947876,474.910217 50.295147,475.025116  C36.918587,475.130005 24.260973,472.367889 14.694218,462.694427  C9.714712,457.659332 6.826223,450.557465 2.983809,444.395020  C2.566177,443.725220 2.086217,443.094269 1.317585,442.722656  C1.000000,405.979095 1.000000,368.958221 0.999999,331.468658  z"
        />
        <path
            fill="#00000"
            opacity={1}
            stroke="none"
            d=" M513.000000,442.531342  C512.329956,443.531738 511.314209,443.926117 511.040436,444.615265  C504.285278,461.620117 492.415558,472.349792 473.774323,473.913055  C462.737610,474.838593 451.611359,474.813843 440.521973,474.961517  C428.222504,475.125397 415.919220,474.998993 403.153168,474.998993  C403.802155,470.016998 404.909393,465.421234 404.921509,460.822571  C405.048492,412.535431 404.834808,364.246918 405.103638,315.960938  C405.182159,301.851593 403.798492,288.123291 398.857056,274.311737  C400.805115,274.191772 402.365021,274.014923 403.925232,274.012299  C417.079346,273.990021 430.246826,273.627258 443.384308,274.095367  C459.226685,274.659882 473.361267,280.291229 485.757568,290.244873  C498.829742,300.741211 507.460541,314.022034 511.257019,330.400665  C511.350311,330.803131 511.931061,331.092590 512.642212,331.217377  C513.000000,368.020905 513.000000,405.041779 513.000000,442.531342  z"
        />
        <path
            fill="#00000"
            opacity={1}
            stroke="none"
            d=" M175.030243,244.993637  C187.440094,237.687119 200.717209,235.116409 214.508682,235.045837  C243.505768,234.897446 272.506256,234.792099 301.501160,235.086121  C316.690399,235.240128 330.950714,239.223267 343.688599,247.913940  C361.300018,259.929688 371.710266,276.665833 375.877411,297.346466  C376.846802,302.157318 376.947266,307.201355 376.954926,312.138885  C377.029755,360.301849 376.856445,408.465729 377.098663,456.627594  C377.160919,469.008881 368.463074,475.088135 358.357147,475.068237  C290.695740,474.935028 223.033920,474.956787 155.372406,475.044983  C145.353485,475.058044 136.882996,469.057373 136.927124,456.821320  C137.103851,407.825592 136.533035,358.822693 137.180038,309.834869  C137.551254,281.728119 150.335968,259.813965 175.030243,244.993637  z"
        />
        <path
            fill="#00000"
            opacity={1}
            stroke="none"
            d=" M169.995941,108.081894  C174.436172,90.745270 182.669250,76.133911 195.419434,63.985561  C207.483780,52.490681 221.578293,44.859352 237.700302,41.298504  C258.054962,36.802803 278.092834,39.050812 296.577515,48.415077  C318.680054,59.612144 334.359161,77.112717 341.933624,101.134132  C349.396088,124.800232 347.772430,148.016464 336.379089,169.966583  C322.952393,195.834106 301.181824,211.704620 272.657684,216.733887  C245.649918,221.495804 220.982849,214.893021 199.541809,197.129028  C182.652771,183.136398 172.688995,165.299042 169.038849,143.878372  C167.028320,132.079681 167.195938,120.232620 169.995941,108.081894  z"
        />
        <path
            fill="#00000"
            opacity={1}
            stroke="none"
            d=" M384.429321,128.206207  C394.775238,124.095123 405.111633,121.577080 415.890167,122.053467  C441.673370,123.193016 465.462250,139.262344 475.059113,163.932983  C484.085083,187.136093 481.303284,209.001938 466.885681,229.092682  C456.408051,243.693161 441.882629,252.167343 424.024414,255.060364  C405.391663,258.078888 388.378784,254.342773 373.339600,243.027145  C358.858643,232.131561 349.990051,217.695679 346.899719,199.591141  C344.541351,185.774887 346.823883,172.693573 352.441589,160.302826  C357.904694,148.253159 366.758575,138.779007 378.242065,131.946548  C380.202820,130.779938 382.134216,129.564072 384.429321,128.206207  z"
        />
        <path
            fill="#00000"
            opacity={1}
            stroke="none"
            d=" M159.279785,222.205963  C149.044678,238.979248 135.049911,250.272079 116.035873,254.402054  C94.737099,259.028290 75.257607,254.674713 58.300995,240.745438  C40.043331,225.747437 32.225117,205.915878 34.193233,182.776566  C35.833141,163.496033 44.902107,147.702698 60.496532,135.730087  C72.986740,126.140724 87.115143,121.511475 102.566521,122.143272  C124.953087,123.058678 143.052841,132.544586 156.115189,151.234894  C164.617676,163.400726 168.313248,176.884460 167.910095,191.467850  C167.613144,202.209122 164.678772,212.425339 159.279785,222.205963  z"
        />
    </svg>
);
export default UniqueUsersIcon;
