
const pageNotFoundLines = [
    "404: The Matrix has you, but this page doesn't.",
    "Error 404: E.T. couldn't phone home, and this page can't load.",
    "Page not found: Like tears in rain, lost in the vastness of cyberspace.",
    "404: Houston, we have a problem. The page you're looking for is lost in space.",
    "This is not the page you're looking for. Move along. (Error 404)",
    "404: Inception failed. You're stuck in a dream within a dream, and this page is nowhere to be found.",
    "Page not found: The cake is a lie, and so is the link you clicked.",
    "Error 404: You can't handle the truth, and apparently, you can't handle this page either.",
    "Page not found: Just like Jack, this page couldn't stay on the door in the vast ocean of the internet.",
    "404: There's no place like home, and there's no page like the one you're trying to find.",
    "Error 404: The force is not strong with this page.",
    "This page has gone to the Upside Down. Error 404: Stranger Things have happened.",
    "404: The page is lost in translation, just like Bill Murray in Tokyo.",
    "Page not found: Looks like the Shawshank Redemption tunnel collapsed. No escape, no page.",
    "Error 404: The page is on a mission impossible - can't be found or self-destruct.",
    "404: This page is more elusive than the briefcase in Pulp Fiction.",
    "Page not found: The Jurassic Park fence is broken, and so is this link.",
    "404: The page is in a galaxy far, far away. May the force be with you in finding it.",
    "Page not found: Like a Hobbit's journey, this page is unexpectedly missing from the Shire of the internet.",
    "404: This page is not the droid you're looking for. Move along, move along.",
    "Error 404: You can't click your heels three times to get to this missing page, Dorothy.",
    "Page not found: The page is trapped in a Groundhog Day loop, repeating the same error over and over.",
    "404: This page is as elusive as a leprechaun's pot of gold at the end of the internet rainbow.",
    "Error 404: This page is like the Titanic – it sank without leaving a trace.",
    "Page not found: This is Sparta! But unfortunately, the page is nowhere to be found.",
    "404: The page is lost in the maze of the internet, just like the characters in The Maze Runner.",
    "This page is stuck in the Twilight Zone. Error 404: Dimension not found.",
    "Error 404: The page is frozen in time, like the passengers on the Orient Express.",
    "Page not found: The page is as elusive as the brief appearance of Stan Lee in Marvel movies.",
    "404: The page is on a top-secret mission with Ethan Hunt – impossible to locate.",
    "This is not the page you're looking for. These aren't the droids you're looking for. (Error 404)",
    "404: The page is lost in the quantum realm, where links defy the laws of the internet.",
    "Page not found: The page is more elusive than a Pokemon in the tall grass.",
    "Error 404: The page is as gone as the dinosaurs in Jurassic World.",
    "404: The page is like a character in a Christopher Nolan film – lost in a complex plot twist.",
    "Page not found: This page is as mysterious as the monolith in 2001: A Space Odyssey.",
    "Error 404: The page is in a galaxy far, far away. Use the Force to locate it.",
    "404: The page is as hidden as the treasure in The Goonies.",
    "Page not found: The page has gone rogue, just like Jason Bourne.",
    "Error 404: The page is as vanished as Marty McFly's family photo in Back to the Future.",
    "404: The page is lost in the sea of pixels, like Nemo in Finding Nemo.",
    "Page not found: The page is as elusive as the resolution to Inception's spinning top.",
    "Error 404: The page is as elusive as the black pearl in Pirates of the Caribbean.",
    "404: The page is lost in the desert, searching for water like Mad Max.",
    "Page not found: The page is as missing as the last Horcrux in Harry Potter.",
    "Error 404: The page is as elusive as the Tesseract in the Marvel Cinematic Universe."
]

export default pageNotFoundLines;