import { useEffect, useState } from "react";
import { DEBUG, defaultBackendWebSocketOrigin } from "../../components/constants/constants";

const WebSocketComponent = ({ currentUserUsername, setUnreadNotificationsCount, setEQAvailable }) => {
    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 3;

    //     // useEffect(() => {
    //     //     const socket = new WebSocket(`wss://127.0.0.1:8000/ws/notifications/?token=${localStorage.getItem("token")}`);

    //     //     socket.onopen = () => {
    //     //         console.log("WebSocket connection established");
    //     //     };
    //     //     socket.onmessage = (event) => {
    //     //         console.log(event.data);
    //     //         console.log(JSON.parse(event.data))
    //     //         // setGeneralNotificationState(prevState => ({
    //     //         //     ...prevState,
    //     //         //     unreadNotificationsCount: JSON.parse(event.data).total_unreads
    //     //         // }))
    //     //         setUnreadNotificationsCount(JSON.parse(event.data).total_unreads)
    //     //     };
    //     //     socket.onclose = (event) => {
    //     //         console.log("WebSocket connection closed")
    //     //     }

    //     //     return () => {
    //     //         socket.close();
    //     //     }
    //     // }, [])

    const connectWebSocket = () => {
        const disableWebSockets = true;
        let socket;
        if (!disableWebSockets) {
            if (DEBUG) {
                socket = new WebSocket(`ws://127.0.0.1:8000/ws/notifications/?token=${localStorage.getItem("token")}&random=${Math.random()}`);
            } else {
                socket = new WebSocket(`wss://api.spreecine.com/ws/notifications/?token=${localStorage.getItem("token")}&random=${Math.random()}`);
            }

            socket.onopen = () => {
                setIsConnected(true);
                setRetryCount(0);
            }
            socket.onmessage = (event) => {
                console.log(event)
                console.log("Started")
                setUnreadNotificationsCount(JSON.parse(event.data).total_unreads);
                setEQAvailable(JSON.parse(event.data).q_unreads);
            };
            socket.onclose = () => {
                console.log("Closed")
                setIsConnected(false);
                if (currentUserUsername) {
                    if (retryCount < maxRetries) {
                        setTimeout(() => {
                            setRetryCount(prevCount => prevCount + 1);
                            connectWebSocket();
                        }, 10000);
                    }
                }
            };
            setSocket(socket);
        }
    }

    useEffect(() => {
        // if (currentUserUsername) {
        // console.log("Closed")
        if (!socket || socket.readyState === WebSocket.CLOSED) {
            connectWebSocket();
        }
        // }

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [socket]);
}

// const WebSocketComponent = ({ setGeneralNotificationState }) => {
//     useEffect(() => {
//         const socket = new WebSocket(`wss://127.0.0.1:8000/ws/notifications/`);

//         socket.onopen = () => {
//             socket.send(JSON.stringify({ type: "auth", token: localStorage.getItem("token") }))
//         };
//         socket.onmessage = (event) => {
//             setGeneralNotificationState(prevState => ({
//                 ...prevState,
//                 unreadNotificationsCount: JSON.parse(event.data).total_unreads
//             }))
//         };
//         socket.onclose = (event) => {
//         }

//         return () => {
//             socket.close();
//         }
//     }, [])
// }

export default WebSocketComponent;