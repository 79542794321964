import React, { useState, useEffect } from "react";
import { EncryptStorage } from 'encrypt-storage';

import ThemeContext from "./ThemeContext";
import axios from "axios";

const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState("dark");

    useEffect(() => {
        const encryptStorage = new EncryptStorage('encryptedThemeIdentifier', {
            storageType: "localStorage"
        });
        if (encryptStorage.getItem("theme") === undefined) {
            axios.get(`/api/users/primary/theme/get/`).then((res) => {
                if (res.data.iua) {
                    if (!res.data.dark_mode) {
                        setTheme("light");
                        toggleTheme("light");
                    } else {
                        setTheme("dark");
                        toggleTheme("dark");
                    }
                } else {
                    setTheme("dark");
                }
            }).catch(() => {
                setTheme("dark");
                toggleTheme("dark");
            })
        } else {
            setTheme(!encryptStorage.getItem("theme") || "dark");
        }
    }, [])

    const toggleTheme = (theme) => {
        const newTheme = theme;
        setTheme(newTheme);

        const encryptStorage = new EncryptStorage('encryptedThemeIdentifier', {
            storageType: "localStorage"
        });

        encryptStorage.setItem("theme", newTheme);
        axios.patch(`/api/users/primary/theme/update/`, { "dark_mode": newTheme === "dark" ? "True" : "False" }).then(() => {
        }).catch(() => {
        })
    };

    useEffect(() => {
        document.documentElement.setAttribute("data-theme", theme);
        // document.documentElement.setAttribute("class", theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeProvider;