import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./userDashboard.css";
import axios from "axios";


function UserDashboard() {
    const [currentUserId, setUserCurrentId] = useState(0);
    const [currentUsername, setCurrentUsername] = useState("");
    const [userContentData, setUserContentData] = useState([]);
    const [blockedUser, setBlockedUser] = useState(0);
    const [reportedItems, setReportedItems] = useState({
        reportesSent: 0,
        reportesReceived: 0,
    });
    const [moderationHistory, setModerationHistory] = useState(0);
    const params = useParams();

    useEffect(() => {
        axios.get(`/dashboard/admin/user/mod/home/list/${params.userId}/${params.id}`).then((res) => {
            console.log(res)
            setUserCurrentId(res.data.message[0].id)
            setCurrentUsername(res.data.message[1].username)
            setUserContentData(res.data.message[2].content);
            setBlockedUser(res.data.message[3].blocked_users);
            setReportedItems({
                reportesSent: res.data.message[4].reports_sent,
                reportesReceived: res.data.message[4].reports_received,
            });
            setModerationHistory(res.data.message[5].moderation_history)
        }).catch((err) => {
            setUserCurrentId(0);
            setCurrentUsername("");
            setUserContentData([]);
            setBlockedUser(0);
            setReportedItems({ reportesSent: 0, reportesReceived: 0 });
            setModerationHistory(0);
        })
    }, [params])

    return (
        <>
            {
                currentUserId !== 0 ?
                    <>
                        <div className="mod-header">
                            <h2>{currentUsername}'s Moderation Page</h2>
                        </div>
                        <div className="mod-container">
                            <div>
                                <div className="mod-section-container">
                                    <div className="mod-count">
                                        <span>{currentUsername}'s Moderation History: {moderationHistory}</span>
                                    </div>
                                    <button className="mod-button"><Link to={`/admin/dashboard/user/moderation-history/${sessionStorage.getItem("aamt")}/${currentUserId}/${currentUsername}/`}>View all Moderation History</Link></button>
                                </div>
                            </div>
                            <div>
                                <div className="mod-section-container">
                                    <div className="mod-count">
                                        <span>{currentUsername}'s Blocked Users: {blockedUser}</span>
                                    </div>
                                    <button className="mod-button"><Link to={`/admin/dashboard/user/blocked-users/${sessionStorage.getItem("aamt")}/${currentUserId}/${currentUsername}/`}>View all Blocked Users</Link></button>
                                </div>
                            </div>
                            <div>
                                <div className="mod-section-container">
                                    <div className="mod-count">
                                        <span>{currentUsername}'s Post History: 5</span>
                                    </div>
                                    <button className="mod-button"><Link to={`/admin/dashboard/user/post-history/${sessionStorage.getItem("aamt")}/${currentUserId}/${currentUsername}/`}>View Post History</Link></button>
                                </div>
                                <div className="mod-section">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>UpShots</th>
                                                <th>Remarks</th>
                                                <th>Comments</th>
                                                <th>WT</th>
                                                <th>Favourites</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {
                                                    userContentData?.map((data, index) =>
                                                        <td>{Object.values(data)[0]}</td>
                                                    )
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div>
                                <div className="mod-section-container">
                                    <div className="mod-count">
                                        <span>{currentUsername}'s Reported Items: 5</span>
                                    </div>
                                    <button className="mod-button"><Link to={`/admin/dashboard/user/reporteds/${sessionStorage.getItem("aamt")}/${currentUserId}/${currentUsername}/`}>View all Reported Items</Link></button>
                                </div>
                                <div className="mod-section">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Reports sent</th>
                                                <th>Reports received</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>{reportedItems.reportesSent}</th>
                                                <th>{reportedItems.reportesReceived}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <h2 className="err-message clr-red">Something went wrong</h2>
            }
        </>
    )
}

export default UserDashboard;