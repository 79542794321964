import { useContext } from "react";

import { SpreeCinePremiumLemonSqueenzyButtonLink } from "../constants/constants";
import { GlobalLoginRequiredModalContext } from "../../MainInterface/contexts/LoginRequired/LoginRequiredModal";

const CheckButton = ({ userOnPremium, currentUserUsername }) => {
    const { openModal } = useContext(GlobalLoginRequiredModalContext);

    if (userOnPremium) {
        return <button type="button" className="get-premium-button">You're already on Premium</button>
    } else {
        if (currentUserUsername) {
            return <a className="get-premium-button lemonsqueezy-button" href={`${SpreeCinePremiumLemonSqueenzyButtonLink}`}>Get it for $2.99/mo</a>
        } else {
            return <button type="button" className="get-premium-button" onClick={openModal}>Get it for $2.99/mo</button>
        }
    }
}

export default CheckButton;