import * as React from "react";
const MovieMavenIcon = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width={props.width}
        height={props.height}
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        className={props.className}
        {...props}
    >
        <path
            fill="#663300"
            opacity={1}
            stroke="none"
            d=" M248.830826,443.196716  C253.963455,443.144073 259.096100,443.091461 264.977478,443.373779  C287.880920,446.099121 305.409851,455.765106 316.607910,475.686218  C317.326691,476.964905 319.739655,477.895447 321.393890,477.941925  C329.533844,478.170624 337.683807,478.033630 345.830170,478.048340  C376.915680,478.104431 408.001221,478.169159 439.080261,478.684814  C439.049194,490.425964 439.024597,501.712982 439.000000,513.000000  C389.979095,513.000000 340.958221,513.000000 291.468689,512.563721  C291.105011,494.664001 275.352081,478.470306 256.298096,478.891846  C238.590775,479.283600 222.558533,494.446716 223.000000,513.000000  C173.979111,513.000000 124.958214,513.000000 75.455780,512.531494  C74.954033,500.785522 74.933815,489.508026 75.384033,478.158813  C114.483925,478.073273 153.113464,478.022461 191.742645,478.113861  C194.937744,478.121399 196.727722,477.296814 198.380936,474.356445  C206.335556,460.208740 218.427078,451.056671 233.921631,446.488861  C238.791626,445.053192 243.855621,444.275543 248.830826,443.196716  z"
        />
        <path
            fill="#ffb61a"
            opacity={1}
            stroke="none"
            d=" M262.539368,1.000000  C264.361450,6.394134 265.343811,11.920426 267.167908,17.153286  C270.386383,26.386288 279.254303,34.634178 290.555939,34.970203  C294.381653,35.083950 297.830109,36.091881 299.705933,39.501709  C301.606171,42.955883 301.844025,47.072426 298.864288,49.884136  C296.715515,51.911728 293.373718,53.801811 290.560394,53.807068  C276.950500,53.832508 266.768799,65.994637 266.010315,79.086754  C265.644562,85.399246 263.017761,88.022125 256.895416,87.999763  C250.846680,87.977669 248.391586,85.410690 247.987518,78.930847  C247.185425,66.068253 237.131775,53.964760 223.315567,53.855068  C215.033829,53.789322 211.184601,47.090691 214.010468,40.289158  C215.695679,36.233055 219.181076,35.225788 223.069321,35.010490  C236.845490,34.247681 246.357101,24.218575 248.120209,10.349350  C248.504196,7.328624 250.416260,4.502146 251.312256,1.293101  C254.692917,1.000000 258.385834,1.000000 262.539368,1.000000  M254.577103,49.054157  C255.429016,50.082668 256.280914,51.111183 256.814056,51.754852  C259.632904,49.122417 262.183594,46.740406 263.574036,45.441917  C261.399231,42.620296 259.158905,39.713661 257.676605,37.790501  C254.952484,40.346104 252.373062,42.765961 249.861557,45.122089  C250.770004,45.868675 252.401154,47.209213 254.577103,49.054157  z"
        />
        <path
            fill="#804000"
            opacity={1}
            stroke="none"
            d=" M439.468658,513.000000  C439.024597,501.712982 439.049194,490.425964 439.536316,478.608124  C457.336792,478.011566 474.674683,477.945862 492.012604,477.880127  C497.457062,477.880127 502.901489,477.880127 509.028503,477.880127  C509.028503,489.621521 509.028503,500.842255 509.014252,512.531494  C485.979095,513.000000 462.958221,513.000000 439.468658,513.000000  z"
        />
        <path
            fill="#804000"
            opacity={1}
            stroke="none"
            d=" M74.913597,478.230560  C74.933815,489.508026 74.954033,500.785522 74.987122,512.531494  C51.979103,513.000000 28.958210,513.000000 5.468657,513.000000  C5.000000,501.573639 5.000000,490.147278 5.000000,477.940430  C10.572071,477.940430 15.813449,477.940430 21.988058,477.975769  C40.252060,478.084290 57.582829,478.157410 74.913597,478.230560  z"
        />
        <path
            fill="#ccff66"
            opacity={1}
            stroke="none"
            d=" M269.766174,178.267319  C279.461365,186.219208 287.829742,194.917389 294.587646,205.092377  C301.460663,215.440689 305.959106,226.473419 307.190918,239.131195  C308.699646,254.634705 304.390228,268.233917 297.217194,281.572235  C291.356720,292.470032 283.671814,301.907654 274.899475,310.324829  C266.875732,318.023743 257.795959,324.622009 248.213638,332.501129  C248.554718,327.639191 248.832108,324.467773 248.990189,321.290466  C249.544144,310.156647 246.313324,299.791107 241.913208,289.834137  C233.537811,270.881531 221.796982,254.071289 207.514725,239.141678  C195.412857,226.491364 182.847855,214.282349 170.424561,201.941727  C153.344559,184.975403 141.646881,164.826630 137.277176,141.214294  C134.065735,123.860779 135.495407,106.309425 140.365005,89.158661  C147.288071,64.775681 159.211624,43.067833 175.076828,23.431808  C179.053925,18.509455 183.148911,13.682346 187.767181,8.115362  C186.365219,16.103071 185.294998,22.924431 183.952789,29.691845  C181.428482,42.419483 183.721329,54.751812 187.433350,66.780975  C194.333298,89.141029 206.076065,108.943825 221.242004,126.647873  C232.249374,139.497406 243.630402,152.034698 255.094711,164.481033  C259.548645,169.316528 264.689117,173.519669 269.766174,178.267319  M248.238022,221.364929  C248.238022,217.437271 248.238022,213.509598 248.238022,209.505493  C242.095947,209.505493 236.708649,209.505493 231.219986,209.505493  C231.219986,215.298370 231.219986,220.727844 231.219986,226.762543  C235.567093,226.762543 239.693497,226.563095 243.790665,226.823471  C247.285889,227.045563 248.759155,225.806320 248.238022,221.364929  M274.254120,237.752686  C273.653900,236.920090 273.080811,235.389755 272.449158,235.365204  C267.376007,235.167984 262.291779,235.254883 257.291870,235.254883  C257.291870,241.451340 257.291870,246.877121 257.291870,252.452194  C263.002136,252.452194 268.400238,252.452194 274.268188,252.452194  C274.268188,247.645920 274.268188,243.176743 274.254120,237.752686  z"
        />
        <path
            fill="#ffffe6"
            opacity={1}
            stroke="none"
            d=" M75.384033,478.158813  C57.582829,478.157410 40.252060,478.084290 22.454451,477.945648  C22.002111,469.553497 22.016611,461.226837 22.548206,452.467896  C42.692158,452.061981 62.319004,452.096832 81.945869,452.112091  C98.246681,452.124786 114.548035,452.172546 130.847504,452.024017  C132.465942,452.009277 135.392868,450.854584 135.468033,450.025543  C135.909775,445.152252 135.685211,440.218536 135.685211,435.085907  C97.288452,435.085907 59.715199,435.085907 22.141945,435.085907  C22.141945,420.488770 22.141945,405.891602 22.141945,391.009399  C30.405323,391.722473 38.359844,392.270020 46.279892,393.133667  C53.383621,393.908264 60.452465,395.008972 67.531273,396.004425  C78.446770,397.539429 89.372787,399.009033 100.266136,400.689209  C110.970695,402.340302 121.722511,403.831909 132.306854,406.083344  C151.327515,410.129272 170.477722,413.867554 189.123535,419.284271  C205.659027,424.087891 221.629074,430.876495 237.742569,437.065277  C241.427124,438.480438 244.675751,441.030640 248.477905,443.127594  C243.855621,444.275543 238.791626,445.053192 233.921631,446.488861  C218.427078,451.056671 206.335556,460.208740 198.380936,474.356445  C196.727722,477.296814 194.937744,478.121399 191.742645,478.113861  C153.113464,478.022461 114.483925,478.073273 75.384033,478.158813  z"
        />
        <path
            fill="#ffffe6"
            opacity={1}
            stroke="none"
            d=" M492.006958,477.422485  C474.674683,477.945862 457.336792,478.011566 439.542786,478.153992  C408.001221,478.169159 376.915680,478.104431 345.830170,478.048340  C337.683807,478.033630 329.533844,478.170624 321.393890,477.941925  C319.739655,477.895447 317.326691,476.964905 316.607910,475.686218  C305.409851,455.765106 287.880920,446.099121 265.450317,443.451813  C287.846008,429.187378 313.253204,422.123596 338.591980,415.366882  C356.951263,410.471313 375.837280,407.482422 394.563324,404.052124  C410.812805,401.075470 427.134613,398.443604 443.487701,396.100708  C453.666840,394.642273 463.969879,394.063660 474.205170,392.975891  C479.987061,392.361420 485.744354,391.515533 491.979889,390.713837  C491.979889,405.593079 491.979889,419.869110 491.460510,434.586884  C457.508606,435.005524 424.076050,434.979858 390.643524,434.960510  C377.404083,434.952850 377.487518,434.954956 378.184906,448.473999  C378.332306,451.332001 379.355164,452.158325 382.075287,452.150391  C418.666962,452.043488 455.258881,452.013519 491.850739,451.964478  C491.900909,460.297913 491.951080,468.631348 492.006958,477.422485  M351.533051,452.111206  C354.602875,452.111206 357.672699,452.111206 360.681213,452.111206  C360.681213,445.920105 360.681213,440.679565 360.681213,435.423584  C354.934113,435.423584 349.570618,435.423584 344.296417,435.423584  C344.296417,441.135315 344.296417,446.376343 344.296417,452.111420  C346.643005,452.111420 348.605164,452.111420 351.533051,452.111206  z"
        />
        <path
            fill="#D476E2"
            opacity={1}
            stroke="none"
            d=" M475.158997,59.035225  C470.866486,63.130585 466.396484,67.062164 462.381348,71.413452  C461.043762,72.863014 460.112335,75.464500 460.308105,77.407066  C460.912231,83.401978 462.307556,89.313736 463.040619,95.301247  C463.247589,96.991638 462.753632,99.441353 461.616699,100.405434  C460.738495,101.150146 458.152496,100.527237 456.675629,99.800957  C451.823547,97.414879 447.085663,94.783440 442.393585,92.090027  C439.502625,90.430511 436.983765,90.018906 433.861603,91.938965  C428.986755,94.936905 423.842346,97.528992 418.663239,99.978195  C417.285492,100.629723 414.700165,100.818382 413.854553,99.997948  C412.685272,98.863441 411.975098,96.471298 412.229858,94.794197  C413.156769,88.692337 414.715790,82.687294 415.674225,76.588821  C415.874054,75.317337 414.837128,73.511711 413.839630,72.451241  C409.741760,68.094551 405.439087,63.930527 401.320892,59.098999  C407.145752,57.659184 412.851746,56.740776 418.580597,55.999329  C422.382629,55.507259 425.386780,54.451042 426.983307,50.337746  C428.783539,45.699707 431.216980,41.287521 433.624420,36.914406  C434.665405,35.023476 436.331543,33.476677 438.156189,31.228344  C439.765503,33.569695 440.939362,34.953609 441.754608,36.523746  C444.286682,41.400509 446.471985,46.471279 449.242432,51.202938  C450.250488,52.924610 452.354614,54.644539 454.256500,55.088013  C461.155701,56.696728 468.181152,57.763802 475.158997,59.035225  z"
        />
        <path
            fill="#ccff66"
            opacity={1}
            stroke="none"
            d=" M337.281189,57.214569  C342.977417,47.645901 347.135681,37.789757 346.824677,26.891420  C346.655060,20.946877 344.694122,15.053453 343.482849,8.838179  C344.186340,9.240506 344.921051,9.499434 345.446503,9.984867  C356.805908,20.479601 367.262970,31.838095 374.691284,45.450214  C379.716248,54.658276 383.197296,64.655243 382.981659,75.455460  C382.745514,87.281784 378.667145,97.809746 370.283569,106.030151  C359.846344,116.264229 348.296417,125.375244 338.009796,135.747589  C329.018219,144.814117 321.598846,155.263840 318.468109,168.054825  C317.934418,170.235275 317.939178,172.547501 317.619965,175.492767  C302.271210,164.261429 287.690796,153.208023 282.894897,133.728073  C280.008453,122.004059 283.657043,112.095085 291.667633,104.061516  C300.812775,94.890099 311.236389,86.995049 320.402405,77.842316  C326.568756,71.684921 331.517151,64.307808 337.281189,57.214569  z"
        />
        <path
            fill="#FFD782"
            opacity={1}
            stroke="none"
            d=" M444.199799,255.449799  C442.685303,260.547394 440.983276,265.245514 440.090546,270.092743  C439.041351,275.789886 435.475525,279.239166 429.818176,278.999603  C424.728363,278.784088 421.041046,275.000763 421.058624,269.244843  C421.091278,258.557343 412.712494,248.203171 403.139069,245.661957  C400.192139,244.879715 397.109589,244.623978 394.119659,243.984528  C388.432220,242.768188 386.302063,239.447922 387.001556,233.102051  C387.420227,229.304062 391.405060,226.300171 396.043457,225.977783  C409.690643,225.029236 416.743195,218.395126 420.604034,206.809906  C421.511963,204.085419 421.215546,200.975021 421.999298,198.190247  C423.304688,193.551941 427.779572,190.585831 431.735809,191.287598  C436.309113,192.098862 439.981934,195.925644 439.946289,200.614517  C439.834900,215.250534 451.488861,225.737549 465.184601,226.023224  C469.996246,226.123596 473.789642,229.629303 473.999847,233.862106  C474.333740,240.587189 472.601074,243.137146 466.410858,243.962189  C457.711578,245.121643 450.050873,248.102371 444.199799,255.449799  M427.487671,239.992264  C428.284637,241.178619 429.081573,242.364990 429.576904,243.102310  C432.790710,239.975708 435.225891,237.606598 438.556824,234.366074  C435.683167,232.299850 432.737305,230.181702 430.756012,228.757111  C428.419434,230.395401 425.355499,232.543655 422.035492,234.871445  C424.338226,237.017654 425.667725,238.256775 427.487671,239.992264  z"
        />
        <path
            fill="#FFD782"
            opacity={1}
            stroke="none"
            d=" M134.354324,292.201477  C134.756943,290.066895 134.873825,288.255646 135.001709,286.445221  C135.337418,281.692841 139.662445,277.904877 144.636948,278.001495  C148.778168,278.081909 152.654922,282.243713 153.030258,286.580719  C154.261673,300.809998 162.667969,312.210358 179.371811,313.012238  C184.088516,313.238617 187.983109,317.351013 187.999496,321.957916  C188.019760,327.656128 185.050659,330.526398 179.432480,331.045410  C163.572556,332.510559 154.765213,341.148804 153.054169,357.489014  C152.472519,363.043762 149.560913,366.064941 143.889359,365.999603  C139.240250,365.946045 135.213867,362.056335 135.009598,357.299927  C134.333267,341.552460 123.402473,331.865631 109.141296,331.011505  C104.047653,330.706482 100.038681,326.839630 100.004822,322.040192  C99.969620,317.052429 104.133293,313.329895 109.557007,312.959290  C122.228760,312.093414 129.715759,305.410187 133.937088,295.007202  C134.231781,294.280975 134.039459,293.357117 134.354324,292.201477  M148.002991,325.495514  C149.213867,324.452087 150.424744,323.408661 151.071716,322.851135  C148.779083,320.432678 146.296951,317.814270 143.878433,315.263000  C141.715012,317.425476 139.416580,319.722900 137.306259,321.832306  C139.769363,324.337952 142.298767,326.911041 144.654068,329.307037  C145.413071,328.425049 146.456619,327.212372 148.002991,325.495514  z"
        />
        <path
            fill="#59B1CC"
            opacity={1}
            stroke="none"
            d=" M491.942932,451.510559  C455.258881,452.013519 418.666962,452.043488 382.075287,452.150391  C379.355164,452.158325 378.332306,451.332001 378.184906,448.473999  C377.487518,434.954956 377.404083,434.952850 390.643524,434.960510  C424.076050,434.979858 457.508606,435.005524 491.399658,435.057495  C491.917145,440.409821 491.976135,445.733246 491.942932,451.510559  z"
        />
        <path
            fill="#59B1CC"
            opacity={1}
            stroke="none"
            d=" M22.052704,435.535645  C59.715199,435.085907 97.288452,435.085907 135.685211,435.085907  C135.685211,440.218536 135.909775,445.152252 135.468033,450.025543  C135.392868,450.854584 132.465942,452.009277 130.847504,452.024017  C114.548035,452.172546 98.246681,452.124786 81.945869,452.112091  C62.319004,452.096832 42.692158,452.061981 22.607338,452.000305  C22.087402,446.638458 22.025433,441.311920 22.052704,435.535645  z"
        />
        <path
            fill="#87CEE9"
            opacity={1}
            stroke="none"
            d=" M330.759033,360.730286  C322.651367,367.580688 313.982635,367.397736 307.980591,363.340729  C300.655945,358.389740 297.921753,349.615784 301.041168,341.734436  C304.185669,333.789703 309.691193,330.426605 318.452026,330.107788  C325.515289,329.850739 333.119751,336.527191 335.015625,343.338135  C336.827393,349.846893 335.024628,355.376984 330.759033,360.730286  z"
        />
        <path
            fill="#D476E2"
            opacity={1}
            stroke="none"
            d=" M66.000061,200.950989  C64.985352,192.196503 71.535950,184.432709 79.110458,183.169525  C87.605576,181.752777 96.138206,184.075729 100.123840,194.159042  C102.758812,200.825302 100.428093,210.968079 94.115952,215.223053  C88.515678,218.998154 81.977615,219.381134 75.872726,216.827042  C69.345497,214.096268 65.821480,208.762405 66.000061,200.950989  z"
        />
        <path
            fill="#D9176C"
            opacity={1}
            stroke="none"
            d=" M48.002079,336.791656  C48.000423,331.699829 48.000423,327.088623 48.000423,322.246643  C54.403046,322.246643 60.325817,322.246643 66.626076,322.246643  C66.626076,327.928345 66.626076,333.639771 66.626076,339.978638  C61.117592,339.978638 55.529461,340.071106 49.952896,339.859192  C49.271393,339.833282 48.651943,338.174408 48.002079,336.791656  z"
        />
        <path
            fill="#D9176C"
            opacity={1}
            stroke="none"
            d=" M369.997589,168.210007  C369.999237,173.633331 369.999237,178.575989 369.999237,183.752014  C363.932526,183.752014 358.343384,183.752014 352.375824,183.752014  C352.375824,177.741272 352.375824,171.699585 352.375824,165.019836  C357.553528,165.019836 362.807953,164.921371 368.049164,165.139786  C368.732910,165.168289 369.348694,166.826172 369.997589,168.210007  z"
        />
        <path
            fill="#D9176C"
            opacity={1}
            stroke="none"
            d=" M231.000000,374.970184  C231.000000,377.753784 231.000000,380.053680 231.000000,382.675232  C224.969315,382.675232 219.264313,382.675232 213.278015,382.675232  C213.278015,376.996307 213.278015,371.407318 213.278015,365.407532  C218.854401,365.407532 224.562836,365.407532 231.000000,365.407532  C231.000000,368.320404 231.000000,371.403442 231.000000,374.970184  z"
        />
        <path
            fill="#ccff66"
            opacity={1}
            stroke="none"
            d=" M248.237976,221.843689  C248.759155,225.806320 247.285889,227.045563 243.790665,226.823471  C239.693497,226.563095 235.567093,226.762543 231.219986,226.762543  C231.219986,220.727844 231.219986,215.298370 231.219986,209.505493  C236.708649,209.505493 242.095947,209.505493 248.238022,209.505493  C248.238022,213.509598 248.238022,217.437271 248.237976,221.843689  z"
        />
        <path
            fill="#D91E71"
            opacity={1}
            stroke="none"
            d=" M274.261169,238.230133  C274.268188,243.176743 274.268188,247.645920 274.268188,252.452194  C268.400238,252.452194 263.002136,252.452194 257.291870,252.452194  C257.291870,246.877121 257.291870,241.451340 257.291870,235.254883  C262.291779,235.254883 267.376007,235.167984 272.449158,235.365204  C273.080811,235.389755 273.653900,236.920090 274.261169,238.230133  z"
        />
        <path
            fill="#ffffe6"
            opacity={1}
            stroke="none"
            d=" M351.050201,452.111328  C348.605164,452.111420 346.643005,452.111420 344.296417,452.111420  C344.296417,446.376343 344.296417,441.135315 344.296417,435.423584  C349.570618,435.423584 354.934113,435.423584 360.681213,435.423584  C360.681213,440.679565 360.681213,445.920105 360.681213,452.111206  C357.672699,452.111206 354.602875,452.111206 351.050201,452.111328  z"
        />
    </svg>
);
export default MovieMavenIcon;
