import * as React from "react";
const AllClearedUpEmojiIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <defs>
            <style>{".b{fill:#e7c930}.c{fill:#864e20}"}</style>
        </defs>
        <rect
            x={1}
            y={1}
            width={22}
            height={22}
            rx={7.656}
            style={{
                fill: "#f8de40",
            }}
        />
        <path
            className="b"
            d="M23 13.938a14.69 14.69 0 0 1-12.406 6.531c-5.542 0-6.563-1-9.142-2.529A7.66 7.66 0 0 0 8.656 23h6.688A7.656 7.656 0 0 0 23 15.344z"
        />
        <path
            className="c"
            d="M18.29 9.084h-4.1a.586.586 0 0 1 0-1.171h4.1a.586.586 0 0 1 0 1.171zM9.806 9.084H5.71a.586.586 0 0 1 0-1.171h4.1a.586.586 0 0 1 0 1.171z"
        />
        <path
            className="c"
            d="M8.672 10.205A1.706 1.706 0 0 0 10.379 8.5H6.966a1.706 1.706 0 0 0 1.706 1.705zM17.156 10.205A1.706 1.706 0 0 0 18.863 8.5H15.45a1.706 1.706 0 0 0 1.706 1.705z"
        />
        <ellipse className="c" cx={12} cy={13.375} rx={5.479} ry={0.297} />
        <ellipse className="b" cx={12} cy={14.646} rx={1.969} ry={0.229} />
    </svg>
);
export default AllClearedUpEmojiIcon;
