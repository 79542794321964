import * as React from "react";
const SignupCorrect = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="7%"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="green"
            opacity={1}
            stroke="none"
            d=" M367.350220,380.001312  C362.406311,375.905609 357.947601,376.322052 352.567047,379.404266  C320.918091,397.533997 286.702148,406.422272 250.294052,404.712158  C191.975296,401.972870 144.008514,377.871887 107.125908,332.335571  C108.748199,332.622406 110.111832,333.216125 111.509422,333.714661  C123.017570,337.819733 134.532028,341.907104 146.340820,346.152039  C148.375748,347.595306 150.118744,348.880554 151.852463,350.178223  C188.697510,377.756378 230.107559,389.706024 275.813873,384.718628  C320.963562,379.791962 358.622833,359.678864 388.398560,325.300598  C436.041779,270.292999 444.100677,191.282196 408.439178,127.985870  C403.228973,118.738159 396.743225,110.209084 390.764221,101.082611  C390.431854,100.551773 390.182953,100.285828 389.949463,100.044785  C389.964905,100.069702 389.912140,100.096703 389.937805,99.688385  C389.639984,97.665352 389.525635,95.972961 388.959320,94.448448  C385.043518,83.906815 381.033539,73.400169 377.053802,62.882286  C379.467865,64.557838 382.101257,65.990746 384.263336,67.945107  C420.517578,100.716591 442.740295,141.085861 448.008820,189.840164  C454.891235,253.529663 434.603912,307.766449 388.899323,352.560638  C387.472992,353.958527 386.079956,355.409668 384.525574,356.653778  C380.531128,359.850769 379.878693,363.705322 382.100861,368.590240  C377.370697,372.601929 372.521729,376.228821 367.350220,380.001312  z"
        />
        <path
            fill="green"
            opacity={1}
            stroke="none"
            d=" M377.031738,62.912899  C381.033539,73.400169 385.043518,83.906815 388.959320,94.448448  C389.525635,95.972961 389.639984,97.665352 389.642639,99.532280  C384.988678,95.404907 380.800415,90.870087 376.299774,86.669983  C336.226349,49.272743 288.703400,33.754143 234.518494,40.386009  C191.111938,45.698677 155.096268,65.954147 126.033699,98.588448  C121.926567,103.200333 121.739395,106.135201 124.937958,111.832642  C121.086067,117.173325 117.468307,122.229485 113.444397,127.251938  C107.409134,126.155128 104.422577,129.238647 102.146477,133.855499  C89.093803,160.331650 82.835922,188.187576 83.755516,217.805222  C84.888435,254.293152 96.280762,287.151764 118.284973,316.041016  C126.495934,326.821136 136.731934,336.058807 146.044571,345.999817  C134.532028,341.907104 123.017570,337.819733 111.509422,333.714661  C110.111832,333.216125 108.748199,332.622406 106.965805,332.016602  C88.630981,310.268646 76.194016,285.645782 70.169373,258.290405  C53.785492,183.898117 73.517723,120.126274 129.427063,68.440536  C159.654297,40.496773 196.154648,24.964218 237.232147,20.919241  C289.022552,15.819344 335.452148,29.519053 376.645447,61.824974  C376.655273,62.537979 376.756927,62.798183 377.068268,62.991089  C377.068268,62.991089 377.009644,62.943516 377.031738,62.912899  z"
        />
        <path
            fill="lightgreen"
            opacity={1}
            stroke="none"
            d=" M146.340820,346.152039  C136.731934,336.058807 126.495934,326.821136 118.284973,316.041016  C96.280762,287.151764 84.888435,254.293152 83.755516,217.805222  C82.835922,188.187576 89.093803,160.331650 102.146477,133.855499  C104.422577,129.238647 107.409134,126.155128 113.648766,127.508804  C118.690514,131.429626 118.368118,135.467377 115.940254,140.245850  C100.029037,171.561920 95.155441,204.620026 100.881477,239.238968  C115.377457,326.879944 202.439148,386.150665 289.215240,367.490112  C377.573151,348.489410 431.835999,262.356354 410.900452,174.335342  C392.195038,95.690567 312.817474,42.707260 233.341156,55.616905  C194.991852,61.846130 163.024734,79.430138 137.394714,108.540092  C133.849426,112.566757 130.171646,114.290703 125.172081,111.548119  C121.739395,106.135201 121.926567,103.200333 126.033699,98.588448  C155.096268,65.954147 191.111938,45.698677 234.518494,40.386009  C288.703400,33.754143 336.226349,49.272743 376.299774,86.669983  C380.800415,90.870087 384.988678,95.404907 389.616974,99.940598  C389.912140,100.096703 389.964905,100.069702 390.036346,100.305359  C390.354401,100.809837 390.601044,101.078659 390.847656,101.347488  C396.743225,110.209084 403.228973,118.738159 408.439178,127.985870  C444.100677,191.282196 436.041779,270.292999 388.398560,325.300598  C358.622833,359.678864 320.963562,379.791962 275.813873,384.718628  C230.107559,389.706024 188.697510,377.756378 151.852463,350.178223  C150.118744,348.880554 148.375748,347.595306 146.340820,346.152039  z"
        />
        <path
            fill="#3FC3A4"
            opacity={1}
            stroke="none"
            d=" M124.937958,111.832642  C130.171646,114.290703 133.849426,112.566757 137.394714,108.540092  C163.024734,79.430138 194.991852,61.846130 233.341156,55.616905  C312.817474,42.707260 392.195038,95.690567 410.900452,174.335342  C431.835999,262.356354 377.573151,348.489410 289.215240,367.490112  C202.439148,386.150665 115.377457,326.879944 100.881477,239.238968  C95.155441,204.620026 100.029037,171.561920 115.940254,140.245850  C118.368118,135.467377 118.690514,131.429626 114.054901,127.542503  C117.468307,122.229485 121.086067,117.173325 124.937958,111.832642  M306.730713,234.629807  C311.177338,230.227509 315.623932,225.825211 320.799438,221.807556  C324.581970,223.987488 328.092712,223.769302 331.252045,220.600113  C341.366547,210.454102 351.739685,200.549774 361.521820,190.092712  C373.890564,176.870529 375.085938,156.647827 364.993103,141.566925  C351.017059,120.683716 321.987427,117.807068 303.732605,135.798035  C282.138153,157.080322 260.815186,178.638092 239.367966,200.069870  C237.979599,201.457245 236.534821,202.788177 234.895309,204.356796  C233.425140,203.001373 232.189621,201.935013 231.035797,200.786545  C224.187561,193.970123 217.408295,187.083649 210.510056,180.318390  C201.712814,171.690765 191.122635,167.290436 178.801941,168.535843  C162.235123,170.210464 150.439575,178.973358 144.747070,194.662628  C138.964966,210.598877 142.761993,224.902573 154.578262,236.886475  C171.538071,254.086823 188.641968,271.146637 205.804855,288.144775  C222.717484,304.895050 246.458862,305.047272 263.397491,288.450378  C277.200043,274.926270 290.699249,261.092896 304.463959,247.529587  C308.406189,243.645035 309.209808,239.726212 306.730713,234.629807  z"
        />
        <path
            fill="#DFF5FC"
            opacity={1}
            stroke="none"
            d=" M320.070557,221.422913  C315.623932,225.825211 311.177338,230.227509 306.147339,234.729126  C300.899628,231.740845 297.222839,233.357849 293.637024,236.978806  C280.157684,250.590179 266.599854,264.124329 253.010559,277.626160  C242.272217,288.295410 227.670364,288.632843 216.907684,278.064209  C199.436707,260.908264 182.130386,243.580841 164.964264,226.119629  C154.466980,215.441910 154.624756,200.431564 164.880432,190.390671  C174.917435,180.563828 189.578094,180.931717 200.171768,191.384567  C209.183228,200.276199 218.074417,209.289581 227.053680,218.214020  C233.240280,224.362823 236.269028,224.328918 242.508392,218.094162  C266.192657,194.427444 289.831268,170.714905 313.557617,147.090485  C324.099823,136.593613 339.531219,136.229019 349.350128,146.045807  C359.536652,156.230133 359.195679,171.345627 348.463135,182.174408  C339.551361,191.166107 330.544159,200.063248 321.627930,209.050598  C317.216034,213.497696 316.989075,215.761658 320.070557,221.422913  z"
        />
        <path
            fill="green"
            opacity={1}
            stroke="none"
            d=" M320.434998,221.615234  C316.989075,215.761658 317.216034,213.497696 321.627930,209.050598  C330.544159,200.063248 339.551361,191.166107 348.463135,182.174408  C359.195679,171.345627 359.536652,156.230133 349.350128,146.045807  C339.531219,136.229019 324.099823,136.593613 313.557617,147.090485  C289.831268,170.714905 266.192657,194.427444 242.508392,218.094162  C236.269028,224.328918 233.240280,224.362823 227.053680,218.214020  C218.074417,209.289581 209.183228,200.276199 200.171768,191.384567  C189.578094,180.931717 174.917435,180.563828 164.880432,190.390671  C154.624756,200.431564 154.466980,215.441910 164.964264,226.119629  C182.130386,243.580841 199.436707,260.908264 216.907684,278.064209  C227.670364,288.632843 242.272217,288.295410 253.010559,277.626160  C266.599854,264.124329 280.157684,250.590179 293.637024,236.978806  C297.222839,233.357849 300.899628,231.740845 305.852295,234.958618  C309.209808,239.726212 308.406189,243.645035 304.463959,247.529587  C290.699249,261.092896 277.200043,274.926270 263.397491,288.450378  C246.458862,305.047272 222.717484,304.895050 205.804855,288.144775  C188.641968,271.146637 171.538071,254.086823 154.578262,236.886475  C142.761993,224.902573 138.964966,210.598877 144.747070,194.662628  C150.439575,178.973358 162.235123,170.210464 178.801941,168.535843  C191.122635,167.290436 201.712814,171.690765 210.510056,180.318390  C217.408295,187.083649 224.187561,193.970123 231.035797,200.786545  C232.189621,201.935013 233.425140,203.001373 234.895309,204.356796  C236.534821,202.788177 237.979599,201.457245 239.367966,200.069870  C260.815186,178.638092 282.138153,157.080322 303.732605,135.798035  C321.987427,117.807068 351.017059,120.683716 364.993103,141.566925  C375.085938,156.647827 373.890564,176.870529 361.521820,190.092712  C351.739685,200.549774 341.366547,210.454102 331.252045,220.600113  C328.092712,223.769302 324.581970,223.987488 320.434998,221.615234  z"
        />
    </svg>
);
export default SignupCorrect;
