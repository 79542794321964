import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import "./styles/userActivation.css";
import LoadingLogo from "../animations/LoadingLogo";
import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";

function UserActivation() {
    const params = useParams();
    const [userActivationState, setUserActivationState] = useState({
        isLoading: false,
        wasSuccess: null,
        wasConfirmed: null,
        wasValid: null,
    })


    useEffect(() => {
        activateAccountOnLoad();
    }, [])

    const activateAccountOnLoad = () => {
        setUserActivationState({
            isLoading: true
        });
        axios.get(`/api/users/activation/${params.uid}/${params.token}/`).then((res) => {
            if (res.status === 200) {
                setUserActivationState({
                    wasSuccess: true,
                    isLoading: false
                })
            } else if (res.response.status === 400) {
                setUserActivationState({
                    wasSuccess: false,
                    wasConfirmed: true,
                    isLoading: false
                })
            } else {
                setUserActivationState({
                    wasSuccess: false,
                    wasConfirmed: false,
                    wasValid: false,
                    isLoading: false
                })
            }
        }).catch((err) => {
            setUserActivationState({
                isLoading: false
            });
            if (err.response.status === 400) {
                setUserActivationState({
                    wasSuccess: false,
                    wasConfirmed: true,
                    isLoading: false
                })
            } else {
                setUserActivationState({
                    wasSuccess: false,
                    wasConfirmed: false,
                    wasValid: false,
                    isLoading: false
                })
            }
        })
    }

    return (
        <LayoutComponentForHelmet pageTitle="SpreeCine">
            <div className="body-space">
                <div className="b">
                    {
                        userActivationState.isLoading ?
                            <LoadingLogo type="processing" />
                            :
                            <>
                                <div className="confirmation-box-success">
                                    {
                                        userActivationState.wasSuccess ?
                                            <>
                                                <div className="confirmation-icon suc">&#10004;</div>
                                                <h1>Successfully Verified</h1>
                                                <p>Your account has been successfully confirmed.</p>
                                            </>
                                            :
                                            <>
                                                {
                                                    userActivationState.wasConfirmed ?
                                                        <>
                                                            <div className="confirmation-icon err">&#10799;</div>
                                                            <h1>Invalid request</h1>
                                                            <p>This may occur if your account has been confirmed or if the credentials are invalid.</p>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </>
                                    }
                                    <Link to={"/login/"} className="login-button">Login</Link>
                                </div>
                            </>
                    }
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default UserActivation;