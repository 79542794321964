import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

let defaultProfileShareURL = `profile`


function ProfileMoreActionDropdown({ userProfileUsername, currentUserUsername, flipContainer, setReceiverBlocked, isReceiverBlocked, blockUser, openReportModal, setShareModalOpen, shareData, setShareData, setPublicShareModalOpen, isUserAuthenticated, isCurrentUserProfileOwner, openUserSettings }) {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const { username } = useParams();
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

    const blockUnBlockUser = () => {
        blockUser();
        setReceiverBlocked(!isReceiverBlocked)
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsProfileDropdownOpen(false);
            }
        };
        window.addEventListener("click", handleOutsideClick);
        return () => {
            window.removeEventListener("click", handleOutsideClick)
        }
    }, [])

    const openShareMainModal = () => {
        const prepareUrlForShare = defaultProfileShareURL + `/${userProfileUsername}/`;

        setShareData(prevState => ({
            shareUrl: prepareUrlForShare,
            pageType: "P"
        }));
        setPublicShareModalOpen(true)

        // if (username === currentUserUsername) {
        //     setShareModalOpen(true)
        // } else {
        //     setPublicShareModalOpen(true);
        // };
    }

    return (
        <span ref={dropdownRef}>
            <svg className='dark-mode-responsive-svg profile-menu-dropdown' onClick={() => setIsProfileDropdownOpen(!isProfileDropdownOpen)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" /></svg>
            {
                isProfileDropdownOpen ?
                    <div className="user-profile-dropdown">
                        <div className="user-profile-dropdown-content">
                            <span className="user-profile-dropdown-item" onClick={flipContainer}>{t("More")}</span>
                            <span className="user-profile-dropdown-item" onClick={openShareMainModal}>{t("Share Profile")}</span>
                            {
                                isCurrentUserProfileOwner &&
                                <>
                                    <hr className='profile-hr-divider' />
                                    <span className="user-profile-dropdown-item" onClick={openUserSettings}>{t("Edit Profile")}</span>
                                    <hr className='profile-hr-divider' />
                                    <Link to={`/blocked-users`} className="user-profile-dropdown-item">{t("Blocked users")}</Link>
                                </>
                            }
                            {
                                !isCurrentUserProfileOwner &&
                                <>
                                    <span className="user-profile-dropdown-item hover-red" onClick={blockUnBlockUser}>{isReceiverBlocked ? <>{t("UnBlock")}</> : <>{t("Block")}</>} <span className='username fw-700'>{userProfileUsername}</span></span>

                                    <hr className='profile-hr-divider' />
                                    <span className="user-profile-dropdown-item hover-red" onClick={openReportModal}>{t("Report Profile")}</span>
                                </>
                            }
                        </div>
                    </div>
                    :
                    null
            }
        </span>
    )
}

export default ProfileMoreActionDropdown;