import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
    Link, useNavigate,
} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { HoverCard, Button, Text, Group } from '@mantine/core';

import "./styles/watch-later.css";
// import MaintenanceMode from '../Preventation/maintenance';
import showToastError from '../helpers/showToastError';
import textTrimmer from "../helpers/stringCutter";
import SomethingWentWrong from "../helpers/SomethingWentWrongMessage";
import LoadingLogo from "../animations/LoadingLogo";
import { useDisclosure } from '@mantine/hooks';


import WatchLaterOpenIcon from './icons/OpenWatchLaterIcon';
import WatchLaterCloseIcon from './icons/CloseWatchLaterIcon';
import OnlyLocals from './icons/OnlyLocals';
import WatchLaterListCreationModal from './WatchLaterListCreationModal';
import TimestampPopover from './TimestampPopover';
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';
import WatchLaterItemPopupOptions from './WatchLaterItemPopupOptions';
import ShareForPublicOnly from '../Share/ShareOpen';


function WatchLater({ currentUserUsername }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { username } = useParams();
    const [watchLaterList, setWatchLaterList] = useState([]);
    const [cuo, setCuo] = useState();
    const [wtUser, setWtUser] = useState("")
    const [wtShareData, setWtShareData] = useState({
        isWtPopupOpen: false,
        shareUrl: "",
    })

    const [errorBox, setErrorBox] = useState("");
    const [titleError, setTitleError] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isPostLoading, setPostLoading] = useState(false);
    const [postError, setPostError] = useState("");

    const [opened, { open, close }] = useDisclosure(false);

    const [watchLaterTitle, setWatchLaterTitle] = useState("");
    const [visibilityChoice, setVisibilityChoice] = useState("R");

    const [isVisibilityDropdownOpen, setVisibilityDropdownOpen] = useState(false);

    const [currentOptionData, setCurrentOptionData] = useState({
        id: 0,
        itemCount: 0,
        timestamp: "",
        visibility: "",
    })
    const [openedOptions, setOpenedOptions] = useState(new Array(0).fill(false));
    const optionsMenuContainerRef = useRef(null);

    // useEffect(() => {
    //     console.log("handling close")
    //     const handleClickOutside = (event) => {
    //         if (optionsMenuContainerRef.current && !optionsMenuContainerRef.current.contains(event.target)) {
    //             closeAllMenuOptions();
    //         }
    //     };

    //     document.addEventListener('click', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, []);

    // const closeAllMenuOptions = () => {
    //     const updatedOpenMenu = openedOptions.map(() => false);
    //     setOpenedOptions(updatedOpenMenu);
    // };

    const openCloseWtShareModal = (wtId, visibility) => {
        setWtShareData(prevState => ({
            ...prevState,
            isWtPopupOpen: !prevState.isWtPopupOpen,
            shareUrl: `watch-later-list/${wtId}`
        }))
        if (visibility === "R") {
            showToastError("Watch later list is private", "info");
        } else if (visibility === "AO") {
            showToastError("Only your Audience will be able to see your List", "info");
        }
    }

    const headers = {
        Accept: "application/json",
    }

    const checkIfFormIsValid = () => {
        let isFormValid = true;
        setTitleError("");
        // ! Implement proptype validation here.
        if (!watchLaterTitle) {
            isFormValid = false;
            setTitleError("Title can't be Empty");
        }
        return isFormValid;
    }

    const createWatchLaterList = () => {
        setPostLoading(true);
        setPostError("");
        let form_data = new FormData();

        form_data.append("list_name", watchLaterTitle)
        form_data.append("watch_later_visibility", visibilityChoice)

        axios.post("/api/content/management/wt-create/", form_data, { headers: headers }).then((res) => {
            setPostLoading(false);
            setPostError("");
            // ? Set new comment in the state.
            setWatchLaterList(prevState => [res.data, ...prevState]);
            navigate(`/watch-later-list/${res.data.id}`);
        }).catch(() => {
            setPostLoading(false);
            setPostError("Something went Wrong!");
        })
    };

    const fetchWTs = () => {
        setIsLoading(true);
        axios.get(`/api/content/management/new_watch_later_creation/${username}/`, { params: { requestingUser: currentUserUsername } }).then(response => {
            setCuo(response.data.cuo);
            setWtUser(response.data.wt_user)
            setIsLoading(false);
            setErrorBox("");
            const watchLaterList = response.data.data;
            setWatchLaterList(watchLaterList)
            if (!response.data.cuo) {
                showToastError(`You are viewing ${response.data.wt_user}'s list`, "success")
            }
        }).catch(() => {
            setIsLoading(false);
            setErrorBox("Something went wrong");
        })
    }

    useEffect(() => {
        fetchWTs();
    }, [username]);

    const openMoreOptions = (id, contentInsideCount, timestamp, visibility) => {
        const user = [...openedOptions];
        const updatedOpenMenu = openedOptions.map(() => false);
        updatedOpenMenu[id] = !user[id];
        setOpenedOptions(updatedOpenMenu)
        setCurrentOptionData({
            id: id,
            itemCount: contentInsideCount,
            timestamp: timestamp,
            visibility: visibility,
        })
    }

    const runTheseTwoOnClick = () => {
        let checkIfValidAndInformMe = checkIfFormIsValid();

        if (checkIfValidAndInformMe) {
            createWatchLaterList();
            setTimeout(
                function () {
                    close();
                    showToastError("List successfully created", "success")
                }, 2000
            );
        }
    };

    if (isLoading) {
        return <LoadingLogo type="processing" />
    };

    if (errorBox !== "") {
        return <SomethingWentWrong retry={fetchWTs} />
    };

    return (
        <LayoutComponentForHelmet pageTitle={"Watch Later - SpreeCine"}>
            <div className='body-space'>
                <div className="wt-list">
                    {
                        cuo &&
                        <div className="wt-item bg-grey" onClick={open}>
                            <span className="container-text main-wt-creation-text make-text-cursor text-white">
                                {t("Create new Watch Later List")}
                            </span>
                            <span className="wt-info"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200v-240H200v-80h240v-240h80v240h240v80H520v240h-80Z" /></svg></span>
                        </div>
                    }
                    {
                        watchLaterList?.map((content) =>
                            <div
                                className="wt-item"
                                key={content.id}
                            >
                                <span className="container-text">
                                    <Link
                                        to={`/watch-later-list/${content.id}`}
                                        state={{
                                            id: content.id,
                                            wt_name: content.list_name,
                                            wt_creater: content.list_creater,
                                        }}
                                        title={content.list_name}
                                    >
                                        {textTrimmer(content.list_name, 23)}
                                    </Link>
                                </span>
                                <span className="wt-info">
                                    <svg onClick={() => openMoreOptions(content.id, content.wt_content_count, content.time_stamp, content.watch_later_visibility)} className={`fill-white svg-mg-r-10 ${openedOptions[content.id] ? "active-dropdown-svg" : ""} make-text-cursor`} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" /></svg>
                                    <HoverCard width={250} shadow="md">
                                        <HoverCard.Target>
                                            <svg className='make-text-cursor fill-white' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" /></svg>
                                        </HoverCard.Target>
                                        <HoverCard.Dropdown>
                                            <Text size="sm" className='clr-white'>
                                                {content.time_stamp}
                                            </Text>
                                        </HoverCard.Dropdown>
                                    </HoverCard>
                                </span>
                                {
                                    openedOptions[content.id] ?
                                        <WatchLaterItemPopupOptions
                                            id={currentOptionData.id}
                                            currentOptionData={currentOptionData}
                                            optionsMenuContainerRef={optionsMenuContainerRef}
                                            openMoreOptions={openMoreOptions}
                                            watchLaterList={watchLaterList}
                                            setWatchLaterList={setWatchLaterList}
                                            openCloseWtShareModal={openCloseWtShareModal}
                                            cuo={cuo}
                                        />
                                        :
                                        null
                                }
                            </div>
                        )
                    }
                </div>
                <ShareForPublicOnly
                    isPubliShareModalOpen={wtShareData.isWtPopupOpen}

                    openShareMainModal={() => setWtShareData({ isWtPopupOpen: true })}
                    closeShareMainModal={() => setWtShareData({ isWtPopupOpen: false })}

                    shareData={wtShareData}
                    pageType={"WT"}
                />

                <WatchLaterListCreationModal
                    t={t}
                    opened={opened}
                    close={close}
                    watchLaterTitle={watchLaterTitle}
                    setWatchLaterTitle={setWatchLaterTitle}
                    titleError={titleError}
                    runTheseTwoOnClick={runTheseTwoOnClick}

                    visibilityChoice={visibilityChoice}
                    setVisibilityChoice={setVisibilityChoice}

                    isVisibilityDropdownOpen={isVisibilityDropdownOpen}
                    setVisibilityDropdownOpen={setVisibilityDropdownOpen}

                    isPostLoading={isPostLoading}
                    postError={postError}
                />
            </div>
        </LayoutComponentForHelmet>
    )
}


export default WatchLater;