import * as React from "react";
const WatchLaterFilled = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="20%"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="#FD3018"
            opacity={1}
            stroke="none"
            d=" M104.000000,513.000000  C98.977905,513.000000 93.955818,513.000000 88.809044,512.781433  C81.536392,506.934937 80.861320,499.328644 80.870827,490.816711  C81.047028,333.062531 81.019852,175.308060 80.929794,17.553709  C80.926071,11.021286 82.788704,6.001420 88.617088,2.719192  C88.959015,2.526640 88.882034,1.590242 89.000000,1.000000  C144.687561,1.000000 200.375122,1.000000 256.531342,1.468657  C256.997131,121.240486 256.994263,240.543655 256.683716,359.993439  C207.237564,409.276794 158.096527,458.411041 108.970825,507.560608  C107.236168,509.296051 105.653488,511.183411 104.000000,513.000000  z"
        />
        <path
            fill="#E61E14"
            opacity={1}
            stroke="none"
            d=" M256.991364,359.846832  C256.994263,240.543655 256.997131,121.240486 257.000000,1.468657  C312.687561,1.000000 368.375122,1.000000 424.201813,1.250956  C430.568665,6.096790 433.153595,11.957189 433.144165,19.983635  C432.957672,178.787994 432.984406,337.592651 433.079773,496.397217  C433.083710,502.943085 431.269348,507.980011 425.421295,511.272308  C425.068481,511.470917 425.130859,512.407043 425.000000,513.000000  C420.310760,513.000000 415.621490,513.000000 410.793884,512.753296  C407.045837,509.121857 403.339996,505.833435 399.841003,502.337952  C353.547058,456.090332 307.283386,409.812347 261.003235,363.550873  C259.716980,362.265167 258.330872,361.079285 256.991364,359.846832  z"
        />
    </svg>
);
export default WatchLaterFilled;
