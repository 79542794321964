import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import './userReports.css';
import textTrimmer from "../../helpers/stringCutter";
import { defaultBackendOrigin } from "../../constants/constants";

function UserReportedItems() {
    const params = useParams();
    const [reportsSent, setReportsSent] = useState({
        reports: []
    });
    const [reportsReceived, setReportsReceived] = useState({
        reports: []
    });
    const [filterToggleReport, setFilterToggleReport] = useState("sent");

    const toggleReportFilter = (e) => {
        setFilterToggleReport(e.target.value);
    }

    useEffect(() => {
        axios.get(`/dashboard/admin/user/mod/report-list/${params.userId}/${params.id}/`).then((res) => {
            console.log(res.data.message[0].reported_done);
            setReportsSent({
                reports: res.data.message[0].reported_done
            });
            setReportsReceived({
                reports: res.data.message[0].reports_received_against
            });
        });
    }, [])

    return (
        <>

            <ul className="toggle-upshot-creation-theme inline-flex p-1 space-x-1 bg-gray-200 rounded-md text-blue-600">
                <li className="filter-switch-item h-8">
                    <input type="radio" name="filter1" id="filter1-0" className="sr-only" value="sent" checked={filterToggleReport === "sent" && true} onChange={(e) => toggleReportFilter(e)} />
                    <label htmlFor="filter1-0" className="make-text-cursor h-8 py-1 px-2 leading-6 text-gray-600 hover:text-gray-800 bg-white">
                        Sent
                    </label>
                    <div aria-hidden="true" className="filter-active"></div>
                </li>
                <li className="filter-switch-item h-8">
                    <input type="radio" name="filter1" id="filter1-1" className="sr-only" value="received" checked={filterToggleReport === "received" && true} onChange={(e) => toggleReportFilter(e)} />
                    <label htmlFor="filter1-1" className="make-text-cursor h-8 py-1 px-2 leading-6 text-gray-600 hover:text-gray-800 bg-white">
                        Received
                    </label>
                </li>
            </ul>

            {
                filterToggleReport === "sent" &&
                <div className="container">
                    {
                        reportsSent.reports?.map((report, index) =>
                            <div className="report-card" key={report.id}>
                                <h2>User Report (Sent) #{report.id} <a href={`${defaultBackendOrigin}/admin/report/report/${report.id}/change/`}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" /></svg></a></h2>
                                <div className="report-tag">{report.reportcopyright.length === 0 ? <>Basic</> : <>Copyright</>}</div>
                                <div className="report-content-type">
                                    {
                                        (() => {
                                            switch (true) {
                                                case report.profile !== null:
                                                    return <>Profile</>;
                                                case report.remark !== null:
                                                    return <>Remark</>;
                                                case report.comment !== null:
                                                    return <>Comment</>;
                                                default:
                                                    return <><h1>UpShot</h1></>;
                                            }
                                        })()
                                    }
                                </div>
                                <p className="user-info">Reported by: {report.reported_by_username} | Date: {report.timestamp}</p>
                                <p className="report-content">{report.reported_third_step_detail}</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Timestamp</th>
                                            <th>Impact type</th>
                                            <th>Report severity</th>
                                            <th>Reported main reason</th>
                                            <th>Reported second step</th>
                                            <th>Reported third detail</th>
                                            <th>Is Reviewed?</th>
                                            <th>Is Resolved?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{report.timestamp}</td>
                                            <td>{report.impact_type}</td>
                                            <td>{report.report_severity}</td>
                                            <td>{report.reported_main_reason}</td>
                                            <td>{report.reported_second_step}</td>
                                            <td>{report.reported_third_step_detail}</td>
                                            <td className={`${report.is_reviewed ? "resolved" : "not-resolved"}`}>{report.is_reviewed ? <>Yes</> : <>No</>}</td>
                                            <td className={`${report.is_resolved ? "resolved" : "not-resolved"}`}>{report.is_resolved ? <>Yes</> : <>No</>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr />
                                {
                                    report.reportcopyright.length !== 0 &&
                                    <>
                                        <div className="detail-container">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Reporting for?</th>
                                                        <th>Company name</th>
                                                        <th>Copyright individual type</th>
                                                        <th>Copyright owner name</th>
                                                        <th>Country</th>
                                                        <th>Descrive infringment</th>
                                                        <th>Description</th>
                                                        <th>Direct link of O/W</th>
                                                        <th>Link of R/C</th>
                                                        <th>Email</th>
                                                        <th>Address</th>
                                                        <th>Phone</th>
                                                        <th>Profile Link</th>
                                                        <th>Is Resolved?</th>
                                                        <th>Is Reviewed?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{report.reportcopyright[0].type_of_reporting_content}</td>
                                                        <td>{report.reportcopyright[0].company_name}</td>
                                                        <td>{report.reportcopyright[0].copyright_individual_type}</td>
                                                        <td>{report.reportcopyright[0].copyright_owner_full_name}</td>
                                                        <td>{report.reportcopyright[0].country}</td>
                                                        <td>{report.reportcopyright[0].describe_infringment}</td>
                                                        <td>{report.reportcopyright[0].description}</td>
                                                        <td><a href={report.reportcopyright[0].direct_link_of_original_work} target="_blank" rel="noopener noreferrer">{textTrimmer(report.reportcopyright[0].direct_link_of_original_work, 5)}</a></td>
                                                        <td><a href={report.reportcopyright[0].direct_link_of_reporting_content} target="_blank" rel="noopener noreferrer">{textTrimmer(report.reportcopyright[0].direct_link_of_reporting_content, 5)}</a></td>
                                                        <td>{report.reportcopyright[0].email}</td>
                                                        <td>{report.reportcopyright[0].full_address}</td>
                                                        <td>{report.reportcopyright[0].phone_number}</td>
                                                        <td><a href={report.reportcopyright[0].profile_link} target="_blank" rel="noopener noreferrer">{textTrimmer(report.reportcopyright[0].profile_link, 5)}</a></td>
                                                        <td className={`${report.reportcopyright[0].is_resolved ? "resolved" : "not-resolved"}`}>{report.reportcopyright[0].is_resolved ? <>Yes</> : <>No</>}</td>
                                                        <td className={`${report.reportcopyright[0].is_resolved ? "resolved" : "not-resolved"}`}>{report.reportcopyright[0].is_reviewed ? <>Yes</> : <>No</>}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                    </>
                                }
                                <div className="detail-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Language</th>
                                                <th>Platform</th>
                                                <th>Screen</th>
                                                <th>User Agent</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{report.additional_device_info.language}</td>
                                                <td>{report.additional_device_info.platform}</td>
                                                {/* <td>{report.additional_device_info.screen}</td> */}
                                                <td>NONE</td>
                                                <td>{report.additional_device_info.userAgent}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    }
                </div>
            }

            {
                filterToggleReport === "received" &&
                <div className="container">
                    {
                        reportsReceived.reports?.map((report, index) =>
                            <div className="report-card" key={report.id}>
                                <h2>User Received (Sent) #{report.id}  <a href={`${defaultBackendOrigin}/admin/report/report/${report.id}/change/`}><svg href={`${defaultBackendOrigin}/admin/report/report/${report.id}/change/`} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" /></svg></a></h2>
                                <div className="report-tag">{report.reportcopyright.length === 0 ? <>Basic</> : <>Copyright</>}</div>
                                <div className="report-content-type">
                                    {report.hasOwnProperty("remark") !== null
                                        ?
                                        "Remark"
                                        :
                                        report.hasOwnProperty("profile") !== null
                                            ?
                                            "Profile"
                                            :
                                            report.hasOwnProperty("upshot") !== null
                                                ?
                                                "UpShot"
                                                :
                                                report.hasOwnProperty("comment") !== null
                                                    ?
                                                    "Comment"
                                                    :
                                                    null
                                    }
                                </div>
                                <p className="user-info">Reported by: {report.reported_by_username} | Date: {report.timestamp}</p>
                                <p className="report-content">{report.reported_third_step_detail}</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Timestamp</th>
                                            <th>Impact type</th>
                                            <th>Report severity</th>
                                            <th>Reported main reason</th>
                                            <th>Reported second step</th>
                                            <th>Reported third detail</th>
                                            <th>Is Reviewed?</th>
                                            <th>Is Resolved?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{report.timestamp}</td>
                                            <td>{report.impact_type}</td>
                                            <td>{report.report_severity}</td>
                                            <td>{report.reported_main_reason}</td>
                                            <td>{report.reported_second_step}</td>
                                            <td>{report.reported_third_step_detail}</td>
                                            <td className={`${report.is_reviewed ? "resolved" : "not-resolved"}`}>{report.is_reviewed ? <>Yes</> : <>No</>}</td>
                                            <td className={`${report.is_resolved ? "resolved" : "not-resolved"}`}>{report.is_resolved ? <>Yes</> : <>No</>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr />
                                {
                                    report.reportcopyright.length !== 0 &&
                                    <>
                                        <div className="detail-container">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Reporting for?</th>
                                                        <th>Company name</th>
                                                        <th>Copyright individual type</th>
                                                        <th>Copyright owner name</th>
                                                        <th>Country</th>
                                                        <th>Descrive infringment</th>
                                                        <th>Description</th>
                                                        <th>Direct link of O/W</th>
                                                        <th>Link of R/C</th>
                                                        <th>Email</th>
                                                        <th>Address</th>
                                                        <th>Phone</th>
                                                        <th>Profile Link</th>
                                                        <th>Is Resolved?</th>
                                                        <th>Is Reviewed?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{report.reportcopyright[0].type_of_reporting_content}</td>
                                                        <td>{report.reportcopyright[0].company_name}</td>
                                                        <td>{report.reportcopyright[0].copyright_individual_type}</td>
                                                        <td>{report.reportcopyright[0].copyright_owner_full_name}</td>
                                                        <td>{report.reportcopyright[0].country}</td>
                                                        <td>{report.reportcopyright[0].describe_infringment}</td>
                                                        <td>{report.reportcopyright[0].description}</td>
                                                        <td><a href={report.reportcopyright[0].direct_link_of_original_work} target="_blank" rel="noopener noreferrer">{textTrimmer(report.reportcopyright[0].direct_link_of_original_work, 5)}</a></td>
                                                        <td><a href={report.reportcopyright[0].direct_link_of_reporting_content} target="_blank" rel="noopener noreferrer">{textTrimmer(report.reportcopyright[0].direct_link_of_reporting_content, 5)}</a></td>
                                                        <td>{report.reportcopyright[0].email}</td>
                                                        <td>{report.reportcopyright[0].full_address}</td>
                                                        <td>{report.reportcopyright[0].phone_number}</td>
                                                        <td><a href={report.reportcopyright[0].profile_link} target="_blank" rel="noopener noreferrer">{textTrimmer(report.reportcopyright[0].profile_link, 5)}</a></td>
                                                        <td className={`${report.reportcopyright[0].is_resolved ? "resolved" : "not-resolved"}`}>{report.reportcopyright[0].is_resolved ? <>Yes</> : <>No</>}</td>
                                                        <td className={`${report.reportcopyright[0].is_resolved ? "resolved" : "not-resolved"}`}>{report.reportcopyright[0].is_reviewed ? <>Yes</> : <>No</>}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                    </>
                                }
                                <div className="detail-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Language</th>
                                                <th>Platform</th>
                                                <th>Screen</th>
                                                <th>User Agent</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{report.additional_device_info.language}</td>
                                                <td>{report.additional_device_info.platform}</td>
                                                {/* <td>{report.additional_device_info.screen}</td> */}
                                                <td>NONE</td>
                                                <td>{report.additional_device_info.userAgent}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    }
                </div>
            }

        </>
    )
}

export default UserReportedItems;