import "./contentPolicy.css";
import UserLegalReadablesActions from "../UserLegalReadablesActions";
import LayoutComponentForHelmet from "../../../../MainInterface/SEO/LayoutComponentForHelmet";

function ContentPolicy() {

    return (
        <LayoutComponentForHelmet pageTitle={"Content Policy - SpreeCine"}>
            <div className="body-space">
                <div className="content-policy-main">

                    <h2 className="heading">Content Policy</h2>
                    <UserLegalReadablesActions />

                    <b>Following these rules is like sticking to the plot of an epic adventure movie – stay on course, and the journey will be filled with fun and excitement.</b>
                    <br />
                    <br />

                    <ul className="content-policy-rules">
                        <span className="cp-rn">1. NO Hate Speech and Discrimination</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Content that promotes hate speech, discrimination, racism, sexism, xenophobia, homophobia, or any form of bigotry is strictly prohibited.</span>
                        </li>
                        <span className="cp-rn">2. NO Violence and Threats</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Content that promotes violence, self - harm, harm to others, terrorism, or any form of physical harm is strictly prohibited.</span>
                        </li>
                        <span className="cp-rn">3. NO Illegal Activities</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Content that encourages or discusses illegal activities, such as drug trafficking, terrorism, or any other criminal activities, is not allowed.</span>
                        </li>
                        <span className="cp-rn">4. NO Nudity and Sexual Content</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Explicit adult content, nudity, pornography, or sexually suggestive material is strictly not permitted.</span>
                        </li>
                        <span className="cp-rn">5. Personal Information</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Sharing personal information of others without consent, including addresses, phone numbers, and financial details, is strictly prohibited.</span>
                        </li>
                        <span className="cp-rn">6. Intellectual Property</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Content that violates copyrights, trademarks, patents, or any other intellectual property rights is not permitted.Users should respect and adhere to intellectual property laws.</span>
                        </li>
                        <span className="cp-rn">7. Respectful Behavior</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Users are expected to treat each other with respect and maintain a civil tone in discussions.Disagreements should be expressed in a respectful and constructive manner.</span>
                        </li>
                        <span className="cp-rn">8. Reporting and Moderation</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Users are encouraged to report any violations of the content policy to moderators.Moderators will enforce the rules and may take appropriate actions against violators.</span>
                        </li>
                        <span className="cp-rn">9. Spam and Unwanted Content</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Posting repetitive or irrelevant content, advertisements, or spam is not allowed.Users should refrain from excessive self - promotion.</span>
                        </li>
                        <span className="cp-rn">9. Just like in the movies, don't script a site-break scene</span>
                        <li className="cpr-item">
                            <span className="cp-rt">Please refrain from engaging in activities that disrupt the functioning of the website or cause interference in its normal operations.</span>
                        </li>
                    </ul>

                    By implementing and enforcing these content policies, the platform can aim to create a safe, inclusive, and informative community for its users.

                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default ContentPolicy;