import React, { useEffect, useState } from 'react';
import sanitize from 'sanitize-html';
import { Link } from 'react-router-dom';

import "./helpCenter.css";
import helpQA from './helpQA';
import LayoutComponentForHelmet from '../../../MainInterface/SEO/LayoutComponentForHelmet';
import SpreeCineLoginSignupSVGLogo from '../../../MainInterface/assets/SpreeCineLoginSignupLogoSVG';


const AccordionItemSanitizedContent = ({ content }) => {

    const sanitizeHTMLContent = (content) => {
        return sanitize(content, {
            allowedTags: ['b', 'i', 'em', 'strong', 'p', 'a', "br", "ul", "li", "small", "i"],
            allowedAttributes: {
                "a": ["href", "target", "className"],
                "ul": ["className"],
            }
        });
    };

    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizeHTMLContent(content) }} />
    );
};

function HelpCenter() {
    const [openAccordionId, setOpenAccordionId] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [matchedItems, setMatchedItems] = useState([]);

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.slice(1);
            setOpenAccordionId(hash);
        };

        window.addEventListener('hashchange', handleHashChange);
        handleHashChange();

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    const toggleAccordion = (accordionId) => {
        setOpenAccordionId((prevId) => (prevId === accordionId ? null : accordionId));
    };

    const handleInputChange = (event) => {
        const rawValue = event.target.value.trim().toLowerCase();
        const colonIndex = rawValue.indexOf(':');

        let category = '';
        let textQuery = '';

        if (colonIndex !== -1) {
            category = rawValue.slice(0, colonIndex).trim();
            textQuery = rawValue.slice(colonIndex + 1).trim();
        } else {
            textQuery = rawValue;
        }

        let matchingItems;
        if (category && textQuery) {
            matchingItems = helpQA.filter(
                (item) =>
                    item.category.toLowerCase() === category &&
                    (item.content.toLowerCase().includes(textQuery) ||
                        item.title.toLowerCase().includes(textQuery))
            );
        } else if (category) {
            matchingItems = helpQA.filter((item) => item.category.toLowerCase() === category);
        } else {
            matchingItems = helpQA.filter(
                (item) =>
                    item.content.toLowerCase().includes(textQuery) ||
                    item.title.toLowerCase().includes(textQuery)
            );
        }

        setMatchedItems(matchingItems);
        setInputValue(event.target.value);
    };

    const groupedItems = {};
    helpQA.forEach((item) => {
        if (!groupedItems[item.category]) {
            groupedItems[item.category] = [];
        }
        groupedItems[item.category].push(item);
    });

    return (
        <LayoutComponentForHelmet pageTitle={"Help Center - SpreeCine"}>
            <div className="help-center-accordin-container">
                <div className="help-center-accordin-main">
                    <header className="help-header">
                        <Link to="/">
                            <b className='help-center-app-name-label'>
                                <SpreeCineLoginSignupSVGLogo svgsizewidth={"50%"} bgcolor={"rgb(68, 68, 68)"} />
                            </b>
                        </Link>
                        <b className='help-center-last-updated'>Last updated: 14-01-2024</b>
                        <h1>Help Center</h1>
                    </header>
                    <div className="help-search-input-container">
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            className='help-search-field'
                            placeholder="Search..."
                        />
                    </div>

                    {
                        inputValue ?
                            <>
                                {matchedItems.length > 0 ? (
                                    matchedItems.map((section) => (
                                        <>
                                            {
                                                !section.disabled ?
                                                    <div id={section.id} className={`accordion-item ${openAccordionId === section.id ? 'hc-active' : ''}`} data-id={section.id} onClick={() => toggleAccordion(section.id)}>
                                                        <a href={`#${section.id}`} className={`accordion-header ${openAccordionId === section.id ? 'hc-active' : ''}`}>
                                                            {section.title}
                                                            <span className="arrow">&#9662;</span>
                                                        </a>
                                                        <div className={`accordion-content ${openAccordionId === section.id ? 'active-ac' : ''}`}>
                                                            <AccordionItemSanitizedContent content={section.content} />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </>
                                    ))
                                )
                                    :
                                    <div className='clr-red text-align-center'>Didn't find what you were looking for? <Link to={"/contact"} className='contact-us-link'>Contact us here</Link></div>
                                }
                            </>
                            :
                            <>
                                {Object.entries(groupedItems).map(([category, items]) => (
                                    <div key={category}>
                                        <h2>{category}</h2>
                                        {items.map((section) => (
                                            <>
                                                {
                                                    !section.disabled ?
                                                        <div id={section.id} className={`accordion-item ${openAccordionId === section.id ? 'hc-active' : ''}`} data-id={section.id} onClick={() => toggleAccordion(section.id)}>
                                                            <a href={`#${section.id}`} className={`accordion-header ${openAccordionId === section.id ? 'hc-active' : ''}`}>
                                                                {section.title}
                                                                <span className="arrow">&#9662;</span>
                                                            </a>
                                                            <div className={`accordion-content ${openAccordionId === section.id ? 'active-ac' : ''}`}>
                                                                <AccordionItemSanitizedContent content={section.content} />
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </>
                                        ))}
                                    </div>
                                ))}
                            </>
                    }
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default HelpCenter;