import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

function AdminDashboardDropdown({ isDropdownOpen, setIsDropdownOpen }) {
    const params = useParams();
    const [currentId, setCurrentId] = useState("");

    useEffect(() => {
        setCurrentId(params.id);
    }, [])

    return (
        <>
            <b className="toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 896q-33 0-56.5-23.5T400 816q0-33 23.5-56.5T480 736q33 0 56.5 23.5T560 816q0 33-23.5 56.5T480 896Zm0-240q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm0-240q-33 0-56.5-23.5T400 336q0-33 23.5-56.5T480 256q33 0 56.5 23.5T560 336q0 33-23.5 56.5T480 416Z" /></svg></b>
            <div className={`admin-dashboard-dropdown ${!isDropdownOpen ? `display-none` : null}`}>
                <Link to={`/admin/dashboard/${currentId}`}>Dashboard</Link>
                <Link to={`/admin/dashboard/api/${currentId}`}>API</Link>
                <Link to={`/admin/dashboard/banned-users/${currentId}`}>Banned Users</Link>
                <Link to={`/admin/dashboard/users/${currentId}/?page=1`}>Users</Link>
                <Link to={`/admin/dashboard/all-reports/${currentId}/?page=1`}>Reports</Link>
                <Link to={`/admin/dashboard/content/${currentId}/?page=1&sectionType=upshots`}>Content</Link>
                <hr />
                <a href="#">Conclusions</a>
                <Link to={`/admin/dashboard/analytics/${currentId}`}>Analytics</Link>
            </div>
        </>
    )
}

export default AdminDashboardDropdown;