import { useState, useEffect } from "react";
import axios from "axios";
import { Link, } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import "./styles/selectedContentPopup.css";
import pluralize from "../helpers/pluralize";
import PopoverCustom from "../helpers/Popover/PopoverCustom";
import { defaultPlaceholderImageIfNotPresentAny } from "../constants/constants";
import { Button, Modal, Text } from '@mantine/core';


function SelectedContentDetail({ openContentDetailPopup, onHideContentDetailPopup, data, currentUserUsername }) {
  const { t } = useTranslation();
  const [currentContentId, setCurrentContentId] = useState(0);
  const [isContentInWatching, setIsContentInWatching] = useState(false);

  const [currentContentData, setCurrentContentData] = useState({
    currentContentId: 0,
    mediaType: "",

    currentDataResponseDict: [],
    contentGenres: [],

    isContentInWatching: false,

    isDataLoading: true,
    dataFetchError: ""
  })

  useEffect(() => {
    setCurrentContentId(data.id)
  }, [data])

  useEffect(() => {
    if (currentContentId !== 0 && currentContentId !== undefined) {
      axios.get("/api/content/management/content-details-instant/get/", { params: { content_id: data.id, media_type: data.media_type, content_name: data.title ? data.title : data.name } }).then((res) => {
        console.log(res)
        setCurrentContentData({
          currentContentId: res.data.data.id,
          mediaType: data.media_type,

          currentDataResponseDict: res.data.data,
          contentGenres: res.data.genres,
        });
        setIsContentInWatching(res.data.data.watching_data);
      }).catch(() => {
      })
    }
  }, [currentContentId])


  const addRemoveToWatchings = () => {
    setIsContentInWatching(!isContentInWatching);
  }

  const removeFromWatchings = (id) => {
    const dataToSend = ({
      content_id: id
    })
    addRemoveToWatchings();
    axios.delete(`/api/content/main/direct-remove/watching/content/`, { data: dataToSend }).then(() => {
    }).catch(() => {
      addRemoveToWatchings();
    })
  }

  const addContentToWatching = (data) => {
    const { id, title, name, media_type, first_air_date, release_date, overview, poster_path } = data;
    const extractedData = { id, contentType: media_type, original_title: title ? title : name, release_date: first_air_date ? first_air_date : release_date, content_plot: overview, poster_path }
    const dataToSend = ({
      watcing_tv_movie_name: title ? title : name,
      watcing_tv_movie_id: id,
      content_data: extractedData,
    });
    addRemoveToWatchings();
    axios.post(`/api/content/main/watching/create/get-list/`, dataToSend).then(() => {
    }).catch(() => {
      addRemoveToWatchings();
    });
  }

  return (
    <Modal opened={openContentDetailPopup} onClose={onHideContentDetailPopup} radius="10px" size="xl" title={currentContentData.currentDataResponseDict.original_title ? currentContentData.currentDataResponseDict.original_title : currentContentData.currentDataResponseDict.name} centered>
      <div
        className={`content-detail-popup bg-img ${currentContentData.currentDataResponseDict.adult ? `blur-image` : ""}`}
        style={{ backgroundImage: !currentContentData.isDataLoading && "url(" + `https://image.tmdb.org/t/p/original/${currentContentData.currentDataResponseDict.backdrop_path}` + ")" }}
      >

        <div className="info">
          {currentContentData.currentDataResponseDict.poster_path !== null ?
            <img src={`https://image.tmdb.org/t/p/w300/${currentContentData.currentDataResponseDict.poster_path}`} className={`scd-poster ${currentContentData.currentDataResponseDict.adult ? `blur-image` : ""}`} />
            :
            <img src={defaultPlaceholderImageIfNotPresentAny} className='scd-poster' />
          }
          <div className="info-data">
            <Link
              to={`/content/${currentContentData.mediaType}/${currentContentData.currentDataResponseDict.original_title ? currentContentData.currentDataResponseDict.original_title?.replace(/\s+/g, "-") : currentContentData.currentDataResponseDict.name?.replace(/\s+/g, "-")}/${currentContentData.currentDataResponseDict.id}`}
              state={{ "id": currentContentData.currentDataResponseDict.id, "name": currentContentData.currentDataResponseDict.original_name ? currentContentData.currentDataResponseDict.original_name : currentContentData.currentDataResponseDict.name, "mediaType": currentContentData.mediaType }}
              className="clr-black"
              style={{ textDecoration: "none" }}
            >
              <h2 className='main-content-name'>{currentContentData.currentDataResponseDict.original_title ? currentContentData.currentDataResponseDict.original_title : currentContentData.currentDataResponseDict.name}</h2>
            </Link>

            <div className="details">
              {
                currentContentData.mediaType === "movie" ?
                  <span>{currentContentData.currentDataResponseDict.runtime} min</span>
                  :
                  <>
                    <span>{currentContentData.currentDataResponseDict.number_of_seasons} <small>{t("season")}{pluralize(currentContentData.currentDataResponseDict.number_of_seasons)}</small></span>
                    <span>{currentContentData.currentDataResponseDict.number_of_episodes} <small>{t("episode")}{pluralize(currentContentData.currentDataResponseDict.number_of_episodes)}</small></span>
                  </>
              }
            </div>

            <div className="genre">
              {currentContentData.contentGenres}
            </div>

            {
              currentContentData.mediaType == "tv" &&
              <div className='details'>
                {t("Status")} :
                <span className='clr-grn make-text-cursor'>
                  {t(currentContentData.currentDataResponseDict.status)}
                  <PopoverCustom t={t} messageTitle={null} messageBody={t("There will be more episodes of this series in the future")} />
                </span>
              </div>
            }
          </div>
        </div>
        {
          currentUserUsername ?
            <div className="scd-action-container">
              {
                isContentInWatching ?
                  <Button variant="outline" color="blue" className="atw-btn" onClick={() => removeFromWatchings(currentContentData.currentDataResponseDict.id)}>{t("Remove from Watchings")}</Button>
                  :
                  <Button variant="outline" color="blue" className="atw-btn" onClick={() => addContentToWatching(currentContentData.currentDataResponseDict)}>{t("Add to Watchings")}</Button>
              }
            </div>
            :
            null
        }
        <Text className="scd-details-plot">{currentContentData.currentDataResponseDict.overview}</Text>
      </div>
    </Modal>
  )
}

export default SelectedContentDetail;