import { useState } from 'react';
import { Button, Modal } from '@mantine/core';
import { useTranslation } from "react-i18next";

function InappropriateReportModal({ isInappropriateReportModalOpen, setInappropriateReportModalOpen, setMainReportShow, openAdditionalInfo, setSelectedReasonStepTwo }) {
    const { t } = useTranslation();
    const [selectedReason, setSelectedReason] = useState("");

    const backToMainReportModal = () => {
        setInappropriateReportModalOpen(false);
        setMainReportShow(true);
    }
    const updateSelectedReportReason = (value) => {
        if (value !== undefined && value !== null) {
            setSelectedReasonStepTwo(value);
            setSelectedReason(value);
        }
    }

    return (

        <Modal opened={isInappropriateReportModalOpen} onClose={backToMainReportModal} radius="10px" size="xl" title={t("Inappropriate content & behavior")} centered>
            <div className="report-area">
                <form onClick={(e) => updateSelectedReportReason(e.target.getAttribute("data-value"))}>
                    <ul>
                        <li className={`${selectedReason === "OFFCTNT" ? `selected` : ``}`} data-value="OFFCTNT">
                            {t("Offensive or inappropriate languauge/content")}
                            <br />
                            <span className='report-reason-description'>{t("You come across content that they find offensive, such as hate speech, racist or sexist language")}</span>
                        </li>
                        <li className={`${selectedReason === "INCIFO" && `selected`}`} data-value="INCIFO">
                            {t("Inaccurate information")}
                            <br />
                            <span className='report-reason-description'>{t("You believe the information presented is inaccurate or misleading")}</span>
                        </li>
                        <li className={`${selectedReason === "SPM" && `selected`}`} data-value="SPM">
                            {t("Spam or misleading content")}
                            <br />
                            <span className='report-reason-description'>{t("You believe the post contains spam or other misleading content, such as clickbait or false claims")}</span>
                        </li>
                        <li className={`${selectedReason === "DGABS" && `selected`}`} data-value="DGABS">
                            {t("Drug use or abuse")}
                            <br />
                            <span className='report-reason-description'>{t("It contains content that promotes or glorifies drug use or abuse")}</span>
                        </li>
                        <li className={`${selectedReason === "TRGTDHRSMNT" && `selected`}`} data-value="TRGTDHRSMNT">
                            {t("Includes targeted harassment")}
                            <br />
                            <span className='report-reason-description'>{t("It contains harassment or bullying behavior, such as if it targets a specific individual or group of individuals with the intent to harm or intimidate")}</span>
                        </li>
                        <li className={`${selectedReason === "HTRCRLGN" && `selected`}`} data-value="HTRCRLGN">
                            {t("It directs hate against a race, religion, gender, or orientation")}
                        </li>
                        <li className={`${selectedReason === "VLNCEHRM" && `selected`}`} data-value="VLNCEHRM">
                            {t("Threatening violence, physical harm or self-harm")}
                            <br />
                            <span className='report-reason-description'>{t("If you think this post contains language or behavior that suggests a user intends to harm themselves or others, either physically or emotionally")}</span>
                        </li>
                        <li className={`${selectedReason === "VLTNPLCY" && `selected`}`} data-value="VLTNPLCY">
                            {t("Violation of website policies")}
                            <br />
                            <span className='report-reason-description'>{t("It violates the website's terms of service or community guidelines. This can include things like spamming, posting illegal content, or engaging in other prohibited activities")}</span>
                        </li>
                    </ul>
                </form>
                <Button variant='filled' color='rgb(103, 103, 222)' fullWidth onClick={openAdditionalInfo} disabled={selectedReason === "" ? true : false}>{t("I'm sure")}</Button>
            </div>
        </Modal>
    )
}

export default InappropriateReportModal;