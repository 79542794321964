import { useEffect, useState } from "react";

import "./styles/welcomeMessage.css";
import UpShotRemarkIcon from "../upshot/icons/svgComponents/UpShotRemarkIconV2";
import TournamentIcon from "../discussions/icons/tournamentIcon";
import AudienceCountSVGIcon from "../Profile/icons/ProfileAudienceCount";
import UpShotIconV2 from "../upshot/icons/svgComponents/UpShotIconV2";
import DesignWorldIcon from "../upshot/icons/svgComponents/DesignWorldIcon";
import StorylineIcon from "../upshot/icons/svgComponents/StorylineIcon";
import UserOnPremiumBadgeIcon from "../Profile/icons/UserOnPremiumBadgeIcon";
import { Link } from "react-router-dom";

function SpreeCineShortAboutWelcomMessages({ ips }) {
    const [isSpreeCineWelcomeMessageModalOpen, setSpreeCineWelcomeMessageModalOpen] = useState(false);
    const [isSpreeCineWelcomeMessageNextStepModalOpen, setSpreeCineWelcomeMessageNextStepModalOpen] = useState(false);

    useEffect(() => {
        if (!ips) {
            setSpreeCineWelcomeMessageModalOpen(true)
        } else {
            setSpreeCineWelcomeMessageModalOpen(false)
        }
    }, [ips])

    const showNextModal = () => {
        setSpreeCineWelcomeMessageModalOpen(false);
        setSpreeCineWelcomeMessageNextStepModalOpen(true);
    }

    const goBackToFirstModal = () => {
        setSpreeCineWelcomeMessageModalOpen(true);
        setSpreeCineWelcomeMessageNextStepModalOpen(false);
    }

    const closeAllModal = () => {
        setSpreeCineWelcomeMessageModalOpen(false);
        setSpreeCineWelcomeMessageNextStepModalOpen(false);
    }

    if (isSpreeCineWelcomeMessageModalOpen) {
        return <SpreeCineShortAboutWelcomMessageFirstStep isSpreeCineWelcomeMessageModalOpen={isSpreeCineWelcomeMessageModalOpen} showNextModal={showNextModal} />
    }

    if (isSpreeCineWelcomeMessageNextStepModalOpen) {
        return <SpreeCineShortAboutWelcomMessageNextStep isSpreeCineWelcomeMessageNextStepModalOpen={isSpreeCineWelcomeMessageNextStepModalOpen} goBackToFirstModal={goBackToFirstModal} closeAllModal={closeAllModal} />
    }
}

function SpreeCineShortAboutWelcomMessageFirstStep({ isSpreeCineWelcomeMessageModalOpen, showNextModal }) {

    // if (!isSpreeCineWelcomeMessageModalOpen) {
    //     return null;
    // }

    return (
        <div className="sc-welcome-message-modal" id="myModal">
            <div className="sc-welcome-message-modal-content">
                <div className="sc-welcome-message-modal-info">
                    <h2 className="inline-block">Welcome to, SpreeCine</h2>
                </div>
                <div className="sc-wm-info-card-container">
                    <div className="sc-wm-info-card">
                        <UpShotIconV2 />
                        <Link to={"/upshots"} className="show-underline-on-hover"><h3>UpShots</h3></Link>
                        <p>Create UpShots by outlining your ideas, including concepts and storylines for movies and
                            television shows.</p>
                    </div>
                    <div className="sc-wm-info-card">
                        <UpShotRemarkIcon />
                        <Link to={`/upshots/?by=newest&from=everywhere&byCategory=remarks`} className="show-underline-on-hover"><h3>Remarks</h3></Link>
                        <p>Remarks on the platform are meant to showcase your storylines, plots, and obtain insights from
                            others' perspectives.</p>
                    </div>
                </div>
                <div className="sc-wm-info-card-container">
                    <div className="sc-wm-info-card">
                        <TournamentIcon />
                        <Link to={`/tournaments`} className="show-underline-on-hover"><h3>Tournaments</h3></Link>
                        <p>Engage in tournaments to express your ideas, influence others, and achieve victory, with the
                            opportunity of being showcased.</p>
                    </div>
                    <div className="sc-wm-info-card">
                        <AudienceCountSVGIcon />
                        <h3>Audience</h3>
                        <p>Enhance your audience reach through active engagement on the platform.</p>
                    </div>
                </div>
                <button className="sc-wm-next-button" onClick={showNextModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                        <path
                            d="m298-262-56-56 121-122H80v-80h283L242-642l56-56 218 218-218 218Zm222-18v-80h360v80H520Zm0-320v-80h360v80H520Zm120 160v-80h240v80H640Z" />
                    </svg>
                    More
                </button>
            </div>
        </div>
    )
}

function SpreeCineShortAboutWelcomMessageNextStep({ isSpreeCineWelcomeMessageNextStepModalOpen, goBackToFirstModal, closeAllModal }) {

    if (!isSpreeCineWelcomeMessageNextStepModalOpen) {
        return null;
    }

    return (
        <div className="sc-welcome-message-second-modal" id="secondModal">
            <div className="sc-welcome-message-modal-content">
                <div className="sc-welcome-message-modal-info">
                    <h2 className="inline-block">Welcome to, SpreeCine</h2>
                    {/* <button className="sc-wm-more-button">More</button> */}
                </div>
                <div className="sc-wm-info-card-container">
                    <div className="sc-wm-info-card">
                        <StorylineIcon />
                        <h3>Unexplored Storylines</h3>
                        <p>Explore an inventive and imaginative approach to exploring movie plots that surpasses your expectations.</p>
                    </div>
                    <div className="sc-wm-info-card">
                        <DesignWorldIcon />
                        <h3>Design your Own World</h3>
                        <p>Create and develop your own imaginative universe featuring unique cinematic storylines.</p>
                    </div>
                </div>
                <div className="sc-wm-info-card-container sc-wm-info-card-container-custom-style">
                    <div className="sc-wm-info-card sc-wm-info-card-custom-style">
                        <UserOnPremiumBadgeIcon />
                        <Link to={"/premium"} className="show-underline-on-hover"><h3>SpreeCine Premium</h3></Link>
                        <p>Enhance your audience reach through active engagement on the platform.</p>
                    </div>
                </div>
                <div className="sc-wm-info-container-button-container">
                    <button className="sc-wm-close-button" onClick={goBackToFirstModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                            <path
                                d="M220-240v-480h80v480h-80Zm520 0L380-480l360-240v480Zm-80-240Zm0 90v-180l-136 90 136 90Z" />
                        </svg>
                        Previous
                    </button>
                    <button className="sc-wm-close-button" onClick={closeAllModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                            <path
                                d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                        </svg>
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SpreeCineShortAboutWelcomMessages;