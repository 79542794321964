import React, { Suspense, lazy } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { EncryptStorage } from 'encrypt-storage';
import { Link } from 'react-router-dom';
import { useContextMenu } from "react-contexify";
import { t } from "i18next";
import { HoverCard, Avatar, Text, Group, Anchor, Stack } from '@mantine/core';

import './upshotDetailV2.css';
import MaintenanceMode from '../Preventation/Maintenance/maintenance';
import withRouter from '../helpers/withRouter';
import apiUrls from './api';
import pluralize from "../helpers/pluralize";
import InfoPopup from '../modals/InfoPopup';
import textTrimmer from "../helpers/stringCutter";
import showToastError from '../helpers/showToastError';
import ShareForPublicOnly from '../Share/ShareOpen';
import Identicon from 'react-identicons';
import MessageContext from '../../MainInterface/contexts/UserAdminMessage/UserAdminMessageProvider';
import BanContext from '../../MainInterface/contexts/UserBan/UserBanModalProvider';
import LoadingIfElseErrorSvg from "../animations/LoadingIfElseErrorSvg";
import SingleRemarkFetch from './UpShotDetailRemark/SingleRemarkFetch';
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';
import { defaultBackendOrigin, defaultOrigin } from '../constants/constants';
import UserDetailPopup from './UserDetailShortInfoPopup';

let defaultShareURL = `upshot`;

const LazyReportModal = lazy(() => import("../Report/Report"))
const LazyUserDetailPopup = lazy(() => import("./UserDetailShortInfoPopup"));

function saveTasteIntoLST(existingDataKey, newData, limit) {
    const encryptStorage = new EncryptStorage('secret-key-value', {
        storageType: "localStorage"
    });

    const existingData = encryptStorage.getItem(existingDataKey);
    // let existingIds = existingData ? JSON.parse(existingData) : [];
    let existingContent = existingData ? existingData : [];
    const mergedContent = [...existingContent, ...newData];
    if (mergedContent.length > limit) {
        existingContent = mergedContent.slice(mergedContent.length - limit)
    } else {
        existingContent = mergedContent;
    }
    encryptStorage.setItem(existingDataKey, mergedContent);
}

class UpShotDetail extends React.Component {
    constructor(props) {
        super(props);
        this.scrollToRemarksRef = React.createRef();
        this.scrollToRemarkMainCommentRef = React.createRef();
        this.contentEditableRef = React.createRef();
        this.popupRef = React.createRef();
        this.clickTimeout = null;
        this.state = {
            fetchedUpShotDetails: {},
            aboutTheAuthor: {},
            fetchedRemarks: [],
            likedRemarks: new Array(0).fill(false),

            fetchError: "",
            fetchLoading: false,
            hasMore: true,
            limit: 10,
            offset: 0,

            hashValue: 0,

            isMaintenanceOn: false,

            remarkText: "",
            isRemarkPostingLoading: false,
            remarkPostError: "",

            remarkAwardState: [],

            isReportModalOpen: false,
            reportInfo: 0,
            reportTypeContent: "",

            isShareModalOpen: false,
            isPubliShareModalOpen: false,

            countRemarks: 0,

            singleRemarkFetchData: {},
            isForRemarkComment: false,
            isSingleRemarkCommentSectionOpen: false,
            singleRemarkFetchComments: [],
            commentHighlightId: 0,

            currentUpShotTab: "upshot",
            shareData: {},

            isUserPopupOpen: false,
            popupPositionX: 0,
            popupPositionY: 0,

            clickCount: 0,

            relatedUpShots: {
                isRelatedLoading: false,
                relatedFetchError: "",

                fetchedRelatedUpShot: [],
            },
            isUpShotDropdownOpen: false,
            isRemarkSortDropdownOpen: false,
            remarkFilterSort: "2",
            isBanned: false,
        }
        window.onscroll = (e) => {
            const {
                loadUpShots,
                state: { fetchError, fetchLoading, hasMore }
            } = this;
            if (fetchError || fetchLoading || !hasMore) return;
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) {
                loadUpShots();
            }
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.debouncedGetRemarksAfterUpShotSuccess = debounce(this.getRemarksAfterUpShotSuccess, 500);
    }

    componentDidMount() {
        const hash = window.location.hash;
        const urlParamteres = window.location.search;
        const prepareParameters = new URLSearchParams(urlParamteres);

        const remarkId = prepareParameters.get("r");
        const commentId = prepareParameters.get("c");

        if (hash && hash.length > 1) {
            const hashValue = parseInt(hash.substring(1));
            if (!isNaN(hashValue)) {
                this.setState({ hashValue });
            }
        }
        window.addEventListener("scroll", this.handleScroll);
        this.getDetailsOfUpShot();
        if (remarkId && !isNaN(remarkId)) {
            this.fetchSingleRemarkIfInUrl(remarkId, commentId);
        }
        // this.saveAfter10SecondsOfTimeSpent();
    };

    openCloseUpShotDropdown = () => {
        this.setState({
            isUpShotDropdownOpen: !this.state.isUpShotDropdownOpen
        })
    }

    handleDoubleClick = (event, contentId, contextMenuContent) => {
        const { clickCount } = this.state;
        this.setState({ clickCount: clickCount + 1 });
        clearTimeout(this.clickTimeout);
        this.clickTimeout = setTimeout(() => {
            this.setState({ clickCount: 0 });
        }, 300);
        if (clickCount === 1) {
            this.handleDoubleTap(event, contentId, contextMenuContent);
        }
    }

    handleDoubleTap = (event, contentId, contextMenuContent) => {
        this.handleContextMenu(event, contentId, contextMenuContent)
    };

    handleContextMenu = (event, contentId, contextMenuContent) => {
        event.preventDefault();
        const contextMenuId = `${contextMenuContent}_${contentId}`
        useContextMenu({ id: contextMenuId }).show({ event });
    };

    scrollToRemarks = () => {
        if (this.scrollToRemarksRef.current) {
            this.scrollToRemarksRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;

        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset + 0.5;
        if (windowBottom > docHeight) {
            if (this.state.hasMore) {
                // this.getRemarksAfterUpShotSuccess();
                // ! UNCOMMENT THIS TO GET REMARKS ON SCROLL
                this.debouncedGetRemarksAfterUpShotSuccess();
            }
        }
    }

    fetchSingleRemarkIfInUrl(remarkId, commentId) {
        axios.get(`/api/content/main/single-remark-fetch/${remarkId}/`).then((res) => {
            if (res.data.message !== "REMARK_DOES_NOT_EXIST") {
                this.setState({
                    singleRemarkFetchData: res.data,
                });
            }
            // ! Check if passed comment Id is number, not integar with string.
            if (commentId && !isNaN(commentId)) {
                this.singleRemarkFetchCommentsFromServer(commentId)
                this.setState({
                    isForRemarkComment: true,
                    isSingleRemarkCommentSectionOpen: true,
                    openCloseSingleRemarkdata: remarkId,
                    commentHighlightId: commentId,
                })
            }
        }).catch(() => {
        })
    }

    singleRemarkFetchCommentsFromServer(commentId) {
        axios.get(`/api/content/main/single-remark-comment-fetch/${commentId}/`).then((res) => {
            if (Array.isArray(res.data) && res.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    singleRemarkFetchComments: res.data,
                }))
            }
        }).catch(() => {
        })
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        this.debouncedGetRemarksAfterUpShotSuccess.cancel();
    }

    getRemarksAfterUpShotSuccess(sortBy = "1") {
        this.setState({
            fetchLoading: true,
            fetchError: "",
        });
        const { offset, limit } = this.state;
        const { pathname } = this.props.router.location;
        const id = pathname.split('/').filter(Boolean).pop();
        axios.get(`/api/content/main/remark/create/${id}/?limit=${limit}&offset=${offset}&sortBy=${sortBy}`).then((res) => {
            const hasMore = res.data.has_more
            console.log(res)
            const newRemarks = res.data.remarks
            this.setState(prevState => {
                const uniqueFetchedContent = [
                    ...prevState.fetchedRemarks,
                    ...newRemarks.filter(newBlog =>
                        !prevState.fetchedRemarks.some(oldBlog => oldBlog.id === newBlog.id)
                    )
                ];
                return {
                    fetchLoading: false,
                    hasMore: hasMore,
                    fetchedRemarks: uniqueFetchedContent,
                    offset: offset + limit
                };
            });
            const allRewards = res.data.remarks.map(remark => remark.remark_award).flat();
            const uniqueValuesMap = new Map();

            const uniqueResponse = allRewards.filter(item => {
                const key = `${item.award_type}_${item.id}`;

                if (!uniqueValuesMap.has(key)) {
                    uniqueValuesMap.set(key, true);
                    return true;
                }

                return false;
            });

            this.setState({
                remarkAwardState: uniqueResponse,
            })
        }).catch(() => {
            this.setState({
                fetchLoading: false,
                fetchError: "Something went Wrong!",
            });
        })
    }

    getRemarksAfterUpShotSuccessForFilter(sortBy = "1") {
        this.setState({
            fetchLoading: true,
            fetchError: "",
        });
        const { limit } = this.state;
        const { pathname } = this.props.router.location;
        const id = pathname.split('/').filter(Boolean).pop();
        axios.get(`/api/content/main/remark/create/${id}/?limit=${limit}&offset=0&sortBy=${sortBy}`).then((res) => {
            const hasMore = res.data.has_more
            const newRemarks = res.data.remarks
            this.setState({
                hasMore,
                fetchedRemarks: newRemarks,
                fetchLoading: false,
                offset: 0,
                fetchError: "",
            });

            const allRewards = res.data.remarks.map(remark => remark.remark_award).flat();
            this.setState({
                remarkAwardState: allRewards,
            })
        }).catch(() => {
            this.setState({
                fetchLoading: false,
                fetchError: "Something went Wrong!",
            });
        })
    }

    // Transfer to Remark
    saveAwardToBackend(remarkId, awardAction, dataToPost) {
        axios.post(`/api/content/main/remark-award/${remarkId}/?awardAction=${awardAction}`, dataToPost).then(() => {
        }).catch(() => {
        })
    }

    // Transfer to Remark
    sendAwardOnRemark = (awardType, remarkId, isAwardPremium, isUserOnPremium) => {
        if (isAwardPremium && !isUserOnPremium) {
            showToastError("You need to have Premium Membership to give this award!", "info");
            return;
        }
        const dataToPost = ({
            awardType: awardType
        })

        const getUsername = this.props.currentUserUsername;
        this.setState((prevState) => {
            const awardTypeExists = prevState.remarkAwardState?.some(award => award.award_type === awardType && award.remark_id === remarkId);
            if (awardTypeExists) {
                const updatedAwards = prevState.remarkAwardState?.map((award) => {
                    if (award.award_type === awardType && award.remark_id === remarkId) {
                        if (award.awarded_by.includes(getUsername)) {
                            const updatedAwardedBy = award.awarded_by.filter(name => name !== getUsername);
                            if (updatedAwardedBy.length === 0) {
                                this.saveAwardToBackend(remarkId, "FINAL_DELETE", dataToPost);
                                return null;
                            } else {
                                return { ...award, awarded_by: updatedAwardedBy };
                            }
                        } else {
                            return { ...award, awarded_by: [...award.awarded_by, getUsername] }
                        }
                    } else {
                        return award
                    }
                }).filter(Boolean);
                return {
                    remarkAwardState: updatedAwards,
                    // lastAddedType: prevState.lastAddedType
                }
            } else {
                const { remarkAwardState } = this.state;
                const updatedAwards = remarkAwardState?.filter(
                    (award) => award.case !== "temp" || award.remark_id !== remarkId);
                const newAward = {
                    award_type: awardType,
                    awarded_by: [getUsername],
                    total_awards: 1,
                    remark_id: remarkId,
                    case: "temp",
                };
                const countIfAny = remarkAwardState?.filter((award) => award.case === "temp");
                if (countIfAny.length === 0) {
                    this.saveAwardToBackend(remarkId, "ADD", dataToPost);
                    showToastError(t("Award successfully submitted"), "SUCCESS")
                }
                // ? Updating from one award to another.
                if (countIfAny.length !== 0) {
                    if (!remarkAwardState?.some(award => award.awarded_by === getUsername)) {
                        this.saveAwardToBackend(remarkId, "UPDATE", dataToPost);
                        showToastError(t("Award successfully submitted"), "SUCCESS")
                    }
                }
                return {
                    remarkAwardState: [...updatedAwards, newAward]
                }
            }
        })
    }

    fetchRelatedUpShots = (id) => {
        axios.get(`/api/content/main/get/related/upshot/${id}/`).then((res) => {
            const transformedItems = res.data.map(item => {
                const dataLength = item.tv_movie_data.length;
                if (dataLength === 1) {
                    return {
                        id: item.id,
                        image: item.tv_movie_data[0].poster_path,
                        title: item.title,
                        description: item.description,
                    };
                } else if (dataLength > 1) {
                    return {
                        id: item.id,
                        image: item.tv_movie_data[Math.floor(Math.random() * dataLength)].poster_path,
                        title: item.title,
                        description: item.description,
                    };
                }
                return null;
            });
            this.setState({
                isRelatedLoading: false,
                relatedFetchError: "",
                fetchedRelatedUpShot: transformedItems
            });

        }).catch((err) => {
            this.setState({
                isRelatedLoading: false,
                relatedFetchError: err.message,
            })
        })
    }

    getDetailsOfUpShot() {
        const { pathname } = this.props.router.location;
        const id = pathname.split('/').filter(Boolean).pop();
        this.setState({
            isRelatedLoading: true,
            relatedFetchError: "",
        })
        const apiUrlCall = apiUrls.getUpShotDetails(id);
        axios.get(apiUrlCall).then((res) => {
            this.setState(prevState => ({
                fetchedUpShotDetails: { ...this.state.fetchedUpShotDetails, ...res.data },
                aboutTheAuthor: { ...this.state.aboutTheAuthor, ...res.data.about_author[0] },
                countRemarks: res.data.count_all_remarks.total_remarks,
                isBanned: res.data.is_current_user_banned,
            }))
            // ! UNCOMMENT THIS TO GET REMARKS
            // this.debouncedGetRemarksAfterUpShotSuccess();
            if (res.status === 200) {
                this.fetchRelatedUpShots(id);
            }
        }).catch(() => {
            this.setState({
                isRelatedLoading: false,
                relatedFetchError: "Something went Wrong!",
            })
        })
    }

    addMeInUserAudience = (receiverUser) => {
        const data = ({
            receiver_id: receiverUser,
        })
        axios.post(`/api/audience/audience-request/`, data).then((res) => {
            showToastError(res.data.message, "success")
        }).catch(() => {
            showToastError(t("Something went Wrong while adding to Audience!"), "error");
        })
    }

    openReportModal = (id, contentType) => {
        this.setState({ isReportModalOpen: true, reportInfo: id, reportTypeContent: contentType });
    }

    openShareModal = (contentId, upshotId, isRemarkOwnerSameAsCurrentUser, pageType, additionalId) => {
        let prepareUrlForShare = defaultShareURL + `/${upshotId}/`;
        if (contentId && pageType === "UR") {
            prepareUrlForShare = prepareUrlForShare + `/?r=${contentId}`
        } else if (contentId && pageType === "URC") {
            prepareUrlForShare = prepareUrlForShare + `/?r=${additionalId}&c=${contentId}`
        }
        this.setState(prevState => ({
            shareData: {
                ...prevState.shareData,
                id: contentId,
                additionalId: additionalId,
                shareUrl: prepareUrlForShare,
                pageType: pageType,
            }
        }));
        this.setState({
            isPubliShareModalOpen: true,
        });
    }

    addNewRemark(upShotId) {
        const dataToPost = {
            upshot: upShotId,
            content: this.state.remarkText,
        }
        if (this.state.remarkText !== "") {
            this.setState({
                isRemarkPostingLoading: true,
                remarkPostError: "",
            })
            axios.post(`/api/content/main/remark/create/${upShotId}/`, dataToPost,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            ).then((res) => {
                this.setState({
                    fetchedRemarks: [res.data, ...this.state.fetchedRemarks],
                    // remarkSortDropdownValue: "newest",
                    remarkText: "",
                    countRemarks: this.state.countRemarks + 1,
                    isRemarkPostingLoading: false,
                    remarkPostError: "",
                })
                showToastError(t("Remark successfully Posted"), "success");

                const saveUserTasteTimeout = setTimeout(() => {
                    this.saveTaste("remark_created");
                }, 2000);
                return () => clearTimeout(saveUserTasteTimeout);
            }).catch((err) => {
                this.setState({
                    isRemarkPostingLoading: false,
                    remarkPostError: "Something went Wrong!",
                });
                if (err.response.status === 401) {
                    showToastError(t("You're Banned. You cannot post Remarks."), "error")
                }
            })
        }
    }

    saveTaste(contentType) {
        const formattedDataContentId = this.state.fetchedUpShotDetails.tv_movie_data.map(({ id, contentType }) => ({ [contentType === "movies" ? "movie" : "tv"]: id }));
        if (this.props.currentUserUsername) {
            const dataToPost = ({
                visited_upshot_ids: formattedDataContentId,
                visited_upshot_genres: this.state.fetchedUpShotDetails.tv_movie_genre,

                "content_type": contentType,
                "id": this.state.fetchedUpShotDetails.id,
            });
            axios.post('/api/user/t/save/user/taste/', dataToPost).then(() => {
            })
        }
    }

    // ? Send the id and content type to backend when 10 seconds passes after opening UpShot.
    saveAfter10SecondsOfTimeSpent() {
        const timeId = setTimeout(() => {
            this.saveTaste("upshot");
        }, 10000)
        return () => {
            clearTimeout(timeId)
        };
    }

    setTab(tabAction) {
        this.setState({
            currentUpShotTab: tabAction,
        })
    }

    makeLikeAnimation = (action) => {
        const updatedUpShotLikeState = { ...this.state.fetchedUpShotDetails };
        updatedUpShotLikeState.is_user_liked_upshot = !this.state.fetchedUpShotDetails.is_user_liked_upshot
        this.setState({
            fetchedUpShotDetails: updatedUpShotLikeState,
        })
        if (action === "LIKE") {
            this.setState(prevState => ({
                fetchedUpShotDetails: {
                    ...prevState.fetchedUpShotDetails,
                    count_likes: prevState.fetchedUpShotDetails.count_likes - 1
                }
            }));
        } else {
            this.setState(prevState => ({
                fetchedUpShotDetails: {
                    ...prevState.fetchedUpShotDetails,
                    count_likes: prevState.fetchedUpShotDetails.count_likes + 1
                }
            }));
        }
    }

    likeUpShot = (action) => {
        this.makeLikeAnimation(action);
        axios.post(`/api/content/main/upshot/${this.state.fetchedUpShotDetails.id}/like/`).then((res) => {
            this.saveTaste("liked")
        }).catch(() => {
            this.makeLikeAnimation(action);
        })
    }

    openUserPopup = (e) => {
        // this.timer = setTimeout(() => {
        //     this.setState({
        //         isUserPopupOpen: true,
        //         popupPositionX: e.clientX,
        //         popupPositionY: e.clientY,
        //     })
        // }, 350);
        this.setState({
            isUserPopupOpen: true,
        })
    }

    handleMouseLeave = (e) => {
        this.timer = setTimeout(() => {
            this.setState({
                isUserPopupOpen: false,
                popupPositionX: e.clientX,
                popupPositionY: e.clientY,
            })
        }, 300);
    };
    handlePopupMouseEnter = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };
    handlePopupMouseLeave = (e) => {
        this.handleMouseLeave(e);
    };

    updateRemarkListAfterRemarkEdit = (remarkId, newlyRemarkEditedContentValue) => {
        this.setState(prevState => ({
            ...prevState,
            fetchedRemarks: prevState.fetchedRemarks.map((remark) => {
                if (remark.id === remarkId) {
                    return { ...remark, content: newlyRemarkEditedContentValue }
                }
                return remark
            }),
        }))
    }
    removeRemarkFromRemarkListAfterDeletion = (remarkDeletionId) => {
        setTimeout(() => {
            this.setState(prevState => ({
                ...prevState,
                fetchedRemarks: prevState.fetchedRemarks.filter((remark) => remark.id !== remarkDeletionId),
                countRemarks: prevState.countRemarks - 1,
            }));
        }, 700);
    }

    openCloseRemarkSortDropdown = () => {
        this.setState({
            isRemarkSortDropdownOpen: !this.state.isRemarkSortDropdownOpen
        })
    }

    updateRemarkSortFilter(sortBy) {
        this.setState({
            remarkFilterSort: sortBy
        });
        this.getRemarksAfterUpShotSuccessForFilter(sortBy);
    }

    render() {
        const customSteps = [
            { "title": "Explain more!", "description": "Offer a brief overview/scenarios of movies,shows." },
            { "title": "Be polite", "description": "Try to be polite while posting something." },
            { "title": "Double click menu", "description": "Double click on Remark, Comment to open Menu." },
        ];

        const {
            isMaintenanceOn,
            fetchedUpShotDetails,
            aboutTheAuthor,
            countRemarks,
            isRemarkPostingLoading,
            remarkPostError,
            remarkText,
            fetchedRemarks,
            currentUpShotTab,
            fetchLoading,
            fetchError,
            fetchedRelatedUpShot,
            isRelatedLoading,
            relatedFetchError,

            isRemarkSortDropdownOpen,
            singleRemarkFetchData,
        } = this.state;
        if (isMaintenanceOn) {
            return <MaintenanceMode />
        }
        return (
            <LayoutComponentForHelmet pageTitle={`${fetchLoading ? "SpreeCine" : `${textTrimmer(fetchedUpShotDetails.title, 10)} - SpreeCine`}`}>
                <MessageContext.Consumer>
                    {({ openModMessageModal }) => (
                        <BanContext.Consumer>
                            {({ openBanModel }) => (
                                <div className='body-space'>
                                    {/* <Suspense fallback={null}> */}
                                    {/* <UserDetailPopup
                                        t={t}
                                        isOpen={this.state.isUserPopupOpen}
                                        openUserPopup={(e) => this.openUserPopup(e)}
                                        name={aboutTheAuthor.full_name}
                                        userId={fetchedUpShotDetails.user}
                                        description={aboutTheAuthor.about}
                                        imageUrl={aboutTheAuthor.profile_image}
                                        // popupPositionX={this.state.popupPositionX}
                                        // popupPositionY={this.state.popupPositionY}

                                        // onMouseEnter={this.handlePopupMouseEnter}
                                        // onMouseLeave={this.handlePopupMouseLeave}
                                    /> */}
                                    {/* </Suspense> */}
                                    {/* <div className="upshot-header-image">
                                            <img src="https://wallpapershome.com/images/pages/pic_h/21486.jpg"
                                                alt="" />
                                            <div className="upshot-small-over-image">
                                                <img src="https://image.tmdb.org/t/p/w500//ym1dxyOk4jFcSl4Q2zmRrA5BEEN.jpg" alt="" />
                                            </div>
                                        </div> */}

                                    <div className="upshot-detail-main-container">
                                        <div className="left-section">
                                            <div className="author-info">
                                                {/* {
                                                    aboutTheAuthor.profile_image ?
                                                        <img src={`${defaultBackendOrigin}/media/${aboutTheAuthor.profile_image}`} onMouseEnter={(e) => this.openUserPopup(e)} onMouseLeave={(e) => this.handleMouseLeave(e)} alt="" />
                                                        :
                                                        <div onMouseEnter={(e) => this.openUserPopup(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}>
                                                            <Identicon string={this.props.currentUserUsername} size="25" />
                                                        </div>
                                                } */}
                                                <h2>{aboutTheAuthor.full_name}</h2>
                                            </div>
                                        </div>
                                        <div className="middle-section">
                                            <div className="blog-description">
                                                <div className="custom-hr-disable-when-small"></div>
                                                <div className="upshot-detail-main-title">
                                                    <h2>
                                                        {fetchedUpShotDetails.title}
                                                        <span className="detail-text">
                                                        <UserDetailPopup
                                                                        t={t}
                                                                        isOpen={this.state.isUserPopupOpen}
                                                                        openUserPopup={this.openUserPopup}
                                                                        name={aboutTheAuthor.full_name}
                                                                        userId={fetchedUpShotDetails.user}
                                                                        description={aboutTheAuthor.about}
                                                                        imageUrl={aboutTheAuthor.profile_image}
                                                                    // popupPositionX={this.state.popupPositionX}
                                                                    // popupPositionY={this.state.popupPositionY}

                                                                    // onMouseEnter={this.handlePopupMouseEnter}
                                                                    // onMouseLeave={this.handlePopupMouseLeave}
                                                                    />
                                                        </span>
                                                    </h2>
                                                </div>

                                                <div className="upshot-tab">
                                                    <input type="radio" name="tabs" id="upshot-nav-1" value="upshot" onChange={() => this.setTab("upshot")} />
                                                    <label htmlFor="upshot-nav-1" className={`${currentUpShotTab === "upshot" ? "active" : ""}`}>
                                                        {t("UpShot")}
                                                    </label>
                                                    <input type="radio" name="tabs" id="upshot-nav-2" value="aboutContent" onChange={() => this.setTab("aboutContent")} />
                                                    <label htmlFor="upshot-nav-2" className={`${currentUpShotTab === "aboutContent" ? "active" : ""}`}>
                                                        {t("About content")}
                                                    </label>

                                                    <div className="upshot-tabs">
                                                        {
                                                            currentUpShotTab === "upshot" &&
                                                            <>
                                                                {fetchedUpShotDetails.description &&
                                                                    <div className={`upshot-detail-data-main-detail`}>
                                                                        <p>
                                                                            {fetchedUpShotDetails.description}
                                                                        </p>
                                                                    </div>
                                                                }
                                                            </>
                                                        }

                                                        {
                                                            currentUpShotTab === "aboutContent" &&
                                                            <div className={`upshot-detail-data`}>
                                                                {
                                                                    fetchedUpShotDetails?.tv_movie_data?.map((content, index) =>
                                                                        <div className="movie-container" key={content.original_title}>
                                                                            <div className="movie-poster">
                                                                                <img src={`https://image.tmdb.org/t/p/w500${content.poster_path}`} alt="Movie Poster 1" />
                                                                            </div>
                                                                            <div className="movie-details">
                                                                                <h2 className="movie-title">{content.original_title}</h2>
                                                                                <p className="movie-rating">{t("Rating")}: 8.5/10</p>
                                                                                <p className="movie-description">
                                                                                    <strong>{t("Release Year")} : </strong> {content.release_date}<br />
                                                                                    <strong>{t("Genres")}:</strong> Action, Adventure<br />
                                                                                    <strong>{t("Plot")}:</strong>{content.content_plot}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right-section">
                                            <ul className="upshot-actions">
                                                <div className="upshot-detail-actions-svg-container-main">
                                                    <div className="upshot-detail-actions-svg-container">
                                                        {
                                                            fetchedUpShotDetails.is_user_liked_upshot ?
                                                                <div className="upshot-detail-like-svg">
                                                                    <svg className='make-text-cursor fill-purple' onClick={() => this.likeUpShot("LIKE")} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" /></svg>
                                                                    {
                                                                        fetchedUpShotDetails.count_likes !== 0 &&
                                                                        <div className="upshot-detail-like-svg-count">{fetchedUpShotDetails.count_likes}</div>
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="upshot-detail-like-svg">
                                                                    <svg onClick={() => this.likeUpShot("UNLIKE")} className='make-text-cursor' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                                                        <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
                                                                    </svg>
                                                                    {
                                                                        fetchedUpShotDetails.count_likes !== 0 &&
                                                                        <div className="upshot-detail-like-svg-count">{fetchedUpShotDetails.count_likes}</div>
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="upshot-detail-actions-svg-container" onClick={this.scrollToRemarks}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                                            <path
                                                                d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" />
                                                        </svg>
                                                    </div>
                                                    <div className="upshot-detail-actions-svg-container" onClick={() => this.openShareModal(fetchedUpShotDetails.id, fetchedUpShotDetails.id, fetchedUpShotDetails.is_upshot_user_is_request_user, "U")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                                            <path
                                                                d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z" />
                                                        </svg>
                                                    </div>
                                                    <div className="upshot-detail-actions-svg-container" onClick={this.openCloseUpShotDropdown}>
                                                        <svg className='dropdown-svg svg-hover-dark-blue' xmlns="http://www.w3.org/2000/svg" height="24"
                                                            viewBox="0 96 960 960" width="24">
                                                            <path
                                                                d="M480 896q-33 0-56.5-23.5T400 816q0-33 23.5-56.5T480 736q33 0 56.5 23.5T560 816q0 33-23.5 56.5T480 896Zm0-240q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm0-240q-33 0-56.5-23.5T400 336q0-33 23.5-56.5T480 256q33 0 56.5 23.5T560 336q0 33-23.5 56.5T480 416Z" />
                                                        </svg>
                                                        <div className={`upshot-detail-option-area ${!this.state.isUpShotDropdownOpen ? "display-none" : ""}`}>
                                                            <span openReportModal={() => this.openReportModal(fetchedUpShotDetails.id, "upshot")}>{t("Report UpShot")}</span>
                                                            {
                                                                sessionStorage.getItem("aamt") !== null && sessionStorage.getItem("aamt").length === 13 ?
                                                                    <>
                                                                        <hr className="tools-separator" />
                                                                        <span className="tools-label">Mod tools</span>
                                                                        <hr className="tools-separator" />
                                                                        <span onClick={() => openBanModel(fetchedUpShotDetails.user)}>Ban User</span>
                                                                        <span onClick={() => openModMessageModal(fetchedUpShotDetails.id, "upshot", fetchedUpShotDetails.user)}>Message User</span>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="custom-hr"></div>
                                                <div className="upshot-right-section-container">
                                                    <h2>
                                                        {t("Related UpShots")}
                                                        <LoadingIfElseErrorSvg isLoading={isRelatedLoading} errorString={relatedFetchError} classNamesForLoading={`error-retry retry-rotate fill-white`} classNamesForError={`svg-vertical-align-middle fill-red`} />
                                                    </h2>
                                                    <hr className='related-upshot-separator' />
                                                    <div className="related-ups-container">
                                                        <ul className="related-ups-list">
                                                            {
                                                                fetchedRelatedUpShot?.map((upshot) =>
                                                                    <li className="ups-item" key={upshot.id}>
                                                                        <div className="ups-content">
                                                                            <Link to={`/upshot/${upshot.id}/`} target='_blank'><h5 className="ups-title">{textTrimmer(upshot.title, 30)}</h5></Link>
                                                                            <span className="ups-description">{textTrimmer(upshot.description, 30)}</span>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className={`comment-container`} ref={this.scrollToRemarksRef}>
                                        <div className='remark-label-remark-sort'>
                                            <div className='inline-block remark-main-label'>{t("Remark")}{pluralize(countRemarks)} <span className='upshot-remark-count'>{countRemarks}</span></div>
                                            <div className='inline-block remark-sort make-text-cursor' onClick={this.openCloseRemarkSortDropdown}>
                                                <div className={`comment-sort-filter-dropdown ${isRemarkSortDropdownOpen ? "active-sort-dropdown" : ""}`}>
                                                    <span>{t("Sort by")} : {this.state.remarkFilterSort === "1" ? <>{t("Top Rated")}</> : <>{this.state.remarkFilterSort === "2" ? <>{t("Most Recent")}</> : <>{t("Oldest")}</>}</>}</span>
                                                    <div className="filter-content-opt">
                                                        <span className="comment-filter-item" onClick={() => this.updateRemarkSortFilter("1")}>{t("Top Rated")} <small>{this.state.remarkFilterSort === "1" && <svg className='svg-inline fill-purple flt-rgt' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" /></svg>}(default)</small></span>
                                                        <span className="comment-filter-item" onClick={() => this.updateRemarkSortFilter("2")}>{t("Most Recent")} <small>{this.state.remarkFilterSort === "2" && <svg className='svg-inline fill-purple flt-rgt' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" /></svg>}</small></span>
                                                        <span className="comment-filter-item" onClick={() => this.updateRemarkSortFilter("3")}>{t("Oldest")} <small>{this.state.remarkFilterSort === "3" && <svg className='svg-inline fill-purple flt-rgt' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" /></svg>}</small></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            Object.keys(singleRemarkFetchData).length !== 0 &&
                                            <SingleRemarkFetch
                                                t={t}
                                                singleRemarkFetchData={singleRemarkFetchData}
                                                state={this.state}

                                                sendAwardOnRemark={this.sendAwardOnRemark}
                                                openShareModal={this.openShareModal}
                                                fetchedUpShotDetails={fetchedUpShotDetails}
                                                addMeInUserAudience={this.addMeInUserAudience}
                                                jumpToMainComment={this.jumpToMainComment}
                                                scrollToRemarkMainCommentRef={this.scrollToRemarkMainCommentRef}
                                                handleDoubleClick={this.handleDoubleClick}
                                                openReportModal={this.openReportModal}

                                                updateRemarkListAfterRemarkEdit={this.updateRemarkListAfterRemarkEdit}
                                                removeRemarkFromRemarkListAfterDeletion={this.removeRemarkFromRemarkListAfterDeletion}

                                                isForComment={this.state.isForRemarkComment ? true : false}
                                                commentHighlightId={this.state.commentHighlightId ? this.state.commentHighlightId : null}
                                            />
                                        }

                                        <div className="comment-create-container">
                                            <img src="https://www.w3schools.com/howto/img_avatar.png" alt="User Avatar" className="commenter-image" />
                                            <div id="remarkForm" className="remark-form">
                                                <textarea type="text" className="remark-place-input" id="remark" name="remark" placeholder={t("Write your Remark here...")} value={remarkText} onInput={(e) => this.setState({ remarkText: e.target.value })} required autoComplete="off"></textarea>
                                                <button type="button" className="post-remark-button" onClick={() => this.addNewRemark(fetchedUpShotDetails.id)}>
                                                    <svg className="remark-post-svg" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"
                                                        width="24">
                                                        <path d="M120-160v-240l320-80-320-80v-240l760 320-760 320Z" />
                                                    </svg>
                                                    <LoadingIfElseErrorSvg isLoading={isRemarkPostingLoading} errorString={remarkPostError} classNamesForLoading={`error-retry retry-rotate fill-black`} classNamesForError={`svg-vertical-align fill-red`} />
                                                </button>
                                            </div>
                                        </div>

                                        {
                                            fetchedRemarks?.map((remark) =>
                                                <SingleRemarkFetch
                                                    t={t}
                                                    singleRemarkFetchData={remark}
                                                    state={this.state}

                                                    sendAwardOnRemark={this.sendAwardOnRemark}
                                                    openShareModal={this.openShareModal}
                                                    fetchedUpShotDetails={fetchedUpShotDetails}
                                                    addMeInUserAudience={this.addMeInUserAudience}
                                                    jumpToMainComment={this.jumpToMainComment}
                                                    scrollToRemarkMainCommentRef={this.scrollToRemarkMainCommentRef}
                                                    handleDoubleClick={this.handleDoubleClick}
                                                    openReportModal={this.openReportModal}

                                                    updateRemarkListAfterRemarkEdit={this.updateRemarkListAfterRemarkEdit}
                                                    removeRemarkFromRemarkListAfterDeletion={this.removeRemarkFromRemarkListAfterDeletion}
                                                />
                                            )
                                        }
                                        <LoadingIfElseErrorSvg isLoading={fetchLoading} errorString={fetchError} classNamesForLoading={`error-retry retry-rotate fill-white svg-align-center`} classNamesForError={`svg-vertical-align fill-red svg-align-center`} />
                                    </div>

                                    <InfoPopup
                                        steps={customSteps}
                                        browserStorageInfoValidationKey="uinfstat"
                                    />

                                    <Suspense fallback={null}>
                                        <LazyReportModal
                                            isReportModalOpen={this.state.isReportModalOpen}
                                            setReportModalOpen={() => this.setState({ isReportModalOpen: !this.state.isReportModalOpen })}
                                            reportInfo={this.state.reportInfo}
                                            // ! add contentType here
                                            reportTypeContent={this.state.reportTypeContent}
                                        />
                                    </Suspense>

                                    <ShareForPublicOnly
                                        isPubliShareModalOpen={this.state.isPubliShareModalOpen}

                                        openShareMainModal={() => this.setState({ isPubliShareModalOpen: true })}
                                        closeShareMainModal={() => this.setState({ isPubliShareModalOpen: false })}

                                        shareData={this.state.shareData}
                                        pageType={this.state.shareData === undefined ? "" : this.state.shareData.pageType}
                                    />
                                </div>
                            )}
                        </BanContext.Consumer>
                    )}
                </MessageContext.Consumer>
            </LayoutComponentForHelmet>
        )
    }
}


/*
# Free
Influencer
Reviewer Extraordinaire
Scene Stealer
GENRE_MASTER
MOVIE_MAVEN



# Premuim
REVIEWING_LEGEND
MOVIE_MAGIC_MAKER
NEXT_BIG_THING
DIRECTOR_DARLING
MOST_PROLIFIC_SCENE_CREATOR
INDEPENDENT_VOICE
INSIGHTFUL_OBSERVER
THOUGHT_LEADER
 */

export default withRouter(UpShotDetail);
