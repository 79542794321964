import React, { createContext, useContext, useEffect, useState } from "react";
import "./userAdminMessage.css";
import axios from "axios";
import textTrimmer from "../../../components/helpers/stringCutter";

const MessageContext = createContext();

export const UserAdminMessageProvider = ({ children }) => {
    const [isModMessageModalOpen, setModMessageOpen] = useState(false);
    const [contentId, setContentId] = useState(0);
    const [contentType, setContentType] = useState("");
    const [contentUserId, setContentUserId] = useState(0);
    const [userData, setUserData] = useState({
        messagesSentCount: 0,
        banHistoryCont: 0,

        userUsername: "",

        fetchedUserMessages: [],

        isDataLoading: false,
        fetchError: "",
    });

    const openModMessageModal = (id, type, userId) => {
        setModMessageOpen(!isModMessageModalOpen);
        setContentId(id);
        setContentType(type);
        setContentUserId(userId)
    }

    useEffect(() => {
        if (isModMessageModalOpen) {
            fetchUserData();
        }
    }, [isModMessageModalOpen])

    const fetchUserData = () => {
        setUserData({
            isDataLoading: true,
            fetchError: "",
        })
        axios.get(`/dashboard/admin/message/user/${contentUserId}/${sessionStorage.getItem("aamt")}/`).then((res) => {
            setUserData({
                isDataLoading: false,
                fetchError: "",

                userUsername: res.data.user_username,
                messagesSentCount: res.data.messages_sent_count,
                banHistoryCont: res.data.banned_times_count,
                fetchedUserMessages: res.data.messages,
            })
        }).catch(() => {
            setUserData({
                isDataLoading: false,
                fetchError: "Something went Wrong",
            })
        })
    }

    const closeModMessageModal = () => {
        setModMessageOpen(false);
        setContentId(0);
        setContentType("");
        setContentUserId(0);
    }

    const MessageModal = ({ children }) => {
        const [messageChoiceForAutomaticGeneration, setMessageChoiceForAutomaticGeneration] = useState("");
        const [messageType, setMessageType] = useState("W");
        const [messageDangerWeight, setMessageDangerWeight] = useState("G");
        const [reasonMessaging, setReasonMessaging] = useState("");
        const [action, setAction] = useState("H");

        const sendMessage = () => {
            const dataToPost = ({
                messaginForId: contentId,
                messaginForType: contentType,
                message_type: messageType,
                messageChoice: messageChoiceForAutomaticGeneration,
                message_danger_weight: messageDangerWeight,
                reason_of_messaging: reasonMessaging,
                action: action,
            });
            axios.post(`/dashboard/admin/message/user/${contentUserId}/${sessionStorage.getItem("aamt")}/`, dataToPost).then((res) => {
            }).catch(() => {
            })
        }

        return (
            isModMessageModalOpen && (
                <div className="moderation-message-modal">
                    <div className="moderation-message-modal-content">
                        <div className="message-modal-header">
                            Sending message to {userData.userUsername}
                            <span className="flt-rgt" onClick={closeModMessageModal}>
                                <svg className="svg-fill-white make-text-cursor svg-hover-red" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" /></svg>
                            </span>
                        </div>
                        <div className="moderation-message-modal-body">
                            <hr />
                            <span className="message-history">{userData.messagesSentCount} messages have been sent to user so far.</span>
                            <div className="moderation-message-table-container">
                                <div className="scrollable-wrapper custom-scroll">

                                    <table className="styled-table">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Sent at</th>
                                                <th>Sent by</th>
                                                <th>Sent for</th>
                                                <th>Message</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                userData.fetchedUserMessages?.map((message, index) =>
                                                    <>
                                                        <tr>
                                                            <td>{message.id}</td>
                                                            <td>{message.natural_time}</td>
                                                            <td>{message.sent_by_username}</td>
                                                            <td>{message.reason_of_messaging}</td>
                                                            <td>{textTrimmer(message.message, 10)}</td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="mod-message-input-container">

                            <label className="select" htmlFor="slct">
                                <select id="slct" required="required" onChange={(e) => setMessageChoiceForAutomaticGeneration(e.target.value)}>
                                    <option value="" disabled="disabled" selected="selected">Select option</option>
                                    <option value="PEOIC">Posting Explicit Or Inappropriate Content</option>
                                    <option value="DOSP">Doxing Or Sharing Personal Information</option>
                                    <option value="FOSA">Fraudulent Or Spam Activity</option>
                                    <option value="VOIP">Violation Of Intellectual Property</option>
                                    <option value="ISR">Impersonating Staff Representative</option>
                                    <option value="VTOS">Violation Of Terms Of Services</option>
                                    <option value="PHS">Persistent Harrassment Stalking</option>
                                </select>
                                <svg>
                                    <use xlinkHref="#select-arrow-down"></use>
                                </svg>
                            </label>
                            <svg className="sprites">
                                <symbol id="select-arrow-down" viewBox="0 0 10 6">
                                    <polyline points="1 1 5 5 9 1"></polyline>
                                </symbol>
                            </svg>

                            <label className="select" htmlFor="slct">
                                <select id="slct" required="required" onChange={(e) => setAction(e.target.value)}>
                                    <option value="" disabled="disabled" selected="selected">Action</option>
                                    <option value="N" selected={action === "N" && true}>Do Nothing</option>
                                    <option value="D" selected={action === "D" && true}>Delete</option>
                                    <option value="H" selected={action === "H" && true}>Hide</option>
                                </select>
                                <svg>
                                    <use xlinkHref="#select-arrow-down"></use>
                                </svg>
                            </label>
                            <svg className="sprites">
                                <symbol id="select-arrow-down" viewBox="0 0 10 6">
                                    <polyline points="1 1 5 5 9 1"></polyline>
                                </symbol>
                            </svg>


                            <div className="inline-block">
                                <label className="select" htmlFor="slct">
                                    <select id="slct" required="required" onChange={(e) => setMessageDangerWeight(e.target.value)}>
                                        <option value="" disabled="disabled" selected="selected">Message weight</option>
                                        <option value="G" selected={messageDangerWeight === "G" && true}>Green</option>
                                        <option value="O" selected={messageDangerWeight === "O" && true}>Orange</option>
                                        <option value="R" selected={messageDangerWeight === "R" && true}>Red</option>
                                    </select>
                                    <svg>
                                        <use xlinkHref="#select-arrow-down"></use>
                                    </svg>
                                </label>
                                <svg className="sprites">
                                    <symbol id="select-arrow-down" viewBox="0 0 10 6">
                                        <polyline points="1 1 5 5 9 1"></polyline>
                                    </symbol>
                                </svg>

                                <label className="select" htmlFor="slct">
                                    <select id="slct" required="required" onChange={(e) => setMessageType(e.target.value)}>
                                        <option value="" disabled="disabled" selected="selected">Message type</option>
                                        <option value="W" selected={messageType === "W" && true}>Warning</option>
                                        <option value="G" selected={messageType === "G" && true}>Greeting</option>
                                        <option value="I" selected={messageType === "I" && true}>Information</option>
                                        <option value="U" selected={messageType === "U" && true}>Update</option>
                                    </select>
                                    <svg>
                                        <use xlinkHref="#select-arrow-down"></use>
                                    </svg>
                                </label>
                                <svg className="sprites">
                                    <symbol id="select-arrow-down" viewBox="0 0 10 6">
                                        <polyline points="1 1 5 5 9 1"></polyline>
                                    </symbol>
                                </svg>
                            </div>

                            <label>Reason of Message</label>
                            <textarea
                                onChange={(e) => setReasonMessaging(e.target.value)}
                                value={reasonMessaging}
                                name="message-mod"
                                className="table-width-input"
                                placeholder="Enter your text here">
                            </textarea>

                            {/* <button type="button" className="send-message-btn" onClick={openBanModel}>Send <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" /></svg></button> */}
                            <button type="button" className="send-message-btn" onClick={sendMessage}>Send <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" /></svg></button>
                        </div>
                    </div>
                </div>
            )
        )
    }

    return (
        <MessageContext.Provider value={{ openModMessageModal, closeModMessageModal, MessageModal }}>
            {children}
            <MessageModal>{children}</MessageModal>
        </MessageContext.Provider>
    )
}


export const useModMessagePopup = () => {
    return useContext(MessageContext);
}

export default MessageContext;
