import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { createSearchParams } from 'react-router-dom';

import "./styles/trending.css";
import showToastError from '../helpers/showToastError';
import WatchLaterSelectionList from '../modals/WatchLaterSelectionList';
import SelectedContentDetail from './SelectedContentDetial';
import ShareForPublicOnly from '../Share/ShareOpen';
import textTrimmer from '../helpers/stringCutter';
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';
import { GlobalLoginRequiredModalContext } from "../../MainInterface/contexts/LoginRequired/LoginRequiredModal";
import { Button, Grid } from '@mantine/core';


function Trending({ currentUserUsername }) {
    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const { openModal } = useContext(GlobalLoginRequiredModalContext);
    const [shareData, setShareData] = useState({
        id: 0,
        additionalId: 0,
        mediaType: "movie",
        shareUrl: "",
        pageType: "",
        contentName: "",
    });

    const navigate = useNavigate();
    const [trendingRecords, setTrendingRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [maxPage, setMaxPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const [dBSavedWt, setDbSavedWt] = useState([])
    const [isClicked, setIsClicked] = useState({});

    const [showAll, setShowAll] = useState({});

    const [fetchErrorBox, setFetchErrorBox] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [contentDetailPopup, setContentDetailPopup] = useState(false);

    const [currentWTData, setCurrentWTData] = useState({});
    const [currentSelectedDetailData, setCurrentSelectedDetailData] = useState({});

    const toggleShowAll = (blogId) => {
        setShowAll(prevState => ({
            ...prevState,
            [blogId]: !prevState[blogId]
        }));
    };

    const jumpToPage = (whichPage) => {
        const jumpToPageOptions = {
            pathname: "/trending/",
            search: `?${createSearchParams({ page: whichPage })}`
        }
        setCurrentPage(whichPage);
        navigate(jumpToPageOptions, { replace: true });
        fetchTrendingRecords(whichPage);
    }

    const prevParams = {
        page: currentPage - 1
    }
    const prevOptions = {
        pathname: '/trending/',
        search: `?${createSearchParams(prevParams)}`
    }
    const goToPrevPage = () => {
        setCurrentPage(currentPage - 1);
        fetchTrendingRecords(currentPage - 1);

        navigate(prevOptions, { replace: true });
    }

    const params = {
        page: currentPage + 1,
    };
    const options = {
        pathname: '/trending/',
        search: `?${createSearchParams(params)}`
    }
    const goToNextPage = () => {
        setCurrentPage(currentPage + 1)
        fetchTrendingRecords(currentPage + 1)

        navigate(options, { replace: true });
    }

    const fetchTrendingRecords = (pageToSendAndGet) => {
        setLoading(true);
        axios.get(`/trending/`, { params: { page: pageToSendAndGet } }).then((res) => {
            setTrendingRecords(res?.data?.message?.results[0]?.results?.results);
            if (!maxPage) {
                setMaxPage(res?.data?.message?.results[0]?.results?.total_pages)
            }
            setFetchErrorBox("");
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            setLoading(false);
            if (currentUserUsername) {
                if (res?.status === 200) {
                    contentSavedFromDB();
                }
            }
        }).catch(() => {
            setLoading(false);
            setFetchErrorBox("Something went Wrong");
            showToastError("Something went wrong...Maybe because of network", "error")
        })
    }

    const contentSavedFromDB = () => {
        axios.get(`/api/content/main/combined/content/validator/`).then((res) => {
            setDbSavedWt(res.data.message.watch_later_data);
        }).catch(() => {
            showToastError("Unable to fetch", "error");
        })
    }

    useEffect(() => {
        const pageParamFromUrl = searchParams.get("page");

        if (!isNaN(pageParamFromUrl) && pageParamFromUrl !== undefined && pageParamFromUrl !== null && pageParamFromUrl !== "") {
            fetchTrendingRecords(pageParamFromUrl);
            setCurrentPage(parseInt(pageParamFromUrl));
        } else {
            fetchTrendingRecords(1);
        }
        // * Saved records to compare with real data for Watch Laters.
        // check_if_default_is_set();
    }, [])

    const runTheseOnClickToDelete = (res) => {
        deleteContentWatchLater(res);
        addOrRemove(res)
    }

    const deleteContentWatchLater = (contentId) => {
        axios.delete("/api/content/management/add_to_wt/", { data: { imdb_id_or_tmdb_id: contentId } }).then(() => {
        }).catch(() => {
        })
    }

    const addOrRemove = btnId => {
        setIsClicked(state => ({
            ...state, [btnId]: !state[btnId]
        }));
    };

    const runTheseOnClickToAdd = (res_imdb_id, res_name, full_data) => {
        if (!currentUserUsername) {
            openModal();
            return;
        }
        addCelebToWatchLater(res_name, res_imdb_id, full_data)
        setCurrentWTData(full_data);
        addOrRemove(res_imdb_id);
    }

    const addCelebToWatchLater = (s, f, full_data) => {
        const filteredContent = {
            poster_path: full_data.poster_path
        }
        axios.get("/api/content/management/check_if_any_of_list_is_default/").then((res) => {
            if (!res.data.is_default_status) {
                setModalShow(true);
            } else {
                var removeAnyComma = s.replace(/'/g, '');
                const dataToPost = ({
                    action: "WT_SELECTED",
                    defaultList: res.data.id !== null && res.data.id,
                    content_name: removeAnyComma,
                    imdb_id_or_tmdb_id: f, // TMDB ID or IMDB ID,
                    content_data: filteredContent,
                    media_type: full_data.media_type
                })
                axios.post("/api/content/management/add_to_wt/", dataToPost).then(() => {
                    addOrRemove(s);
                }).catch(() => {
                })
            }
        })
    }

    const openDetailPopupAndSetData = (s) => {
        console.log(s)
        setContentDetailPopup(true);
        setCurrentSelectedDetailData(s)
    }

    const openShareModal = (contentId, mediaType, contentName, pageType, additionalId) => {
        setShareModalOpen(true);
        setShareData(prevState => ({
            ...prevState,
            id: contentId,
            additionalId: additionalId,
            mediaType: mediaType,
            pageType: pageType,
            contentName: contentName,
        }))
    }

    return (
        <LayoutComponentForHelmet pageTitle={`Trending Page ${currentPage} - SpreeCine`}>
            <WatchLaterSelectionList
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={currentWTData}
                handleClick={addOrRemove}
            />
            <SelectedContentDetail
                currentUserUsername={currentUserUsername}
                openContentDetailPopup={contentDetailPopup}
                onHideContentDetailPopup={() => setContentDetailPopup(false)}
                data={currentSelectedDetailData}
            />

            {loading ? <>
                <div className="gridding body-space">
                    {
                        Array(5).fill().map(function (v, i) {
                            return (
                                <div className="loading-container" key={i}>
                                    <div className="content-data">
                                        <img className="skeleton" data-img="" />
                                    </div>
                                    <div data-body="">
                                        <div className="skeleton loading-text"></div>
                                        <br />
                                        <br />
                                        <div className="skeleton loading-text"></div>
                                        <div className="skeleton loading-text"></div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </> : null}

            {
                fetchErrorBox ?
                    <div className='error-box'>{fetchErrorBox}</div>
                    :
                    <>
                        <ul className={`cards`}>
                            {
                                trendingRecords?.map((record, index) =>
                                    <li className="cards__item" key={index}>
                                        <div className="card-detail">
                                            <div className="imgontop">
                                                <div className="actions">
                                                    <a>
                                                        <button type="button" className="btn btn-default delete-image-btn pull-right share-btn" onClick={() => openShareModal(record.id, record.media_type, record.name ? record.name : record.title, "T", 100)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15"><path d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z" /></svg>
                                                        </button>
                                                    </a>
                                                </div>
                                                <img onClick={() => toggleShowAll(record.id)} className={record.adult ? `blur-image` : ""} src={`https://image.tmdb.org/t/p/w400${record.poster_path}`} alt="Poster-Image" height="" />
                                            </div>

                                            <div className="card__content">
                                                <div className="card__title">
                                                    {record.name ? <>{record.name}</> : <>{record.title}</>}
                                                </div>
                                                <p className="card__text">
                                                    {showAll[record.id] ? record.overview : <>{textTrimmer(record.overview, 100)}</>}
                                                </p>
                                                <Grid>
                                                    <Grid.Col span={7}>
                                                        {
                                                            dBSavedWt.some(added_wt => parseInt(added_wt.imdb_id_or_tmdb_id) === record.id) ?
                                                                <>
                                                                    {
                                                                        isClicked[record.id] ?
                                                                            <Button color="#9F2B68" variant='outline' fullWidth onClick={() => runTheseOnClickToAdd(record.id, record.title ? record.title : record.name, record)}><small>Add to Watch Later</small></Button>
                                                                            :
                                                                            <Button color="#9F2B68" variant='outline' fullWidth onClick={() => runTheseOnClickToDelete(record.id)}><small>Remove from Watch Later</small></Button>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        !isClicked[record.id] ?
                                                                            <Button color="#9F2B68" variant='outline' fullWidth onClick={() => runTheseOnClickToAdd(record.id, record.title ? record.title : record.name, record)}><small>Add to Watch Later</small></Button>
                                                                            :
                                                                            <Button color="#9F2B68" variant='outline' fullWidth onClick={() => runTheseOnClickToDelete(record.id)}><small>Remove from Watch Later</small></Button>
                                                                    }
                                                                </>
                                                        }
                                                    </Grid.Col>
                                                    <Grid.Col span={5}>
                                                        <Button color="#9F2B68" fullWidth onClick={() => openDetailPopupAndSetData(record)}>More</Button>
                                                    </Grid.Col>
                                                </Grid>
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                        <TrendingPagination loading={loading} currentPage={currentPage} goToPrevPage={goToPrevPage} goToNextPage={goToNextPage} jumpToPage={jumpToPage} maxPage={maxPage} />
                    </>
            }

            <ShareForPublicOnly
                isPubliShareModalOpen={isShareModalOpen}

                openShareMainModal={() => setShareModalOpen(true)}
                closeShareMainModal={() => setShareModalOpen(false)}

                shareData={shareData}
                pageType={shareData === undefined ? "" : shareData.pageType}
                saveInBackend={false}
            />
        </LayoutComponentForHelmet>
    )
}

function TrendingPagination({ loading, currentPage, goToPrevPage, goToNextPage, jumpToPage, maxPage }) {

    return (
        <>
            {
                !loading ?
                    <div className="trending-pagination:container">
                        {
                            currentPage > 1 ?
                                <div className="trending-pagination:number arrow" onClick={goToPrevPage}>
                                    <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-240H80v-480h80v480Zm320 0L240-480l240-240 56 56-143 144h487v80H393l144 144-57 56Z" /></svg>
                                    <span className="arrow:text">Previous</span>
                                </div>
                                :
                                <div className="trending-pagination:number arrow">
                                    <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-240H80v-480h80v480Zm320 0L240-480l240-240 56 56-143 144h487v80H393l144 144-57 56Z" /></svg>
                                    <span className="arrow:text">Previous</span>
                                </div>
                        }
                        {
                            currentPage > 1 && currentPage - 2 != 0 &&
                            <div className="trending-pagination:number" onClick={() => jumpToPage(currentPage - 2)}>
                                <span>{currentPage - 2}</span>
                            </div>
                        }
                        {
                            currentPage > 1 &&
                            <div className="trending-pagination:number" onClick={() => jumpToPage(currentPage - 1)}>
                                <span>{currentPage - 1}</span>
                            </div>
                        }
                        <div className="trending-pagination:number trending-pagination:active">
                            {currentPage}
                        </div>
                        {
                            currentPage != maxPage &&
                            <>
                                {
                                    (currentPage + 1) != maxPage &&
                                    <div className="trending-pagination:number" onClick={() => jumpToPage(currentPage + 1)}>
                                        {
                                            currentPage < maxPage &&
                                            <span>{currentPage + 1}</span>
                                        }
                                    </div>
                                }
                            </>
                        }
                        {
                            currentPage != maxPage &&
                            <div className="pagination:number" onClick={() => jumpToPage(maxPage)}>
                                <span>{maxPage}</span>
                            </div>
                        }
                        {
                            currentPage < maxPage ?
                                <div className="pagination:number arrow" onClick={goToNextPage}>
                                    <span className="arrow:text">Next</span>
                                    <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M800-240v-480h80v480h-80Zm-320 0-57-56 144-144H80v-80h487L424-664l56-56 240 240-240 240Z" /></svg>
                                </div>
                                :
                                <div className="pagination:number arrow">
                                    <span className="arrow:text">Next</span>
                                    <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M800-240v-480h80v480h-80Zm-320 0-57-56 144-144H80v-80h487L424-664l56-56 240 240-240 240Z" /></svg>
                                </div>
                        }
                    </div>
                    : null
            }
        </>
    )
}

export default Trending;