import React from 'react';

import "./privacyPolicy.css";
import UserLegalReadables from '../UserLegalReadablesActions';
import LegalPolicyHeader from "../LegalPolicyHeader";
import { Link } from 'react-router-dom';
import LayoutComponentForHelmet from '../../../../MainInterface/SEO/LayoutComponentForHelmet';

function PrivacyPolicy() {
    return (
        <LayoutComponentForHelmet pageTitle={"Privacy Policy - SpreeCine"}>
            <div className='privacy-policy'>

                <LegalPolicyHeader includeAppName header="Privacy Policy" />
                <UserLegalReadables />

                Welcome to our platform! Your privacy and data security are of utmost importance to us.
                This privacy policy outlines how we collect, use, disclose, and protect your personal information when you use our services.

                WE DO NOT AND WILL NOT SELL YOUR PERSONAL INFORMATION. Your data is safe with us, and we take every measure to protect it.

                By using our platform, you agree to the terms and practices described in this policy.
                Please take the time to read and understand this document to ensure that you are comfortable with how we handle your data.

                <ul className='privacy-policy-jump-to-links'>
                    <li><a href='#what-information-do-we-collect'>WHAT INFORMATION DO WE COLLECT?</a></li>
                    <li><a href='#how-we-use-your-personal-data'>HOW WE USE YOUR PERSONAL DATA?</a></li>
                    <li><a href='#how-do-we-share-your-data'>WHO DO WE SHARE YOUR PERSONAL DATA WITH?</a></li>
                    <li><a href='#how-long-do-we-retain-data'>HOW LONG DO WE RETAIN YOUR PERSONAL DATA?</a></li>
                    <li><a href='#your-rights'>YOUR RIGHTS</a></li>
                    <li><a href='#contact-us'>CONTACT US</a></li>
                </ul>

                We collect and process various types of personal information to provide you with our services effectively.
                This may include your name, email address, contact details, device information, and usage data.
                We use this information to enhance your experience, personalize our services, communicate with you, and ensure the security of our platform.
                Rest assured, we do not share your personal information with third parties for their marketing or advertising purposes without your explicit consent.

                <table id='what-information-do-we-collect'>
                    <thead>
                        <tr>
                            <th colspan="2">WHAT INFORMATION DO WE COLLECT?</th>
                        </tr>
                    </thead>
                    <tbody className='privacy-policy-table-tbody'>
                        <tr>
                            <td className='l-s'>Account registration information/Account Information</td>
                            <td>
                                When you create an account on our platform, we collect essential account information to facilitate your access and interaction with our services.
                                This includes details such as your username, email address, password, and any optional profile information you choose to provide.
                                We use this account information to personalize your experience, authenticate your identity, communicate with you, and ensure the security and integrity of your account. Rest assured, we prioritize the confidentiality and protection of your account information, adhering to stringent security measures to prevent unauthorized access or disclosure.
                                We do not share your account details with third parties without your consent, except as necessary for the provision of our services or as required by applicable laws and regulations.
                                <br />
                                <br />

                                Additionally, we may request information about the genres you enjoy to tailor your home feed and present content that aligns with your preferences.
                                This user-provided data, concerning your liked genres, helps us curate a personalized home feed that enhances your platform experience.
                                <br />
                                <br />

                                It allows us to recommend relevant content, services, or products that suit your interests.
                                We respect your choices and utilize this data exclusively to create a more engaging and customized user interface, ensuring that your interactions with our platform are both enjoyable and valuable.
                                Your privacy and data protection are fundamental, and we do not share this information with third parties without your explicit consent.
                            </td>
                        </tr>
                        <tr>
                            <td>Content You Submit</td>
                            <td>
                                We actively accumulate the content you contribute to our platform, encompassing a spectrum of forms including text, images, and various media types.
                                This entails your posts and comments, encompassing saved drafts.
                                The breadth of your content ranges from textual expressions, hyperlinks, images, gifs.
                            </td>
                        </tr>
                        <tr>
                            <td>Transactional information</td>
                            <td>
                                When your purchase SpreeCine premium, We collect transactional data, subscription details, to provide and manage your premium membership.
                                For secure payment processing, we utilize lemon squeezy, a trusted third-party payment service.
                                We do not store sensitive payment information, such as credit card numbers, on our servers.
                                Your data is stored securely and used solely for subscription-related purposes.
                            </td>
                        </tr>
                        <tr>
                            <td>Actions you take</td>
                            <td>
                                We collect information about the actions you take when using the Services. This includes your interactions with content, like voting, saving, hiding, and reporting. It also includes your interactions with other users, such as following. We collect your interactions with communities, like your subscriptions or moderator status.
                            </td>
                        </tr>
                        <tr>
                            <td>Other information</td>
                            <td>
                                You may choose to provide other information directly to us. For example, we may collect information when you fill out a form, register for a product, newsletter or service, participate in surveys or Stack-sponsored activities or promotions, apply for a job, request customer support, or otherwise communicate with us.
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th colspan="2">Information we generate or collect automatically through your use of our services</th>
                        </tr>
                    </thead>
                    <tbody className='privacy-policy-table-tbody'>
                        <tr>
                            <td className='l-s'>Log and usage data</td>
                            <td>
                                Refers to the automatically collected information when you access or interact with our services. This includes details about your device, such as its type, operating system, browser type, IP address, and unique device identifiers. Additionally, we gather data about your interactions with our platform, such as the pages you visit, the features you use, the actions you take, and the duration and frequency of your usage. These logs help us understand how you and others use our services, allowing us to improve and optimize performance, enhance security, and provide a better user experience. We may also analyze aggregated and anonymized usage patterns to identify trends and preferences, aiding in future service enhancements and updates. Rest assured, we handle this data responsibly and in accordance with our privacy policy.
                            </td>
                        </tr>
                        <tr>
                            <td className='l-s'>Information collected from cookies and similar technologies	</td>
                            <td>
                                Refers to the automatically collected information when you access or interact with our services. This includes details about your device, such as its type, operating system, browser type, IP address, and unique device identifiers. Additionally, we gather data about your interactions with our platform, such as the pages you visit, the features you use, the actions you take, and the duration and frequency of your usage. These logs help us understand how you and others use our services, allowing us to improve and optimize performance, enhance security, and provide a better user experience. We may also analyze aggregated and anonymized usage patterns to identify trends and preferences, aiding in future service enhancements and updates. Rest assured, we handle this data responsibly and in accordance with our privacy policy.
                            </td>
                        </tr>
                        <tr>
                            <td className='l-s'>Location information</td>
                            <td>
                                We may receive and process information about your location.
                                we may derive an approximate location based on your IP address.
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th colspan="2">Information we collect about you from third parties</th>
                        </tr>
                    </thead>
                    <tbody className='privacy-policy-table-tbody'>
                        <tr>
                            <td className='l-s'>Information we collect from other sources</td>
                            <td>
                                Information from third parties to help us to combat fraud, provide Services or that relates to your interactions, including your communications between individuals, organizations, prospects and other stakeholders acquired from companies that collect combined information and share it with us.
                            </td>
                        </tr>
                    </tbody>
                </table>


                During the sign-up process, we may collect general location information, such as the region or country where you are located.
                This information is used solely to associate users with their respective countries and for aiding us in measuring and understanding the global distribution of our user base. However, it's important to note that we do not utilize this location data for specific geographical targeting or to tailor services based on location. Your privacy is of utmost importance to us.
                <br />
                <br />
                <p className='font-size-14px clr-white'>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>


                <h1 id='how-we-use-your-personal-data'>HOW WE USE YOUR PERSONAL DATA?</h1>

                Below sections explains how we use your information and what we need it for.

                We use information about you to:

                <ul className='usage-infomation'>
                    <li>Maintain and enhance Services.</li>
                    <li>Personalize content to match your preferences.</li>
                    <li>Safeguard SpreeCine and users by blocking spammers, addressing abuse, and enforcing policies.</li>
                    <li>Send you technical notices, updates, security alerts, invoices, and other support and administrative messages.</li>
                    <li>Provide customer service.</li>
                    <li>Share news, offers, and information of interest.</li>
                    <li>Analyze usage trends and activities on our Services.</li>
                </ul>

                <h1 id='how-do-we-share-your-data'>WHO DO WE SHARE YOUR PERSONAL DATA WITH?</h1>

                We DO NOT share any of your data with third party services.
                However We offer social sharing features that let you share content present on the Platform.
                When you use social sharing features, some of your information may be shared with your friends or the public. The extent of this sharing depends on the settings you choose with the company that provides the social sharing feature, like Facebook, or Twitter. To understand more about why and how data is collected and used through these features, check the privacy policies of these third-party companies.

                <h2>How your data is Protected?</h2>
                We safeguard your information from loss, theft, and misuse. This includes using secure HTTPS for data transmission and controlling access to personal data within our organization.

                <h1 id='how-long-do-we-retain-data'>HOW LONG DO WE RETAIN YOUR PERSONAL DATA?</h1>
                We retain collected data only as long as needed for its original purpose. Some information may be kept for business reasons or due to legal requirements.
                We are also required to keep some basic information about our customers for a longer period in accordance with applicable tax and other laws. In some circumstances, you can ask us to delete your data.

                <h1 id='your-rights'>YOUR RIGHTS</h1>
                You can control how your information is collected and shared when using our Services. Depending on your location, you might have the right to access, delete, or correct your personal data. You can also opt out of specific advertising practices and withdraw consent you previously provided. SpreeCine treats all users equally, regardless of whether they exercise these data protection rights.

                You have a number of rights in relation to your personal data, and you can contact us if you want to exercise any of these.

                <table>
                    {/* <thead>
                        <tr>
                            <th colspan="2">Information we collect about you from third parties</th>
                        </tr>
                    </thead> */}
                    <tbody className='privacy-policy-table-tbody'>
                        <tr>
                            <td className='l-s'>Access</td>
                            <td>
                                You have the ability to access and edit your information through our Services. If you want a copy of your personal data held by SpreeCine, you can request it in your User Settings Section.
                            </td>
                        </tr>
                        <tr>
                            <td className='l-s'>Rectification</td>
                            <td>
                                The right to require us to correct any mistakes in your personal data.
                            </td>
                        </tr>
                        <tr>
                            <td className='l-s'>Erasure</td>
                            <td>
                                You can delete your account information anytime by going to the user preferences page. To request the deletion of the personal information SpreeCine holds about you.
                                <br /><br />
                                When you delete your account, your profile becomes invisible to others, and it gets unlinked from the content you've posted. However, please note that posts, comments, and messages you submitted before deleting your account will still be visible to others unless you delete them individually. After requesting account deletion, it might take up to 90 days for the process to finish. We may also retain some information as required by law or for legitimate business purposes.
                            </td>
                        </tr>
                        <tr>
                            <td className='l-s'>Controlling the Use of Cookies</td>
                            <td>
                                Web browsers typically accept cookies as the default setting. However, you can usually configure your browser to delete or decline both first-party and third-party cookies. Keep in mind that if you decide to remove or reject cookies, it may impact the availability and functionality of our Services. For detailed guidance on managing cookies and similar technologies on SpreeCine, refer to our Cookie Notice.
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br /><br />
                <h2>Additional Information for EEA Users</h2>
                In accordance with the rights granted to users within the European Economic Area (EEA), individuals possess the entitlement to request access, rectification, or erasure of their personal data. They may also exercise their right to data portability in specific circumstances, request the restriction of data processing, object to processing, and revoke their consent for data processing, where consent was previously provided. The process for exercising these rights is elucidated in the "Your Rights and Choices" section above. Furthermore, EEA users retain the prerogative to file complaints with their local supervisory authority.
                <br /><br />
                In strict adherence to pertinent legislation, our collection and processing of information pertaining to individuals within the EEA are predicated solely on a legitimate legal foundation. The specific legal bases are contingent upon the nature of the Services being utilized and the manner in which they are used. Our information processing operations are anchored in the following legal frameworks:

                <ul className='usage-infomation'>
                    <li>Consent, as explicitly granted by you for a specified purpose.</li>
                    <li>Necessity for processing information to deliver the Services, encompassing their operation, provision of customer support, implementation of personalized features, and safeguarding the safety and security of the Services.</li>
                    <li>Satisfaction of a legitimate interest, provided that such interest does not contravene your data protection concerns. This may include the prevention of fraud, assurance of network and information security, enforcement of our rules and policies, protection of our legal rights and interests, research and development activities, personalization of the Services, and the marketing and promotion of the Services.</li>
                    <li>Obligation to process information to comply with our legal duties.</li>
                </ul>

                If you would like to exercise any of these rights, please submit a data request.

                We may need to request specific information from you to help us confirm your identity before we can deal with your request. We will respond to your request within one month. If your request is complicated, it may take us longer. We will let you know if we need longer than one month to respond.

                <br /><br />
                <h2>Children</h2>
                SpreeCine is not intended for children. We do not knowingly offer this or any other Product or Service to anyone under the age of 13.

                <hr />

                <br />
                <h2>Changes to This Policy</h2>
                We may change or update this policy from time to time and will notify you of any material changes to this policy.
                Previous versions of this privacy policy are available upon request.

                <br /><br />
                <h2 id='contact-us'>Contact Us</h2>
                Should you have any additional inquiries or require further information regarding this Privacy Policy, please do not hesitate to <Link to={"/contact"}><b className='clr-blue'>reach out to us</b></Link>.
            </div>
        </LayoutComponentForHelmet>
    )
}

export default PrivacyPolicy;