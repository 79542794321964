import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EncryptStorage } from 'encrypt-storage';

import SuccessNotification from '../../svgComponents/successNotification';

function showToastError(messageToShow, typeOfError) {
    const makeTextLower = typeOfError ? typeOfError.toLowerCase() : "error";

    const encryptStorage = new EncryptStorage('encryptedThemeIdentifier', {
        storageType: "localStorage"
    });

    if (makeTextLower === "error") {
        toast.error(messageToShow, {
            transition: Slide,
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: encryptStorage.getItem("theme") || "dark",
        });
    } else if (makeTextLower === "success") {
        toast.success(messageToShow, {
            transition: Slide,
            icon: <SuccessNotification />,
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: encryptStorage.getItem("theme") || "dark",
        });
    } else if (makeTextLower === "info") {
        toast.info(messageToShow, {
            transition: Slide,
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: encryptStorage.getItem("theme") || "dark",
        });
    } else if (makeTextLower === "warning") {
        toast.warning(messageToShow, {
            transition: Slide,
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: encryptStorage.getItem("theme") || "dark",
        });
    } else {
        toast.error(messageToShow, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: encryptStorage.getItem("theme") || "dark",
        });
    }
}

export default showToastError;
