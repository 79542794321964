import React, { Suspense, lazy, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { t } from "i18next";
import Identicon from 'react-identicons';


import './styles/profile.css';
// import CheersGlass from '../../svgComponents/CheersGlass';
// import CheersGlassOneSide from '../../svgComponents/CheersGlassOneSide';

import ReportModal from '../Report/Report';
import ShareModel from '../Share/Share';
import CannotViewUserBlockedYou from '../Preventation/BlockedUser.js'
import showToastError from '../helpers/showToastError';
import MaintenanceMode from '../Preventation/Maintenance/maintenance';
import ProfileMoreActionDropdown from './ProfileDropdownActions';
import ShareForPublicOnly from '../Share/ShareOpen';
import extractAppropriateIconFromKeyWord from '../helpers/extractAppropriateIconFromKeyWord';
import UpShotListDetailContainerBox from "../upshot/UpShotList/UpShotListDetailContainerBox";
import BannedPage from '../Preventation/BannedPage';
import UserOnPremiumBadgeIcon from './icons/UserOnPremiumBadgeIcon';
import RemarkContainer from "../upshot/RemarkContainer";
import LoadingIfElseErrorSvg from "../animations/LoadingIfElseErrorSvg.js";
import PageNotFound from '../Preventation/PageNotFound/PageNotFound.js';
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet.js';
import AudienceCountSVGIcon from './icons/ProfileAudienceCount.js';
import UpShotRemarkIcon from '../upshot/icons/svgComponents/UpShotRemarkIconV2.js';
import UpShotProfileCount from './icons/UpShotProfileCountIcon.js';
import RemarkCommentListSingle from '../upshot/Comments/CommentListSingle.js';
import { SegmentedControl } from '@mantine/core';


const LazySettingsComponent = lazy(() => import("../../components/settingsv2/SettingsMainModal"))


const TruncatedParagraph = ({ text, maxLength }) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const handleToggle = () => {
        setIsTruncated(!isTruncated);
    };

    const truncatedText = isTruncated ? text.slice(0, maxLength) : text;

    return (
        <>
            <p>{truncatedText}</p>
            {text.length > maxLength && (
                <button onClick={handleToggle}>
                    {isTruncated ? 'More...' : 'Less'}
                </button>
            )}
        </>
    );
};

class UserContentProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchedContentUpShot: [],
            fetchedContentRemark: [],
            fetchedContentComment: [],

            remarkInUpShot: [],
            typeOfContentToFetch: "upshots",
            sortContentBy: "top",


            fetchLoading: false,
            fetchError: "",

            hasMore: true,
            offset: 0,
            limit: 7,
            height: window.innerHeight,
        }
        window.onscroll = (e) => {
            const {
                loadUserContentProfile,
                state: { fetchError, fetchLoading, hasMore, typeOfContentToFetch, sortContentBy }
            } = this;
            if (fetchError || fetchLoading || !hasMore) return;
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) {
                loadUserContentProfile(typeOfContentToFetch, sortContentBy)
            }
        }
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        const { typeOfContentToFetch, sortContentBy, fetchLoading } = this.state;

        // * Calculating the distance from the bottom
        const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
        const distanceFromBottom = scrollableHeight - window.scrollY;
        const threshold = 700; // * Adjust this value based on desired distance in pixels. It was 300 but it was not very much space from bottom so changed to 700.

        if (distanceFromBottom <= threshold && !fetchLoading) {
            if (this.state.hasMore) {
                this.loadUserContentProfile(typeOfContentToFetch, sortContentBy);
            }
        }
    }

    updateSortSlider = (typeOfContentToFetch, sortContentBy) => {
        console.log(typeOfContentToFetch)
        this.setState(prevState => ({
            ...prevState,
            typeOfContentToFetch: typeOfContentToFetch,
            sortContentBy: sortContentBy,
            offset: 0,
            limit: 7,
        }))
        this.loadUserContentProfile(typeOfContentToFetch, sortContentBy)
    }

    loadUserContentProfile = (typeOfContentToFetch, sortContentBy) => {
        this.setState({ fetchLoading: true }, () => {
            const { offset, limit } = this.state;
            axios.get(`/api/users/primary/user/profile/content/${this.props.username}/?content_type=${typeOfContentToFetch}&filter_by=${sortContentBy}&limit=${limit}&offset=${offset}`).then((res) => {
                const newContent = res?.data?.message;
                const hasMore = res.data.has_more;

                if (typeOfContentToFetch === "upshots") {
                    this.setState(prevState => {
                        const uniqueFetchedContent = [
                            ...(prevState?.fetchedContentUpShot || []),
                            ...newContent.filter(newBlog =>
                                !(prevState?.fetchedContentUpShot || []).some(oldBlog => oldBlog.id === newBlog.id)
                                // !prevState.fetchedContentUpShot?.some(oldBlog => oldBlog.id === newBlog.id)
                            )
                        ];
                        return {
                            fetchLoading: false,
                            hasMore: hasMore,
                            fetchedContentUpShot: uniqueFetchedContent,
                            offset: offset + limit
                        };
                    });
                } else if (typeOfContentToFetch === "remarks") {
                    this.setState(prevState => {
                        const uniqueFetchedContent = [
                            ...(prevState?.fetchedContentRemark || []),
                            ...newContent.filter(newBlog =>
                                !(prevState?.fetchedContentRemark || []).some(oldBlog => oldBlog.id === newBlog.id)
                                // !prevState.fetchedContentRemark?.some(oldBlog => oldBlog.id === newBlog.id)
                            )
                        ];
                        return {
                            fetchLoading: false,
                            hasMore: hasMore,
                            fetchedContentRemark: uniqueFetchedContent,
                            offset: offset + limit
                        };
                    });
                } else if (typeOfContentToFetch === "comments") {
                    this.setState(prevState => {
                        const uniqueFetchedContent = [
                            ...(prevState?.fetchedContentComment || []),
                            ...newContent.filter(newBlog =>
                                !(prevState?.fetchedContentComment || []).some(oldBlog => oldBlog.id === newBlog.id)
                                // !prevState.fetchedContentComment?.some(oldBlog => oldBlog.id === newBlog.id)
                            )
                        ];
                        return {
                            fetchLoading: false,
                            hasMore: hasMore,
                            fetchedContentComment: uniqueFetchedContent,
                            offset: offset + limit
                        };
                    });
                }
            }).catch(() => {
            })
        })
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        this.loadUserContentProfile("upshots", "top")
    }

    render() {
        const contentSortingOptions = ['UpShots', 'Remarks', 'Comments'].map(option => t(option));
        const contentFilterOptions = ['Top', 'Newest'].map(option => t(option));
        const { typeOfContentToFetch, sortContentBy, isFetchingLoading, fetchError, fetchedContentComment, fetchedContentRemark, fetchedContentUpShot, remarkInUpShot } = this.state;
        return (
            <>
                <div className="">
                    <SegmentedControl fullWidth radius="md" data={contentSortingOptions} onChange={(e) => this.updateSortSlider(e.toLowerCase(), sortContentBy)} />
                    <br />
                    <div className="filter-option">
                        <SegmentedControl fullWidth radius="md" data={contentFilterOptions} onChange={(e) => this.updateSortSlider(typeOfContentToFetch, e.toLowerCase())} />
                    </div>
                </div>
                {
                    typeOfContentToFetch === "upshots"
                        ?
                        <>
                            {<LoadingIfElseErrorSvg isLoading={isFetchingLoading} errorString={fetchError} loadingLogoBigOrSmall={"big"} retry={this.loadUserContentProfile} />}
                            {
                                fetchedContentUpShot?.map((item, index) =>
                                    <div className='upshots-list-v2' key={item.id}>
                                        <UpShotListDetailContainerBox
                                            res={item}
                                            index={index}
                                            remarkInUpShot={remarkInUpShot}
                                            currentUserProfileImage={this.props.userProfileMainImage}
                                        />
                                    </div>
                                )
                            }
                        </>
                        :
                        null
                }
                {
                    typeOfContentToFetch === "remarks"
                    &&
                    <>
                        {<LoadingIfElseErrorSvg isLoading={isFetchingLoading} errorString={fetchError} loadingLogoBigOrSmall={"big"} retry={this.loadUserContentProfile} />}
                        {
                            fetchedContentRemark?.map((item) =>
                                <div className="remark-list" key={item.id}>
                                    <RemarkContainer remark={item} userProfileImage={this.props.userProfileMainImage} />
                                </div>
                            )
                        }
                    </>
                }
                {
                    typeOfContentToFetch === "comments"
                    &&
                    <>
                        {<LoadingIfElseErrorSvg isLoading={isFetchingLoading} errorString={fetchError} loadingLogoBigOrSmall={"big"} retry={this.loadUserContentProfile} />}
                        <ul className="remark-comments-container">
                            {
                                fetchedContentComment?.map((item) =>
                                    <RemarkCommentListSingle
                                        id={item.id}
                                        item={item}
                                        userProfileImage={this.props?.userProfileMainImage}
                                    />
                                )
                            }
                        </ul>
                    </>
                }
            </>
        )
    }
}

function UserProfile({ currentUserUsername }) {
    const { t } = useTranslation();
    const { username } = useParams();
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [maintenanceIsOn, setMaintenanceIsOn] = useState(false);
    const [userNotActive, setUserNotActive] = useState(false);
    const [userAddedInAudience, setUserAddedInAudience] = useState(false);
    const [audienceRequestPending, setAudienceRequestPending] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const [isReportModalOpen, setReportModalOpen] = useState(false);
    const [reportInfo, setReportInfo] = useState(0);

    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const [isPubliShareModalOpen, setPublicShareModalOpen] = useState(false);

    // ? If receiver blocked request.user then hide the profile and show message.
    const [isRequestUserBlocked, setRequestUserBlocked] = useState(false);
    // ? This is just for Boolean, as if user want to know the status of Blocking receiver.
    const [isReceiverBlocked, setReceiverBlocked] = useState(false);

    const [isAdditionalInfoModalOpen, setAdditionalInfoModalOpen] = useState(false);
    const [showAdditionalData, setShowAdditionalData] = useState(false);
    const [authenticatedUsername, setAuthenticatedUsername] = useState("");

    const [userProfileData, setUserProfileData] = useState({
        userId: 0,
        userUsername: "",
        userEmail: "",
        userFullName: "",
        userAbout: "",
        userProfileBgImage: "",
        userProfileMainImage: "",

        birthdayVisibility: "",
        favouriteVisibility: "",
        whoCanJoinMyAudience: "",
        profileVisibility: "",

        userBirthday: "",
        userSelectedAchievement: "",
        remarkCount: 0,
        upShotCount: 0,
        audienceCount: 0,
        watchingCount: 0,
        showPremiumBadgeOnPremium: false,

        isCurrentUserProfileOwner: false,

        isBanned: false,
        bannedTill: "",

        genresLiked: [],

        currentUser: 0,
        currentUserUsername: "",
    });

    const [profileFetchState, setProfileFetchState] = useState({
        isProfileDataLoading: false,
        fetchError: null,

        pageNotFoundErrorType: null,
        pageNotFoundErrorMessage: "",
    })
    const [shareData, setShareData] = useState({});
    const [userStreakData, setUserStreakData] = useState({
        fetchedStreakData: [],
        streakMessage: "",

        isStreakFetchLoading: false,
        fetchError: "",
    });
    const [userAwardsData, setUserAwardsData] = useState({
        areAwardsLoading: false,
        awardsFetchError: "",
        fetchedAwardsData: [],
    })
    const [userContentDataState, setUserContentDataState] = useState({
        fetchedContentUpShot: [],
        fetchedContentRemark: [],
        fetchedContentComment: [],
        isFetchingLoading: false,
        fetchError: "",

        remarkInUpShot: [],
        typeOfContentToFetch: "upshots",
        sortContentBy: "top",
    })

    useEffect(() => {
        if (currentUserUsername) {
            setIsUserAuthenticated(true);
        } else {
            setIsUserAuthenticated(false);
        }
        renderPage();
    }, [username])

    const renderPage = () => {
        setProfileFetchState({
            isProfileDataLoading: true,
            fetchError: null,

            pageNotFoundErrorType: null,
            pageNotFoundErrorMessage: "",
        })
        axios.get(`/api/users/primary/user-profile/${username}/`).then(res => {
            setProfileFetchState({
                isProfileDataLoading: false,
                fetchError: null,

                pageNotFoundErrorType: null,
                pageNotFoundErrorMessage: "",
            })
            setUserAddedInAudience(res.data.if_user_in_audience);

            setRequestUserBlocked(res.data.is_user_blocked_by_receiver);
            setReceiverBlocked(res.data.is_receiver_blocked_by_user);

            if (res.data.who_can_be_in_my_audience === "AR") {
                setAudienceRequestPending(res.data.is_request_sent);
            }

            setUserProfileData({
                userId: res.data.id,
                currentUser: res.data.current_user,
                currentUserUsername: res.data.current_user_username,
                userUsername: res.data.user,
                userEmail: res.data.email,
                userFullName: res.data.full_name,
                userAbout: res.data.about,
                userProfileBgImage: res.data.bg_profile_image,
                userProfileMainImage: res.data.profile_image,
                profileVisibility: res.data.profile_visibility,

                birthdayVisibility: res.data.dob_visibility,
                favouriteVisibility: res.data.favourite_visibility,
                watchLaterVisibility: res.data.watch_later_visibility,
                whoCanJoinMyAudience: res.data.who_can_be_in_my_audience,

                userBirthday: res.data.date_of_birth,
                userSelectedAchievement: "",
                remarkCount: res.data.remark_count,
                upShotCount: res.data.upshot_count,
                audienceCount: res.data.audience_count,
                watchingCount: res.data.watching_count,
                isBanned: res.data.is_banned,
                bannedTill: res.data.banned_till,
                isCurrentUserProfileOwner: res.data.is_current_user_owner_of_profile,
                showPremiumBadgeOnPremium: res.data.show_premium_badge_on_profile,
            });
        })
            .catch((err) => {
                setProfileFetchState({
                    isProfileDataLoading: false,
                    fetchError: "Something went Wrong",

                    pageNotFoundErrorType: "Something went Wrong",
                    pageNotFoundErrorMessage: "Something went Wrong",
                })
                if (err?.response?.data?.message === "USER_NOT_ACTIVE") {
                    setUserNotActive(true);
                } else {
                    setUserNotActive(false);
                }
            })
    }

    const openReportModal = () => {
        setReportModalOpen(true);
        setReportInfo(userProfileData.userId);
    };

    const addMeInUserAudience = () => {
        const data = ({
            r: userProfileData.userUsername,
        })
        axios.post(`/api/audience/audience-request/`, data).then(() => {
            if (userProfileData.whoCanJoinMyAudience === "AR") {
                showToastError(`A request has been sent to ${userProfileData.userUsername}`, "success")
            } else if (userProfileData.whoCanJoinMyAudience === "A") {
                setUserAddedInAudience(!userAddedInAudience);
                showToastError(`You are successfully added in ${userProfileData.userUsername} Audience`, "success")
            } else {
                showToastError(`Nobody can join ${userProfileData.userUsername} Audience`, "success")
            }
        }).catch(() => {
            showToastError("Something went wrong! Try again later", "error")
        })
    }

    const blockUser = (username) => {
        axios.put(`/api/users/primary/blocked-usersv2/${username}/`).then(() => {
        }).catch(() => {
            showToastError("Something went wrong! Try again later", "error");
        })
    }

    const openAdditionalInfoModal = () => {
        setAdditionalInfoModalOpen(!isAdditionalInfoModalOpen);
        if (!isAdditionalInfoModalOpen) {
            fetchStreakData();
        }
    }

    const fetchStreakData = () => {
        setUserAwardsData({
            areAwardsLoading: true,
            awardsFetchError: "",
        })
        // setUserStreakData({
        //     isStreakFetchLoading: true,
        //     fetchError: "",
        // })
        axios.get(`/api/users/primary/user/streak/${username}`).then((res) => {
            // setUserStreakData({
            //     fetchedStreakData: res.data.response !== null ? res.data.response.message[0] : [],
            //     streakMessage: res.data.mes ? res.data.mes : "",

            //     isStreakFetchLoading: false,
            //     fetchError: "",
            // });
            setUserAwardsData({
                fetchedAwardsData: res.data.awards,
                areAwardsLoading: false,
                awardsFetchError: "",
            })
        }).catch(() => {
            setUserAwardsData({
                areAwardsLoading: false,
                awardsFetchError: "Something went Wrong!",
            })
            // setUserStreakData({
            //     isStreakFetchLoading: false,
            //     fetchError: "Something went Wrong!",
            // })
        })
    }

    const openUserSettings = () => {
        setIsSettingsOpen(true);
    }

    if (profileFetchState.isProfileDataLoading) {
        return <LoadingIfElseErrorSvg isLoading={profileFetchState.isProfileDataLoading} errorString={profileFetchState.fetchError} loadingLogoBigOrSmall={"big"} retry={renderPage} />
    }

    if (maintenanceIsOn) {
        return <MaintenanceMode />;
    }

    if (userNotActive) {
        return <PageNotFound />
    }

    if (isRequestUserBlocked) {
        return <CannotViewUserBlockedYou blockedByUsername={userProfileData.userUsername} />
    }

    if (userProfileData.isBanned) {
        if (userProfileData.isCurrentUserProfileOwner) {
            return <BannedPage whoIsAccessing={"current"} page={"profile"} banReliefTill={userProfileData.bannedTill} />
        } else {
            return <BannedPage whoIsAccessing={"other"} page={"profile"} />
        }
    }

    return (
        <LayoutComponentForHelmet pageTitle={`User ${userProfileData.userFullName ? userProfileData.userFullName : "Profile"} - SpreeCine`}>
            <div className="profile-info-main-container">
                <div className='user-profile-content'>
                    <UserContentProfile username={username} />
                </div>
                <div className="profile-container">
                    <div className="profile-container-sidebar position-relative">
                        {
                            isAdditionalInfoModalOpen ?
                                <>
                                    {
                                        userAwardsData?.areAwardsLoading ?
                                            <LoadingIfElseErrorSvg isLoading={userAwardsData?.areAwardsLoading} fetchError={userAwardsData?.awardsFetchError} loadingLogoBigOrSmall={"big"} />
                                            :
                                            <>
                                                {
                                                    userAwardsData?.awardsFetchError ?
                                                        <>{userAwardsData?.awardsFetchError}</>
                                                        :
                                                        <>
                                                            {
                                                                userAwardsData?.fetchedAwardsData?.length <= 0 ?
                                                                    <div className='zero-content-container'>
                                                                        <p className='zero-content'>
                                                                            {
                                                                                userProfileData.isCurrentUserProfileOwner ?
                                                                                    <>
                                                                                        {t("You haven't earned any awards yet.")}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {t(`${userProfileData.userUsername} haven't earned any awards yet.`)}
                                                                                    </>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    <div className="p-awd-container">
                                                                        {
                                                                            userAwardsData?.fetchedAwardsData?.map((award) =>
                                                                                <div className="awd">
                                                                                    {extractAppropriateIconFromKeyWord(award.award_type_short)}
                                                                                    <div className="awd-details">
                                                                                        <h2>{award.award_type}</h2>
                                                                                        <span className="awd-timestamp">{award.award_timestamp}</span>
                                                                                        <Link to={`/${award.remark_url}`} target='_blank'>
                                                                                            <div className='direct-svg'>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M516-120 402-402 120-516v-56l720-268-268 720h-56Zm26-148 162-436-436 162 196 78 78 196Zm-78-196Z" /></svg>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                            }
                                                        </>
                                                }
                                            </>
                                    }

                                    <div className='flip-again-btn dark-mode-responsive-svg' onClick={openAdditionalInfoModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960"
                                            width="24">
                                            <path
                                                d="M360 936H200q-33 0-56.5-23.5T120 856V296q0-33 23.5-56.5T200 216h160v80H200v560h160v80Zm80 80V136h80v880h-80Zm320-720v-80q33 0 56.5 23.5T840 296h-80Zm0 320v-80h80v80h-80Zm0 320v-80h80q0 33-23.5 56.5T760 936Zm0-480v-80h80v80h-80Zm0 320v-80h80v80h-80ZM600 936v-80h80v80h-80Zm0-640v-80h80v80h-80Z" />
                                        </svg>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="images-conteinr">
                                        <img className="big-profile-image" src={userProfileData.userProfileBgImage ? userProfileData.userProfileBgImage : "https://t4.ftcdn.net/jpg/05/32/27/51/360_F_532275175_XYkIzusERG8unr6zYXpZ7LDv8Xx6iPjz.jpg"} alt="Big User Profile Image" />
                                        {
                                            userProfileData.userProfileMainImage ?
                                                <img className="small-profile-image" src={userProfileData.userProfileMainImage} alt="Small User Profile Image" />
                                                :
                                                <Identicon string={userProfileData.userUsername} size="25" className="small-profile-image" />
                                        }
                                    </div>
                                    <div className="profile-stats">
                                        <div className="profile-u-info">
                                            <div className='profile-u-info-main'>
                                                <h2 className="display-inline position-relative">
                                                    {userProfileData.userFullName}
                                                    <small className='position-relative'>
                                                        @{userProfileData.userUsername}
                                                        {userProfileData.showPremiumBadgeOnPremium
                                                            ?
                                                            <span className='premium-profile-badge-p'>
                                                                <UserOnPremiumBadgeIcon />
                                                            </span>
                                                            : null}
                                                    </small>
                                                </h2>
                                            </div>

                                            <ProfileMoreActionDropdown userProfileUsername={userProfileData.userUsername} flipContainer={openAdditionalInfoModal} isReceiverBlocked={isReceiverBlocked} setReceiverBlocked={setReceiverBlocked} blockUser={() => blockUser(userProfileData.userUsername)} setReportModalOpen={setReportModalOpen} isReportModalOpen={isReportModalOpen} setShareModalOpen={setShareModalOpen} openReportModal={openReportModal} shareData={shareData} setShareData={setShareData} setPublicShareModalOpen={setPublicShareModalOpen} currentUserUsername={userProfileData.currentUserUsername} isUserAuthenticated={isUserAuthenticated} isCurrentUserProfileOwner={userProfileData.isCurrentUserProfileOwner} openUserSettings={openUserSettings} />
                                            <div className="profile-u-bio">
                                                <TruncatedParagraph text={userProfileData.userAbout} maxLength={50} />
                                                <div className='profile-actions-container'>
                                                    {
                                                        currentUserUsername &&
                                                        <>
                                                            {
                                                                userProfileData.userUsername !== currentUserUsername &&
                                                                <>
                                                                    <button type='button' className='profile-actions-button profile-actions-btn-primary' onClick={addMeInUserAudience} disabled={userProfileData.whoCanJoinMyAudience === "NO" ? true : false}>
                                                                        {
                                                                            audienceRequestPending ?
                                                                                <>
                                                                                    <svg className='audience-btn-svg' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M680 976q-83 0-141.5-58.5T480 776q0-83 58.5-141.5T680 576q83 0 141.5 58.5T880 776q0 83-58.5 141.5T680 976Zm67-105 28-28-75-75V656h-40v128l87 87Zm-547 65q-33 0-56.5-23.5T120 856V296q0-33 23.5-56.5T200 216h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594 216h166q33 0 56.5 23.5T840 296v250q-18-13-38-22t-42-16V296h-80v120H280V296h-80v560h212q7 22 16 42t22 38H200Zm280-640q17 0 28.5-11.5T520 256q0-17-11.5-28.5T480 216q-17 0-28.5 11.5T440 256q0 17 11.5 28.5T480 296Z" /></svg>
                                                                                    {t("Pending")}
                                                                                    {/* <span className='corner-svg'><CheersGlass /></span> */}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        // ! IF USER IS IN RECEIVER'S AUDIENCE THEN SHOW STEP OUT BUTTON.
                                                                                        userAddedInAudience ?
                                                                                            <>
                                                                                                <svg className='in-audience-svg' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="m424 760 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                                                                {t("Step out from Audience")}
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    userProfileData.whoCanJoinMyAudience === "A" && userProfileData.whoCanJoinMyAudience === "AR" ?
                                                                                                        <>
                                                                                                            <svg className='audience-btn-svg' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M120 936v-80h80V296q0-33 23.5-56.5T280 216h400q33 0 56.5 23.5T760 296v560h80v80H120Zm560-80V296H280v560h400ZM560 616q17 0 28.5-11.5T600 576q0-17-11.5-28.5T560 536q-17 0-28.5 11.5T520 576q0 17 11.5 28.5T560 616ZM280 296v560-560Z" /></svg>
                                                                                                            {t("Be in Audience")}
                                                                                                            {/* <span className='corner-svg'><CheersGlass /></span> */}
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <svg className='audience-btn-svg' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M480 976q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Zm0-80q134 0 227-93t93-227q0-54-17.5-104T732 380L284 828q42 33 92 50.5T480 896ZM228 772l448-448q-42-33-92-50.5T480 256q-134 0-227 93t-93 227q0 54 17.5 104t50.5 92Z" /></svg>
                                                                                                            {t("Be in Audience")}
                                                                                                            {/* <span className='corner-svg'><CheersGlassOneSide /></span> */}
                                                                                                        </>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </button>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="profile-stats-container">
                                            <div className="p-s-section mg-btm-5px" onClick={() => setUserContentDataState(prevState => ({ ...prevState, typeOfContentToFetch: "upshots", sortContentBy: "top" }))}>
                                                <div>
                                                    <span className="ps-emoji"><UpShotProfileCount /></span>
                                                </div>
                                                <div className="ps-count">
                                                    {userProfileData.upShotCount}
                                                </div>
                                            </div>
                                            <div className="p-s-section mg-btm-5px" onClick={() => setUserContentDataState(prevState => ({ ...prevState, typeOfContentToFetch: "remarks", sortContentBy: "top" }))}>
                                                <div>
                                                    <span className="ps-icon"><UpShotRemarkIcon /></span>
                                                </div>
                                                <div className="ps-count">
                                                    {userProfileData.remarkCount}
                                                </div>
                                            </div>
                                            <div className="p-s-section">
                                                <div>
                                                    <span className="ps-icon"><Link to={`/audience/${userProfileData.userUsername}/`}><AudienceCountSVGIcon /></Link></span>
                                                </div>
                                                <div className="ps-count">
                                                    {userProfileData.audienceCount}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>

            <ReportModal
                isReportModalOpen={isReportModalOpen}
                setReportModalOpen={setReportModalOpen}
                reportInfo={reportInfo}
                reportTypeContent={"profile"}
            />

            <ShareModel
                isShareModalOpen={isShareModalOpen}
                openShareMainModal={() => setShareModalOpen(true)}
                closeShareMainModal={() => setShareModalOpen(false)}
                shareData={shareData}
                pageType={"P"}
                contentVisibility={userProfileData.profileVisibility}
            />

            <ShareForPublicOnly
                isPubliShareModalOpen={isPubliShareModalOpen}
                openShareMainModal={() => setPublicShareModalOpen(true)}
                closeShareMainModal={() => setPublicShareModalOpen(false)}
                shareData={shareData}
                pageType={"P"}
            />

            <Suspense fallback={null}>
                <LazySettingsComponent
                    isSettingsOpen={isSettingsOpen}
                    closeSettingsModal={() => setIsSettingsOpen(false)}
                    defaultPageToOpenOnOpen={"Profile"}
                />
            </Suspense>
        </LayoutComponentForHelmet>
    )
}


export default UserProfile;