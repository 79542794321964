import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import textTrimmer from "../helpers/stringCutter";
import showToastError from "../helpers/showToastError";
import pluralize from "../helpers/pluralize";
import submitRemarkComment from "./helpers/remarkCommentSaveFunction";
import UserOnPremiumBadgeIcon from "../Profile/icons/UserOnPremiumBadgeIcon";

function RemarkContainer({ remark, userProfileImage }) {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState({});
    const [likedRemarks, setLikedRemarks] = useState([]);
    const [openedRemarkCommentSection, setOpenedRemarkCommentSection] = useState([]);
    const [remarkCommentState, setRemarkCommentState] = useState({
        inputFieldValue: "",

        isCommentPostLoading: false,
        commentPostError: "",
    });

    const submitRemarkCommentIn = (remarkId) => {
        try {
            setRemarkCommentState({
                isCommentPostLoading: true,
                commentPostError: "",
            })

            submitRemarkComment(
                remarkId,
                remarkCommentState,
                setRemarkCommentState,
            );
            openRemarkCommentCreationSection(remarkId);
        } catch (err) {
            setRemarkCommentState({
                isCommentPostLoading: false,
                commentPostError: "Something went wrong!",
            })
            openRemarkCommentCreationSection(remarkId);
        }
    }

    const openRemarkCommentCreationSection = (remarkId) => {
        const remark = [...openedRemarkCommentSection];
        remark[remarkId] = !remark[remarkId];
        setOpenedRemarkCommentSection(remark);
    }

    const makeRemarkLikeAnimation = (id) => {
        const remark = [...likedRemarks];
        remark[id] = !remark[id];
        setLikedRemarks(remark);
    }

    const likeRemark = (remarkId) => {
        makeRemarkLikeAnimation(remarkId);
        axios.post(`/api/content/main/remark/${remarkId}/like/`).then((res) => {
            // saveTaste("liked")
        }).catch((err) => {
            makeRemarkLikeAnimation(remarkId);
            if (err.response.status === 401) {
                showToastError("You're Banned. You cannot like Remarks.", "error")
            }
        })
    }

    const toggleShowAll = (remarkId) => {
        setShowAll(prevState => ({
            ...prevState,
            [remarkId]: !prevState[remarkId]
        }));
    };

    return (
        <div className="remark-list-item">
            <div className="remark-header">
                <img src={userProfileImage} alt="Avatar"
                    className="user-avatar" />
                <div className="remark-details">
                    {
                        remark.is_profile_name_available ?
                            <>
                                <h2 className="remark-username">{remark.profile_name} <small>@{remark.user_username}</small> {remark.show_premium_badge_on_profile ? <span className='premium-profile-badge'><UserOnPremiumBadgeIcon /></span> : null}</h2>
                            </>
                            :
                            <h2 className="remark-username">@{remark.user_username} {remark.show_premium_badge_on_profile ? <span className='premium-profile-badge'><UserOnPremiumBadgeIcon /></span> : null}</h2>
                    }
                    <span className="remark-date">{remark.remark_timestamp}</span>
                </div>
            </div>
            <div className="r-upshot-title">{remark.remark_upshot_title}
                <span className="counts">Likes: {remark.count_upshot_like}</span>
                <span className="counts">Remarks: {remark.count_upshot_total_remarks}</span>
            </div>

            <div className="remark-content quote-block">
                <span>
                    {showAll[remark.id] ? remark?.content : <>{textTrimmer(remark?.content, 150)}</>}
                    {remark?.content?.length > 150 && (
                        <b className="make-text-cursor" onClick={() => toggleShowAll(remark.id)}>{showAll[remark.id] ? 'Show less' : 'Show more'}</b>
                    )}
                </span>
            </div>
            <div className="remark-actions">
                {
                    remark?.remark_like?.some((like) => like.liked_to === remark.id)
                        ?
                        <>
                            {
                                likedRemarks[remark.id] ?
                                    <span className="remark-btn">
                                        <svg onClick={() => likeRemark(remark.id)} className={`make-text-cursor svg-inline action-btn`} xmlns="http://www.w3.org/2000/svg" height="20"
                                            viewBox="0 -960 960 960" width="20">
                                            <path
                                                d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Zm0-97q93-83 153-141.5t95.5-102Q764-528 778-562t14-67q0-59-40-99t-99-40q-35 0-65.5 14.5T535-713l-35 41h-40l-35-41q-22-26-53.5-40.5T307-768q-59 0-99 40t-40 99q0 33 13 65.5t47.5 75.5q34.5 43 95 102T480-241Zm0-264Z" />
                                        </svg>
                                        {likedRemarks[remark.id] ? <>{remark.count_remark_like - 1} {t("Like")}{pluralize(remark.count_remark_like - 1)}</> : <>{remark.count_remark_like} {t("Like")}{pluralize(remark.count_remark_like + 1)}</>}
                                    </span>
                                    :
                                    <span className="remark-btn">
                                        <svg onClick={() => likeRemark(remark.id)} className={`make-text-cursor svg-inline fill-purple action-btn`} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Z" /></svg>
                                        {likedRemarks[remark.id] ? <>{remark.count_remark_like + 1} {t("Like")}{pluralize(remark.count_remark_like + 1)})</> : <>{remark.count_remark_like} {t("Like")}{pluralize(remark.count_remark_like - 1)}</>}
                                    </span>
                            }
                        </>
                        :
                        <>
                            {likedRemarks[remark.id] ?
                                <span className="remark-btn">
                                    <svg onClick={() => likeRemark(remark.id)} className={`make-text-cursor svg-inline fill-purple action-btn`} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Z" /></svg>
                                    {likedRemarks[remark.id] ? <>{remark.count_remark_like + 1} {t("Like")}{pluralize(remark.count_remark_like + 1)}</> : <>{remark.count_remark_like} {t("Like")}{pluralize(remark.count_remark_like - 1)}</>}
                                </span>
                                :
                                <span className="remark-btn">
                                    <svg onClick={() => likeRemark(remark.id)} className={`make-text-cursor svg-inline action-btn`} xmlns="http://www.w3.org/2000/svg" height="20"
                                        viewBox="0 -960 960 960" width="20">
                                        <path
                                            d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Zm0-97q93-83 153-141.5t95.5-102Q764-528 778-562t14-67q0-59-40-99t-99-40q-35 0-65.5 14.5T535-713l-35 41h-40l-35-41q-22-26-53.5-40.5T307-768q-59 0-99 40t-40 99q0 33 13 65.5t47.5 75.5q34.5 43 95 102T480-241Zm0-264Z" />
                                    </svg>
                                    {likedRemarks[remark.id] ? <>{remark.count_remark_like + 1} {t("Like")}{pluralize(remark.count_remark_like + 1)}</> : <>{remark.count_remark_like} {t("Like")}{pluralize(remark.count_remark_like + 1)}</>}
                                </span>
                            }
                        </>
                }

                {
                    openedRemarkCommentSection[remark.id] ?
                        <div className="remark-comment-inline-form">
                            <input
                                type="text"
                                placeholder="Write your thoughts..."
                                className="remark-comment-inline-form-input-field"
                                onChange={(e) => setRemarkCommentState(prevState => ({
                                    ...prevState,
                                    inputFieldValue: e.target.value
                                }))}
                                value={remarkCommentState.inputFieldValue}
                            />
                            <button className="remark-comment-inline-form-send-button" onClick={(() => submitRemarkCommentIn(remark.id))}>Post</button>
                        </div>
                        :
                        <button className="action-btn comment-btn" onClick={() => openRemarkCommentCreationSection(remark.id)}>Comment{pluralize(remark.count_remark_comment)} <span className="count">{remark.count_remark_comment}</span></button>
                }
            </div>
        </div>
    )
}

export default RemarkContainer;