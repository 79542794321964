import * as React from "react";
const ProfilicSceneCreatorIcon = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width={props.width}
        height={props.height}
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        className={props.className}
        {...props}
    >
        <path
            fill="#b3b3ff"
            opacity={1}
            stroke="none"
            d=" M199.991226,401.183990  C179.349915,401.122681 158.708633,401.034973 138.067276,401.007141  C115.756302,400.977051 93.445251,401.009552 71.134232,401.000732  C63.493820,400.997711 57.006607,394.632141 57.001492,387.091431  C56.987820,366.944946 57.009346,346.798492 57.333252,326.422546  C60.938103,333.650604 66.442650,337.389740 74.804283,337.056458  C82.250740,336.759644 89.716385,336.943939 97.629272,336.936523  C101.917686,336.972168 105.750374,336.993835 109.583084,337.001282  C139.743347,337.059662 169.903625,337.115356 200.295670,337.412140  C219.317917,347.312805 239.373062,352.724213 260.395111,352.856079  C320.539703,353.233276 380.688232,352.984070 440.835205,352.981201  C440.894897,364.781036 441.089752,376.582306 440.961731,388.380096  C440.889130,395.072235 433.882294,401.001526 426.605133,401.002686  C384.045776,401.009521 341.486420,401.015045 297.999420,401.025940  C279.056427,401.070923 261.041077,401.110931 242.559235,401.087860  C228.058899,401.077850 214.025055,401.130920 199.991226,401.183990  z"
        />
        <path
            fill="#F2E6C7"
            opacity={1}
            stroke="none"
            d=" M121.020309,161.165466  C128.166153,161.110260 135.312012,161.007660 142.457855,161.007339  C200.322403,161.004715 258.186951,161.021286 316.774963,161.317688  C324.668518,164.159317 328.920227,169.175797 328.937012,176.831345  C329.019012,214.229050 328.903198,251.627197 328.401428,289.017090  C320.148987,289.005981 312.348083,289.170380 304.556610,288.969543  C278.087738,288.287231 251.533524,290.872955 225.098663,286.464813  C225.031601,280.007172 225.015427,274.010925 225.000015,268.014709  C224.977982,259.445709 222.570953,257.033722 214.052841,256.997925  C197.067535,256.926605 180.082260,256.843018 163.017395,256.441040  C159.336288,252.289246 155.360825,248.742111 152.220978,244.566620  C147.518692,238.313370 143.403534,231.618622 139.503052,225.069275  C165.586716,225.017624 191.206085,225.037643 216.825287,224.983734  C226.228165,224.963959 232.856628,218.279495 232.887695,208.646912  C231.599670,198.350906 225.749832,193.039902 215.923691,193.013702  C185.636795,192.932983 155.349747,192.908432 124.979774,192.481934  C123.604637,181.789688 122.312469,171.477570 121.020309,161.165466  M295.845245,203.016998  C293.100098,197.121124 288.603577,193.564209 282.038147,193.047974  C275.218567,192.511749 268.738556,196.635742 266.181396,202.914017  C263.491943,209.517136 265.366394,216.765945 270.950317,221.356125  C276.307343,225.759781 283.775635,226.222305 289.538849,222.507370  C295.810822,218.464493 298.174530,211.692490 295.845245,203.016998  M304.913513,271.817932  C303.685089,266.806854 300.337982,264.908417 295.362427,264.963043  C285.713623,265.068939 276.062622,264.951782 266.412994,265.010193  C260.561951,265.045593 257.003876,268.161865 257.026428,273.029694  C257.048950,277.890411 260.604340,280.956818 266.480804,280.990723  C276.130402,281.046387 285.780823,281.045685 295.430450,280.991638  C301.650787,280.956818 303.967194,278.856293 304.913513,271.817932  z"
        />
        <path
            fill="#ccccff"
            opacity={1}
            stroke="none"
            d=" M328.857300,289.025177  C328.903198,251.627197 329.019012,214.229050 328.937012,176.831345  C328.920227,169.175797 324.668518,164.159317 317.237823,161.385529  C341.230377,161.122833 365.483551,161.077957 389.993591,161.352310  C386.549103,163.689484 385.515656,166.880768 385.875153,170.908905  C387.411224,188.120667 388.798981,205.345688 390.340393,222.556931  C392.533569,247.045990 394.781006,271.530304 397.074158,296.010193  C397.941132,305.265045 398.474640,314.596741 400.121521,323.719849  C401.710449,332.521942 410.131653,337.850494 419.136414,336.951202  C427.307465,336.135193 433.716522,329.290070 434.247955,320.619141  C435.612366,307.361755 436.741913,294.471008 438.026367,281.595703  C438.843109,273.408508 439.889893,265.244232 440.833496,257.069702  C440.883423,288.731842 440.933319,320.393951 440.909241,352.518646  C380.688232,352.984070 320.539703,353.233276 260.395111,352.856079  C239.373062,352.724213 219.317917,347.312805 200.756195,337.352295  C208.945801,336.995422 216.906647,336.938629 225.342758,336.923035  C254.972610,336.975250 284.127258,337.020142 313.281769,336.976685  C321.978058,336.963745 328.853790,330.231384 328.954041,321.505341  C329.078369,310.680420 328.903137,299.852051 328.857300,289.025177  z"
        />
        <path
            fill="#EAD098"
            opacity={1}
            stroke="none"
            d=" M139.038666,225.111633  C143.403534,231.618622 147.518692,238.313370 152.220978,244.566620  C155.360825,248.742111 159.336288,252.289246 162.545898,256.520386  C143.498779,256.949188 124.843452,256.944214 106.188446,257.019348  C100.306908,257.043060 97.066193,259.805878 97.054207,265.230347  C97.001915,288.880310 97.118828,312.530609 97.174667,336.364563  C97.175049,336.548340 97.173561,336.915894 97.173561,336.915894  C89.716385,336.943939 82.250740,336.759644 74.804283,337.056458  C66.442650,337.389740 60.938103,333.650604 57.400543,325.938110  C57.100983,298.227692 57.034710,270.772278 57.004932,243.316788  C56.980564,220.850723 56.995636,198.384613 57.001583,175.918533  C57.003895,167.190598 63.078152,161.005753 71.664078,161.002716  C87.806335,160.997025 103.948593,161.011444 120.555580,161.091492  C122.312469,171.477570 123.604637,181.789688 124.515472,192.528198  C120.405365,193.015594 116.676582,193.076569 112.490631,193.089813  C109.873955,193.028015 107.714325,192.991776 105.554955,193.003723  C95.921288,193.057083 88.988350,199.780350 89.001938,209.040375  C89.015488,218.268097 95.974632,224.951752 105.651695,224.996735  C116.780624,225.048462 127.909668,225.074463 139.038666,225.111633  z"
        />
        <path
            fill="#F7931E"
            opacity={1}
            stroke="none"
            d=" M199.988632,401.572754  C214.025055,401.130920 228.058899,401.077850 242.554718,401.502441  C242.981430,402.808807 243.043396,403.655243 242.896545,404.463715  C239.720078,421.953125 254.440323,433.440186 266.760559,433.085876  C280.524780,432.690033 294.308228,432.963196 308.083313,432.945221  C309.728149,438.009857 311.372955,443.074493 312.586060,448.555145  C308.323792,448.980774 304.493225,448.998413 300.662659,448.998749  C271.849518,449.001099 243.035934,448.935852 214.224045,449.125854  C211.992294,449.140594 208.811981,450.320465 207.716141,452.019104  C203.399628,458.710144 207.279617,464.975311 215.463608,464.982880  C256.268280,465.020599 297.073059,465.003693 337.877777,465.006012  C338.575043,466.641785 339.344391,468.251068 339.956573,469.918091  C342.154541,475.903320 338.613220,480.982483 332.175842,480.989288  C306.848572,481.016052 281.521240,480.999939 256.193939,480.999908  C226.367722,480.999878 196.541489,481.003693 166.715271,480.997711  C158.460022,480.996063 155.356659,476.138306 158.867432,468.552002  C161.259995,463.382019 163.943878,458.346527 166.323425,453.170898  C167.758163,450.050323 170.084106,448.901733 173.357666,448.979645  C177.011215,449.066559 180.668427,448.999603 184.578186,448.999603  C189.738388,433.246185 194.862213,417.603851 199.988632,401.572754  z"
        />
        <path
            fill="#FBB03B"
            opacity={1}
            stroke="none"
            d=" M440.906250,256.619629  C439.889893,265.244232 438.843109,273.408508 438.026367,281.595703  C436.741913,294.471008 435.612366,307.361755 433.830872,320.619751  C423.302216,320.395599 417.446289,315.487549 415.992615,305.694427  C414.875244,298.167206 414.498718,290.530609 413.771820,282.944672  C412.523865,269.920319 411.201416,256.902771 410.018768,243.872528  C408.880280,231.328415 407.881500,218.771622 406.817261,206.220764  C405.543549,191.199097 404.265503,176.177826 402.989349,161.156372  C415.642212,161.104248 428.296021,161.127731 440.947540,160.968185  C447.301514,160.888046 449.394196,166.681641 448.919403,171.015778  C448.387604,175.870636 447.453674,180.686295 447.040283,185.548370  C445.926941,198.642929 445.075958,211.759766 443.970184,224.855026  C443.087982,235.302902 441.984344,245.732086 440.906250,256.619629  z"
        />
        <path
            fill="#F69420"
            opacity={1}
            stroke="none"
            d=" M402.531158,161.085785  C404.265503,176.177826 405.543549,191.199097 406.817261,206.220764  C407.881500,218.771622 408.880280,231.328415 410.018768,243.872528  C411.201416,256.902771 412.523865,269.920319 413.771820,282.944672  C414.498718,290.530609 414.875244,298.167206 415.992615,305.694427  C417.446289,315.487549 423.302216,320.395599 433.661621,320.992920  C433.716522,329.290070 427.307465,336.135193 419.136414,336.951202  C410.131653,337.850494 401.710449,332.521942 400.121521,323.719849  C398.474640,314.596741 397.941132,305.265045 397.074158,296.010193  C394.781006,271.530304 392.533569,247.045990 390.340393,222.556931  C388.798981,205.345688 387.411224,188.120667 385.875153,170.908905  C385.515656,166.880768 386.549103,163.689484 390.434570,161.413696  C394.436829,161.108978 398.254883,161.062088 402.531158,161.085785  z"
        />
        <path
            fill="#FBB03B"
            opacity={1}
            stroke="none"
            d=" M337.859283,464.653137  C297.073059,465.003693 256.268280,465.020599 215.463608,464.982880  C207.279617,464.975311 203.399628,458.710144 207.716141,452.019104  C208.811981,450.320465 211.992294,449.140594 214.224045,449.125854  C243.035934,448.935852 271.849518,449.001099 300.662659,448.998749  C304.493225,448.998413 308.323792,448.980774 312.628448,448.951538  C314.091614,448.954498 315.080627,448.990631 316.069794,448.997681  C332.203644,449.112305 328.532257,447.462494 335.355469,460.769257  C336.005798,462.037567 337.004059,463.127502 337.859283,464.653137  z"
        />
        <path
            fill="#FBB03B"
            opacity={1}
            stroke="none"
            d=" M308.039001,432.562378  C294.308228,432.963196 280.524780,432.690033 266.760559,433.085876  C254.440323,433.440186 239.720078,421.953125 242.896545,404.463715  C243.043396,403.655243 242.981430,402.808807 243.021210,401.565491  C261.041077,401.110931 279.056427,401.070923 297.535797,401.098846  C301.331421,411.504395 304.663055,421.841949 308.039001,432.562378  z"
        />
        <path
            fill="#0B7D6E"
            opacity={1}
            stroke="none"
            d=" M97.629272,336.936523  C97.173561,336.915894 97.175049,336.548340 97.473930,336.224030  C106.092911,327.805450 114.413010,319.711182 122.962387,311.921570  C133.394073,317.830658 144.381973,320.506165 156.324448,321.123352  C157.559280,320.661194 158.585434,320.234650 159.255997,319.488556  C162.644531,315.718140 165.940536,311.864594 169.596283,308.089050  C182.170013,317.344116 195.748734,322.232025 211.548401,321.029358  C216.178131,326.147644 220.509644,331.124329 224.859314,336.295837  C224.877472,336.490631 224.867493,336.881836 224.867493,336.881836  C216.906647,336.938629 208.945801,336.995422 200.524414,337.112061  C169.903625,337.115356 139.743347,337.059662 109.583084,337.001282  C105.750374,336.993835 101.917686,336.972168 97.629272,336.936523  z"
        />
        <path
            fill="#FBB03B"
            opacity={1}
            stroke="none"
            d=" M224.841141,336.101013  C220.509644,331.124329 216.178131,326.147644 211.415527,320.714966  C202.423309,309.958740 193.862167,299.658478 184.974503,288.965393  C179.384705,295.754395 174.326843,301.897278 169.268997,308.040192  C165.940536,311.864594 162.644531,315.718140 159.255997,319.488556  C158.585434,320.234650 157.559280,320.661194 156.208313,320.808777  C149.554718,312.674744 143.389893,304.967072 137.639236,297.777191  C132.399307,302.642242 127.566200,307.129578 122.733101,311.616913  C114.413010,319.711182 106.092911,327.805450 97.473557,336.040253  C97.118828,312.530609 97.001915,288.880310 97.054207,265.230347  C97.066193,259.805878 100.306908,257.043060 106.188446,257.019348  C124.843452,256.944214 143.498779,256.949188 162.625488,256.843842  C180.082260,256.843018 197.067535,256.926605 214.052841,256.997925  C222.570953,257.033722 224.977982,259.445709 225.000015,268.014709  C225.015427,274.010925 225.031601,280.007172 225.048309,286.937378  C224.979828,303.947906 224.910477,320.024475 224.841141,336.101013  z"
        />
        <path
            fill="#EAD098"
            opacity={1}
            stroke="none"
            d=" M224.859314,336.295837  C224.910477,320.024475 224.979828,303.947906 225.099548,287.398804  C251.533524,290.872955 278.087738,288.287231 304.556610,288.969543  C312.348083,289.170380 320.148987,289.005981 328.401428,289.017090  C328.903137,299.852051 329.078369,310.680420 328.954041,321.505341  C328.853790,330.231384 321.978058,336.963745 313.281769,336.976685  C284.127258,337.020142 254.972610,336.975250 225.342758,336.923035  C224.867493,336.881836 224.877472,336.490631 224.859314,336.295837  M284.494080,296.992340  C278.184174,296.994781 271.872253,296.900726 265.564941,297.030029  C260.464203,297.134583 257.108551,300.346466 257.028168,304.860809  C256.947723,309.378754 260.294189,312.884125 265.293884,312.952637  C275.753082,313.095947 286.216919,313.094696 296.676178,312.953796  C301.685333,312.886322 305.038116,309.397522 304.969421,304.881958  C304.900848,300.373199 301.535645,297.189941 296.448944,297.022766  C292.799713,296.902802 289.143494,296.994873 284.494080,296.992340  z"
        />
        <path
            fill="#F7931E"
            opacity={1}
            stroke="none"
            d=" M139.503052,225.069275  C127.909668,225.074463 116.780624,225.048462 105.651695,224.996735  C95.974632,224.951752 89.015488,218.268097 89.001938,209.040375  C88.988350,199.780350 95.921288,193.057083 105.554955,193.003723  C107.714325,192.991776 109.873955,193.028015 112.509689,193.475372  C114.652626,204.168793 120.259918,208.988174 130.618393,208.995621  C164.691971,209.020157 198.765549,209.028564 232.839127,209.043182  C232.856628,218.279495 226.228165,224.963959 216.825287,224.983734  C191.206085,225.037643 165.586716,225.017624 139.503052,225.069275  z"
        />
        <path
            fill="#FBB03B"
            opacity={1}
            stroke="none"
            d=" M232.887695,208.646896  C198.765549,209.028564 164.691971,209.020157 130.618393,208.995621  C120.259918,208.988174 114.652626,204.168793 112.966858,193.523102  C116.676582,193.076569 120.405365,193.015594 124.598450,192.908325  C155.349747,192.908432 185.636795,192.932983 215.923691,193.013702  C225.749832,193.039902 231.599670,198.350906 232.887695,208.646896  z"
        />
        <path
            fill="#F2E6C7"
            opacity={1}
            stroke="none"
            d=" M295.961304,203.395767  C298.174530,211.692490 295.810822,218.464493 289.538849,222.507370  C283.775635,226.222305 276.307343,225.759781 270.950317,221.356125  C265.366394,216.765945 263.491943,209.517136 266.181396,202.914017  C268.738556,196.635742 275.218567,192.511749 282.038147,193.047974  C288.603577,193.564209 293.100098,197.121124 295.961304,203.395767  z"
        />
        <path
            fill="#44240E"
            opacity={1}
            stroke="none"
            d=" M304.942017,272.214294  C303.967194,278.856293 301.650787,280.956818 295.430450,280.991638  C285.780823,281.045685 276.130402,281.046387 266.480804,280.990723  C260.604340,280.956818 257.048950,277.890411 257.026428,273.029694  C257.003876,268.161865 260.561951,265.045593 266.412994,265.010193  C276.062622,264.951782 285.713623,265.068939 295.362427,264.963043  C300.337982,264.908417 303.685089,266.806854 304.942017,272.214294  z"
        />
        <path
            fill="#0C9687"
            opacity={1}
            stroke="none"
            d=" M169.596283,308.089050  C174.326843,301.897278 179.384705,295.754395 184.974503,288.965393  C193.862167,299.658478 202.423309,309.958740 211.117310,320.573364  C195.748734,322.232025 182.170013,317.344116 169.596283,308.089050  z"
        />
        <path
            fill="#0C9687"
            opacity={1}
            stroke="none"
            d=" M122.962372,311.921570  C127.566200,307.129578 132.399307,302.642242 137.639236,297.777191  C143.389893,304.967072 149.554718,312.674744 155.835663,320.697021  C144.381973,320.506165 133.394073,317.830658 122.962372,311.921570  z"
        />
        <path
            fill="#44240E"
            opacity={1}
            stroke="none"
            d=" M284.992249,296.992371  C289.143494,296.994873 292.799713,296.902802 296.448944,297.022766  C301.535645,297.189941 304.900848,300.373199 304.969421,304.881958  C305.038116,309.397522 301.685333,312.886322 296.676178,312.953796  C286.216919,313.094696 275.753082,313.095947 265.293884,312.952637  C260.294189,312.884125 256.947723,309.378754 257.028168,304.860809  C257.108551,300.346466 260.464203,297.134583 265.564941,297.030029  C271.872253,296.900726 278.184174,296.994781 284.992249,296.992371  z"
        />
    </svg>
);
export default ProfilicSceneCreatorIcon;
