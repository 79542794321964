import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import showToastError from '../../helpers/showToastError';
import "./styles/customUserModal.css";

function CustomUpShotVisibilityModal({ show, onHide, upShotVisibilityState, setUpShotVisibilityState }) {
    const [searchTextState, setSearchTextState] = useState({
        inputFieldTextValue: "",
        currentSelectedSectionType: "",

        includeSearchText: "",
        excludeSearchText: "",
    })
    const [fetchedUsersState, setFetchedUsersState] = useState({
        fetchedUsers: [],
        isUserFetchLoading: false,
        userFetchError: "",
    })

    const updateSectionType = (sectionType) => {
        setSearchTextState(prevState => ({
            ...prevState,
            currentSelectedSectionType: sectionType
        }))
    }

    useEffect(() => {
        if (searchTextState.inputFieldTextValue) {
            setFetchedUsersState(prevState => ({
                ...prevState,
                isUserFetchLoading: true,
                userFetchError: "",
            }))
            axios.get(`/api/search/search-users/?username=${searchTextState.inputFieldTextValue}`).then((res) => {
                setFetchedUsersState(prevState => ({
                    ...prevState,
                    fetchedUsers: res.data,
                    isUserFetchLoading: false,
                    userFetchError: "",
                }))
            }).catch(() => {
                setFetchedUsersState(prevState => ({
                    ...prevState,
                    isUserFetchLoading: false,
                    userFetchError: "Something went wrong!",
                }))
            })
        }
    }, [searchTextState.inputFieldTextValue])

    // ? Add selected user to the list of includes.
    const addSelectedUsersToInclude = (username) => {
        if (!upShotVisibilityState.excludedUsers.includes(username)) {
            if (!upShotVisibilityState.includedUsers.includes(username)) {
                setUpShotVisibilityState(prevState => ({
                    ...prevState,
                    includedUsers: [...prevState.includedUsers, username],
                }))
            }
        } else {
            showToastError(`Cannot add same user (${username}) in both list`, "error")
        }
    }

    // ? Add selected user to the list of excludes.
    const addSelectedUsersToExclude = (username) => {
        if (!upShotVisibilityState.includedUsers.includes(username)) {
            if (!upShotVisibilityState.excludedUsers.includes(username)) {
                setUpShotVisibilityState(prevState => ({
                    ...prevState,
                    excludedUsers: [...prevState.excludedUsers, username],
                }))
            }
        } else {
            showToastError(`Cannot add same user (${username}) in both list`, "error")
        }
    }

    // ? Delete selected users from list.
    const removeFromIncludedUsers = (username) => {
        setUpShotVisibilityState(prevState => ({
            ...prevState,
            includedUsers: prevState.includedUsers.filter(item => item !== username)
        }))
    }

    // ? Delete selected users from list.
    const removeFromExcludedUsers = (username) => {
        setUpShotVisibilityState(prevState => ({
            ...prevState,
            excludedUsers: prevState.excludedUsers.filter(item => item !== username)
        }))
    }

    // ? When user clicks on "Discard" button that means user doesn't want to continue
    // ? adding Include/Exclude users.
    // * Tasks
    // ? Closes the Include/Exclude modal.
    // ? As the process stops, it removes the "custom" option.
    const discardChangesAndCloseModal = () => {
        onHide();
        setUpShotVisibilityState(prevState => ({
            ...prevState,
            includedUsers: [],
            excludedUsers: [],
            selectedVisibilityType: "custom"
        }));
        if (upShotVisibilityState.includedUsers.length < 1) {
            showToastError("You didn't add any users in Included users to show posts. So It is technically Private", "warning");
            setUpShotVisibilityState(prevState => ({
                ...prevState,
                selectedVisibilityType: "onlyMe"
            }));
        }
    }

    if (!show) {
        return null;
    }

    return (
        <div className="custom-user-modal-container">
            <div className="custom-user-modal-content">
                <span className="close-v2-button" onClick={discardChangesAndCloseModal}>&times;</span>
                <h2>Select custom users</h2>
                <hr />
                <div className="custom-user-button-container">
                    <button id="button1" className={`custom-user-select-button ${searchTextState.currentSelectedSectionType === "include" && "active"}`} onClick={() => updateSectionType("include")}>Include</button>
                    <button id="button2" className={`custom-user-select-button ${searchTextState.currentSelectedSectionType === "exclude" && "active"}`} onClick={() => updateSectionType("exclude")}>Exclude</button>
                </div>

                {
                    searchTextState.currentSelectedSectionType &&
                    <div className="custom-user-input-container">
                        <input
                            type="text"
                            name={searchTextState.currentSelectedSectionType === "include" ? "includeSearchText" : "excludeSearchText"}
                            className="custom-user-input"
                            onChange={(e) => setSearchTextState(prevState => ({ ...prevState, inputFieldTextValue: e.target.value }))}
                            value={searchTextState.inputFieldTextValue}
                            placeholder={searchTextState.currentSelectedSectionType === "include" ? "Search users to Include" : "Search users to Exclude"}
                        />
                    </div>
                }

                <ul className='custom-users-list'>
                    {
                        fetchedUsersState.fetchedUsers?.map((res) =>
                            <li key={res.username} className='make-text-cursor' onClick={searchTextState.currentSelectedSectionType === "include" ? () => addSelectedUsersToInclude(res.username) : () => addSelectedUsersToExclude(res.username)}>{res.username}</li>
                        )
                    }
                </ul>

                {
                    upShotVisibilityState.includedUsers.length >= 1 &&
                    <h3 className='inc-exc-header'>Included users</h3>
                }
                {
                    upShotVisibilityState.includedUsers?.map((res) =>
                        <>
                            <tags className="tagify tagify--outside" tabIndex="-1" aria-expanded="false">
                                <tag title={res} className="tagify__tag tagify--noAnim" value={res}>
                                    <x title="" className="tagify__tag__removeBtn" role="button" aria-label="remove tag" onClick={() => removeFromIncludedUsers(res)}></x>
                                    <div>
                                        <span className="tagify__tag-text">{res}</span>
                                    </div>
                                </tag>
                            </tags>
                        </>
                    )
                }

                {
                    upShotVisibilityState.excludedUsers.length >= 1 &&
                    <h3 className='inc-exc-header'>Excluded users</h3>
                }
                {
                    upShotVisibilityState.excludedUsers?.map((res) =>
                        <>
                            <tags className="tagify tagify--outside" tabIndex="-1" aria-expanded="false">
                                <tag title={res} className="tagify__tag tagify--noAnim" value={res}>
                                    <x title="" className="tagify__tag__removeBtn" role="button" aria-label="remove tag" onClick={() => removeFromExcludedUsers(res)}></x>
                                    <div>
                                        <span className="tagify__tag-text">{res}</span>
                                    </div>
                                </tag>
                            </tags>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default CustomUpShotVisibilityModal;