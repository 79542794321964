import axios from "axios";


export const setAxiosAuthToken = token => {
  if (typeof token !== "undefined" && token) {
    // Apply for every request
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const logout = () => {
  axios
    .post("/api/v1/token/logout/")
    .then(response => {
      setAxiosAuthToken("")
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      // ------
      // dispatch(push("/"));
      // navigate("/");
      // ------

      // toast.success("Logout successful.");
    })
    .catch(error => {
      // dispatch(unsetCurrentUser());
      // toastOnError(error);
    });
}