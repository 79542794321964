import * as React from "react";
const ShareUpShotV2Icon = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="4%"
        className={props.customclassnames}
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="#FF8086"
            opacity={1}
            stroke="none"
            d=" M513.000000,10.000007  C513.000000,15.688041 513.000000,21.376081 512.712585,27.194227  C511.943787,28.122456 511.294525,28.864254 511.005157,29.726791  C498.659088,66.529259 486.305481,103.329277 474.034271,140.156769  C434.003082,260.295532 394.018372,380.449829 353.942322,500.573608  C352.155548,505.929382 349.254303,510.657898 342.799683,511.529724  C327.138092,478.594482 311.667633,445.943756 296.113586,413.332886  C280.701050,381.018799 265.163391,348.764374 249.766449,316.442902  C244.326828,305.024017 239.140656,293.484467 233.926849,281.662842  C235.612183,279.433655 237.080811,277.413086 238.825073,275.667999  C326.995941,187.455261 415.191742,99.267487 503.371277,11.063478  C505.011688,9.422622 506.489441,7.619180 508.373260,6.041033  C510.135620,7.460310 511.567810,8.730162 513.000000,10.000007  z"
        />
        <path
            fill="#FF646F"
            opacity={1}
            stroke="none"
            d=" M508.043030,5.891614  C506.489441,7.619180 505.011688,9.422622 503.371277,11.063478  C415.191742,99.267487 326.995941,187.455261 238.825073,275.667999  C237.080811,277.413086 235.612183,279.433655 233.633820,281.662964  C232.858566,282.000946 232.464462,282.001678 231.961609,281.674805  C229.489395,279.873230 227.242584,278.154816 224.744736,276.962189  C191.099167,260.897705 157.426376,244.890198 123.743507,228.904007  C107.054062,220.983032 90.321312,213.153336 73.625420,205.245895  C64.749237,201.041977 55.825001,196.926788 47.077980,192.466553  C38.458729,188.071503 35.203381,179.167511 40.477882,172.770447  C43.326111,169.316025 47.961494,166.671082 52.310871,165.215012  C179.267639,122.713181 306.273682,80.358231 433.320892,38.127254  C451.057190,32.231636 465.574738,21.856720 478.085144,8.314146  C478.939117,7.389714 479.350189,6.056110 479.972534,4.946767  C479.977753,4.980727 479.909027,4.980925 480.117157,4.885074  C480.577576,4.613204 480.798828,4.404341 481.381287,4.085720  C483.182343,3.005873 484.591156,2.002938 486.000000,1.000002  C491.354919,1.000000 496.709869,1.000000 502.201416,1.240422  C504.239716,2.951104 506.141388,4.421363 508.043030,5.891614  z"
        />
        <path
            fill="#FB4455"
            opacity={1}
            stroke="none"
            d=" M1.000000,169.467606  C1.212866,169.186676 1.425732,169.373367 1.638598,169.560043  C7.503757,159.483078 18.445015,158.337128 27.948725,155.157730  C175.822876,105.687759 323.759186,56.403641 471.687225,7.094899  C474.144531,6.275796 476.677765,5.684397 479.571411,4.949776  C479.350189,6.056110 478.939117,7.389714 478.085144,8.314146  C465.574738,21.856720 451.057190,32.231636 433.320892,38.127254  C306.273682,80.358231 179.267639,122.713181 52.310871,165.215012  C47.961494,166.671082 43.326111,169.316025 40.477882,172.770447  C35.203381,179.167511 38.458729,188.071503 47.077980,192.466553  C55.825001,196.926788 64.749237,201.041977 73.625420,205.245895  C90.321312,213.153336 107.054062,220.983032 123.743507,228.904007  C157.426376,244.890198 191.099167,260.897705 224.744736,276.962189  C227.242584,278.154816 229.489395,279.873230 231.764893,281.948120  C227.760742,286.397980 223.844589,290.246887 219.928421,294.095795  C199.364624,284.282166 178.787643,274.495972 158.242188,264.644135  C146.781967,259.148773 135.403381,253.482971 123.936913,248.000839  C94.556572,233.954056 65.158287,219.944656 35.743237,205.970688  C27.666824,202.133896 19.280571,198.881729 11.479975,194.560699  C7.629028,192.427521 4.787630,188.471863 1.244717,185.670410  C1.000000,180.645065 1.000000,175.290146 1.000000,169.467606  z"
        />
        <path
            fill="#FF646F"
            opacity={1}
            stroke="none"
            d=" M220.077576,294.418701  C223.844589,290.246887 227.760742,286.397980 231.873627,282.275726  C232.464462,282.001678 232.858566,282.000946 233.545715,282.000122  C239.140656,293.484467 244.326828,305.024017 249.766449,316.442902  C265.163391,348.764374 280.701050,381.018799 296.113586,413.332886  C311.667633,445.943756 327.138092,478.594482 342.799683,511.529724  C342.773468,512.033752 342.592804,512.235901 342.706085,512.718994  C338.310760,513.000000 333.621490,513.000000 328.466125,513.000000  C328.096588,512.649231 328.356689,512.041931 328.266449,511.984406  C322.501221,508.310760 319.547302,502.809235 316.743225,496.677765  C308.222717,478.046631 298.942139,459.764496 290.070435,441.292206  C280.006714,420.338013 270.064392,399.325470 260.070618,378.337677  C250.076904,357.350006 240.087677,336.360168 230.090561,315.374115  C226.812149,308.492065 223.515198,301.618866 220.077576,294.418701  z"
        />
    </svg>
);
export default ShareUpShotV2Icon;
