import "./userAgreement.css";
import LegalPolicyHeader from "../LegalPolicyHeader";
import UserLegalReadablesActions from "../UserLegalReadablesActions";
import { Link } from "react-router-dom";
import LayoutComponentForHelmet from "../../../../MainInterface/SEO/LayoutComponentForHelmet";

function UserAgreement() {

    return (
        <LayoutComponentForHelmet pageTitle={"User Agreement - SpreeCine"}>
            <div className="user-agreement">
                <LegalPolicyHeader includeAppName header="User Agreement" />
                <UserLegalReadablesActions />

                <div className="ua-overview">
                    Before you start using SpreeCine, please take a moment to read and accept our Terms of Service. By using our platform, you acknowledge and agree to abide by these terms, ensuring a secure and enjoyable experience for all users. Your commitment to respecting these guidelines is essential in maintaining a vibrant and inclusive community. If you do not agree with these terms, we regret that you will not be able to use SpreeCine. Thank you for your cooperation in making SpreeCine a great place to connect and share.
                </div>

                <h2 className="ua-subheader">1. Your Access to the Services</h2>
                <p className="ua-explaination">
                    Users under the age of 13 are strictly not permitted to use or access our services.
                    It's important to note that we may provide additional services with age requirements, so we kindly ask that you carefully review all notifications and any supplementary terms when accessing our services.
                </p>

                <p className="ua-ul-header">
                    By utilizing the services, you affirm that:
                </p>
                <ul className="ua-ul">
                    <li>You are at least 13 years old and meet the age requirements set by the laws of your country to use our services.</li>
                    <li>You can enter into a legally binding agreement with SpreeCine, or, if you're between 13 and the legal age of majority in your jurisdiction, your legal guardian has reviewed and accepted these Terms on your behalf.</li>
                    <li>You are in compliance with all applicable laws and are not prohibited from using the Services.</li>
                    <li>Your access to the Services has not been permanently suspended or revoked.</li>
                </ul>

                <p className="ua-ul-header">
                    In addition, you agree to use the Services responsibly and to respect the rights and privacy of others.
                </p>

                <h2 className="ua-subheader">2. Privacy</h2>
                <p className="ua-explaination">
                    SpreeCine's Privacy Policy outlines our practices for collecting, using, and sharing your information when you use our Services. By using our Services, you acknowledge and agree to the terms described in the Privacy Policy."
                </p>

                <h2 className="ua-subheader">3. Your Use of the Services</h2>
                <p className="ua-explaination">
                    Provided you continuously adhere to these Terms, SpreeCine extends a personal, non-transferable, non-exclusive, revocable, limited license that allows you to: (a) install and utilize our mobile application associated with the Services, obtained from a legitimate mobile marketplace on a device under your ownership or control; and (b) access and utilize the Services. Please note that all rights not explicitly conferred by these Terms are reserved.
                </p>

                <p className="ua-ul-header">
                    With the exception of cases where such restrictions are prohibited by applicable law, you are prohibited from, unless we grant written permission
                </p>

                <ul className="ua-ul">
                    <li>
                        - license, sell, transfer, assign, distribute, host, or otherwise commercially exploit the Services or Content;
                    </li>
                    <li>
                        - modify, prepare derivative works of, disassemble, decompile, or reverse engineer any part of the Services or Content; or
                    </li>
                    <li>
                        - access the Services or Content in order to build a similar or competitive website, product, or service, except as permitted under any Additional Terms (as defined below).
                    </li>
                </ul>
                <br />
                <p className="ua-explaination">
                    We improve our Services by adding or removing features.
                    We can change, suspend, or discontinue them at any time, with or without notice.
                    You agree that we won't be responsible for these changes
                </p>

                <h2 className="ua-subheader">4. Account and Account Security (C)</h2>
                <p className="ua-explaination">
                    Password Protection: Safeguard your account by keeping your login details confidential and secure.
                    Reporting Suspicious Activity: If you notice any unauthorized access or suspicious behavior on your account, please report it immediately to ensure the security of your account and our platform. Your vigilance is crucial in maintaining a safe and trusted environment for all our users.
                </p>

                <h2 className="ua-subheader">5. Your Posted Content (C)</h2>
                <p className="ua-explaination">
                    The Services encompass various materials known as "Content," including information, text, links, graphics, photos, videos, audio, and more. This Content also includes what you create or submit, known as "Your Content." Please be aware that we don't take responsibility for the accuracy or completeness of Your Content.

                    By submitting Your Content, you confirm that you have the necessary rights to grant us permission to use it. Keep in mind that you are solely responsible for the Content you post.

                    You own Your Content, but you give SpreeCine a broad license to use it:
                    <br />
                    <br />

                    When you submit Your Content, you grant us a worldwide, non-exclusive license to use, modify, and share it. This allows us to display Your Content and make it available to others through syndication or other means.
                    <br />

                    Your ideas and feedback are welcome, and we may use them without compensation or obligation.
                    <br />

                    We have the right to remove Your Content if it violates these Terms, our Content Policy, or if it could create legal issues.
                </p>

                <h2 className="ua-subheader">6. Prohibited Actions and Limitations</h2>
                <p className="ua-explaination">
                    When utilizing or accessing the Services, you are required to adhere to these Terms and all relevant laws, rules, and regulations. We urge you to thoroughly examine the Content Policy, which is integrated by reference into these Terms and constitutes an integral component of them. The Content Policy outlines SpreeCine's guidelines pertaining to restricted content and behavior.
                </p>

                <p className="ua-ul-header">
                    Furthermore, beyond the restrictions highlighted in the Content Policy, you are prohibited from engaging in any of the following actions
                </p>

                <ul className="ua-ul">
                    <li>Don't use the Services in a way that could slow them down or cause problems.</li>
                    <li>Don't try to get into another user's account or areas of the Services that are meant to be private.</li>
                    <li>Don't send harmful software like viruses or worms through the Services.</li>
                    <li>Don't use the Services to break the law or steal someone else's intellectual property.</li>
                    <li>Don't gather data from the Services in ways that aren't allowed (except as our robots.txt file permits).</li>
                    <li>Don't use the Services for any activity that we/anyone believe is an abuse or fraud on SpreeCine or any payment system."</li>
                </ul>

                <p className="ua-explaination">
                    We welcome you to report any content or behavior you think breaks these Terms or our Content Policy. If you discover a security problem, please email us at security@appname.com.
                </p>


                <h2 className="ua-subheader">7. Copyright, Trademark, the DMCA, and Takedowns</h2>
                <p className="ua-explaination">
                    SpreeCine upholds a strong commitment to safeguarding intellectual property rights and expects the same from our users. Our policy includes the removal of infringing material from our Services and, when necessary, the termination of users who repeatedly infringe on these rights. If you believe that any content on our Services infringes upon your copyright or trademark, please inform SpreeCine's Designated Agent by using our Copyright Report Form or Trademark Report Form. You can also reach out to
                    <br />
                    <br />

                    Copyright Agent
                    <br />
                    SpreeCine, Inc.
                    <br />
                    303 2nd Street, Suite 500S
                    <br />
                    San Francisco, CA 94107
                    <br />
                    copyright@SpreeCine.com
                </p>

                <h2 className="ua-subheader">8. Paid Services</h2>
                <p className="ua-explaination">
                    While many aspects of the Services are free to use, certain services, features, and products may be offered for purchase (referred to as 'Paid Services'). In addition to these Terms, the use of Paid Services is subject to the SpreeCine Premium and Virtual Goods Agreement. When you purchase or use Paid Services, you are also bound by the terms of the Premium and Virtual Goods Agreement and, if applicable, the Previews Terms of Use.
                </p>

                <h2 className="ua-subheader">10. Indemnity</h2>
                <p className="ua-explaination">
                    Unless prohibited by law, you agree to protect, compensate, and hold SpreeCine and its affiliates, along with their directors, officers, employees, agents, contractors, third-party service providers, and licensors (referred to as the 'SpreeCine Entities'), harmless from any claims, demands, liabilities, damages, losses, and expenses (including legal costs) made by third parties. This protection covers issues related to: (a) your use of the Services; (b) your violation of these Terms; (c) your violation of applicable laws or regulations; and (d) Your Content. We have the right to oversee the defense of any matter where you are obligated to indemnify us, and you must cooperate with us in these cases.
                </p>

                <h2 className="ua-subheader">11. Disclaimers and Limitation of Liability</h2>
                <p className="ua-explaination">
                    Nothing in these Terms will prejudice the statutory rights that you may have as a user of the Services. Some countries, states, provinces or other jurisdictions do not allow the exclusion of certain warranties or the limitation of liability as stated in this section, so the below terms may not fully apply to you. Instead, in such jurisdictions, the exclusions and limitations below shall apply only to the extent permitted by the laws of such jurisdictions.
                    <br />
                    <br />

                    THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, LEGAL, OR STATUTORY, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE SpreeCine ENTITIES DO NOT WARRANT THAT THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR FREE. SpreeCine DOES NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY CONTENT AVAILABLE ON OR LINKED TO THE SERVICES OR THE ACTIONS OF ANY THIRD PARTY OR USER, INCLUDING MODERATORS. WHILE SpreeCine ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF OUR SERVICES SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.

                    IN NO EVENT WILL ANY OF THE SpreeCine ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO THESE TERMS OR THE SERVICES, INCLUDING THOSE ARISING FROM OR RELATING TO CONTENT MADE AVAILABLE ON THE SERVICES THAT IS ALLEGED TO BE DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR RESULTING LOSS OF DATA. IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE SpreeCine ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS ($100) OR ANY AMOUNT YOU PAID SpreeCine IN THE PREVIOUS SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF THIS SECTION WILL APPLY TO ANY THEORY OF LIABILITY, INCLUDING THOSE BASED ON WARRANTY, CONTRACT, STATUTE, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF THE SpreeCine ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY SET FORTH IN THESE TERMS IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.
                </p>

                <h2 className="ua-subheader">12. Governing Law and Venue</h2>
                <p className="ua-explaination">
                    If you have an issue or dispute, you agree to raise it and try to resolve it with us informally. You can contact us with feedback and concerns here.
                </p>

                <h2 className="ua-subheader">13. Changes to these Terms</h2>
                <p className="ua-explaination">
                    We may change or update this policy from time to time and will notify you of any material changes to this policy.
                    Previous versions of this privacy policy are available upon request.
                </p>


                <h2 className="ua-subheader">14. Additional Terms</h2>
                <p className="ua-explaination">
                    We provide different Services, and sometimes you may need to accept extra rules or terms for a particular product or service (called 'Additional Terms'). These Additional Terms are part of these Terms, and if they clash with these Terms, they apply to the specific Service you're using
                </p>
                <ul className="ua-ul">
                    <li> you use Premium Subscription, you must also agree to the Premium Agreement.</li>
                    <li> If you use any preview features, you must also agree to the Terms of Service.</li>
                </ul>

                <h2 className="ua-subheader"><Link to={"/contact"} className="clr-blue">Contact Us</Link></h2>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default UserAgreement;