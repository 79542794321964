import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';
import './styles/pageGotMissed.css';

function PageGotMissed() {
    return (
        <LayoutComponentForHelmet pageTitle={"Page Not Found - SpreeCine"}>
            <div className='page-not-exist page-not-exist-container'>
                <div className="center">
                    <h1>404</h1>

                    <p>PAGE NOT FOUND.</p>
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default PageGotMissed;