import React, { useState, useEffect, useContext } from 'react';
import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import "./styles/discussions.css";
import TrophyIcon from './icons/trophyIcon';
import ReportModal from '../Report/Report';
import { GlobalLoginRequiredModalContext } from "../../MainInterface/contexts/LoginRequired/LoginRequiredModal";
import InfoPopup from '../modals/InfoPopup';
import showToastError from "../helpers/showToastError"
import ConfirmationDialog from '../modals/ConfirmationModal';
import TournamentForPremiumUsers from './icons/tournamentForPremiumUsers';
import UserOnPremiumBadgeIcon from "../Profile/icons/UserOnPremiumBadgeIcon";
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';
import { defaultBackendWebSocketOrigin } from '../constants/constants';
import TournamentNotFound from './TournamentNotFound';

function MovieDiscussionChatRoom({ currentUserUsername }) {
    const { t } = useTranslation();
    const params = useParams();
    const messageContainerRef = useRef(null);
    const { openModal } = useContext(GlobalLoginRequiredModalContext);
    const [tournamentValid, setTournamentValid] = useState(true);
    const [isTournamentLoading, setTournamentLoading] = useState(false);

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    const [socket, setSocket] = useState(null);

    const [page, setPage] = useState(1);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [hasMoreMessages, setHasMoreMessages] = useState(true);

    const [tournamentPremiumState, setTournamentPremiumState] = useState({
        tournamentOnlyForPremiumUsers: true,
        isUserOnPremium: false,
    });

    const [tournamentExpirationTime, setTournamentExpirationTime] = useState("");
    const [isTournamentExpired, setTournamentExpired] = useState(false);

    const [tournamentState, setTournamentState] = useState({
        tournamentName: "",
        tournamentId: 0,
    })
    const [winnerState, setWinnerState] = useState({
        isWinnerFinalized: false,
        tournamentName: "",
        isWinnerModalOpen: false,
        tournamentWinnerName: "",
        tournamentWinnerUsername: "",
        winnerUpShotId: 0,
        winnerUserId: 0,
        winnerMessages: [],
    });
    const [isOnlyForPremiumUsersOpen, setOnlyForPremiumUsers] = useState(false);

    const [likedMessages, setLikedMessages] = useState([]);

    const [messageCountState, setMessageCountState] = useState({
        messagesCount: 0,
        isLimitExceed: false,
    })

    const [reportDataState, setReportDataState] = useState({
        isReportModalOpen: false,
    });
    const [reportStateId, setReportStateId] = useState(null);
    const [deleteTournamentMessageState, setDeleteTournamentMessageState] = useState({
        currentContentId: 0,
        isDeleteModalOpen: false,
        isDeleteLoading: false,
        deleteError: "",
    })


    const customSteps = [
        { "title": "Limited messages", "description": "You can send 3 messages in a Tournament and 7 with Premium membership." },
        { "title": "Read Guidelines", "description": "Don't forget to read Tournament Guidelines. It will take you less than 1 minute to read." },
    ];

    const openReportModal = (id) => {
        setReportDataState({
            isReportModalOpen: true,
        });
        setReportStateId(id)
    }

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const likeUnLikeMessageAnimation = (messageId) => {
        setLikedMessages(prevLikedMessages => {
            const updatedLikedMessages = [...prevLikedMessages];
            updatedLikedMessages[messageId] = !updatedLikedMessages[messageId];
            return updatedLikedMessages;
        });
    }

    const likeUnLikeMessage = (messageId, actionType) => {
        likeUnLikeMessageAnimation(messageId);
        axios.post(`/api/trivia/like/tournament-message/${messageId}/`).then(() => {
        }).catch(() => {
            likeUnLikeMessageAnimation(messageId);
        })
    }

    const deleteTournamentMessage = () => {
        setDeleteTournamentMessageState(prevState => ({
            ...prevState,
            isDeleteLoading: true,
            deleteError: "",
        }));
        axios.put(`/api/trivia/tournament-message/delete/${deleteTournamentMessageState.currentContentId}/`).then((res) => {
            setDeleteTournamentMessageState(prevState => ({
                ...prevState,
                isDeleteLoading: false,
                deleteError: "",
                currentContentId: 0,
            }));
            const updatedMessages = messages.filter(message => message.id !== deleteTournamentMessageState.currentContentId);
            setMessages(updatedMessages);
            closeDeleteDialog();
        }).catch(() => {
            showToastError("Something went wrong while delete message!", "error");
            setDeleteTournamentMessageState(prevState => ({
                ...prevState,
                isDeleteLoading: true,
                deleteError: "Something went wrong!",
            }));
        })
    }

    const connectWebSocket = () => {
        const socket = new WebSocket(`ws://${defaultBackendWebSocketOrigin}/ws/chat/${params.roomId}/?token=${localStorage.getItem("token")}`);
        socket.onopen = () => {
            setSocket(socket);
        };
        setTournamentLoading(true);
        // Listen for messages from the WebSocket
        socket.onmessage = (event) => {
            setTournamentLoading(false);
            setTournamentValid(true);
            const message = JSON.parse(event.data);
            if (currentUserUsername) {
                if (currentUserUsername === message.current_user_username) {
                    console.log(message.is_limit_exceeded)
                    setMessageCountState(prevState => ({
                        ...prevState,
                        messagesCount: message.user_messages_count !== null ? message.user_messages_count : 0,
                        isLimitExceed: message.is_limit_exceeded,
                    }));
                }
            }
            setTournamentState({
                tournamentName: message.room_name
            })
            setTournamentExpirationTime(message.remaining_time);
            if (message.remaining_time === "Tournament expired") {
                setTournamentExpired(true);
                setWinnerState({
                    tournamentName: message.room_name,
                    isWinnerFinalized: message.winner !== null ? true : false,
                    isWinnerModalOpen: message.winner !== null ? true : false,
                    tournamentWinnerName: message.winner_name,
                    tournamentWinnerUsername: message.winner_username,
                    winnerUpShotId: message.picked_upshot,
                    winnerUserId: message.winner,
                    winnerMessages: message.winner_s_posted_message,
                });
            };
            setTournamentPremiumState({
                isUserOnPremium: message.user_on_premium,
                tournamentOnlyForPremiumUsers: message.only_premium_can_join,
            })

            if (message.only_premium_can_join && !message.user_on_premium) {
                setOnlyForPremiumUsers(true)
            }
            setMessages(prevMessages => [...prevMessages, message]);
        };

        socket.onclose = (event) => {
            setTournamentLoading(false);
            if (event.code === 1000) {
                setTournamentValid(false);
            }
            console.error('Tournament failed to Fetch! Try again (oc)');
        };

        socket.onerror = (event) => {
            setTournamentLoading(false);
            console.error('Tournament failed to Fetch! Try again (oe)');
        };
    }

    useEffect(() => {
        if (!socket || socket.readyState === WebSocket.CLOSED) {
            connectWebSocket();
        }

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [socket]);

    const sendMessage = () => {
        if (socket && inputMessage) {
            const message = {
                message: inputMessage,
            };
            socket.send(JSON.stringify(message));
            setInputMessage('');
        }
    };

    const fetchOldDiscssionMessage = (page) => {
        console.log(page)
        axios.get(`/api/trivia/older/tournament-messages/?chat_id=${params.roomId}&page=${page}`).then((response) => {
            console.log(response);
            const data = response?.data?.results;
            setMessages(prevMessages => [...prevMessages, ...data]);
            if (response?.data?.next === null) {
                setHasMoreMessages(false);
                return;
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleScroll = () => {
        const chatContainer = document.getElementById('tournament-chat-container');
        if (chatContainer.scrollTop <= 30 && hasMoreMessages) {
            setPage(prevPage => prevPage + 1);
        }
    };

    // useEffect(() => {
    //     connectWebSocket();
    // }, [])

    useEffect(() => {
        if (tournamentPremiumState.tournamentOnlyForPremiumUsers) {
            if (tournamentPremiumState.isUserOnPremium) {
                if (currentUserUsername) {
                    setIsUserAuthenticated(true);
                    fetchOldDiscssionMessage(page);
                } else {
                    fetchOldDiscssionMessage(1);
                    // openModal();
                    return;
                }
            }
        } else {
            if (currentUserUsername) {
                setIsUserAuthenticated(true);
                fetchOldDiscssionMessage(page);
            } else {
                fetchOldDiscssionMessage(1);
                // openModal();
                return;
            }
        }
        const chatContainer = document.getElementById('tournament-chat-container');
        if (chatContainer) {
            chatContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (chatContainer) {
                chatContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [tournamentPremiumState.tournamentOnlyForPremiumUsers, page]);

    const closeDeleteDialog = () => {
        setDeleteTournamentMessageState(prevState => ({
            ...prevState,
            isDeleteModalOpen: false,
            isDeleteLoading: false,
            deleteError: "",
            currentContentId: 0
        }))
    }

    if (!tournamentValid && !isTournamentLoading) {
        return <TournamentNotFound tournamentValid={tournamentValid} />
    }

    return (
        <LayoutComponentForHelmet pageTitle={`${tournamentState.tournamentName} - SpreeCine`}>
            <div className='body-space'>
                <WinnerPrizeModal
                    t={t}
                    isWinnerModalOpen={winnerState.isWinnerModalOpen}
                    tournamentName={winnerState.tournamentName}
                    tournamentWinner={winnerState.tournamentWinnerName}
                    tournamentWinnerUsername={winnerState.tournamentWinnerUsername}
                    winnerUpShotId={winnerState.winnerUpShotId}
                    winnerMessages={winnerState.winnerMessages}
                    closeWinnerModal={() => setWinnerState(prevState => ({ ...prevState, isWinnerModalOpen: false }))}
                />
                <ConfirmationDialog
                    isOpen={deleteTournamentMessageState.isDeleteModalOpen}
                    close={closeDeleteDialog}
                    confirm={deleteTournamentMessage}
                    deleteState={deleteTournamentMessageState}
                    confirmationPageFor={"Delete Tournament Message"}
                />
                <OnlyForPremiumDialog
                    t={t}
                    isOnlyForPremiumUsersOpen={isOnlyForPremiumUsersOpen}
                    close={() => setOnlyForPremiumUsers(false)}
                    isTournamentForPremiumUsers={tournamentPremiumState.tournamentOnlyForPremiumUsers}
                    isUserOnPremium={tournamentPremiumState.isUserOnPremium}
                />
                <div className="tournament-container">
                    <div className="tournament-panel">
                        <h1 className="tournament-title">
                            {tournamentState.tournamentName}
                            <div className="tournament-time-left">
                                {
                                    winnerState.isWinnerFinalized &&
                                    <>
                                        {
                                            isTournamentExpired ? <span onClick={() => setWinnerState(prevState => ({ ...prevState, isWinnerModalOpen: true }))} className='make-text-cursor'>Reveal Winner -</span> : null
                                        }
                                    </>
                                }
                                {tournamentExpirationTime ? <>{tournamentExpirationTime}</> : <>{t("Failed to fetch")}</>}
                            </div>
                        </h1>
                        <p className="tournament-instructions">
                            {/* Welcome to the <b className="tournament-name-header">Storyline Tournament</b>. Respect the scene */}
                            {t("Welcome to the Storyline Tournament. Respect the scene")}
                            <b className="tournament-rules"> <Link to={"/tournament/rules/"}>{t("Guidelines")}</Link></b>.
                            <b><Link to={"/tournament/help/"}> {t("Know more about it?")}</Link></b>
                        </p>

                        <div className={`tournament-messages`} id='tournament-chat-container' ref={messageContainerRef} style={{ overflowY: 'auto' }}>
                            {
                                messages.map((msg, index) => (
                                    <>
                                        {
                                            msg.id &&
                                            <div className="tournament-message" key={index}>
                                                <div className="tournament-info">
                                                    <img src="https://image.tmdb.org/t/p/w500//ym1dxyOk4jFcSl4Q2zmRrA5BEEN.jpg"
                                                        alt="User Profile Picture" className="user-avatar" />
                                                    <span className="user">{msg.user} {msg.show_premium_badge_on_profile ? <span className='premium-profile-badge'><UserOnPremiumBadgeIcon /></span> : null}</span>
                                                    <span className="timestamp">{msg.time}</span>
                                                </div>
                                                <p>{msg.message} - {msg.id}</p>
                                                <span className='report-message'>
                                                    <svg onClick={() => openReportModal(msg.id)} className='actions-svg' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M192-144v-672h336l24 96h216v384H528l-24-96H264v288h-72Zm300-431Zm92 167h112v-240H496l-24-96H264v240h296l24 96Z" /></svg>
                                                </span>
                                                {
                                                    msg.is_message_liked_by_user ?
                                                        <>
                                                            <span className='message-like'>{likedMessages[msg.id] ? <>{msg.messages_likes_count - 1}</> : <>{msg.messages_likes_count}</>}</span>
                                                            <svg className={`make-text-cursor actions-svg ${likedMessages[msg.id] ? "" : "filled"}`} onClick={() => likeUnLikeMessage(msg.id, "LIKE")} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m479-84-79-72q-107-96-176-164T116.5-441q-38.5-53-53-97.5T49-632q0-108 74-182.5T305-889q47 0 92.5 16.5T479-826q36-30 81.5-46.5T653-889q108 0 183 74.5T911-632q0 48-14 91.5T844.5-445q-38.5 52-108 121.5T556-154l-77 70Z" /></svg>
                                                        </>
                                                        :
                                                        <>
                                                            <span className='message-like'>{likedMessages[msg.id] ? <>{msg.messages_likes_count + 1}</> : <>{msg.messages_likes_count}</>}</span>
                                                            <svg className={`make-text-cursor actions-svg ${likedMessages[msg.id] ? "filled" : ""}`} onClick={() => likeUnLikeMessage(msg.id, "UNLIKE")} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m479-84-79-72q-107-96-176-164T116.5-441q-38.5-53-53-97.5T49-632q0-108 74-182.5T305-889q47 0 92.5 16.5T479-826q36-30 81.5-46.5T653-889q108 0 183 74.5T911-632q0 48-14 91.5T844.5-445q-38.5 52-108 121.5T556-154l-77 70Z" /></svg>
                                                        </>
                                                }
                                                {
                                                    msg.is_request_user_is_message_owner === undefined ?
                                                        <>
                                                            {
                                                                currentUserUsername === msg.user &&
                                                                <svg onClick={() => deleteTournamentMessage(msg.id)} className='msg-delete-btn actions-svg make-text-cursor' xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
                                                            }
                                                        </>
                                                        :
                                                        <svg onClick={() => setDeleteTournamentMessageState(prevState => ({ ...prevState, isDeleteModalOpen: true, currentContentId: msg.id }))} className='msg-delete-btn actions-svg make-text-cursor' xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
                                                }
                                            </div>
                                        }
                                    </>
                                ))
                            }
                        </div>
                        {
                            currentUserUsername &&
                            <>
                                {
                                    socket === null ?
                                        <div className='clr-white tournament-fetch-error-again' onClick={connectWebSocket}>
                                            {t("Something went Wrong while fetching Tournament details.")}
                                            <span className='clr-red make-text-cursor'>{t("Click to fetch again")}</span>
                                        </div>
                                        :
                                        null
                                }
                            </>
                        }
                        {
                            messageCountState.isLimitExceed &&
                            <div className='tournament-message-limit-exceed'>{t("Users can send only 3 message and Premium users can send upto 7.")}</div>
                        }
                        {
                            !isTournamentExpired && currentUserUsername && !messageCountState.isLimitExceed ?
                                <div className="message-input">
                                    <textarea
                                        type="text"
                                        placeholder="Type here....."
                                        className={`non-resizable`}
                                        value={inputMessage}
                                        onChange={(e) => setInputMessage(e.target.value)}
                                    >
                                    </textarea>
                                    <button type='button' onClick={sendMessage}>{t("Send")}</button>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>

                <InfoPopup
                    steps={customSteps}
                    browserStorageInfoValidationKey="mdinfostat"
                />

                <ReportModal
                    isReportModalOpen={reportDataState.isReportModalOpen}
                    setReportModalOpen={() => setReportDataState({ isReportModalOpen: !reportDataState.isReportModalOpen })}
                    reportInfo={reportStateId}
                    reportTypeContent={"tournament_message"}
                />
            </div>
        </LayoutComponentForHelmet>
    )
}

function WinnerPrizeModal({ t, isWinnerModalOpen, tournamentName, tournamentWinner, winnerUpShotId, tournamentWinnerUsername, closeWinnerModal, winnerMessages }) {
    const [isPrizeContainerFlipped, setPrizeContainerFlipped] = useState(false);

    return (
        <>
            {
                isWinnerModalOpen ?
                    <section className="main-prize-modal prize-container">
                        <div className="modal__prize-container show-modal" id="modal-container">
                            <div className="prize-modal__content">
                                <div className="prize-modal__close" onClick={closeWinnerModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m289-199-89-90 190-191-190-191 89-91 191 191 191-191 89 91-190 191 190 191-89 90-191-191-191 191Z" /></svg>
                                </div>
                                {
                                    !isPrizeContainerFlipped ?
                                        <>
                                            <span className='prize-modal__img'><TrophyIcon /></span>
                                            <h1 className="tournament-name">{tournamentWinner} {t("Won the Tournament")}</h1>
                                            <p className="winner-info">
                                                {tournamentName}
                                                <svg onClick={() => setPrizeContainerFlipped(true)} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M385-98H226q-53.7 0-90.85-37.15Q98-172.3 98-226v-508q0-53.7 37.15-90.85Q172.3-862 226-862h159v128H226v508h159v128Zm34 86v-936h128v936H419Zm176-86v-120h69v120h-69Zm0-644v-120h69v120h-69ZM742-98v-120h120q0 50-35.25 85T742-98Zm0-198v-69h120v69H742Zm0-147v-73h120v73H742Zm0-151v-70h120v70H742Zm0-148v-120q49.5 0 84.75 35.25T862-742H742Z" /></svg>
                                            </p>
                                            <div className="prize-buttons">
                                                <Link to={`/upshot/${winnerUpShotId}`}>
                                                    <button className="no-border-btn prize-modal__button bg-color-bluevoilet prize-modal__button-width">
                                                        {t("View UpShot")}
                                                    </button>
                                                </Link>

                                                <Link to={`/user-profile/${tournamentWinnerUsername}`}>
                                                    <button
                                                        className="no-border-btn prize-modal__button bg-color-bluevoilet prize-modal__button-width">
                                                        {t("View User")}
                                                    </button>
                                                </Link>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <svg className='make-text-cursor winner-message-flip-btn' onClick={() => setPrizeContainerFlipped(false)} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M385-98H226q-53.7 0-90.85-37.15Q98-172.3 98-226v-508q0-53.7 37.15-90.85Q172.3-862 226-862h159v128H226v508h159v128Zm34 86v-936h128v936H419Zm176-86v-120h69v120h-69Zm0-644v-120h69v120h-69ZM742-98v-120h120q0 50-35.25 85T742-98Zm0-198v-69h120v69H742Zm0-147v-73h120v73H742Zm0-151v-70h120v70H742Zm0-148v-120q49.5 0 84.75 35.25T862-742H742Z" /></svg>
                                            <div className='messages-list'>
                                                {
                                                    winnerMessages?.map((message, index) =>
                                                        <div className='message-item-text' key={message.id}>
                                                            {message.message}
                                                            <small className='winner-message-likes'>100 Likes</small>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </section>
                    :
                    null
            }
        </>
    )
}

function OnlyForPremiumDialog({ t, isOnlyForPremiumUsersOpen, close, isTournamentForPremiumUsers, isUserOnPremium }) {

    if (!isTournamentForPremiumUsers) {
        return null;
    }

    return (
        <>
            {
                isOnlyForPremiumUsersOpen &&
                <section className="main-prize-modal prize-container">
                    <div className="modal__prize-container show-modal" id="modal-container">
                        <div className="prize-modal__content">
                            <span className='prize-modal__img'><TournamentForPremiumUsers /></span>
                            <h4 className="tournament-name">{t("Only users with Premium Membership can Join")}</h4>
                            <p className="winner-info"></p>
                            <div className="prize-buttons">
                                <Link to={`/premium`}>
                                    <button className="no-border-btn prize-modal__button bg-color-black prize-modal__button-width">
                                        {t("Get Premium")}
                                    </button>
                                </Link>

                                <Link to={`/tournaments`}>
                                    <button
                                        className="no-border-btn prize-modal__button bg-color-black prize-modal__button-width">
                                        {t("Go Back")}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default MovieDiscussionChatRoom;