import { Link } from "react-router-dom";
import "./styles/discussionHelp.css";
import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";

function TournamentHelp() {

    return (
        <LayoutComponentForHelmet pageTitle={`Storyline Tournament Help - SpreeCine`}>
            <div className="body-space">
                <div className="help-container">
                    <div className="help-main">
                        <h2 className="heading">Storyline Tournament FAQs</h2>
                        <ul className="help-list">
                            <li className="help-item">
                                <span className="help-icon">&#128220;</span>
                                <div className="help-details">
                                    <h3 className="help-title">What is Storyline Tournament?</h3>
                                    <p className="help-description">
                                        It's an automated Tournament, in which users can submit their wonderful thoughts, plots, movie endings for movies/tv shows.
                                        It stays active for random timings like 1 hour, 3 hours, 6 hours, 1 day. That means a user can send their plots while the Tournament
                                        is active and after that It will be freezed. And When Tournament freezes we will select 1 user which's users find more intresting
                                        and user will stay Featured until next Tournament.
                                    </p>
                                </div>
                            </li>
                            <li className="help-item">
                                <span className="help-icon">&#128196;</span>
                                <div className="help-details">
                                    <h3 className="help-title">Which UpShots do you pick for Tournament?</h3>
                                    <p className="help-description">We pick random UpShot posted within one week for a Tournament.</p>
                                </div>
                            </li>
                            <li className="help-item">
                                <span className="help-icon">&#128242;</span>
                                <div className="help-details">
                                    <h3 className="help-title">Can I send unlimited plots?</h3>
                                    <p className="help-description">A user can only send upto 3 plots in one Tournament and User with Premium Membership can send upto 7 plots.</p>
                                </div>
                            </li>
                            <li className="help-item">
                                <span className="help-icon">&#128227;</span>
                                <div className="help-details">
                                    <h3 className="help-title">I want to be informed when a Tournament starts.</h3>
                                    <p className="help-description">Absolutely, You can Enable "Inform me before Tournament starts" and will send you notifications when it starts.</p>
                                </div>
                            </li>
                            <li className="help-item">
                                <span className="help-icon">&#128295;</span>
                                <div className="help-details">
                                    <h3 className="help-title">I notice something Bad/Dangerous, Should I report it?</h3>
                                    <p className="help-description">If you notice someone is voilating Tournament <Link to={"/tournament/rules/"}>Rules</Link> or SpreeCine's <Link to={"/community-policy/"}>Regulations</Link> then Report it NOW!</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default TournamentHelp;