import { useState, useEffect } from "react";
import {
    Navigate,
} from 'react-router-dom';
import { useLocation } from "react-router";
import axios from "axios";

function RequireAuth({ children }) {

    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(true);

    useEffect(() => {
        axios.get("/check_if_authenticated/")
            .then((res) => {
                if (res.data.message.is_authenticated) {
                    setAuthenticated(true);
                } else {
                    setAuthenticated(false);
                }
            })
            .catch(() => {
                setAuthenticated(false)
            })
    }, [authenticated])


    return authenticated === true ? (
        children
    ) : (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
}

export default RequireAuth;