import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { Button, Modal } from '@mantine/core';

function OtherReportModal({ isOtherReportModalOpen, setOtherReportModalOpen, setMainReportShow, openAdditionalInfo, setSelectedReasonStepTwo }) {
    const { t } = useTranslation();
    const [selectedOtherReason, setSelectedOtherReason] = useState("");

    const backToMainReportModal = () => {
        setOtherReportModalOpen(false);
        setMainReportShow(true);
    }

    const updateSelectedReportReason = (value) => {
        if (value !== undefined && value !== null) {
            setSelectedOtherReason(value);
            setSelectedReasonStepTwo(value);
        }
    }

    return (
        <Modal opened={isOtherReportModalOpen} onClose={backToMainReportModal} radius="10px" size="xl" title={t("Other concerns")} centered>
            <div className="report-area">
                <form onClick={(e) => updateSelectedReportReason(e.target.getAttribute("data-value"))}>
                    <ul>
                        <li className={`${selectedOtherReason === "TCNCLISUER" && `selected`}`} data-value="TCNCLISUER">{t("Technical issues or errors")}</li>
                        <li className={`${selectedOtherReason === "OTRCNCN" && `selected`}`} data-value="OTRCNCN">{t("Other concerns")}</li>
                    </ul>
                    <Button variant='filled' color='rgb(103, 103, 222)' fullWidth onClick={openAdditionalInfo} disabled={selectedOtherReason === "" ? true : false}>{t("I'm sure")}</Button>
                </form>
            </div>
        </Modal>
    )
}

export default OtherReportModal;