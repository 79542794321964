import { useTranslation } from "react-i18next";

import { useState } from 'react';
import { Button, Modal } from "@mantine/core";

function SafetyReportModal({ isSafetyReportModalOpen, setSafetyReportModalOpen, setMainReportShow, setSelectedReasonStepTwo, openAdditionalInfo, reportInfo }) {
    const { t } = useTranslation();
    const [selectedSafetyReason, setSelectedSafetyReason] = useState("");

    const backToMainReportModal = () => {
        setSafetyReportModalOpen(false);
        setMainReportShow(true);
    }

    const updateSelectedReportReason = (value) => {
        if (value !== undefined && value !== null) {
            setSelectedSafetyReason(value);
            setSelectedReasonStepTwo(value);
        }
    }

    return (

        <Modal opened={isSafetyReportModalOpen} onClose={backToMainReportModal} radius="10px" size="xl" title={t("Safety concerns")} centered>
            <div className="report-area">
                <form onClick={(e) => updateSelectedReportReason(e.target.getAttribute("data-value"))}>
                    <ul>
                        <li className={`${selectedSafetyReason === "PRMTNHRMFLACTVY" && `selected`}`} data-value="PRMTNHRMFLACTVY">{t("Promotion of harmful activities or products")}</li>
                        <li className={`${selectedSafetyReason === "HRSMNTSKNG" && `selected`}`} data-value="HRSMNTSKNG">{t("Harassment or stalking")}</li>
                        <li className={`${selectedSafetyReason === "PRVCYVILTN" && `selected`}`} data-value="PRVCYVILTN">{t("Privacy violations")}</li>
                        <li className={`${selectedSafetyReason === "DCLSREPRSNLINFO" && `selected`}`} data-value="DCLSREPRSNLINFO">{t("Disclosure of personal contact information (e.g, address, phone number, email address)")}</li>
                        <li className={`${selectedSafetyReason === "DCLSRHMEADRS" && `selected`}`} data-value="DCLSRHMEADRS">{t("Disclosure of home or work address")}</li>
                        <li className={`${selectedSafetyReason === "TRKNGMNTRNG" && `selected`}`} data-value="TRKNGMNTRNG">{t("Unauthorized tracking or monitoring")}</li>
                    </ul>
                    <Button variant='filled' color='rgb(103, 103, 222)' fullWidth onClick={openAdditionalInfo} disabled={selectedSafetyReason === "" ? true : false}>{t("I'm sure")}</Button>
                </form>
            </div>
        </Modal>
    )
}

export default SafetyReportModal;