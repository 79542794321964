import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import "./feedbackForEverything.css";

const GlobalModalContext = createContext();

const GlobalModalProvider = ({ children }) => {
    const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);

    const openModal = () => {
        setFeedbackModalOpen(true);
    };

    const closeModal = () => {
        setFeedbackModalOpen(false);
    };

    return (
        <GlobalModalContext.Provider value={{ isFeedbackModalOpen, openModal, closeModal }}>
            {children}
        </GlobalModalContext.Provider>
    );
};

const GlobalModal = () => {
    const { t } = useTranslation();
    const { isFeedbackModalOpen, closeModal } = useContext(GlobalModalContext);
    const [userFieldState, setUserFieldState] = useState({
        emoji: "",
        feedback: "",

        emojiStateError: "",
        fieldStateError: "",
        userCompletedTheForm: false,
        wasSuccess: null
    });

    useEffect(() => {
        setUserFieldState({
            emoji: "",
            feedback: "",
            emojiStateError: "",
            fieldStateError: "",
            userCompletedTheForm: false,
            wasSuccess: null
        })
    }, [isFeedbackModalOpen])

    const updateField = (fieldName, fieldValue) => {
        setUserFieldState(prevState => ({
            ...prevState,
            [fieldName]: fieldValue
        }))
    }

    const submitFFE = () => {
        setUserFieldState(prevState => ({
            ...prevState,
            emojiStateError: !prevState.emoji ? t("Select one of the Emojis") : "",
            fieldStateError: !prevState.feedback ? t("Provide more information about feedback or problem if any.") : ""
        }))
        if (userFieldState.emoji && userFieldState.feedback) {
            const dataToPost = ({
                emoji: userFieldState.emoji,
                feedback: userFieldState.feedback,
                location: window.location.href
            })
            axios.post("/ffe/c/", dataToPost).then(() => {
                setUserFieldState({
                    wasSuccess: true,
                    userCompletedTheForm: true
                })
            }).catch(() => {
                setUserFieldState({
                    wasSuccess: false,
                    userCompletedTheForm: true
                })
            })
        }
    }

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Escape' && isFeedbackModalOpen) {
                closeModal();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [isFeedbackModalOpen, closeModal]);

    if (!isFeedbackModalOpen) {
        return null;
    }

    return (
        <div className="ffe-modal">
            <div className="ffe-modal-content">
                <div className="ffe-close" onClick={closeModal}>&times;</div>
                <div className="ffe-form-container">
                    <h2>{t("Provide Feedback")}</h2>
                    {
                        !userFieldState.userCompletedTheForm ?
                            <form>
                                <div className="emojis-types">
                                    <div className={`emoji happy ${userFieldState.emoji === "h" ? "selected" : ""}`} onClick={() => updateField("emoji", "h")}>&#128522;</div>
                                    <div className={`emoji neutral ${userFieldState.emoji === "n" ? "selected" : ""}`} onClick={() => updateField("emoji", "n")}>&#128528;</div>
                                    <div className={`emoji sad ${userFieldState.emoji === "s" ? "selected" : ""}`} onClick={() => updateField("emoji", "s")}>&#128577;</div>
                                </div>
                                {
                                    userFieldState.emojiStateError &&
                                    <div className="err-message margin-bottom-15px">{userFieldState.emojiStateError}</div>
                                }
                                <div className="form-group">
                                    <label htmlFor="feedback" className="ffe-field-label">{t("Note")}:</label>
                                    <textarea id="feedback" name="feedback" className="ffe-form" required value={userFieldState.feedback} onInput={(e) => updateField("feedback", e.target.value)}></textarea>
                                </div>
                                {
                                    userFieldState.fieldStateError &&
                                    <div className="err-message margin-bottom-15px">{userFieldState.fieldStateError}</div>
                                }
                                <button type="button" className="ffe-submit-button" onClick={submitFFE}>{t("Submit Feedback")}</button>
                            </form>
                            :
                            <>
                                {userFieldState.wasSuccess ?
                                    <div className="success-sent">{t("Feedback successfully shared with the team.")}</div>
                                    :
                                    <div className="n-success-sent">{t("There was a problem sending feedback.")}</div>
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    );
};


export { GlobalModalProvider, GlobalModalContext, GlobalModal };
