import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Divider, Input, Modal, Text } from '@mantine/core';

const isValidUrl = (url) => {
    const regex = new RegExp(/^(http|https):\/\/[^ "]+$/);
    return regex.test(url);
}

function CopyrightAdditionalInfoModal({ isAdditionalInfoModalInfo, setAdditionalInfoModalOpen, setMainReportShow, copyrightFieldState, setCopyrightFieldState, sendCopyrightDataToBackend, selectedCopyrightReason }) {
    const { t } = useTranslation();
    const [linkValidationState, setLinkValidationState] = useState({
        isOriginalWorkLinkValid: false,
        isReportingWorkLinkValid: false,
        isProfileLinkValid: false,
    })
    const [linkInputErrorState, setLinkInputErrorState] = useState({
        isOriginalLinkPresent: false,
        isReportingWorkLinkPresent: false,
        isProfileLinkPresent: false,
    })

    const backToMainReportModal = () => {
        setAdditionalInfoModalOpen(false);
        setMainReportShow(true);
    }

    useEffect(() => {
        verifyState();
    }, [copyrightFieldState.directLinkOfOriginalWork, copyrightFieldState.directLinkOfReportingContent, copyrightFieldState.profileLink])

    function verifyState() {
        setLinkValidationState(prevState => ({
            ...prevState,
            isOriginalWorkLinkValid: isValidUrl(copyrightFieldState.directLinkOfOriginalWork),
            isReportingWorkLinkValid: isValidUrl(copyrightFieldState.directLinkOfReportingContent),
            isProfileLinkValid: isValidUrl(copyrightFieldState.profileLink),
        }))

        const anyNotFalse = (linkValidationState.isOriginalWorkLinkValid && linkValidationState.isReportingWorkLinkValid && linkValidationState.isProfileLinkValid)
        return anyNotFalse
    }

    const validateAndSendToBackend = () => {
        if (copyrightFieldState.directLinkOfOriginalWork === "") {
            setLinkInputErrorState(prevState => ({ ...prevState, isOriginalLinkPresent: true }));
        } else {
            setLinkInputErrorState(prevState => ({ ...prevState, isOriginalLinkPresent: false }));
        }
        if (copyrightFieldState.directLinkOfReportingContent === "") {
            setLinkInputErrorState(prevState => ({ ...prevState, isReportingWorkLinkPresent: true }));
        } else {
            setLinkInputErrorState(prevState => ({ ...prevState, isReportingWorkLinkPresent: false }));
        }
        if (copyrightFieldState.profileLink === "") {
            setLinkInputErrorState(prevState => ({ ...prevState, isProfileLinkPresent: true }));
        } else {
            setLinkInputErrorState(prevState => ({ ...prevState, isProfileLinkPresent: false }));
        }

        const trueIfLinkIsValid = verifyState();
        const trueIfNotAnyBlank = (linkValidationState.isOriginalWorkLinkValid && linkValidationState.isReportingWorkLinkValid && linkValidationState.isProfileLinkValid)
        // ! Check if both are true if true then send to backend.
        if (trueIfLinkIsValid && trueIfNotAnyBlank) {
            sendCopyrightDataToBackend();
        }
    }


    return (
        <Modal
            show={isAdditionalInfoModalInfo}
            opened={isAdditionalInfoModalInfo} onClose={() => setAdditionalInfoModalOpen(false)}
            size="lg"
            title={selectedCopyrightReason === "CPYRGTMTRL" ? <>{t("Unauthorized use of copyrighted materials")}</> : <>{t("Unauthorized trademark use")}</>} centered
        >
            <Text size='13px' className='clr-red'>{t("Fill the below details about the copyright carefully")}</Text>
            <div className="report-area">
                <div className="copyright-input-container copyright-label-gap">
                    <Input.Wrapper label={t("Copyright owner's full name")}>
                        <Input name="copyrightOwnerName" value={copyrightFieldState.copyrightOnwerName} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, copyrightOnwerName: e.target.value }))} />
                    </Input.Wrapper>
                    {/* My company, My copyright, my client(choice) */}
                    {/* Company */}
                    <Input.Wrapper label={t("Company Name")}>
                        <Input name="companyName" value={copyrightFieldState.companyName} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, companyName: e.target.value }))} />
                    </Input.Wrapper>
                    {/* Email */}
                    <Input.Wrapper label={t("Email")}>
                        <Input name='email' value={copyrightFieldState.email} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, email: e.target.value }))} />
                    </Input.Wrapper>
                    {/* Full Address */}
                    <Input.Wrapper label={t("Full Address")}>
                        <Input name='fullAddress' value={copyrightFieldState.fullAddress} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, fullAddress: e.target.value }))} />
                    </Input.Wrapper>
                    {/* Country */}
                    <Input.Wrapper label={t("Country")}>
                        <Input name='country' value={copyrightFieldState.country} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, country: e.target.value }))} />
                    </Input.Wrapper>
                    {/* phn(optional) */}
                    <Input.Wrapper label={t("Phone Number (optional)")}>
                        <Input name='ph' value={copyrightFieldState.phNumber} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, phNumber: e.target.value }))} />
                    </Input.Wrapper>
                    {/* Description of the original work */}
                    <Input.Wrapper label={t("Description")}>
                        <Input name='description' value={copyrightFieldState.description} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, description: e.target.value }))} />
                    </Input.Wrapper>
                    <Divider my="xs" label={t("Carefully fill the below fields")} labelPosition="center" />
                    {/* Direct link to the original work */}
                    <Input.Wrapper label={t("Direct link to the original work")} error={linkInputErrorState.isOriginalLinkPresent && "Required"}>
                        <Input name='originalWorklink' value={copyrightFieldState.directLinkOfOriginalWork} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, directLinkOfOriginalWork: e.target.value }))} />
                    </Input.Wrapper>
                    {
                        copyrightFieldState.directLinkOfOriginalWork !== "" &&
                        <>{!linkValidationState.isOriginalWorkLinkValid && <b className='error-message fs-10px'>{t("Link you provided is invalid")}</b>}</>
                    }
                    {/* Reporting content(multiple choice) image,video,post, ad */}
                    {/* Reporting reason(multiple choice) this content copies my work,i appear in this content,this content uses my name */}
                    {/* Direct url of the reporting content */}
                    <Input.Wrapper label={t("Direct url of the reporting content")} error={linkInputErrorState.isReportingWorkLinkPresent && "Required"}>
                        <Input name='reportingContentLink' value={copyrightFieldState.directLinkOfReportingContent} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, directLinkOfReportingContent: e.target.value }))} />
                    </Input.Wrapper>
                    <br />
                    {
                        copyrightFieldState.directLinkOfReportingContent !== "" &&
                        <>{!linkValidationState.isReportingWorkLinkValid && <b className='error-message fs-10px'>{t("Link you provided is invalid")}</b>}</>
                    }
                    {/* User profile url OR search user */}
                    <Input.Wrapper label={t("Profile Link")} error={linkInputErrorState.isProfileLinkPresent && "Required"}>
                        <Input name='profileLink' value={copyrightFieldState.profileLink} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, profileLink: e.target.value }))} />
                    </Input.Wrapper>
                    <br />
                    {
                        copyrightFieldState.profileLink !== "" &&
                        <>{!linkValidationState.isProfileLinkValid && <b className='error-message fs-10px'>{t("Link you provided is invalid")}</b>}</>
                    }
                    {/* Describe infringement */}
                    <Input.Wrapper label={t("Describe Infringement")}>
                        <Input name='details' value={copyrightFieldState.describeInfringment} onInput={(e) => setCopyrightFieldState(prevState => ({ ...prevState, describeInfringment: e.target.value }))} />
                    </Input.Wrapper>
                    {/* Have additional data */}
                    {/* <label htmlFor="additionalDetails">Have additional details</label> */}
                    {/* <input type='text' name='additionalDetails' id='additionalDetails' /> */}
                </div>

                {/* I understand that under 17 U.S.C. § 512, I may be liable for damages if I knowingly misrepresent that material or activity is infringing. */}
                {/* I have a good faith belief that the use of the copyrighted material described in this declaration is not authorized by the copyright owner, its agent, or the law. */}
                {/* The information provided in this declaration is accurate to the best of my knowledge and under penalty of perjury, I am authorized to act on behalf of the copyright owner */}
                {/* I understand that by submitting this declaration, I am requesting that the infringing material be removed from the site and I consent to the jurisdiction of the courts for the district in which I reside. */}
                {/* Signature */}

                <Button variant='filled' color='rgb(103, 103, 222)' fullWidth onClick={validateAndSendToBackend}>{t("Send Report")}</Button>
            </div>
        </Modal>
    )
}

export default CopyrightAdditionalInfoModal;