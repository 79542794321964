import React from 'react';
import DateConverterHumanize from './formatDateHumanize';

const formatDate = (inputDate) => {
    const today = new Date();
    const inputDateObj = new Date(inputDate);

    // Check if the input date is today
    if (inputDateObj.toDateString() === today.toDateString()) {
        return 'Released Today';
    }

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // Check if the input date is yesterday
    if (inputDateObj.toDateString() === yesterday.toDateString()) {
        // return `Yesterday at ${inputDate}`;
        return `Released yesterday`;
    }

    const diffInMilliseconds = inputDateObj - today;
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (diffInDays < 0) {
        return <>Released at <DateConverterHumanize inputDate={inputDate} /></>;
    } else if (diffInDays <= 30) {
        return `Releasing in ${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
    } else {
        return <>Releasing at <DateConverterHumanize inputDate={inputDate} /></>;
    }
};


const ReleaseDateNaturalTimeDisplay = ({ date }) => {
    const formattedDate = formatDate(date);

    return <div>{formattedDate}</div>;
};

export default ReleaseDateNaturalTimeDisplay;
