import { useState } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { Button, Modal } from '@mantine/core';

import './styles/report.css';
import InappropriateReportModal from './InappropriateReport';
import SafetyReportModal from './SafetyReport';
import ImpersonateReportModal from './ImpersonationReport';
import CopyrightReportModal from './CopyrightReport';
import OtherReportModal from './OtherReport';
import AdditionalInfoReportModal from './AdditionalInfoModal';
import showToastError from '../helpers/showToastError';

function ReportModal({ isReportModalOpen, setReportModalOpen, reportInfo, reportTypeContent }) {
    const { t } = useTranslation();
    // ! Backend fields to send - START
    const [selectedReason, setSelectedReason] = useState("");
    const [selectedReasonStepTwo, setSelectedReasonStepTwo] = useState("");
    const [descriptionValue, setdescriptionValue] = useState("");
    const [effectedType, setEffectedType] = useState("WHLCMUNTY");
    const [howEffectingTargeted, setHowEffectingTargeted] = useState("");
    // ! Backend fields to send - END

    const [isInappropriateReportModalOpen, setInappropriateReportModalOpen] = useState(false);
    const [isSafetyReportModalOpen, setSafetyReportModalOpen] = useState(false);
    const [isImpersonationReportModalOpen, setImpersonationReportModalOpen] = useState(false);
    const [isCopyrightReportModalOpen, setCopyrightReportModalOpen] = useState(false);
    const [isOtherReportModalOpen, setOtherReportModalOpen] = useState(false);

    const [isAdditionalInfoModalInfo, setAdditionalInfoModalOpen] = useState(false);

    const [reportSaveState, setReportSaveState] = useState({
        isReportSaveLoading: false,
        reportSaveError: "",
    });

    const getDeviceInfo = () => {
        const { userAgent, language, platform, hardwareConcurrency, deviceMemory, screen } = window.navigator;
        const screenData = screen ? { width: screen.width, height: screen.height, availWidth: screen.availWidth, availHeight: screen.availHeight } : {};

        return {
            userAgent,
            language,
            platform,
            screen: screenData,
        }
    }

    const saveToServer = () => {
        if (selectedReason !== "" && selectedReasonStepTwo !== "" && howEffectingTargeted !== "") {
            setReportSaveState({
                isReportSaveLoading: true,
                reportSaveError: "",
            });
            axios.get(`/get-current-user/`).then((res) => {
                const dataToPost = ({
                    reported_by: res.data.USER_ID,
                    // [reportTypeContent === "Profile" ? "profile" : "upshot"]: reportInfo,
                    [reportTypeContent === "profile" ? "profile" : reportTypeContent === "remark" ? "remark" : reportTypeContent === "upshot" ? reportTypeContent : reportTypeContent === "comment" ? "comment" : reportTypeContent === "tournament_message" ? "tournament_message" : reportTypeContent]: reportInfo,
                    reported_main_reason: selectedReason,
                    reported_second_step: selectedReasonStepTwo,
                    reported_third_step_detail: descriptionValue,
                    impact_type: effectedType,
                    how_effecting_or_why_targeted: howEffectingTargeted,
                    additional_device_info: getDeviceInfo(),
                })
                axios.post(`/report/create/`, dataToPost).then(() => {
                    setReportSaveState({
                        isReportSaveLoading: false,
                        reportSaveError: ""
                    });
                    setSelectedReason("");
                    setSelectedReasonStepTwo("");
                    setdescriptionValue("");
                    setEffectedType("WHLCMUNTY");
                    setHowEffectingTargeted("");
                    setAdditionalInfoModalOpen(false);
                    showToastError("Report was success fully saved!", "success")
                }).catch(() => {
                    setReportSaveState({
                        isReportSaveLoading: false,
                        reportSaveError: "Something went Wrong!"
                    });
                    showToastError("Something went wrong!", "error")
                })
            }).catch(() => {
                setReportSaveState({
                    isReportSaveLoading: false,
                    reportSaveError: "Something went Wrong!"
                })
            })
        } else {
            return;
        }
    }

    const reportModals = {
        "INAPCNT": setInappropriateReportModalOpen,
        "SFTCNCN": setSafetyReportModalOpen,
        "IMPRSNPRFL": setImpersonationReportModalOpen,
        "CPYINFGMNT": setCopyrightReportModalOpen,
        "OTRCNCN": setOtherReportModalOpen,
    }
    const updateSelectedReportReason = (value) => {
        setSelectedReason(value);
    }
    const proccedReportAction = () => {
        setReportModalOpen(false);
        if (reportModals[selectedReason]) {
            reportModals[selectedReason](true);
        }
    }

    const openAdditionalInfo = () => {
        setAdditionalInfoModalOpen(true);

        setInappropriateReportModalOpen(false);
        setSafetyReportModalOpen(false);
        setImpersonationReportModalOpen(false);
        setCopyrightReportModalOpen(false);
        setOtherReportModalOpen(false);
    }
    const closeAdditionalInfo = () => {
        setAdditionalInfoModalOpen(false);
    }

    const closeEverything = () => {
        setAdditionalInfoModalOpen(false);

        setInappropriateReportModalOpen(false);
        setSafetyReportModalOpen(false);
        setImpersonationReportModalOpen(false);
        setCopyrightReportModalOpen(false);
        setOtherReportModalOpen(false);

        setSelectedReason("");
        setSelectedReasonStepTwo("");
        setdescriptionValue("");
        setEffectedType("WHLCMUNTY");
        setHowEffectingTargeted("");
    }

    return (
        <>
            <Modal opened={isReportModalOpen} onClose={() => setReportModalOpen(false)} radius="10px" size="xl" title={t("Select a reason to report")} centered>
                <div className="report-area">
                    <form onChange={(e) => updateSelectedReportReason(e.target.value)}>
                        <ul>
                            <label className="custom-radio" id="INAPCNT">
                                <li className={`${selectedReason === "INAPCNT" && "selected"}`}>
                                    {t("Inappropriate content & behavior")}
                                    <div className="report-reason-description">{t("Choose this if they you or experience any inappropriate behavior, such as harassment, discrimination, hate speech, or other offensive content.")}</div>
                                    <input type="radio" name="option" id="INAPCNT" value="INAPCNT" defaultChecked={selectedReason === "INAPCNT" ? true : false} />
                                    <span></span>
                                </li>
                            </label>
                            <label className="custom-radio" id="SFTCNCN">
                                <li className={`${selectedReason === "SFTCNCN" && "selected"}`}>
                                    {t("Safety concerns")}
                                    <div className="report-reason-description">{t("Choose this if you have safety concerns, such as if you believe the profile belongs to someone who is a danger to others, or if you suspect the profile is involved in criminal activities.")}</div>
                                    <input type="radio" name="option" id="SFTCNCN" value="SFTCNCN" defaultChecked={selectedReason === "SFTCNCN" ? true : false} />
                                    <span></span>
                                </li>
                            </label>
                            <label className="custom-radio" id="IMPRSNPRFL">
                                <li className={`${selectedReason === "IMPRSNPRFL" && "selected"}`}>
                                    {t("Impersonation or Fake profiles")}
                                    <div className="report-reason-description">
                                        {t("Choose this if you believe the user is impersonating someone else or using a fake profile or misrepresenting.")}
                                    </div>
                                    <input type="radio" name="option" id="IMPRSNPRFL" value="IMPRSNPRFL" defaultChecked={selectedReason === "IMPRSNPRFL" ? true : false} />
                                    <span></span>
                                </li>
                            </label>
                            <label className="custom-radio" id="CPYINFGMNT">
                                <li className={`${selectedReason === "CPYINFGMNT" && "selected"}`}>
                                    {t("Copyright infringement")}
                                    <div className="report-reason-description">{t("Choose this if you believe the content is infringing on your copyright, such as by using your photos or text your permission.")}</div>
                                    <input type="radio" name="option" id="CPYINFGMNT" value="CPYINFGMNT" defaultChecked={selectedReason === "CPYINFGMNT" ? true : false} />
                                    <span></span>
                                </li>
                            </label>
                            <label className="custom-radio" id="OTRCNCN">
                                <li className={`${selectedReason === "OTRCNCN" && "selected"}`}>
                                    {t("Other concerns")}
                                    <div className="report-reason-description">{t("Choose this if they you or experience any inappropriate behavior, such as harassment, discrimination, hate speech, or other offensive content.")}</div>
                                    <input type="radio" name="option" id="OTRCNCN" value="OTRCNCN" defaultChecked={selectedReason === "OTRCNCN" ? true : false} />
                                    <span></span>
                                </li>
                            </label>
                        </ul>
                    </form>
                    {reportSaveState.reportSaveError !== "" && <b className='error-message'>{reportSaveState.reportSaveError}</b>}
                    <Button variant='filled' color='rgb(103, 103, 222)' fullWidth onClick={proccedReportAction} disabled={selectedReason === "" ? true : false}>{t("Procced")}</Button>
                </div>
            </Modal>

            <InappropriateReportModal
                isInappropriateReportModalOpen={isInappropriateReportModalOpen}
                setInappropriateReportModalOpen={setInappropriateReportModalOpen}
                setMainReportShow={setReportModalOpen}
                openAdditionalInfo={openAdditionalInfo}
                closeAdditionalInfo={closeAdditionalInfo}

                selectedReason={selectedReason}
                setSelectedReasonStepTwo={setSelectedReasonStepTwo}
            />

            <SafetyReportModal
                isSafetyReportModalOpen={isSafetyReportModalOpen}
                setSafetyReportModalOpen={setSafetyReportModalOpen}
                setMainReportShow={setReportModalOpen}
                openAdditionalInfo={openAdditionalInfo}
                reportInfo={reportInfo}
                closeAdditionalInfo={closeAdditionalInfo}

                setSelectedReasonStepTwo={setSelectedReasonStepTwo}
            />

            <ImpersonateReportModal
                isImpersonationReportModalOpen={isImpersonationReportModalOpen}
                setImpersonationReportModalOpen={setImpersonationReportModalOpen}
                setMainReportShow={setReportModalOpen}
                openAdditionalInfo={openAdditionalInfo}
                closeAdditionalInfo={closeAdditionalInfo}
                reportInfo={reportInfo}

                setSelectedReasonStepTwo={setSelectedReasonStepTwo}
            />

            <CopyrightReportModal
                isCopyrightReportModalOpen={isCopyrightReportModalOpen}
                setCopyrightReportModalOpen={setCopyrightReportModalOpen}
                setMainReportShow={setReportModalOpen}
                openAdditionalInfo={openAdditionalInfo}
                closeAdditionalInfo={closeAdditionalInfo}
                reportInfo={reportInfo}

                selectedReason={selectedReason}
                selectedReasonStepTwo={selectedReasonStepTwo}
                setSelectedReasonStepTwo={setSelectedReasonStepTwo}
            />

            <OtherReportModal
                isOtherReportModalOpen={isOtherReportModalOpen}
                setOtherReportModalOpen={setOtherReportModalOpen}
                setMainReportShow={setReportModalOpen}
                openAdditionalInfo={openAdditionalInfo}
                closeAdditionalInfo={closeAdditionalInfo}
                reportInfo={reportInfo}

                setSelectedReasonStepTwo={setSelectedReasonStepTwo}
            />

            <AdditionalInfoReportModal
                isAdditionalInfoModalInfo={isAdditionalInfoModalInfo}
                setAdditionalInfoModalOpen={setAdditionalInfoModalOpen}
                setReportModalOpen={setReportModalOpen}
                setMainReportShow={setReportModalOpen}
                closeEverything={closeEverything}

                descriptionValue={descriptionValue}
                setdescriptionValue={setdescriptionValue}

                effectedType={effectedType}
                setEffectedType={setEffectedType}

                howEffectingTargeted={howEffectingTargeted}
                setHowEffectingTargeted={setHowEffectingTargeted}

                reportSaveState={reportSaveState}
                saveToServer={saveToServer}
            />
        </>
    )
}

export default ReportModal;