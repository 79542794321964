
function UpShotActionsDropdown({ dropdownStates, index, res, t, openReportModal, currentUserUsername, removeContentFromView, addMeInUserAudience, handleBanModelPopup, handleModMessagePopup, addedAsFavoriteUpShot, saveUpShotAsFavourite }) {

    return (
        <div className={`upshot-content-area  ${!dropdownStates[index] ? `display-none` : ``}`}>
            <span onClick={() => openReportModal(res.id, index)}>{t("Report UpShot")}</span>
            {/* <span onClick={() => openQuickShareModal(res, index)}>{t("Quick Share")}</span> */}
            {
                currentUserUsername && !res.is_request_user_upshot_owner &&
                <>
                    <span onClick={() => addMeInUserAudience(res.user)}>{t("Add me in their Audience")}</span>
                    <span onClick={() => removeContentFromView(res.id, "content", res, "unint")}>{t("Not interested")}</span>
                    <span onClick={() => removeContentFromView(res.id, "user", res, "unint")}><small>{t("Don't like post from this user")}</small></span>
                </>
            }
            {
                sessionStorage.getItem("aamt") !== null && sessionStorage.getItem("aamt").length === 13 ?
                    <>
                        <hr className="tools-separator" />
                        <span className="tools-label">Mod tools</span>
                        <hr className="tools-separator" />
                        <span onClick={() => handleBanModelPopup(res.user)}>Ban User</span>
                        <span onClick={() => handleModMessagePopup(res.id, "upshot", res.user)}>Message User</span>
                    </>
                    :
                    null
            }
        </div>
    )
}

export default UpShotActionsDropdown;