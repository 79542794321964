import axios from 'axios';
import { useEffect, useState } from 'react';
// import Chart from 'react-apexcharts'
import { useParams } from 'react-router';
import Form from 'react-bootstrap/Form';

import { Pie, Bar, Line } from "react-chartjs-2";
import { Button } from 'react-bootstrap';
import showToastError from "../helpers/showToastError";


function AdminAnalytics() {
    const params = useParams();
    const [analyticsFilterState, setAnalyticsFilterState] = useState({
        isChartsLoading: false,
        chartError: "",

        userDataFilterSince: "1M",
        customUserDate: "",
        userChartDisplayForm: "bar",
        userGraphData: {
            labels: [],
            datasets: [
                {
                    label: "Users Gained ",
                    data: [],
                    backgroundColor: [
                        "rgba(75,192,192,1)",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ],
                    borderColor: "black",
                    borderWidth: 2
                }
            ]
        },

        upshotDateFilterSince: "1M",
        customUpShotDate: "",
        upShotChartDisplayForm: "bar",
        upShotGraphData: {
            labels: [],
            datasets: [
                {
                    label: "UpShots created ",
                    data: [],
                    backgroundColor: [
                        "rgba(75,192,192,1)",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ],
                    borderColor: "black",
                    borderWidth: 2
                }
            ]
        },

        remarkDateFilterSince: "1M",
        customRemarkDate: "",
        remarkChartDisplayForm: "bar",
        remarkGraphData: {
            labels: [],
            datasets: [
                {
                    label: "Remarks created ",
                    data: [],
                    backgroundColor: [
                        "rgba(75,192,192,1)",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ],
                    borderColor: "black",
                    borderWidth: 2
                }
            ]
        },

        commentFilterSince: "1M",
        customCommentDate: "",
        commentChartDisplayForm: "bar",
        commentGraphData: {
            labels: [],
            datasets: [
                {
                    label: "Commments created ",
                    data: [],
                    backgroundColor: [
                        "rgba(75,192,192,1)",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ],
                    borderColor: "black",
                    borderWidth: 2
                }
            ]
        },
    })
    useEffect(() => {
        fetchContentAnalysis();
    }, []);

    const fetchContentAnalysis = () => {
        setAnalyticsFilterState(prevState => ({
            ...prevState,
            isChartsLoading: true,
            chartError: "",
        }))
        const paramData = ({
            userDateChoice: analyticsFilterState.userDataFilterSince,
            upshotDateChoice: analyticsFilterState.upshotDateFilterSince,
            remarkDateChoice: analyticsFilterState.remarkDateFilterSince,
            commentDateChoice: analyticsFilterState.commentFilterSince,

            customUserDate: analyticsFilterState.customUserDate,
            customUpshotDate: analyticsFilterState.customUpShotDate,
            customRemarkDate: analyticsFilterState.customRemarkDate,
            customCommentDate: analyticsFilterState.customCommentDate,
        })
        axios.get(`/dashboard/admin/analytics/?key=${params.id}`, { params: paramData }).then((res) => {

            setAnalyticsFilterState(prevState => ({
                ...prevState,
                isChartsLoading: false,
                chartError: "",
                userGraphData: {
                    labels: res.data[0]?.users?.map(data => data.time),
                    datasets: [
                        {
                            ...analyticsFilterState.userGraphData.datasets[0],
                            data: res.data[0]?.users?.map(data => data.count)
                        }
                    ]
                },
                upShotGraphData: {
                    labels: res.data[1]?.upshots?.map(data => data.time),
                    datasets: [
                        {
                            ...analyticsFilterState.upShotGraphData.datasets[0],
                            data: res.data[1]?.upshots?.map(data => data.count)
                        }
                    ]
                },
                remarkGraphData: {
                    labels: res.data[2]?.remarks?.map(data => data.time),
                    datasets: [
                        {
                            ...analyticsFilterState.remarkGraphData.datasets[0],
                            data: res.data[2]?.remarks?.map(data => data.count)
                        }
                    ]
                },
                commentGraphData: {
                    labels: res.data[3]?.comments?.map(data => data.time),
                    datasets: [
                        {
                            ...analyticsFilterState.commentGraphData.datasets[0],
                            data: res.data[3]?.comments?.map(data => data.count)
                        }
                    ]
                },
            }))
        }).catch((err) => {
            setAnalyticsFilterState(prevState => ({
                ...prevState,
                isChartsLoading: false,
                chartError: err.message,
            }));
            showToastError(`Something went wrong while fetching charts, ${err.message}`, "error");
        })
    }

    return (
        <div className='margin-right-100px'>
            <br /><br />
            <div>

                {
                    analyticsFilterState.isChartsLoading ? <>Loading...</> :
                        <>
                            {
                                analyticsFilterState.chartError !== "" ? <b className='clr-red'>{analyticsFilterState.chartError}</b>
                                    :
                                    <>
                                        <div>
                                            <b>User data graph</b>
                                            <div>
                                                <Form.Group className="mb-3">
                                                    <Form.Select className='border-none' onChange={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, userDateChoice: e.target.value === "custom" && "custom", userDataFilterSince: e.target.value }))}>
                                                        <option>Show user data since</option>
                                                        <option value="1M">One month</option>
                                                        <option value="custom">Custom</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                {
                                                    analyticsFilterState.userDateChoice === "custom" &&
                                                    <div className="mb-3">
                                                        <label className="form-label" placeholder='2023-05-27'>Custom user date since</label>
                                                        <input type="text" className="form-control" value={analyticsFilterState.customUserDate} onInput={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, customUserDate: e.target.value }))} />
                                                    </div>
                                                }
                                                <Form.Group className="mb-3">
                                                    <Form.Select className='border-none' id='userChartDisplayForm' defaultValue={analyticsFilterState.userChartDisplayForm} onChange={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, userChartDisplayForm: e.target.value }))}>
                                                        <option>Type of user graph</option>
                                                        <option value="bar">Bar</option>
                                                        <option value="pie">Pie</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Button variant='secondary' onClick={fetchContentAnalysis}>Fetch</Button>
                                                {
                                                    analyticsFilterState.userChartDisplayForm === "bar" ?
                                                        <div className='chart-canvas'>
                                                            <Bar
                                                                data={analyticsFilterState.userGraphData}
                                                                options={{ plugins: { title: { display: true, text: "Users Gained" } } }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className='chart-canvas'>
                                                            <Pie
                                                                data={analyticsFilterState.userGraphData}
                                                                options={{ plugins: { title: { display: true, text: "Users Gained" } } }}
                                                            />
                                                        </div>
                                                }
                                            </div>

                                            <div className='chart-canvas'>
                                                <b>UpShot data graph</b>
                                                <Form.Group className="mb-3">
                                                    <Form.Select className='border-none' onChange={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, upshotDateChoice: e.target.value === "custom" && "custom", upshotDateFilterSince: e.target.value }))}>
                                                        <option>Show UpShot data since</option>
                                                        <option value="1M">One month</option>
                                                        <option value="custom">Custom</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                {
                                                    analyticsFilterState.upshotDateChoice === "custom" &&
                                                    <div className="mb-3">
                                                        <label className="form-label">Custom UpShot date since</label>
                                                        <input type="text" className="form-control" value={analyticsFilterState.customUpShotDate} onInput={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, customUpShotDate: e.target.value }))} />
                                                    </div>
                                                }
                                                <Form.Group className="mb-3">
                                                    <Form.Select className='border-none' id='upshotChartDisplayForm' defaultValue={analyticsFilterState.upShotChartDisplayForm} onChange={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, upShotChartDisplayForm: e.target.value }))}>
                                                        <option>Type of UpShot graph</option>
                                                        <option value="bar">Bar</option>
                                                        <option value="pie">Pie</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Button variant='secondary' onClick={fetchContentAnalysis}>Fetch</Button>
                                                {
                                                    analyticsFilterState.upShotChartDisplayForm === "bar" ?
                                                        <div className='chart-canvas'>
                                                            <Bar
                                                                data={analyticsFilterState.upShotGraphData}
                                                                options={{ plugins: { title: { display: true, text: "UpShots created" } } }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className='chart-canvas'>
                                                            <Pie
                                                                data={analyticsFilterState.upShotGraphData}
                                                                options={{ plugins: { title: { display: true, text: "UpShots created" } } }}
                                                            />
                                                        </div>
                                                }
                                            </div>

                                            <div className='chart-canvas'>
                                                <Form.Group className="mb-3">
                                                    <Form.Select className='border-none' onChange={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, remarkDateChoice: e.target.value === "custom" && "custom", remarkDateFilterSince: e.target.value }))}>
                                                        <option>Show Remark data since</option>
                                                        <option value="1M">One month</option>
                                                        <option value="custom">Custom</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                {
                                                    analyticsFilterState.remarkDateChoice === "custom" &&
                                                    <div className="mb-3">
                                                        <label className="form-label">Custom Remark date since</label>
                                                        <input type="text" className="form-control" value={analyticsFilterState.customRemarkDate} onInput={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, customRemarkDate: e.target.value }))} />
                                                    </div>
                                                }
                                                <Form.Group className="mb-3">
                                                    <Form.Select className='border-none' id='upshotChartDisplayForm' defaultValue={analyticsFilterState.remarkChartDisplayForm} onChange={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, remarkChartDisplayForm: e.target.value }))}>
                                                        <option>Type of Remark graph</option>
                                                        <option value="bar">Bar</option>
                                                        <option value="pie">Pie</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Button variant='secondary' onClick={fetchContentAnalysis}>Fetch</Button>
                                                {
                                                    analyticsFilterState.remarkChartDisplayForm === "bar" ?
                                                        <div className='chart-canvas'>
                                                            <Bar
                                                                data={analyticsFilterState.remarkGraphData}
                                                                options={{ plugins: { title: { display: true, text: "Remarks created" } } }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className='chart-canvas'>
                                                            <Pie
                                                                data={analyticsFilterState.remarkGraphData}
                                                                options={{ plugins: { title: { display: true, text: "Remarks created" } } }}
                                                            />
                                                        </div>
                                                }
                                            </div>

                                            <div className='chart-canvas'>
                                                <Form.Group className="mb-3">
                                                    <Form.Select className='border-none' onChange={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, commentDateChoice: e.target.value === "custom" && "custom", commentFilterSince: e.target.value }))}>
                                                        <option>Show Comments data since</option>
                                                        <option value="1M">One month</option>
                                                        <option value="custom">Custom</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                {
                                                    analyticsFilterState.commentDateChoice === "custom" &&
                                                    <div className="mb-3">
                                                        <label className="form-label">Custom Comments date since</label>
                                                        <input type="text" className="form-control" value={analyticsFilterState.customCommentDate} onInput={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, customCommentDate: e.target.value }))} />
                                                    </div>
                                                }
                                                <Form.Group className="mb-3">
                                                    <Form.Select className='border-none' id='upshotChartDisplayForm' defaultValue={analyticsFilterState.commentChartDisplayForm} onChange={(e) => setAnalyticsFilterState(prevState => ({ ...prevState, commentChartDisplayForm: e.target.value }))}>
                                                        <option>Type of Comment graph</option>
                                                        <option value="bar">Bar</option>
                                                        <option value="pie">Pie</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Button variant='secondary' onClick={fetchContentAnalysis}>Fetch</Button>
                                                {
                                                    analyticsFilterState.commentChartDisplayForm === "bar" ?
                                                        <div className='chart-canvas'>
                                                            <Bar
                                                                data={analyticsFilterState.commentGraphData}
                                                                options={{ plugins: { title: { display: true, text: "Comments posted" } } }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className='chart-canvas'>
                                                            <Pie
                                                                data={analyticsFilterState.commentGraphData}
                                                                options={{ plugins: { title: { display: true, text: "Comments posted" } } }}
                                                            />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default AdminAnalytics;