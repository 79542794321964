
function UpShotCreationDropdown({ isDropdownOpen, setDraftModalOpen, saveCurrentPostAsDraft, isBackgroundThemeOn, onOffBackgroundTheme, discardUpShot, currentUpShotDraftedId }) {

    if (!isDropdownOpen) {
        return null;
    }

    return (
        <div className='upshot-creation-dropdown'>
            <ul className='upshot-creation-dropdown-ul'>
                <li onClick={() => setDraftModalOpen(true)}>Show Drafts</li>
                <li onClick={() => saveCurrentPostAsDraft(true)}>Save as Draft</li>
                <li
                    data-value={isBackgroundThemeOn === "enable" ? "disable" : "enable"}
                    onClick={(e) => onOffBackgroundTheme(e.target.getAttribute("data-value"))}
                >
                    {isBackgroundThemeOn === "enable" ? "Disable" : "Enable"} Gradient Background
                </li>
                <li className='hover-red' onClick={discardUpShot}>Discard this UpShot</li>
            </ul>
        </div>
    )
}


export default UpShotCreationDropdown;