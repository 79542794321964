import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

import textTrimmer from "../../../components/helpers/stringCutter";

const BanContext = createContext();

export const BanUserProvider = ({ children }) => {
    const [isBanModalOpen, setBanModalOpen] = useState(false);
    const [contentUserId, setContentUserId] = useState(0);

    const [userData, setUserData] = useState({
        messagesSentCount: 0,
        banHistoryCont: 0,

        userUsername: "",

        fetchedUserMessages: [],

        isDataLoading: false,
        fetchError: "",
    });

    useEffect(() => {
        if (isBanModalOpen) {
            fetchUserData();
        }
    }, [isBanModalOpen])

    const fetchUserData = () => {
        setUserData({
            isDataLoading: true,
            fetchError: "",
        })
        axios.get(`/dashboard/admin/message/user/${contentUserId}/${sessionStorage.getItem("aamt")}/`).then((res) => {
            setUserData({
                isDataLoading: false,
                fetchError: "",

                userUsername: res.data.user_username,
                messagesSentCount: res.data.messages_sent_count,
                banHistoryCont: res.data.banned_times_count,
                fetchedUserMessages: res.data.messages,
            })
        }).catch(() => {
            setUserData({
                isDataLoading: false,
                fetchError: "Something went Wrong",
            })
        })
    }

    const openBanModel = (userId) => {
        setBanModalOpen(!isBanModalOpen);
        setContentUserId(userId);
    }

    const closeBanModel = () => {
        setBanModalOpen(false);
        setContentUserId(0);
    }

    const BanModal = ({ children }) => {
        const [messageChoiceForAutomaticGeneration, setMessageChoiceForAutomaticGeneration] = useState("");
        const [banDuration, setBanDuration] = useState("");
        const [additionalComment, setAdditionalComment] = useState("")

        const banUser = () => {
            const dataToPost = ({
                user: contentUserId,
                messageChoice: messageChoiceForAutomaticGeneration,
                // ban_reason: reasonBanning,
                ban_duration: banDuration,
                additional_comment: additionalComment,
            });

            axios.post(`/dashboard/action/ban/user/${contentUserId}/${sessionStorage.getItem("aamt")}/`, dataToPost).then((res) => {
            }).catch(() => {
            })
        }

        return (
            isBanModalOpen && (
                <div className="moderation-message-modal">
                    <div className="moderation-message-modal-content">
                        <div className="message-modal-header">
                            Banning {userData.userUsername}
                            <span className="flt-rgt" onClick={closeBanModel}>
                                <svg className="svg-fill-white make-text-cursor svg-hover-red" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" /></svg>
                            </span>
                        </div>
                        <div className="moderation-message-modal-body">
                            <hr />
                            <span className="message-history">{userData.messagesSentCount} messages have been sent to user so far.</span>
                            <div className="moderation-message-table-container">
                                <div className="scrollable-wrapper custom-scroll">

                                    <table className="styled-table">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Sent at</th>
                                                <th>Sent by</th>
                                                <th>Sent for</th>
                                                <th>Message</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                userData.fetchedUserMessages?.map((message, index) =>
                                                    <>
                                                        <tr>
                                                            <td>{message.id}</td>
                                                            <td>{message.natural_time}</td>
                                                            <td>{message.sent_by_username}</td>
                                                            <td>{message.reason_of_messaging}</td>
                                                            <td>{textTrimmer(message.message, 10)}</td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="mod-message-input-container">

                            <label className="select" htmlFor="slct">
                                <select id="slct" required="required" onChange={(e) => setMessageChoiceForAutomaticGeneration(e.target.value)}>
                                    <option value="" disabled="disabled" selected="selected">Select option</option>
                                    <option value="PEOIC">Posting Explicit Or Inappropriate Content</option>
                                    <option value="DOSP">Doxing Or Sharing Personal Information</option>
                                    <option value="IDC">Intentional disruption of community</option>
                                    <option value="FOSA">Fraudulent Or Spam Activity</option>
                                    <option value="VOIP">Violation Of Intellectual Property</option>
                                    <option value="ISR">Impersonating Staff Representative</option>
                                    <option value="VTOS">Violation Of Terms Of Services</option>
                                    <option value="PHS">Persistent Harrassment Stalking</option>
                                </select>
                                <svg>
                                    <use xlinkHref="#select-arrow-down"></use>
                                </svg>
                            </label>
                            <svg className="sprites">
                                <symbol id="select-arrow-down" viewbox="0 0 10 6">
                                    <polyline points="1 1 5 5 9 1"></polyline>
                                </symbol>
                            </svg>

                            <div className="inline-block">
                                <label className="select" htmlFor="slct">
                                    <select id="slct" required="required" onChange={(e) => setBanDuration(e.target.value)}>
                                        <option value="" disabled="disabled" selected="selected">Ban Duration</option>
                                        <option value="1M" selected={banDuration === "1M" && true}>1 Month</option>
                                        <option value="3M" selected={banDuration === "3M" && true}>3 Month</option>
                                        <option value="6M" selected={banDuration === "6M" && true}>6 Month</option>
                                        <option value="1Y" selected={banDuration === "1Y" && true}>1 Year</option>
                                        <option value="3Y" selected={banDuration === "3M" && true}>3 Year</option>
                                    </select>
                                    <svg>
                                        <use xlinkHref="#select-arrow-down"></use>
                                    </svg>
                                </label>
                                <svg className="sprites">
                                    <symbol id="select-arrow-down" viewbox="0 0 10 6">
                                        <polyline points="1 1 5 5 9 1"></polyline>
                                    </symbol>
                                </svg>

                                {/* <label>Reason of Ban</label>
                                <textarea
                                    onChange={(e) => setReasonBanning(e.target.value)}
                                    value={reasonBanning}
                                    name="message-mod"
                                    className="table-width-input"
                                    placeholder="Reason of Ban">
                                </textarea> */}

                                <label>Additional Comment</label>
                                <textarea
                                    onChange={(e) => setAdditionalComment(e.target.value)}
                                    value={additionalComment}
                                    name="message-mod"
                                    className="table-width-input"
                                    placeholder="Additional Comment">
                                </textarea>

                                <button type="button" className="ban-user-btn" onClick={banUser}>Ban <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" /></svg></button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }

    return (
        <BanContext.Provider value={{ openBanModel, closeBanModel, BanModal }}>
            {children}
            {/* <BanModal></BanModal> */}
            <BanModal />
        </BanContext.Provider>
    )
}

export const useBanModel = () => {
    return useContext(BanContext);
}

export default BanContext;