import axios from "axios";
import { useEffect, useState } from "react";

import "./premium.css";
import AwardIcon from "./premiumSVGComponents/awardIcon";
import QuestIcon from "./premiumSVGComponents/questIcon";
import EverydayIcon from "./premiumSVGComponents/everydayIcon";
import PriorityIcon from "./premiumSVGComponents/prorityIcon";
import EarlyIcon from "./premiumSVGComponents/earlyIcon";

import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";
import CheckButton from "./CheckoutButton";
import SpreeCineSIcon from "../../svgComponents/SpreeCineIconS";


function PremiumInfo({ currentUserUsername }) {
    const [userOnPremium, setUserOnPremium] = useState(false);

    useEffect(() => {
        window.createLemonSqueezy();
        checkIfUserAuthenticatedOrNot();
    }, [])

    const checkIfUserAuthenticatedOrNot = () => {
        axios.get("/payments/premium-page/user-validation/")
            .then((res) => {
                if (res.data.uop) {
                    setUserOnPremium(true)
                }
            })
            .catch(() => {
            })
    }

    return (
        <LayoutComponentForHelmet pageTitle={"SpreeCine Premium - SpreeCine"}>
            <div className="main-premium-info-container">
                <div className="middle-container">
                    <div className="main-container">
                        <SpreeCineSIcon backgroundcolor={"#20212b"} maincolor={"#897acb"} />
                        <h1>SpreeCine Premium</h1>
                        <p>Upgrade to SpreeCine for enhanced access to exclusive features.</p>
                        <div className="top-right-star corner-star"></div>
                        <div className="bottom-left-star corner-star"></div>
                        <div className="button-container">
                            <CheckButton userOnPremium={userOnPremium} currentUserUsername={currentUserUsername} />
                        </div>
                    </div>

                    <div className="additional-container">
                        <div className="additional-box">
                            <span className="premium-info-feature-icon"><AwardIcon /></span>
                            <p className="info-additional-text">More Awards to Be Given</p>
                        </div>
                        <div className="additional-box">
                            <span className="premium-info-feature-icon"><QuestIcon /></span>
                            <p className="info-additional-text">Participate in longer Tournaments</p>
                        </div>
                        <div className="additional-box">
                            <i className="premium-info-feature-icon"><EverydayIcon /></i>
                            <p className="info-additional-text">Movie Mails Everyday</p>
                        </div>
                        <div className="additional-box">
                            <span className="premium-info-feature-icon"><PriorityIcon /></span>
                            <p className="info-additional-text">More prority of your Posts</p>
                        </div>
                        <div className="additional-box">
                            <span className="premium-info-feature-icon"><EarlyIcon /></span>
                            <p className="info-additional-text">Early access of features</p>
                        </div>
                    </div>
                </div>

                <div className="pi-footer-container">
                    <div className="pi-footer-text">
                        <p>Ready to take your seat in the front row?</p>
                    </div>
                    <div className="pi-footer-button-container">
                        <CheckButton userOnPremium={userOnPremium} currentUserUsername={currentUserUsername} />
                        <p className="pi-footer-additional-text">Subscription renews automatically.</p>
                    </div>
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default PremiumInfo;