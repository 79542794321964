import axios from "axios";
import showToastError from "../../helpers/showToastError";

const submitRemarkComment = (remarkId, remarkCommentState, setRemarkCommentState) => {
    if (!remarkCommentState.inputFieldValue) {
        return ;
    }
    if (remarkCommentState.inputFieldValue !== "") {
        setRemarkCommentState({
            isCommentPostLoading: true,
            commentPostError: "",
        })

        const dateToPost = ({
            comment_body: remarkCommentState.inputFieldValue,
            remark_comment: remarkId,
        })
        axios.post(`/api/content/main/c/u/d/g/remark-comment/${remarkId}/`, dateToPost).then((res) => {
            setRemarkCommentState({
                isCommentPostLoading: false,
                commentPostError: "",
            })

        }).catch((err) => {
            setRemarkCommentState({
                isCommentPostLoading: false,
                commentPostError: "Something went wrong!",
            })
            if (err.response.status === 401) {
                showToastError("You're Banned. You cannot post comment.", "error")
            }
        })
    }
}

export default submitRemarkComment;