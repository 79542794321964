import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Link,
} from 'react-router-dom';
import { useTranslation } from "react-i18next";

import "./styles/blockedUsers.css";
import showToastError from '../helpers/showToastError';
import textTrimmer from "../helpers/stringCutter";
import LoadingIfElseErrorSvg from '../animations/LoadingIfElseErrorSvg';
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';
import { defaultPlaceholderImageIfNotPresentAny } from '../constants/constants';

function BlockedUsers() {
    const { t } = useTranslation();
    const [blockedUserState, setBlockedUserState] = useState({
        isBlockedUsersLoading: false,
        blockedUserFetchError: "",
    });
    const [blockedUsers, setBlockedUsers] = useState([]);
    const [openedUserMoreActionsBar, setOpenedUserMoreActionsBar] = useState([]);

    const getBlockedUsersFromBackend = () => {
        setBlockedUserState({
            isBlockedUsersLoading: true,
            blockedUserFetchError: "",
        })
        axios.get("/api/users/primary/blocked-users/").then((res) => {
            setBlockedUsers(res.data.message[0].blocked_users);
            setBlockedUserState({
                isBlockedUsersLoading: false,
                blockedUserFetchError: "",
            })
        }).catch(() => {
            setBlockedUserState({
                isBlockedUsersLoading: false,
                blockedUserFetchError: "Something went Wrong",
            })
        })
    }

    useEffect(() => {
        getBlockedUsersFromBackend()
    }, [])

    const unBlockUser = (username, id) => {
        const dataToPost = ({
            userToBlockUnBlock: username
        });
        axios.put("/blocked-users/", dataToPost).then((res) => {
            if (res.data.message === "SUCCESS_FOR_REMOVE") {
                const updatedData = blockedUsers.map(item =>
                    item.id === id ? { ...item, blocked_or_unblocked: !item.blocked_or_unblocked } : item
                );
                setBlockedUsers(updatedData);
            } else if (res.data.message === "SUCCESS_FOR_ADD") {
                const updatedData = blockedUsers.map(item =>
                    item.id === id ? { ...item, blocked_or_unblocked: !item.blocked_or_unblocked } : item
                );
                setBlockedUsers(updatedData);
            }
        })
            .catch(() => {
                showToastError(t("Cannot perform action."), "error")
            })
    }

    const openCloseMoreActionBar = (userId) => {
        const user = [...openedUserMoreActionsBar];
        user[userId] = !user[userId];
        setOpenedUserMoreActionsBar(user);
    }

    if (blockedUserState.isBlockedUsersLoading) {
        return <LoadingIfElseErrorSvg isLoading={blockedUserState.isBlockedUsersLoading} errorString={blockedUserState.blockedUserFetchError} retry={getBlockedUsersFromBackend} loadingLogoBigOrSmall={"big"} />
    }

    // todo: Dont show upshots of blocked users.
    return (
        <LayoutComponentForHelmet pageTitle={"Blocked Users - SpreeCine"}>
            <div className="b-user-list">
                {
                    blockedUsers?.map((res) =>
                        <div className="b-user" key={res.id}>
                            {
                                res.profile_image ?
                                    <img src={res.profile_image} alt="User Image" />
                                    :
                                    <img src={defaultPlaceholderImageIfNotPresentAny} alt="User Image" />
                            }
                            <div className="b-user-info">
                                <h3>{res.user_profile_full_name ? <>{res.user_profile_full_name}</> : <>@{res.user_username}</>}</h3>
                                <p>{res.user_profile_full_name ? <>@{res.user_username}</> : null}</p>
                            </div>
                            <svg className={`make-text-cursor ${openedUserMoreActionsBar[res.id] ? "svg-fill-grey" : ""}`} onClick={() => openCloseMoreActionBar(res.id)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                <path
                                    d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                            </svg>
                            {
                                openedUserMoreActionsBar[res.id] ?
                                    <ul className="b-user-action-dropdown">
                                        <li onClick={() => unBlockUser(res.user_username, res.id)} title={res.user_username}>{res.blocked_or_unblocked ? <>{t("Unblock")}</> : <>{t("Block")}</>} @{res.user_username}</li>
                                        <li><Link to={`/profile/${res.user_username, res.id}`}>{t("View profile")}</Link></li>
                                    </ul>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            </div>
        </LayoutComponentForHelmet>
    )
}

export default BlockedUsers;