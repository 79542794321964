import { useState } from 'react';

import InfluencerIcon from './icons/svgComponents/InfluencerSVG';
import ReviewExtraOrdanaireIcon from './icons/svgComponents/ReviewExtraordairne';
import SceneStealerIcon from './icons/svgComponents/SceneStealer';
import GenreMasterIcon from './icons/svgComponents/GenreMaster';
// import ReviewingLegendIcon from './svgComponents/ReviewingLegend';
import ReviewingLegendIcon from './icons/svgComponents/ReviewingLegend';
import MovieMavenIcon from './icons/svgComponents/MovieMaven';
import NextBigThingIcon from './icons/svgComponents/NextBigThing';
import DirectorDarlingIcon from './icons/svgComponents/DirectorDarling';
import ProfilicSceneCreatorIcon from './icons/svgComponents/ProfilicSceneCreator';
import IndependentVoiceIcon from './icons/svgComponents/IndependentVoice';
import MovieMagicMakerIcon from './icons/svgComponents/MovieMagicMaker';
import InsightfulObserverIcon from './icons/svgComponents/InsightfulObserver';
import ThoughtLeaderIcon from './icons/svgComponents/ThoughtLeader';

const defaultClassNames = "navigation-service-icon svg-converted";


function RemarkAwardPopover({ openedRemarkRewardOverlays, openCloseRemarkRewardOverlay, remarkId, sendAwardOnRemark, isUserOnPremium }) {
    const [arePremiumIconsVisible, setPremiumIconsVisible] = useState(false);

    const closeAwardMenu = (remarkId) => {
        openCloseRemarkRewardOverlay(remarkId)
        setPremiumIconsVisible(false);
    }

    return (
        <div className={`main-award-container ${openedRemarkRewardOverlays[remarkId] ? `show-container` : "hide-container"}`}>
            <div className="award-container">
                <div className="award-row">
                    <div className="award-item" data-type="INC" onClick={() => sendAwardOnRemark("INC", remarkId, false, isUserOnPremium)}>
                        <InfluencerIcon height={44} width={44} className={defaultClassNames} />
                        {/* <br /> */}
                        <span>Influencer</span>
                    </div>
                    <div className="award-item" data-type="RVE" onClick={() => sendAwardOnRemark("RVE", remarkId, false, isUserOnPremium)}>
                        <ReviewExtraOrdanaireIcon height={44} width={44} className={defaultClassNames} />
                        <span>Extraordinaire Reviewer</span>
                    </div>
                    <div className="award-item" data-type="STR" onClick={() => sendAwardOnRemark("STR", remarkId, false, isUserOnPremium)}>
                        <SceneStealerIcon height={44} width={44} className={defaultClassNames} />
                        <span>Scene Stealer</span>
                    </div>
                </div>

                {
                    arePremiumIconsVisible ?
                        <>
                            <div className="award-row">
                                <div className="award-item" data-type="GM" onClick={() => sendAwardOnRemark("GM", remarkId, false, isUserOnPremium)}>
                                    <GenreMasterIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Genre Master</span>
                                </div>
                                <div className="award-item" data-type="MVN" onClick={() => sendAwardOnRemark("MVN", remarkId, false, isUserOnPremium)}>
                                    <MovieMavenIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Movie Maven</span>
                                </div>
                                <div className="award-item" data-type="MMM" onClick={() => sendAwardOnRemark("MMM", remarkId, true, isUserOnPremium)}>
                                    {/* <a href="#" className="navigation-services-list-link"> */}
                                    <MovieMagicMakerIcon height={44} width={44} className={defaultClassNames} />
                                    Movie Magic Maker
                                    {/* </a> */}
                                </div>
                            </div>
                            <div className="award-row">
                                <div className="award-item" data-type="NBT" onClick={() => sendAwardOnRemark("NBT", remarkId, true, isUserOnPremium)}>
                                    <NextBigThingIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Next Big Thing</span>
                                </div>
                                <div className="award-item" data-type="DDL" onClick={() => sendAwardOnRemark("DDL", remarkId, true, isUserOnPremium)}>
                                    <DirectorDarlingIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Director Darling</span>
                                </div>
                                <div className="award-item" data-type="MPSCR" onClick={() => sendAwardOnRemark("MPSCR", remarkId, true, isUserOnPremium)}>
                                    <ProfilicSceneCreatorIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Most Profilic Scene Creator</span>
                                </div>
                            </div>
                            <div className="award-row">
                                <div className="award-item" data-type="IDV" onClick={() => sendAwardOnRemark("IDV", remarkId, true, isUserOnPremium)}>
                                    <IndependentVoiceIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Independent Voice</span>
                                </div>
                                <div className="award-item" data-type="IGO" onClick={() => sendAwardOnRemark("IGO", remarkId, true, isUserOnPremium)}>
                                    <InsightfulObserverIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Insightful Observer</span>
                                </div>
                                <div className="award-item" data-type="TGL" onClick={() => sendAwardOnRemark("TGL", remarkId, true, isUserOnPremium)}>
                                    <ThoughtLeaderIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Thought Leader</span>
                                </div>
                            </div>
                            <div className="award-row">
                                <div className="award-item" data-type="GM" onClick={() => sendAwardOnRemark("GM", remarkId, true, isUserOnPremium)}>
                                    <ReviewingLegendIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Reviewing Legend</span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="award-row">
                                <div className="award-item" data-type="GM" onClick={() => sendAwardOnRemark("GM", remarkId, true, isUserOnPremium)}>
                                    <GenreMasterIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Genre Master</span>
                                </div>
                                <div className="award-item" data-type="MVN" onClick={() => sendAwardOnRemark("MVN", remarkId, true, isUserOnPremium)}>
                                    <MovieMavenIcon height={44} width={44} className={defaultClassNames} />
                                    <span>Movie Maven</span>
                                </div>
                                <div className="award-item" onClick={() => setPremiumIconsVisible(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="44" viewBox="0 -960 960 960" width="44"><path d="M481-1 298-184l56-56 127 126 127-127 56 57L481-1Zm0-200L298-384l56-56 127 126 127-127 56 57-183 183ZM354-520l-57-57 184-184 183 184-57 57-126-127-127 127Zm0-200-57-57 184-184 183 184-57 57-126-127-127 127Z" /></svg>
                                    {/* <span className='more-with-premium'>more like "Director Darling", "Movie Magic Maker" with premium.</span> */}
                                    <span className='more-with-premium'>more with premium</span>
                                </div>
                            </div>
                        </>

                }
                <div className='separator'></div>
                <div className='award-close make-text-cursor' onClick={() => closeAwardMenu(remarkId)}>Close</div>
            </div>
        </div>
    )
}

export default RemarkAwardPopover;