import { useTranslation } from "react-i18next";

function SomethingWentWrong({ retry }) {
    const { t } = useTranslation();

    return (
        <div className="error-retry-display-container">
            <div className="error-retry-display-text">
                <p>{t("Something went wrong!")} <b onClick={retry} className="clr-lightblue make-text-cursor">{t("Try Again")}</b></p>
            </div>
        </div>
    )
}

export default SomethingWentWrong;