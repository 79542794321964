import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { t } from "i18next";

import "./styles/tournamentList.css";
import TournamentForPremium from "./icons/tournamentForPremium";
import textTrimmer from '../helpers/stringCutter';
import LoadingIfElseErrorSvg from "../animations/LoadingIfElseErrorSvg";
import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";

class TournamentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: "newest",
            fetchedTournaments: [],
            fetchLoading: [],
            fetchError: "",
            totalTournamentsCount: 0,

            winnersList: [],

            hasMore: true,
            offset: 0,
            limit: 25,

            height: window.innerHeight,
        }
        window.onscroll = (e) => {
            const {
                fetchMoreTournaments,
                state: { fetchError, fetchLoading, hasMore }
            } = this;
            if (fetchError || fetchLoading || !hasMore) return;
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) {
                fetchMoreTournaments();
            }
        }
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        const { fetchLoading } = this.state;

        // * Calculating the distance from the bottom
        const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
        const distanceFromBottom = scrollableHeight - window.scrollY;
        const threshold = 700; // * Adjust this value based on desired distance in pixels. It was 300 but it was not very much space from bottom so changed to 700.

        // * Check if the distance is less than or equal to the threshold.
        if (distanceFromBottom <= threshold && !fetchLoading) {
            if (this.state.hasMore) {
                this.fetchMoreTournaments();
            }
        }
    }

    // sortTournamentsBy(val) {
    //     this.setState(prevState => ({
    //         ...prevState,
    //         sortBy: val,
    //         offset: 0,
    //         limit: 25,
    //         fetchedTournaments: []
    //     }));
    //     this.fetchMoreTournaments();
    // }

    fetchWinnersList() {
        axios.get(`/api/trivia/tournaments/winner/list/`).then((res) => {
            this.setState({
                winnersList: res.data.response
            })
        }).catch(() => {
        })
    }

    fetchMoreTournaments() {
        this.setState({ fetchLoading: true }, () => {
            const { offset, limit, sortBy } = this.state;
            axios.get(`/api/trivia/tournaments/list/?limit=${limit}&offset=${offset}&sortBy=${sortBy}`).then((res) => {
                const newTournaments = res.data.results;
                const hasMore = res.data.has_more;
                this.setState({
                    totalTournamentsCount: res.data.total_tournaments_count
                })
                this.setState(prevState => {
                    const uniqueFetchedContent = [
                        ...prevState.fetchedTournaments,
                        ...newTournaments.filter(newBlog =>
                            !prevState.fetchedTournaments.some(oldBlog => oldBlog.id === newBlog.id)
                        )
                    ];
                    return {
                        fetchLoading: false,
                        hasMore: hasMore,
                        fetchedTournaments: uniqueFetchedContent,
                        offset: this.state.offset + this.state.limit,
                    };
                });
            }).catch(() => {
                this.setState(prevState => ({
                    fetchLoading: false,
                    fetchError: "Something went Wrong!",
                }));
            })
        })
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }
    componentDidMount() {
        this.fetchMoreTournaments();
        this.fetchWinnersList();
        window.addEventListener("scroll", this.handleScroll);
    }

    render() {
        const { fetchedTournaments, winnersList, totalTournamentsCount, fetchLoading, fetchError } = this.state;
        // if (fetchLoading) {
        //     <LoadingIfElseErrorSvg isLoading={fetchLoading} errorString={fetchError} loadingLogoBigOrSmall={"big"} retry={this.fetchMoreTournaments} />
        // }
        return (
            <LayoutComponentForHelmet pageTitle={"Storyline Tournaments - SpreeCine"}>
                <div className="body-space">
                    <header className="tournament-header-text">
                        <div className="tournament-header-text">
                            <h1>{t("Storyline Tournaments")}</h1>
                            <b>{t("Participate in Tournaments and Be a Winner")}</b>
                        </div>
                        <div className="tournament-header-number">
                            <p>{fetchLoading ? <small className="font-size-13px">{t("Loding...")}</small> : <>{fetchError !== "" ? <small className="err-message font-size-13px make-text-cursor" onClick={() => this.fetchMoreTournaments()}>{t("Couldn't fetch. Retry")}</small> : <>{totalTournamentsCount}</>}</>}</p>
                            <span className="header-count-text">{t("Total Tournaments")}</span>
                        </div>
                    </header>

                    <hr className="t-header-below-line" />
                    {
                        fetchLoading &&
                        <LoadingIfElseErrorSvg isLoading={fetchLoading} errorString={fetchError} loadingLogoBigOrSmall={"big"} retry={this.fetchMoreTournaments} />
                    }
                    {
                        winnersList.length !== 0 ?
                            <>
                                <div className="winner-list-container">
                                    <b className="winner-header">{t("Winners")}</b>
                                    <div className="winner-contents">
                                        <div className="flex-container">
                                            {
                                                winnersList?.map((user) =>
                                                    <div className="winner-user-item" key={user.id}>
                                                        <img src={user.winner_user_profile_image}
                                                            alt="User Image" />
                                                        <div className="text-v3">
                                                            <span>{user.winner_name}</span>
                                                        </div>
                                                        <Link to={`/tournament/discussion/${user.id}/`}><small className="winner-tournament-name">{user.room_name}</small></Link>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </>
                            :
                            null
                    }

                    {/* <section className="tournament-section">
                                    <span className="tournament-premium-icon" title="Only users with Premium Membership can participate in this Tournament."><TournamentForPremium /></span>
                </section> */}

                    <div className="tournament--item-container">
                        {
                            fetchedTournaments?.map((res) =>
                                <div className="tournament--item" key={res.id}>
                                    <div className="tournament--item-details">
                                        <h2>
                                            {res.room_name}
                                        </h2>
                                        <b className="tournament-time-duration" title={res.creation_timestamp}>{res.valid_for_hours} <small>{!res.is_freezed ? <>{res.remaining_time}</> : null}</small></b>
                                        <div className="tournament--dot-container">
                                            <div className={`tournament--dot ${res.is_freezed ? `tournament--red-dot` : `tournament--green-dot`}`}></div>
                                            <div className="tournament--dot-label">{res.is_freezed ? <>{t("Tournament Expired")}</> : <>{t("Active")}</>}</div>
                                        </div>
                                        <p className="tournament--attached-content">{textTrimmer(res.upshot_attached_title, 50)}</p>
                                        <hr />
                                        <Link to={`/tournament/discussion/${res.id}`}>
                                            <button type="button">
                                                {
                                                    res.is_for_premium &&
                                                    <span className="tournament-premium-icon" title="Only users with Premium Membership can participate in this Tournament."><TournamentForPremium /></span>
                                                }
                                                {t("Enter Tournament")}
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </LayoutComponentForHelmet>
        )
    }
}

export default TournamentList;