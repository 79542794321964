import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "./contact.css";
import LayoutComponentForHelmet from "../SEO/LayoutComponentForHelmet";
import LoadingIfElseErrorSvg from "../../components/animations/LoadingIfElseErrorSvg";
import Recaptcha from "react-recaptcha";
import showToastError from "../../components/helpers/showToastError";
import { DEBUG, gRecaptchaPublicKey } from "../../components/constants/constants";
import { useParams } from "react-router";

function Contact() {
    const { t } = useTranslation();
    const params = useParams();
    const [formDataState, setFormDataState] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [isSubjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
    const contactChoices = [
        { value: "General Inquiry", validValue: "G" },
        { value: "Security Issue", validValue: "S" },
        { value: "Help with Premium", validValue: "P" },
        { value: "Get Premium Invoice", validValue: "GI" },
        { value: "Want to connect with the team", validValue: "W" },
        { value: "Other", validValue: "O" },
    ];
    const [messagePostState, setMessagePostState] = useState({
        isPostLoading: false,
        isSuccess: false,
        postError: "",
    })
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [recaptchaResponse, setRecaptchaResponse] = useState('');
    const [captchaVerifiedServerFailed, setCaptchaVerifiedServerFailed] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('retrieve-invoice')) {
            setFormDataState(prevState => ({
                ...prevState,
                subject: "GI"
            }))
        }

    }, [])

    const handleGRecaptchaVerifyTrue = (response) => {
        setCaptchaVerified(true);
        setRecaptchaResponse(response);
    };

    const handleGRecaptchaVerifyFalse = () => {
        setCaptchaVerified(false);
    };

    const verifiyGRecaptchaInServer = () => {
        if (!captchaVerified) {
            showToastError("Something went wrong! Try reloading the Page", "warning");
            return;
        }
        sendContactMessage();

        // axios.post("/api/users/g/recaptcha/", null, {
        //     params: {
        //         "g-recaptcha-response": recaptchaResponse
        //     }
        // }).then(() => {
        //     sendContactMessage();
        //     setCaptchaVerifiedServerFailed(true);
        // }).catch(() => {
        //     setCaptchaVerifiedServerFailed(false);
        // })
    }


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormDataState({
            ...formDataState,
            [name]: value
        });
        // Clear the error for the current field when it is being updated
        setErrors({
            ...errors,
            [name]: ''
        });
    };


    const sendContactMessage = () => {
        const newErrors = {};

        // Validate the form fields for empty values
        if (formDataState.name.trim() === '') {
            newErrors.name = 'Name is required.';
        }
        if (formDataState.email.trim() === '') {
            newErrors.email = 'Email is required.';
        }
        if (formDataState.subject.trim() === '') {
            newErrors.subject = 'Choose subject.';
        }
        if (formDataState.message.trim() === '') {
            newErrors.message = 'Type you message.';
        }

        // If any errors, set the errors state and prevent form submission
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setMessagePostState({
            isPostLoading: true,
            isSuccess: false,
            postError: "",
        })
        const dataToPost = ({
            name: formDataState.name,
            email: formDataState.email,
            subject: formDataState.subject,
            message: formDataState.message,
        })
        axios.post("/save/contact/message/", dataToPost).then(() => {
            setMessagePostState({
                isPostLoading: false,
                isSuccess: true,
                postError: "",
            });
            setFormDataState({
                name: "",
                email: "",
                subject: "",
                message: "",
            });
            const timerId = setTimeout(() => {
                setMessagePostState(prevState => ({ ...prevState, isSuccess: false }))
            }, 5000);
            return () => {
                clearTimeout(timerId);
            }
        }).catch(() => {
            setMessagePostState({
                isPostLoading: false,
                isSuccess: false,
                postError: "Something went Wrong!",
            })
        })
    }

    return (
        <LayoutComponentForHelmet pageTitle={"Contact Us - SpreeCine"}>
            <div className={`contact-container`}>
                <header className="cct-header">
                    <h1>{t("Contact Us")}</h1>
                    <p>{t("We'd love to hear from you")} <p className="cct-tagline">{t("Your message will receive our prompt attention")}</p></p>
                </header>
                <main>

                    <div className="contact-form-v2">
                        {
                            messagePostState.isSuccess ?
                                <div className="success-message">
                                    {t("We got your message. Your message won't be missed")}
                                </div>
                                :
                                null
                        }

                        <label htmlFor="name">{t("Name")}</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formDataState.name}
                            onChange={handleChange}
                            required
                        />
                        {errors.name && <span className="c-err-message">{errors.name}</span>}

                        <label htmlFor="email">{t("Email")}</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formDataState.email}
                            onChange={handleChange}
                            required
                        />
                        {errors.email && <span className="c-err-message">{errors.email}</span>}

                        <div className="cct-form-dropdown">
                            <label htmlFor="subject">{t("Subject")}</label>
                            <div className={`contact-dropdown ${isSubjectDropdownOpen ? "dropdown-open" : ""}`} onClick={() => setSubjectDropdownOpen(!isSubjectDropdownOpen)}>
                                <span>{contactChoices.map(choiceFromDict => choiceFromDict.validValue === formDataState.subject && <>{t(choiceFromDict.value)}</>)}</span>
                                <svg className="contact-dropdown-arrow" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                                    <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
                                </svg>
                                <div className="contact-choices" id="dropdown-items">
                                    {
                                        contactChoices.map((value) =>
                                            <div onClick={() => setFormDataState(prevState => ({ ...prevState, subject: value.validValue }))} className={`contact-option-item`} key={value.value}>
                                                <span>
                                                    {t(value.value)}
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        {errors.subject && <span className="c-err-message">{errors.subject}</span>}

                        <label htmlFor="message">{t("Message")}</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            value={formDataState.message}
                            onChange={handleChange}
                            required>
                        </textarea>
                        {errors.message && <span className="c-err-message">{errors.message}</span>}
                        {
                            formDataState.message &&
                            <>
                                <div className="g-recaptcha-mg7"></div>
                                <Recaptcha
                                    sitekey={gRecaptchaPublicKey}
                                    render="explicit"
                                    onloadCallback={() => console.log('reCAPTCHA loaded')}
                                    verifyCallback={handleGRecaptchaVerifyTrue}
                                    expiredCallback={handleGRecaptchaVerifyFalse}
                                    theme="dark"
                                />
                            </>
                        }
                        {
                            captchaVerifiedServerFailed !== null && !captchaVerifiedServerFailed &&
                            <div className="err-message">Failed Verifying! Try again later</div>
                        }

                        <button type="submit" disabled={!captchaVerified} onClick={verifiyGRecaptchaInServer}>
                            {t("Send")}
                            <LoadingIfElseErrorSvg isLoading={messagePostState.isPostLoading} errorString={messagePostState.postError} classNamesForLoading={"error-retry retry-rotate fill-black svg-inline"} classNamesForError={"svg-vertical-align fill-red svg-inline"} retry={sendContactMessage} />
                        </button>
                    </div>
                </main>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default Contact;