
function pop(e) {
    var amount = 15;
    switch (e.target.dataset.type) {
        case 'add':
            amount = 20;
            break;
        case 'delete':
            amount = 20;
            break;
    }

    for (let i = 0; i < amount; i++) {
        const bbox = e.target.getBoundingClientRect();
        const x = bbox.left + bbox.width / 2;
        const y = bbox.top + bbox.height / 2;
        for (let i = 0; i < 3; i++) {
            // We call the function createParticle 30 times
            // We pass the coordinates of the button for x & y values
            createParticle(x, y, e.target.dataset.type);
        }
    }
}

function createParticle(x, y, type) {
    const particle = document.createElement('particle');
    document.body.appendChild(particle);
    let width = Math.floor(Math.random() * 30 + 8);
    let height = width;
    let destinationX = (Math.random() - 0.5) * 300;
    let destinationY = (Math.random() - 0.5) * 300;
    let rotation = Math.random() * 520;
    let delay = Math.random() * 200;

    // 0, 100%, 50% - FOR RED
    // 120, 100%, 25% - FOR GREEN

    switch (type) {
        case 'add':
            var color = `hsl(120, 100%, 25%)`;
            particle.style.background = color;
            particle.style.borderRadius = '50%';
            particle.style.zIndex = '99';
            width = height = Math.random() * 5 + 4;
            break;
        case "delete":
            var color = `hsl(0, 100%, 50%)`;
            particle.style.background = color;
            particle.style.borderRadius = '50%';
            particle.style.zIndex = '99';
            width = height = Math.random() * 5 + 4;
            break;
    }

    particle.style.width = `${width}px`;
    particle.style.height = `${height}px`;
    const animation = particle.animate([
        {
            transform: `translate(-50%, -50%) translate(${x}px, ${y}px) rotate(0deg)`,
            opacity: 1
        },
        {
            transform: `translate(-50%, -50%) translate(${x + destinationX}px, ${y + destinationY}px) rotate(${rotation}deg)`,
            opacity: 0
        }
    ], {
        duration: 2500,
        easing: 'cubic-bezier(0, .9, .57, 1)',
        delay: delay
    });
    animation.onfinish = removeParticle;
}
function removeParticle(e) {
    e.srcElement.effect.target.remove();
}

export default pop;