function formatReturningSeriesRepresentation(firstAirDate) {
    if (firstAirDate) {
        const parts = firstAirDate.split("-");
        const year = parts[0];
        return year + " to " + "Now"
    } else {
        return "N/A"
    }
}

export default formatReturningSeriesRepresentation;