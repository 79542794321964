import "./cookiePolicy.css";
import LegalPolicyHeader from "../LegalPolicyHeader";
import UserLegalReadablesActions from "../UserLegalReadablesActions"
import LayoutComponentForHelmet from "../../../../MainInterface/SEO/LayoutComponentForHelmet";

function CookiePolicy() {

    return (
        <LayoutComponentForHelmet pageTitle={"Cookie Policy - SpreeCine"}>
            <div className="cookies-policy">

                <LegalPolicyHeader includeAppName header={"Cookie Policy"} />
                <UserLegalReadablesActions />

                <div className="cp-overview">
                    This Cookie Notice tells you how we use cookies, plus how you can manage them.
                    Please take a moment to check out our cookie policy. It tells you about us and how we use cookies on our website.
                    Remember to also read our privacy policy, which explains how we handle personal information, your rights, and how to contact us if you have concerns.
                </div>

                <p className="cp-cookie-q">What are Cookies?</p>
                <div className="cp-cookie-a">
                    Cookies are small text files that websites store on your computer or device. They serve various functions, such as remembering your preferences, improving site performance, and providing personalized content. Cookies play a crucial role in enhancing your online experience and are commonly used across the internet to make websites more user-friendly and efficient.
                </div>

                <div className="mg-bm-15p">
                    We use two types of cookies on our website. First-party cookies are set by our website and are primarily used to remember your preferences, enhance site performance, and provide you with a personalized browsing experience.
                    On the other hand, third-party cookies may come from external sources, such as analytics or other info and can be embedded on our website.
                    Additionally, we may utilize our own cookies, specifically for analytics or other relevant purposes.
                </div>

                {/* <table>
                <tr>
                    <th colSpan="4">Strictly Necessary Cookies</th>
                </tr>
                <tr>
                    <th>Domain</th>
                    <th>Cookies</th>
                    <th>Purpose</th>
                    <th>Used as</th>
                </tr>
                <tr>
                    <td>__Spree__DOMAIN__</td>
                    <td><b>token</b></td>
                    <td>Stores a login token needed to act as a logged in user</td>
                    <td>1st Party</td>
                </tr>
            </table> */}
                <i>Strictly necessary:</i> <span className="font-size-14px">These cookies are essential for the proper and secure functioning of our services and cannot be disabled within our systems. While you have the option to configure your browser to block or notify you about these cookies, it's important to note that such actions may render certain parts of the site inoperable</span>

                <table>
                    <tr>
                        <th colSpan="4">Functional(Optional) Cookies</th>
                    </tr>
                    <tr>
                        <th>Domain</th>
                        <th>Cookies</th>
                        <th>Purpose</th>
                        <th>Used as</th>
                    </tr>
                    <tr>
                        <td>google.com</td>
                        <td><b>_GRECAPTCHA</b></td>
                        <td>This Google's reCAPTCHA cookie is used to collect information about how visitors use our website</td>
                        <td>3rd party</td>
                    </tr>
                </table>

                <i>Functional necessary:</i> <span className="font-size-14px">These cookies make our app more useful and tailored to you. If you don't allow them, some or all of these cool features might not work right</span>

                <p className="cp-cookie-q">How to turn off all cookies?</p>
                <div className="cp-cookie-a">
                    If you prefer not to accept any cookies, you can adjust your browser settings to reject cookies, even the ones necessary for the services you want. Keep in mind that by doing this, you might lose some of the features on our website.
                </div>

                <p className="cp-cookie-q">Your browser settings</p>
                <div className="cp-cookie-a">
                    Your web browser has settings for cookies. You can see, delete, or block cookies in most browsers. At first, your browser might accept cookies, but you can change it to notify you or block cookies.
                    Remember, if you block cookies, you might not get access to some features or personalized content on the website. To learn more, check your browser's 'Help' section or click the links below for help with popular browsers:
                </div>

                <ul className="ul-links">
                    <li>
                        <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer" target="_blank">Mozilla Firefox</a>
                    </li>
                    <li>
                        <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">Apple Safari</a>
                    </li>
                    <li>
                        <a href="https://support.google.com/chrome/answer/95647" target="_blank">Google Chrome</a>
                    </li>
                    <li>
                        <a href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies" target="_blank">Microsoft Edge</a>
                    </li>
                </ul>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default CookiePolicy;