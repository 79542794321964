import { Link } from "react-router-dom";
import "./styles/passwordResetEmailSent.css";

function ResetPasswordSuccessComponent({ type }) {

    if (type === "PASSWORD_RESET_EMAIL_SENT_SUCCESS") {
        return (
            <div className="password-reset-container-main">
                <div className="password-reset-container">
                    <div>Password Reset Email sent Successful!</div>
                    <p>If the email you entered is correct and present in our Database then An email should be sent to your email address.</p>
                    <Link to="/login" className="back-to-login">Back to Login</Link>
                </div>
            </div>
        )
    } else {
        return (
            <div className="password-reset-container-main">
                <div className="password-reset-container">
                    <div>Password Reset Successful!</div>
                    <p>You password is successfully reset. You can now Login.</p>
                    <Link to="/login" className="back-to-login">Back to Login</Link>
                </div>
            </div>
        )
    }
}

export default ResetPasswordSuccessComponent;