import InfluencerIcon from "../upshot/icons/svgComponents/InfluencerSVG";
import ThoughtLeaderIcon from "../upshot/icons/svgComponents/ThoughtLeader";
import MovieMagicMakerIcon from "../upshot/icons/svgComponents/MovieMagicMaker";
import NextBigThingIcon from "../upshot/icons/svgComponents/NextBigThing";
import ReviewExtraOrdanaireIcon from "../upshot/icons/svgComponents/ReviewExtraordairne";
import SceneStealerIcon from "../upshot/icons/svgComponents/SceneStealer";
import DirectorDarlingIcon from "../upshot/icons/svgComponents/DirectorDarling";
import GenreMasterIcon from "../upshot/icons/svgComponents/GenreMaster";
import ReviewingLegendIcon from "../upshot/icons/svgComponents/ReviewingLegend";
import ProfilicSceneCreatorIcon from "../upshot/icons/svgComponents/ProfilicSceneCreator";
import MovieMavenIcon from "../upshot/icons/svgComponents/MovieMaven";
import IndependentVoiceIcon from "../upshot/icons/svgComponents/IndependentVoice";
import InsightfulObserverIcon from "../upshot/icons/svgComponents/InsightfulObserver";



function extractAppropriateIconFromKeyWord(key) {
    const defaultHeight = 20;
    const defaultWidth = 20;
    const className = "navigation-service-icon svg-converted-for-small-icons";

    if (key === "INC") {
        return <InfluencerIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "TGL") {
        return <ThoughtLeaderIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "MMM") {
        return <MovieMagicMakerIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "NBT") {
        return <NextBigThingIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "RVE") {
        return <ReviewExtraOrdanaireIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "STR") {
        return <SceneStealerIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "DDL") {
        return <DirectorDarlingIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "GM") {
        return <GenreMasterIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "RVL") {
        return <ReviewingLegendIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "MPSCR") {
        return <ProfilicSceneCreatorIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "MVN") {
        return <MovieMavenIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "IDV") {
        return <IndependentVoiceIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "IGO") {
        return <InsightfulObserverIcon height={defaultHeight} width={defaultWidth} className={className} />
    } else if (key === "ADW") {
        // ! BUILD THE ICON AND UPDATE IT ICON HERE.
        return <IndependentVoiceIcon height={defaultHeight} width={defaultWidth} className={className} />
    }
}

export default extractAppropriateIconFromKeyWord;