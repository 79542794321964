import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from 'react-router-dom';

import "./legalPolicyStyle.css";

function UserLegalReadables({ i18n }) {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [readablesOpen, setReadablesOpen] = useState(false)
    const [currentSelected, setCurrentSelected] = useState("");

    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [currentLanguageSelected, setCurrentLanguageSelected] = useState("en");

    const userLegalReadables = [
        { value: "User Agreement", validValue: "UA", link: `/policies/user-agreement` },
        // { value: "User Agreement", validValue: "UA", link: `/policies/user-agreement?lang=${currentLanguageSelected}` },
        { value: "Terms of Service", validValue: "TOS", link: `/policies/terms-of-service` },
        // { value: "Terms of Service", validValue: "TOS", link: `/policies/terms-of-service?lang=${currentLanguageSelected}` },
        { value: "Privacy Policy", validValue: "PP", link: `/policies/privacy-policy` },
        // { value: "Privacy Policy", validValue: "PP", link: `/policies/privacy-policy?lang=${currentLanguageSelected}` },
        { value: "Content Policy", validValue: "CA", link: `/policies/content-policy` },
        // { value: "Content Policy", validValue: "CA", link: `/policies/content-policy?lang=${currentLanguageSelected}` },
        { value: "Cookie Policy", validValue: "CP", link: `/policies/cookie-policy` },
        // { value: "Cookie Policy", validValue: "CP", link: `/policies/cookie-policy?lang=${currentLanguageSelected}` },
        { value: "Premium Agreement", validValue: "PA", link: `/policies/premium-agreement` },
        // { value: "Premium Agreement", validValue: "PA", link: `/policies/premium-agreement?lang=${currentLanguageSelected}` },
    ];
    const userLegalLanguages = [
        { value: "English", validValue: "en" },
        { value: "Español", validValue: "es" },
        { value: "Italiano", validValue: "it" },
        { value: "Français", validValue: "fe" },
    ];

    useEffect(() => {
        const langValue = searchParams.get('lang');

        if (currentLanguageSelected !== langValue) {
            setCurrentLanguageSelected(langValue || '');
        }
    }, [searchParams, currentLanguageSelected]);

    const handleInputChange = (e) => {
        const newValue = e;
        setCurrentLanguageSelected(newValue);
        setSearchParams({ lang: newValue });
        i18n.changeLanguage(e);
    };

    useEffect(() => {
        if (window.location.pathname === "/policies/privacy-policy") {
            setCurrentSelected("PP")
        } else if (window.location.pathname === "/policies/premium-agreement") {
            setCurrentSelected("PA")
        } else if (window.location.pathname === "/policies/content-policy") {
            setCurrentSelected("CA")
        } else if (window.location.pathname === "/policies/cookie-policy") {
            setCurrentSelected("CP")
        } else if (window.location.pathname === "/policies/user-agreement") {
            setCurrentSelected("UA")
        } else if (window.location.pathname === "/policies/terms-of-service") {
            setCurrentSelected("TOS")
        }
    }, [window.location.pathname])

    return (
        <div className="legal-policy-buttons">
            <div className="legal-policy-action-dropdown">
                <div className={`lp-action-dropdown-main ${readablesOpen ? "dropdown-open" : ""}`} onClick={() => setReadablesOpen(!readablesOpen)}>
                    <span>{userLegalReadables.map(choiceFromDict => choiceFromDict.validValue === currentSelected && <>{t(choiceFromDict.value)}</>)}</span>
                    <svg className="lp-action-dropdown-main-arrow" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                        <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
                    </svg>
                    <div className="lp-action-dropdown-main-arrow-choices" id="dropdown-items">
                        {
                            userLegalReadables.map((value) =>
                                <Link to={t(value.link)} onClick={() => setCurrentSelected(value.validValue)} className={`d-item`} key={value.value}>
                                    <span>{t(value.value)}</span>
                                </Link>
                            )
                        }
                    </div>
                </div>
            </div>
            {/* <div className="legal-policy-action-dropdown">
                <div className={`lp-action-dropdown-main ${languageDropdownOpen ? "dropdown-open" : ""}`} onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}>
                    <span>{userLegalLanguages.map(choiceFromDict => choiceFromDict.validValue === currentLanguageSelected && <>{t(choiceFromDict.value)}</>)}</span>
                    <svg className="lp-action-dropdown-main-arrow" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                        <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
                    </svg>
                    <div className="lp-action-dropdown-main-arrow-choices" id="dropdown-items">
                        {
                            userLegalLanguages.map((value, index) =>
                                <div to={t(value.link)} onClick={() => handleInputChange(value.validValue)} className={`d-item`} key={value.value}>
                                    <span>{t(value.value)}</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default UserLegalReadables;