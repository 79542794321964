import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";

import './contentLookup.css';
import withRouter from "../../helpers/withRouter";
import UpShotListDetailContainerBox from ".././UpShotList/UpShotListDetailContainerBox"
import RemarkContainer from '../RemarkContainer';
import LayoutComponentForHelmet from "../../../MainInterface/SEO/LayoutComponentForHelmet";
import pluralize from "../../helpers/pluralize";
import showToastError from "../../helpers/showToastError";

class ContentListLookup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchedContentList: [],
            isContentFetchingLoading: false,
            contentFetchError: "",

            hasMore: false,
            offset: 0,
            limit: 20,
            height: window.innerHeight,

            currentContentName: "",
            currentContentId: 0,
            currentContentType: "",
            currentContentData: {},
            isContentDataLoading: false,
            remarkInUpShot: new Array(0).fill(false),

            totalCount: 0,
        }
        window.onscroll = (e) => {
            const {
                fetchContentNameResults,
                state: { contentFetchError, isContentFetchingLoading, hasMore }
            } = this;
            if (contentFetchError || isContentFetchingLoading || !hasMore) return;
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) {
                fetchContentNameResults();
            }
        }
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        const { currentContentType, currentContentId, contentMediaType, fetchLoading } = this.state;

        // * Calculating the distance from the bottom
        const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
        const distanceFromBottom = scrollableHeight - window.scrollY;
        const threshold = 700; // * Adjust this value based on desired distance in pixels. It was 300 but it was not very much space from bottom so changed to 700.

        // * Check if the distance is less than or equal to the threshold.
        if (distanceFromBottom <= threshold && !fetchLoading) {
            if (this.state.hasMore) {
                this.fetchContentNameResults(currentContentType, currentContentId, contentMediaType);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        const { contentName, contentType, contentId, mediaType } = this.props.router.params;
        this.setState({
            currentContentName: contentName,
            currentContentId: contentId,
            currentContentType: contentType,
            contentMediaType: mediaType
        })
        this.fetchContentNameResults(contentType, contentId, mediaType);
    }

    fetchContentNameResults = (contentType = "upshot", contentId = undefined, mediaType) => {
        this.setState({ isContentFetchingLoading: true }, () => {
            const { offset, limit } = this.state;
            if (offset === 0) {
                this.setState(prevState => ({
                    ...prevState,
                    isContentDataLoading: true
                }))
            }
            axios.get(`/api/content/main/created-content/name/fetch/${contentId}/?limit=${limit}&offset=${offset}&contentType=${contentType}&mediaType=${mediaType}`
            ).then((res) => {
                const newUpShots = res.data.content;
                const hasMore = res.data.has_more;
                const outerData = res.data.get_content;
                if (offset === 0) {
                    this.setState(prevState => ({
                        ...prevState,
                        isContentDataLoading: false
                    }))
                }
                this.setState(prevState => ({
                    ...prevState,
                    hasMore,
                    isContentFetchingLoading: false,
                    fetchedContentList: [...new Set([...prevState.fetchedContentList, ...newUpShots])],
                    offset: offset + limit,
                    totalCount: res.data.total_count,
                    currentContentData: outerData ? outerData : prevState.currentContentData,
                    mediaType: mediaType
                }));
                const haveAnyRemarksOnUpShot = res.data.content.filter(upshot => upshot.if_user_have_any_remark).map(upshot => upshot.id);
                this.setState({
                    remarkInUpShot: haveAnyRemarksOnUpShot,
                })
            }).catch(() => {
                this.setState({
                    isContentFetchingLoading: false,
                    contentFetchError: "Something went wrong",
                    isContentDataLoading: false
                });
                showToastError("Failed to get content lookups, maybe because of network", "error");
            })
        })
    }

    render() {
        const { fetchedContentList, remarkInUpShot, totalCount, currentContentType, currentContentData, mediaType, isContentFetchingLoading, contentFetchError } = this.state;

        return (
            <LayoutComponentForHelmet pageTitle={"Content List lookup - SpreeCine"}>
                <div className="content-lookup-container">
                    <ContentDataHeader
                        contentData={currentContentData}
                        mediaType={mediaType}
                        contentType="upshot"
                        isContentFetchingLoading={isContentFetchingLoading}
                        totalCount={totalCount}
                        contentFetchError={contentFetchError}
                    />
                    {
                        currentContentType === "upshots"
                            ?
                            <>
                                {
                                    fetchedContentList?.map((res, index) =>
                                        <div key={res.id} className="upshots-list-v2">
                                            <UpShotListDetailContainerBox
                                                index={index}
                                                res={res}
                                                remarkInUpShot={remarkInUpShot}
                                                currentUserProfileImage={this.props.currentUserProfileImage}
                                            />
                                        </div>
                                    )
                                }
                            </>
                            :
                            <div className="remark-list-container">
                                {
                                    fetchedContentList?.map((remark, index) =>
                                        <div key={remark.id} className="remark-list">
                                            <RemarkContainer remark={remark} />
                                        </div>
                                    )
                                }
                            </div>
                    }
                </div>
            </LayoutComponentForHelmet>
        )
    }
}

function ContentDataHeader({ contentData, mediaType, contentType, totalCount, isContentFetchingLoading, contentFetchError }) {

    return (
        <div className="lookup-container mg-top-40px">
            <h1 className="lookup-header">
                {
                    isContentFetchingLoading ?
                        <>Loading...</>
                        :
                        <>
                            {
                                contentFetchError === "" ?
                                    <>
                                        {contentData?.original_title ? contentData?.original_title : contentData?.name}
                                        <small> ({totalCount} {contentType}{pluralize(totalCount)})</small>
                                    </>
                                    :
                                    <>Something went Wrong</>
                            }
                        </>
                }
            </h1>
            <hr />
            <div className="lookup-content-description">
                <div className="lc-img">
                    {/* <img src={`https://image.tmdb.org/t/p/w300/${contentData?.poster_path ? contentData?.poster_path : contentData?.profile_path}`} alt="Image" /> */}
                </div>
                <div className="lookup-content">
                    <p>{contentData?.overview ? contentData?.overview : contentData?.biography}</p>
                </div>
            </div>
            {
                mediaType !== "celeb" &&
                <div className="genre-container">
                    {
                        contentData?.genres?.map((genre) =>
                            <kbd key={genre.name}>{genre.name}</kbd>
                        )
                    }
                </div>
            }
            {
                isContentFetchingLoading ?
                    <>Loading...</>
                    :
                    <>
                        {
                            contentFetchError === "" &&
                            <>
                                {mediaType !== "celeb" &&
                                    <Link to={`/content/${mediaType}/${contentData?.original_title ? contentData?.original_title : contentData?.name}/${contentData?.id}`} className="lookup-more-button">More</Link>
                                }
                            </>
                        }
                    </>
            }
        </div>
    )
}

export default withRouter(ContentListLookup);
