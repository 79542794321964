import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import "./home.css"
import './translations/i18n';
import MainApp from './MainApp';
import esTranslation from "./translations/locales/es.json";
import enTranslation from "./translations/locales/en.json";
import itTranslation from "./translations/locales/it.json";
import jpTranslation from "./translations/locales/jp.json";
import zhTranslation from "./translations/locales/zh.json";
import { GlobalModal, GlobalModalProvider } from './MainInterface/contexts/FeedbackForEverything/FeedbackForEverythingProvider';

i18n.use(LanguageDetector).init({
    resources: {
        en: { translation: enTranslation },
        es: { translation: esTranslation },
        it: { translation: itTranslation },
        jp: { translation: jpTranslation },
        zh: { translation: zhTranslation }
    },
    fallbackLng: "en",
    debug: false,
    interpolation: { escapeValue: false },
});

const selectedAppLanguage = localStorage.getItem("inaplang") || "en";
i18n.changeLanguage(selectedAppLanguage);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18n}>
        <GlobalModalProvider>
            <MainApp />
            <GlobalModal />
        </GlobalModalProvider>
    </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
