import React, { useContext } from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import {
    FacebookShareButton,
    InstapaperShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    FacebookIcon,
    InstapaperIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

import './styles/Share.css';
import "./styles/shareV2.css"

import showToastError from "../helpers/showToastError";
import { defaultOrigin } from "../constants/constants";
import { OPTION_UPSHOT, OPTION_PROFILE, OPTION_WATCH_LATER, OPTION_FAVOURITES, CHOICE_IF_PUBLIC } from "./constants";
import ThemeContext from "../appearance/ThemeContext";
import { useTranslation } from "react-i18next";

// const constantClassForSelectedChoice = "fs-12 error-message"

// function SearchUsers({ searchedQuery, setSearchedQuery, selectedUsers, setSelectedUsers }) {
//     const [searchUserState, setSearchUserState] = useState({
//         fetchedUsers: [],

//         isContentSearchLoading: false,
//         contentSearchError: "",
//     })
//     const timerRef = useRef(null);

//     useEffect(() => {
//         setSearchedQuery(searchedQuery);
//         clearTimeout(timerRef.current);
//         setSearchUserState({
//             isContentSearchLoading: true,
//             contentSearchError: null
//         })

//         timerRef.current = setTimeout(() => {
//             axios.get(`/search-users/?username=${searchedQuery}`).then((res) => {
//                 setSearchUserState(prevState => ({
//                     ...prevState,
//                     fetchedUsers: res.data,
//                     isContentSearchLoading: false,
//                 }))
//             }).catch((err) => {
//                 setSearchUserState({
//                     contentSearchError: err.message,
//                     isContentSearchLoading: false,
//                 })
//             })
//         }, 300);
//     }, [searchedQuery])

//     const addUserInSelectedList = (username) => {
//         const userExists = selectedUsers.some(user => user == username);
//         if (userExists) {
//             setSelectedUsers(prevUsers => prevUsers.filter(user => user != username));
//         }
//         else {
//             setSelectedUsers(prevUsers => [...prevUsers, username]);
//         }
//     }

//     return (
//         <>
//             {searchUserState.isContentSearchLoading ? <>Loading.....</> : null}
//             <div className="user-list">
//                 {
//                     searchUserState.fetchedUsers?.map((res, index) =>
//                         <>
//                             <div className="user-item" key={index}>
//                                 <label className="user-checkbox">
//                                     <input type="checkbox" name="user1" onClick={() => addUserInSelectedList(res.username[0])} defaultChecked={selectedUsers.some(user => user.username === res.username[0] ? true : false)} />
//                                     <span className="user-checkmark"></span>
//                                 </label>
//                                 <div className="user-profile">
//                                     <img src="https://www.w3schools.com/howto/img_avatar.png" alt="User 1" />
//                                     <div className="user-info">
//                                         <h3>{index} - {res.username[0]}</h3>
//                                     </div>
//                                 </div>
//                             </div>
//                         </>
//                     )
//                 }
//             </div>
//         </>
//     )
// }


function ShareModel({ isShareModalOpen, closeShareMainModal, shareData, pageType }) {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const [urlState, setUrlState] = useState({
        url: "",
        id: 0,
        pageType: "",
    });

    useEffect(() => {
        setUrlState({
            url: shareData.shareUrl,
            id: shareData.id,
            pageType: pageType
        })
    }, [shareData])

    // const saveUrl = () => {
    // }

    const copyLinkToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        showToastError("Link copied to clipboard", "success")
    }

    return (
        <>
            {
                isShareModalOpen &&
                <>
                    <div className={`share-modal-v2-container`}>
                        <div className="overlay"></div>
                        <div className="share-modal-v2">
                            <div className="share-modal-v2-close-button" onClick={closeShareMainModal}>X</div>
                            <div className="share-modal-v2-header">
                                <h2>Share
                                    {(() => {
                                        switch (urlState.pageType) {
                                            case OPTION_UPSHOT:
                                                if (urlState.pageType === OPTION_UPSHOT) {
                                                    return <> {t("UpShot")}</>;
                                                }
                                                return null;
                                            case OPTION_PROFILE:
                                                if (urlState.pageType === OPTION_PROFILE) {
                                                    return <> {t("Profile")}</>;
                                                }
                                                return null;
                                            case OPTION_WATCH_LATER:
                                                if (urlState.pageType === OPTION_WATCH_LATER) {
                                                    return <> {t("Watch Later")}</>;
                                                }
                                                return null;
                                            case OPTION_FAVOURITES:
                                                if (urlState.pageType === OPTION_FAVOURITES) {
                                                    return <> {t("Favourites")}</>;
                                                }
                                                return null;
                                            default:
                                                return null;
                                        }
                                    })()}
                                </h2>
                            </div>
                            <div className="share-modal-v2-content">
                                <div className="share-modal-input-container">
                                    <input type="text" value={`${defaultOrigin}${urlState.url}`} disabled />
                                    <button id="copy-link-button" onClick={() => copyLinkToClipboard(`${defaultOrigin}/${urlState.url}`)}>
                                        Copy
                                    </button>
                                </div>
                                <p>{t("Share this link via")}</p>
                                <div className="share-modal-sm-icon-container">
                                    <FacebookShareButton
                                        url={`${defaultOrigin}/${urlState.url}`}
                                        quote={"SpreeCine - World is yours to explore"}
                                        hashtag="#SpreeCine"
                                    >
                                        <FacebookIcon size={45} round={true} />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        url={`${defaultOrigin}/${urlState.url}`}
                                        quote={"SpreeCine - World is yours to explore"}
                                        hashtag="#SpreeCine"
                                    >
                                        <TwitterIcon size={45} round={true} />
                                    </TwitterShareButton>
                                    <InstapaperShareButton
                                        url={`${defaultOrigin}/${urlState.url}`}
                                        quote={"SpreeCine - World is yours to explore"}
                                        hashtag="#SpreeCine"
                                    >
                                        <InstapaperIcon size={45} round={true} />
                                    </InstapaperShareButton>
                                    <WhatsappShareButton
                                        url={`${defaultOrigin}/${urlState.url}`}
                                        title={"title"}
                                        separator=":: "
                                    >
                                        <WhatsappIcon size={45} round={true} />
                                    </WhatsappShareButton>
                                    <TelegramShareButton
                                        url={`${defaultOrigin}/${urlState.url}`}
                                        title={"title"}
                                    >
                                        <TelegramIcon size={45} round={true} />
                                    </TelegramShareButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default ShareModel;