import { useState } from "react";

import textTrimmer from "../../helpers/stringCutter";

const UpShotCarousel = ({ content, title, tv_movie_data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + content.length) % content.length);
    };

    return (
        <div className="usd-upshot-crousel">
            <div className="usd-crousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {content.map((item, index) => (
                    <div
                        key={index}
                        className={`upshot-crousel-slide ${currentIndex !== 1 && "mg-t-30"}`}
                    >
                        {
                            currentIndex !== 1 ?
                                <>
                                    {title}
                                </>
                                :
                                <div className="upshot-more-description">
                                    <div className="table-container">
                                        <div className="table">
                                            <div className="table-row">
                                                {tv_movie_data && Object.keys(tv_movie_data).length > 0 && (
                                                    Object.keys(tv_movie_data)?.map((keyName) => (
                                                        <div className="table-cell">
                                                            <div className="image">
                                                                <img src={`https://image.tmdb.org/t/p/w200${item[keyName].poster_path}`} alt="Image 1" />
                                                            </div>
                                                            <div className="description">
                                                                <p>{textTrimmer(tv_movie_data[keyName].original_title, 38)}</p>
                                                                <span className="release-date">{tv_movie_data[keyName].release_date}</span>
                                                            </div>
                                                        </div>
                                                    )))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                ))}
            </div>
            <button type="button" name="crousel-button" className="upshot-carousel-button left" onClick={prevSlide}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
                </svg>
            </button>
            <button type="button" name="crousel-button" className="upshot-carousel-button right" onClick={nextSlide}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
                </svg>
            </button>
        </div>
    );
};

export default UpShotCarousel;