import * as React from "react";
const Loading = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="10%"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="grey"
            opacity={1}
            stroke="none"
            d=" M128.411194,407.838440  C119.956970,410.910767 111.139206,408.578705 107.183327,402.529907  C103.723816,397.240082 104.228043,385.739655 110.023979,380.207672  C126.889061,364.110687 143.133926,347.364075 159.630386,330.880585  C166.511749,324.004639 176.556442,324.004303 183.358459,330.855316  C189.952103,337.496399 189.928574,347.556702 183.260727,354.231842  C166.662109,370.848694 150.058487,387.460602 133.415588,404.033020  C132.031616,405.411133 130.335999,406.476288 128.411194,407.838440  z"
        />
        <path
            fill="grey"
            opacity={1}
            stroke="none"
            d=" M156.253754,179.753510  C140.591782,164.091705 125.318459,148.538193 109.694962,133.344742  C104.224350,128.024719 102.534950,116.487602 109.514969,109.280968  C115.489288,103.112656 126.959679,103.473907 133.146866,109.652084  C149.864532,126.345383 166.353333,143.275742 183.374619,159.653000  C189.652740,165.693588 189.306778,178.217850 183.686188,183.771759  C178.058334,189.332855 165.029068,190.176575 159.660477,183.194901  C158.755539,182.018051 157.563858,181.061661 156.253754,179.753510  z"
        />
        <path
            fill="grey"
            opacity={1}
            stroke="none"
            d=" M377.254608,113.245880  C381.825134,107.847366 386.841919,104.323029 394.065277,104.956451  C400.977448,105.562599 405.725281,109.062866 408.040253,115.381332  C410.179230,121.219376 409.936768,127.351303 405.414764,131.962524  C388.527832,149.182526 371.432129,166.199860 354.276794,183.153656  C347.380096,189.969376 337.039246,189.766434 330.595490,183.095612  C324.122620,176.394638 324.256561,166.341766 331.151825,159.391159  C346.381592,144.039139 361.713257,128.788208 377.254608,113.245880  z"
        />
        <path
            fill="grey"
            opacity={1}
            stroke="none"
            d=" M408.798126,395.406982  C404.781342,411.126068 387.932281,412.157349 378.667328,402.396240  C363.095947,385.990967 346.782501,370.290283 330.791626,354.282837  C324.020111,347.504303 324.087799,337.342712 330.919586,330.576752  C337.504639,324.055145 347.614532,324.129791 354.273163,330.781250  C370.398682,346.889374 386.480194,363.041687 402.649048,379.106110  C407.115753,383.543945 409.412628,388.693817 408.798126,395.406982  z"
        />
        <path
            fill="grey"
            opacity={1}
            stroke="none"
            d=" M431.999939,241.000000  C438.663025,240.999954 444.826355,240.964890 450.989136,241.008743  C458.690094,241.063538 464.649323,247.448395 465.081635,255.221588  C465.500244,262.747833 463.283142,268.569092 456.223755,271.840210  C454.513092,272.632874 452.436890,272.947144 450.527191,272.952667  C425.875061,273.024231 401.222626,273.010956 376.570282,272.994171  C368.021820,272.988373 361.358185,265.883850 360.910370,257.201599  C360.537933,249.980988 368.312073,240.566467 376.537384,240.822357  C394.842377,241.391769 413.177277,241.000000 431.999939,241.000000  z"
        />
        <path
            fill="grey"
            opacity={1}
            stroke="none"
            d=" M271.425385,144.094070  C267.469299,151.394836 260.139465,154.600891 250.930557,151.900894  C245.151398,150.206467 241.000397,143.473877 241.000183,135.824554  C240.999496,111.831871 240.993317,87.839188 241.003021,63.846504  C241.006714,54.721378 247.145996,49.000458 256.888092,48.999638  C266.701538,48.998810 272.989258,54.683521 272.994781,63.630455  C273.010040,88.289589 273.031433,112.948860 272.942871,137.607697  C272.935425,139.671005 272.120667,141.731445 271.425385,144.094070  z"
        />
        <path
            fill="grey"
            opacity={1}
            stroke="none"
            d=" M241.000000,398.000000  C241.000061,390.509369 240.982101,383.518677 241.004761,376.528107  C241.032333,368.029205 248.084991,361.132568 256.841736,361.004272  C265.711243,360.874329 272.990784,367.928711 272.995483,376.715240  C273.008728,401.348877 273.015381,425.982513 272.991821,450.616150  C272.983276,459.550720 266.540649,465.016327 256.156067,465.000275  C247.233917,464.986481 241.014755,459.033569 241.005417,450.430359  C240.986633,433.120239 240.999939,415.810120 241.000000,398.000000  z"
        />
        <path
            fill="grey"
            opacity={1}
            stroke="none"
            d=" M99.000000,273.000000  C86.847786,273.000031 75.195549,273.017120 63.543377,272.994568  C54.677956,272.977417 48.998093,266.634644 48.999645,256.816467  C49.001141,247.380188 54.761322,241.013885 63.395370,241.006882  C88.197922,240.986771 113.000511,240.981842 137.803040,241.010208  C145.963211,241.019531 153.139725,248.717529 152.996017,257.180634  C152.850891,265.725708 146.023407,272.958862 137.952225,272.987762  C125.134972,273.033630 112.317421,273.000000 99.000000,273.000000  z"
        />
    </svg>
);
export default Loading;
