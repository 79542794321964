import React from "react";
import axios from "axios";
import sanitizeHtml from "sanitize-html";
import { t } from "i18next";

import "./questionList.css";
// import LoadingLogo from "../animations/LoadingLogo";
import LoadingIfElseErrorSvg from "../animations/LoadingIfElseErrorSvg";
import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";


class DailyQuestionsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchedQuestions: [],

            fetchError: "",
            fetchLoading: false,
            hasMore: true,
            offset: 0,
            limit: 10,

            isMoreLoading: false,

            height: window.innerHeight,
        }
        window.onscroll = (e) => {
            const {
                loadQuestions,
                state: { fetchError, fetchLoading, hasMore }
            } = this;
            if (fetchError || fetchLoading || !hasMore) return;
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) {
                loadQuestions();
            }
        }
        this.handleScroll = this.handleScroll.bind(this);
    };

    handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;

        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset + 0.5;

        const { innerHeight } = window;
        const { scrollHeight, scrollTop } = document.documentElement;
        const distanceFromBottom = scrollHeight - (innerHeight + scrollTop);
        if (distanceFromBottom < 100) {
            if (windowBottom >= docHeight) {
                if (this.state.hasMore) {
                    this.loadQuestions();
                }
            }
        }
    }

    loadQuestions = () => {
        this.setState({ fetchLoading: true, isMoreLoading: true }, () => {
            const { offset, limit } = this.state;
            axios.get(`/api/trivia/get/user/questions/yawan/?limit=${limit}&offset=${offset}`).then((res) => {
                const newQuestions = res.data.message;
                const hasMore = res.data.has_more;
                this.setState(prevState => ({
                    hasMore,
                    fetchLoading: false,
                    fetchedQuestions: [...new Set([...prevState.fetchedQuestions, ...newQuestions])],
                    offset: offset + limit,
                    isMoreLoading: false,
                }))
            }).catch(() => {
                this.setState({
                    fetchError: "Something went Wrong!",
                    fetchLoading: false,
                    isMoreLoading: false,
                })
            })
        })
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        this.loadQuestions();
    }

    render() {
        const { fetchError, isMoreLoading, fetchedQuestions, hasMore } = this.state;
        return (
            <LayoutComponentForHelmet pageTitle={"Daily Questions List - SpreeCine"}>
                <div className="body-space">
                    <div className="questions-list-container">
                        {
                            fetchedQuestions?.map((res) =>
                                <div className="question-item" key={res.id}>
                                    <h2>
                                        {sanitizeHtml(res.question_statement)}
                                        <span className="question-status">{res.user_question_status}</span>
                                    </h2>
                                    <span className="question-time">{res.generation_date}</span>
                                    <ul className="question-options">
                                        {
                                            res.question_options?.map((option) =>
                                                <li
                                                    key={option}
                                                    className={`${res.correct_answer_atfh && `${option === res.selected_answer ? `was-selected ${res.selected_answer !== res.correct_answer_atfh ? "question-stat-wrong" : ""}` : ""}`} ${res.correct_answer_atfh && `${option === res.correct_answer_atfh ? "question-stat-correct" : ""}`}`}>
                                                    {option}
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                    <LoadingIfElseErrorSvg isLoading={isMoreLoading} errorString={fetchError} loadingLogoBigOrSmall={"big"} retry={this.loadQuestions} />
                    {
                        !hasMore && <div className="no-data-message">{t("No more Questions")}</div>
                    }
                </div>
            </LayoutComponentForHelmet>
        )
    }
}

export default DailyQuestionsList;