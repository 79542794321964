import { useParams } from "react-router";
import WorkingToBuild from "../../../svgComponents/buildingIcon";
import "./appFor.css";
import { useEffect, useState } from "react";
import LayoutComponentForHelmet from "../../../MainInterface/SEO/LayoutComponentForHelmet";

function AppForIosAndroid() {
    const params = useParams();
    const [typeOfAppFor, setTypeOfAppFor] = useState("");

    useEffect(() => {
        if (params.for === "android") {
            setTypeOfAppFor("Android")
        } else if (params.for === "ios") {
            setTypeOfAppFor("IOS")
        }
    }, [params])

    return (
        <LayoutComponentForHelmet pageTitle="SpreeCine">
            <div className="body-space">
                <div className="app-for-image-container">
                    {/* <img src="https://image.tmdb.org/t/p/w500//ym1dxyOk4jFcSl4Q2zmRrA5BEEN.jpg" alt="Working hard" className="center-image" /> */}
                    <WorkingToBuild />
                </div>
                <div className="app-for-header">
                    <div className="app-for-container">
                        <h1>
                            Our team is pouring their expertise and passion into the development of an exceptional {typeOfAppFor} application, aiming to redefine user experiences and set new standards in mobile innovation.
                            <br />
                            <br />
                            We'll let you you know whenever it's Ready.
                        </h1>
                    </div>
                </div>
                {/* <section>
                <div className="app-for-container">
                    <p>
                        Our team is pouring their expertise and passion into the development of an exceptional iOS application, aiming to redefine user experiences and set new standards in mobile innovation.
                    </p>
                </div>
            </section> */}
            </div>
        </LayoutComponentForHelmet>
    )
}

export default AppForIosAndroid;