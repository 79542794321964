import { useState, useEffect } from "react";
import statusCards from './statusCards.json';
import './styles/statuscard.css';
import Chart from 'react-apexcharts'
import UniqueUsersIcon from './svgs/uniqueUsersIcon';
import { useLocation } from "react-router";
import { useParams } from 'react-router';
import axios from "axios";
import showToastError from '../../components/helpers/showToastError';
import AdminDashboardDropdown from "./AdminDashboardDropdown";
import { adminApiUrls } from "./api/api";

const chartOptions = {
    series: [{
        name: 'Users Graph',
        data: [
            {
                "x": "Apr-27",
                "y": 2
            },
            {
                "x": "May-14",
                "y": 1
            },
            {
                "x": "May-17",
                "y": 1
            },
            {
                "x": "May-20",
                "y": 18
            },
            {
                "x": "May-21",
                "y": 10
            },
            {
                "x": "May-22",
                "y": 17
            },
            {
                "x": "May-23",
                "y": 89
            }
        ]
    },
    ],
    options: {
        color: ['#6ab04c'],
        chart: {
            background: 'transparent'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        legend: {
            position: 'top'
        },
        grid: {
            show: false
        }
    }
}



function StatusCard(props) {
    return (
        <div className='status-card'>
            <div className="status-card__icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 96 960 960" width="40"><path d="M226.666 976q-27 0-46.833-19.833T160 909.334V402.666q0-27 19.833-46.833T226.666 336h100.001v-6.667q0-64 44.666-108.666Q416 176 480 176t108.667 44.667q44.666 44.666 44.666 108.666V336h100.001q27 0 46.833 19.833T800 402.666v506.668q0 27-19.833 46.833T733.334 976H226.666Zm0-66.666h506.668V402.666H633.333v86.667q0 14.167-9.617 23.75t-23.833 9.583q-14.216 0-23.716-9.583-9.5-9.583-9.5-23.75v-86.667H393.333v86.667q0 14.167-9.617 23.75t-23.833 9.583q-14.216 0-23.716-9.583-9.5-9.583-9.5-23.75v-86.667H226.666v506.668ZM393.333 336h173.334v-6.667q0-36.333-25.167-61.5T480 242.666q-36.333 0-61.5 25.167t-25.167 61.5V336ZM226.666 909.334V402.666v506.668Z" /></svg>
            </div>
            <div className="status-card__info">
                <h4>{props.count}</h4>
                <span>{props.title}</span>
            </div>
        </div>
    )
}

function AdminDashboard() {
    const params = useParams();
    const [showDashboardToAdmin, setShowDashboardToAdmin] = useState(false);
    const [graphData, setGraphData] = useState([]);
    const [dashboardData, setDashboardData] = useState({
        totalUsers: 0,
        uniqueUsersToday: 0,
        totalIncome: 0,
        totalPayments: 0,
    })
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    useEffect(() => {
        const adminDashboardUrls = adminApiUrls.adminDashboard;
        const dashboardDataFetchUrl = adminDashboardUrls.dashboardHomeData(params.id);

        setShowDashboardToAdmin(false);
        axios.get(dashboardDataFetchUrl).then((res) => {
            console.log(res);
            if (res.status === 200) {
                if (res.data.access_status === "A") {
                    setShowDashboardToAdmin(true);

                    const formattedData = res.data.series[0].data.map(item => ({
                        x: item.x,
                        y: item.y
                    }));
                    const options = {
                        series: [
                            {
                                "name": "User Graph",
                                data: formattedData,
                            }
                        ]
                    };

                    setDashboardData(prevState => ({
                        ...prevState,
                        totalUsers: res.data.total_users,
                        uniqueUsersToday: res.data.users_signed_up_today,
                        totalIncome: res.data.total_income,
                        totalPayments: res.data.total_payments,
                    }))
                    setGraphData(options)
                }
            } else {
                setShowDashboardToAdmin(false);
            }
        }).catch((err) => {
            console.log(err);
            setShowDashboardToAdmin(false);
            if (err.response.status === 401) {
                showToastError("Can't access right now", "error");
            }
        })
    }, [params])

    return (
        <>
            {
                showDashboardToAdmin ?
                    <>
                        <div>
                            <AdminDashboardDropdown
                                isDropdownOpen={isDropdownOpen}
                                setIsDropdownOpen={setIsDropdownOpen}
                            />
                            <br /><br />
                            <h2 className="page-header"></h2>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">

                                        <div className="col-6">
                                            <div className='status-card'>
                                                <div className="status-card__icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M38 896v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358 636q62 0 120 14t131 46q32 14 50.5 42.5T678 802v94H38Zm700 0v-94q0-63-32-103.5T622 633q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519 568q24-25 36.5-61.5T568 425q0-45-12.5-79.5T519 282q11-3 24.5-5t24.5-2q66 0 108 42t42 108ZM98 836h520v-34q0-16-9.5-31T585 750q-72-32-121-43t-106-11q-57 0-106.5 11T130 750q-14 6-23 21t-9 31v34Zm260-321q39 0 64.5-25.5T448 425q0-39-25.5-64.5T358 335q-39 0-64.5 25.5T268 425q0 39 25.5 64.5T358 515Zm0 321Zm0-411Z" /></svg>
                                                </div>
                                                <div className="status-card__info">
                                                    {/* <h4>20,995</h4> */}
                                                    <h4>{dashboardData.totalUsers}</h4>
                                                    <span>Total users</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className='status-card'>
                                                <div className="status-card__icon">
                                                    <UniqueUsersIcon />
                                                </div>
                                                <div className="status-card__info">
                                                    {/* <h4>2,001</h4> */}
                                                    <h4>{dashboardData.uniqueUsersToday}</h4>
                                                    <span>Unique users today</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className='status-card'>
                                                <div className="status-card__icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M451 936v-84q-57-10-93.5-43.5T305 724l56-23q17 48 49 71.5t77 23.5q48 0 79-24t31-66q0-44-27.5-68T466 589q-72-23-107.5-61T323 433q0-55 35.5-92t92.5-42v-83h60v83q45 5 77.5 29.5T638 391l-56 24q-14-32-37.5-46.5T483 354q-46 0-73 21t-27 57q0 38 30 61.5T524 542q68 21 100.5 60.5T657 702q0 63-37 101.5T511 853v83h-60Z" /></svg>
                                                </div>
                                                <div className="status-card__info">
                                                    {/* <h4>$2,632</h4> */}
                                                    <h4>${dashboardData.totalIncome}</h4>
                                                    <span>Total income</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className='status-card'>
                                                <div className="status-card__icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M222 976q-43.75 0-74.375-30.625T117 871V746h127V176l59.8 60 59.8-60 59.8 60 59.8-60 59.8 60 60-60 60 60 60-60 60 60 60-60v695q0 43.75-30.625 74.375T738 976H222Zm516-60q20 0 32.5-12.5T783 871V276H304v470h389v125q0 20 12.5 32.5T738 916ZM357 434v-60h240v60H357Zm0 134v-60h240v60H357Zm333-134q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9Zm0 129q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9ZM221 916h412V806H177v65q0 20 12.65 32.5T221 916Zm-44 0V806v110Z" /></svg>                                    </div>
                                                <div className="status-card__info">
                                                    {/* <h4>1,71</h4> */}
                                                    <h4>{dashboardData.totalPayments}</h4>
                                                    <span>Total payments</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="chart-card full-height">
                                        <Chart
                                            // options={themeReducer === 'theme-mode-dark' ? {
                                            //     ...chartOptions.options,
                                            //     theme: { mode: 'dark' }
                                            // } : {
                                            //     ...chartOptions.options,
                                            //     theme: { mode: 'light' }
                                            // }}
                                            options={{ theme: { mode: "light" } }}
                                            series={graphData.series}
                                            type='line'
                                            height='100%'
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-4">
                        <div className="card">
                            <div className="card__header">
                                <h3>top customers</h3>
                            </div>
                            <div className="card__body">
                                <Table
                                    headData={topCustomers.head}
                                    renderHead={(item, index) => renderCusomerHead(item, index)}
                                    bodyData={topCustomers.body}
                                    renderBody={(item, index) => renderCusomerBody(item, index)}
                                />
                            </div>
                            <div className="card__footer">
                                <Link to='/'>view all</Link>
                            </div>
                        </div>
                    </div> */}
                                {/* <div className="col-8">
                        <div className="card">
                            <div className="card__header">
                                <h3>latest orders</h3>
                            </div>
                            <div className="card__body">
                                <Table
                                    headData={latestOrders.header}
                                    renderHead={(item, index) => renderOrderHead(item, index)}
                                    bodyData={latestOrders.body}
                                    renderBody={(item, index) => renderOrderBody(item, index)}
                                />
                            </div>
                            <div className="card__footer">
                                <Link to='/'>view all</Link>
                            </div>
                        </div>
                    </div> */}
                            </div>
                        </div>
                    </>
                    :
                    null
            }
        </>
    )
}

export default AdminDashboard;