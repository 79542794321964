import { useEffect } from 'react';
import './styles/adminDashboardContent.css';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import showToastError from '../helpers/showToastError';
import Form from 'react-bootstrap/Form';
import AdminDashboardDropdown from './AdminDashboardDropdown';
import { adminApiUrls } from './api/api';

function UserContactModal({ userId, isContactModalOpen, onHide }) {
    const params = useParams();
    const [userMessage, setUserMessage] = useState("");

    const adminDashboardUrls = adminApiUrls.adminContent;

    useEffect(() => {
        setUserMessage("")
    }, [isContactModalOpen])

    const sendMessageToUser = () => {
        const adminMessageUrl = adminDashboardUrls.sendMessageUrl(params.id)
        const dataToPost = ({
            receiver: userId,
            message: userMessage,
        })
        axios.post(adminMessageUrl, dataToPost).then((res) => {
            console.log(res);
            showToastError("Message successfully sent to user", "success")
        }).catch((err) => {
            showToastError(`Something went wrong while sending message to user ${err.message}`, "error")
        })
    }

    return (
        <>
            <div className={`fade ban-user-modal ${isContactModalOpen ? 'show' : null}`} tabIndex="-1" style={{ "display": "none" }}>
                <div className="modal-dialog ban-user-modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">Send message to User <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-54-17.5-104T732-676L284-228q42 33 92 50.5T480-160ZM228-284l448-448q-42-33-92-50.5T480-800q-134 0-227 93t-93 227q0 54 17.5 104t50.5 92Z" /></svg></div>
                            <button type="button" className="btn-close" aria-label="Close" onClick={onHide}></button>
                        </div>
                        <div className="report-detail-modal-content modal-body">

                            <Form>
                                <div className="mb-3">
                                    <label className="form-label">Message</label>
                                    <input type="text" className="form-control" onInput={(e) => setUserMessage(e.target.value)} value={userMessage} />
                                </div>
                                <Button variant="primary" type="button" onClick={sendMessageToUser}>
                                    Submit
                                </Button>
                            </Form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={onHide}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function UserBanModal({ showModal, userId, onHide }) {
    const params = useParams();
    const [banUserDataState, setBanUserDataState] = useState({
        additionalComment: "",
        banReason: "",
        banDuration: "",
    })

    const adminDashboardUrls = adminApiUrls.adminContent;

    const banContentCreater = (contentId) => {
        const banUserUrl = adminDashboardUrls.banUserUrl(params.id);
        const dataToPost = ({
            user: userId,
            ban_duration: banUserDataState.banDuration,
            ban_reason: banUserDataState.banReason,
            additional_comment: banUserDataState.additionalComment,
        })
        axios.post(banUserUrl, dataToPost).then((res) => {
            showToastError("User baned successfully", "success");
            onHide();
        }).catch((err) => {
            showToastError(`Something went wrong while banning user, ${err.message}`, "error");
        })
    }

    return (
        <>
            <div className={`fade ban-user-modal ${showModal ? 'show' : null}`} tabIndex="-1" style={{ "display": "none" }}>
                <div className="modal-dialog ban-user-modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">Ban User <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-54-17.5-104T732-676L284-228q42 33 92 50.5T480-160ZM228-284l448-448q-42-33-92-50.5T480-800q-134 0-227 93t-93 227q0 54 17.5 104t50.5 92Z" /></svg></div>
                            <button type="button" className="btn-close" aria-label="Close" onClick={onHide}></button>
                        </div>
                        <div className="report-detail-modal-content modal-body">

                            <Form>
                                <div className="mb-3">
                                    <label className="form-label">Additional comment</label>
                                    <input type="text" className="form-control" onInput={(e) => setBanUserDataState(prevState => ({ ...prevState, additionalComment: e.target.value }))} value={banUserDataState.additionalComment} />
                                    <small value="" className="text-muted form-text">Describe before banning someone.</small>
                                </div>

                                <Form.Group className="mb-3 ban-option">
                                    <Form.Select onChange={(e) => setBanUserDataState(prevState => ({ ...prevState, banReason: e.target.value }))}>
                                        <option>Ban reason</option>
                                        <option value="PEOIC">Posting Explicit or Inappropriate content</option>
                                        <option value="DOSP">Doxing or Sharing personal information</option>
                                        <option value="FOSA">Fraudulent or Spam Activity</option>
                                        <option value="VOIP">Inintentional disruption of community</option>
                                        <option value="IDC">Violtion of Intellectual property</option>
                                        <option value="NCOUSPC">Non concensual or Unauthorized sharing of personal content</option>
                                        <option value="ISR">Impersonating staff representative</option>
                                        <option value="HSOIV">Hate speech or Inciting violence</option>
                                        <option value="VTOS">Violation of terms of service</option>
                                        <option value="PHS">Persistent harrassment stalking</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3 ban-option">
                                    <Form.Select className='border-none' onChange={(e) => setBanUserDataState(prevState => ({ ...prevState, banDuration: e.target.value }))}>
                                        <option>Ban duration</option>
                                        <option value="1M">One month</option>
                                        <option value="3M">Three month</option>
                                        <option value="6M">Six month</option>
                                        <option value="1Y">One year</option>
                                        <option value="3Y">Three year</option>
                                    </Form.Select>
                                </Form.Group>

                                <Button variant="primary" type="button" onClick={banContentCreater}>
                                    Submit
                                </Button>
                            </Form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={onHide}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


function ReportFetchModal({ show, onHide, currentContentData }) {
    const params = useParams();
    const [reportFetchState, setReportFetchState] = useState({
        isReportFetchLoading: false,

        fetchedReports: [],
    })
    const adminDashboardUrls = adminApiUrls.adminContent;

    const fetchContentReports = () => {
        const contentReportFetchUrl = adminDashboardUrls.contentReportFetchUrl(params.id, currentContentData.id, currentContentData.contentType)
        setReportFetchState({
            isReportFetchLoading: true,
        })
        axios.get(contentReportFetchUrl).then((res) => {
            setReportFetchState({
                isReportFetchLoading: false,

                fetchedReports: res.data,
            })
        }).catch((err) => {
            setReportFetchState({
                isReportFetchLoading: false,
            });
            showToastError(`Something went wrong while fetching Report details ${err.message}`, "error")
        })
    }

    useEffect(() => {
        if (show) {
            fetchContentReports();
        }
    }, [currentContentData])

    return (
        <>
            <div className={`fade report-data-modal ${show ? 'show' : null}`} tabIndex="-1" style={{ "display": "none" }}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">Content Report List <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 96 960 960" width="30"><path d="M200 936V256h348.666l18 84H800v380H536l-17.955-83.334H266.666V936H200Zm300-448Zm92 165.333h141.334V406.666H510.667l-18-84H266.666V570H574l18 83.333Z" /></svg></div>
                            <button type="button" className="btn-close" aria-label="Close" onClick={onHide}></button>
                        </div>
                        <div className="report-detail-modal-content modal-body">
                            {
                                reportFetchState.fetchedReports?.length > 0 ?
                                    <>
                                        <ul>
                                            <table>
                                                <thead>
                                                    <tr className='report-data-table'>
                                                        <th>Index</th>
                                                        <th>ID</th>
                                                        <th>R-1</th>
                                                        <th>R-2</th>
                                                        <th>Impact type</th>
                                                        <th>How effecting</th>
                                                        <th>Explanation</th>
                                                        <th><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25"><path d="M448.667-280h66.666v-240h-66.666v240Zm31.321-316q15.012 0 25.179-9.966 10.166-9.967 10.166-24.7 0-15.3-10.155-25.65-10.155-10.35-25.166-10.35-15.012 0-25.179 10.35-10.166 10.35-10.166 25.65 0 14.733 10.155 24.7Q464.977-596 479.988-596Zm.189 516q-82.822 0-155.666-31.5t-127.178-85.833Q143-251.667 111.5-324.56 80-397.454 80-480.333q0-82.88 31.5-155.773Q143-709 197.333-763q54.334-54 127.227-85.5Q397.454-880 480.333-880q82.88 0 155.773 31.5Q709-817 763-763t85.5 127Q880-563 880-480.177q0 82.822-31.5 155.666T763-197.456q-54 54.21-127 85.833Q563-80 480.177-80Zm.156-66.666q139 0 236.001-97.334 97-97.333 97-236.333t-96.875-236.001q-96.876-97-236.459-97-138.667 0-236 96.875Q146.666-619.583 146.666-480q0 138.667 97.334 236 97.333 97.334 236.333 97.334ZM480-480Z" /></svg></th>
                                                        <th>Review</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='report-data-table'>
                                                    {
                                                        reportFetchState.fetchedReports?.map((report, index) =>
                                                            <>
                                                                <td>{index + 1}</td>
                                                                <td>{report.id}</td>
                                                                <td>{report.reported_main_reason}</td>
                                                                <td>{report.reported_second_step}</td>
                                                                <td>{report.impact_type === "WHLCMUNTY" ? "Whole Community" : "Targeted me"}</td>
                                                                <td>{report.how_effecting_or_why_targeted}</td>
                                                                <td>{report.reported_third_step_detail}</td>
                                                                <td><a title='Go to Admin Refrence' href={`${adminApiUrls.defaultAdminSiteOriginBackend + `report/report/${report.id}/change/`}`} target='_blank'><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M680-280q25 0 42.5-17.5T740-340q0-25-17.5-42.5T680-400q-25 0-42.5 17.5T620-340q0 25 17.5 42.5T680-280Zm0 120q31 0 57-14.5t42-38.5q-22-13-47-20t-52-7q-27 0-52 7t-47 20q16 24 42 38.5t57 14.5ZM480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v227q-19-8-39-14.5t-41-9.5v-147l-240-90-240 90v188q0 47 12.5 94t35 89.5Q310-290 342-254t71 60q11 32 29 61t41 52q-1 0-1.5.5t-1.5.5Zm200 0q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80ZM480-494Z" /></svg></a></td>
                                                                <td><svg title="Link to Report Reviewing system" className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25"><path d="M186.666-120q-27.5 0-47.083-19.583T120-186.666v-586.668q0-27.5 19.583-47.083T186.666-840h586.668q27.5 0 47.083 19.583T840-773.334v586.668q0 27.5-19.583 47.083T773.334-120H186.666Zm0-66.666h586.668v-506.668H186.666v506.668Zm293.361-96.667q-80.694 0-144.194-43.601-63.5-43.601-92.5-113.167 29-69.566 92.473-113.066t144.167-43.5q80.694 0 144.194 43.601 63.5 43.601 92.5 113.167-29 69.566-92.473 113.066t-144.167 43.5ZM480-336.667q56.667 0 103.966-27.383Q631.266-391.433 658-440q-26.734-48.567-74.034-75.95Q536.667-543.333 480-543.333q-56.667 0-103.966 27.383Q328.734-488.567 302-440q26.734 48.567 74.034 75.95Q423.333-336.667 480-336.667ZM480-440Zm.078 53.333q22.255 0 37.755-15.578 15.5-15.579 15.5-37.833 0-22.255-15.578-37.755-15.579-15.5-37.833-15.5-22.255 0-37.755 15.578-15.5 15.579-15.5 37.833 0 22.255 15.578 37.755 15.579 15.5 37.833 15.5Z" /></svg></td>
                                                            </>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </ul>
                                    </>
                                    :
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M480.177-80q-82.822 0-155.666-31.5t-127.178-85.833Q143-251.667 111.5-324.56 80-397.454 80-480.333q0-82.88 31.5-155.773Q143-709 197.333-763q54.334-54 127.227-85.5Q397.454-880 480.333-880q82.88 0 155.773 31.5Q709-817 763-763t85.5 127Q880-563 880-480.177q0 82.822-31.5 155.666T763-197.456q-54 54.21-127 85.833Q563-80 480.177-80Zm.156-66.666q139 0 236.001-97.334 97-97.333 97-236.333t-96.875-236.001q-96.876-97-236.459-97-138.667 0-236 96.875Q146.666-619.583 146.666-480q0 138.667 97.334 236 97.333 97.334 236.333 97.334ZM480-480Zm-50 208.667h100q27.5 0 47.083-19.583 19.584-19.584 19.584-47.083v-284.668q0-27.5-19.584-47.083Q557.5-689.334 530-689.334H430q-27.5 0-47.083 19.584-19.584 19.583-19.584 47.083v284.668q0 27.499 19.584 47.083Q402.5-271.333 430-271.333Zm0-351.334h100v284.668H430v-284.668Z" /></svg>
                                        <div></div>No reports have been made against this content
                                    </>
                            }



                        </div>
                        <div className="modal-footer">
                            {
                                reportFetchState.isReportFetchLoading ? <>Loading...</> : null
                            }
                            <button type="button" className="btn btn-primary" onClick={onHide}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <table>
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>ID</th>
                        <th>Impact type</th>
                        <th>How effecting?</th>
                        <th>R-1</th>
                        <th>R-2</th>
                    </tr>
                </thead>
                <tbody>
                    <td>{5 + 1}</td>
                    <td>{5 + 1}</td>
                    <td>how_effecting_or_why_targeted</td>
                    <td>how_effecting_or_why_targeted</td>
                    <td>reported_third_step_detail</td>
                    <td>reported_third_step_detail</td>
                </tbody>
            </table> */}

        </>
    );
}


function ActionDropdownBar({ userId, contentId, additionalContentId, additionalContentId2, contentType, openedActionBarDropdown, openActionDropdown }) {
    const params = useParams();
    const [originalUrl, setOriginalUrl] = useState("");
    const [adminDetailUrl, setAdminDetailUrl] = useState("");

    const [showReportFetchModal, setShowReportFetchModal] = useState(false);
    const [currentContentData, setCurrentContentData] = useState({
        id: contentId,
        contentType: contentType
    })
    const [openUserBanModal, setOpenUserBanModal] = useState(false);
    const [isContactModalOpen, setContactModalOpen] = useState(false);

    const adminDashboardUrls = adminApiUrls.adminContent;

    const originalRedirectUrl = {
        upshotDetailUrl: (contentId) => `/api/content/main/upshot/${contentId}/`,
        remarkUpShotDetailUrl: (contentId, additionalContentId) => `/api/content/main/upshot/${contentId}/?remark_id=${additionalContentId}`,
        commentRemarkUpShotUrl: (contentId, additionalContentId, additionalContentId2) => `/api/content/main/upshot/${contentId}/?remark_id=${additionalContentId}&comment_id=${additionalContentId2}`,
    }

    const adminRedirectUrl = {
        upshotAdminDetailUrl: (contentId) => adminApiUrls.defaultAdminSiteOriginBackend + `whatwouldhappen/upshot/${contentId}/change`,
        remarkAdminDetailUrl: (contentId) => adminApiUrls.defaultAdminSiteOriginBackend + `whatwouldhappen/remark/${contentId}/change`,
        commentDetailUrl: (contentId) => adminApiUrls.defaultAdminSiteOriginBackend + `whatwouldhappen/remarkcomment/${contentId}/change`
    }

    useEffect(() => {
        if (contentType === "upshot") {
            setOriginalUrl(originalRedirectUrl.upshotDetailUrl(contentId));
            setAdminDetailUrl(adminRedirectUrl.upshotAdminDetailUrl(contentId));
        } else if (contentType === "remark") {
            setOriginalUrl(originalRedirectUrl.upshotDetailUrl(contentId, additionalContentId));
            setAdminDetailUrl(adminRedirectUrl.remarkAdminDetailUrl(contentId));
        } else if (contentType === "comment") {
            setOriginalUrl(originalRedirectUrl.commentRemarkUpShotUrl(additionalContentId2, additionalContentId, contentId));
            setAdminDetailUrl(adminRedirectUrl.commentDetailUrl(contentId));
        }
    }, [contentId])

    // ? COMPLETED
    const hideContent = () => {
        const hideContentUrl = adminDashboardUrls.hideContentUrl(params.id, contentId, contentType);
        axios.put(`/dashboard/action/hide/${contentId}/?key=${params.id}&contentType=${contentType}`).then((res) => {
            showToastError("Content hidden successfully", "success");
        }).catch((err) => {
            showToastError(`Something went wrong while hiding the content, ${err.message}`, "error");
        })
    }
    // ? COMPLETED
    const openReportFetchModal = () => {
        setShowReportFetchModal(true);
        setCurrentContentData({
            id: contentId,
            contentType: contentType
        })
    }
    // ? COMPLETED
    const deleteContent = () => {
        const deleteContentUrl = adminDashboardUrls.deleteContentUrl(params.id, contentId, contentType);
        axios.put(deleteContentUrl).then((res) => {
            showToastError("Content deleted successfully", "success")
        }).catch((err) => {
            showToastError(`Something went wrong while deleting content, ${err.message}`, "error")
        })
    }

    return (
        <>
            <div className={`admin-user-dropdown ${openedActionBarDropdown[contentId] ? `svg-active` : `display-none`}`}>
                {/* WORKING */}
                <a href={originalUrl} target='_blank'>Go to Original Page</a>
                {/* WORKING */}
                <a href={adminDetailUrl} target='_blank'><small>Go to content Admin Page</small></a>
                {/* WORKING */}
                <a href="#" className='light-red-bg-on-hover' onClick={hideContent}>Hide this Content</a>
                {/* WORKING */}
                <a href="#" className="blue-background-on-hover" onClick={openReportFetchModal}>Reports</a>
                {/* <a href="#">{user.last_login_naturaltime}(Last Login)</a> */}
                <hr />
                {/* WORKING */}
                <a href="#" onClick={() => setContactModalOpen(true)}>Contact</a>
                {/* WORKING */}
                <a href="#" className="red-bg-on-hover" onClick={deleteContent}>Delete</a>
                {/* WORKING */}
                <a href="#" className="red-bg-on-hover" onClick={() => setOpenUserBanModal(true)}>Ban content creater</a>
            </div>
            <ReportFetchModal
                show={showReportFetchModal}
                onHide={() => setShowReportFetchModal(false)}
                currentContentData={currentContentData}
            />
            <UserBanModal
                showModal={openUserBanModal}
                userId={userId}
                onHide={() => setOpenUserBanModal(false)}
            />
            <UserContactModal
                userId={userId}
                isContactModalOpen={isContactModalOpen}
                onHide={() => setContactModalOpen(false)}
            />
        </>
    )
}

function DashboardAdminContent() {
    const params = useParams();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedSectionType, setSelectedSectionType] = useState("");

    const [isFurtherAvailable, setIsFurtherAvailable] = useState(true);
    const [isBackAvailable, setIsBackAvailable] = useState(false);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [dataFetchState, setDataFetchState] = useState({
        isFetchLoading: false,
        fetchError: "",

        fetchedData: [],
    })
    const [contentCountState, setContentCountState] = useState({
        upshotsCount: 0,
        remarksCount: 0,
        commentsCount: 0,
    })
    const [openedActionBarDropdown, setOpenedActionBarDropdown] = useState(new Array(0).fill(false));

    const adminDashboardUrls = adminApiUrls.adminContent;


    const updateSectionTypeParams = {
        sectionType: selectedSectionType
    };
    const sectionTypeOptions = {
        pathname: `/admin/dashboard/content/${params.id}/`,
        search: `?page=1&${createSearchParams(updateSectionTypeParams)}`
    };
    const updateSectionType = (sectionType) => {
        fetchSection(sectionType, 1);
        setCurrentPage(1);
        navigate(sectionTypeOptions, { replace: true })
    }

    useEffect(() => {
        updateSectionType(selectedSectionType);
    }, [selectedSectionType])

    const fetchSection = (sectionType, pageNumber) => {
        const fetchContentSection = adminDashboardUrls.fetchSectionContentUrl(params.id, sectionType, pageNumber)

        axios.get(fetchContentSection).then((res) => {
            if (res.data.next !== null) {
                setIsFurtherAvailable(true);
            } else {
                setIsFurtherAvailable(false);
            }
            if (res.data.previous !== null) {
                setIsBackAvailable(true);
            } else {
                setIsBackAvailable(false);
            }

            setContentCountState({
                upshotsCount: res.data.results.upshots_count,
                remarksCount: res.data.results.remarks_count,
                commentsCount: res.data.results.comments_count
            })

            setDataFetchState(prevState => ({
                ...prevState,
                fetchedData: res.data,
            }))
        }).catch((err) => {
            showToastError(`Something went wrong while fetching content, ${err.message}`, "error")
        })
    }

    // ! Content next page - START
    const urlNextParams = {
        page: currentPage + 1,
    };
    const nextOptions = {
        pathname: `/admin/dashboard/content/${params.id}/`,
        search: `?${createSearchParams(urlNextParams)}&sectionType=${selectedSectionType === undefined ? "upshots" : selectedSectionType}`
    }
    const goToNextPage = () => {
        setCurrentPage(currentPage + 1)
        fetchSection(selectedSectionType, currentPage + 1)
        navigate(nextOptions, { replace: true });
    }
    // ! Content next page - END

    // ! Content next page - START
    const urlPrevParams = {
        page: currentPage - 1,
    };
    const PrevOptions = {
        pathname: `/admin/dashboard/content/${params.id}/`,
        search: `?${createSearchParams(urlPrevParams)}&sectionType=${selectedSectionType === undefined ? "upshots" : selectedSectionType}`
    }
    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1)
        fetchSection(selectedSectionType, currentPage - 1)
        navigate(PrevOptions, { replace: true });
    }

    useEffect(() => {
        const currentPageFromUrl = searchParams.get("page");
        const sectionType = searchParams.get("sectionType");
        setSelectedSectionType(sectionType === undefined ? "upshots" : sectionType);
        fetchSection(sectionType, currentPageFromUrl);
    }, [])

    const openActionDropdown = (contentId) => {
        const user = [...openedActionBarDropdown];
        const updatedOpenMenu = openedActionBarDropdown.map(() => false);
        updatedOpenMenu[contentId] = !user[contentId];
        setOpenedActionBarDropdown(updatedOpenMenu)
    }


    return (
        <>
            <AdminDashboardDropdown
                setIsDropdownOpen={setIsDropdownOpen}
                isDropdownOpen={isDropdownOpen}
            />
            <div className="container">
                <div className={`section ${selectedSectionType === "upshots" ? `active-section` : null}`} onClick={() => setSelectedSectionType("upshots")}>
                    <h2>UpShots</h2>
                    <p>{contentCountState.upshotsCount}</p>
                </div>

                <div className={`section ${selectedSectionType === "remarks" ? `active-section` : null}`} onClick={() => setSelectedSectionType("remarks")}>
                    <h2>Remarks</h2>
                    <p>{contentCountState.remarksCount}</p>
                </div>

                <div className={`section ${selectedSectionType === "comments" ? `active-section` : null}`} onClick={() => setSelectedSectionType("comments")}>
                    <h2>Comments</h2>
                    <p>{contentCountState.commentsCount}</p>
                </div>
            </div>

            <div className='table-header'>{selectedSectionType}</div>

            <table>
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Date Published</th>
                        <th>Image</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        dataFetchState.fetchedData.results?.results?.map((content, index) =>
                            <tr className='text-clr-white'>
                                {
                                    selectedSectionType === "upshots" ?
                                        <>
                                            <td>{index + 1}</td>
                                            <td>{content.id}</td>
                                            <td>{content.title} <small>({content.id})</small></td>
                                            <td>{content.user_username}</td>
                                            <td title={content.date_published}>{content.natural_time}</td>
                                            <td>{content.title}</td>
                                            <td>
                                                <svg className='fill-svg-white cursor-pointer' onClick={() => openActionDropdown(content.id)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 896q-33 0-56.5-23.5T400 816q0-33 23.5-56.5T480 736q33 0 56.5 23.5T560 816q0 33-23.5 56.5T480 896Zm0-240q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm0-240q-33 0-56.5-23.5T400 336q0-33 23.5-56.5T480 256q33 0 56.5 23.5T560 336q0 33-23.5 56.5T480 416Z" /></svg>
                                                <ActionDropdownBar
                                                    userId={content.user}
                                                    contentId={content.id}
                                                    contentType={"upshot"}
                                                    openedActionBarDropdown={openedActionBarDropdown}
                                                />
                                            </td>
                                        </>
                                        :
                                        <>
                                            {
                                                selectedSectionType === "remarks" ?
                                                    <>
                                                        <td>{index + 1}</td>
                                                        <td>{content.id}</td>
                                                        <td>{content.content}</td>
                                                        <td>{content.user_username}</td>
                                                        <td title={content.remark_timestamp}>{content.remark_timestamp}</td>
                                                        <td>{content.title}</td>
                                                        <td>
                                                            <svg className='fill-svg-white cursor-pointer' onClick={() => openActionDropdown(content.id)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 896q-33 0-56.5-23.5T400 816q0-33 23.5-56.5T480 736q33 0 56.5 23.5T560 816q0 33-23.5 56.5T480 896Zm0-240q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm0-240q-33 0-56.5-23.5T400 336q0-33 23.5-56.5T480 256q33 0 56.5 23.5T560 336q0 33-23.5 56.5T480 416Z" /></svg>
                                                            <ActionDropdownBar
                                                                userId={content.remarked_by}
                                                                contentId={content.id}
                                                                additionalContentId={content.upshot}
                                                                contentType={"remark"}
                                                                openedActionBarDropdown={openedActionBarDropdown}
                                                            />
                                                        </td>
                                                    </>
                                                    :
                                                    <>
                                                        <td>{index + 1}</td>
                                                        <td>{content.id}</td>
                                                        <td>{content.comment_body}</td>
                                                        <td>{content.commented_by_username}</td>
                                                        <td title={content.comment_creation_timestamp}>{content.comment_creation_timestamp}</td>
                                                        <td>{content.title}</td>
                                                        <td>
                                                            <svg className='fill-svg-white cursor-pointer' onClick={() => openActionDropdown(content.id)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 896q-33 0-56.5-23.5T400 816q0-33 23.5-56.5T480 736q33 0 56.5 23.5T560 816q0 33-23.5 56.5T480 896Zm0-240q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm0-240q-33 0-56.5-23.5T400 336q0-33 23.5-56.5T480 256q33 0 56.5 23.5T560 336q0 33-23.5 56.5T480 416Z" /></svg>
                                                            <ActionDropdownBar
                                                                userId={content.comment_by}
                                                                contentId={content.id}
                                                                additionalContentId={content.remark_comment}
                                                                additionalContentId2={content.comment_upshot_id}
                                                                contentType={"comment"}
                                                                openedActionBarDropdown={openedActionBarDropdown}
                                                                openActionDropdown={openActionDropdown}
                                                            />
                                                        </td>
                                                    </>
                                            }
                                        </>
                                }
                                <br />
                            </tr>
                        )
                    }

                </tbody>
            </table>
            <div className="pagination-contet">
                {
                    isFurtherAvailable &&
                    <button id="nextButton" onClick={goToNextPage}>Next &gt;</button>
                }
                <br />
                {
                    isBackAvailable &&
                    <button id="prevButton" onClick={goToPreviousPage}>&lt; Previous</button>
                }
            </div>
        </>
    )
}

export default DashboardAdminContent;