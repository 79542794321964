import '@mantine/core/styles.css';
import React, { useEffect, useState, useContext } from 'react';
import { Modal, Button, NativeSelect, Group } from "@mantine/core";
import { Input } from '@mantine/core';

import ThemeContext from "../appearance/ThemeContext";

function WatchLaterListCreationModal({
    t,
    opened,
    close,
    watchLaterTitle,
    setWatchLaterTitle,
    titleError,
    setVisibilityChoice,
    visibilityChoice,
    runTheseTwoOnClick,
    isPostLoading,
    postError,
}) {
    const { theme } = useContext(ThemeContext);

    const [randomTitle, setRandomTitle] = useState("");
    const titles = [
        "Nightmare Chronicles",
        "Tales of Terror",
        "Horror Vault",
        "Dreadful Delights",
        "The Haunting Collection",
        "Creepy Classics",
        "Adrenaline Adventures",
        "Adventure Chronicles",
        "Tales of Brave Explorers",
        "Noir Mysteries",
        "Crime Thrillers",
        "The Lawless Chronicles",
        "The Dark Underbelly",
        "Passionate Love Affairs",
        "Enchanting Love Journeys",
        "Romantic Classics",
        "Whispered Promises",
        "Amour Amore",
        "Love Stories Unveiled",
    ];

    // *Choose a random placeholder title on page load.
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * titles.length);
        setRandomTitle(titles[randomIndex]);
    }, [opened])

    const dataOptions = ['Public', 'Private', 'Only my Audience'].map(option => t(option));

    return (
        <Modal size="lg" opened={opened} onClose={close} title="Create Watch Later" centered>
            <Input.Wrapper label={t("Watch Later list name")} error={titleError}>
                <Input placeholder={t(randomTitle)} classNames={{ input: "placeholder-clr-light-white" }} value={watchLaterTitle} onInput={(e) => setWatchLaterTitle(e.target.value)} />
            </Input.Wrapper>

            <NativeSelect
                mt="md"
                label={t("Watch Later Visibility")}
                data={dataOptions}
                description={t("Who can view your Watch Later List?")}
                value={visibilityChoice}
                onChange={(e) => setVisibilityChoice(e.target.value)}
            />
            {postError}
            <Group justify="right" mt="xl">
                <Button variant="outline" color="red" onClick={close}>
                    {t("Discard")}
                </Button>
                <Button variant="outline" color="#9e73a6" loading={isPostLoading} onClick={runTheseTwoOnClick}>
                    {t("Save")}
                </Button>
            </Group>
        </Modal>
    )
}

export default WatchLaterListCreationModal;