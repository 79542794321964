import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Identicon from 'react-identicons';

import "./search-user-modal.css";
import LoadingLogo from '../animations/LoadingLogo';
import CorrectUnFill from '../upshot/icons/svgComponents/CorrectUnFill';
import CorrectFill from "../upshot/icons/svgComponents/CorrectFill";
import Loading from "../upshot/icons/svgComponents/Loading";
import pluralize from "../helpers/pluralize";
import CorrectErrorIcon from "../upshot/icons/svgComponents/CorrectError";
import textTrimmer from "../helpers/stringCutter";
import showToastError from "../helpers/showToastError";

function SearchUserModal({ isOpen, close, sharedItemType, contentData, contentId, contentName, wtVisibility }) {
    const { t } = useTranslation();
    const [searchState, setSearchState] = useState({
        currentSearchValue: "",
        fetchedSearchedUsers: [],

        isUserFetchLoading: false,
        userFetchError: "",
    })
    const [userContentPostState, setUserContentPostState] = useState({
        whichLoading: [],
        sentTo: [],
        successfullySents: [],
        failedSents: [],
    })

    const searchOnInput = (evt) => {
        var currentSearchVal = ""
        if (evt !== "") {
            currentSearchVal = evt.target.value
            setSearchState(prevState => ({
                ...prevState,
                currentSearchValue: evt.target.value,
            }));
        } else {
            currentSearchVal = ''
            setSearchState(prevState => ({
                ...prevState,
                currentSearchValue: "",
            }));
        }
        setSearchState(prevState => ({
            ...prevState,
            isUserFetchLoading: true,
            userFetchError: "",
        }));
        axios.get("/api/search/search-users/", { params: { username: currentSearchVal } }).then((res) => {
            console.log(res);
            res.data?.map((d) => {
                console.log(d.username)
            })
            setSearchState(prevState => ({
                ...prevState,
                fetchedSearchedUsers: res.data,

                isUserFetchLoading: false,
                userFetchError: "",
            }));
        }).catch(() => {
            setSearchState({
                isUserFetchLoading: false,
                userFetchError: "Something went Wrong!",
            })
        })
    }

    useEffect(() => {
        if (isOpen) {
            searchOnInput("");
        }
    }, [isOpen])

    const actionOnClick = (receiverUsername) => {
        setUserContentPostState(prevState => ({
            ...prevState,
            whichLoading: [...prevState.whichLoading, receiverUsername]
        }))
        axios.post(`/api/content/management/share-item/${contentId}/${receiverUsername}/`).then((res) => {
            if (res.data.add_message) {
                showToastError(res.data.add_message, "warning");
            }
            setUserContentPostState(prevState => ({
                ...prevState,
                whichLoading: prevState.whichLoading.filter(existingId => existingId !== receiverUsername),
                sentTo: [...prevState.sentTo, receiverUsername],
                // ? Implement this on success
                successfullySents: [...prevState.successfullySents, receiverUsername],
                failedSents: prevState.failedSents.filter(existingId => existingId !== receiverUsername)
            }))
        }).catch(() => {
            setUserContentPostState(prevState => ({
                ...prevState,
                whichLoading: prevState.whichLoading.filter(existingId => existingId !== receiverUsername),
                failedSents: [...prevState.failedSents, receiverUsername]
            }))
        })
    }

    return (
        <div className={`search-modal ${isOpen ? "show" : "hide"}`}>
            <div className="search-modal-content">
                <div className="search-modal-search">
                    <span className="wt-search-close-btn" onClick={close}>&times;</span>
                    <div className="search-bar">
                        <input type="text" placeholder="Search..." onInput={searchOnInput} />
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                            <path
                                d="M765-144 526-383q-30 22-65.792 34.5T384.035-336Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.035q0 40.381-12.5 76.173T577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z" />
                        </svg>
                    </div>
                </div>
                <div className="search-modal-body">
                    {
                        wtVisibility === "R" ?
                            <div className='current-sharing-content-data clr-white font-size-15-px'>{textTrimmer(contentName, 10)}<span className='clr-red font-size-13--5-px'> is currently set to private. If you decide to share it with others, it will automatically become public.</span></div>
                            :
                            <>
                                {
                                    wtVisibility === "AO" &&
                                    <div className='current-sharing-content-data clr-red font-size-13--5-px'>Only Audience can view <span className='clr-white font-size-15-px'>{textTrimmer(contentName, 10)}</span>. If you decide to share it with users which are not in your Audience, it will automatically become public.</div>
                                }
                            </>
                    }
                    {
                        searchState.currentSearchValue === "" ?
                            <span className="recent-title">{t("Recent users")}</span>
                            :
                            <>
                                {
                                    searchState?.fetchedSearchedUsers?.length <= 0 ?
                                        <span className='recent-title'>{t("No users Found")}</span>
                                        :
                                        <span className="recent-title">{searchState.fetchedSearchedUsers?.length} user{pluralize(searchState.fetchedSearchedUsers?.length)} {t("found")}</span>
                                }
                            </>
                    }
                    <ul className="searched-user-list">
                        {searchState.isUserFetchLoading ?
                            <div><LoadingLogo type="processing" moveToLeft="50%" /></div>
                            :
                            <>
                                {searchState.userFetchError ?
                                    <div className='error-msg'>
                                        <span>{searchState.userFetchError}</span>
                                    </div>
                                    :
                                    <>
                                        {
                                            searchState.fetchedSearchedUsers?.map((username) =>
                                                <SearchedUsersList
                                                    username={username.username[0]}
                                                    fullName={username.username[2]}
                                                    userProfileImage={username.username[1]}
                                                    actionOnClick={actionOnClick}
                                                    userContentPostState={userContentPostState}
                                                />
                                            )
                                        }
                                    </>
                                }
                            </>
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

function SearchedUsersList({ username, fullName, userProfileImage, actionOnClick, userContentPostState }) {

    return (
        <li key={username}>
            {
                userProfileImage ?
                    <img src={`https://spreeproductionbucket.s3.amazonaws.com/media/${userProfileImage}`} alt="User 1"
                        className="searched-user-image" />
                    :
                    <Identicon string={username} size="25" />
            }
            <div className="searched-user-info">
                {
                    fullName ?
                        <>
                            <span className="searched-user-name">{fullName} </span>
                            <span className="user-username"> <span className='at-symbol'> @</span>{username}</span>
                        </>
                        :
                        <span className="user-username"><span className='at-symbol'>@</span>{username}</span>
                }
            </div>
            <div className='user-actions'>
                {
                    userContentPostState.whichLoading.includes(username) ?
                        <p className='large-svg retry-rotate'><Loading /></p>
                        :
                        <>
                            {
                                userContentPostState.failedSents.includes(username) ?
                                    <p className='large-svg' onClick={() => actionOnClick(username)}><CorrectErrorIcon /></p>
                                    :
                                    <>
                                        {
                                            userContentPostState.sentTo.includes(username) ?
                                                <p className='large-svg'><CorrectFill /></p>
                                                :
                                                <p className='large-svg' onClick={() => actionOnClick(username)}><CorrectUnFill /></p>
                                        }
                                    </>
                            }
                        </>
                }
            </div>
        </li>
    )
}

export default SearchUserModal;