import axios from "axios";
import { useEffect, useState } from "react";

import Replied from "../../../svgComponents/Replied";
import BigTextField from "../../HTMLComponents/BigTextField";
import CustomButton from "../../HTMLComponents/Button";
import showToastError from "../../helpers/showToastError";
import ConfirmationDialog from "../../modals/ConfirmationModal";
import RemarkContextMenu from "../RemarkContextMenu";
import UserOnPremiumBadgeIcon from "../../Profile/icons/UserOnPremiumBadgeIcon";

function UpShotRemarkCommentList({ t, comment, remarkId, handleDoubleClick, setRemarkCommentFetchState, openReportModal, openShareModal, addMeInUserAudience, upshotId, fetchRemarkComments, isForComment, commentHighlightId }) {
    const [remarkCommentState, setRemarkCommentState] = useState({
        remarkCommentReply: "",
        isReplyLoading: false,
        replyPostError: "",

        remarkCommentReplyCommentId: 0,
        isRemarkCommentReplyFieldOpen: false,
    });
    const [highlightedId, setHighlightedId] = useState(0);
    const [commonRemarkCommentReplyState, setCommonRemarkCommentReplyState] = useState({
        isMainCommentReplyHighlighted: false,
        highLightedCommentReplyId: 0,
    });
    const [remarkCommentDeleteState, setRemarkCommentDeleteState] = useState({
        isRemarkCommentDeletionDialogOpen: false,
        currentDeletionId: 0,

        isDeleteLoading: false,
        deleteError: "",
    });

    const deleteRemarkCommentConfirmationDialog = (commentId) => {
        setRemarkCommentDeleteState({
            isRemarkCommentDeletionDialogOpen: true,
            currentDeletionId: commentId
        })
    }

    const deleteRemarkComment = () => {
        axios.delete(`/api/content/main/c/u/d/g/remark-comment/${remarkCommentDeleteState.currentDeletionId}`).then(() => {
            setRemarkCommentFetchState(prevState => ({
                ...prevState,
                fetchRemarkComments: fetchRemarkComments.filter(comment => comment.id !== remarkCommentDeleteState.currentDeletionId)
            }));
            setRemarkCommentDeleteState({
                isRemarkCommentDeletionDialogOpen: false,
                currentDeletionId: 0
            })
            showToastError("Comment deleted successfully", "success")
        }).catch(() => {
            showToastError("Something went wrong while deleting comment", "error")
        })
    };

    const scrollToMainCommentOnReplyClick = (mainCommentId) => {
        console.log(mainCommentId)
        // const targetComment = document.getElementById(mainCommentId);
        // if (targetComment) {
        //     targetComment.scrollIntoView({
        //         behavior: 'smooth',
        //         block: 'center',
        //         inline: 'center',
        //     });
        // }
        setTimeout(() => {
            setCommonRemarkCommentReplyState({ isMainCommentReplyHighlighted: false, highLightedCommentReplyId: 0 })
        }, 10000)
    }

    useEffect(() => {
        console.log(commonRemarkCommentReplyState)
    }, [commonRemarkCommentReplyState])

    const jumpToMainComment = (mainCommentId) => {
        console.log(mainCommentId)
        setHighlightedId(mainCommentId)
        scrollToMainCommentOnReplyClick(mainCommentId);
        setCommonRemarkCommentReplyState(prevState => ({
            ...prevState,
            isMainCommentReplyHighlighted: true,
            highLightedCommentReplyId: mainCommentId
        }))
    }

    const handleDislike = (id) => {
        setRemarkCommentFetchState(prevState => ({
            ...prevState,
            fetchRemarkComments: prevState.fetchRemarkComments.map(comment => {
                if (comment.id === id) {
                    return {
                        ...comment,
                        if_is_comment_liked: false,
                        if_is_comment_disliked: true,
                        comment_dislikes_count: comment.comment_dislikes_count + 1,
                        comment_likes_count: comment.comment_likes_count > 0 ? comment.comment_likes_count - 1 : comment.comment_likes_count
                    };
                }
                return comment
            })
        }))
    }
    const handleLike = (id) => {
        setRemarkCommentFetchState(prevState => ({
            ...prevState,
            fetchRemarkComments: prevState.fetchRemarkComments.map(comment => {
                if (comment.id === id) {
                    return {
                        ...comment,
                        if_is_comment_liked: true,
                        if_is_comment_disliked: false,
                        comment_likes_count: comment.comment_likes_count + 1,
                        comment_dislikes_count: comment.comment_dislikes_count > 0 ? comment.comment_dislikes_count - 1 : comment.comment_dislikes_count
                    };
                }
                return comment
            })
        }))
    }
    const handleUnLike = (id) => {
        setRemarkCommentFetchState(prevState => ({
            ...prevState,
            fetchRemarkComments: prevState.fetchRemarkComments.map(comment => {
                if (comment.id === id) {
                    return {
                        ...comment,
                        if_is_comment_liked: false,
                        if_is_comment_disliked: false,
                        comment_likes_count: comment.comment_likes_count - 1,
                    };
                }
                return comment
            })
        }))
    }
    const handleUnDisLike = (id) => {
        setRemarkCommentFetchState(prevState => ({
            ...prevState,
            fetchRemarkComments: prevState.fetchRemarkComments.map(comment => {
                if (comment.id === id) {
                    return {
                        ...comment,
                        if_is_comment_liked: false,
                        if_is_comment_disliked: false,
                        comment_dislikes_count: comment.comment_dislikes_count - 1
                    };
                }
                return comment
            })
        }))
    }

    const handleLikeUnlikeRemarkComment = (commentId, actionType) => {
        if (actionType === "LIKE") {
            handleLike(commentId);
        } else if (actionType === "DISLIKE") {
            handleDislike(commentId);
        } else if (actionType === "UNLIKE") {
            handleUnLike(commentId)
        } else if (actionType === "UNDISLIKE") {
            handleUnDisLike(commentId)
        }
        const dataToSend = ({
            action_type: actionType
        })
        axios.post(`/api/content/main/remark-comment-like-dislike/${commentId}/`, dataToSend).then(() => {
        }).catch((err) => {
            if (err.response.status === 401) {
                showToastError("You're Banned. You cannot like/dislike Comments.", "error")
            }
        })
    }

    const openRemarkCommentReply = (commentId) => {
        setRemarkCommentState({
            remarkCommentReplyCommentId: commentId,
            isRemarkCommentReplyFieldOpen: true,
        })
    }
    const closeRemarkCommentReply = () => {
        setRemarkCommentState({
            remarkCommentReplyCommentId: 0,
            isRemarkCommentReplyFieldOpen: false,
            remarkCommentReply: "",
        })
    }
    const updateRemarkCommentReplyValue = (event) => {
        setRemarkCommentState(prevState => ({
            ...prevState,
            remarkCommentReply: event.target.value
        }))
    }
    const postCommentReply = (remarkId, commentId) => {
        const { remarkCommentReply } = remarkCommentState;
        if (remarkCommentReply !== "") {
            setRemarkCommentState({
                isReplyLoading: true,
                replyPostError: "",
            })
            const dateToPost = ({
                comment_body: remarkCommentReply,
                remark_comment: remarkId,
                reply_for: commentId,
                is_reply: true,
            })
            axios.post(`/api/content/main/remark-comment-reply/${commentId}/`, dateToPost).then((res) => {
                setRemarkCommentState({
                    isReplyLoading: false,
                    replyPostError: "",
                    remarkCommentReplyCommentId: 0,
                    isRemarkCommentReplyFieldOpen: false,
                    remarkCommentReply: "",
                })
                setRemarkCommentFetchState(prevState => {
                    const targetIndex = prevState.fetchRemarkComments.findIndex(comment => comment.id === commentId);

                    if (targetIndex === -1) {
                        return {
                            fetchRemarkComments: [res.data, ...fetchRemarkComments]
                        };
                    }

                    const updatedComments = [
                        ...fetchRemarkComments.slice(0, targetIndex + 1),
                        res.data,
                        ...fetchRemarkComments.slice(targetIndex + 1)
                    ];

                    return { fetchRemarkComments: updatedComments }
                })
            }).catch(() => {
                setRemarkCommentState({
                    isReplyLoading: false,
                    replyPostError: "Something went Wrong",
                })
            })
        }
    }

    return (
        <>
            <div className="comment-trackline-vertical-line" key={comment.id}></div>
            <div
                id={comment.id}
                onClick={(event) => handleDoubleClick(event, comment.id, "comment")}
                className={`reply ${parseInt(commentHighlightId) === parseInt(comment.id) && "highlighted-main-content"} ${parseInt(highlightedId) === parseInt(comment.id) && "highlighted-main-content"}`}>
                <img className="reply-avatar"
                    src={comment.user_profile_image} alt='' />
                <div className="reply-body">
                    <div className="comment-header">
                        <span className="comment-author">
                            {comment.commented_by_full_name ? <>{comment.commented_by_full_name}
                                <small> @{comment.commented_by_username}</small></> : <>@{comment.commented_by_username}</>}
                            {
                                comment.show_premium_badge_on_profile ?
                                    <span className='premium-profile-badge'><UserOnPremiumBadgeIcon /></span>
                                    :
                                    null
                            }
                            {
                                comment.is_reply &&
                                <span className='replied-icon' onClick={() => jumpToMainComment(comment.reply_for)}>
                                    <span className='replied-tag'>{t("Replied")}</span>
                                    <Replied />
                                </span>
                            }
                            <h3 className="clr-white">{comment.id}</h3>
                        </span>
                        <span className="comment-time">{comment.comment_creation_timestamp}</span>
                    </div>
                    <div>
                        {
                            commonRemarkCommentReplyState.highLightedCommentReplyId === comment.id &&
                            <>HIGHLIGHT</>
                        }
                        Highlight id {highlightedId}
                        <br />
                        Comment id {comment.id}
                    </div>
                    <div className="comment-content">
                        <p>{comment.comment_body}</p>
                        <div className="comment-actions">
                            {
                                comment.if_is_comment_liked ?
                                    <span>
                                        <svg className='svg-vertical-middle margin-right-3px make-text-cursor fill-svg-comment-liked' onClick={() => handleLikeUnlikeRemarkComment(comment.id, "UNLIKE")} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M720-144H264v-480l288-288 32 22q18 13 26.5 33t3.5 41l-38 192h264q30 0 51 21t21 51v57q0 8-1.5 14.5T906-467L787-188q-9 20-27 32t-40 12ZM192-624v480H48v-480h144Z" /></svg>
                                        <small>({comment.comment_likes_count})</small>
                                    </span>
                                    :
                                    <span>
                                        <svg className='svg-vertical-middle margin-right-3px make-text-cursor make-text-cursor' onClick={() => handleLikeUnlikeRemarkComment(comment.id, "LIKE")} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M720-144H264v-480l288-288 32 22q18 13 26.5 33t3.5 41l-38 192h264q30 0 51 21t21 51v57q0 8-1.5 14.5T906-467L787-188q-9 20-27 32t-40 12ZM192-624v480H48v-480h144Z" /></svg>
                                        <small>({comment.comment_likes_count})</small>
                                    </span>
                            }
                            {
                                comment.if_is_comment_disliked ?
                                    <span>
                                        <svg className='svg-vertical-middle margin-right-3px make-text-cursor fill-svg-comment-unliked' onClick={() => handleLikeUnlikeRemarkComment(comment.id, "UNDISLIKE")} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M240-816h456v480L408-48l-32-22q-18-13-26.5-33t-3.5-41l38-192H120q-30 0-51-21t-21-51v-57q0-8 1.5-14.5T54-493l119-279q8-20 26.5-32t40.5-12Zm528 480v-480h144v480H768Z" /></svg>
                                        <small>({comment.comment_dislikes_count})</small>
                                    </span>
                                    :
                                    <span>
                                        <svg className='svg-vertical-middle margin-right-3px make-text-cursor' onClick={() => handleLikeUnlikeRemarkComment(comment.id, "DISLIKE")} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M240-816h456v480L408-48l-32-22q-18-13-26.5-33t-3.5-41l38-192H120q-30 0-51-21t-21-51v-57q0-8 1.5-14.5T54-493l119-279q8-20 26.5-32t40.5-12Zm528 480v-480h144v480H768Z" /></svg>
                                        <small>({comment.comment_dislikes_count})</small>
                                    </span>
                            }
                            <svg className='comment-reply-button' onClick={() => openRemarkCommentReply(comment.id)} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.03 6.47a.75.75 0 0 0-1.06 0l-5 5a.75.75 0 0 0 0 1.06l5 5a.75.75 0 1 0 1.06-1.06L5.56 12l4.47-4.47a.75.75 0 0 0 0-1.06Z" fill="#F0F8FF" />
                                <g opacity=".5" fill="#F8F8FF">
                                    <path d="M6.31 11.25h8.19c.953 0 2.367.28 3.563 1.141 1.235.89 2.187 2.365 2.187 4.609a.75.75 0 0 1-1.5 0c0-1.756-.715-2.78-1.563-3.391-.887-.639-1.974-.859-2.687-.859H6.31L5.56 12l.75-.75ZM3.807 11.713Z" />
                                </g>
                            </svg>
                        </div>
                        {
                            remarkCommentState.isRemarkCommentReplyFieldOpen && remarkCommentState.remarkCommentReplyCommentId === comment.id &&
                            <>
                                <br />
                                <BigTextField inputValue={remarkCommentState.remarkCommentReply} setInputValue={updateRemarkCommentReplyValue} />
                                <div className='button-wrapper'>
                                    <CustomButton onClick={closeRemarkCommentReply} label={t("Discard")} customclassnames={"discard-button"} />
                                    <CustomButton onClick={() => postCommentReply(remarkId, comment.id)} label={t("Reply")} customclassnames={"edit-save-button"} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <RemarkContextMenu
                id={comment.id}
                userId={comment.commented_by_id}
                userUsername={comment.commented_by_username}
                data={comment}
                isContentUserRequestUser={comment.is_commenter_request_user}
                openShareModal={() => openShareModal(comment.id, upshotId, comment.is_commenter_request_user, "URC", remarkId)}
                openReportModal={() => openReportModal(comment.id, "comment")}
                addMeInUserAudience={() => addMeInUserAudience(comment.commented_by)}
                deleteContent={() => deleteRemarkCommentConfirmationDialog(comment.id)}
                menuContentType={"comment"}
                isQuickShareDisabled={true}

                editLabel={null}
                isEditDisabled={true}

                deleteLabel={"Comment"}
            />
            <ConfirmationDialog
                isOpen={remarkCommentDeleteState.isRemarkCommentDeletionDialogOpen}
                close={() => setRemarkCommentDeleteState({ isRemarkCommentDeletionDialogOpen: false })}
                confirm={deleteRemarkComment}
                deleteState={remarkCommentDeleteState}
                confirmationPageFor={"Delete Remark Comment"}
            />
        </>
    )
}

export default UpShotRemarkCommentList;