import { useState } from "react";
import axios from "axios";

import pluralize from "../../components/helpers/pluralize"
import ConfirmationDialog from "../modals/ConfirmationModal";
import WatchLaterCloseIcon from "./icons/CloseWatchLaterIcon";
import WatchLaterOpenIcon from "./icons/OpenWatchLaterIcon";
import OnlyLocals from "./icons/OnlyLocals";

function WatchLaterItemPopupOptions({ id, currentOptionData, optionsMenuContainerRef, openMoreOptions, watchLaterList, setWatchLaterList, openCloseWtShareModal, cuo }) {
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [deleteState, setDeleteState] = useState({
        isDeleteLoading: false,
        deleteError: "",
    });

    const confirmation = () => {
        deleteMainWtList();
    }

    const deleteMainWtList = () => {
        setDeleteState({
            isDeleteLoading: true,
            deleteError: "",
        })
        axios.delete(`/api/content/management/wt-list/del/edt/${currentOptionData.id}/`).then(() => {
            setDeleteState({
                isDeleteLoading: false,
                deleteError: "",
            })
            setConfirmationModalOpen(false);
            openMoreOptions(0, 0, "", "")
            const updatedMessages = watchLaterList.filter(message => message.id !== currentOptionData.id);
            setWatchLaterList(updatedMessages);
        }).catch(() => {
            setDeleteState({
                isDeleteLoading: false,
                deleteError: "Something went wrong",
            })
        })
    }

    return (
        <>
            <div className="wt-more-options" ref={optionsMenuContainerRef}>
                <ul>
                    <li>
                        {
                            (() => {
                                switch (currentOptionData.visibility) {
                                    case "R":
                                        return <><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M240-640h360v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85h-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640Zm0 480h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM240-160v-400 400Z" /></svg> Private </>;
                                    case "P":
                                        return <><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" /></svg> Public</>;
                                    case "AO":
                                        return <><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M40-160v-160q0-34 23.5-57t56.5-23h131q20 0 38 10t29 27q29 39 71.5 61t90.5 22q49 0 91.5-22t70.5-61q13-17 30.5-27t36.5-10h131q34 0 57 23t23 57v160H640v-91q-35 25-75.5 38T480-200q-43 0-84-13.5T320-252v92H40Zm440-160q-38 0-72-17.5T351-386q-17-25-42.5-39.5T253-440q22-37 93-58.5T480-520q63 0 134 21.5t93 58.5q-29 0-55 14.5T609-386q-22 32-56 49t-73 17ZM160-440q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T280-560q0 50-34.5 85T160-440Zm640 0q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T920-560q0 50-34.5 85T800-440ZM480-560q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-680q0 50-34.5 85T480-560Z" /></svg> Audience Only</>;
                                    default:
                                        return null;
                                }
                            })()
                        }
                    </li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m240-160 40-160H120l20-80h160l40-160H180l20-80h160l40-160h80l-40 160h160l40-160h80l-40 160h160l-20 80H660l-40 160h160l-20 80H600l-40 160h-80l40-160H360l-40 160h-80Zm140-240h160l40-160H420l-40 160Z" /></svg>
                        {currentOptionData.itemCount} item{pluralize(currentOptionData.itemCount)}
                    </li>
                    <li onClick={() => openCloseWtShareModal(id, currentOptionData.visibility)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" /></svg>
                        Share
                    </li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" /></svg>
                        {currentOptionData.timestamp}
                    </li>
                    {
                        cuo &&
                        <>
                            <hr />
                            <li onClick={() => setConfirmationModalOpen(true)} className="clr-red onhover make-text-cursor">Delete</li>
                        </>
                    }
                </ul>
            </div>

            <ConfirmationDialog
                isOpen={isConfirmationModalOpen}
                close={() => setConfirmationModalOpen(false)}
                confirm={confirmation}
                deleteState={deleteState}
                confirmationPageFor={"Delete Watch Later List"}
            />
        </>
    )
}

export default WatchLaterItemPopupOptions;