import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

import SpreeCineLoginSignupSVGLogo from '../assets/SpreeCineLoginSignupLogoSVG';
import TournamentIcon from '../../components/discussions/icons/tournamentIcon';
import { pagesToHideHeaderOn } from "../../components/constants/constants";

function SideBar({ currentUserUsername, eqAvailable, t, setEQModalOpen }) {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(localStorage.getItem("sidebarOpen") ? JSON.parse(localStorage.getItem("sidebarOpen")) : false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const hideHeader = pagesToHideHeaderOn.includes(location.pathname);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!currentUserUsername) {
            const timeoutId = setTimeout(() => {
                setIsOpen(true);
            }, 500);

            const timeoutId2 = setTimeout(() => {
                setIsOpen(false);
            }, 1300);

            return () => {
                clearTimeout(timeoutId);
                clearTimeout(timeoutId2);
            };
        }
    }, []);


    const toggleSidebar = (actionBy) => {
        if (windowWidth >= 1000 && actionBy === "auto") {
            setIsOpen(isOpen);
        } else {
            setIsOpen(!isOpen);
        }
        localStorage.setItem("sidebarOpen", !isOpen);
    };

    if (hideHeader) {
        return null;
    }

    return (
        <>
            <div id="menu-toggle-sidebar-v4" onClick={() => toggleSidebar("manual")}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm120-80v-560H200v560h120Zm80 0h360v-560H400v560Zm-80 0H200h120Z" /></svg>
            </div>
            <div className={`sidebar-v4 ${isOpen ? 'active-v4-sidebar' : ''}`}>
                <div id="close-sidebar-btn-v4" onClick={() => toggleSidebar("manual")}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm440-80h120v-560H640v560Zm-80 0v-560H200v560h360Zm80 0h120-120Z" /></svg>
                    <b className='SpreeCine-main-logo-in'>
                        <SpreeCineLoginSignupSVGLogo svgsizewidth={"200%"} bgcolor={"#27282b"} />
                    </b>
                </div>
                <ul className='sidebar-v4-btns'>
                    <li className={`sidebar-item-v3 ${location.pathname === "/" && `active-link-v3`}`} onClick={() => toggleSidebar("auto")}>
                        <Link to={'/'}>
                            <span className="icon-v3">
                                <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22">
                                    <path
                                        d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" />
                                </svg>
                            </span>
                            <span className="item-text-v3">{t("Home")}</span>
                        </Link>
                    </li>
                    <li className={`sidebar-item-v3 ${location.pathname.startsWith("/upshots") && `active-link-v3`}`} onClick={() => toggleSidebar("auto")}>
                        <Link to={'/upshots'}>
                            <span className="icon-v3">
                                <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22">
                                    <path
                                        d="M200-160v-80h80v-160L40-760h560L360-400v160h80v80H200Zm36-440h168l56-80H180l56 80Zm404 440q-50 0-85-35t-35-85q0-50 35-85t85-35q11 0 21 1.5t19 6.5v-368h200v120H760v360q0 50-35 85t-85 35Z" />
                                </svg>
                            </span>
                            <span className="item-text-v3">{t("UpShots")}</span>
                        </Link>
                    </li>
                    {
                        currentUserUsername &&
                        <li className={`sidebar-item-v3 ${location.pathname.startsWith("/watch-later") && `active-link-v3`}`} onClick={() => toggleSidebar("auto")}>
                            <Link to={`/watch-later/${currentUserUsername}`}>
                                <span className="icon-v3">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960"
                                        width="22">
                                        <path
                                            d="m472-312 56-56-128-128v-184h-80v216l152 152Zm248 172v-88q74-35 117-103t43-149q0-81-43-149T720-732v-88q109 38 174.5 131.5T960-480q0 115-65.5 208.5T720-140Zm-360 20q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T0-480q0-75 28.5-140.5t77-114q48.5-48.5 114-77T360-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T720-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T360-120Zm0-80q117 0 198.5-81.5T640-480q0-117-81.5-198.5T360-760q-117 0-198.5 81.5T80-480q0 117 81.5 198.5T360-200Zm0-280Z" />
                                    </svg>
                                </span>
                                <span className="item-text-v3">{t("Watch Later")}</span>
                            </Link>
                        </li>
                    }

                    <li className={`sidebar-item-v3 ${location.pathname.startsWith("/trending") && `active-link-v3`}`} onClick={() => toggleSidebar("auto")}>
                        <Link to={`/trending/?page=1`}>
                            <span className="icon-v3">
                                <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960"
                                    width="22">
                                    <path
                                        d="m136-240-56-56 296-298 160 160 208-206H640v-80h240v240h-80v-104L536-320 376-480 136-240Z" />
                                </svg>
                            </span>
                            <span className="item-text-v3">{t("Trending")}</span>
                        </Link>
                    </li>
                    {
                        currentUserUsername &&
                        <li className={`sidebar-item-v3 ${location.pathname.startsWith("/watchings") && `active-link-v3`}`} onClick={() => toggleSidebar("auto")}>
                            <Link to={`/watchings/${currentUserUsername}`}>
                                <span className="icon-v3">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960"
                                        width="22">
                                        <path
                                            d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z" />
                                    </svg>
                                </span>
                                <span className="item-text-v3">{t("Watchings")}</span>
                            </Link>
                        </li>
                    }

                    {
                        currentUserUsername &&
                        <li className={`sidebar-item-v3 tournament-sidebar-button ${location.pathname.startsWith("/tournaments") && `active-link-v3`}`} onClick={() => toggleSidebar("auto")}>
                            <Link to={`/tournaments/`}>
                                <span className="icon-v3">
                                    <TournamentIcon />
                                </span>
                                <span className="item-text-v3">{t("Tournaments")}</span>
                            </Link>
                        </li>
                    }
                </ul>
                {
                    currentUserUsername &&
                    <div className="bottom-container-v3">
                        <Link to={"/questions"} className='description-v3'>{t("View all Challenges")}</Link>
                        {eqAvailable ? <div className='available-sign'></div> : null}
                        <button className="container-button-v3" onClick={() => setEQModalOpen(true)}>{t("Open now")}</button>
                    </div>
                }
            </div>
        </>
    )
}

export default SideBar;