import * as React from "react";
const WatchLaterUnFilled = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="20%"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="white"
            opacity={1}
            stroke="none"
            d=" M91.000000,1.000000  C201.354233,1.000000 311.708466,1.000000 422.622437,1.345754  C429.938995,4.506010 433.066223,9.391582 433.062347,16.887974  C432.980011,175.701202 432.937469,334.514587 433.165497,493.327515  C433.178284,502.238251 431.036804,508.833496 423.000000,513.000000  C419.307098,513.000000 415.614166,513.000000 411.384766,512.661377  C403.543060,505.359344 396.097717,498.536377 388.955597,491.409546  C346.279510,448.824554 303.667236,406.175598 261.073212,363.508545  C259.808685,362.241852 258.982056,360.537994 257.472351,358.341064  C255.392365,360.760101 254.399704,362.078735 253.244339,363.234741  C206.504730,409.999756 159.766098,456.765778 112.965813,503.470001  C109.546074,506.882690 105.666031,509.834137 102.000000,513.000000  C98.638710,513.000000 95.277428,513.000000 91.366959,512.654236  C84.061050,509.493988 80.933769,504.608429 80.937660,497.112122  C81.019981,338.298828 81.062531,179.485443 80.834518,20.672455  C80.821732,11.761727 82.963181,5.166477 91.000000,1.000000  M112.000000,312.500000  C112.000000,361.315582 112.000000,410.131195 112.000000,460.227692  C114.401367,457.966492 115.729332,456.780090 116.986526,455.522949  C159.636200,412.875488 202.400055,370.340820 244.798920,327.445404  C251.519012,320.646576 262.457886,320.607422 269.361603,327.595520  C311.866455,370.619751 354.767395,413.252777 397.547333,456.005188  C398.708679,457.165771 399.991547,458.204773 401.657379,459.693359  C401.657379,316.133514 401.657379,173.620529 401.657379,31.315281  C304.930328,31.315281 208.546066,31.315281 112.000000,31.315281  C112.000000,124.920311 112.000000,218.210159 112.000000,312.500000  z"
        />
    </svg>
);
export default WatchLaterUnFilled;
