import * as React from "react";
const IndependentVoiceIcon = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width={props.width}
        height={props.height}
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        className={props.className}
        {...props}
    >
        <path
            fill="#595959"
            opacity={1}
            stroke="none"
            d=" M163.468658,513.000000  C157.569992,503.690735 160.898041,493.492310 160.562134,483.249634  C166.067093,482.845367 171.037140,482.911621 175.995544,483.446075  C175.980621,486.410675 175.970398,488.907043 175.975510,491.403442  C175.988922,497.955841 175.991592,497.970337 182.573303,497.970215  C239.657776,497.969208 296.742249,497.962585 353.826721,497.957611  C354.134033,503.141785 355.014221,508.463806 350.669617,512.724487  C288.645782,513.000000 226.291550,513.000000 163.468658,513.000000  z"
        />
        <path
            fill="#ffff00"
            opacity={1}
            stroke="none"
            d=" M179.887512,28.946556  C178.666153,16.496109 183.820297,7.479609 194.328827,1.262088  C200.021149,1.000000 206.042297,1.000000 212.531723,1.000001  C213.273254,1.647386 213.366302,2.643810 213.845963,2.891490  C228.755554,10.590139 231.016739,29.631371 218.675018,41.600151  C213.423203,43.619473 208.712723,45.311089 203.605011,46.970310  C196.090652,46.084946 189.755142,43.590919 185.317459,37.693237  C183.262390,34.962078 181.682709,31.873236 179.887512,28.946556  M196.992599,26.423691  C198.004669,27.616781 198.818405,29.102430 200.065933,29.948217  C203.840210,32.507057 207.120239,30.348976 208.708542,27.384571  C209.830688,25.290249 209.819397,20.975964 208.403015,19.547098  C206.677078,17.805946 202.496674,16.872469 200.203979,17.749401  C197.614975,18.739679 194.624329,21.619829 196.992599,26.423691  z"
        />
        <path
            fill="#e0c000"
            opacity={1}
            stroke="none"
            d=" M234.954437,169.130798  C227.110825,166.753510 219.240814,164.459061 211.432999,161.969482  C206.319260,160.338928 203.754745,155.374359 205.506607,151.009140  C207.369888,146.366348 212.259888,144.631699 217.374390,146.867798  C220.615860,148.285004 223.812683,149.804291 227.170059,151.342163  C226.326767,133.900711 225.503708,116.877518 224.638687,98.986435  C215.823822,96.085945 205.939636,91.941910 198.565781,83.877968  C195.464706,80.486687 193.588486,76.000549 190.931320,72.167511  C189.617447,70.272209 188.067078,68.291969 186.155396,67.103401  C178.202988,62.159077 170.352814,56.814411 166.167343,48.333595  C163.096909,42.112087 161.534348,35.013245 160.172256,28.132689  C159.378708,24.124107 162.523605,21.981359 166.429855,23.246256  C169.627914,24.281832 172.639435,25.893414 175.876007,28.003460  C176.729263,31.952286 176.968155,35.351105 178.242661,38.302757  C181.598145,46.073849 188.193146,50.901337 194.956955,55.492088  C196.979401,56.864784 199.758743,58.065399 200.628113,60.034885  C204.993607,69.924423 212.495575,76.573112 221.464294,82.014236  C227.917206,85.929085 228.679535,85.632339 229.570374,78.261719  C234.644821,78.547043 239.321548,78.776031 243.997299,79.432907  C243.984528,80.360191 244.035797,80.870468 243.951874,81.357437  C240.054306,103.974541 240.318863,126.643929 243.026276,149.352921  C243.638992,154.492203 245.238373,156.032349 250.182999,154.623413  C255.422699,153.130402 260.956604,151.667130 265.442657,148.768158  C272.076965,144.480972 277.899689,138.937851 284.067993,133.929504  C292.969757,141.525665 292.969757,141.525665 283.646545,150.738220  C279.086517,154.427063 275.004272,157.740509 270.922028,161.053925  C264.920807,163.372375 258.919586,165.690842 252.083130,168.041107  C245.816742,168.425537 240.385590,168.778168 234.954437,169.130798  z"
        />
        <path
            fill="#808080"
            opacity={1}
            stroke="none"
            d=" M353.900513,497.498962  C296.742249,497.962585 239.657776,497.969208 182.573303,497.970215  C175.991592,497.970337 175.988922,497.955841 175.975510,491.403442  C175.970398,488.907043 175.980621,486.410675 176.456299,483.428711  C181.306549,482.600555 185.684341,482.257935 190.062149,481.915314  C197.705750,481.940582 205.349335,481.965820 213.841690,482.131287  C216.154739,482.489349 217.618759,482.894928 219.083389,482.897186  C244.309097,482.936096 269.534943,482.945343 294.760559,482.882538  C296.533539,482.878113 298.304993,482.260986 300.077179,481.928772  C307.722412,481.948883 315.367615,481.969025 323.862671,482.118530  C334.416687,482.458954 344.120850,482.670013 353.825012,482.881073  C353.874786,487.600830 353.924561,492.320587 353.900513,497.498962  z"
        />
        <path
            fill="#C9D2DB"
            opacity={1}
            stroke="none"
            d=" M299.916687,481.574341  C298.304993,482.260986 296.533539,482.878113 294.760559,482.882538  C269.534943,482.945343 244.309097,482.936096 219.083389,482.897186  C217.618759,482.894928 216.154739,482.489349 214.304321,482.101318  C215.944153,474.502686 217.707779,466.987183 220.127991,459.689392  C221.066437,456.859619 223.489624,454.522186 225.972046,451.912628  C228.157623,451.650574 229.607590,451.436310 231.045593,451.669739  C231.023834,455.099884 230.701965,458.126495 231.115555,461.049072  C231.384705,462.950806 232.341904,465.826263 233.666855,466.276642  C236.153671,467.121887 239.220764,466.886475 241.829651,466.233765  C242.371536,466.098175 242.385101,462.091675 241.889999,460.051727  C241.364716,457.887329 239.943726,455.940308 239.100418,453.548584  C241.522675,441.903717 243.647766,430.585175 246.037430,419.322723  C247.377243,413.008148 250.462753,410.917877 257.030884,411.604004  C256.688110,419.149536 256.382294,426.255463 255.999191,433.357239  C255.776627,437.482635 255.075424,441.614227 255.224594,445.719940  C255.433472,451.469849 256.165771,457.206390 256.846680,462.928131  C256.964600,463.919037 257.880249,465.570618 258.523499,465.618195  C262.135376,465.885376 265.909210,466.305206 269.340240,465.478973  C270.645111,465.164734 271.140991,461.654114 272.086700,459.638367  C272.995514,457.701141 274.009247,455.813141 275.192230,453.626465  C277.953033,452.644226 280.498474,451.939056 283.033813,451.684143  C283.010010,453.128815 282.987915,454.123138 282.983978,455.117554  C282.938385,466.637054 282.939087,466.636719 294.105011,465.990448  C294.435730,465.971283 294.767792,465.975525 295.099243,465.968872  C296.651550,471.052551 298.203857,476.136261 299.916687,481.574341  z"
        />
        <path
            fill="#F8E7C7"
            opacity={1}
            stroke="none"
            d=" M243.998291,79.005028  C239.321548,78.776031 234.644821,78.547043 229.204315,78.148056  C214.257080,72.416168 206.819229,61.532269 204.002243,47.002705  C208.712723,45.311089 213.423203,43.619473 218.498444,41.892349  C219.495468,49.003685 222.893555,54.610046 227.962357,59.596138  C233.842728,65.380554 240.228561,63.551876 247.354858,61.387161  C251.968857,61.731335 256.007660,62.408913 260.046478,63.086487  C264.484650,68.685631 268.922821,74.284782 273.737915,80.353958  C280.619843,88.077095 288.389526,93.355766 297.835205,96.017990  C303.983612,98.009155 310.135101,99.990730 316.279419,101.994492  C321.288666,103.628105 323.421539,107.699265 321.788879,112.574738  C320.749603,115.678177 316.971100,118.489250 311.645569,117.413681  C299.404510,114.941391 287.894135,110.883995 277.411224,104.048965  C276.479889,103.441719 275.587372,102.774963 274.174561,101.707245  C269.781952,98.191017 265.891602,95.103386 261.842346,91.721687  C258.746124,88.037880 255.531204,84.844826 252.950119,81.202614  C250.414215,77.624191 247.623520,77.127647 243.998291,79.005028  z"
        />
        <path
            fill="#BD7F54"
            opacity={1}
            stroke="none"
            d=" M212.914551,220.047272  C205.405426,219.926254 203.010025,217.504852 203.002167,209.997589  C202.995041,203.190155 202.891022,196.380096 203.049042,189.576294  C203.144135,185.481110 207.021179,181.354858 210.638336,181.028885  C214.865967,180.647934 218.199783,183.482529 219.008484,188.998489  C219.009384,192.847473 219.259842,195.867004 218.944473,198.826233  C218.436722,203.590698 220.177994,205.102859 225.045547,205.067902  C253.638779,204.862595 282.234283,204.972595 310.829010,204.976303  C310.885437,206.967972 311.045807,208.963226 310.977661,210.950623  C310.811859,215.786362 308.570709,220.634186 301.015289,220.015381  C271.064667,220.029388 241.989609,220.038330 212.914551,220.047272  z"
        />
        <path
            fill="#D49D6D"
            opacity={1}
            stroke="none"
            d=" M310.899414,204.532074  C282.234283,204.972595 253.638779,204.862595 225.045547,205.067902  C220.177994,205.102859 218.436722,203.590698 218.944473,198.826233  C219.259842,195.867004 219.009384,192.847473 219.018707,189.381866  C226.490463,188.908722 233.956375,188.908722 242.106552,188.908722  C239.652496,182.352707 237.319992,176.121384 234.970947,169.510437  C240.385590,168.778168 245.816742,168.425537 251.682892,168.079437  C254.481201,174.255310 256.699280,180.487854 259.332916,186.539566  C259.847198,187.721329 261.958679,188.864090 263.360016,188.897049  C272.341919,189.108353 281.332764,188.914795 290.317169,189.063538  C292.811584,189.104858 294.098022,188.341507 295.073059,186.007141  C296.608154,182.331863 299.572052,180.455948 303.640167,181.327637  C307.828339,182.225006 309.983002,184.819916 310.051117,189.323288  C310.125580,194.248306 310.644562,199.166595 310.899414,204.532074  z"
        />
        <path
            fill="#e0c000"
            opacity={1}
            stroke="none"
            d=" M243.997299,79.432907  C247.623520,77.127647 250.414215,77.624191 252.950119,81.202614  C255.531204,84.844826 258.746124,88.037880 261.859863,92.135193  C262.727631,96.243294 263.419006,99.643814 264.110413,103.044327  C263.612762,110.700279 267.517456,116.743919 271.957886,122.217758  C275.330872,126.375732 279.804688,129.640686 283.928345,133.616241  C277.899689,138.937851 272.076965,144.480972 265.442657,148.768158  C260.956604,151.667130 255.422699,153.130402 250.182999,154.623413  C245.238373,156.032349 243.638992,154.492203 243.026276,149.352921  C240.318863,126.643929 240.054306,103.974541 243.951874,81.357437  C244.035797,80.870468 243.984528,80.360191 243.997299,79.432907  z"
        />
        <path
            fill="#E0BBA3"
            opacity={1}
            stroke="none"
            d=" M288.071106,372.097961  C288.042328,398.399994 288.013580,424.702057 287.831573,451.499664  C287.389893,451.949799 287.157684,451.810577 286.941803,451.142303  C284.234528,437.910522 281.724640,425.078430 278.847870,412.329163  C276.700348,402.811920 267.929535,395.908234 258.218292,396.100098  C253.035339,396.202454 247.869446,397.168884 242.059204,397.816284  C242.059204,389.225861 242.059204,380.173096 242.523560,371.097351  C256.443634,371.059052 269.899506,371.008453 283.354767,371.075714  C284.928528,371.083588 286.499084,371.741455 288.071106,372.097961  z"
        />
        <path
            fill="#E0BBA4"
            opacity={1}
            stroke="none"
            d=" M288.087708,322.039337  C288.061310,333.035675 288.034943,344.032013 287.570679,355.505432  C272.099609,355.974426 257.066437,355.966339 242.032227,355.974426  C242.031174,355.990601 242.043671,355.969116 242.016327,355.503967  C241.986084,344.019623 241.983185,333.000488 242.450073,321.969971  C257.975800,321.985504 273.031769,322.012421 288.087708,322.039337  z"
        />
        <path
            fill="#E0BBA4"
            opacity={1}
            stroke="none"
            d=" M288.078247,272.042175  C288.055267,283.038269 288.032288,294.034332 287.573853,305.518616  C272.086243,306.010254 257.034027,306.013733 241.973267,305.546814  C241.997375,294.072601 242.030060,283.068817 242.527145,272.047424  C258.020447,272.033966 273.049347,272.038086 288.078247,272.042175  z"
        />
        <path
            fill="#CF9875"
            opacity={1}
            stroke="none"
            d=" M213.092072,220.379059  C241.989609,220.038330 271.064667,220.029388 300.613037,220.033554  C294.049225,222.316299 288.874542,226.305939 287.447449,234.629242  C273.491730,235.010895 260.063049,235.077286 246.636139,234.953751  C243.310181,234.923157 241.757080,235.888718 241.907730,239.465149  C242.151703,245.257507 242.071930,251.063477 242.067719,256.927063  C242.006607,256.990448 241.883102,257.115967 241.430588,257.075378  C235.964020,256.655457 230.949966,256.276123 225.935913,255.896790  C225.629257,247.561737 225.937637,239.142258 224.808868,230.920074  C223.999557,225.025177 219.439468,221.503342 213.092072,220.379059  z"
        />
        <path
            fill="#fdf500"
            opacity={1}
            stroke="none"
            d=" M298.004242,95.683075  C288.389526,93.355766 280.619843,88.077095 274.049316,80.494858  C275.050659,79.778877 276.117554,79.392021 277.832794,79.154739  C279.470306,80.194237 280.481964,81.060562 281.444855,81.978035  C286.563171,86.854843 288.866028,87.245705 295.222382,83.881035  C303.494171,79.502449 309.891083,73.329758 312.082703,63.936466  C313.058014,59.756397 315.526886,57.568172 318.921570,55.437134  C329.576874,48.748211 339.058044,41.029209 339.871735,27.015720  C342.159637,25.922222 344.415131,24.753429 346.742401,23.751423  C351.129822,21.862452 354.138641,23.481506 353.888794,28.257528  C353.434845,36.935780 351.680054,45.571606 345.335724,51.961349  C339.802399,57.534290 333.269562,62.102760 327.370789,67.332115  C325.583221,68.916817 324.269257,71.125313 323.027588,73.219566  C319.098724,79.846100 314.774872,86.084816 307.880157,90.006935  C304.670441,91.832840 301.410461,93.570503 298.004242,95.683075  z"
        />
        <path
            fill="#ffff99"
            opacity={1}
            stroke="none"
            d=" M339.506714,27.014187  C339.058044,41.029209 329.576874,48.748211 318.921570,55.437134  C315.526886,57.568172 313.058014,59.756397 312.082703,63.936466  C309.891083,73.329758 303.494171,79.502449 295.222382,83.881035  C288.866028,87.245705 286.563171,86.854843 281.444855,81.978035  C280.481964,81.060562 279.470306,80.194237 278.185547,79.134590  C288.885376,72.452721 296.658081,63.263512 300.412231,51.002117  C302.821655,43.132740 309.502747,40.553509 315.921539,37.560848  C323.626129,33.968697 331.398621,30.522221 339.506714,27.014187  z"
        />
        <path
            fill="#E0BBA3"
            opacity={1}
            stroke="none"
            d=" M242.128845,256.863708  C242.071930,251.063477 242.151703,245.257507 241.907730,239.465149  C241.757080,235.888718 243.310181,234.923157 246.636139,234.953751  C260.063049,235.077286 273.491730,235.010895 287.377869,235.021698  C287.893433,241.679352 287.950897,248.329971 287.704803,255.698120  C286.266571,256.597137 285.131897,256.935822 283.997131,256.936188  C270.041046,256.940735 256.084930,256.895844 242.128845,256.863708  z"
        />
        <path
            fill="#C9D2DB"
            opacity={1}
            stroke="none"
            d=" M353.899780,482.437439  C344.120850,482.670013 334.416687,482.458954 324.326233,482.081543  C325.291138,467.776794 326.654053,453.639465 327.988159,439.499390  C328.579163,433.235382 333.374146,431.587311 338.642151,430.552246  C339.010498,438.554260 338.996735,446.026245 339.004547,453.498199  C339.019440,467.793060 337.519928,467.230194 352.844971,466.977844  C353.221497,471.983154 353.598053,476.988464 353.899780,482.437439  z"
        />
        <path
            fill="#D09977"
            opacity={1}
            stroke="none"
            d=" M241.981827,306.017181  C257.034027,306.013733 272.086243,306.010254 287.611481,305.983398  C291.981842,306.379883 294.617340,308.142059 294.997345,312.372437  C295.429260,317.181366 294.350037,320.999573 288.465118,322.019531  C273.031769,322.012421 257.975800,321.985504 242.385010,321.908722  C241.850174,321.858856 241.720459,321.735352 241.794998,321.298462  C241.820679,315.995300 241.771820,311.129059 241.787506,306.201202  C241.852036,306.139618 241.981827,306.017181 241.981827,306.017181  z"
        />
        <path
            fill="#CF9875"
            opacity={1}
            stroke="none"
            d=" M242.067719,256.927063  C256.084930,256.895844 270.041046,256.940735 283.997131,256.936188  C285.131897,256.935822 286.266571,256.597137 287.734528,256.159790  C292.655762,257.095642 295.412170,259.528778 295.069092,264.806244  C294.796387,269.001160 293.140900,271.522614 288.460815,272.023254  C273.049347,272.038086 258.020447,272.033966 242.470459,271.988220  C241.949356,271.946594 241.835861,271.828339 241.879730,271.380371  C241.910080,266.326904 241.896591,261.721436 241.883102,257.115967  C241.883102,257.115967 242.006607,256.990448 242.067719,256.927063  z"
        />
        <path
            fill="#CF9875"
            opacity={1}
            stroke="none"
            d=" M242.033264,355.958282  C257.066437,355.966339 272.099609,355.974426 287.605652,355.970276  C292.548035,355.407013 294.368164,358.128632 294.951996,361.889740  C295.821564,367.491669 294.361877,369.628387 288.400604,371.941589  C286.499084,371.741455 284.928528,371.083588 283.354767,371.075714  C269.899506,371.008453 256.443634,371.059052 242.508118,370.619385  C242.029953,365.429016 242.031601,360.693634 242.033264,355.958282  z"
        />
        <path
            fill="#F0C87E"
            opacity={1}
            stroke="none"
            d=" M260.438873,63.048035  C256.007660,62.408913 251.968857,61.731335 247.547485,61.035995  C242.278915,57.642548 239.691772,53.098484 239.987732,47.047546  C240.069046,45.385078 240.155792,43.698006 239.973358,42.051785  C239.149048,34.613789 241.444229,30.367075 250.690338,27.649479  C251.049362,32.396122 250.881470,36.705711 251.084381,40.997776  C251.392090,47.506863 256.505219,52.659901 262.766510,51.771759  C266.604279,51.227379 270.154968,48.659145 273.834595,46.999756  C274.213135,55.314201 268.882416,61.877426 260.438873,63.048035  z"
        />
        <path
            fill="#ffff99"
            opacity={1}
            stroke="none"
            d=" M273.908691,46.544449  C270.154968,48.659145 266.604279,51.227379 262.766510,51.771759  C256.505219,52.659901 251.392090,47.506863 251.084381,40.997776  C250.881470,36.705711 251.049362,32.396122 251.058060,27.638180  C255.755783,27.447226 260.641266,26.983532 265.086243,28.170097  C270.155914,29.523422 274.859772,32.853859 274.032928,40.143105  C273.810760,42.101807 273.988617,44.105892 273.908691,46.544449  z"
        />
        <path
            fill="#E9EDF1"
            opacity={1}
            stroke="none"
            d=" M352.916168,466.523041  C337.519928,467.230194 339.019440,467.793060 339.004547,453.498199  C338.996735,446.026245 339.010498,438.554260 339.020294,430.624146  C344.930573,430.630798 348.902496,433.039398 349.847900,438.566895  C351.400909,447.647400 351.991150,456.892517 352.916168,466.523041  z"
        />
        <path
            fill="#EBB34D"
            opacity={1}
            stroke="none"
            d=" M271.049103,161.367706  C275.004272,157.740509 279.086517,154.427063 283.501312,151.048859  C285.625397,152.898529 287.382416,154.846741 289.215729,156.720291  C292.871857,160.456680 293.137024,166.491043 289.741425,169.738861  C286.922638,172.434982 281.293793,171.767517 277.816986,168.314865  C275.596954,166.110229 273.389343,163.893036 271.049103,161.367706  z"
        />
        <path
            fill="#E9EDF1"
            opacity={1}
            stroke="none"
            d=" M286.981689,451.577576  C287.157684,451.810577 287.389893,451.949799 287.882294,451.961304  C293.917206,454.485382 294.315582,459.889435 295.053589,465.554199  C294.767792,465.975525 294.435730,465.971283 294.105011,465.990448  C282.939087,466.636719 282.938385,466.637054 282.983978,455.117554  C282.987915,454.123138 283.010010,453.128815 283.428650,451.632843  C284.882996,451.280029 285.932343,451.428802 286.981689,451.577576  z"
        />
        <path
            fill="#F0C87E"
            opacity={1}
            stroke="none"
            d=" M264.521362,103.013870  C263.419006,99.643814 262.727631,96.243294 262.018738,92.429260  C265.891602,95.103386 269.781952,98.191017 273.852844,101.554291  C270.999664,102.214424 267.966003,102.598915 264.521362,103.013870  z"
        />
        <path
            fill="#CD9571"
            opacity={1}
            stroke="none"
            d=" M286.941803,451.142303  C285.932343,451.428802 284.882996,451.280029 283.438782,451.182556  C280.498474,451.939056 277.953033,452.644226 274.871582,453.432129  C273.891296,451.591370 273.379852,449.680481 273.014038,447.742126  C271.268311,438.492279 269.746307,429.195526 267.778137,419.993866  C266.427063,413.677124 263.081421,411.223633 257.035370,411.162659  C250.462753,410.917877 247.377243,413.008148 246.037430,419.322723  C243.647766,430.585175 241.522675,441.903717 238.775665,453.431519  C235.862106,452.850189 233.459824,452.036133 231.057541,451.222076  C229.607590,451.436310 228.157623,451.650574 226.338104,451.857025  C225.976013,426.586121 225.976883,401.322998 226.001114,376.059875  C226.003555,373.525391 226.072296,371.631348 222.687820,370.533661  C219.271011,369.425476 218.395172,365.981812 218.918152,362.155121  C219.459320,358.195404 221.254242,356.170319 226.067291,356.020020  C231.959549,355.978394 237.001602,355.973755 242.043671,355.969116  C242.043671,355.969116 242.031174,355.990601 242.032227,355.974426  C242.031601,360.693634 242.029953,365.429016 242.043762,370.642334  C242.059204,380.173096 242.059204,389.225861 242.059204,397.816284  C247.869446,397.168884 253.035339,396.202454 258.218292,396.100098  C267.929535,395.908234 276.700348,402.811920 278.847870,412.329163  C281.724640,425.078430 284.234528,437.910522 286.941803,451.142303  z"
        />
        <path
            fill="#C9D2DB"
            opacity={1}
            stroke="none"
            d=" M190.035019,481.483826  C185.684341,482.257935 181.306549,482.600555 176.467972,482.960510  C171.037140,482.911621 166.067093,482.845367 160.641205,482.771942  C161.130966,468.882080 161.707062,454.959595 163.195663,441.135345  C163.831711,435.228546 167.497269,430.666565 174.632706,430.547607  C175.005569,438.542114 174.911270,446.005615 175.014786,453.466339  C175.226517,468.726166 172.527130,467.000763 188.844238,466.977753  C189.232117,471.669281 189.619995,476.360840 190.035019,481.483826  z"
        />
        <path
            fill="#ffff4d"
            opacity={1}
            stroke="none"
            d=" M203.605011,46.970310  C206.819229,61.532269 214.257080,72.416168 228.806595,78.091721  C228.679535,85.632339 227.917206,85.929085 221.464294,82.014236  C212.495575,76.573112 204.993607,69.924423 200.628113,60.034885  C199.758743,58.065399 196.979401,56.864784 194.956955,55.492088  C188.193146,50.901337 181.598145,46.073849 178.242661,38.302757  C176.968155,35.351105 176.729263,31.952286 176.032379,28.329439  C177.106964,28.181324 178.167542,28.461046 179.557800,28.843662  C181.682709,31.873236 183.262390,34.962078 185.317459,37.693237  C189.755142,43.590919 196.090652,46.084946 203.605011,46.970310  z"
        />
        <path
            fill="#CF9976"
            opacity={1}
            stroke="none"
            d=" M242.006042,272.005798  C242.030060,283.068817 241.997375,294.072601 241.973267,305.546814  C241.981827,306.017181 241.852036,306.139618 241.352722,306.131775  C235.871780,306.070984 230.890152,306.018066 225.908508,305.965149  C225.935303,294.965363 225.962082,283.965637 226.423309,272.491760  C231.850464,271.954529 236.843155,271.891418 241.835861,271.828339  C241.835861,271.828339 241.949356,271.946594 242.006042,272.005798  z"
        />
        <path
            fill="#CF9976"
            opacity={1}
            stroke="none"
            d=" M242.016327,355.503967  C237.001602,355.973755 231.959549,355.978394 226.449890,355.980560  C225.983841,344.973450 225.985367,333.968811 226.416351,322.481934  C231.804031,321.911591 236.762238,321.823456 241.720459,321.735352  C241.720459,321.735352 241.850174,321.858856 241.915222,321.920105  C241.983185,333.000488 241.986084,344.019623 242.016327,355.503967  z"
        />
        <path
            fill="#E9EDF1"
            opacity={1}
            stroke="none"
            d=" M188.915802,466.532959  C172.527130,467.000763 175.226517,468.726166 175.014786,453.466339  C174.911270,446.005615 175.005569,438.542114 175.017761,430.621552  C183.258545,432.077423 185.895294,434.447357 186.904922,442.311310  C187.917236,450.196106 188.318359,458.159332 188.915802,466.532959  z"
        />
        <path
            fill="#BD7F54"
            opacity={1}
            stroke="none"
            d=" M241.794998,321.298462  C236.762238,321.823456 231.804031,321.911591 226.373322,322.017212  C220.673798,321.950348 218.473663,317.988495 218.401703,314.061646  C218.335541,310.451080 219.986801,306.224548 225.532410,305.981140  C230.890152,306.018066 235.871780,306.070984 241.288193,306.193359  C241.771820,311.129059 241.820679,315.995300 241.794998,321.298462  z"
        />
        <path
            fill="#BD7F54"
            opacity={1}
            stroke="none"
            d=" M241.879730,271.380371  C236.843155,271.891418 231.850464,271.954529 226.385986,272.026672  C221.037781,272.410645 219.271011,269.510834 218.644470,265.264343  C217.871384,260.024536 221.111374,257.736511 225.581604,255.963196  C230.949966,256.276123 235.964020,256.655457 241.430588,257.075378  C241.896591,261.721436 241.910080,266.326904 241.879730,271.380371  z"
        />
        <path
            fill="#E9ECF0"
            opacity={1}
            stroke="none"
            d=" M257.030884,411.604004  C263.081421,411.223633 266.427063,413.677124 267.778137,419.993866  C269.746307,429.195526 271.268311,438.492279 273.014038,447.742126  C273.379852,449.680481 273.891296,451.591370 274.656250,453.709198  C274.009247,455.813141 272.995514,457.701141 272.086700,459.638367  C271.140991,461.654114 270.645111,465.164734 269.340240,465.478973  C265.909210,466.305206 262.135376,465.885376 258.523499,465.618195  C257.880249,465.570618 256.964600,463.919037 256.846680,462.928131  C256.165771,457.206390 255.433472,451.469849 255.224594,445.719940  C255.075424,441.614227 255.776627,437.482635 255.999191,433.357239  C256.382294,426.255463 256.688110,419.149536 257.030884,411.604004  z"
        />
        <path
            fill="#E9ECF0"
            opacity={1}
            stroke="none"
            d=" M231.045593,451.669739  C233.459824,452.036133 235.862106,452.850189 238.589142,453.781311  C239.943726,455.940308 241.364716,457.887329 241.889999,460.051727  C242.385101,462.091675 242.371536,466.098175 241.829651,466.233765  C239.220764,466.886475 236.153671,467.121887 233.666855,466.276642  C232.341904,465.826263 231.384705,462.950806 231.115555,461.049072  C230.701965,458.126495 231.023834,455.099884 231.045593,451.669739  z"
        />
    </svg>
);
export default IndependentVoiceIcon;
