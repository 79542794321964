import { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import './styles/adminDashboardApi.css';
import { Button } from "react-bootstrap";
import axios from "axios";
import { useParams } from 'react-router';
import AdminDashboardDropdown from "./AdminDashboardDropdown";
import { adminApiUrls } from "./api/api";

const urlsList = [
    `/api/content/main/upshot/?upshot_type_page=popular&popular_since=month&limit=10&offset=0`, // GET // ! Done
    `/api/content/main/upshot/?upshot_type_page=newest&popular_since=month&limit=10&offset=0`, // GET // ! Done
    `/api/content/main/upshot-detail/${9}`, // GET // ! Done
    `/api/content/main/upshot/drafts/`, // ! Done
    `/api/notificationnotifications/`, // GET // ! Done
    // `/report/create/`, // POST
    // `/get-celeb-details/`, // GET // ! Done
    // `/get-content-news/`, // GET // ! Done
    // `/get-videos-news/`, // GET // ! Done
    // `/export_watch_later/`, // POST
    // `/explore-api/`, // GET // ! 
]

function AdminDashboardAPI() {
    const params = useParams();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // const [currentId, setCurrentId] = useState("");
    const [urlStatusSState, setUrlStatusState] = useState([]);
    const [contentNewsTestStatus, setContentNewsTestState] = useState({
        statusesponse: "",
        status: "",
    });
    const [seperateTestCallState, setSeperateTestCallState] = useState({
        newsStatusResponse: "",
        celebDetailStatusResponse: "",
        videoDetailStatusResponse: "",
    });
    const adminDashboardUrls = adminApiUrls.adminAPIStatus;
    const verifyKeyStatusUrl = adminDashboardUrls.verifyKeyStatus(params.id);
    const ytVidListUrl = adminDashboardUrls.getYtVideoFetchStatus;
    const celebDataUrl = adminDashboardUrls.getCelebDetailAPIStatus;
    const newsStatusUrl = adminDashboardUrls.getNewsStatus;


    const runTests = () => {
        setUrlStatusState([])
        const fetchedUrls = new Set();

        axios.get(verifyKeyStatusUrl).then((res) => {
            console.log(res)
            if (res.data.message) {
                const promises = urlsList.forEach((url) => {
                    if (!fetchedUrls.has(url)) {
                        axios.get(url).then((res) => {
                            // console.log(res);
                            const path = res.config.url.split('?')[0];
                            setUrlStatusState((prevState) => [
                                ...prevState,
                                { url, status: res.status }
                            ])
                        }).catch((err) => {
                            console.log(err);
                            setUrlStatusState((prevState) => [
                                ...prevState,
                                { url, status: err.code }
                            ])
                        })
                    }
                    fetchedUrls.add(url);
                });
                testContentNews();
                testCelebDetail();
                testYtVideoList();
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const testYtVideoList = () => {
        axios.get(ytVidListUrl).then((res) => {
            console.log(res);
            setSeperateTestCallState(prevState => ({
                ...prevState,
                videoDetailStatusResponse: res.status,
            }))
        }).catch((err) => {
            console.log(err);
            setSeperateTestCallState(prevState => ({
                ...prevState,
                videoDetailStatusResponse: err.code,
            }))
        })
    }

    const testCelebDetail = () => {
        axios.get(celebDataUrl).then((res) => {
            setSeperateTestCallState(prevState => ({
                ...prevState,
                celebDetailStatusResponse: res.status,
            }))
        }).catch((err) => {
            setSeperateTestCallState(prevState => ({
                ...prevState,
                celebDetailStatusResponse: err.code,
            }))
        })
    }

    const testContentNews = () => {
        axios.get(newsStatusUrl).then((res) => {
            console.log(res);
            setSeperateTestCallState(prevState => ({
                ...prevState,
                newsStatusResponse: res.status,
            }))
        }).catch((err) => {
            console.log(err);
            setSeperateTestCallState(prevState => ({
                ...prevState,
                newsStatusResponse: err.code,
            }))
        });
    }

    return (
        <>
            <AdminDashboardDropdown
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen}
            />
            <Button variant="secondary" onClick={runTests}>Run tests</Button>
            <Accordion defaultActiveKey={['0']}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        UpShot List (Popular)
                        {
                            urlStatusSState?.map((res) =>
                                <>
                                    {res.url === "/upshot/?upshot_type_page=popular&popular_since=month&limit=10&offset=0" &&
                                        <>
                                            <b className="api-status-response">{res.status === 200 ? <span className="clr-text-green">200</span> : <span>{res.status}</span>}</b>
                                            <b className="api-status">{res.status === 200 ? <>Functional</> : <>Not Working</>}</b>
                                        </>}
                                </>
                            )
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        UpShot List (Newest)
                        {
                            urlStatusSState?.map((res) =>
                                <>
                                    {res.url === "/upshot/?upshot_type_page=newest&popular_since=month&limit=10&offset=0" &&
                                        <>
                                            <b className="api-status-response">{res.status === 200 ? <span className="clr-text-green">200</span> : <span>{res.status}</span>}</b>
                                            <b className="api-status">{res.status === 200 ? <>Functional</> : <>Not Working</>}</b>
                                        </>}
                                </>
                            )
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        UpShot Detail
                        {
                            urlStatusSState?.map((res) =>
                                <>
                                    {res.url === "/upshot-detail/9" &&
                                        <>
                                            <b className="api-status-response">{res.status === 200 ? <span className="clr-text-green">200</span> : <span>{res.status}</span>}</b>
                                            <b className="api-status">{res.status === 200 ? <>Functional</> : <>Not Working</>}</b>
                                        </>}
                                </>
                            )
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        UpShot drafts
                        {
                            urlStatusSState?.map((res) =>
                                <>
                                    {res.url === "/upshot/drafts/" &&
                                        <>
                                            <b className="api-status-response">{res.status === 200 ? <span className="clr-text-green">200</span> : <span>{res.status}</span>}</b>
                                            <b className="api-status">{res.status === 200 ? <>Functional</> : <>Not Working</>}</b>
                                        </>}
                                </>
                            )
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        Notifications
                        {
                            urlStatusSState?.map((res) =>
                                <>
                                    {res.url === "/notifications/" &&
                                        <>
                                            <b className="api-status-response">{res.status === 200 ? <span className="clr-text-green">200</span> : <span>{res.status}</span>}</b>
                                            <b className="api-status">{res.status === 200 ? <>Functional</> : <>Not Working</>}</b>
                                        </>}
                                </>
                            )
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        Celeb Details
                        <b className="api-status-response">{seperateTestCallState.celebDetailStatusResponse === 200 ? <span className="clr-text-green">200</span> : <span>{seperateTestCallState.celebDetailStatusResponse}</span>}</b>
                        <b className="api-status">{seperateTestCallState.celebDetailStatusResponse == 200 ? <>Functional</> : <>Not Working</>}</b>
                    </Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        Content News
                        <b className="api-status-response">{seperateTestCallState.newsStatusResponse === 200 ? <span className="clr-text-green">200</span> : <span>{seperateTestCallState.newsStatusResponse}</span>}</b>
                        <b className="api-status">{seperateTestCallState.newsStatusResponse == 200 ? <>Functional</> : <>Not Working</>}</b>
                    </Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        Video News
                        <b className="api-status-response">{seperateTestCallState.videoDetailStatusResponse === 200 ? <span className="clr-text-green">200</span> : <span>{seperateTestCallState.videoDetailStatusResponse}</span>}</b>
                        <b className="api-status">{seperateTestCallState.videoDetailStatusResponse == 200 ? <>Functional</> : <>Not Working</>}</b>
                    </Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                    <Accordion.Header>Explore API <b className="api-status-response">200 OK</b> <b className="api-status">Functional</b></Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default AdminDashboardAPI;