import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import _debounce from 'lodash/debounce';

import UpShotListDetailContainerBox from '../../components/upshot/UpShotList/UpShotListDetailContainerBox';
import textTrimmer from '../../components/helpers/stringCutter';
import LayoutComponentForHelmet from '../SEO/LayoutComponentForHelmet';
import LoadingIfElseErrorSvg from "../../components/animations/LoadingIfElseErrorSvg";

class HomePage2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchContent: [],
      fetchLoading: false,
      fetchError: "",

      offset: 0,
      limit: 10,
      hasMore: true,

      fetchedWatchings: [],
      fetchErrorOccured: null,
      fetchError: "",
      isWatchingLoading: false,
      isRequestUserAuthenticated: false,
    }
    window.onscroll = (e) => {
      const {
        loadMoreContent,
        state: { fetchError, fetchLoading, hasMore }
      } = this;
      if (fetchError || fetchLoading || !hasMore) return;
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        loadMoreContent();
      }
    }
    this.debouncedLoadMoreContent = _debounce(this.loadMoreContent, 700); // Adjust the debounce time as needed
    this.handleScroll = this.handleScroll.bind(this);
  }

  fetchWatchings() {
    // this.setState(prevState => ({
    //   ...prevState,
    //   isFetchLoading: true,
    //   fetchErrorOccured: false,
    //   fetchError: "",
    // }))
    // axios.get(`/api/content/main/watching/list/home-page`).then((res) => {
    //   this.setState(prevState => ({
    //     ...prevState,
    //     fetchedWatchings: res.data,

    //     isFetchLoading: false,
    //     fetchErrorOccured: false,
    //     fetchError: "",
    //   }))
    // }).catch(() => {
    //   this.setState(prevState => ({
    //     ...prevState,
    //     isFetchLoading: false,
    //     fetchErrorOccured: true,
    //     fetchError: "Something went Wrong!",
    //   }))
    // })
  }

  handleScroll() {
    // const { innerHeight } = window;
    // const { scrollHeight, scrollTop } = document.documentElement;

    // const distanceFromBottom = scrollHeight - (innerHeight + scrollTop);
    // const loadThreshold = 2000;

    // if (distanceFromBottom < loadThreshold && this.state.hasMore) {
    //   this.debouncedLoadMoreContent();
    // }
    const { fetchLoading } = this.state;

    // * Calculating the distance from the bottom
    const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
    const distanceFromBottom = scrollableHeight - window.scrollY;
    const threshold = 700; // * Adjust this value based on desired distance in pixels. It was 300 but it was not very much space from bottom so changed to 700.

    // * Check if the distance is less than or equal to the threshold.
    if (distanceFromBottom <= threshold && !fetchLoading) {
      if (this.state.hasMore) {
        this.debouncedLoadMoreContent();
      }
    }
  }

  fetchAgain = () => {
    this.loadMoreContent();
  }

  loadMoreContent = () => {
    this.setState({ fetchLoading: true }, () => {
      const { offset, limit } = this.state;
      axios.get(`/api/user/t/home/page/recommendations/?limit=${limit}&offset=${offset}`).then((res) => {
        this.setState({
          isRequestUserAuthenticated: res.data.is_r_u_athtcted
        })
        const newContent = res.data.message;
        let hasMore = true;
        if (res.data.message.length == 0) {
          hasMore = false
          this.fetchAgain();
        };

        // Implement this every infinite scroll.
        this.setState(prevState => {
          const uniqueFetchedContent = [
            ...prevState.fetchContent,
            ...newContent.filter(newBlog =>
              !prevState.fetchContent.some(oldBlog => oldBlog.id === newBlog.id)
            )
          ];
          return {
            fetchLoading: false,
            hasMore: hasMore,
            fetchContent: uniqueFetchedContent,
          };
        });
      }).catch(() => {
        this.setState({
          fetchError: "Something went Wrong!",
          fetchLoading: false,
        })
      })
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.loadMoreContent();
    this.fetchWatchings();
    window.addEventListener("scroll", this.handleScroll)
  }

  render() {
    const { fetchContent, fetchedWatchings, isRequestUserAuthenticated, fetchLoading, fetchError } = this.state;

    // if (fetchLoading || fetchError) {
    //   return <LoadingIfElseErrorSvg isLoading={fetchLoading} errorString={fetchError} retry={this.fetchAgain} loadingLogoBigOrSmall={"big"} />
    // }
    return (
      <div className='body-space-margin-top'>
        <LayoutComponentForHelmet pageTitle={"Home - SpreeCine"}>
          {/* Watchings Horizontal Scrollables */}
          <div className={`main-site-content-v3`}>
            <div className="horizontal-contents-main-v3">
              <div className="horizontal-contents-v3">
                <div className="inner-container">
                  {
                    fetchedWatchings.length !== 0 &&
                    <>
                      {
                        fetchedWatchings?.map((watching) =>
                          <div className="horizontal-contents-item-v3" key={watching.id}>
                            <img src={`https://image.tmdb.org/t/p/w200/${watching.content_poster}`}
                              alt="User Image" />
                            <Link to={`/cl/movie/${watching.watcing_tv_movie_name.replace(/\s+/g, "-")}}/${watching.watcing_tv_movie_id}`}>
                              <div className="number-container-v3" title={`${watching.total_upshots} UpShots have been created on "${watching.watcing_tv_movie_name}"`}>{watching.total_upshots}</div>
                            </Link>
                            <div className="text-v3">
                              <span>{textTrimmer(watching.watcing_tv_movie_name, 15)}</span>
                            </div>
                          </div>
                        )
                      }
                    </>
                  }
                  {
                    fetchedWatchings?.length >= 5 &&
                    <div className="scroll-indicator-v3">→</div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={`body-space`}>
            {
              fetchContent?.map((res, index) =>
                <div key={res.id} className='upshots-list-v2'>
                  <UpShotListDetailContainerBox
                    index={index}
                    res={res}
                    isRequestUserAuthenticated={isRequestUserAuthenticated}
                    remarkInUpShot={[]}
                  />
                </div>
              )
            }
          </div>
        </LayoutComponentForHelmet>
      </div>
    )
  }
}

export default HomePage2;