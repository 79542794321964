import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Identicon from 'react-identicons';
import { HoverCard, Avatar, Text, Group, Anchor, Stack } from '@mantine/core';

import showToastError from '../helpers/showToastError';

const PlusNumberSymbol = ({ number, max }) => {
    return (
        <div>
            {number > max ? (
                <>{number}+</>
            ) : (
                <>{number}</>
            )}
        </div>
    );
};

const UserDetailPopup = ({ t, isOpen, userId, name }) => {
    const [userData, setUserData] = useState({
        userId: 0,
        currentUser: 0,
        userUsername: "",
        userFullName: "",
        userAbout: "",
        userImage: "",
        countAllUserUpShots: 0,
        countAllUserAwards: 0,

        isUserBlockedByReceiver: false,
        isAudienceRequestSent: false,
        receiverAudienceVisibility: "",
        isUserInReceiverAudience: false,

        isUserDataLoading: false,
        userDataFetchError: "",
    })

    useEffect(() => {
        if (isOpen) {
            setUserData({
                isUserDataLoading: true,
                userDataFetchError: "",
            });
            axios.get(`/api/users/primary/user/short/info/${userId}/`).then((res) => {
                setUserData({
                    userId: res.data.id,
                    currentUser: res.data.current_user,
                    userUsername: res.data.username,
                    userFullName: res.data.full_name,
                    userAbout: res.data.about,
                    userImage: res.data.profile_image,
                    countAllUserUpShots: res.data.count_all_user_upshots,
                    countAllUserAwards: res.data.count_all_user_awards,

                    isUserBlockedByReceiver: res.data.is_user_blocked_by_receiver,
                    isAudienceRequestSent: res.data.is_request_sent,
                    receiverAudienceVisibility: res.data.receiver_audience_visibility,
                    isUserInReceiverAudience: res.data.is_user_in_receiver_audience,

                    isUserDataLoading: false,
                    userDataFetchError: "",
                })
            }).catch(() => {
                setUserData({
                    isUserDataLoading: false,
                    userDataFetchError: "Something went Wrong!",
                })
            })
        }
    }, [isOpen])

    if (!isOpen) {
        return null;
    }

    const addCurrentUserToAudience = () => {
        const data = ({
            receiver_id: userData.userId,
        })
        axios.post(`/api/audience/audience-request/`, data).then(() => {
            if (userData.receiverAudienceVisibility === "APR") {
                setUserData(prevState => ({
                    ...prevState,
                    isAudienceRequestSent: !prevState.isAudienceRequestSent
                }))
            } else if (userData.receiverAudienceVisibility === "ANY") {
                setUserData(prevState => ({
                    ...prevState,
                    isUserInReceiverAudience: true
                }))
                showToastError(t("You are successfully in added their Audience"), "success")
            } else {
                showToastError(t("Nobody can be in their Audience"), "info")
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                showToastError(t("You're Banned. You cannot send Audience Request."), "error")
            }
        })
    }

    // ! Create a feature of users to keep track of notifications of their user's.

    const stepOutFromUserReceiver = () => {
        axios.put(`/api/audience/step-out/audience/${userData.userId}/`).then((res) => {
            showToastError(t("Successfully stepped out from their Audience"), "success");
            setUserData(prevState => ({
                ...prevState,
                isUserInReceiverAudience: false
            }))
        }).catch(() => {
            showToastError(t("Something went wrong while removing from their Audience"), "error");
        })
    }

    return (
        <Group justify="center">
            <HoverCard width={320} shadow="md" withArrow openDelay={200} closeDelay={1000}>
                <HoverCard.Target>
                    <Identicon string={name} size="25" />
                    {/* <Avatar src="https://avatars.githubusercontent.com/u/79146003?s=200&v=4" radius="xl" /> */}
                </HoverCard.Target>
                <HoverCard.Dropdown>
                    <Group>
                        <Avatar src="https://avatars.githubusercontent.com/u/79146003?s=200&v=4" radius="xl" />
                        <Stack gap={5}>
                            <Text size="sm" fw={700} style={{ lineHeight: 1 }}>
                                Mantine
                            </Text>
                            <Anchor
                                href="https://twitter.com/mantinedev"
                                c="dimmed"
                                size="xs"
                                style={{ lineHeight: 1 }}
                            >
                                @mantinedev
                            </Anchor>
                        </Stack>
                    </Group>

                    <Text size="sm" mt="md">
                        Customizable React components and hooks library with focus on usability, accessibility
                        and developer experience
                    </Text>

                    <Group mt="md" gap="xl">
                        <Text size="sm">
                            <b>0</b> Following
                        </Text>
                        <Text size="sm">
                            <b>1,174</b> Followers
                        </Text>
                    </Group>
                </HoverCard.Dropdown>
            </HoverCard>
        </Group>
    )

    // return (
    //     <div onMouseEnter={onMouseEnter} onMouseLeave={(e) => onMouseLeave(e)}>
    //         <div className={`${isOpen ? "show" : "hide"}`}>
    //             <div
    //                 className="short-info-popup"
    //                 style={{
    //                     top: `${popupPositionY}px`,
    //                     left: `${popupPositionX + 25}px`,
    //                 }}
    //             >
    //                 {
    //                     userData.userDataFetchError === "" ?
    //                         <>
    //                             {
    //                                 userData.userImage ?
    //                                     <>
    //                                         {
    //                                             userData.isUserDataLoading ?
    //                                                 <LoadingDots />
    //                                                 :
    //                                                 <img className="popup-image" src={`${defaultBackendOrigin}/media/${userData.userImage}`} alt="" />
    //                                         }
    //                                     </>
    //                                     :
    //                                     <>
    //                                         {
    //                                             !userData.isUserDataLoading &&
    //                                             <Identicon string={userData.userFullName} size="30" className="popup-image" />
    //                                         }
    //                                     </>
    //                             }
    //                             <div className="popup-info">
    //                                 <Link to={`/profile/${userData.userUsername}`}><h3 className="popup-name">{userData.isUserDataLoading ? <><LoadingDots /></> : <>{userData.userFullName}</>}</h3></Link>
    //                                 <p className="popup-description">{userData.isUserDataLoading ? <><LoadingDots /></> : <>{userData.userAbout}</>}</p>
    //                             </div>
    //                             <div className="additional-info-container">
    //                                 <div className="small-container">
    //                                     <small>UpShots</small>
    //                                     <p>{userData.isUserDataLoading ? <><LoadingDots /></> : <PlusNumberSymbol number={userData.countAllUserUpShots} max={100} />}</p>
    //                                 </div>
    //                                 <div className="small-container">
    //                                     <small>Awards</small>
    //                                     <p>{userData.isUserDataLoading ? <><LoadingDots /></> : <PlusNumberSymbol number={userData.countAllUserAwards} max={100} />}</p>
    //                                 </div>
    //                                 {
    //                                     !userData.isUserInReceiverAudience ?
    //                                         <>
    //                                             {
    //                                                 userData.receiverAudienceVisibility === "ANY"
    //                                                     ?
    //                                                     <div className="large-container">
    //                                                         <button className="add-to-audience-button" disabled={userData.isUserBlockedByReceiver} onClick={addCurrentUserToAudience}>
    //                                                             <svg className='add-to-audience-button-icon' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" /></svg>
    //                                                             {/* <span className='corner-svg'><CheersGlass /></span> */}
    //                                                             {t("Be in Audience")}
    //                                                         </button>
    //                                                     </div>
    //                                                     :
    //                                                     <>
    //                                                         {
    //                                                             userData.receiverAudienceVisibility === "APR" ?
    //                                                                 <div className="large-container">
    //                                                                     <button className="add-to-audience-button" disabled={userData.isUserBlockedByReceiver}>
    //                                                                         {
    //                                                                             userData.isAudienceRequestSent ?
    //                                                                                 <>
    //                                                                                     <svg className='add-to-audience-button-icon' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
    //                                                                                         <path d="M371.789-288Q387-288 397.5-298.289q10.5-10.29 10.5-25.5Q408-339 397.711-349.5q-10.29-10.5-25.5-10.5Q357-360 346.5-349.711q-10.5 10.29-10.5 25.5Q336-309 346.289-298.5q10.29 10.5 25.5 10.5Zm108 0Q495-288 505.5-298.289q10.5-10.29 10.5-25.5Q516-339 505.711-349.5q-10.29-10.5-25.5-10.5Q465-360 454.5-349.711q-10.5 10.29-10.5 25.5Q444-309 454.289-298.5q10.29 10.5 25.5 10.5Zm108 0Q603-288 613.5-298.289q10.5-10.29 10.5-25.5Q624-339 613.711-349.5q-10.29-10.5-25.5-10.5Q573-360 562.5-349.711q-10.5 10.29-10.5 25.5Q552-309 562.289-298.5q10.29 10.5 25.5 10.5ZM439-403l218-218-51-51-167 167-85-85-51 51 136 136Zm41.276 307Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z" />
    //                                                                                     </svg>
    //                                                                                     {/* <span className='corner-svg'><CheersGlass /></span> */}
    //                                                                                     Already Sent
    //                                                                                 </>
    //                                                                                 :
    //                                                                                 <span onClick={addCurrentUserToAudience}>
    //                                                                                     <svg className='audience-btn-svg' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M120 936v-80h80V296q0-33 23.5-56.5T280 216h400q33 0 56.5 23.5T760 296v560h80v80H120Zm560-80V296H280v560h400ZM560 616q17 0 28.5-11.5T600 576q0-17-11.5-28.5T560 536q-17 0-28.5 11.5T520 576q0 17 11.5 28.5T560 616ZM280 296v560-560Z" /></svg>
    //                                                                                     {/* <span className='corner-svg'><CheersGlass /></span> */}
    //                                                                                     Send Request
    //                                                                                 </span>
    //                                                                         }
    //                                                                     </button>
    //                                                                 </div>
    //                                                                 :
    //                                                                 null
    //                                                         }
    //                                                     </>
    //                                             }
    //                                         </>
    //                                         :
    //                                         <div className="large-container">
    //                                             <button className="add-to-audience-button step-out-btn" disabled={userData.isUserBlockedByReceiver} onClick={stepOutFromUserReceiver}>
    //                                                 <svg className='add-to-audience-button-icon' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" /></svg>
    //                                                 Step Out
    //                                             </button>
    //                                         </div>
    //                                 }
    //                             </div>
    //                             {/* <span className="close-button" onClick={openClose}> */}
    //                             {/* <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg> */}
    //                             {/* </span> */}
    //                         </>
    //                         :
    //                         <div className='err-message'></div>
    //                 }
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default UserDetailPopup;