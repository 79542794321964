import { useEffect } from "react";

import "./shortcuts.css";

function ShortCutModal({ t, isShortcutModalOpen, closeShortcutModal, openFeedbackContainer }) {

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Escape' && isShortcutModalOpen) {
                closeShortcutModal();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [isShortcutModalOpen, closeShortcutModal]);

    if (!isShortcutModalOpen) {
        return null;
    }

    return (
        <div id="shortcut-modal-main">
            <div className="shortcut-modal">
                <div className='shortcut-modal-header'>
                    {t("Shortcuts")} <small>(<kbd>Ctrl</kbd> + <kbd>I</kbd>)</small>
                    <div className='clr-white make-text-cursor flt-rgt svg-fill-white svg-fill-gey-hover' onClick={closeShortcutModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                    </div>
                    <hr />
                </div>
                <div className="shortcut-container">
                    <div className="srt-row">
                        <div className="srt-col">
                            <ul>
                                <li><strong>{t("Home")} : </strong> <kbd>S</kbd> + <kbd>H</kbd></li>
                                <li><strong>{t("UpShots")} : </strong> <kbd>S</kbd> + <kbd>U</kbd></li>
                                <li><strong>{t("Search")} : </strong> <kbd>S</kbd> + <kbd>S</kbd></li>
                            </ul>
                        </div>
                        <div className="srt-col">
                            <ul>
                                <li><strong>{t("Watch Later")} : </strong> <kbd>S</kbd> + <kbd>W</kbd></li>
                                <li><strong>{t("Trending")} : </strong> <kbd>S</kbd> + <kbd>T</kbd></li>
                                <li><strong>{t("Create UpShot")} : </strong><kbd>Ctrl or ⌘</kbd> + <kbd>M</kbd></li>
                            </ul>
                        </div>
                        <div className="srt-col">
                            <ul>
                                <li><strong>{t("Tournaments")} : </strong> <kbd>S</kbd> + <kbd>D</kbd></li>
                                <li><strong>{t("Help")} : </strong> <kbd>Alt</kbd> + <kbd>H</kbd></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ShortCutModal;