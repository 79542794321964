import { useState } from 'react';
import { useTranslation } from "react-i18next";

import showToastError from '../../../helpers/showToastError';
import CustomUpShotVisibilityModal from '../CustomUpShotVisibilityModal';
import "../styles/visibilityStyles.css";

function UpShotVisibility({ activeTabState, upShotVisibilityState, setUpShotVisibilityState }) {
    const { t } = useTranslation();
    const [customOptionModalOpen, setCustomOptionModalOpen] = useState(false);

    const updateSelectedVisibilityType = (selectedType) => {
        setUpShotVisibilityState(prevState => ({
            ...prevState,
            selectedVisibilityType: selectedType,
        }))
    }

    // ? Only triggers when user clicks on "Custom".
    // * Tasks
    // ? Set visibility as "custom".
    // ? Opens up the user selection modal.
    const updateCustomTypeAndOpenModal = () => {
        setCustomOptionModalOpen(true);
        updateSelectedVisibilityType("custom")
    };

    const validateAndSaveCustomUsers = () => {
        const addBothUsers = upShotVisibilityState.includedUsers.length + upShotVisibilityState.excludedUsers.length
        // ? Check if user selected "Custom" if list have 0 length.
        if (upShotVisibilityState.selectedVisibilityType === "custom") {
            // ? If user didn't add any Include/Exclude users and selected "Custom Users" then show error.
            if (addBothUsers <= 0) {
                showToastError("Add some users in the Include/Exclude list or otherwise It will not show to anyone.", "error")
            } else {
                // ? This removes the audience popup.
                setCustomOptionModalOpen(false);
            }
        } else {
            // ? Save if user didn't select "Custom".
            setCustomOptionModalOpen(false);
        }
    }

    return (
        <>
            <CustomUpShotVisibilityModal
                show={customOptionModalOpen}
                onHide={() => setCustomOptionModalOpen(false)}
                setUpShotVisibilityState={setUpShotVisibilityState}
                upShotVisibilityState={upShotVisibilityState}
            />
            {
                activeTabState === "visibility" ?

                    <ul id="custom-list" className="visib-options">
                        <li data-tab="public" className={`${upShotVisibilityState.selectedVisibilityType === "public" ? "clicked" : ""}`} onClick={(e) => updateSelectedVisibilityType("public")}>
                            <svg className='audience-svg' xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 96 960 960" width="35"><path d="M480 976q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Zm-40-82v-78q-33 0-56.5-23.5T360 736v-40L168 504q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440 894Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600 280v16q0 33-23.5 56.5T520 376h-80v80q0 17-11.5 28.5T400 496h-80v80h240q17 0 28.5 11.5T600 616v120h40q26 0 47 15.5t29 40.5Z" /></svg>
                            <div className="description">
                                <b>Public</b>
                                <span>Everyone can interact with it.</span>
                            </div>
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" checked={upShotVisibilityState.selectedVisibilityType === "public" ? true : false} />
                                <div className="checkbox-custom"></div>
                            </div>
                        </li>
                        <li data-tab="audience" className={`${upShotVisibilityState.selectedVisibilityType === "audience" ? "clicked" : ""}`} onClick={(e) => updateSelectedVisibilityType("audience")}>
                            <svg className='audience-svg' xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 96 960 960" width="35"><path d="M46.667 644q-23.334-40.666-35-82Q0 520.667 0 480q0-110.667 76.667-187.333Q153.333 216 264 216q63 0 119.667 26.167Q440.333 268.333 480 314q39.667-45.667 96.333-71.833Q633 216 696 216q110.667 0 187.333 76.667Q960 369.333 960 480q0 40.667-11.667 81.667-11.666 41-35 81.667-10.333-14.333-23.666-25-13.334-10.666-29.334-17.666 16.334-31.001 24.667-61.001 8.334-30 8.334-59.667 0-82.667-57.334-140-57.333-57.334-140-57.334-63.666 0-113.833 36.5T480 417.999q-52-62.667-102.167-99T264 282.666q-82.667 0-140 57.334-57.334 57.333-57.334 140 0 29.667 8.334 59.667 8.333 30 24.667 61.001-16 7-29.334 17.999Q57 629.667 46.668 644ZM0 976v-56.333q0-40.667 42.833-65.5 42.834-24.833 110.5-24.833 13 0 24.333.5 11.334.5 22.334 2.5-10 18-15 37.333T180 911v65H0Zm240 0v-65q0-65 66.5-105T480 766q108 0 174 40t66 105v65H240Zm540 0v-65q0-22-4.5-41.333t-14.166-37.333q11-2 22.166-2.5 11.167-.5 23.167-.5 68.666 0 111 24.833Q960 879 960 919.667V976H780ZM480 832.666q-72.334 0-120.667 21-48.334 21.001-51 52.334v3.334H652v-4q-2.999-30.667-50.833-51.668-47.833-21-121.167-21Zm-326.667-36.665q-30.333 0-51.833-21.5T80 722.667q0-30.666 21.5-51.999 21.5-21.334 51.833-21.334 30.667 0 52 21.334 21.333 21.333 21.333 51.999 0 30.334-21.333 51.834-21.333 21.5-52 21.5Zm653.334 0q-30.333 0-51.833-21.5t-21.5-51.834q0-30.666 21.5-51.999 21.5-21.334 51.833-21.334 30.666 0 52 21.334Q880 692.001 880 722.667q0 30.334-21.333 51.834-21.334 21.5-52 21.5ZM480 736q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600 616q0 50-34.5 85T480 736Zm0-173.334q-22.333 0-37.834 15.167-15.5 15.167-15.5 38.167 0 22.333 15.5 37.834 15.501 15.5 37.834 15.5 23 0 38.167-15.5 15.167-15.501 15.167-37.834 0-23-15.167-38.167Q503 562.666 480 562.666ZM480 616Zm.333 293.334Z" /></svg>
                            <div className="description">
                                <b>Audience</b>
                                <span>Only your Audience will be able to interact it.</span>
                            </div>
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" checked={upShotVisibilityState.selectedVisibilityType === "audience" ? true : false} />
                                <div className="checkbox-custom"></div>
                            </div>
                        </li>
                        <li data-tab="onlyMe" className={`${upShotVisibilityState.selectedVisibilityType === "onlyMe" ? "clicked" : ""}`} onClick={(e) => updateSelectedVisibilityType("onlyMe")}>
                            <svg className='audience-svg' xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 96 960 960" width="35"><path d="M186.666 896q-27 0-46.833-19.833T120 829.334V242.666q0-27 19.833-46.833T186.666 176h586.668q27 0 46.833 19.833T840 242.666v240.001q-15.667-7-32.333-12.167-16.667-5.167-34.333-8.167V242.666H186.666V632H356q15.667 28.334 39 49.001 23.333 20.666 52 28.666-2.333 17.333-2 34.167.333 16.833 2.667 33.499Q411 771 378.5 750.833 346 730.667 324 698.667H186.666v130.667h273.668q6.333 18 14.333 34.666 8 16.667 19 32H186.666Zm0-66.666H460.334 186.666ZM691.667 936l-7.333-52q-19.334-6.333-35.501-15.833-16.166-9.5-29.166-20.834l-47.334 19.334L535.667 808l40.666-33.333q-4-16.667-4-36.334 0-19.666 4-36.333l-40.666-33.333 36.666-59.333 47.334 19.333q13-10.667 29.166-20.167Q665 599 684.334 592.667L691.667 540h66.666l8 52.667q19.334 6.333 35.5 15.833Q818 618 831 628.667l47.333-19.333L915 668.667 874.333 702q4.001 16.667 4.001 36.333 0 19.667-4.001 36.334L915 808l-36.667 58.667L831 847.333q-13 11.334-29.167 20.834-16.166 9.5-35.5 15.833l-8 52h-66.666ZM725 832.667q40.334 0 67.5-27.167 27.167-27.166 27.167-67.5 0-40.333-27.167-67.5-27.166-27.167-67.5-27.167-40.333 0-67.5 27.167T630.333 738q0 40.334 27.167 67.5 27.167 27.167 67.5 27.167Z" /></svg>
                            <div className="description">
                                <b>Only Me</b>
                                <span>Only you will be able to see it.</span>
                            </div>
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" checked={upShotVisibilityState.selectedVisibilityType === "onlyMe" ? true : false} />
                                <div className="checkbox-custom"></div>
                            </div>
                        </li>
                        <li data-tab="custom" className={`${upShotVisibilityState.selectedVisibilityType === "custom" ? "clicked" : ""}`} onClick={updateCustomTypeAndOpenModal}>
                            <svg className='audience-svg' xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 96 960 960" width="35"><path d="M431.333 936V706h66.666v82H840v66.667H497.999V936h-66.666ZM120 854.667V788h244.667v66.667H120Zm178.001-164v-81.334H120v-66.666h178.001V460h66.666v230.667h-66.666Zm133.332-81.334v-66.666H840v66.666H431.333Zm164-163.333V216h66.666v81.333H840V364H661.999v82h-66.666ZM120 364v-66.667h408.667V364H120Z" /></svg>
                            <div className="description">
                                <b>Custom</b>
                                <span>You choose Who to show and who to hide.</span>
                            </div>
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" checked={upShotVisibilityState.selectedVisibilityType === "custom" ? true : false} />
                                <div className="checkbox-custom"></div>
                            </div>
                        </li>
                    </ul>
                    :
                    null
            }
        </>
    )
}

export default UpShotVisibility;