import { Link } from "react-router-dom";

import "./styles/profileDropdown.css";
import { isPremiumEnabled } from "../../../components/constants/constants";

function ProfileMenuHeaderDropdown({ isProfilePanelOpen, theme, openCloseProfileMenuPanel, currentUserUsername, openUserSettings, setShortModalOpen, logout, toggleTheme, t, userOnPremium }) {

    if (!isProfilePanelOpen) {
        return null;
    }

    return (
        <div className="sec-center">
            <div className={`section-dropdown`}>
                {
                    currentUserUsername &&
                    <>
                        <Link to={`/profile/${currentUserUsername}/`} style={{ textDecoration: 'none' }}>
                            <svg className='svg-inline-v2 fill-black mg-r-10x' xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M480 576q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42ZM192 864v-96q0-23 12.5-43.5T239 690q55-32 116.292-49 61.293-17 124.5-17Q543 624 604.5 641T721 690q22 13 34.5 34t12.5 44v96H192Zm72-72h432v-24q0-5.177-3.025-9.412Q689.95 754.353 685 752q-46-28-98-42t-107-14q-55 0-107 14t-98 42q-5 4-8 7.724T264 768v24Zm216.212-288Q510 504 531 482.788q21-21.213 21-51Q552 402 530.788 381q-21.213-21-51-21Q450 360 429 381.212q-21 21.213-21 51Q408 462 429.212 483q21.213 21 51 21ZM480 432Zm0 360Z" /></svg>
                            {t("Profile")}
                        </Link>
                        <Link to='/requests/' onClick={openCloseProfileMenuPanel}>
                            <svg className='svg-inline-v2 fill-black mg-r-10x' xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16"> <path d="M2 4a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1h6a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2Zm.5 2h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1ZM14 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-12 1a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Z" /> </svg>
                            {t("Audience Request")}
                        </Link>
                        <a href="#" onClick={() => toggleTheme(theme === "light" ? "dark" : "light")}>
                            <svg height="25" viewBox="0 96 960 960" width="25" className="svg-inline-v2 fill-black mg-r-10x" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M785.334 194.943c174.789 174.787 174.789 459.179 0 633.967-174.787 174.787-459.178 174.787-633.967 0-13.206-13.205-26.22-28.336-39.807-46.314a19.672 19.672 0 0 1-2.223-20.012 19.777 19.777 0 0 1 16.54-11.442c98.838-6.698 191.601-48.753 261.234-118.386C530.853 489.014 546.472 258.475 423.392 96.51a19.553 19.553 0 0 1-2.249-19.981 19.554 19.554 0 0 1 16.54-11.497c129.587-8.759 256.325 38.583 347.651 129.911z" fill="#897acb" /><path d="M785.334 194.943c-14.266-14.268-29.484-27.325-45.354-39.399 151.302 175.925 143.723 442.269-22.987 608.98-121.85 121.85-307.044 190.195-461.161 142.154 60.038 35.511 140.886 47.603 167.101 50.984 129.417 13.067 263.464-29.816 362.401-128.753 174.789-174.787 174.789-459.179 0-633.966z" fill="#030504" /></svg>
                            {theme === "dark" ? <>{t("Light Theme")}</> : <>{t("Dark Theme")}</>}
                        </a>
                        {
                            isPremiumEnabled
                            &&
                            <>
                                {
                                    userOnPremium ?
                                        <span className="premium-button-v2 on-premium"><b>{t("You're on Premium")}</b></span>
                                        :
                                        <Link to={`/premium`} className="premium-button-v2"><b>{t("Upgrade to Premium")}</b></Link>
                                }
                            </>
                        }
                        <hr className='dark-light-hr' />
                        <a href="#" style={{ textDecoration: 'none' }} onClick={openUserSettings}>
                            <svg className='svg-inline-v2 fill-black mg-r-10x make-text-cursor' xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="m367 976-15-126q-10-6-22-13t-22-13l-117 49L78 678l100-76v-52L78 474l113-195 117 49q10-6 22-13t22-13l15-126h226l15 126q10 6 22 13t22 13l117-49 113 195-99 76v52l99 76-113 195-117-49q-10 6-22 13t-22 13l-15 126H367Zm113-257q59 0 101-42t42-101q0-59-42-101t-101-42q-59 0-101 42t-42 101q0 59 42 101t101 42Zm0-84q-24 0-41.5-17.5T421 576q0-24 17.5-41.5T480 517q24 0 41.5 17.5T539 576q0 24-17.5 41.5T480 635Zm1-59Zm-41 316h80l12-104q29-8 56-24t49-38l97 41 40-66-85-65q5-14 7-29t2-31q0-14-2-28.5t-6-30.5l85-66-40-66-97 42q-23-23-49.5-38.5T533 365l-13-105h-80l-13 105q-29 8-55.5 23.5T323 426l-97-41-40 66 84 65q-4 16-6 31t-2 29q0 14 2 28.5t6 30.5l-84 66 40 66 97-41q22 22 48.5 37.5T427 787l13 105Z" /></svg>
                            {t("User Settings")}
                        </a>
                        <hr className='dark-light-hr' />
                    </>
                }
                {/* <Link to={"/login"}>
                    <button type='button' className='header-login-button-glow-on-hover'>Login</button>
                    Login
                </Link> */}

                <Link to={`contact`} onClick={openCloseProfileMenuPanel}>
                    <svg className='svg-inline-v2 fill-black mg-r-10x' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-120v-80h320v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v244h-40q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H440Zm-80-280q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z" /></svg>
                    {t("Contact us")}</Link>
                <Link to={`/help`} onClick={openCloseProfileMenuPanel}>
                    <svg className='svg-inline-v2 fill-black mg-r-10x' xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M480 816q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-36-153h73q0-37 6.5-52.5T555 571q35-34 48.5-58t13.5-53q0-55-37.5-89.5T484 336q-51 0-88.5 27T343 436l65 27q9-28 28.5-43.5T482 404q28 0 46 16t18 42q0 23-15.5 41T496 538q-35 32-43.5 52.5T444 663Zm36 297q-79 0-149-30t-122.5-82.5Q156 795 126 725T96 576q0-80 30-149.5t82.5-122Q261 252 331 222t149-30q80 0 149.5 30t122 82.5Q804 357 834 426.5T864 576q0 79-30 149t-82.5 122.5Q699 900 629.5 930T480 960Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z" /></svg>
                    {t("Help Center")}
                </Link>
                {
                    isPremiumEnabled
                    &&
                    <>
                        {
                            !currentUserUsername &&
                            <Link to={`/premium`} className="premium-button-v2"><b>{t("More about Premium")}</b></Link>
                        }
                    </>
                }
                <input className="dropdown-sub" type="checkbox" id="dropdown-sub" name="dropdown-sub" />
                <label htmlFor="dropdown-sub">
                    {t("More")}
                    <svg className="uil uil-plus" xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M444 816V612H240v-72h204V336h72v204h204v72H516v204h-72Z" /></svg>
                </label>
                <div className="section-dropdown-sub">
                    <Link to={`/about`} onClick={openCloseProfileMenuPanel}>
                        <svg className='svg-inline-v2 fill-black mg-r-10x' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M51 792q9-105 68.5-191T271 463l-76-131q-5-9-2.375-18.2T204 300q8-5 18-3t15 11l77 134q80-34 166.5-34T647 442l76-134q5-9 15-11t18 3q8.75 4.6 11.375 13.8Q770 323 765 332l-76 131q92 52 151.5 138T909 792H51Zm236.887-53Q308 739 322 725.113q14-13.888 14-34Q336 671 322.113 657q-13.888-14-34-14Q268 643 254 656.887q-14 13.888-14 34Q240 711 253.887 725q13.888 14 34 14Zm384 0Q692 739 706 725.113q14-13.888 14-34Q720 671 706.113 657q-13.888-14-34-14Q652 643 638 656.887q-14 13.888-14 34Q624 711 637.887 725q13.888 14 34 14Z" /></svg>
                        {t("About us")}
                    </Link>
                    <Link to={`/app-for/ios`} onClick={openCloseProfileMenuPanel}>
                        <svg className='svg-inline-v2 fill-black mg-r-10x' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M264 1008q-29.7 0-50.85-21.15Q192 965.7 192 936V216q0-29.7 21.15-50.85Q234.3 144 264 144h432q29.7 0 50.85 21.15Q768 186.3 768 216v720q0 29.7-21.15 50.85Q725.7 1008 696 1008H264Zm0-216v144h432V792H264Zm215.789 108Q495 900 505.5 889.711q10.5-10.29 10.5-25.5Q516 849 505.711 838.5q-10.29-10.5-25.5-10.5Q465 828 454.5 838.289q-10.5 10.29-10.5 25.5Q444 879 454.289 889.5q10.29 10.5 25.5 10.5ZM264 720h432V336H264v384Zm0-456h432v-48H264v48Zm0 528v144-144Zm0-528v-48 48Z" /></svg>
                        {t("MoreOptions.App for ios")}
                    </Link>
                    <Link to={`/app-for/android`} onClick={openCloseProfileMenuPanel}>
                        <svg className='svg-inline-v2 fill-black mg-r-10x' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M51 792q9-105 68.5-191T271 463l-76-131q-5-9-2.375-18.2T204 300q8-5 18-3t15 11l77 134q80-34 166.5-34T647 442l76-134q5-9 15-11t18 3q8.75 4.6 11.375 13.8Q770 323 765 332l-76 131q92 52 151.5 138T909 792H51Zm236.887-53Q308 739 322 725.113q14-13.888 14-34Q336 671 322.113 657q-13.888-14-34-14Q268 643 254 656.887q-14 13.888-14 34Q240 711 253.887 725q13.888 14 34 14Zm384 0Q692 739 706 725.113q14-13.888 14-34Q720 671 706.113 657q-13.888-14-34-14Q652 643 638 656.887q-14 13.888-14 34Q624 711 637.887 725q13.888 14 34 14Z" /></svg>
                        {t("MoreOptions.App for Android")}
                    </Link>
                    {
                        currentUserUsername &&
                        <a href="#" className='font-size-10px' onClick={() => setShortModalOpen(true)}>
                            <svg className='svg-inline-v2 fill-black mg-r-10x' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M168 816q-29.7 0-50.85-21.15Q96 773.7 96 744V408q0-29.7 21.15-50.85Q138.3 336 168 336h624q29.7 0 50.85 21.15Q864 378.3 864 408v336q0 29.7-21.15 50.85Q821.7 816 792 816H168Zm0-72h624V408H168v336Zm168-24h288v-72H336v72Zm-96-120h72v-72h-72v72Zm102 0h72v-72h-72v72Zm102 0h72v-72h-72v72Zm102 0h72v-72h-72v72Zm102 0h72v-72h-72v72Zm-408-96h72v-72h-72v72Zm102 0h72v-72h-72v72Zm102 0h72v-72h-72v72Zm102 0h72v-72h-72v72Zm102 0h72v-72h-72v72ZM168 744V408v336Z" /></svg>
                            {t("MoreOptions.Keyboard Shortcuts")}
                        </a>
                    }
                </div>
                <Link to={`policies/privacy-policy`}>{t("Privacy Policy")}</Link>
                {
                    !currentUserUsername &&
                    <div className="lgi-btn-container-container">
                        <Link to="/signup" className="lgi-btn lgi-btn-primary">Signup</Link>
                        {/* <Link to="/signup" className="lgi-btn lgi-btn-secondary">Signup</Link> */}
                    </div>
                }
                {
                    currentUserUsername &&
                    <>
                        <hr className='dark-light-hr' />
                        <a href="#" onClick={logout}>
                            <svg className='svg-inline-v2 fill-black mg-r-10x' xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M216 912q-29.7 0-50.85-21.15Q144 869.7 144 840V312q0-29.7 21.15-50.85Q186.3 240 216 240h264v72H216v528h264v72H216Zm432-168-51-51 81-81H384v-72h294l-81-81 51-51 168 168-168 168Z" /></svg>
                            {t("Logout")}
                        </a>
                    </>
                }
            </div>
        </div>
    )
}

export default ProfileMenuHeaderDropdown;