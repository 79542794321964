import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Link,
} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ReCAPTCHA from 'react-recaptcha';

import SignupCorrect from '../../svgComponents/SignupCorrect';
import SignupWrong from '../../svgComponents/SignupWrong';
import showToastError from '../helpers/showToastError';
import { setAxiosAuthToken } from './LoginHelpers/LoginActionFunctions';
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';
import { validateEmail, validateUsername } from './LoginHelpers/helperFunctions';
import SpreeCineLoginSignupSVGLogo from '../../MainInterface/assets/SpreeCineLoginSignupLogoSVG';
import { gRecaptchaPublicKey } from '../constants/constants';
import LoadingIfElseErrorSvg from '../animations/LoadingIfElseErrorSvg';

// function AccountConfirmationMessagePopup() {

// }

function SignUpPage() {
    const [fetchedLanugageToTransform, setFetchedLanguageToTransform] = useState("en");
    const { t, i18n } = useTranslation('SignUpPage');
    i18n.addResourceBundle('en', 'SignUpPage', {
        signUpText: `Sign up`,
        username: "Username",
        email: "Email",
        password: "Password",
        signUpButtonText: "Create account",
        login: "Log in",
        immerseYourself: "Immerse Yourself in Our Exciting Environment, Including",
        talkAbout: "Talk about what would happen after the final scene of the movie you just watched.",
        finishTheMovie: "Finish the movie you enjoy and demonstrate it's ending in your own personal style.",
        giveAwards: "Give Awards to other user's Stories/Endings.",
        participate: "Participate in Alternate Tournaments and Get Featured!",
    });
    i18n.addResourceBundle('zh', 'SignUpPage', {
        signUpText: `报名`,
        username: "用户名",
        email: "电子邮件",
        password: "密码",
        signUpButtonText: "创建账户",
        login: "登录",
        immerseYourself: "让自己沉浸在我们令人兴奋的环境中，包括",
        talkAbout: "谈谈你刚刚看完的电影的最后一幕之后会发生什么。",
        finishTheMovie: "看完你喜欢的电影，并以你自己的个人风格展示它的结局。",
        giveAwards: "奖励其他用户的故事/结局。",
        participate: "参加替代锦标赛并获得推荐！",
    });
    i18n.addResourceBundle('jp', 'SignUpPage', {
        signUpText: `サインアップ`,
        username: "ユーザー名",
        email: "Eメール",
        password: "パスワード",
        signUpButtonText: "アカウントを作成する",
        login: "ログイン",
        immerseYourself: "当社のエキサイティングな環境に浸ってください。",
        talkAbout: "今見た映画の最後のシーンの後に何が起こるかについて話します。",
        finishTheMovie: "楽しんだ映画を観終えて、自分のスタイルで映画が終わることを実証してください。",
        giveAwards: "他のユーザーのストーリー/エンディングに賞を与えます。",
        participate: "代替トーナメントに参加して注目を集めましょう!",
    });
    i18n.addResourceBundle('it', 'SignUpPage', {
        signUpText: `Iscrizione`,
        username: "Nome utente",
        email: "E-mail",
        password: "Parola d'ordine",
        signUpButtonText: "Creare un account",
        login: "Login",
        immerseYourself: "Immergiti nel nostro entusiasmante ambiente, incluso",
        talkAbout: "Parla di cosa accadrebbe dopo la scena finale del film che hai appena visto.",
        finishTheMovie: "Termina il film che ti piace e dimostra che sta finendo nel tuo stile personale.",
        giveAwards: "Assegna premi alle storie/finali di altri utenti.",
        participate: "Partecipa a tornei alternativi e mettiti in primo piano!",
    });
    i18n.addResourceBundle('es', 'SignUpPage', {
        signUpText: `Inscribirse`,
        username: "Nombre de usuario",
        email: "Correo electrónico",
        password: "Contraseña",
        signUpButtonText: "Crear una cuenta",
        login: "Acceso",
        immerseYourself: "Sumérgete en nuestro apasionante entorno, que incluye",
        talkAbout: "Habla sobre lo que pasaría después de la escena final de la película que acabas de ver.",
        finishTheMovie: "Termina la película que disfrutas y demuestra su final con tu estilo personal.",
        giveAwards: "Dar premios a otras historias/finales de usuarios.",
        participate: "¡Participe en torneos alternativos y destaque!",
    });
    const [userConfirmationState, setUserConfirmationState] = useState({
        isConfirmationLinkSent: false,
    })
    const [signupState, setSignupState] = useState({
        isSignupLoading: false,
        signupFetchError: "",
    })
    const [userSignUpDataState, setUserSignUpDataState] = useState({
        username: "",
        password: "",
        email: "",

        areFieldsDisabled: false,
    });
    const [userSignUpErrors, setUserSignUpErrors] = useState({
        usernameError: "",
        passwordError: "",
        emailError: "",
        status: "",
    })
    const [usernameAvailabilityState, setUsernameAvailabilityState] = useState({
        isAvailable: false,
        isErrorSeen: false,
        isReserved: false,
    });
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [recaptchaResponse, setRecaptchaResponse] = useState('');
    const [captchaVerifiedServerFailed, setCaptchaVerifiedServerFailed] = useState(null);

    const handleGRecaptchaVerifyTrue = (response) => {
        setCaptchaVerified(true);
        setRecaptchaResponse(response);
    };

    const handleGRecaptchaVerifyFalse = () => {
        setCaptchaVerified(false);
    };

    const verifiyGRecaptchaInServer = () => {
        if (!captchaVerified) {
            showToastError("Something went wrong! Try reloading the Page", "warning");
            return;
        }
        axios.post("/g/recaptcha/", null, {
            params: {
                "g-recaptcha-response": recaptchaResponse
            }
        }).then(() => {
            onSignUpClick();
            setCaptchaVerifiedServerFailed(true);
        }).catch(() => {
            setCaptchaVerifiedServerFailed(false);
        })
    }

    const handleValidation = () => {
        let isValid = true;
        const newErrors = {
            usernameError: '',
            emailError: '',
            passwordError: '',
        };

        if (!userSignUpDataState.username.trim()) {
            newErrors.usernameError = 'Username cannot be blank';
            isValid = false;
        } else if (validateUsername(userSignUpDataState.username)) {
            newErrors.usernameError = 'Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters.';
            isValid = false;
        }

        if (!userSignUpDataState.email.trim()) {
            newErrors.emailError = 'Email cannot be blank';
            isValid = false;
        } else if (!validateEmail(userSignUpDataState.email)) {
            newErrors.emailError = 'Invalid email format';
            isValid = false;
        }

        if (!userSignUpDataState.password.trim()) {
            newErrors.passwordError = 'Password cannot be blank';
            isValid = false;
        }

        setUserSignUpErrors(newErrors);
        return isValid;
    };


    const onSignUpClick = () => {
        setSignupState({
            isSignupLoading: true,
            signupFetchError: ""
        })
        if (handleValidation()) {
            const userData = {
                username: userSignUpDataState.username,
                email: userSignUpDataState.email,
                password: userSignUpDataState.password,
            }
            setUsernameAvailabilityState({
                isErrorSeen: false,
            });
            setAxiosAuthToken("");
            axios.post("/api/v1/users/", userData).then(() => {
                setUserSignUpDataState(prevState => ({
                    ...prevState,
                    status: "success",
                    areFieldsDisabled: true
                }));
                setUserConfirmationState({
                    isConfirmationLinkSent: true
                });
                setSignupState({
                    isSignupLoading: false,
                    signupFetchError: ""
                });
            })
                .catch((error) => {
                    setSignupState({
                        isSignupLoading: false,
                        signupFetchError: "Something went Wrong!"
                    })
                    if (error.response) {
                        setUserSignUpErrors(prevState => ({
                            ...prevState,
                            isErrorSeen: false,
                            usernameError: error?.response?.data["username"] || "",
                            emailError: error?.response?.data["email"] || "",
                            passwordError: error?.response?.data["password"] || "",
                            status: error?.response?.data["detail"] ? "error" : "",
                        }));
                    } else {
                        setUserSignUpErrors(prevState => ({
                            ...prevState,
                            status: "error",
                        }));
                    }
                })
        } else {
            return null;
        }
    }

    const editTypedMail = () => {
        axios.delete(`/api/users/soft-d/user/on-signup/${userSignUpDataState.username}`).then((res) => {
            setUserConfirmationState({
                isConfirmationLinkSent: false
            })
            setUserSignUpDataState(prevState => ({
                ...prevState,
                areFieldsDisabled: false
            }));
        }).catch(() => {
        })
    }

    const fetchCountryAndSetLanguage = () => {
        const languagesForCountry = [
            { "countryCode": "IN", "languageCode": "en" },
            { "countryCode": "ES", "languageCode": "es" },
            { "countryCode": "MX", "languageCode": "es" },
            { "countryCode": "AR", "languageCode": "es" },
            { "countryCode": "CO", "languageCode": "es" },
            { "countryCode": "PY", "languageCode": "es" },
            { "countryCode": "CR", "languageCode": "es" },
            { "countryCode": "UY", "languageCode": "es" },
            { "countryCode": "IT", "languageCode": "it" },
            { "countryCode": "JP", "languageCode": "jp" },
            { "countryCode": "CN", "languageCode": "zh" },
        ]
        axios.get("/api/users/primary/get_user_lang/").then((res) => {
            languagesForCountry.map((lang) => lang.countryCode === res.data.country && setFetchedLanguageToTransform(lang.languageCode));
        }).catch(() => {
        })
    }

    useEffect(() => {
        i18n.changeLanguage(fetchedLanugageToTransform)
    }, [fetchedLanugageToTransform])

    useEffect(() => {
        fetchCountryAndSetLanguage();
    }, [])

    const checkIfTypedUsernameIsAvailableOrNot = () => {
        if (!validateUsername(userSignUpDataState.username)) {
            if (userSignUpDataState.username) {
                axios.get(`/api/users/check_if_username_is_used_or_not/${userSignUpDataState.username}/`).then((res) => {
                    setUsernameAvailabilityState({
                        isAvailable: res.data.available,
                        isErrorSeen: true,
                        isReserved: res.data.isr
                    });
                    setUserSignUpErrors(prevState => ({
                        ...prevState,
                        usernameError: "",
                    }))
                }).catch(() => {
                })
            } else {
                setUsernameAvailabilityState({
                    isErrorSeen: false,
                })
            }
        } else {
            setUserSignUpErrors(prevState => ({
                ...prevState,
                usernameError: "Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters."
            }))
            setUsernameAvailabilityState({
                isErrorSeen: false,
            });
        }
    }

    return (
        <LayoutComponentForHelmet pageTitle={`Signup - Title`}>
            <div className="user-gettin-main-container">
                <div className="user-getting-left">
                    <div className="flower-container">
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                        <div className="square"></div>
                    </div>
                    <div className="left-container-data-signup">
                        <h1>{t("immerseYourself")}</h1>
                        <div className="features-container">
                            <ul className="signup-features-list">
                                <li>{t("talkAbout")}</li>
                                <li>{t("finishTheMovie")}</li>
                                <li>{t("giveAwards")}</li>
                                <li>{t("participate")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="user-getting-right">
                    <form className="login-form">
                        <div className="login-container-v2">
                            <div className="login-content">
                                <SpreeCineLoginSignupSVGLogo svgSizeWidth={"60%"} bgcolor={"#3e2d3f"} />
                                {/* <h1 className='login-brand-name'>SpreeCine</h1> */}
                                <input
                                    type="text"
                                    placeholder={t("username")}
                                    className="user-login-signup-input-field"
                                    value={userSignUpDataState.username}
                                    onChange={(e) => setUserSignUpDataState(prevState => ({ ...prevState, username: e.target.value }))}
                                    onBlur={() => checkIfTypedUsernameIsAvailableOrNot()}
                                    disabled={userSignUpDataState.areFieldsDisabled ? true : false}
                                />
                                {
                                    userSignUpErrors.usernameError &&
                                    <>
                                        <b className='signup-error-msg' style={{ color: "red" }}>{userSignUpErrors.usernameError}</b>
                                        <br />
                                    </>
                                }
                                {
                                    usernameAvailabilityState.isErrorSeen ?
                                        <>
                                            {
                                                usernameAvailabilityState.isAvailable ?
                                                    <span className='available-svg-text'><SignupCorrect />Username is <b>available</b></span>
                                                    :
                                                    <>
                                                        {
                                                            !usernameAvailabilityState.isReserved ?
                                                                <span className='not-available-svg-text'><SignupWrong />This username is already <b>Taken</b></span>
                                                                :
                                                                <span className='not-available-svg-text'><SignupWrong />This username is <b>Not Available</b></span>
                                                        }
                                                    </>
                                            }
                                        </>
                                        :
                                        null
                                }
                                <input
                                    type="text"
                                    placeholder={t("email")}
                                    className="user-login-signup-input-field"
                                    value={userSignUpDataState.email}
                                    onChange={(e) => setUserSignUpDataState(prevState => ({ ...prevState, email: e.target.value }))}
                                    disabled={userSignUpDataState.areFieldsDisabled ? true : false}
                                />
                                <b className='signup-error-msg' style={{ color: "red" }}>{userSignUpErrors.emailError}</b>
                                <input
                                    type="password"
                                    placeholder={t("password")}
                                    className="user-login-signup-input-field"
                                    value={userSignUpDataState.password}
                                    onChange={(e) => setUserSignUpDataState(prevState => ({ ...prevState, password: e.target.value }))}
                                    disabled={userSignUpDataState.areFieldsDisabled ? true : false}
                                />
                                <b className='signup-error-msg' style={{ color: "red" }}>{userSignUpErrors.passwordError}</b>
                                {
                                    userSignUpDataState.password &&
                                    <>
                                        <ReCAPTCHA
                                            sitekey={gRecaptchaPublicKey}
                                            render="explicit"
                                            onloadCallback={() => console.log('reCAPTCHA loaded')}
                                            verifyCallback={handleGRecaptchaVerifyTrue}
                                            expiredCallback={handleGRecaptchaVerifyFalse}
                                            theme="dark"
                                        />
                                        <br />
                                    </>
                                }
                                {
                                    userConfirmationState.isConfirmationLinkSent ?
                                        <div className='confirmation-sent-text'>
                                            An email confirmation has been sent to the email address provided.
                                            <div>Want to change mail? <b className='underline-text make-text-cursor' onClick={editTypedMail}>Edit</b></div>
                                        </div>
                                        :
                                        null
                                }
                                <div className="signup-button-container">
                                    <button type="button" className="main-button" onClick={onSignUpClick} disabled={!captchaVerified}>
                                        {t("signUpButtonText")}
                                        <LoadingIfElseErrorSvg isLoading={signupState.isSignupLoading} errorString={signupState.signupFetchError} loadingLogoBigOrSmall={"small"} classNamesForLoading="svg-inline rotate flt-rgt" classNamesForError="svg-inline flt-rgt svg-fill-red" />
                                    </button>
                                    <div className="secondary-buttons">
                                        <Link to={"/login/"}>
                                            <button type="button">{t("login")}</button>
                                        </Link>
                                        <Link to={"/reset-password/"}>
                                            <button type="button">{t("Forget Password?")}</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default SignUpPage;