import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useTranslation } from "react-i18next";

import "./eqModal.css";
import LoadingIfElseErrorSvg from '../animations/LoadingIfElseErrorSvg';
import { defaultBackendWebSocketOrigin } from '../constants/constants';

function EQuestionModal({ isEQModalOpen, closeEQModal }) {
    const { t } = useTranslation();
    const [checkedAnswer, setCheckedAnswer] = useState("");
    const [isEmpty, setIsEmpty] = useState(false);
    const [eQState, seteQState] = useState({
        isEQLoading: false,
        eQError: "",

        fetchedEQ: {},
    })
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const [postState, setPostState] = useState({
        wasPostSuccess: false,
        isPostLoading: false,
        postError: "",
    })

    const fetchEQ = () => {
        setIsEmpty(false)
        seteQState(prevState => ({
            ...prevState,
            isEQLoading: true,
            eQError: "",
        }))
        axios.get("/api/trivia/get/everyday-question/").then((res) => {
            setIsEmpty(false)
            seteQState(prevState => ({
                ...prevState,
                isEQLoading: false,
                eQError: "",
                fetchedEQ: res.data.data
            }))
        }).catch((err) => {
            if (err.response.status === 425) {
                setIsEmpty(true);
            }
            seteQState(prevState => ({
                ...prevState,
                isEQLoading: false,
                eQError: err.message,
            }))
        })
    }

    useEffect(() => {
        if (isEQModalOpen) {
            fetchEQ();
            setPostState({
                isPostLoading: false,
                postError: "",
                wasPostSuccess: false,
            })
        }
        setCheckedAnswer("");
    }, [isEQModalOpen])

    const sendNotification = () => {
        // const authToken = "Token 6b3d371918a2a325a4d6e6bed7dba9e483866feb"
        // const wsUrl = 'wss://${defaultBackendWebSocketOrigin}/ws/notifications/';
        // let socket = null;


        const socket = new WebSocket(`wss://${defaultBackendWebSocketOrigin}/ws/notifications/?token=${"6b3d371918a2a325a4d6e6bed7dba9e483866feb"}`);
        socket.onopen = () => {
            console.log("Connected to EQModel");
            // Once the WebSocket connection is open, send the authentication header
            socket.send(JSON.stringify({ Authorization: 'Token 6b3d371918a2a325a4d6e6bed7dba9e483866feb' }));
        };


        // const socket = new WebSocket('wss://${defaultBackendWebSocketOrigin}/ws/notifications/', [
        //     'Authorization: Token 6b3d371918a2a325a4d6e6bed7dba9e483866feb',
        //   ]);

        // Establish WebSocket connection when the component mounts
        // socket = new WebSocket(wsUrl);

        // WebSocket on message event listener
        socket.onmessage = (event) => {
            const notification = JSON.parse(event.data);
            // Handle the incoming notification, e.g., display a toast or update notifications state
        };

        return () => {
            // Close WebSocket connection when the component unmounts
            socket.close();
        };
    }

    const submitAnswer = () => {
        setPostState({
            isPostLoading: true,
            postError: "",
            wasPostSuccess: false,
        })
        const dataToPut = ({ qId: eQState.fetchedEQ.id, answerMarked: checkedAnswer })
        // axios.put("/check-answer/eq-question/").then((res) => {
        axios.put("/api/trivia/get/everyday-question/", dataToPut).then((res) => {
            setPostState({
                isPostLoading: false,
                postError: "",
                wasPostSuccess: true,
            })
            if (res.data.message === "CORRECT") {
                setIsAnswerCorrect(true);
            } else {
                setIsAnswerCorrect(false);
            }
            sendNotification();
        }).catch((err) => {
            setPostState({
                isPostLoading: false,
                postError: err.message,
                wasPostSuccess: false,
            })
        })
    }

    return (
        <>
            {
                isEQModalOpen &&
                <>
                    <div className={`quest-modal-main ${!isEQModalOpen ? "quest-modal-hide" : "quest-modal-show"}`}>
                        <div className="quest-modal">
                            {
                                !isEmpty ?
                                    <div className={`quest-container`}>
                                        <div className="quest-question">{eQState?.fetchedEQ?.question_statement}</div>
                                        {
                                            eQState.fetchedEQ.question_options?.map((option, index) =>
                                                <div className="quest-option" key={option}>
                                                    <input type="radio" name={`option-${index}`} value={option} id={option} className="quest-input" checked={checkedAnswer === option ? true : false} onChange={() => setCheckedAnswer(option)} />
                                                    <label htmlFor={option} className="quest-checkbox"></label>
                                                    <span className="quest-label" onClick={() => setCheckedAnswer(option)}>{option}</span>
                                                </div>
                                            )
                                        }
                                        {
                                            postState.wasPostSuccess ?
                                                <div className={`quest-depict ${isAnswerCorrect ? "correct" : "wrong"}`}>{isAnswerCorrect ? <>{t("Correct")}</> : <>{t("Wrong")}</>}</div>
                                                :
                                                null
                                        }
                                        <button className="quest-submit-button btn-hover-red" onClick={closeEQModal}>{t("Close")}</button>
                                        <button className="quest-submit-button flt-rgt" onClick={submitAnswer}>
                                            {t("Submit")}
                                            <LoadingIfElseErrorSvg isLoading={postState.isPostLoading} errorString={postState.postError} classNamesForLoading={"error-retry retry-rotate fill-black vertical-middle-middle"} classNamesForError={"svg-vertical-align fill-red vertical-middle-middle"} retry={fetchEQ} />
                                        </button>
                                    </div>
                                    :
                                    <div className="quest-container">
                                        {t("No Question for today, check back later")}
                                        <button className="quest-submit-button btn-hover-red flt-rgt" onClick={closeEQModal}>{t("Close")}</button>
                                    </div>
                            }
                        </div>
                    </div>
                </>
            }
        </>

    )
}

export default EQuestionModal;