import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import showToastError from "../../helpers/showToastError";
import "./styles/draftsStyles.css";
import { Modal } from "@mantine/core";

function UpShotDraftsModal({
  isDraftModalOpen,
  setDraftModalOpen,
  setCurrentSelectedDraft,
  upShotDataState,
}) {
  const { t } = useTranslation();

  // * Drafts - START
  const [draftState, setDraftState] = useState({
    isDraftModalOpen: true,
    isFetchDraftLoading: false,
    fetchedDrafts: [],
    fetchedDraftError: null,
  });
  // * Drafts - END

  const closeDraftModal = () => {
    setDraftModalOpen(false);
  };

  // ! IT'S ADEVNTOURES TO PUT DIFFERENT CASES ON MOVIE/TV SCENES.

  useEffect(() => {
    if (isDraftModalOpen) {
      if (draftState?.fetchedDrafts?.length <= 0) {
        fetchDrafts();
      }
    }
  }, [isDraftModalOpen]);

  const fetchDrafts = () => {
    setDraftState((prevState) => ({
      ...prevState,
      isFetchDraftLoading: true,
      fetchedDraftError: null,
    }));
    // ? Fetch the drafts on Page Load.
    axios
      .get("/api/content/main/upshot/drafts/")
      .then((res) => {
        setDraftState((prevState) => ({
          ...prevState,
          fetchedDrafts: res.data,
          isFetchDraftLoading: false,
          fetchedDraftError: null,
        }));
      })
      .catch(() => {
        setDraftState((prevState) => ({
          ...prevState,
          isFetchDraftLoading: false,
          fetchedDraftError: "Something went Wrong",
        }));
      });
  };

  const selectThisDraftAsCurrentUpShot = (data) => {
    if (
      upShotDataState.upShotTitleText !== "" ||
      upShotDataState.upShotDescriptionText !== ""
    ) {
      const askUserToOveride = window.confirm(
        "Do you want to override the current UpShot with this Draft? Don't worry your current progress will be saved as Draft."
      );
      if (askUserToOveride) {
        setCurrentSelectedDraft(data);
      }
    } else {
      setCurrentSelectedDraft(data);
    }
  };

  function truncateText(text) {
    const words = text.split(" ");
    const truncated = words.slice(0, 5).join(" ");
    if (words.length > 5) {
      return truncated + "...";
    }
    return truncated;
    // ? It works when there is space or not.
    // const maxLength = 25;
    // if (text.length > maxLength) {
    //   return text.slice(0, maxLength) + '...';
    // }
    // return text;
  }

  const deleteFromDraft = (data) => {
    const id = data.id;

    setDraftState((prevState) => ({
      ...prevState,
      fetchedDrafts: prevState.fetchedDrafts.filter(
        (item) => item.id !== data.id
      ),
    }));

    axios
      .delete(`/api/content/main/upshot/drafts/`, { params: { id: id } })
      .then((res) => {
        showToastError("Successfully deleted the Draft", "success");
      })
      .catch(() => {
        showToastError("Unable to delete the Draft, Try again later", "error");
        setDraftState((prevState) => ({
          ...prevState,
          fetchedDrafts: [...prevState.fetchedDrafts, data],
        }));
      });
  };

  return (
    <Modal
      opened={isDraftModalOpen}
      onClose={closeDraftModal}
      radius="10px"
      size="lg"
      title={"UpShot Drafts"}
      centered
    >
      {draftState.isFetchDraftLoading ? (
        <>Loading.....</>
      ) : (
        <>
          {draftState.fetchedDraftError === null ? (
            <>
              {draftState?.fetchedDrafts?.length > 0 ? (
                <ul className="drafts">
                  {draftState?.fetchedDrafts?.map((res, index) => (
                    <li key={index}>
                      <span
                        onClick={() => selectThisDraftAsCurrentUpShot(res)}
                        className="make-text-cursor"
                      >
                        {res.title ? (
                          <>{truncateText(res.title)}</>
                        ) : (
                          <>
                            {res.description ? (
                              <>{truncateText(res.description)}</>
                            ) : (
                              <>Draft title is missing</>
                            )}
                          </>
                        )}
                      </span>
                      <span className="draft-data" title={res.date_published}>
                        <span className="timestamp-draft">
                          {res.natural_time}
                        </span>
                        <svg
                          className="make-text-cursor svg-hover-fill-white"
                          onClick={() => deleteFromDraft(res)}
                          xmlns="http://www.w3.org/2000/svg"
                          height="20"
                          width="20"
                        >
                          <path d="M6.5 17q-.625 0-1.062-.438Q5 16.125 5 15.5v-10H4V4h4V3h4v1h4v1.5h-1v10q0 .625-.438 1.062Q14.125 17 13.5 17Zm7-11.5h-7v10h7ZM8 14h1.5V7H8Zm2.5 0H12V7h-1.5Zm-4-8.5v10Z" />
                        </svg>
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="empty-state-message">
                  {t("Looks like there are no Drafts here")}
                </div>
              )}
            </>
          ) : (
            <>{draftState.fetchedDraftError}</>
          )}
        </>
      )}
    </Modal>
  );
}

export default UpShotDraftsModal;
