import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
// import { GoogleOAuthProvider } from '@react-oauth/google';

import Root from "./Root"
import LoginPage from './components/users/Login';
import PageGotMissed from './components/Preventation/PageGotMissed';
import SignUpPage from './components/users/Signup';
import UserProfile from './components/Profile/UserProfile';
import Trending from './components/content/Trending';
import BasePage from "./MainInterface/Base/BasePage";
import RequireAuth from './components/users/RequireAuth';
import HomePage2 from './MainInterface/Home/HomePage2';
import ResetPasswordEmailConfirmation from './components/users/PasswordReset/ResetPasswordEmailConfirmation';
import ResetPasswordConfirm from './components/users/PasswordReset/ResetPasswordConfirm';
import MaintenanceMode from './components/Preventation/Maintenance/maintenance';
// import SharedItems from './components/content/SharedItems';
import WatchLater from './components/watchLater/WatchLater';
import WatchLaterDetail from './components/watchLater/WatchLaterDetail';
import UpShotCreation from './components/upshot/upshotCreation/UpShotCreation';
import BlockedUsers from './components/Profile/BlockedUsers'
// import Favourites from './components/HIDDEN/Favourites/Favourites';
import UpShot from "./components/upshot/Upshot"
import CelebDetail from './components/content/CelebDetail';
import UpShotDetail from './components/upshot/UpShotDetail';
import WatchingList from './components/watching/WatchingList';
import AudienceRequest from './components/audience/audienceRequest';
import AudienceList from './components/Profile/AudienceList';
import RecordDetail from './components/content/RecordDetail';
// import SharedLinks from './components/Share/SharedLinks';


import AdminDashboard from './components/admin/Dashboard';
import AdminDashboardLogin from './components/admin/AdminDashboardLogin';
import AdminDashboardAPI from './components/admin/AdminDashboardAPI';
import DashboardUsers from './components/admin/UserActionsDashboard/AdminDashboardUsers';

import UserDashboard from './components/admin/UserActionsDashboard/UserDashboard';
// import UserDashboard from './components/admin/UserDashboard';
import UserReportedItems from './components/admin/UserActionsDashboard/UserReportedItems';
import UserBlockedUsers from './components/admin/UserActionsDashboard/UserBlockedUsers';
import UserModerationHistory from './components/admin/UserActionsDashboard/UserModerationHistory';
import UserPostHistory from './components/admin/UserActionsDashboard/UserPostHistory';

import DashboardAdminContent from './components/admin/DashboardAdminContent';
import BannedUsers from './components/admin/BannedUsers';
import AdminAnalytics from './components/admin/AdminAnalytics';

import ModerationMessage from './components/admin/ModerationMessage/ModerationMessage';

import NotificationDetail from './components/notification/NotificationDetail';
import AllNotifications from './components/notification/AllNotifications';

import ContentListLookup from './components/upshot/contentLookup/ContentListLookup';

// import AwardList from './components/award/AwardList';

import ThemeProvider from './components/appearance/ThemeProvider';


// import { useModMessagePopup } from './MainInterface/contexts/UserAdminMessageProvider';
import { UserAdminMessageProvider } from './MainInterface/contexts/UserAdminMessage/UserAdminMessageProvider';
import { BanUserProvider } from './MainInterface/contexts/UserBan/UserBanModalProvider';

import UserAdminAllReports from './components/admin/UserAdminAllReports';

import Contact from './MainInterface/others/Contact';

// import Checkout from './components/premium/PremiumCheckout';

import PrivacyPolicy from './components/UserReadables/Policies/PrivacyPolicy/PrivacyPolicy';
import HelpCenter from './components/UserReadables/HelpCenter/HelpCenter';
import DailyQuestionsList from './components/trivia/DailyQuestionsList';

import SessionStorageExpirationCheck from './components/admin/SessionStorageExpirationCheck';
import UserActivation from './components/users/UserActivation';
import PremiumInfo from './components/premium/PremiumInfo';
import MovieDiscussionChatRoom from './components/discussions/MovieDiscussion';
import TournamentRules from './components/discussions/TournamentRules';
import TournamentHelp from './components/discussions/TournamentHelp';
import TournamentList from './components/discussions/TournamentList';
import AppForIosAndroid from './components/UserReadables/AppFor/AppForIosAndroid';
// import CelebDetailPage from './components/content/CelebDetailPage';
import SearchPage from './components/Search/SearchPage';
import { LoginRequiredGlobalModal, GlobalLoginRequiredModalProvider } from './MainInterface/contexts/LoginRequired/LoginRequiredModal';
import TermsOfService from './components/UserReadables/Policies/TermsOfService/TermsOfService';
import ContentPolicy from './components/UserReadables/Policies/ContentPolicy/ContentPolicy';
import UserAgreement from './components/UserReadables/Policies/UserAgreement/UserAgreement';
import CookiePolicy from './components/UserReadables/Policies/CookiePolicy/CookiePolicy';
import PremiumAgreement from './components/UserReadables/Policies/PremiumAgreement/PremiumAgreement';
import showToastError from './components/helpers/showToastError';
import AboutUs from './components/UserReadables/About/AboutUs';
import { DEBUG, isPremiumEnabled } from './components/constants/constants';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
// import EditUpShot from './components/upshot/editUpShot/EditUpShot';


function AppHandler({ currentUserUsername, currentUserProfileImage }) {

    return (
        <Routes>
            {/* IN-APP-HANDLING Defaults - START */}
            <Route exact path='/' element={<HomePage2 />} />
            <Route path='/app-for/:for' element={<AppForIosAndroid />} />
            <Route path='*' element={<PageGotMissed />} />
            {/* IN-APP-HANDLING Defaults - END */}

            {/* ! User Management - START */}
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup' element={<SignUpPage />} />
            <Route path='/reset-password' element={<ResetPasswordEmailConfirmation />} />
            <Route path='/reset_password/:uid/:token' element={<ResetPasswordConfirm />} />
            <Route path='/account/activation/:uid/:token' element={<UserActivation />} />

            <Route path='/profile/:username' element={<UserProfile currentUserUsername={currentUserUsername} />} />
            <Route path='/blocked-users' element={<BlockedUsers />} />
            <Route path='/requests' element={<AudienceRequest />} />
            <Route path='/audience/:user' element={<AudienceList currentUserUsername={currentUserUsername} />} />
            {/* ! User Management - END */}

            {/* ! Premium - Start */}
            {
                isPremiumEnabled &&
                <>
                    <Route path='/premium' element={<PremiumInfo currentUserUsername={currentUserUsername} />} />
                    <Route path='/checkout/buy/:productId' element={<PremiumInfo currentUserUsername={currentUserUsername} />} />
                </>
            }
            {/* <Route path='/premium/checkout' element={<RequireAuth><Checkout /></RequireAuth>} /> */}
            {/* ! Premium - END */}

            {/* ! Content Management - START */}
            <Route path='/upshot-create' element={<RequireAuth><UpShotCreation /></RequireAuth>} />
            <Route path="/upshots" element={<UpShot currentUserUsername={currentUserUsername} currentUserProfileImage={currentUserProfileImage} />} />
            <Route path="/upshot/:upShotId" element={<UpShotDetail currentUserUsername={currentUserUsername} />} />
            <Route path='/watchings/:username' element={<WatchingList />} />
            <Route path='/search' element={<SearchPage currentUserUsername={currentUserUsername} />} />
            <Route path='/content/:mediaType/:title/:id' element={<RecordDetail currentUserUsername={currentUserUsername} />} />
            <Route path='/cl/:contentType/:contentName/:contentId/:mediaType' element={<ContentListLookup currentUserProfileImage={currentUserProfileImage} />} />
            <Route path='/trending' element={<Trending currentUserUsername={currentUserUsername} />} />
            <Route path='/watch-later-list/:listId' element={<WatchLaterDetail currentUserUsername={currentUserUsername} />} />
            <Route path='/watch-later/:username' element={<WatchLater currentUserUsername={currentUserUsername} />} />
            {/* ! Content Management - END */}

            {/* ! Tournament - START */}
            <Route path="/tournaments/" element={<TournamentList />} />
            <Route path="/tournament/discussion/:roomId" element={<MovieDiscussionChatRoom currentUserUsername={currentUserUsername} />} />
            <Route path="/tournament/rules/" element={<TournamentRules />} />
            <Route path="/tournament/help/" element={<TournamentHelp />} />

            <Route path="/questions" element={<DailyQuestionsList />} />
            {/* ! Tournament - END */}

            {/* ! Notifications - START */}
            <Route path="/notification-detail/:notificationId" element={<NotificationDetail />} />
            <Route path='/notifications/' element={<AllNotifications />} />
            {/* ! Notifications - END */}

            {/* ! Legal Policies - START */}
            <Route path='/policies/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/policies/terms-of-service' element={<TermsOfService />} />
            <Route path='/policies/user-agreement' element={<UserAgreement />} />
            <Route path='/policies/content-policy' element={<ContentPolicy />} />
            <Route path='/policies/cookie-policy' element={<CookiePolicy />} />
            <Route path='/policies/premium-agreement' element={<PremiumAgreement />} />
            {/* ! Legal Policies - END */}

            {/* ! Readables - START */}
            <Route path='/help' element={<HelpCenter />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/contact' element={<Contact />} />
            {/* ! Readables - END */}

            {/* ! ADMIN TOOLS - START */}
            <Route path='/admin/dashboard/login/' element={<AdminDashboardLogin />} />
            <Route path="/admin/dashboard/:id" element={<AdminDashboard />} />
            <Route path='/admin/dashboard/api/:id' element={<AdminDashboardAPI />} />
            <Route path='/admin/dashboard/users/:id' element={<DashboardUsers />} />
            <Route path='/admin/dashboard/user/:id/:userId/:username' element={<UserDashboard />} />
            <Route path='/admin/dashboard/user/reporteds/:id/:userId/:username' element={<UserReportedItems />} />
            <Route path='/admin/dashboard/user/blocked-users/:id/:userId/:username' element={<UserBlockedUsers />} />
            <Route path='/admin/dashboard/user/post-history/:id/:userId/:username' element={<UserPostHistory />} />
            <Route path='/admin/dashboard/user/moderation-history/:id/:userId/:username' element={<UserModerationHistory />} />
            <Route path='/admin/dashboard/all-reports/:id' element={<UserAdminAllReports />} />
            <Route path='/mod-message/:userUsername/:id' element={<ModerationMessage />} />
            <Route path='/admin/dashboard/content/:id' element={<DashboardAdminContent />} />
            <Route path='/admin/dashboard/banned-users/:id' element={<BannedUsers />} />
            <Route path='/admin/dashboard/analytics/:id' element={<AdminAnalytics />} />
            {/* ! ADMIN TOOLS - END */}


            {/* ! Disabled For Now - START */}
            {/* <Route path='/awards/list' element={<AwardList />} /> */}
            {/* <Route path="*" element={<MaintenanceMode />} /> */}
            {/* <Route path='/celeb-detail/:celebName' element={<CelebDetail />} /> */}
            {/* <Route path='/celeb/:celebId' element={<CelebDetailPage />} /> */}
            {/* <Route path='/favourites/:username' element={<Favourites />} /> */}
            {/* ! Disabled For Now - END */}
        </Routes>
    )
}


class MainApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUserUsername: "",
            currentUserProfileImage: "",
            pstsc: true,

            isUserDetailsLoading: true,

            userOnPremium: false,
        }
    }

    componentDidMount() {
        this.checkIfUserAuthenticatedOrNot();
    }

    checkIfUserAuthenticatedOrNot = () => {
        axios.get("/check_if_authenticated/")
            .then((res) => {
                if (res.data.message.is_authenticated) {
                    this.setState({
                        currentUserUsername: res.data.message.username,
                        currentUserProfileImage: res.data.message.profile_image,
                        pstsc: res.data.message.p_c_t_s_c,
                        isUserDetailsLoading: false,
                        userOnPremium: res.data.message.is_user_on_premium
                    })
                }
            })
            .catch(() => {
                // if (localStorage.getItem("token") && localStorage.getItem("token").length === 40) {
                //     showToastError("Something went Wrong while getting your Credentials! Maybe because of network.", "error");
                // }
            })
    }

    render() {
        const { currentUserUsername, currentUserProfileImage, pstsc, userOnPremium } = this.state;
        return (
            <Root>
                <ToastContainer hideProgressBar={true} autoClose={2500} />
                <SessionStorageExpirationCheck />
                <Router>
                    <MantineProvider defaultColorScheme="dark">
                        <ModalsProvider>
                            <ThemeProvider>
                                <UserAdminMessageProvider>
                                    <GlobalLoginRequiredModalProvider>
                                        <BanUserProvider>
                                            <BasePage currentUserUsername={currentUserUsername} currentUserProfileImage={currentUserProfileImage} pstsc={pstsc} userOnPremium={userOnPremium} />
                                            <LoginRequiredGlobalModal />
                                            {/* <GoogleOAuthProvider clientId='314866282024-t34iokc7dguc53ue33bmmteo73q42r8r'> */}
                                            <AppHandler currentUserUsername={currentUserUsername} currentUserProfileImage={currentUserProfileImage} />
                                            {/* </GoogleOAuthProvider> */}
                                        </BanUserProvider>
                                    </GlobalLoginRequiredModalProvider>
                                </UserAdminMessageProvider>
                            </ThemeProvider>
                        </ModalsProvider>
                    </MantineProvider>
                </Router>
            </Root>
        )
    }
}

export default MainApp;