import React, { useState, useRef } from 'react';
import axios from 'axios';

import { useTranslation } from "react-i18next";
import "../styles/creationContentStyles.css";
import { defaultPlaceholderImageIfNotPresentAny } from '../../../constants/constants';
import LoadingIfElseErrorSvg from "../../../animations/LoadingIfElseErrorSvg";

function UpShotContentSelection({ activeTabState, upshotContentState, setUpShotContentState, setContentSearchValue, contentSearchValue, setCurrentContentSelected, currentContentSelected, selectedContentMovieTv, setSelectedContentMovieTv, selectedContentCeleb, setSelectedContentCeleb }) {
    const { t } = useTranslation();
    const [fetchedSearchContent, setFetchSearchedContent] = useState({
        contentSearchResults: [],
        isContentSearchLoading: false,
        contentSearchError: null
    })
    const timerRef = useRef(null);
    const searchContentTabOptions = [
        { tabHTMLName: "movies", tabNameToDisplay: "Movies", tabSVG: "m160 256 80 160h120l-80-160h80l80 160h120l-80-160h80l80 160h120l-80-160h120q33 0 56.5 23.5T880 336v480q0 33-23.5 56.5T800 896H160q-33 0-56.5-23.5T80 816V336q0-33 23.5-56.5T160 256Zm0 240v320h640V496H160Zm0 0v320-320Z" },
        { tabHTMLName: "shows", tabNameToDisplay: "TV Shows", tabSVG: "m380 716 280-180-280-180v360Zm-60 220v-80H160q-33 0-56.5-23.5T80 776V296q0-33 23.5-56.5T160 216h640q33 0 56.5 23.5T880 296v480q0 33-23.5 56.5T800 856H640v80H320ZM160 776h640V296H160v480Zm0 0V296v480Z" },
        { tabHTMLName: "celebs", tabNameToDisplay: "Celebs", tabSVG: "M480 576q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160 896V784q0-34 17.5-62.5T224 678q62-31 126-46.5T480 616q66 0 130 15.5T736 678q29 15 46.5 43.5T800 784v112H160Zm80-80h480v-32q0-11-5.5-20T700 750q-54-27-109-40.5T480 696q-56 0-111 13.5T260 750q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560 416q0-33-23.5-56.5T480 336q-33 0-56.5 23.5T400 416q0 33 23.5 56.5T480 496Zm0-80Zm0 400Z" },
    ]

    const updateCurrentContentSelected = (e) => {
        setUpShotContentState(prevState => ({
            ...prevState,
            contentSearchValue: "",
            currentContentSelected: e,
        }))
        setFetchSearchedContent(prevState => ({
            ...prevState,
            contentSearchResults: [],
        }))
    }

    const runAndGetResultsOnInput = (e) => {
        setUpShotContentState(prevState => ({
            ...prevState,
            contentSearchValue: e.target.value,
        }))
        if (!e.target.value) {
            return;
        }
        clearTimeout(timerRef.current);
        setFetchSearchedContent({
            isContentSearchLoading: true,
            contentSearchError: null
        })

        timerRef.current = setTimeout(() => {
            axios.get(`/api/search/search-content/?query=${e.target.value}&searchType=${upshotContentState.currentContentSelected}`).then((res) => {
                setFetchSearchedContent(prevState => ({
                    ...prevState,
                    contentSearchResults: res.data.results,
                    isContentSearchLoading: false,
                }))

            }).catch(() => {
                setFetchSearchedContent({
                    contentSearchError: "Something went Wrong!",
                    isContentSearchLoading: false,
                })
            })
        }, 300);
    }

    const addClickedContentAsSelected = (data) => {
        const newContent = { ...data, contentType: upshotContentState.currentContentSelected };

        const moviesCount = upshotContentState.selectedContentMovieTv.filter((item) => item.contentType === "movies").length
        const showsCount = upshotContentState.selectedContentMovieTv.filter((item) => item.contentType === "shows").length

        const celebCount = upshotContentState.selectedContentCeleb.filter((item) => item.contentType === "celebs").length

        const isDuplicate = upshotContentState.selectedContentMovieTv.find((item) => item.id === newContent.id)
        const isCelebDuplicate = upshotContentState.selectedContentCeleb.find((item) => item.id === newContent.id)

        if (!isDuplicate) {
            if (upshotContentState.currentContentSelected === "movies" || upshotContentState.currentContentSelected === "shows") {
                if ((moviesCount <= 1 && showsCount === 0) || (showsCount <= 1 && moviesCount === 0)) {
                    setUpShotContentState(prevState => ({
                        ...prevState,
                        selectedContentMovieTv: [...prevState.selectedContentMovieTv, newContent],
                    }))
                }
            }
        }

        if (!isCelebDuplicate) {
            if (upshotContentState.currentContentSelected === "celebs") {
                if (celebCount <= 3) {
                    setUpShotContentState(prevState => ({
                        ...prevState,
                        selectedContentCeleb: [...prevState.selectedContentCeleb, newContent],
                    }))
                }
            }
        }
    }

    const deleteSelectedContent = (id, contentType) => {
        if (contentType === "celeb") {
            setUpShotContentState(prevState => ({
                ...prevState,
                selectedContentCeleb: prevState.selectedContentCeleb.filter((item) => item.id !== id),
            }))
        } else {
            setUpShotContentState(prevState => ({
                ...prevState,
                selectedContentMovieTv: prevState.selectedContentMovieTv.filter((item) => item.id !== id),
            }))
        }
    }

    return (
        <div className={`content-container ${activeTabState === "content" ? "display-block" : ''}`}>
            <section className="content-content">

                <p className='content-selection-info'>
                    {t("Attach a movie/tv/celeb with your post.")}
                </p>

                <span className='content-selection-add-info'>{t("You can select upto 3 celebs and 1 movie or 1 show at a time.")}</span>

                <ul className="list">
                    <form className="search-form">
                        <input type="search" value={upshotContentState.contentSearchValue} onChange={(e) => runAndGetResultsOnInput(e)} placeholder={t("Search")} className="search-input" />
                        <button type="submit" className="search-button">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                                <path
                                    d="M784 936 532 684q-30 24-69 38t-83 14q-109 0-184.5-75.5T120 476q0-109 75.5-184.5T380 216q109 0 184.5 75.5T640 476q0 44-14 83t-38 69l252 252-56 56ZM380 656q75 0 127.5-52.5T560 476q0-75-52.5-127.5T380 296q-75 0-127.5 52.5T200 476q0 75 52.5 127.5T380 656Z" />
                            </svg>
                        </button>
                        <div className="search-option">
                            {
                                searchContentTabOptions.map(({ tabHTMLName, tabNameToDisplay, tabSVG }) =>
                                    <div>
                                        <input name="type" type="radio" checked={upshotContentState.currentContentSelected === tabHTMLName && true} value={tabHTMLName} id={tabHTMLName} onChange={(e) => updateCurrentContentSelected(e.target.value)} />
                                        <label htmlFor={tabHTMLName}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                                                <path
                                                    d={tabSVG} />
                                            </svg>
                                            <span>{t(tabNameToDisplay)}</span>
                                        </label>
                                    </div>
                                )
                            }
                        </div>
                    </form>
                </ul>
            </section>

            {
                fetchedSearchContent.isContentSearchLoading ?
                    <LoadingIfElseErrorSvg isLoading={fetchedSearchContent.isContentSearchLoading} errorString={fetchedSearchContent.contentSearchError} loadingLogoBigOrSmall={"big"} />
                    :
                    <>
                        {
                            fetchedSearchContent.contentSearchError ? <>{fetchedSearchContent.contentSearchError}</>
                                :
                                <>
                                    {fetchedSearchContent.contentSearchResults?.length > 0 &&
                                        <>
                                            {
                                                fetchedSearchContent.contentSearchResults?.map((res, index) =>
                                                    <span className='searched-content' key={index} onClick={() => addClickedContentAsSelected(res)}>
                                                        <img
                                                            className='content-selected-img make-text-cursor mr-2'
                                                            src={res.poster_path ? `https://image.tmdb.org/t/p/w200${res.poster_path}` : res.profile_path ? `https://image.tmdb.org/t/p/w200${res.profile_path}` : defaultPlaceholderImageIfNotPresentAny}
                                                        />
                                                        <span className='content-name'>
                                                            {
                                                                upshotContentState.currentContentSelected === "movies" ? <>{res.original_title}</> : upshotContentState.currentContentSelected === "shows" ? <>{res.original_title ? res.original_title : res.original_name}</> : <>{res.name}</>
                                                            }
                                                        </span>
                                                        <hr className='style-two' />
                                                    </span>
                                                )
                                            }
                                        </>
                                    }
                                </>
                        }
                    </>
            }
            <hr className='content-atc-hr' />

            {
                upshotContentState?.selectedContentMovieTv.length !== 0 &&
                <>
                    {
                        upshotContentState?.selectedContentMovieTv?.filter((item) => item.contentType === "movies" || item.contentType === "shows").map((item) => (
                            <SelectedContentListItem id={item.id} name={item.original_title ? item.original_title : item.original_name} releaseDate={item.release_date ? item.release_date : item.first_air_date} image={`https://image.tmdb.org/t/p/w200${item.poster_path}`} contentType={item.contentType === "movies" ? "Movie" : "TV"} deleteSelectedContent={deleteSelectedContent} />
                        ))
                    }
                </>
            }

            {
                upshotContentState?.selectedContentCeleb?.map((item) => (
                    <SelectedContentListItem id={item.id} name={item.original_title ? item.original_title : item.original_name} releaseDate={item.release_date ? item.release_date : item.first_air_date} image={`https://image.tmdb.org/t/p/w200${item.profile_path}`} contentType={"Celeb"} deleteSelectedContent={deleteSelectedContent} />
                ))
            }
        </div>
    )
}

function SelectedContentListItem({ id, name, releaseDate, image, contentType, deleteSelectedContent }) {

    return (
        <div className="atc-content-container">
            <div className="atc-content">
                <img src={image} alt="Image 1" />
                <div className="atc-content-text">
                    <h2>{name}</h2>
                    <p className='date'>{releaseDate}</p>
                    <span className='clr-white content-type'>{contentType}</span>
                    <div className="remove-atc-content-button" onClick={() => deleteSelectedContent(id, contentType === "Celeb" ? "celeb" : "movieTv")}>Remove</div>
                </div>
            </div>
        </div>
    )
}

export default UpShotContentSelection;