import * as React from "react";
const TournamentForPremium = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="#F9CD16"
            opacity={1}
            stroke="none"
            d=" M459.064484,251.085678  C457.376953,258.815094 455.580475,266.522644 454.023407,274.278290  C450.189087,293.376770 446.487000,312.501740 442.689758,331.607727  C439.154236,349.396851 435.674500,367.198212 431.959595,384.950104  C429.516388,396.625000 426.889465,408.269196 423.998444,419.840576  C420.887451,432.292206 404.526398,444.070496 390.495636,444.053680  C302.031403,443.947632 213.566864,443.915802 125.102791,444.071533  C107.550446,444.102448 91.736847,431.793884 88.085663,414.498138  C83.776367,394.084900 80.049713,373.549255 75.982712,353.084137  C72.190445,334.001434 68.227974,314.952545 64.444351,295.868195  C61.542557,281.231720 58.802483,266.563202 56.377167,251.491821  C72.364494,253.633652 86.143547,249.529724 97.508591,238.744247  C102.351868,234.147964 105.920799,228.208847 110.068848,222.879959  C119.134270,228.175919 128.226929,233.425980 137.256775,238.781937  C144.913284,243.323334 152.497223,247.987076 160.113220,252.596771  C163.451889,254.617569 166.002319,254.305161 168.103287,250.589935  C174.304108,239.624756 180.714203,228.778091 187.014465,217.868912  C195.822662,202.617126 204.632172,187.365997 213.385696,172.082825  C216.672028,166.345078 219.803177,160.518433 223.434250,154.386826  C224.891098,154.530685 226.051971,154.849838 226.926819,155.534470  C245.310852,169.921616 268.521790,169.998581 286.843658,155.768967  C287.938354,154.918793 289.485168,154.650772 290.821594,154.111847  C295.428772,162.452591 299.964722,170.833694 304.660950,179.123978  C310.708740,189.800049 316.871979,200.410812 323.009277,211.035980  C330.526398,224.050125 338.158264,236.999191 345.545319,250.086548  C347.827148,254.129211 350.321503,254.744461 354.213043,252.388199  C370.502411,242.525345 386.865448,232.784119 403.852844,223.137909  C405.342194,224.498779 406.264221,225.671356 407.005005,226.949020  C413.550049,238.237320 423.044434,245.717560 435.481445,249.736069  C443.293457,252.260178 451.106934,252.336838 459.064484,251.085678  z"
        />
        <path
            fill="#FAA701"
            opacity={1}
            stroke="none"
            d=" M110.056473,222.495422  C105.920799,228.208847 102.351868,234.147964 97.508591,238.744247  C86.143547,249.529724 72.364494,253.633652 56.340904,251.067337  C44.483501,249.587173 35.039635,243.996033 28.027601,235.294876  C14.687644,218.741455 12.919019,200.198807 22.103197,181.188904  C28.973398,166.968582 41.090462,159.024872 56.549885,155.974136  C73.165558,152.695251 87.393784,158.078766 99.218826,169.076996  C107.850166,177.104813 112.775932,187.686035 113.008041,199.932449  C113.151131,207.482208 113.369202,215.029968 110.056473,222.495422  z"
        />
        <path
            fill="#FAA701"
            opacity={1}
            stroke="none"
            d=" M459.459961,251.048386  C451.106934,252.336838 443.293457,252.260178 435.481445,249.736069  C423.044434,245.717560 413.550049,238.237320 407.005005,226.949020  C406.264221,225.671356 405.342194,224.498779 404.212219,223.091583  C399.491943,208.703842 398.991089,194.589111 406.053040,181.092560  C413.362183,167.123444 425.112152,158.955139 440.623566,155.964935  C457.245850,152.760559 471.474365,158.092453 483.277527,169.131088  C491.886078,177.182053 496.379181,187.788345 497.115448,200.010040  C498.055054,215.607803 493.242767,228.719070 482.019562,239.413635  C475.792999,245.346939 468.256042,249.079483 459.459961,251.048386  z"
        />
        <path
            fill="#FAA701"
            opacity={1}
            stroke="none"
            d=" M290.945190,153.774719  C289.485168,154.650772 287.938354,154.918793 286.843658,155.768967  C268.521790,169.998581 245.310852,169.921616 226.926819,155.534470  C226.051971,154.849838 224.891098,154.530685 223.511475,154.024170  C217.112961,146.317581 211.568024,138.545959 209.722229,128.451736  C205.288956,104.207291 216.080139,82.017235 239.051132,73.250511  C258.884644,65.681183 277.490326,69.583878 292.491425,85.182785  C300.330231,93.333977 304.620270,103.299965 305.150818,115.064034  C305.826172,130.038589 301.083801,142.599014 290.945190,153.774719  z"
        />
    </svg>
);
export default TournamentForPremium;
