
const helpQA = [
    {
        id: 'what-is-upshot',
        category: "Content",
        title: 'What is UpShot?',
        content:
            "UpShot is more like a post which you can create to share your your Plots, Endings, thoughts about movies, tv shows you like.",
    },
    {
        id: 'what-is-remark',
        category: "Content",
        title: 'What is Remark?',
        content:
            "Remark is that You can create to define your own explanation, plots and to share your own thoughts based on the details Author defined in their UpShot.",
    },
    {
        id: 'how-can-i-create-upshot',
        category: "Content",
        title: 'How can I create UpShot?',
        content:
            `You can create UpShot by going to UpShots page and clicking on "Create UpShot" button or You can directly go to <a href='/upshot-create' target="_blank">UpShot creation</a> page by pressing CTRL + M.`,
    },
    {
        id: 'attach-movie-with-upshot',
        title: 'Can I attach the Movie or TV Shows with the UpShot?',
        category: "Content",
        content:
            `Absolutely, Attach Movie, TV Shows, Person by clicking on "Movie/TV/Celeb".`,
    },
    {
        id: 'only-two-attachments-with-upshot',
        title: `Why I can't attach more than 2 Movies, TV shows, Celeb?`,
        category: "Content",
        content:
            "We only allow users to add only 1 Movie 1 TV show or 3 Celeb with a UpShot at this Time, we may increase this number in future.",
    },
    {
        id: 'seeing-too-many-requests',
        title: `Why I get "Too many requests" message sometimes?`,
        category: "Content",
        content:
            "We do this to prevent spam, If you're new user and doing so much actions in minimal time on SpreeCine then you might encounter this restriction. New users encounter this kind of restriction more.",
    },
    {
        id: 'allowed-types-of-posts',
        title: `On what topic can/should I create UpShots?`,
        category: "Content",
        content:
            "At the time, We only allow UpShots or content based on movies, tv shows, celebs. Basically you can create anything about the movies, tv shows, celebs.",
    },
    {
        id: 'what-happens-on-off-topic-creation',
        title: `What would happen If I post something other than movies, tv shows, celebs?`,
        category: "Content",
        content:
            "We may hide or lock your UpShot if we found that your UpShot was not about the content we allow here. Don't worry nothing will happen to your Account but if you create off topics frequently then we may temporarily freeze your Profile for some time.",
    },
    {
        id: 'profile',
        title: `What I can access in my Profile?`,
        category: "Profile",
        content:
            `
                Each user have their own Profile in which they can access their created UpShots, Remarks, Comments and all their data including Awards earned and streak score.
            `,
    },
    {
        id: 'what-is-streak',
        title: `What is Streak in my Profile, What is it's use?`,
        category: "Profile",
        disabled: true,
        content:
            `
            Streak in SpreeCine is a way to track how many consecutive days you use the app without a break.

            Streak is a way to reward you for using SpreeCine regularly and staying engaged. 
            Imagine each day you use the app, you're building a streak. 
            When you reach 60 consecutive days of using the app, 
            You will get a <b>1 Month Premium Membership for FREE!</b>. So, keep coming back, and let's build those streaks together! Happy exploring!
            `,
    },
    {
        id: "what-is-tournament",
        title: `What is Tournament?`,
        category: "Tournament",
        content:
            `
            <b>Tournament</b> is a random duration competetion (We call it <b>Storyline Tournament</b> here) which occurs on Monday at 7AM, Wednesday at 6PM, Saturday at 2PM and Sunday at 6PM (UTC+05:30).
            Each tournament will be assigned with a random Picked UpShot which will the topic to discuss in that particular tournament, users can join and participate
            to post their Plots, thoughts in the Topic Tournament.
            `
    },
    {
        id: "tournament-duration",
        title: `How long does a Storyline Tournament last?`,
        category: "Tournament",
        content:
            `
            Storyline Tournament last on random duration, minimum of 1 Hour to max. 24 Hours.
            `
    },
    {
        id: "anyone-can-join",
        title: `Can anyone join the Tournaments?`,
        category: "Tournament",
        content:
            `
            Anyone can join and participate in Storyline Tournament as long the tournament is of duration of 1, 3 Hours, if the tournament's Life is 6, 24 Hours long than You need a Premium membership to join.
            `
    },
    {
        id: "can-i-post-anything-in-tournament",
        title: `Can I post or say anything in an Storyline Tournament?`,
        category: "Tournament",
        content:
            `
            You can only post or say about the UpShot's Topic. 
            If you say something bad in tournament which is Off-Topic or doesn't relate with the assigned UpShot then You will be kicked out from the Tournament.
            <br />
            <br />
            You can read the Rules and Guidelines in every Tournament on the tournament's Header.
            `
    },
    {
        id: "can-i-send-unlimited-messages",
        title: `Can I send unlimited messages in Storyline Tournament?`,
        category: "Tournament",
        content:
            `
            User can send upto 3 messages in each Tournament and user with Premium membership can send upto 7 messages.
            `
    },
    {
        id: "tournament-winner",
        title: `When will the Tournament will declare the Winner?`,
        category: "Tournament",
        content:
            `
            Tournament will automatically pick the most liked message of a user and when the Tournament ends then it Will declare the Winner.
            You will get a notification when the Tournament ends.
            `
    },
    {
        id: 'what-are-awards',
        title: `What are Awards in Remarks and How can I send them?`,
        category: "Awards",
        content:
            `
                Awards are special kind of Recognition or Honor you can give to the Remark Author, If you liked their work or their content then try to give them some Appreciation by giving away one of the Award. 
                There are multiple awards you can give to the users.
                You can give award to user by clicking "Award or Prize Icon" below every Remark, after clicking it there will be many awards you'll see, You can choose any award to give them.
            `,
    },
    {
        id: 'some-awards-are-locked',
        title: `Why some awards are locked, Why I can't click or give away them?`,
        category: "Awards",
        content:
            `
                Awards you're talking about are Premium awards, only users with Premium membership can give away these awards.
            `,
    },
    {
        id: 'multiple-award-on-one',
        title: `Can I give multiple awards to a single Remark?`,
        category: "Awards",
        content:
            `
                No you can't give multiple awards on one Remark.
            `,
    },
    {
        id: 'what-are-channelges',
        title: `What are challenges?`,
        category: "Challenge",
        content:
            `
                Challenges are simply random trivia questions which popups at random timing and are based on movies, tv shows and celebrities. Actually they are fun to Play.
            `,
    },
    {
        id: 'challenge-not-available',
        title: `My Challenge is always showing "No Question for today, check back later".`,
        category: "Challenge",
        content:
            `
                A Challenge creates for every user each day but it will only available for One day and previous won't
                be seen. Just Don't worry and check back later, you will definately see it
            `,
    },
    {
        id: 'premium-membership',
        title: `What is Premium Membership, How can I get it and what benefits will I get with it?`,
        category: "Premium Membership",
        content:
            `
                Premium membership on SpreeCine is a special upgrade that you can get to make your SpreeCine experience even better. 
                You can read more about Premium membership and it's features <a href="/premium" target="_blank">here</a>
                <br />
                <br />

                You can get it by purchasing it on Premium Page.
            `,
    },
    {
        id: 'premium-membership-pricing',
        title: `How much does it cost to buy Premium Membership?`,
        category: "Premium Membership",
        content:
            `
                It will cost you 3.99$/month (plus applicable taxes).
            `,
    },
    {
        id: 'will-i-get-refund',
        title: `Will I get refund if I don't like the Premium Membership?`,
        category: "Premium Membership",
        content:
            `
                No, We don't do refunds at the time. 
                And If you don't like the premium membership then please ensure you cancel at least 24 hours prior to the end of your existing subscription period to avoid being billed for next month.
            `,
    },
    {
        id: 'premium-for-f',
        title: `Is there a way I can get Premium Membership for Free?`,
        category: "Premium Membership",
        disabled: true,
        content:
            `
                Yes, You can get Premium membership for Free if you meet these criterias.
                <br />
                <br />
                <ul>
                    <li>If you complete and reach your Streak to 60 then You will be Awarded with a 1 Month Premium Membership.</li>
                    <li>If a user give away 10 Premium award on your Remarks then you will be Awarded with a 1 Month Premium Membership.</li>
                </ul>
            `,
    },
    {
        id: 'invoice-for-premium',
        title: `How can I get Invoice of my Premium Membership Purchase?`,
        category: "Premium Membership",
        content:
            `
            You can request your Premium Membership Payment Invoice in <b>User Settings</b>. 
            In user settings click on "Request Invoice" and then You should receive an Email shortly, with your Invoice details.
            <br />
            <br />
            <small>It will only send Invoice for your current or recent Premium Membership Purchase. If you want to access your previous
            payment's Invoices then you can  <a href="/contact"><b>Contact us</b></a>  or drop us an Email. We will send it you in 24 Hours.</small>
        `,
    },
    {
        id: "current-free-membership-gone-after-purchase",
        title: `My current Premium Membership which I got for free is now gone after purchasing Premium.`,
        disabled: true,
        category: "Premium Membership",
        content:
            `
            Yes, If you have an active Free Premium Membership and you purchase a Paid Premium Membership then all the active Free Premium
            memberships will be gone and the membership you just purchased will be Activated.
            `
    },
    {
        id: "will-i-get-free-premium-membership-after-purchasing-premium",
        title: `Will I be eligible for Free Premium Membership Plans after subscribing for Paid Premium Membership?`,
        disabled: true,
        category: "Premium Membership",
        content:
            `
            Absolutely, You will continously be receiving Free 1 month Premium memberships (by completing 60 Days 
                <a href="/help#what-is-streak">Streaks</a> or 
                by earning Premium awards on Remarks) even after purchasing SpreeCine Premium Membership. 
            <br />
            <br />
            <i>Like, If you purchase a Premium membership today and then luckily next day you completed 60 day Streak then You <b>won't</b> 
            charged for next month and any upcoming Free Premium month will take over for next month when the current one ends and 
            after that when the Free Membership ends then Your Paid Premium Membership will be automatically activated for third month.</i>
            `
    },
    {
        id: "premium-membership-did-not-activate",
        title: `I have purchased Premium Membership but It is showing that "You are not on Premium Plan"?`,
        category: "Premium Membership",
        content:
            `
            If it is showing "You are not on Premium Plan" even after successfully susbcribing for Premium Membership then Please 
            <a href="/contact">Contact us</a> Immediately.
            `
    },
    {
        id: "error-while-purchasing",
        title: `Showing error while getting Premium membership.`,
        category: "Premium Membership",
        content:
            `
            If the problem presists then Please <Link="/contact">Contact us</a> Immediately. We'll sort it out together.
            `
    },
    {
        id: 'what-is-profile-freeze',
        title: `What does it mean by Freezed Profile/Account?`,
        category: "Moderation",
        content:
            "Profile Freezed Profile means that User who is freezed cannot create UpShot, <a href='#what-is-remark'>Remark</a> and place Comments for some time on the platform but they can engage in other activities like Liking-Disliking posts, can send Audience requests to other users, etc.",
    },
    {
        id: 'profile-is-freezed-for-how-long',
        title: `My profile is Freezed, How long it will be freezed and Why it was freezed?`,
        category: "Moderation",
        content:
            `
                Well, If you create off-topic UpShots, post rude comments which is strictly not suitable in this Platform may result in your Profile freeze and Durating of freeze depends on what actions your Profile was freezed. 
                It may take 24 hours to 6 months to relief from Freeze.`,
    },
    {
        id: 'account-is-banned',
        title: `My account is Banned, what I can do and what I can't?`,
        category: "Moderation",
        content:
            `
                If your account is Banned then you can't do anything like you can't participate or engage with other Users or with community in any possible way.
                <br />
                You can check your Profile to know when your Ban will Relief. Only you can see it.
                <br />
                <br />
                <b>What you can't access while your acccount is Banned</b>
                <br />
                <br />
                <ul className='cant-ul'>
                    <li>You can't create UpShots, Remarks, or place comments, replies.</li>
                    <li>You can't send Audience request to other users, also other user's can't send you or add you as their Audience.</li>
                    <li>You can't share your Watch Later lists, Favourites or create customizable share urls.</li>
                </ul>
                <br />
                <br />
                <b>What you can access while your acccount is Banned</b>
                <br />
                <br />
                <ul className='cant-ul'>
                    <li>You can just look at the UpShots, Remarks and Enjoy reading them.</li>
                    <li>Explore Trending Movies, TV Shows.</li>
                </ul>
            `,
    },
    {
        id: 'account-deletion',
        title: `How long does my data take to delete from SpreeCine after account deletion?`,
        category: "Account",
        content:
            `If you delete your account today then All of your data assigned with your username will be deleted within three days of account deletion.
            And Your posted content like UpShots, Remarks, Comments and Replies will be assigned with a random ID like <i>user12345</i> completely unidentified with you
            and noone will ever know who posted it.
            `,
    },
    {
        id: 'change-username',
        title: `Can I change my username?`,
        category: "Account",
        content:
            `You cannot change your Username after creating it.`,
    },
    {
        id: 'data-exportation',
        title: `How long does it take to Export my data?`,
        category: "Account",
        content:
            `It may take several minutes to successfully export your data and send it to you.`,
    },
    {
        id: "previous-version-of-policies",
        title: `I want to see previous version of Policies, How and Where do I see it?`,
        category: "Policies",
        content:
            `
            If you want to know previous version of Policies then You can request it <a href="/contact">here</a>.
            `
    },
    {
        id: "discuss-something",
        title: `Want to discuss something I didn't see here.`,
        category: "Other",
        content:
            `
            If you want to discuss something, then directly <a className="contact-us-link clr-red" href="/contact">Contact us</a>.
            `
    },
    {
        id: "saw-a-bug",
        title: `I saw a bug or a problem which is causing something unusal. Where do I report it?`,
        category: "Other",
        content:
            `
            If you encounter some problem or a bug then please report it as soon as possible in <a href="/contact">Contact us</a> page.
            `
    },
];

export default helpQA;