import "./animationCss/loadingLogo.css";

function LoadingLogo({ type, moveToLeft, moveToTop }) {
    // const defaultType = "processing"; // searching
    // const defaultLeft = "50%";

    if (type === "processing") {
        return (
            <div>
                {moveToLeft ?
                    <>
                        {
                            moveToTop ?
                                <>
                                    <section><span style={{ left: moveToLeft, top: moveToTop }} className="loader-17"></span></section>
                                </>
                                :
                                <>
                                    <section><span style={{ left: moveToLeft }} className="loader-17"></span></section>
                                </>
                        }
                    </>
                    :
                    <>
                        {
                            moveToTop ?
                                <>
                                    <section><span style={{ top: moveToTop }} className="loader-17"></span></section>
                                </>
                                :
                                <>
                                    <section><span className="loader-17"></span></section>
                                </>
                        }
                    </>
                }
            </div>
        )
    } else if (type === "searching") {
        return (
            <div>
                {moveToLeft ?
                    <>
                        {
                            moveToTop ?
                                <>
                                    <section><span style={{ left: moveToLeft, top: moveToTop }} className="loader-94"></span></section>
                                </>
                                :
                                <>
                                    <section><span style={{ left: moveToLeft }} className="loader-94"></span></section>
                                </>
                        }
                    </>
                    :
                    <>
                        {
                            moveToTop ?
                                <>
                                    <section><span style={{ top: moveToTop }} className="loader-94"></span></section>
                                </>
                                :
                                <>
                                    <section><span className="loader-94"></span></section>
                                </>
                        }
                    </>
                }
            </div>
        )
    }
}

export default LoadingLogo;