// GlobalModalContextProvider.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./loginRequired.css";

const GlobalLoginRequiredModalContext = createContext();

const GlobalLoginRequiredModalProvider = ({ children }) => {
    const [isLoginRequiredModalOpen, setLoginRequiredModalOpen] = useState(false);

    const openModal = () => {
        setLoginRequiredModalOpen(true);
    };

    const closeModal = () => {
        setLoginRequiredModalOpen(false);
    };

    return (
        <GlobalLoginRequiredModalContext.Provider value={{ isLoginRequiredModalOpen, openModal, closeModal }}>
            {children}
        </GlobalLoginRequiredModalContext.Provider>
    );
};

const LoginRequiredGlobalModal = () => {
    const { t } = useTranslation();
    const { isLoginRequiredModalOpen, closeModal } = useContext(GlobalLoginRequiredModalContext);
    const [selectedCatchyLine, setSelectedCatchyLine] = useState("")
    const catchyLines = [
        "Join the party! Log in or sign up and let the good times roll!",
        "Ready, set, log in! Your adventure begins with just one click.",
        "Don't miss out! Dive into a world of possibilities - log in or sign up now!",
        "Lights, camera, action! Your starring role awaits. Log in or sign up for your blockbuster moment.",
        "Unlock the magic! Log in or sign up and step into a realm of endless excitement.",
        "Time to level up! Power up your experience - log in or sign up to the next stage.",
        "Get in on the secret sauce! Log in or sign up and savor the exclusive flavor of our community.",
        "One small step for you, one giant leap for your experience. Log in or sign up now and soar!",
        "Become a legend! Your story begins when you log in or sign up - start scripting your epic tale.",
        "Ready to rock and roll? Log in or sign up and let the rhythm of our platform move you!",
        "Houston, we have a problem. You're not logged in! Time to navigate the stars by logging in or signing up.",
        "Here's looking at you, kid. But hey, you're not logged in! How about logging in or signing up for your own epic story.",
        "You're not in Kansas anymore. Time to click those heels and log in, or better yet, sign up for the Emerald City experience!",
        "May the Force be with you, but it's stronger when you're logged in. Join us on the Dark Side or the Light Side by logging in or signing up!",
        "You can't handle the truth! Well, maybe you can, but first, log in or sign up to get the real cinematic experience.",
        "You're not logged in, and that's no moon. Time to navigate the stars by logging in or signing up.",
        "To log in or not to log in, that is the question. Actually, there's no question—log in or sign up!",
        "Houston, we've had a logout. Please log in or sign up to re-enter the atmosphere of our amazing community.",
        "Welcome to the not-so-secret garden. Want the key? Log in or sign up to unlock the gate.",
        "Life is like a box of cookies, and right now, you need to log in or sign up to get yours!"
    ];

    const getRandomCatchyLine = () => {
        const randomIndex = Math.floor(Math.random() * catchyLines.length);
        setSelectedCatchyLine(t(catchyLines[randomIndex]));
    };

    useEffect(() => {
        getRandomCatchyLine()
    }, [isLoginRequiredModalOpen])


    return (
        isLoginRequiredModalOpen && (
            <div className="login-r-modal">
                <div className="login-r-modal-content">
                    <div className="login-r-modal-header">
                        <span className="close-l-r" onClick={closeModal}>&times;</span>
                    </div>
                    <div className="login-r-modal-body">
                        <p className="caligraphy-header">{selectedCatchyLine}</p>
                    </div>
                    <div className="login-r-modal-footer">
                        <Link to={"/login"} onClick={closeModal} id="confirmBtn">{t("Login")}</Link>
                        <Link to={"/signup"} onClick={closeModal} className="l-r-signup-link-below-button">{t("Signup")}</Link>
                    </div>
                </div>
            </div>
        )
    );
};


export { GlobalLoginRequiredModalProvider, GlobalLoginRequiredModalContext, LoginRequiredGlobalModal };
