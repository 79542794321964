import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import "./moderationHistory.css";
import { useSearchParams } from "react-router-dom";
import textTrimmer from "../../helpers/stringCutter"

function UserModerationHistory() {
    const [moderationHistory, setModerationHistory] = useState({
        currentUserId: 0,
        currentUserUsername: "",
        ban_history: [],
        message_history: [],
    })
    const params = useParams();

    useEffect(() => {
        axios.get(`/dashboard/admin/user/mod/mod-history/${params.userId}/${params.id}`).then((res) => {
            setModerationHistory({
                currentUserId: res.data.message[0].user_id,
                currentUserUsername: res.data.message[0].username,
                ban_history: res.data.message[0].ban_history,
                message_history: res.data.message[0].message_history,
            })
        }).catch((err) => {
            setModerationHistory({ currentUserId: 0 })
        })
    }, [])

    return (
        <>
            {
                moderationHistory.currentUserId !== 0 ?
                    <div className="moderation-history-accordins">
                        <h3>Ban History</h3>
                        {moderationHistory.ban_history?.map((item) => (
                            <Accordion
                                key={item.id}
                                id={item.id}
                                username={item.user_username}
                                additionalComment={item.additional_comment}
                                banDuration={item.ban_duration}
                                actionTakenBy={item.action_taken_by_username}
                                banReason={item.ban_reason}
                                banned={item.banned}
                                banTimestamp={item.ban_timestamp}
                                banTimestampNatural={item.natural_time}
                                isBanResolved={item.is_ban_resolved}
                            />
                        ))}
                        <hr />
                        <h3>Message History</h3>
                        {moderationHistory.message_history?.map((item) => (
                            <MessageHistory
                                key={item.id}
                                id={item.id}
                                username={item.receiver_username}
                                messageThatSent={item.message}
                                messageDangerWeight={item.message_danger_weight}
                                actionTakenBy={item.sent_by_username}
                                timestamp={item.natural_time}
                                messageReason={item.reason_of_messaging}
                            />
                        ))}
                    </div>
                    :
                    <h2 className="err-message">Soemthing went Wrong</h2>
            }

        </>
    )
}

const MessageHistory = ({ id, username, messageThatSent, messageDangerWeight, actionTakenBy, timestamp, messageReason }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion ${isOpen ? 'open' : ''}`}>
            <div className="accordion-header" onClick={toggleAccordion}>
                <h3>
                    <span>Message sent at?</span> {timestamp}
                    <br />
                    <span>For - </span> {textTrimmer(messageReason, 15)}
                </h3>
                <span className={`icon ${isOpen ? 'open' : ''}`}></span>
            </div>
            {isOpen && <div className="accordion-content">{messageReason}</div>}
        </div>
    );
}

const Accordion = ({ id, username, additionalComment, banned, banDuration, actionTakenBy, banReason, banTimestampNatural, isBanResolved }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion ${isOpen ? 'open' : ''}`}>
            <div className="accordion-header" onClick={toggleAccordion}>
                <h3>
                    <span>Still Banned?</span> {banned ? <>Yes</> : <>No</>}
                    <br />
                    <span>For - </span> {textTrimmer(additionalComment, 15)}
                </h3>
                <span className={`icon ${isOpen ? 'open' : ''}`}></span>
            </div>
            {isOpen && <div className="accordion-content">{additionalComment}</div>}
        </div>
    );
};

export default UserModerationHistory;