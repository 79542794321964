import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import './styles/bannedUsers.css';
import AdminDashboardDropdown from './AdminDashboardDropdown';
import showToastError from "../helpers/showToastError";
import { adminApiUrls } from "./api/api";

function extractBanDurationFromShortcut(shortcutDuration) {
    if (shortcutDuration === "1M") {
        return "1 Month"
    } else if (shortcutDuration === "3M") {
        return "3 Months"
    } else if (shortcutDuration === "6M") {
        return "6 Months"
    } else if (shortcutDuration === "1Y") {
        return "1 Year"
    } else if (shortcutDuration === "3Y") {
        return "3 Years"
    } else {
        return shortcutDuration
    }
}

function extractBanReasonFromShortcut(shortcutReason) {
    if (shortcutReason === "PEOIC") {
        return "Posting Explicit or Inappropriate content"
    } else if (shortcutReason === "DOSP") {
        return "Doxing or Sharing personal information"
    } else if (shortcutReason === "FOSA") {
        return "Fraudulent or Span Activity"
    } else if (shortcutReason === "IDC") {
        return "Inintentional disruption of community"
    } else if (shortcutReason === "VOIP") {
        return "Violtion of Intellectual property"
    } else if (shortcutReason === "NCOUSPC") {
        return "Non concensual or Unauthorized sharing of personal content"
    } else if (shortcutReason === "ISR") {
        return "Impersonating staff representative"
    } else if (shortcutReason === "HSOIV") {
        return "Hate speech or Inciting violence"
    } else if (shortcutReason === "VTOS") {
        return "Violation of terms of service"
    } else if (shortcutReason === "PHS") {
        return "Persistent harrassment stalking"
    } else {
        return shortcutReason
    }
}

function BannedUsers() {
    const params = useParams();
    const [bannedUsersState, setBannedUsersState] = useState({
        isLoading: false,

        fetchedBannedUsers: [],
    })
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const adminDashboardUrls = adminApiUrls.adminBannedUsers;
    const bannedUsersListUrl = adminDashboardUrls.getBannedUsers(params.id);

    useEffect(() => {
        fetchBannedUsers();
    }, [])

    const fetchBannedUsers = () => {
        axios.get(bannedUsersListUrl).then((res) => {
            setBannedUsersState(prevState => ({
                ...prevState,
                fetchedBannedUsers: res.data,
                isLoading: false,
            }))
            showToastError("Successfully banned user", "error")
        }).catch(() => {
            showToastError(`Something went wrong while banning user!`, "error")
        })
    }

    return (
        <>
            <br /><br />
            <AdminDashboardDropdown
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen}
            />

            <table>
                <thead>
                    <tr>
                        <th>Index - ID</th>
                        <th>User ID (username)</th>
                        <th>Ban duration</th>
                        <th>Banned at</th>
                        <th>Ban Reason</th>
                        <th>Additional comment</th>
                        <th>Still banned?</th>
                        <th>Action taken by</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        bannedUsersState.fetchedBannedUsers?.map((user, index) =>
                            <tr className='text-clr-white' key={user.id}>
                                <td>{index + 1} - <b><a href={`${adminApiUrls.defaultAdminSiteOriginBackend + `site_admin/banuser/${user.id}/`}`} target="_blank" className="clr-blue">{user.id}</a></b></td>
                                <td>{user.user} ({user.user_username})</td>
                                <td>{extractBanDurationFromShortcut(user.ban_duration)}</td>
                                <td>{user.natural_time}</td>
                                <td>{extractBanReasonFromShortcut(user.ban_reason)}</td>
                                <td><small>{user.additional_comment}</small></td>
                                <td>{user.banned ? <b className="clr-red">Banned</b> : <b className="clr-green">Resolved</b>}</td>
                                <td><a href={`${adminApiUrls.defaultAdminSiteOriginBackend + `auth/user/${user.action_taked_by}/change`}`} className="clr-blue" target="_blank">{user.action_taken_by_username}</a></td>
                                {/* <td><a href={`${adminApiUrls.defaultAdminSiteOriginBackend + `api/users/user/${user.action_taked_by}/change`}`} className="clr-blue" target="_blank">{user.action_taken_by_username}</a></td> */}
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </>
    )
}

export default BannedUsers;