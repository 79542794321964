import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./styles/tournamentNotFound.css"

function TournamentNotFound({ tournamentValid }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [count, setCount] = useState(7);

    useEffect(() => {
        const timer = setInterval(() => {
            if (count > 1) {
                setCount(count - 1);
            } else {
                clearInterval(timer);
                navigate(`/tournaments/`);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [tournamentValid, count])

    if (tournamentValid) {
        return null;
    }

    return (
        <div className="not-found-body">
            <div className="not-found-container">
                <h1 className="not-found-title">{"Tournament Not Found"}</h1>
                <p className="not-found-container-more">{t("This Tournament is missing or may be It Never Existed.")}</p>
                <p className="go-to-link">Redirecting in {count}</p>
            </div>
        </div>
    )
}

export default TournamentNotFound;