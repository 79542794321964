import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import chooseAppropriateHeaderLine from "./helpers/chooseAppropriateHeaderNotification";
import EQuestionModal from "../EQModal/EQuestionModal";
import showToastError from "../helpers/showToastError";
import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";
import { defaultBackendOrigin } from "../constants/constants";
import AllClearedUpEmojiIcon from "../../MainInterface/Base/HeaderDropdowns/SVGIcons/NotificationEmojiIcon";

function AllNotifications() {
    const { t } = useTranslation();
    const [allNotificationsDataState, setAllNotificationsDataState] = useState({
        isAllNotificationsLoading: false,
        allNotificationsFetchError: "",

        allNotifications: [],
    });
    const [nextPageUrl, setNextPageUrl] = useState("");
    const [isNextPageAvailable, setNextPageAvailable] = useState(false);
    const [isEQModalOpen, setEQModalOpen] = useState(false);

    useEffect(() => {
        getAllNotifications();
    }, []);

    const getAllNotifications = () => {
        setAllNotificationsDataState(prevState => ({
            ...prevState,
            isAllNotificationsLoading: true,
        }));
        axios.get(`/api/notification/all-notifications/`).then((res) => {
            setAllNotificationsDataState(prevState => ({
                ...prevState,
                allNotifications: res.data.results,
                isAllNotificationsLoading: false,
            }));
            if (res.data.next !== null) {
                setNextPageAvailable(true);
                setNextPageUrl(res.data.next)
            } else {
                setNextPageAvailable(false);
            }
            readAllNotifications();
        }).catch(() => {
            setAllNotificationsDataState(prevState => ({
                ...prevState,
                isAllNotificationsLoading: false,
            }));
            showToastError("Something went wrong!", "error");
        })
    }

    const readAllNotifications = () => {
        axios.put('/api/notification/notifications/').then(() => {
        }).catch(() => {
        })
    }

    const showMore = () => {
        setAllNotificationsDataState(prevState => ({
            ...prevState,
            isAllNotificationsLoading: true,
        }));
        axios.get(nextPageUrl).then((res) => {
            setAllNotificationsDataState(prevState => ({
                ...prevState,
                isAllNotificationsLoading: false,
                allNotifications: [...prevState.allNotifications, ...res.data.results],
            }));
            if (res.data.next !== null) {
                setNextPageAvailable(true);
                setNextPageUrl(res.data.next);
            } else {
                setNextPageAvailable(false);
            }
        }).catch(() => {
            setAllNotificationsDataState(prevState => ({
                ...prevState,
                isAllNotificationsLoading: false,
            }));
            showToastError("Something went wrong!", "error");
        })
    }

    if (allNotificationsDataState.allNotifications?.length == 0) {
        return (
            <div className="clr-white all-notification-cleared-up">
                <AllClearedUpEmojiIcon />
                <p>No notifications!</p>
            </div>
        )
    }

    return (
        <LayoutComponentForHelmet pageTitle={"Notifications - SpreeCine"}>
            <div className="notification-page-v3 margin-top-20px">
                {
                    allNotificationsDataState.allNotifications?.map((notification) =>
                        <div className="notification-item-v3" key={notification.id}>
                            {
                                notification.participant_user_profile_image &&
                                <img src={`${defaultBackendOrigin}${notification.participant_user_profile_image}`} alt="User Image" />
                            }
                            <div className="notification-content-v3">
                                <div className="notification-type-v3">
                                    {
                                        notification.notification_type !== "EQ" ?
                                            <a href={notification.notification_url} target="_blank">{chooseAppropriateHeaderLine(notification.notification_type, notification.notification_content_data.main_content_title, notification.notification_url, 1, notification.participant_username, notification.participant_username)}</a>
                                            :
                                            <span className="make-text-cursor" onClick={() => setEQModalOpen(true)}>{chooseAppropriateHeaderLine(notification.notification_type, notification.notification_content_data.main_content_title, notification.notification_url, 1, notification.participant_username, notification.participant_username)}</span>
                                    }
                                </div>
                                {
                                    Object.keys(notification.notification_content_data).length !== 0 &&
                                    <div className="notification-details-v3">{notification.notification_content_data.main_content_title}</div>
                                }
                                <div className="notification-time-v3" title={notification.timestamp}>{notification.naturaltime}</div>
                            </div>
                            {
                                !notification.is_read &&
                                <div className="unread-dot-v3"></div>
                            }
                        </div>
                    )}
            </div>
            {
                isNextPageAvailable &&
                <div className="notification-more-btn-container">
                    <button type="button" className="notification-more-btn" onClick={showMore}>
                        {t("Show more")}
                        {
                            allNotificationsDataState.isAllNotificationsLoading &&
                            <svg className="error-retry retry-rotate fill-white vertical-middle-middle" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" /></svg>
                        }
                    </button>
                </div>
            }
            <EQuestionModal
                isEQModalOpen={isEQModalOpen}
                closeEQModal={() => setEQModalOpen(false)}
            />
        </LayoutComponentForHelmet>
    )
}

export default AllNotifications;