import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import '../styles/adminDashboardUsers.css';
import UserAdminMessage from "../ModerationActions/UserAdminMessage";

import { useModMessagePopup } from "../../../MainInterface/contexts/UserAdminMessage/UserAdminMessageProvider";
import { useBanModel } from "../../../MainInterface/contexts/UserBan/UserBanModalProvider";

function UserPostHistory() {
    const params = useParams();
    const { openModMessageModal, MessageModal } = useModMessagePopup();
    const { openBanModel } = useBanModel();
    const [filterToggleReport, setFilterToggleReport] = useState("upshot");
    const [fetchedUpShots, setFetchedUShots] = useState([]);
    const [fetchedRemarks, setFetchedRemarks] = useState([]);
    const [fetchedComments, setFetchedComments] = useState([]);
    const [fetchedWTs, setFetchedWTs] = useState([]);
    const [fetchedFavourites, setFetchedFavourites] = useState([]);
    const [message, setMessage] = useState("");


    const handleModMessagePopup = (clickedId, clickedType, clickedContentUserId) => {
        console.log("Open Mod message")
        openModMessageModal(clickedId, clickedType, clickedContentUserId);
    }

    const handleBanModelPopup = (userId) => {
        console.log("Open Ban Model")
        openBanModel(userId)
    }

    const toggleReportFilter = (e) => {
        setFilterToggleReport(e.target.value);
    }

    useEffect(() => {
        fetchUserUpShots(filterToggleReport);
    }, [filterToggleReport])

    const fetchUserUpShots = () => {
        axios.get(`/dashboard/admin/user/mod/content-list/${params.userId}/${params.id}/?filterType=${filterToggleReport}`).then((res) => {
            console.log(res);
            if (filterToggleReport === "upshot") {
                setFetchedUShots(res.data.message)
            } else if (filterToggleReport === "remark") {
                setFetchedRemarks(res.data.message)
            } else if (filterToggleReport === "comment") {
                setFetchedComments(res.data.message)
            } else if (filterToggleReport === "wt") {
                setFetchedWTs(res.data.message)
            }
            // else if (filterToggleReport === "favourite") {
            //     setFetchedFavourites(res.data.message)
            // }
        })
    }

    useEffect(() => {
        // console.log(fetchUserUpShots)
        fetchUserUpShots();
    }, [])


    return (
        <>
            {/* <MessageModal>
            Good Modal
        </MessageModal> */}
            <ul className="toggle-upshot-creation-theme inline-flex p-1 space-x-1 bg-gray-200 rounded-md text-blue-600">
                <li className="filter-switch-item h-8">
                    <input type="radio" name="filter1" id="filter1-0" className="sr-only" value="upshot" checked={filterToggleReport === "upshot" && true} onChange={(e) => toggleReportFilter(e)} />
                    <label htmlFor="filter1-0" className="make-text-cursor h-8 py-1 px-2 leading-6 text-gray-600 hover:text-gray-800 bg-white">
                        UpShot
                    </label>
                    <div aria-hidden="true" className="filter-active"></div>
                </li>
                <li className="filter-switch-item h-8">
                    <input type="radio" name="filter1" id="filter1-1" className="sr-only" value="remark" checked={filterToggleReport === "remark" && true} onClick={(e) => toggleReportFilter(e)} />
                    <label htmlFor="filter1-1" className="make-text-cursor h-8 py-1 px-2 leading-6 text-gray-600 hover:text-gray-800 bg-white">
                        Remarks
                    </label>
                </li>
                <li className="filter-switch-item h-8">
                    <input type="radio" name="filter1" id="filter1-2" className="sr-only" value="comment" checked={filterToggleReport === "comment" && true} onClick={(e) => toggleReportFilter(e)} />
                    <label htmlFor="filter1-2" className="make-text-cursor h-8 py-1 px-2 leading-6 text-gray-600 hover:text-gray-800 bg-white">
                        Comments
                    </label>
                </li>
                <li className="filter-switch-item h-8">
                    <input type="radio" name="filter1" id="filter1-3" className="sr-only" value="wt" checked={filterToggleReport === "wt" && true} onChange={(e) => toggleReportFilter(e)} />
                    <label htmlFor="filter1-3" className="make-text-cursor h-8 py-1 px-2 leading-6 text-gray-600 hover:text-gray-800 bg-white">
                        WT
                    </label>
                </li>
                {/* <li className="filter-switch-item h-8">
                    <input type="radio" name="filter1" id="filter1-4" className="sr-only" value="favourite" checked={filterToggleReport === "favourite" && true} onChange={(e) => toggleReportFilter(e)} />
                    <label htmlFor="filter1-4" className="make-text-cursor h-8 py-1 px-2 leading-6 text-gray-600 hover:text-gray-800 bg-white">
                        Favourites
                    </label>
                </li> */}
            </ul>


            {
                filterToggleReport === "upshot" &&
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Published at</th>
                                <th>Visibility</th>
                                <th>Is deleted?</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchedUpShots?.map((upshot, index) =>
                                <tr>
                                    <td>{upshot.id}</td>
                                    <td>{upshot.title}</td>
                                    <td>{upshot.natural_time}</td>
                                    <td>{upshot.upshot_visibility}</td>
                                    <td>{upshot.is_deleted ? <>Yes</> : <>No</>}</td>
                                    <td>
                                        {/* <svg className="svg-hover-red make-text-cursor" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-48v-72h192v-48h192v48h192v72h-48v479.566Q720-186 698.85-165 677.7-144 648-144H312Zm336-552H312v480h336v-480ZM384-288h72v-336h-72v336Zm120 0h72v-336h-72v336ZM312-696v480-480Z" /></svg> */}
                                        <svg className="svg-hover-light-red make-text-cursor" onClick={() => handleBanModelPopup(upshot.user)} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m808-249-72-72v-415H321l-72-72h487q29 0 50.5 21.5T808-736v487ZM766-88l-59.135-59H219q-29 0-50.5-21.5T147-219v-487l-60-60 51-51 679 679-51 50ZM258-293l109-145 83 110 32-43-263-262v414h414l-73-74H258Zm270-235ZM426-426Z" /></svg>
                                        <svg className="svg-hover-blue make-text-cursor" onClick={() => handleModMessagePopup(upshot.id, "upshot", upshot.user)} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M360-240q-30 0-51-21t-21-51v-48h456v-312h48q30 0 51 21t21 51v504L720-240H360ZM96-288v-504q0-30 21-51t51-21h432q30 0 51 21t21 51v288q0 30-21 51t-51 21H240L96-288Zm504-216v-288H168v330l42-42h390Zm-432 0v-288 288Z" /></svg>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            }

            {
                filterToggleReport === "remark" &&
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Content</th>
                                <th>Published at</th>
                                <th>Is deleted?</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchedRemarks?.map((remark, index) =>
                                <tr>
                                    <td>{remark.id}</td>
                                    <td>{remark.content}</td>
                                    <td>{remark.remark_timestamp}</td>
                                    <td>{remark.is_deleted ? <>Yes</> : <>No</>}</td>
                                    <td>
                                        {/* <svg className="svg-hover-red make-text-cursor" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-48v-72h192v-48h192v48h192v72h-48v479.566Q720-186 698.85-165 677.7-144 648-144H312Zm336-552H312v480h336v-480ZM384-288h72v-336h-72v336Zm120 0h72v-336h-72v336ZM312-696v480-480Z" /></svg> */}
                                        <svg className="svg-hover-light-red make-text-cursor" onClick={() => handleBanModelPopup(remark.remarked_by)} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m808-249-72-72v-415H321l-72-72h487q29 0 50.5 21.5T808-736v487ZM766-88l-59.135-59H219q-29 0-50.5-21.5T147-219v-487l-60-60 51-51 679 679-51 50ZM258-293l109-145 83 110 32-43-263-262v414h414l-73-74H258Zm270-235ZM426-426Z" /></svg>
                                        <svg className="svg-hover-blue make-text-cursor" onClick={() => handleModMessagePopup(remark.id, "remark", remark.remarked_by)} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M360-240q-30 0-51-21t-21-51v-48h456v-312h48q30 0 51 21t21 51v504L720-240H360ZM96-288v-504q0-30 21-51t51-21h432q30 0 51 21t21 51v288q0 30-21 51t-51 21H240L96-288Zm504-216v-288H168v330l42-42h390Zm-432 0v-288 288Z" /></svg>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            }

            {
                filterToggleReport === "comment" &&
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Content</th>
                                <th>Published at</th>
                                <th>Is deleted?</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchedComments?.map((comment, index) =>
                                <tr>
                                    <td>{comment.id}</td>
                                    <td>{comment.comment_body}</td>
                                    <td>{comment.comment_creation_timestamp}</td>
                                    <td>{comment.is_deleted ? <>Yes</> : <>No</>}</td>
                                    <td>
                                        {/* <svg className="svg-hover-red make-text-cursor" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-48v-72h192v-48h192v48h192v72h-48v479.566Q720-186 698.85-165 677.7-144 648-144H312Zm336-552H312v480h336v-480ZM384-288h72v-336h-72v336Zm120 0h72v-336h-72v336ZM312-696v480-480Z" /></svg> */}
                                        <svg className="svg-hover-light-red make-text-cursor" onClick={() => handleBanModelPopup(comment.commented_by)} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m808-249-72-72v-415H321l-72-72h487q29 0 50.5 21.5T808-736v487ZM766-88l-59.135-59H219q-29 0-50.5-21.5T147-219v-487l-60-60 51-51 679 679-51 50ZM258-293l109-145 83 110 32-43-263-262v414h414l-73-74H258Zm270-235ZM426-426Z" /></svg>
                                        <svg className="svg-hover-blue make-text-cursor" onClick={() => handleModMessagePopup(comment.id, "comment", comment.commented_by)} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M360-240q-30 0-51-21t-21-51v-48h456v-312h48q30 0 51 21t21 51v504L720-240H360ZM96-288v-504q0-30 21-51t51-21h432q30 0 51 21t21 51v288q0 30-21 51t-51 21H240L96-288Zm504-216v-288H168v330l42-42h390Zm-432 0v-288 288Z" /></svg>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            }

            {
                filterToggleReport === "wt" &&
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>List name</th>
                                <th>Created at</th>
                                <th>Visibility</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchedWTs?.map((upshot, index) =>
                                <tr>
                                    <td>{upshot.id}</td>
                                    <td>{upshot.list_name}</td>
                                    <td>{upshot.time_stamp}</td>
                                    <td>{upshot.watch_later_visibility}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            }
            {/* <UserAdminMessage
                isMessageDialogOpen={isMessageDialogOpen}
                close={closeMessageDialog}
                userId={userId}
            /> */}
        </>
    )
}

export default UserPostHistory;