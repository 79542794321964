import React from "react";
import { useState, useEffect } from "react";
import {
    FacebookShareButton,
    InstapaperShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    FacebookIcon,
    InstapaperIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import { useTranslation } from "react-i18next";

import './styles/Share.css';
import showToastError from "../helpers/showToastError";
import { defaultOrigin } from "../constants/constants";
import { OPTION_FAVOURITES, OPTION_PROFILE, OPTION_UPSHOT, OPTION_WATCH_LATER, UPSHOT_REMARK, UPSHOT_REMARK_COMMENT } from "./constants";

function ShareForPublicOnly({ isPubliShareModalOpen, closeShareMainModal, shareData, pageType, saveInBackend }) {
    const { t } = useTranslation();
    const [u, setU] = useState("");

    useEffect(() => {
        if (isPubliShareModalOpen) {
            if (pageType === "U") {
                setU(shareData.shareUrl)
            } else if (pageType === "P") {
                setU(shareData.shareUrl)
            } else if (pageType === "WT") {
                setU(shareData.shareUrl)
            } else if (pageType === "UR") {
                setU(shareData.shareUrl)
            } else if (pageType === "URC") {
                setU(shareData.shareUrl)
            } else {
                const prepareUrlForShare = `content/${shareData.mediaType}/${shareData.contentName.replace(/\s+/g, "-")}/${shareData.id}/`
                setU(prepareUrlForShare)
            }
        }
    }, [isPubliShareModalOpen, shareData.url, shareData])

    const copyLinkToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        showToastError("Link copied to clipboard", "success")
    }

    if (!isPubliShareModalOpen) {
        return null;
    }

    return (
        <div id="url-modal-overlay">
            <div className="url-modal show">
                <header className="flt-rgt">
                    <div className="close" onClick={closeShareMainModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18">
                            <path
                                d="m256 856-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                        </svg>
                    </div>
                </header>
                <div className="share-content">
                    <h2 className="clr-white">Share
                        {(() => {
                            switch (shareData.pageType) {
                                case OPTION_UPSHOT:
                                    if (shareData.pageType === OPTION_UPSHOT) {
                                        return <> {t("UpShot")}</>;
                                    }
                                    return null;
                                case OPTION_PROFILE:
                                    if (shareData.pageType === OPTION_PROFILE) {
                                        return <> {t("Profile")}</>;
                                    }
                                    return null;
                                case OPTION_WATCH_LATER:
                                    if (shareData.pageType === OPTION_WATCH_LATER) {
                                        return <> {t("Watch Later")}</>;
                                    }
                                    return null;
                                case OPTION_FAVOURITES:
                                    if (shareData.pageType === OPTION_FAVOURITES) {
                                        return <> {t("Favourites")}</>;
                                    }
                                    return null;
                                case UPSHOT_REMARK:
                                    if (shareData.pageType === UPSHOT_REMARK) {
                                        return <> {t("Remark")}</>;
                                    }
                                    return null;
                                case UPSHOT_REMARK_COMMENT:
                                    if (shareData.pageType === UPSHOT_REMARK_COMMENT) {
                                        return <> {t("Remark Comment")}</>;
                                    }
                                    return null;
                                default:
                                    return null;
                            }
                        })()}
                    </h2>
                    <ul className="share-icons">
                        <a href="#" className="fb-sign">
                            <FacebookShareButton
                                url={`${defaultOrigin}/${u}`}
                                quote={"CampersTribe - World is yours to explore"}
                                hashtag="#camperstribe"
                            >
                                <FacebookIcon size={45} round={true} />
                            </FacebookShareButton>
                        </a>
                        <a href="#" className="twt-sign">
                            <TwitterShareButton
                                url={`${defaultOrigin}/${u}`}
                                quote={"CampersTribe - World is yours to explore"}
                                hashtag="#camperstribe"
                            >
                                <TwitterIcon size={45} round={true} />
                            </TwitterShareButton>
                        </a>
                        <a href="#" className="ins-sign">
                            <InstapaperShareButton
                                url={`${defaultOrigin}/${u}`}
                                quote={"CampersTribe - World is yours to explore"}
                                hashtag="#camperstribe"
                            >
                                <InstapaperIcon size={45} round={true} />
                            </InstapaperShareButton>
                        </a>
                        <a href="#" className="wat-sign">
                            <WhatsappShareButton
                                url={`${defaultOrigin}/${u}`}
                                title={"title"}
                                separator=":: "
                            >
                                <WhatsappIcon size={45} round={true} />
                            </WhatsappShareButton>
                        </a>
                        <a href="#" className="tel-sign">
                            <TelegramShareButton
                                url={`${defaultOrigin}/${u}`}
                                title={"title"}
                            >
                                <TelegramIcon size={45} round={true} />
                            </TelegramShareButton>
                        </a>
                    </ul>

                    <div className="url-field">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M240 976q-33 0-56.5-23.5T160 896V496q0-33 23.5-56.5T240 416h40v-80q0-83 58.5-141.5T480 136q83 0 141.5 58.5T680 336v80h40q33 0 56.5 23.5T800 496v400q0 33-23.5 56.5T720 976H240Zm0-80h480V496H240v400Zm240-120q33 0 56.5-23.5T560 696q0-33-23.5-56.5T480 616q-33 0-56.5 23.5T400 696q0 33 23.5 56.5T480 776ZM360 416h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240 896V496v400Z" /></svg>
                        <input type="text" readOnly value={`${defaultOrigin}/${u}`} />
                        <button onClick={() => copyLinkToClipboard(`${defaultOrigin}/${u}`)}>{t("Copy")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareForPublicOnly;