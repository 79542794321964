import React, { Suspense, lazy, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";


import "./styles/record-detail-v3.css";
import PlayButton from './icons/PlayButton';
import WatchingIcon from '../../svgComponents/WatchingFilled';
import WatchingUnFilled from '../../svgComponents/WatchingUnFilled';
import WatchLaterFilled from '../../svgComponents/WatchLaterFilled';
import WatchLaterUnFilled from '../../svgComponents/WatchLaterUnFilled';
import showToastError from '../helpers/showToastError';
import LoadingLogo from '../animations/LoadingLogo';
import { changeImageSlideshowInSeconds } from '../constants/constants';
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';
import { defaultPlaceholderImageIfNotPresentAny } from '../constants/constants';
import { GlobalLoginRequiredModalContext } from "../../MainInterface/contexts/LoginRequired/LoginRequiredModal";
import formatDate from './helpers/formatDate';
import formatReturningSeriesRepresentation from './helpers/formatReturningSeriesRepresentation';
import formatTheSeasonString from "./helpers/formatTheSeasonString";
import AnimateHeartCanvasV2 from "./animations/AnimateHeartCanvasV2";
import Sketch from "./animations/Sketch";


const VideoPlayerContainerLazy = lazy(() => import("../modals/VideoPlayerContainerModal"));
const WatchLaterSelectionListLazy = lazy(() => import("../modals/WatchLaterSelectionList"));


function ContentPlot({ imageUrl, isLoading, plot }) {
    const [averageRGB, setAverageRGB] = useState({ r: 0, g: 0, b: 0 });

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.crossOrigin = 'Anonymous';

        img.onload = () => {
            const rgb = getAverageRGB(img);
            setAverageRGB(rgb);
        };
    }, [imageUrl]);

    function getAverageRGB(imgEl) {
        const blockSize = 5;
        const defaultRGB = { r: 0, g: 0, b: 0 };
        const canvas = document.createElement('canvas');
        const context = canvas.getContext && canvas.getContext('2d');
        let data, width, height;
        let i = -4;
        let length;
        const rgb = { r: 0, g: 0, b: 0 };
        let count = 0;

        if (!context) {
            return defaultRGB;
        }

        height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
        width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

        context.drawImage(imgEl, 0, 0);

        try {
            data = context.getImageData(0, 0, width, height);
        } catch (e) {
            return defaultRGB;
        }

        length = data.data.length;

        while ((i += blockSize * 4) < length) {
            ++count;
            rgb.r += data.data[i];
            rgb.g += data.data[i + 1];
            rgb.b += data.data[i + 2];
        }

        // ~~ used to floor values
        rgb.r = ~~(rgb.r / count);
        rgb.g = ~~(rgb.g / count);
        rgb.b = ~~(rgb.b / count);

        return rgb;
    }

    const backgroundColor = `rgb(${averageRGB.r},${averageRGB.g},${averageRGB.b})`;

    return (
        <>
            {
                isLoading ?
                    <LoadingLogo type="processing" />
                    :
                    <p className="movie-plot" style={{ color: backgroundColor }}>
                        {plot}
                    </p>
            }
        </>
    )
}

function RecordDetail({ currentUserUsername }) {
    const { t } = useTranslation();
    const { openModal } = useContext(GlobalLoginRequiredModalContext);
    const [contentData, setContentData] = useState({
        mediaType: "",
        contentName: "",
        contentId: 0,
    });
    const [fetchedContentData, setFetchedContentData] = useState({
        isFetchLoading: false,
        fetchError: "",
        fetchedData: [],
        fetchedContentInsData: [],
        languagesSpoken: [],
    });
    const [fetchContentImagesData, setFetchContentImagesData] = useState({
        showBackdrop: false,
        backdropImages: [],
    })
    const [contentSeasonData, setContentSeasonData] = useState({
        seasons: [],
    });
    const [contentVideoState, setContentVideoState] = useState({
        isVideoFetching: false,
        videoFetchError: "",
        fetchedContentVideo: [],

        isVideoContainerOpen: false,
    });
    const [videoData, setVideoData] = useState({});
    const [seasonDropdown, setSeasonDropdown] = useState({ "season/1": true });

    const [userBackendState, setUserBackendState] = useState({
        isContentInWT: false,
        isContentInWatching: false,
    });
    const [wtDefaultModalShow, setWtDefaultModalShow] = useState(false);
    const [wtFullData, setWtFullData] = useState({});
    const [animationsVisibility, setAnimationsVisibility] = useState({
        isLoveAnimationOn: false,
        isMatrixAnimationOn: false,
        isHorrorTextAnimationOn: false,
        isSnowMultipleDynamicOn: false,
        isSnowSingleOn: false,
    })
    const [contentUpShotState, setContentUpShotState] = useState({
        fetchedUpShots: [],
        isFetchLoading: false,
        fetchError: "",
    });
    const [seasonEpisodePopUpState, setSeasonEpisodePopUpState] = useState({
        isSeasonOverviewPopupOpen: false,
        currentOpenedEpisodeId: 0,
    });
    const [imageIndex, setImageIndex] = useState(0);

    const openCloseEpisodeOverview = (episode) => {
        setSeasonEpisodePopUpState(prevState => ({
            isSeasonOverviewPopupOpen: prevState.currentOpenedEpisodeId === episode.id ? !prevState.isSeasonOverviewPopupOpen : true,
            currentOpenedEpisodeId: episode.id ? episode.id : 0,
        }))
    }

    const updateBackendStateOnClickDynamically = (clickedOn, additionalData, actionType) => {
        if (currentUserUsername) {
            setUserBackendState(prevState => ({
                ...prevState,
                isContentInWT: clickedOn === "addinwt" ? !prevState.isContentInWT : prevState.isContentInWT,
                isContentInWatching: clickedOn === "wtc" ? !prevState.isContentInWatching : prevState.isContentInWatching
            }))

            if (actionType === "manual") {
                if (clickedOn === "addinwt") {
                    if (userBackendState.isContentInWT) {
                        deleteContentWatchLater(additionalData.id)
                    } else {
                        addCelebToWatchLater(additionalData.original_title ? additionalData.original_title : additionalData.name, additionalData.id, additionalData.poster_path);
                        setWtFullData(additionalData);
                    }
                } else if (clickedOn === "wtc") {
                    if (userBackendState.isContentInWatching) {
                        removeFromWatchings(additionalData.id)
                    } else {
                        addContentToWatching(additionalData)
                    }
                }
            }
        } else {
            openModal();
        }
    }


    const addContentToWatching = (data) => {
        const { id, title, name, media_type, first_air_date, release_date, overview, poster_path } = data;
        const extractedData = { id, contentType: media_type, original_title: title ? title : name, release_date: first_air_date ? first_air_date : release_date, content_plot: overview, poster_path }

        const dataToPost = ({
            watcing_tv_movie_name: title ? title : name,
            watcing_tv_movie_id: id,
            content_data: extractedData,
        });

        axios.post(`/api/content/main/watching/create/get-list/`, dataToPost).then(() => {
        }).catch(() => {
            updateBackendStateOnClickDynamically("wtc");
        });
    }
    const removeFromWatchings = (id) => {
        const dataToPost = ({
            content_id: id
        })
        axios.delete(`/api/content/main/direct-remove/watching/content/`, { data: dataToPost }).then(() => {
        }).catch(() => {
            updateBackendStateOnClickDynamically("wtc");
        })
    }

    const addCelebToWatchLater = (s, f, pp) => {
        axios.get("/api/content/management/check_if_any_of_list_is_default/").then((res) => {
            if (!res.data.is_default_status) {
                setWtDefaultModalShow(true);
            } else {
                var removeAnyComma = s.replace(/'/g, '');
                const dataToPost = ({
                    action: "WT_SELECTED",
                    defaultList: res.data.id !== null && res.data.id,
                    content_name: removeAnyComma,
                    imdb_id_or_tmdb_id: f, // TMDB ID or IMDB ID,
                    content_data: { poster_path: pp },
                    media_type: window.location.pathname.split("/")[2]
                })
                axios.post("/api/content/management/add_to_wt/", dataToPost).then(() => {
                }).catch(() => {
                    showToastError("Something went wrong!", "error");
                    updateBackendStateOnClickDynamically("addinwt");
                })
            }
        }).catch(() => {
            showToastError("Something went wrong!", "error");
            updateBackendStateOnClickDynamically("addinwt");
        })
    }
    const deleteContentWatchLater = (id) => {
        axios.delete("/api/content/management/add_to_wt/", { data: { content_id: id } }).then(() => {
        }).catch(() => {
            showToastError("Something went wrong!", "error");
            updateBackendStateOnClickDynamically("addinwt");
        })
    }

    const openCloseSeasonDropdown = (seasonIndex) => {
        setSeasonDropdown((prevState) => ({
            ...prevState,
            [seasonIndex]: !prevState[seasonIndex]
        }))
    }

    const validateMediaTypeFromParam = (mediaType) => {
        if (mediaType === "tv" || mediaType === "movie") {
            return mediaType
        } else {
            showToastError("Check if media type you passed is correct (tv/movie).", "warning")
        }
    }

    const validateContentId = (id) => {
        if (!isNaN(id)) {
            return id
        } else {
            showToastError("Check if id you passed is correct (e.g. 5585)", "warning")
        }
    }

    useEffect(() => {
        setContentData({
            mediaType: validateMediaTypeFromParam(window.location.pathname.split("/")[2]),
            contentName: window.location.pathname.split("/")[3],
            contentId: validateContentId(window.location.pathname.split("/")[4])
        })
        fetchContentDetails();
    }, [window.location.pathname.split("/")[4]])

    const fetchContentDetails = () => {
        setFetchedContentData({
            isFetchLoading: true,
            fetchError: "",
        })
        axios.get("/api/content/management/get-content-details/", { params: { content_id: window.location.pathname.split("/")[4], media_type: window.location.pathname.split("/")[2], content_name: window.location.pathname.split("/")[3] } }).then((res) => {
            setFetchedContentData({
                fetchedData: res.data.message[0],
                fetchedContentInsData: res.data.ins_data,
                languagesSpoken: res.data.message[0]?.spoken_languages?.map(item => item.english_name),
                isFetchLoading: false,
                fetchError: "",
            });
            setFetchContentImagesData({
                backdropImages: res.data.message[0].images.backdrops,
                showBackdrop: res.data.show_backdrop
            });
            setUserBackendState({
                isContentInWT: res.data.isiwt ? true : false,
                isContentInWatching: res.data.iswtc ? true : false
            });

            const mergedData = {};
            for (const key in res.data.message[0]) {
                if (key.startsWith("season/")) {
                    if (!mergedData[key]) {
                        mergedData[key] = []
                    }
                    mergedData[key].push(res.data.message[0][key])
                }
            }
            const formattedData = Object.entries(mergedData).map(([key, value]) => ({
                [key]: value,
            }));
            // ! Fetch the trailer first and fetch the videos after a user actions (on scroll).

            setContentSeasonData({
                seasons: formattedData
            });
            getContentUpShots(window.location.pathname.split("/")[4]);
        })
    }

    const getContentUpShots = (contentId) => {
        setContentUpShotState({
            isFetchLoading: true,
            fetchError: "",
        })
        axios.get(`/api/content/main/fetch/content/upshots/?content_id=${contentId}`).then((res) => {
            setContentUpShotState({
                fetchedUpShots: res.data.message,
                isFetchLoading: false,
                fetchError: "",
            })
        }).catch(() => {
            setContentUpShotState({
                isFetchLoading: false,
                fetchError: "Something went Wrong!",
            })
        });
    }

    const openCloseVideoContainer = () => {
        setContentVideoState(prevState => ({
            ...prevState,
            isVideoContainerOpen: !prevState.isVideoContainerOpen
        }));
    }
    const videoContainer = () => {
        getVideos("t");
        openCloseVideoContainer();
    };
    const openVideosContainer = (videoData) => {
        openCloseVideoContainer();
        setVideoData({ name: videoData.videoTitle, key: videoData.videoId })
    }

    const getVideos = (videoType) => {
        setContentVideoState(prevState => ({
            ...prevState,
            isVideoFetching: true,
            videoFetchError: "",
        }))
        axios.get("/api/content/management/get-videos-news/", {
            params:
            {
                content_name: window.location.pathname.split("/")[3],
                media_type: window.location.pathname.split("/")[2],
                video_type: videoType,
            }
        }).then((res) => {
            if (videoType === "t") {
                setVideoData(prevState => ({
                    ...prevState,
                    key: res.data.message[0].videoId,
                    name: res.data.message[0].videoTitle
                }))
            } else {
                setContentVideoState(prevState => ({
                    ...prevState,
                    isVideoFetching: false,
                    videoFetchError: "",
                    fetchedContentVideo: res.data.message
                }))
            }
        }).catch(() => {
            setContentVideoState({
                isVideoFetching: false,
                videoFetchError: "Something went Wrong!",
            })
        })
    }

    // * Get 10 videos when the page loads.
    useEffect(() => {
        getVideos("10");
    }, [])

    function tableriggerLoveAnimation() {
        setAnimationsVisibility({
            isLoveAnimationOn: true,
        })
    }

    // function triggerMatrixAnimation() {
    //     setAnimationsVisibility({
    //         isMatrixAnimationOn: true,
    //     })
    // }

    function triggerHorrorAnimation() {
        setAnimationsVisibility({
            isHorrorTextAnimationOn: true,
        })
    }

    const saveTaste = () => {
        if (fetchedContentData?.fetchedData?.genres !== undefined) {

            const formattedDataContentId = [{
                [contentData.mediaType]: fetchedContentData?.fetchedData.id
            }]
            const formattedDataContentGenres = fetchedContentData?.fetchedData?.genres?.map(dict => dict.name)

            if (currentUserUsername) {
                const dataToPost = ({
                    visited_content_data: formattedDataContentId,
                    visited_content_genres: formattedDataContentGenres,
                    "content_type": "content",
                    "id": contentData.contentId,
                });
                console.log(dataToPost)
                axios.post('/api/user/t/save/user/taste/', dataToPost).then((res) => {
                    console.log(res)
                })
            } else {
            }
        }
    }

    // useEffect(() => {
    //     saveTaste();
    // }, [fetchedContentData?.fetchedData?.genres])

    const keywordFunctions = {
        "love": tableriggerLoveAnimation,
        "secret love": tableriggerLoveAnimation,
        "romance": tableriggerLoveAnimation,
        "tears": tableriggerLoveAnimation,
        "heart": tableriggerLoveAnimation,
        "christmas": tableriggerLoveAnimation,
        "lover": tableriggerLoveAnimation,
        "horror": triggerHorrorAnimation,
        "creepy": triggerHorrorAnimation,
        "haunted": triggerHorrorAnimation,
        "supernatural": triggerHorrorAnimation,
        "exorcism": triggerHorrorAnimation,
        "possession": triggerHorrorAnimation,
        "ghost": triggerHorrorAnimation,
        "demonic": triggerHorrorAnimation,
        "crucifix": triggerHorrorAnimation,
        "demonology": triggerHorrorAnimation,
        "cellar": triggerHorrorAnimation,
        "paranormal": triggerHorrorAnimation,
        // "computer": triggerMatrixAnimation,
        // "hacking": triggerMatrixAnimation,
        // "artificial intelligence": triggerMatrixAnimation,
        // "artificial world": triggerMatrixAnimation,
        // "hacker": triggerMatrixAnimation,
        // "secret society": triggerMatrixAnimation,
        // "nature of reality": triggerMatrixAnimation,
    }

    const formattedString = fetchedContentData?.fetchedData?.genres?.map((item, index) => {
        return index === 0 ? item.name : ` | ${item.name}`;
    }).join('');


    useEffect(() => {
        const intervalId = setInterval(() => {
            setImageIndex((prevIndex) => (prevIndex + 1) % fetchContentImagesData.backdropImages.length);
        }, changeImageSlideshowInSeconds);

        return () => clearInterval(intervalId);
    }, [fetchContentImagesData.backdropImages]);


    return (
        <LayoutComponentForHelmet pageTitle={`${fetchedContentData.isFetchLoading ? "SpreeCine" : `${fetchedContentData?.fetchedData?.original_title ? fetchedContentData?.fetchedData?.original_title : fetchedContentData?.fetchedData?.name} - SpreeCine`}`}>
            <div className='body-space'>
                <Suspense fallback={null}>
                    <VideoPlayerContainerLazy
                        videoData={videoData}
                        openVideoContainerModel={contentVideoState.isVideoContainerOpen}
                        onHideVideoContainerModel={openCloseVideoContainer}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <WatchLaterSelectionListLazy
                        show={wtDefaultModalShow}
                        onHide={() => setWtDefaultModalShow(false)}
                        data={wtFullData}
                        mediaType={contentData.mediaType}
                    />
                </Suspense>
                {
                    animationsVisibility.isLoveAnimationOn &&
                    <AnimateHeartCanvasV2 />
                }
                {/* {
                animationsVisibility.isHorrorTextAnimationOn &&
                <Sketch />
            } */}

                <div
                    className={`movie-data-container`}
                    style={{
                        backgroundImage:
                            fetchContentImagesData.showBackdrop
                                ?
                                fetchContentImagesData?.backdropImages?.length !== 0 ?
                                    `url("https://image.tmdb.org/t/p/original${fetchContentImagesData?.backdropImages?.[imageIndex]?.file_path}")`
                                    :
                                    `url("https://image.tmdb.org/t/p/original${fetchedContentData?.fetchedData?.backdrop_path}")`
                                :
                                `url("https://image.tmdb.org/t/p/original${fetchedContentData?.fetchedData?.backdrop_path}")`
                    }}
                >
                    <div className="movie-details-info">
                        {
                            !fetchedContentData.isFetchLoading &&
                            <>
                                <div className="movie-genres-list">{formattedString}</div>
                                <h1 className="movie-title">{fetchedContentData?.fetchedData?.original_title ? fetchedContentData?.fetchedData?.original_title : fetchedContentData?.fetchedData?.name}
                                    <span className="movie-date">{contentData.mediaType === "tv" ? <>{formatReturningSeriesRepresentation(fetchedContentData?.fetchedData?.first_air_date)}</> : <span className="movie-date">{formatDate(fetchedContentData?.fetchedData?.release_date)}</span>}</span>
                                </h1>
                                <p className='tagline'>{fetchedContentData?.fetchedData?.tagline}</p>
                                {
                                    contentData.mediaType === "tv" ?
                                        <div className="series-air-info">
                                            {t("Seasons")}:
                                            <div className="seasons-list">
                                                <span className="season-air-going">{fetchedContentData?.fetchedData?.number_of_seasons}</span>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </>
                        }
                        <ContentPlot imageUrl={"https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg"} plot={fetchedContentData?.fetchedData?.overview} isLoading={fetchedContentData.isFetchLoading} />
                        <div className="movie-buttons-actions">
                            {
                                !fetchedContentData.isFetchLoading &&
                                <div className="actions-icon-container">
                                    <div className="icon-with-text-to-right" onClick={videoContainer}>
                                        <PlayButton />
                                        <span className="icon-text gap-left-10-px make-text-cursor">{t("Watch Trailer")}</span>
                                    </div>

                                    <div className='content-main-action' onClick={() => updateBackendStateOnClickDynamically("addinwt", fetchedContentData?.fetchedData, "manual")}>
                                        {
                                            userBackendState.isContentInWT ?
                                                <WatchLaterFilled />
                                                :
                                                <WatchLaterUnFilled />
                                        }
                                        <p className="icon-text">{t("Add to Watch Later")}</p>
                                    </div>

                                    <div className='content-main-action' onClick={() => updateBackendStateOnClickDynamically("wtc", fetchedContentData?.fetchedData, "manual")}>
                                        {
                                            userBackendState.isContentInWatching ?
                                                <WatchingIcon />
                                                :
                                                <WatchingUnFilled />
                                        }
                                        <p className="icon-text">{t("Add into Watching")}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        !fetchedContentData.isFetchLoading &&
                        <div className="movie-info">
                            <div className="data-table">
                                <div className="data-table-item gap-bottom-10px">
                                    <div className="data-label">{t("Director")}(s):</div>
                                    <div className="data-value">{fetchedContentData?.fetchedContentInsData?.Director}</div>
                                </div>
                                <div className="data-table-item">
                                    <div className="data-label">{t("Starring")}:</div>
                                    <div className="data-value">{fetchedContentData?.fetchedContentInsData?.Actors}</div>
                                </div>
                                <div className="data-table-item">
                                    <div className="data-label">Language:</div>
                                    <div className="data-value">{fetchedContentData?.languagesSpoken?.map((language) => <>{language},</>)}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div >

                <div className='content-data-additional-content'>
                    {contentSeasonData?.seasons?.map((dataObject, index) => (
                        <>
                            {Object.entries(dataObject).map(([key, value]) => (
                                <>
                                    <div className="title-container">
                                        {t("Season")} {formatTheSeasonString(key)}
                                        <svg onClick={() => openCloseSeasonDropdown(key)} className='make-text-cursor' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                                            <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
                                        </svg>
                                    </div>
                                    {
                                        seasonDropdown[key] && (
                                            <div className="scroll-container">
                                                <div className="seasons-horizontal-data-list">
                                                    {value.map((dataItem, dataIndex) => (
                                                        <>
                                                            {
                                                                dataItem.episodes?.map((episode, index) =>
                                                                    <div className="season-item">
                                                                        {
                                                                            episode.still_path !== null ?
                                                                                <img src={`https://image.tmdb.org/t/p/w200/${episode.still_path}`} />
                                                                                :
                                                                                <img src={defaultPlaceholderImageIfNotPresentAny} />
                                                                        }
                                                                        <span className='r-d-season-episode-info-icon' onClick={() => openCloseEpisodeOverview(episode)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                                        </span>
                                                                        {
                                                                            seasonEpisodePopUpState.isSeasonOverviewPopupOpen &&
                                                                            <>
                                                                                {
                                                                                    seasonEpisodePopUpState.currentOpenedEpisodeId === episode.id &&
                                                                                    <div className='r-d-season-episode-info-popup'>
                                                                                        <b>{episode.name} - {episode.air_date}</b>
                                                                                        <hr />
                                                                                        <span>{episode.overview}</span>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            ))}
                        </>
                    ))}

                    <div className="title-container">
                        {t("Videos")}
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                            <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
                        </svg>
                    </div>

                    <div className="scroll-container">
                        <div className="season-videos-list">
                            {
                                contentVideoState?.fetchedContentVideo?.map((video, index) =>
                                    <div className="season-video-item">
                                        <img onClick={() => openVideosContainer(video)} src={video.videoThumbnail} />
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className='content-upshots'>
                        <div className="vertical-upshots-container">
                            {
                                contentUpShotState?.fetchedUpShots?.map((upshot, index) =>
                                    <div className="medium-upshot-container" key={upshot.id}>
                                        <p className="rel-text">{upshot.title}</p>
                                        <p><small>{upshot.description}</small></p>
                                        <p className="rel-time" title={upshot.date_published}>{upshot.natural_time}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}


export default RecordDetail;