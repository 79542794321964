import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Fireworks from "@fireworks-js/react";

import "./moderationMessage.css";
import LoadingIfElseErrorSvg from "../../animations/LoadingIfElseErrorSvg";


function ModerationMessage() {
    const { t } = useTranslation();
    const [messageDataState, setMessageDataState] = useState({
        isMessageLoading: false,
        messageFetchError: "",

        type: "",
        subject: "",
        message: "",
        naturalTimeStamp: "",
        messageType: "",
        additionalContent: {},
    });
    const [userUsername, setUserUsername] = useState("");
    const [messageBanState, setMessageBanState] = useState({
        subject: "",
        shortDescription: "",
        message: "",
        timeStamp: "",
        banReason: "",
        banDuration: "",
    });
    const params = useParams();

    const fetchModMessage = (paramId) => {
        setMessageDataState({
            isMessageLoading: true,
            messageFetchError: "",
        })

        axios.get(`/moderation/message/fetch/user/${params.userUsername}/${paramId}/`).then((res) => {
            console.log(res);
            const texturedMessage = res.data.message.message.replace(/\n\n\n/g, '\n');
            setMessageDataState({
                type: res.data.type,
                isMessageLoading: false,
                messageFetchError: "",
            });
            setUserUsername(res.data.userUsername);
            if (res.data.type === "M" || res.data.type === "G") {
                setMessageDataState({
                    type: res.data.type,
                    message: texturedMessage,
                    subject: res.data.message.subject,
                    naturalTimeStamp: res.data.message.send_timestamp,
                    messageType: res.data.message.message_type,
                    additionalContent: res.data.message.content_additional_data[0],
                    isMessageLoading: false,
                    messageFetchError: "",
                })
            } else if (res.data.type === "B") {
                setMessageBanState({
                    subject: res.data.message.subject,
                    shortDescription: res.data.message.short_description,
                    message: texturedMessage,
                    timeStamp: res.data.message.ban_timestamp,
                    banReason: res.data.message.ban_reason,
                    banDuration: res.data.message.ban_duration,
                })
                setMessageDataState({
                    isMessageLoading: false,
                    messageFetchError: "",
                })
            }
        }).catch(() => {
            setMessageDataState({
                isMessageLoading: false,
                messageFetchError: "Something went Wrong!",
            })
        })
    }

    useEffect(() => {
        fetchModMessage(params.id)
    }, [params.id])

    return (
        <div className="body-space">
            {
                messageDataState.isMessageLoading ?
                    <LoadingIfElseErrorSvg isLoading={messageDataState.isMessageLoading} errorString={messageDataState.messageFetchError} retry={() => fetchModMessage(params.id)} loadingLogoBigOrSmall={"big"} classNamesForLoading={"svg-inline retry-rotate"} classNamesForError={"svg-inline"} />
                    :
                    <>
                        {
                            messageDataState.messageFetchError === "" ?
                                <>
                                    {
                                        messageDataState?.type === "M" || messageDataState?.type === "G"
                                            ?
                                            <>
                                                {/* {
                                                    messageDataState?.type === "G" &&
                                                    <Fireworks options={options} style={style} />
                                                } */}
                                                <div className="letter">
                                                    <p className="subject">{t(messageDataState?.subject)}</p>
                                                    <div className="date">
                                                        <p>Date: {messageDataState?.naturalTimeStamp}</p>
                                                    </div>
                                                    <div className="body-content">
                                                        <p style={{ whiteSpace: 'pre-line' }}>{t(messageDataState?.message)}</p>
                                                    </div>
                                                    {/* <FireworksCanvas /> */}
                                                    {/* {
                                                        Object.keys(messageDataState.additionalContent).length !== 0
                                                        &&
                                                        <div className="reported-container">
                                                            <p className="reported-meta">Posted on {messageDataState.additionalContent?.timestamp}</p>
                                                            <div className="reported-content">
                                                                <p>
                                                                    {messageDataState.additionalContent?.content}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    } */}
                                                    <Link to={"/contact"} className="contact-button">{t("Get in Touch")}</Link>
                                                </div>
                                            </>
                                            :
                                            <div className="ban-letter-container">
                                                <div className="ban-letter">
                                                    <div className="ban-letter-header">{t(messageBanState?.subject)}</div>
                                                    <div className="ban-letter-content">
                                                        <p>{t("Dear User")},</p>
                                                        <br />
                                                        <p style={{ whiteSpace: 'pre-line' }}>{t(messageBanState?.shortDescription)}</p>
                                                        <br />
                                                        <p>
                                                            {t("Ban Reason")}: <b>{messageBanState?.banReason}</b>
                                                            <br />
                                                            {t("Ban Duration")}: <b>{messageBanState?.banDuration}</b>
                                                            <br />
                                                            {t("Username")}: <b>{userUsername}</b>
                                                        </p>
                                                        <br />
                                                        <p style={{ whiteSpace: 'pre-line' }}>
                                                            {messageBanState?.message}
                                                        </p>
                                                    </div>
                                                    <br />
                                                    <Link to={"/contact"} className="contact-button">{t("Get in Touch")}</Link>
                                                </div>
                                            </div>
                                    }
                                </>
                                :
                                <h2 className="clr-white">{messageDataState.messageFetchError}</h2>
                        }
                    </>
            }
        </div>
    )
}

export default ModerationMessage;