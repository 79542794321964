import textTrimmer from "../../helpers/stringCutter";
import pluralize from "../../helpers/pluralize";

function RemarkCommentListSingle({ id, item, userProfileImage }) {

    return (
        <li className="remark-comment-list-item" key={id}>
            <img className='remark-comment-user-image' src={userProfileImage} />
            <div className="remark-comment-content">
                <div className="remark-comment-header">
                    <h3 className="remark-comment-user-name">{item.commented_by_full_name}</h3>
                    <span className="remark-comment-date">{item.comment_creation_timestamp}</span>
                </div>
                <div className="quote-block">
                    <span>
                        {textTrimmer(item.comment_remark_content, 70)}
                    </span>
                </div>
                <p className="remark-comment-text">
                    {item.comment_body}
                </p>
                <div className="remark-comment-action-details">
                    <div className="remark-comment-likes">{item.comment_likes_count} Like{pluralize(item.comment_likes_count)}</div>
                    <div className="remark-comment-dislikes">{item.comment_dislikes_count} Dislike{pluralize(item.comment_dislikes_count)}</div>
                    <div className="remark-comment-replies">{item.comment_replies_count} Replies</div>
                </div>
            </div>
        </li>
    )
}

export default RemarkCommentListSingle;