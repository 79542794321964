import { useEffect, useState } from 'react';
import { Button, Divider, Group, Input, Modal, SegmentedControl, Space } from '@mantine/core';
import { useTranslation } from "react-i18next";


function AdditionalInfoReportModal({ isAdditionalInfoModalInfo, setAdditionalInfoModalOpen, setMainReportShow, descriptionValue, setdescriptionValue, effectedType, setEffectedType, howEffectingTargeted, setHowEffectingTargeted, saveToServer, reportSaveState, closeEverything }) {
    const { t } = useTranslation();
    const [fieldValidationSate, setFieldValidationState] = useState({
        isHowEffectingFieldEmpty: false,
    })

    const backToMainReportModal = () => {
        setAdditionalInfoModalOpen(false);
        setMainReportShow(true);
    }

    const updateTargetType = (value) => {
        if (value !== undefined) {
            setEffectedType(value);
        }
    }

    const validateAndSendToBackend = () => {
        if (howEffectingTargeted !== "") {
            setFieldValidationState(prevState => ({
                ...prevState,
                isHowEffectingFieldEmpty: false,
            }));
            saveToServer();
        } else {
            setFieldValidationState(prevState => ({
                ...prevState,
                isHowEffectingFieldEmpty: true,
            }))
        }
    }

    const dataOptions = ['Whole community', 'Targeted me'].map(option => t(option));

    return (
        <Modal opened={isAdditionalInfoModalInfo} onClose={backToMainReportModal} radius="10px" size="lg" title={t("Add Additional Info")} centered>
            {/* Please describe more as possible about reporting content */}
            <div className="report-area">
                <div className="copyright-input-container">
                    <Input.Wrapper label={t("Want to explain more about it?")} error={""}>
                        <Input value={descriptionValue} onInput={(e) => setdescriptionValue(e.target.value)} />
                    </Input.Wrapper>
                    <Divider my="xl" />

                    <label htmlFor="name">{t("Is it directed to whole community or it only targets you?")}</label>
                    <SegmentedControl fullWidth radius="md" data={dataOptions} onChange={(e) => updateTargetType(e === "Whole community" ? "WHLCMUNTY" : "TRGTDM")} />
                    <Space h="md" />

                    <Input.Wrapper label={effectedType === "TRGTDM" ? <>{t("Why are they doing it?")}</> : <>{t("How is it effecting/effected the community?")}</>} error={fieldValidationSate.isHowEffectingFieldEmpty && "Required"}>
                        <Input className={`${fieldValidationSate.isHowEffectingFieldEmpty && `border-red`}`} value={howEffectingTargeted} onInput={(e) => setHowEffectingTargeted(e.target.value)} />
                    </Input.Wrapper>

                    {/* <br />
                                <br />
                                <br />
                                <label htmlFor="message">Have any additional data?(e.g. like screenshots)</label>
                                <textarea id="message" name="message" placeholder="Enter your message..."></textarea> */}

                    <Group justify="right" mt="xl">
                        <Button variant="outline" color="red" onClick={closeEverything}>
                            {t("Discard")}
                        </Button>
                        <Button variant="outline" color="#9e73a6" disabled={effectedType === "" ? true : false} loading={reportSaveState.isReportSaveLoading} onClick={validateAndSendToBackend}>
                            {t("Save")}
                        </Button>
                    </Group>
                </div>
            </div>
        </Modal>
    )
}

export default AdditionalInfoReportModal;