
function UpShotCreationTitleFirstStep({ t, upShotDataState, activeTabState, handleInputChangeInDBLevel }) {

    return (
        <>
            {
                activeTabState === "title" ?
                    <>
                        <input
                            type="text"
                            name="upShotTitleText"
                            className="title-input"
                            onChange={handleInputChangeInDBLevel}
                            placeholder={t(upShotDataState.upShotTitlePlaceHolderText)}
                            value={upShotDataState.upShotTitleText}
                        />
                        {
                            upShotDataState.titleFirstSectionErrorSeen ?
                                <div className='err-message'>
                                    {upShotDataState.titleFirstSectionError}
                                </div>
                                :
                                null
                        }
                        <textarea
                            className="content-input"
                            name='upShotDescriptionText'
                            onChange={handleInputChangeInDBLevel}
                            placeholder={t("Explain more here...")}
                            spellCheck="false"
                            value={upShotDataState.upShotDescriptionText}
                        >
                        </textarea>
                    </>
                    :
                    null
            }
        </>
    )
}

export default UpShotCreationTitleFirstStep;