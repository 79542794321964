import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import "./styles/audienceList.css";
import LoadingIfElseErrorSvg from "../animations/LoadingIfElseErrorSvg";
import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";
import { defaultPlaceholderImageIfNotPresentAny } from "../constants/constants";
import { defaultBackendOrigin } from "../constants/constants";
import { GlobalLoginRequiredModalContext } from "../../MainInterface/contexts/LoginRequired/LoginRequiredModal";
import { SegmentedControl } from "@mantine/core";

function AddOrRemoveFromAudienceButton({ currentUserUsername, userId, sortFilter, t }) {
    const [clickedToRemove, setClickedToRemove] = useState(Array(0).fill(false));

    const clickToKickUser = (userId) => {
        setClickedToRemove(prevState => {
            if (prevState[userId]) {
                const { [userId]: removedBlog, ...rest } = prevState;
                return rest;
            } else {
                return { ...prevState, [userId]: true };
            }
        });
    }

    const removeUserFromAudience = (user) => {
        clickToKickUser(user);
        axios.put(`/api/users/primary/kick/user/from-audience/${currentUserUsername}/${user}/`).then((res) => {
        }).catch(() => {
            clickToKickUser(user);
        })
    }

    const stepOutFromAudience = (user) => {
        clickToKickUser(user);
        axios.put(`/api/users/primary/step-out/user/from-audience/${currentUserUsername}/${user}/`).then((res) => {
        }).catch(() => {
            clickToKickUser(user);
        })
    }

    // if (requestStatus) {
    //     return <button type="button" className="a-l-audience-add-btn" disabled>Sent</button>
    // }

    // if (audienceVisibility === "A") {
    //     return <button type="button" className="a-l-audience-add-btn" onClick={() => addUserInAudience(userId)}>Add to Audience</button>
    // } else if (audienceVisibility === "AR") {
    //     if (clickedToRemove[userId]) {
    //         return <button type="button" className="a-l-audience-add-btn" disabled>Sent</button>
    //     } else {
    //         return <button type="button" className="a-l-audience-add-btn" onClick={() => addUserInAudience(userId)}>Send Audience Request</button>
    //     }
    // } else {
    //     return <button type="button" className="a-l-audience-add-btn disabled-a-r" onClick={noOneCanJoinMessage}>Add to Audience</button>
    // }
    if (sortFilter === "myAudience") {
        return <button type="button" className={`a-l-audience-add-btn ${clickedToRemove[userId] ? "disabled-a-r" : ""}`} disabled={clickedToRemove[userId] ? true : false} onClick={() => removeUserFromAudience(userId)}>Kick from Audience</button>
    } else {
        return <button type="button" className={`a-l-audience-add-btn ${clickedToRemove[userId] ? "disabled-a-r" : ""}`} disabled={clickedToRemove[userId] ? true : false} onClick={() => stepOutFromAudience(userId)}>Step out</button>
    }
}

function AudienceList({ currentUserUsername }) {
    const { t } = useTranslation();
    const params = useParams();
    const { openModal } = useContext(GlobalLoginRequiredModalContext);
    const [cuo, setCuo] = useState(false);
    const [sortFilter, setSortFilter] = useState("myAudience");
    const [audienceState, setAudienceState] = useState({
        fetchedAudience: [],
        username: "",
        fetchLoading: false,
        fetchError: "",
    });

    useEffect(() => {
        getAudience(params.user);
    }, [sortFilter, params.user])

    const getAudience = (username) => {
        if (!username) {
            return null;
        }
        setAudienceState({
            fetchLoading: true,
            fetchError: "",
        });
        axios.get(`/api/users/primary/user-profile/audience/list/${username}/?filter_by=${sortFilter}`).then((res) => {
            setCuo(res.data.cuo)
            if (sortFilter === "myAudience") {
                setAudienceState({
                    fetchedAudience: res.data.message.audience,
                    username: res.data.username,
                    fetchLoading: false,
                    fetchError: "",
                })
            } else {
                const f = res.data.message.audience.map((item) => item.user)
                setAudienceState({
                    fetchedAudience: f,
                    username: res.data.username,
                    fetchLoading: false,
                    fetchError: "",
                })
            }
        }).catch(() => {
            setAudienceState({
                fetchLoading: false,
                fetchError: "Something went Wrong",
            })
        })
    }

    const audienceFilterOptions = ['My Audience', "Me as Audience"].map(option => t(option));

    if (audienceState.fetchLoading) {
        return <LoadingIfElseErrorSvg isLoading={audienceState.fetchLoading} fetchError={audienceState.fetchError} loadingLogoBigOrSmall={"big"} retry={getAudience} />
    }

    if (!currentUserUsername) {
        return null;
    }


    return (
        <LayoutComponentForHelmet pageTitle="Audience - SpreeCine">
            <div className="body-space audiences-container">
                <div className="audience-filter-header">
                    <SegmentedControl fullWidth radius="md" data={audienceFilterOptions} onChange={(e) => setSortFilter(e === "My Audience" ? "myAudience" : "meAsAudience")} />
                </div>

                <div className="user-list-container">
                    {
                        audienceState?.fetchedAudience?.length > 0 ?
                            <ul className="styled-list">
                                {
                                    audienceState?.fetchedAudience?.map((user) =>
                                        <li key={user.id}>
                                            <div className="user-info">
                                                {
                                                    user.user_profile_image !== "" ?
                                                        <img src={`${defaultBackendOrigin}${user.user_profile_image}`} alt="Image 1" />
                                                        :
                                                        <img src={defaultPlaceholderImageIfNotPresentAny} alt="Image 1" />
                                                }
                                            </div>
                                            <div className="content-content-main">
                                                <Link to={`/profile/${user.username}/`}><h3>{user.user_profile_full_name}</h3></Link>
                                                <p>@{user.username}</p>
                                            </div>
                                            <div className="user-list-actions">
                                                <span className="user-date-joined" title="Date Joined">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15"><path d="M320-200q-117 0-198.5-81.5T40-480q0-117 81.5-198.5T320-760q27 0 52.5 5t49.5 14q-17 14-32 30.5T362-676q-10-2-20.5-3t-21.5-1q-83 0-141.5 58.5T120-480q0 83 58.5 141.5T320-280q11 0 21.5-1t20.5-3q13 18 28 34.5t32 30.5q-24 9-49.5 14t-52.5 5Zm320 0q-27 0-52.5-5T538-219q17-14 32-30.5t28-34.5q11 2 21 3t21 1q83 0 141.5-58.5T840-480q0-83-58.5-141.5T640-680q-11 0-21 1t-21 3q-13-18-28-34.5T538-741q24-9 49.5-14t52.5-5q117 0 198.5 81.5T920-480q0 117-81.5 198.5T640-200Zm-160-50q-57-39-88.5-100T360-480q0-69 31.5-130T480-710q57 39 88.5 100T600-480q0 69-31.5 130T480-250Z" /></svg>
                                                    <span>{user.user_joined_date}</span>
                                                </span>
                                                <AddOrRemoveFromAudienceButton audienceVisibility={user.u_p_v} sortFilter={sortFilter} currentUserUsername={currentUserUsername} userId={user.id} requestStatus={user.is_audience_request_pending} t={t} />
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                            :
                            <div className="clr-white">
                                {
                                    sortFilter === "myAudience" ?
                                        <>
                                            {!cuo ? <>{audienceState.username}</> : <>You</>} don't have any Audience
                                        </>
                                        :
                                        <>
                                            {!cuo ? <>{audienceState.username}</> : <>You</>} is not watching anyone as Audience
                                        </>
                                }
                            </div>
                    }
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default AudienceList;