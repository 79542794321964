import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Avatar, Badge } from "@mantine/core";
import { IconBell } from '@tabler/icons-react';


import { pagesToHideHeaderOn, randomNamesForSearchInputPlaceholderList } from "../../components/constants/constants";
import ProfileMenuHeaderDropdown from "./HeaderDropdowns/ProfileMenuHeaderDropdown";
import ThemeContext from "../../components/appearance/ThemeContext";
import "./styles/header.css";
import PickFirstLetterOfName from "../../components/helpers/pickFirstChracterOfName";

const LazyNotificationContainer = lazy(() => import("./HeaderDropdowns/NotificationContainer"))


function Header({ unreadNotifications, isSearchModalOpen, setSearchModalOpen, currentUserUsername, currentUserProfileImage, setShortModalOpen, logout, t, dropdownRef, setSettingsOpen, setUnreadNotificationsCount, setEQModalOpen, userOnPremium }) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const { theme, toggleTheme } = useContext(ThemeContext);
    const locationPathname = location.pathname;
    const [isProfilePanelOpen, setProfilePanelOpen] = useState(false);
    const [isNotificationPanelOpen, setNotificationPanelOpen] = useState(false);
    const hideHeader = pagesToHideHeaderOn.some((path) => locationPathname.startsWith(path));

    const [searchedQuery, setSearchedQuery] = useState("");
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [randomName, setRandomName] = useState("");

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setProfilePanelOpen(false);
                setNotificationPanelOpen(false);
            }
        };
        window.addEventListener("click", handleOutsideClick);
        return () => {
            window.removeEventListener("click", handleOutsideClick)
        }
    }, [])

    const openCloseNotificationPanel = () => {
        setNotificationPanelOpen(!isNotificationPanelOpen);
        setProfilePanelOpen(false);
    }

    const openCloseProfileMenuPanel = () => {
        setProfilePanelOpen(!isProfilePanelOpen);
        setNotificationPanelOpen(false);
    }

    const openUserSettings = () => {
        openCloseProfileMenuPanel();
        setSettingsOpen(true);
    }

    const pickRandomName = () => {
        const randomIndex = Math.floor(Math.random() * randomNamesForSearchInputPlaceholderList.length);
        const selectedName = randomNamesForSearchInputPlaceholderList[randomIndex];
        setRandomName(selectedName);
    };

    useEffect(() => {
        pickRandomName();
    }, [randomNamesForSearchInputPlaceholderList])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 400) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isSearchModalOpen) {
            if (location.pathname !== "/search") {
                navigate("/search");
            }
        }
    }, [isSearchModalOpen])

    useEffect(() => {
        if (location.pathname === "/search") {
            if (searchedQuery) {
                setSearchParams({ q: searchedQuery }, { replace: true })
            } else {
                searchParams.delete("q")
                setSearchParams(searchParams, { replace: true })
            }
        }
    }, [searchedQuery, setSearchParams, searchParams])


    if (hideHeader) {
        return null;
    }

    return (
        <>
            <div className={`header-v3`}>
                {
                    currentUserUsername ?
                        <div className={`input-container-v3 ${isSmallScreen && "left-40"}`}>
                            <input
                                type="text"
                                onChange={(e) => setSearchedQuery(e.target.value)}
                                value={searchedQuery}
                                onFocus={() => setSearchModalOpen(true)}
                                onBlur={() => setSearchModalOpen(false)}
                                className="input-field-v3"
                                required
                                placeholder={randomName}
                            />
                        </div>
                        :
                        // <div className={`input-container-v3 ${isSmallScreen && "left-40"}`}>
                        //     <input
                        //         type="text"
                        //         onFocus={() => window.location.pathname !== "/login" ? navigate("/login") : null}
                        //         className="input-field-v3"
                        //         required
                        //         placeholder={randomName}
                        //     />
                        // </div>
                        null
                }

                <div className="user-info-v3">
                    {
                        currentUserUsername ?
                            <>
                                <Avatar color={theme === "light" ? "black" : "#f5deff"} radius="sm" variant="transparent" onClick={openCloseNotificationPanel} style={{ position: 'relative' }}>
                                    {
                                        unreadNotifications !== 0 &&
                                        <Badge circle
                                            style={{ position: 'absolute', top: '0.11rem', right: '0.15rem', width: '1.3rem', height: '1.3rem', backgroundColor: '#ecbfff', color: 'black', }}>
                                            {unreadNotifications}
                                        </Badge>
                                    }
                                    <IconBell size="1.6rem" className="notification-icon-v3 make-text-cursor" />
                                </Avatar>
                                {
                                    currentUserProfileImage ?
                                        <Avatar src={currentUserProfileImage} onClick={openCloseProfileMenuPanel} alt="It's me" radius="xl" size={"30px"} className="make-text-cursor" />
                                        :
                                        <Avatar onClick={openCloseProfileMenuPanel} color="cyan" alt="It's me" radius="xl" size={"30px"} className="make-text-cursor">{PickFirstLetterOfName({ currentUserUsername })}</Avatar>
                                }
                            </>
                            :
                            <Avatar variant="transparent" radius="sm" color="#a636d7" src="" onClick={openCloseProfileMenuPanel} className="make-text-cursor" />
                        // <Link to={"/login"}><button type='button' className='header-login-button-glow-on-hover'>Login</button></Link>
                    }
                </div>
            </div>
            <ProfileMenuHeaderDropdown
                isProfilePanelOpen={isProfilePanelOpen}
                theme={theme}
                openCloseProfileMenuPanel={openCloseProfileMenuPanel}
                openUserSettings={openUserSettings}
                setShortModalOpen={setShortModalOpen}
                logout={logout}
                toggleTheme={toggleTheme}
                t={t}
                currentUserUsername={currentUserUsername}
                userOnPremium={userOnPremium}
            />

            <Suspense fallback={null}>
                <LazyNotificationContainer
                    isNotificationPanelOpen={isNotificationPanelOpen}
                    setUnreadNotificationsCount={setUnreadNotificationsCount}
                    openCloseNotificationPanel={openCloseNotificationPanel}
                    openEQModal={() => setEQModalOpen(true)}
                />
            </Suspense>
        </>
    )
}

export default Header;