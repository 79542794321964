import { useEffect, useState } from "react";

import SpreeCineLoginSignupSVGLogo from '../../../MainInterface/assets/SpreeCineLoginSignupLogoSVG';


function LegalPolicyHeader({ includeAppName, header }) {
    const [lastUpdate, setLastUpdate] = useState("");

    useEffect(() => {
        if (header === "User Agreement") {
            setLastUpdate("14-01-2024")
        } else if (header === "Terms of Service") {
            setLastUpdate("14-01-2024")
        } else if (header === "Privacy Policy") {
            setLastUpdate("14-01-2024")
        } else if (header === "Cookie Policy") {
            setLastUpdate("14-01-2024")
        } else if (header === "Premium Agreement") {
            setLastUpdate("14-01-2024")
        }
    }, [header])

    return (
        <div className="privacy-policy-header-container-main">
            <div className="privacy-policy-header-container">
                <header className="privacy-policy-header-info">
                    <SpreeCineLoginSignupSVGLogo svgsizewidth={"50%"} bgcolor={"rgb(68, 68, 68)"} />
                    <b className='privacy-center-last-updated'>Last updated: {lastUpdate}</b>
                    <h1>{includeAppName && "SpreeCine's"} {header}</h1>
                </header>
            </div>
        </div>
    )
}

export default LegalPolicyHeader;