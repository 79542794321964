import { useTranslation } from "react-i18next";

import "./styles/banned.css";

function BannedPage({ whoIsAccessing, page, banReliefTill }) {
    const { t } = useTranslation();

    return (
        <div className="ban-body">
            <div className="ban-container">
                <h1 className="ban-title">{t(`${whoIsAccessing === "current" ? "You're Banned" : "User is Banned"}`)}</h1>
                {
                    page === "upshotCreation" ?
                        <p className="ban-message">{t("Unfortunately, You cannot create UpShot.")}</p>
                        :
                        null
                }
                {
                    whoIsAccessing === "current" ?
                        <>
                            {
                                banReliefTill ?
                                    <p>{t(`Ban will be Lifted at ${banReliefTill}.`)}</p>
                                    :
                                    null
                            }
                        </>
                        :
                        null
                }
                <p>{t("Please contact the administrator for more information.")}</p>
            </div>
        </div>
    )
}

export default BannedPage;