import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import './styles/watching.css';
import MaintenanceMode from "../Preventation/Maintenance/maintenance";
import LoadingLogo from "../animations/LoadingLogo";
import PageNumberPagination from "../helpers/PageNumberPagination";
import SomethingWentWrong from "../helpers/SomethingWentWrongMessage";
import ConfirmationDialog from "../modals/ConfirmationModal";
import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";

function WatchingList() {
    const params = useParams();
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const [isMaintenanceOn, setMaintenanceIsOn] = useState(false);
    const [watchingState, setWatchingState] = useState({
        fetchedWatchings: [],

        isFetchLoading: false,
        fetchError: null,
    });
    const [watchingDeleteState, setWatchingDeleteState] = useState({
        isDeleteLoading: false,
        deleteError: "",
        currentDeletingId: 0,
    });
    const [contentPaginationState, setContentPaginationState] = useState({
        currentPage: 1,
        totalPages: 0,
        hasNextPage: false,
        hasPreviousPage: false,
    });
    const [hoveredItem, setHoveredItem] = useState(0);

    const showDeleteButtonOnHover = (id) => {
        setHoveredItem(id);
    }
    const hideDeleteButtonOnLeave = () => {
        setHoveredItem(0);
    }

    useEffect(() => {
        fetchUserWatchings(params.username, 1);
    }, []);

    const goToNextPage = () => {
        fetchUserWatchings(params.username, contentPaginationState.currentPage + 1)
    };

    const goToPrevPage = () => {
        fetchUserWatchings(params.username, contentPaginationState.currentPage - 1)
    };

    const fetchUserWatchings = (username, page) => {
        setMaintenanceIsOn(false);
        setWatchingState({
            isFetchLoading: true,
            fetchError: "",
        });
        axios.get(`/api/content/main/watching/create/get-list/${username}/?page=${page}`).then((res) => {
            setMaintenanceIsOn(false);
            setWatchingState(prevState => ({
                ...prevState,
                fetchedWatchings: res.data.results,
                isFetchLoading: false,
                fetchError: "",
            }));
            setContentPaginationState(prevState => ({
                ...prevState,
                currentPage: res.data.current_page,
                totalPages: res.data.total_pages,
                hasNextPage: res.data.links.next,
                hasPreviousPage: res.data.links.previous,
            }));
        }).catch((err) => {
            setWatchingState({
                isFetchLoading: false,
                fetchError: "Something went wrong",
            })
            if (err.response.request.status) {
                setMaintenanceIsOn(true);
            } else {
                setMaintenanceIsOn(false);
            }
        })
    }

    const deleteContentFromWatching = (id) => {
        setWatchingDeleteState(prevState => ({
            ...prevState,
            isDeletingInProgress: true,
            deleteError: "",
            username: params.username
        }))
        axios.delete(`/api/content/main/watching/delete/${id}/?username=${params.username}`).then(() => {
            setWatchingDeleteState(prevState => ({
                ...prevState,
                isDeletingInProgress: false,
                deleteError: "",
                currentDeletingId: 0,
            }));
            setWatchingState(prevState => ({
                ...prevState,
                fetchedWatchings: prevState.fetchedWatchings.filter(item => item.id !== id),
            }));
            setConfirmationModalOpen(false);
        }).catch(() => {
            setWatchingDeleteState({
                isDeletingInProgress: false,
                deleteError: "Something went wrong",
                currentDeletingId: 0,
            })
        })
    }

    const confirmation = () => {
        deleteContentFromWatching(watchingDeleteState.currentDeletingId);
    }

    const openDeleteConfirmationDialog = (id) => {
        setWatchingDeleteState(prevState => ({
            ...prevState,
            currentDeletingId: id,
            username: params.username
        }));
        setConfirmationModalOpen(true);
    }

    const retry = () => {
        fetchUserWatchings(params.username, 1)
    }

    if (isMaintenanceOn) {
        return <MaintenanceMode />
    };

    if (watchingState.isFetchLoading) {
        return <LoadingLogo type="processing" />
    };

    if (watchingState.fetchError !== "") {
        return <SomethingWentWrong retry={retry} />
    }

    return (
        <LayoutComponentForHelmet pageTitle={"Watchings - SpreeCine"}>
            <div className='watching-container'>
                <ul className="image-container">
                    {
                        watchingState?.fetchedWatchings?.map((watching) =>
                            <li key={watching.id} onMouseEnter={() => showDeleteButtonOnHover(watching.id)} onMouseLeave={hideDeleteButtonOnLeave}>
                                <Link
                                    to={`/content/${watching.content_data.contentType}/${watching.content_data.original_title.replace(/\s+/g, "-")}/${watching.content_data.id}`}
                                    state={{ "id": watching.content_data.id, "name": watching.content_data.original_title, "mediaType": watching.content_data.contentType }}
                                    className="text-black"
                                    style={{ textDecoration: "none" }}
                                >
                                    <img src={`https://image.tmdb.org/t/p/w500/${watching.content_data.poster_path}`} alt="" />
                                </Link>
                                {
                                    watching.is_request_user_owner &&
                                    <>
                                        {
                                            watchingDeleteState?.isDeletingInProgress ?
                                                <>
                                                    {
                                                        watchingDeleteState.currentDeletingId === watching.id ?
                                                            <div className={`top-right-text make-text-cursor`} onClick={() => openDeleteConfirmationDialog(watching.id)}>
                                                                <span className="loading-icon"></span>
                                                            </div>
                                                            :
                                                            <>
                                                                {
                                                                    hoveredItem === watching.id &&
                                                                    <div className={`top-right-text make-text-cursor`} onClick={() => openDeleteConfirmationDialog(watching.id)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-48v-72h192v-48h192v48h192v72h-48v479.566Q720-186 698.85-165 677.7-144 648-144H312Zm336-552H312v480h336v-480ZM384-288h72v-336h-72v336Zm120 0h72v-336h-72v336ZM312-696v480-480Z" /></svg>
                                                                    </div>
                                                                }
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        hoveredItem === watching.id &&
                                                        <div className={`top-right-text make-text-cursor`} onClick={() => openDeleteConfirmationDialog(watching.id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-48v-72h192v-48h192v48h192v72h-48v479.566Q720-186 698.85-165 677.7-144 648-144H312Zm336-552H312v480h336v-480ZM384-288h72v-336h-72v336Zm120 0h72v-336h-72v336ZM312-696v480-480Z" /></svg>
                                                        </div>
                                                    }
                                                </>
                                        }
                                    </>
                                }
                                <Link to={`/cl/upshots/${watching.watcing_tv_movie_name.replace(
                                    /\s+/g,
                                    "-"
                                )}/${watching?.watcing_tv_movie_id}/${watching.content_data.contentType}/`} className="watching-section watching-section-left">UpShots</Link>
                                <Link to={`/c/lookup/remarks/${watching.watcing_tv_movie_name.replace(
                                    /\s+/g,
                                    "-"
                                )}/${watching?.watcing_tv_movie_id}/${watching.content_data.contentType}/`} className="watching-section watching-section-right">Remarks</Link>
                            </li>
                        )
                    }
                </ul>
                <ConfirmationDialog
                    isOpen={isConfirmationModalOpen}
                    close={() => setConfirmationModalOpen(false)}
                    confirm={confirmation}
                    deleteState={watchingDeleteState}
                    confirmationPageFor={"Delete Watching"}
                />
                <PageNumberPagination
                    loading={watchingState.isFetchLoading}
                    currentPage={contentPaginationState.currentPage}
                    hasNextPage={contentPaginationState.hasNextPage}
                    totalPages={contentPaginationState.totalPages}
                    hasPreviousPage={contentPaginationState.hasPreviousPage}
                    goToNextPage={goToNextPage}
                    goToPrevPage={goToPrevPage}
                />
            </div>
        </LayoutComponentForHelmet>
    )
}

export default WatchingList;