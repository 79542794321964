import { useEffect, useState } from "react";

function UpShotCreationActionButtons({ currentVisitiableTabs, activeTabState, setActiveTabState, upShotDataState, setUpShotDataState, upShotVisibilityState, createUpShotFinal, upshotContentState, isUpShotCreateButtonDisabled }) {
    const [isPostButtonVisible, setPostButtonVisible] = useState(false);

    useEffect(() => {
        // ? Validating if user typed title and visibility, if true then enable save button else disable.
        if (upShotDataState.upShotTitleText && upShotVisibilityState.selectedVisibilityType && (upshotContentState.selectedContentCeleb.length !== 0 || upshotContentState.selectedContentMovieTv.length !== 0)) {
            setPostButtonVisible(true)
        } else {
            setPostButtonVisible(false);
        }
    }, [upShotDataState, upShotVisibilityState, upshotContentState])

    const goToNextSectionIfCurrentIsVerified = () => {
        if (activeTabState === "title") {
            if (upShotDataState.upShotTitleText) {
                setActiveTabState("content")
            } else {
                setUpShotDataState(prevState => ({
                    ...prevState,
                    titleFirstSectionErrorSeen: true,
                    titleFirstSectionError: "Title is missing",
                }))
            }
        } else if (activeTabState === "content") {
            setActiveTabState("attachments")
        } else if (activeTabState === "attachments") {
            setActiveTabState("visibility")
        }
    }

    // User can only skip content, attachments.
    const skipCurrentSectionWithoutAnyValidation = () => {
        if (activeTabState === "content") {
            setActiveTabState("attachments")
        } else if (activeTabState === "attachments") {
            setActiveTabState("visibility")
        }
    }

    return (
        <div className="ups-creation-action-buttons">
            {
                currentVisitiableTabs.map(({ tabHTMLName, skippable, hasNext }) =>
                    <>
                        {
                            activeTabState === tabHTMLName && skippable &&
                            <button type='button' key={tabHTMLName} onClick={skipCurrentSectionWithoutAnyValidation}>Skip</button>
                        }
                        {
                            activeTabState === tabHTMLName && hasNext &&
                            <button type='button' onClick={goToNextSectionIfCurrentIsVerified}>Next</button>
                        }
                    </>
                )
            }
            <button type='button' disabled={!isPostButtonVisible || isUpShotCreateButtonDisabled} onClick={() => createUpShotFinal(false)}>Save</button>
        </div>
    )
}

export default UpShotCreationActionButtons;