const apiUrls = {
    getUpShotDetails: (upshotId) => `/api/content/main/upshot-detail/${upshotId}`,

    getSingleHighlightRemark: (remarkId) => `/api/content/main/single-remark-fetch/${remarkId}/`,
    fetchRemarkOnScroll: (upshotId, limit, offset) => `/api/content/main/remark/create/${upshotId}/?limit=${limit}&offset=${offset}`,
    likeUnlikeRemark: (remarkId) => `/api/content/main/remark/${remarkId}/like/`,

    sendRemarkAward: (remarkId) => `/api/content/main/remark-award/${remarkId}/`,
    fetchAwardListData: (remarkId) => `/api/content/main/remark-award/${remarkId}/`,
    removeRemarkAward: (awardId) => `/api/content/main/remove-remark-award/${awardId}/`,

    remarkCommentActions: (remarkId) => `/api/content/main/c/u/d/g/remark-comment/${remarkId}/`,
    remarkCommentLikeDislike: (commentId) => `/api/content/main/remark-comment-like-dislike/${commentId}/`,
}

export default apiUrls;