import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import '../styles/adminDashboardUsers.css';
import { useSearchParams, createSearchParams, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { adminApiUrls } from "../api/api";

function UserBlockedUsers() {
    const [blockedUser, setBlockedUser] = useState([])
    const params = useParams();

    useEffect(() => {
        axios.get(`/dashboard/admin/user/mod/blocked-user/${params.userId}/${params.id}`).then((res) => {
            setBlockedUser(res.data.message[0].users);
        })
    }, [])

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th>Is actvie?</th>
                        <th>Is staff?</th>
                        <th>Email</th>
                        <th>Last login</th>
                        <th>Date joined</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        blockedUser?.map((res, index) =>
                            <>
                                <tr>
                                    <td>{res.id}</td>
                                    <td>{res.username}</td>
                                    <td>{res.is_active ? <>Yes</> : <>No</>}</td>
                                    <td>{res.is_staff ? <>Yes</> : <>No</>}</td>
                                    <td>{res.email}</td>
                                    <td>{res.last_login}</td>
                                    <td>{res.date_joined}</td>
                                </tr>
                            </>
                        )
                    }
                </tbody>
            </table>
        </>
    )
}

export default UserBlockedUsers;