export const makeVisibilitySavable = (v) => {
    if (v === "public") {
        return "P"
    } else if (v === "audience") {
        return "A"
    } else if (v === "custom") {
        return "C"
    } else {
        return "O"
    }
}

// Make put request after 15 seconds of user input
export const saveEditDraftWhenInputIsIdleFor = 15000;
// Make post request after 5 seconds of user input
export const saveDraftWhenInputIsIdleFor = 5000;