import React, { useContext, useState } from "react";
import axios from 'axios';
import {
    Link,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import "./upshot-list-v2.css";
import showToastError from "../../helpers/showToastError";
import ShareModel from "../../Share/Share";
import ReportModal from "../../Report/Report";
import SharingItemPopup from "../../modals/SharingContentModal";
import ThemeContext from "../../appearance/ThemeContext";
import { useModMessagePopup } from "../../../MainInterface/contexts/UserAdminMessage/UserAdminMessageProvider";
import { useBanModel } from "../../../MainInterface/contexts/UserBan/UserBanModalProvider";
import { GlobalLoginRequiredModalContext } from "../../../MainInterface/contexts/LoginRequired/LoginRequiredModal";
import UnlikeIcon from "../icons/svgComponents/UnLikeIconV2";
import LikeIcon from "../icons/svgComponents/LikeIconV2";
import ShareUpShotV2Icon from "../icons/svgComponents/ShareUpShotV2";
import UpShotRemarkIcon from "../icons/svgComponents/UpShotRemarkIconV2";
import UpShotActionsDropdown from "./UpShotActionDropdown";
import UpShotCarousel from "./UpShotCrousel";
import UserOnPremiumBadgeIcon from "../../Profile/icons/UserOnPremiumBadgeIcon"
import { defaultPlaceholderImageIfNotPresentAny } from "../../constants/constants";
import ShareForPublicOnly from "../../Share/ShareOpen";

let defaultShareURL = `/upshot`

function UpShotListDetailContainerBox({ res, index, remarkInUpShot, currentUserUsername, currentUserProfileImage }) {
    const { t } = useTranslation();
    const { openModal } = useContext(GlobalLoginRequiredModalContext);
    const { theme } = useContext(ThemeContext);
    const { openModMessageModal } = useModMessagePopup();
    const { openBanModel } = useBanModel();

    const [dropdownStates, setDropdownStates] = useState([]);
    const [likedUpShots, setLikedUpShots] = useState([]);
    // const [isQuickShareModalOpen, setQuickShareModalOpen] = useState(false);
    const [reportDataState, setReportDataState] = useState({
        isReportModalOpen: false,
        reportInfo: 0,
    });
    const [shareDataState, setShareDataState] = useState({
        isShareModalOpen: false,

        currentOpenedUpShotVisibility: "",
        isPubliShareModalOpen: false,
        isUserBanned: false,

        shareData: {},
        shareUrl: "",
    })
    const [shareData, setShareData] = useState({});
    const [openedRemarkContainers, setOpenedRemarkContainers] = useState([]);
    const [newRemarkPostState, setNewRemarkPostState] = useState({
        remarkText: "",
        fetchedRemark: {},
    });
    const [addedAsFavoriteUpShot, setAddedAsFavouriteUpShot] = useState([]);

    const [removedContent, setRemovedContent] = useState({});

    const handleModMessagePopup = (clickedId, clickedType, clickedContentUserId) => {
        openModMessageModal(clickedId, clickedType, clickedContentUserId);
    }

    const handleBanModelPopup = (userId) => {
        openBanModel(userId)
    }

    const addNewRemark = (upShotId) => {
        const dataToPost = {
            content: newRemarkPostState.remarkText,
            upshot: upShotId,
        }
        if (newRemarkPostState.remarkText) {
            axios.post(`/api/content/main/remark/create/${upShotId}/`, dataToPost,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            ).then((res) => {
                setNewRemarkPostState(prevState => ({
                    ...prevState,
                    fetchedRemark: { ...newRemarkPostState.fetchedRemark, ...res.data },
                    remarkText: "",
                }))
                showToastError("Remark successfully created", "success");
            }).catch(() => {
                showToastError("Something went wrong! Try again later!", "error")
            })
        }
    }

    const openShareModal = (shareData) => {
        const prepareUrlForShare = defaultShareURL + `/${shareData.id}/`;
        setShareData({
            shareUrl: prepareUrlForShare,
            pageType: "U",
            id: shareData.id,
        })
        setShareDataState(prevState => ({
            ...prevState,
            isShareModalOpen: true,
            currentOpenedUpShotVisibility: shareData.upshot_visibility,
            isUserBanned: shareData.is_current_user_banned,
        }))
    }

    const openCloseDropdown = (index) => {
        const newDropdownStates = [...dropdownStates];
        newDropdownStates[index] = !newDropdownStates[index];
        setDropdownStates(newDropdownStates);
    }

    // const openQuickShareModal = (res, index) => {
    //     openCloseDropdown(0, index);
    //     // setQuickShareData({ contentType: "UpShot", contentName: res.title, contentId: res.id })
    //     // setQuickShareModalOpen(true);
    // }

    const addMeInUserAudience = (receiverUser) => {
        const data = ({
            receiver_id: receiverUser,
        })
        axios.post(`/api/audience/audience-request/`, data).then((res) => {
            showToastError(res.data.message, "success");
        }).catch(() => {
            showToastError("Something went wrong! Try again later!", "error")
        })
    }

    const makeLikeAnimation = (upShotId) => {
        setLikedUpShots(prevLikedBlogs => {
            const updatedLikedBlogs = [...prevLikedBlogs];
            updatedLikedBlogs[upShotId] = !updatedLikedBlogs[upShotId];
            return updatedLikedBlogs;
        });
    }

    const likeUpShot = (upShotId) => {
        makeLikeAnimation(upShotId);
        axios.post(`/api/content/main/upshot/${upShotId}/like/`).then(() => {
        }).catch((err) => {
            makeLikeAnimation(upShotId);
            if (err.response.status === 401) {
                openModal();
            }
            if (err.response.status === 403) {
                showToastError("You're Banned. You cannot Like UpShot.", "error")
            }
        })
    }

    const openReportModal = (id, index) => {
        openCloseDropdown(index);
        // if (currentUserUsername) {
        setReportDataState(prevState => ({
            ...prevState,
            isReportModalOpen: true,
            reportInfo: id
        }));
        // } else {
        //     openModal();
        // }
    }

    const openRemarkAdder = (index) => {
        toggleContainer(index);
    }
    const toggleContainer = (index) => {
        const newContainerOpenStates = [...openedRemarkContainers];
        newContainerOpenStates[index] = !newContainerOpenStates[index];
        setOpenedRemarkContainers(newContainerOpenStates);
    };

    const addUpShotAsFavorite = (id) => {
        const upShot = [...addedAsFavoriteUpShot];
        upShot[id] = !upShot[id];
        setAddedAsFavouriteUpShot(upShot);
    }
    const saveUpShotAsFavourite = (data) => {
        // ! tmdb/imdb ID is not required in UpShot.
        const dataToPost = ({
            upshot_favourite: data.id,
            content_type: "UpShot",
            content_data_dict: { "title": data.title },
        });

        addUpShotAsFavorite(data.id);
        axios.post(`/api/users/primary/favourite-get-create-delete/`, dataToPost).then(() => {
        }).catch(() => {
            showToastError("Something went wrong! Try again later!", "error")
            addUpShotAsFavorite(data.id);
        })
    }

    const saveUserContentUnInterested = (onBasis, userId, ids, action) => {
        const dataToPost = ({
            on_basis: onBasis === "user" ? "user" : "content",
            content_attached_ids: ids,
            user_id: userId,
            action: action,
        });
        axios.put(`/api/user/t/handle/post-uninterested/`,
            {
                dataToPost: dataToPost
            }).then(() => {
            })
    }

    const removeContentFromView = (id, onBasis, data, action) => {
        setRemovedContent((prevState) => ({
            [id]: { isClicked: !prevState[id]?.isClicked, onBasis },
        }));

        const formattedDataContentId = data.tv_movie_data.map(({ id, contentType }) => ({ [contentType === "movies" ? "movie" : "tv"]: id }));
        saveUserContentUnInterested(onBasis, data.user, formattedDataContentId, action);
    }

    const handleDoubleClick = (upshotId) => {
        likeUpShot(upshotId);
    }
    return (
        <>
            {
                removedContent[res.id]?.isClicked ?
                    <div className="video-container">
                        <div className={`undo-feedback`}>
                            {
                                removedContent[res.id]?.onBasis === "content"
                                    ?
                                    <>
                                        <p>Content hidden. You won't see any posts related to it.</p>
                                        <button onClick={() => removeContentFromView(res.id, removedContent[res.id]?.onBasis, res, "undo")}>Undo</button>
                                    </>
                                    :
                                    <>
                                        <p>You won't see posts from this User anymore.</p>
                                        <button onClick={() => removeContentFromView(res.id, removedContent[res.id]?.onBasis, res, "undo")}>Undo</button>
                                    </>
                            }
                        </div>
                    </div>
                    :
                    <div className="upshot-single-detail">
                        <div className="usd-header">
                            {
                                res.user_profile_image ?
                                    <img className="usd-user-image"
                                        src={res.user_profile_image}
                                        alt="User Image" />
                                    :
                                    <img className="usd-user-image"
                                        src={defaultPlaceholderImageIfNotPresentAny}
                                        alt="User Image" />
                            }
                            <div className="usd-user-info">
                                <Link to={`/profile/${res.user_username}/`}><h2 className="usd-username">{res.profile_name} {res.show_premium_badge_on_profile ? <b className='premium-profile-badge'><UserOnPremiumBadgeIcon /></b> : null}</h2></Link>
                                <p className="usd-timestamp">{res.translated_time}</p> {res.tag_flair_color !== null ? <span className={`usd-tag flair-list-single ${res.tag_flair_color}`}>{res.tags_flair}</span> : null}
                            </div>
                            <span className="more-link" href="#" onClick={() => openCloseDropdown(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                    <path
                                        d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                                </svg>
                                <UpShotActionsDropdown dropdownStates={dropdownStates} index={index} res={res} t={t} currentUserUsername={currentUserUsername} openReportModal={openReportModal} removeContentFromView={removeContentFromView} addMeInUserAudience={addMeInUserAudience} handleBanModelPopup={handleBanModelPopup} handleModMessagePopup={handleModMessagePopup} addedAsFavoriteUpShot={addedAsFavoriteUpShot} saveUpShotAsFavourite={saveUpShotAsFavourite} />
                            </span>
                        </div>

                        <UpShotCarousel content={[res.title, res.tv_movie_data]} title={res.title} tv_movie_data={res.tv_movie_data} />

                        {
                            res.are_attachments_exists ?
                                <img className="usd-image"
                                    onDoubleClick={() => handleDoubleClick(res.id)}
                                    src="https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg?size=626&ext=jpg&ga=GA1.1.386372595.1697932800&semt=sph"
                                    alt="Blog Image" />
                                :
                                null
                        }
                        <div className={`upshot-footer ${openedRemarkContainers[index] ? 'quick-remark-creation-is-open' : ''}`}>
                            {res?.upshotlike?.some((like) => like.upshot === res.id) ? <>
                                {
                                    likedUpShots[res.id] ?
                                        <UnlikeIcon customclassnames="unlike-icon" onClick={() => likeUpShot(res.id)} />
                                        :
                                        <LikeIcon customclassnames="like-icon" onClick={() => likeUpShot(res?.id)} />
                                }
                            </>
                                :
                                <>
                                    {
                                        likedUpShots[res.id] ?
                                            <LikeIcon customclassnames="like-icon" onClick={() => likeUpShot(res?.id)} />
                                            :
                                            <UnlikeIcon customclassnames="unlike-icon" onClick={() => likeUpShot(res.id)} />
                                    }
                                </>}
                            <UpShotRemarkIcon customclassnames="remark-upshot-icon" onClick={() => openRemarkAdder(index)} />
                            <ShareUpShotV2Icon customclassnames="share-upshot-icon" onClick={() => openShareModal(res)} />
                        </div>
                        <div className={`quick-remark-creation-upshot-container ${openedRemarkContainers[index] ? '' : 'dsply-non'}`}>
                            <div className="input-field">
                                {/* <div className="input-img">
                                    <img src={currentUserProfileImage} alt="User Image" />
                                </div> */}
                                <input type="text" placeholder="Remark goes here....." className='upshot-remark-field' value={newRemarkPostState.remarkText} onInput={(e) => setNewRemarkPostState({ remarkText: e.target.value })} />
                                <div className="input-icons">
                                    <span>
                                        <svg className="input-field-icons" onClick={() => addNewRemark(res.id)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M120 896V256l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0V376v400Z" /></svg>
                                    </span>
                                </div>
                            </div>
                            <hr className="style-two" />
                            <div className="view-all-remarks-btn make-text-cursor"><Link to={`/upshot/${res.id}/`}>View all Remarks</Link></div>
                        </div>
                    </div>
            }

            <ReportModal
                isReportModalOpen={reportDataState.isReportModalOpen}
                setReportModalOpen={() => setReportDataState(prevState => ({ ...prevState, isReportModalOpen: !reportDataState.isReportModalOpen }))}
                reportInfo={reportDataState.reportInfo}
                reportTypeContent={"upshot"}
            />

            <ShareForPublicOnly
                isPubliShareModalOpen={shareDataState.isShareModalOpen}

                openShareMainModal={() => setShareDataState({ isShareModalOpen: true })}
                closeShareMainModal={() => setShareDataState({ isShareModalOpen: false })}

                shareData={shareData}
                pageType={"U"}

            // contentVisibility={shareDataState.currentOpenedUpShotVisibility}
            // isBanned={shareDataState.isUserBanned}
            />
        </>
    )
}

export default UpShotListDetailContainerBox;