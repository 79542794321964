
function PageNumberPagination({ loading, currentPage, hasNextPage, totalPages, hasPreviousPage, goToPrevPage, goToNextPage }) {

    return (
        <div className="pagination-stick-to-bottom">
            {
                !loading ? <>
                    <div className="trending-pagination:container">

                        {
                            hasPreviousPage ?
                                <>
                                    <div className="trending-pagination:number arrow" onClick={() => goToPrevPage(currentPage)}>
                                        <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-240H80v-480h80v480Zm320 0L240-480l240-240 56 56-143 144h487v80H393l144 144-57 56Z" /></svg>
                                        <span className="arrow:text">Previous</span>
                                    </div>
                                </>
                                :
                                null
                        }

                        {
                            currentPage > 1 &&
                            <>
                                <div className="trending-pagination:number">
                                    <span>{currentPage - 1}</span>
                                </div>
                            </>
                        }

                        <div className="trending-pagination:number trending-pagination:active">
                            {currentPage}
                        </div>

                        {
                            totalPages !== currentPage &&
                            <div className="pagination:number">
                                <span>{totalPages}</span>
                            </div>
                        }

                        {
                            hasNextPage ?
                                <>
                                    <div className="pagination:number arrow" onClick={() => goToNextPage(currentPage)}>
                                        <span className="arrow:text">Next</span>
                                        <svg className='svg-fill-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M800-240v-480h80v480h-80Zm-320 0-57-56 144-144H80v-80h487L424-664l56-56 240 240-240 240Z" /></svg>
                                    </div>
                                </>
                                :
                                null
                        }
                    </div></> : null
            }
        </div>
    )
}

export default PageNumberPagination;