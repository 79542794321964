import React from 'react';
import { useTranslation } from "react-i18next";

import { Button, Group, Modal, Text } from '@mantine/core';

function ConfirmationDialog({ isOpen, close, confirm, deleteState, confirmationPageFor }) {
    const { t } = useTranslation();

    return (
        <Modal opened={isOpen} onClose={close} radius="10px" size="md" title={confirmationPageFor} centered>
            <Text className='clr-white'>{t("Are you sure you want to proceed?")}</Text>
            <Group mt="xl" justify='right'>
                <Button onClick={close} variant='outline'>{t("Cancel")}</Button>
                <Button onClick={confirm} color="red" loading={deleteState?.isDeleteLoading}>
                    {t("Confirm")}
                </Button>
            </Group>
        </Modal>
    )
}

export default ConfirmationDialog;