import "./premiumAgreement.css";
import UserLegalReadables from "../UserLegalReadablesActions";
import LegalPolicyHeader from "../LegalPolicyHeader"
import LayoutComponentForHelmet from "../../../../MainInterface/SEO/LayoutComponentForHelmet";

function PremiumAgreement() {

    return (
        <LayoutComponentForHelmet pageTitle={"Premium Agreement - SpreeCine"}>
            <div className="premium-agreement">
                <LegalPolicyHeader includeAppName header={"Premium Agreement"} />
                <UserLegalReadables />

                <hr />

                <div className="pa-overview">
                    Our Premium Agreement is a comprehensive set of guidelines designed to enhance your experience as a premium user of SpreeCine.
                    It outlines the terms and conditions specific to premium services, including the scope of premium features, payment details, and billing cycles.
                    We prioritize your data privacy and security, and our agreement addresses how we handle your personal information within the premium tier.
                    In addition, we've established clear policies for refunds and cancellations, ensuring transparency and fairness in the event you choose to discontinue your premium subscription.
                    Our premium agreement also covers rules and expectations regarding content, conduct, and the termination of premium services.
                    It is our commitment to providing a secure, enjoyable, and user-friendly environment for our premium users.
                    We encourage you to read and understand these terms thoroughly, as they play a vital role in shaping your premium experience with us.
                </div>
                <h1>1. Payment Information</h1>

                <h2 className="pa-main-subheader">1.1 Payment Information</h2>
                <div className="pa-agreement-a">
                    You can provide your payment information, such as debit card or credit card details, through our Services to buy Premium Membership.
                    We work with third-party payment processor lemon squeezy(for now) (referred to as "Payment Partners") to handle the processing of your payment information.
                </div>

                <div className="pa-ul-heading">
                    By providing your Payment Information, you are consenting to:
                </div>
                <ul className="pa-ul">
                    <li>You are authorized to use your Payment Information.</li>
                    <li>Your Payment Information is accurate and valid.</li>
                    <li>You are responsible for making payments for the Paid Services, including any applicable taxes, fees, and costs, by the specified payment due date.</li>
                    <li>You authorize our Payment Partner to charge your Payment Information for the full amount of the purchase, including any applicable taxes, fees, and costs, when payment is due for the Paid Services.</li>
                    <li>We may retain and utilize your Payment Information, including any details provided to us by our Payment Partners, to deliver notices and disclosures pertaining to renewals, recurring charges, and modifications that impact your purchase.</li>
                </ul>

                <h2 className="pa-main-subheader">
                    1.2 Discounts, Gifts
                </h2>
                <div className="pa-agreement-a">
                    As of now, SpreeCine does not offer discounts or promotions on Paid Services.
                    We do not have any specific discount terms or trial period offers that would allow free usage of a subscription, followed by automatic conversion to a paid subscription upon trial expiration.
                    Therefore, you are not required to cancel a subscription to avoid incurring charges for a paid subscription.
                </div>

                <h2 className="pa-main-subheader">
                    1.3 Refund Policy
                </h2>
                <div className="pa-agreement-a">
                    Our Refund and Cancellation Policies are in place to provide transparency and fairness to users of our premium services. If you decide to cancel your premium subscription, you can do so at any time, and you will not be charged for any future billing cycles. However, we do not offer refunds for the remainder of the current billing cycle. To initiate a cancellation, simply follow the process outlined in your account settings. Please note that it is your responsibility to cancel your subscription before the next billing date to avoid further charges. In the event of any technical issues or discrepancies related to billing, we encourage you to contact our customer support team promptly, and we will work diligently to resolve the matter. We value your satisfaction and aim to ensure a hassle-free experience with our premium services.
                </div>

                <h2 className="pa-main-subheader">
                    1.4 Taxes and Fees
                </h2>
                <div className="pa-agreement-a">
                    SpreeCine will apply necessary taxes and fees when mandated by relevant regulations. However, it remains your responsibility to cover all applicable taxes and fees linked to your use of the Services, as well as your access to, purchase, and utilization of Paid Services. This includes transaction taxes, data plan fees, internet charges, and similar associated costs.
                </div>

                <h2 className="pa-main-subheader">
                    1.5 Changes to Fees
                </h2>
                <div className="pa-agreement-a">
                    We reserve the right to modify the price for Premium, including any adjustments related to auto-renewals.
                    However, we will provide you with prior notice of any price changes and specify the effective date.
                    If you continue to use Premium and receive its associated benefits after the effective date of the price change, it signifies your acceptance of the new pricing, in accordance with the extent allowed by law.
                    If you wish to decline the price alteration, you can do so by canceling your subscription at least 48 hours before the commencement of the upcoming subscription period.
                    You have the flexibility to cancel your Premium subscription at any time.
                    However, please be aware that your subscription will renew automatically on a monthly or yearly basis unless you cancel it at least 24 hours before the end of your current subscription period.
                    To cancel your subscription, you must follow the provided instructions. If you fail to cancel, you grant us authorization to utilize your Payment Information for charging you the renewal subscription fee, which includes any applicable taxes, on the date of your subscription's renewal.
                </div>

                <h2 className="pa-main-subheader">
                    2. Subscription
                </h2>
                <h2 className="pa-main-subheader">
                    2.1 SpreeCine Premium
                </h2>
                <div className="pa-agreement-a">
                    SpreeCine Premium is a subscription service that provides you with exclusive benefits on our platform, Learn more about SpreeCine Premium Here.
                    By purchasing Premium, you explicitly consent to recurring payments.
                    These payments will be automatically processed through the Payment Information you've provided, at the intervals you've chosen (monthly).
                    This recurring billing will continue until you decide to cancel the subscription either on your own or through SpreeCine's cancellation process.
                    You have the ability to oversee your Premium subscription and Payment Information by accessing your account's user settings or through your mobile app, especially if you acquired your Premium subscription through a third party. Please note that we automatically process your Payment Information on the calendar day that aligns with the start of your subscription.
                </div>

                <h2 className="pa-main-subheader">
                    3. Miscellaneous
                </h2>
                <div className="pa-agreement-a">
                    Our User Agreement is integrated into and considered a part of this Agreement. In situations where there is a conflict between the terms of the User Agreement and the terms of this Agreement, the terms of this Agreement take precedence in relation to your access to subscription purchase.
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default PremiumAgreement;