import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

import LoadingIfElseErrorSvg from "../../animations/LoadingIfElseErrorSvg";
import ResetPasswordSuccessComponent from "./ResetPasswordEmailConfirmation"
import LayoutComponentForHelmet from '../../../MainInterface/SEO/LayoutComponentForHelmet';
import showToastError from '../../helpers/showToastError';
import SpreeCineLoginSignupSVGLogo from '../../../MainInterface/assets/SpreeCineLoginSignupLogoSVG';
import { validateEmail } from "../LoginHelpers/helperFunctions"

function ResetPasswordEmailConfirmation() {
    const { t } = useTranslation();
    const [resetPasswordPostState, setResetPasswordPostState] = useState({
        emailInputFieldValue: "",
        emailInputFieldError: "",

        emailPostError: "",
        isEmailSendingLoading: false,
        success: false,

    });

    const onResetPasswordInputFieldChange = (e) => {
        setResetPasswordPostState(prevState => ({
            ...prevState,
            emailInputFieldValue: e
        }));
    };

    const onResetPasswordSendMailBtnClickValidate = () => {
        if (!resetPasswordPostState?.emailInputFieldValue?.trim()) {
            setResetPasswordPostState(prevState => ({
                ...prevState,
                emailInputFieldError: "Type your Email",
                emailPostError: "",
            }));
            return false;
        };
        if (!validateEmail(resetPasswordPostState?.emailInputFieldValue)) {
            setResetPasswordPostState((prevState) => ({
                ...prevState,
                emailInputFieldError: "Invalid Email",
                emailPostError: "",
            }));
            return false;
        }
        return true;
    }

    const onResetPasswordSendMailBtnClick = () => {
        if (onResetPasswordSendMailBtnClickValidate()) {
            setResetPasswordPostState(prevState => ({
                ...prevState,
                emailPostError: "",
                isEmailSendingLoading: true,
                success: false,
            }));
            const userData = {
                email: resetPasswordPostState.emailInputFieldValue
            };
            axios.post("/api/v1/users/reset_password/", userData).then((res) => {
                setResetPasswordPostState(prevState => ({
                    emailPostError: "",
                    isEmailSendingLoading: false,
                    success: true,
                }));
                // showToastError("If the email you provided present in our Database then you should receive email to reset your account password.", "info")
                showToastError("Mail sent to reset account password.", "info")
            }).catch(() => {
                setResetPasswordPostState(prevState => ({
                    ...prevState,
                    emailPostError: "Something went Wrong!",
                    isEmailSendingLoading: false,
                    emailPostError: "",
                    success: false,
                }))
            })
        }
    }

    if (resetPasswordPostState.success) {
        return <ResetPasswordSuccessComponent type={"PASSWORD_RESET_EMAIL_SENT_SUCCESS"} />
    }

    return (
        <LayoutComponentForHelmet pageTitle={"Reset Password - SpreeCine"}>
            <div className="user-gettin-main-container">
                <div className="user-getting-left">
                    <div className="left-container-data-login">
                        {/* <h1>{t("Picked from our Great content")}</h1>
                        <div className="user-getting-login-side-content">
                        </div> */}
                    </div>
                </div>
                <div className="user-getting-right">
                    <div className='login-form'>
                        <div className="login-container-v2">
                            <SpreeCineLoginSignupSVGLogo svgSizeWidth={"60%"} bgcolor={"#3e2d3f"} />
                            <div className="login-content">
                                <h1 className="welcome-text">{t("Reset Password")}</h1>
                                <input
                                    type="text"
                                    placeholder={t("Email")}
                                    className="user-login-signup-input-field"
                                    value={resetPasswordPostState.emailInputFieldValue}
                                    onChange={(e) => onResetPasswordInputFieldChange(e.target.value)}
                                />
                                {
                                    resetPasswordPostState.emailPostError ?
                                        <div className='err-message font-size-14px'>{resetPasswordPostState.emailPostError}</div>
                                        :
                                        null
                                }
                                {
                                    resetPasswordPostState.emailInputFieldError ?
                                        <div className='err-message font-size-14px'>{resetPasswordPostState.emailInputFieldError}</div>
                                        :
                                        null
                                }
                                <div className="login-button-container">
                                    <button type="submit" className="main-button" onClick={onResetPasswordSendMailBtnClick}>
                                        {t("Send mail")}
                                        <LoadingIfElseErrorSvg isLoading={resetPasswordPostState.isEmailSendingLoading} errorString={resetPasswordPostState.emailPostError} classNamesForLoading={"retry rotate"} classNamesForError={"svg-inline-top mg-left-5px fill-black"} loadingLogoBigOrSmall={"small"} retry={onResetPasswordSendMailBtnClick} />
                                    </button>
                                    <div className="secondary-buttons">
                                        <Link to={"/login/"}>
                                            <button type="button" className='main-button'>{t("Login")}</button>
                                        </Link>
                                        <Link to={"/signup/"}>
                                            <button type="button">{t("Signup")}</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default ResetPasswordEmailConfirmation;