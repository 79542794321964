
function chooseAppropriateHeaderLine(notificationType, contentDescription, url, recipient, participantUsername, participient, is_read) {
    if (notificationType === "ROU") {
        return (
            <span className={`notification-tails ${!is_read ? "clr-green" : ""}`}><span className='notification-tail-username'>{participantUsername}</span> <small>posted a new Remark on your UpShot</small></span>
        )
    } else if (notificationType === "CMTD") {
        return (
            <span className={`notification-tails ${!is_read ? "clr-green" : ""}`}><span className='notification-tail-username'>{participantUsername}</span> <small>commented on your Remark</small></span>
        )
    } else if (notificationType === "NUWTC") {
        return (
            <span className={`notification-tails ${!is_read ? "clr-green" : ""}`}>New UpShot was created on your Watching Movie/Tv</span>
        )
    } else if (notificationType === "MTNED") {
        return (
            <span className={`notification-tails ${!is_read ? "clr-green" : ""}`}><span className='notification-tail-username'>{participantUsername}</span> <small>mentioned you</small></span>
        )
    } else if (notificationType === "RPL") {
        return (
            <span className={`notification-tails ${!is_read ? "clr-green" : ""}`}><span className='notification-tail-username'>{participantUsername}</span> <small>replied to your comment</small></span>
        )
    } else if (notificationType === "ACMOD") {
        return (
            <span className={`notification-tails text-bold ${!is_read ? "clr-green" : ""}`}>Account Moderation Message</span>
        )
    } else if (notificationType === "GRT") {
        return (
            <span className={`notification-tails text-bold ${!is_read ? "clr-green" : ""}`}>You got a new Achievement</span>
        )
    } else if (notificationType === "ADRQ") {
        return (
            <span className={`notification-tails ${!is_read ? "clr-green" : ""}`}>{participantUsername} <small>wants to be in Your Audience</small></span>
        )
    } else if (notificationType === "WTSRD") {
        return (
            <span className={`notification-tails ${!is_read ? "clr-green" : ""}`}><span className='notification-tail-username'>{participantUsername}</span> <small>shared their Watch Later List with you</small></span>
        )
    } else if (notificationType === "TAG") {
        return (
            <span className={`notification-tails ${!is_read ? "clr-green" : ""}`}><span className='notification-tail-username'>{participantUsername}</span> <small>Tagged you in their Post</small></span>
        )
    } else if (notificationType === "EQ") {
        return (
            <span className={`notification-tails text-bold ${!is_read ? "clr-green" : ""}`}>New Everyday Question is Here!</span>
        )
    } else if (notificationType === "TIS") {
        return (
            <span className={`notification-tails text-bold ${!is_read ? "clr-green" : ""}`}>New Tournament is Started</span>
        )
    } else if (notificationType === "TBF") {
        return (
            <span className={`notification-tails text-bold ${!is_read ? "clr-green" : ""}`}>Tournament has Ended</span>
        )
    } else if (notificationType === "TW") {
        return (
            <span className={`notification-tails text-bold ${!is_read ? "clr-green" : ""}`}>You won a Tournament!</span>
        )
    } else if (notificationType === "AOR") {
        return (
            <span className={`notification-tails text-bold ${!is_read ? "clr-green" : ""}`}>Got an Award on Remark</span>
        )
    } else {
        return (
            <span className={`notification-tails ${!is_read ? "clr-green" : ""}`}>UnKnown Notification.</span>
        )
    }
}

export default chooseAppropriateHeaderLine;