function returnARandomPlaceholderText() {
    const paragraphs = [
        "Lights, camera, title! Enter your post title here",
        "The title is everything!",
        "What's the plot? Give your UpShot a title",
        "Enter your movie title here",
        "The Title of your Blockbuster",
        "In a World...",
        "The Hero's Journey Begins",
        "The Title that Stole the Show",
        "What's in a Name? A Movie title, That's What!",
        "Blockbuster Title here",
    ];

    const randomIndex = Math.floor(Math.random() * paragraphs.length);
    return paragraphs[randomIndex]
}

export default returnARandomPlaceholderText;