import { defaultBackendOrigin } from "../../constants/constants";

export const adminApiUrls = {
    adminDashboard: {
        dashboardHomeData: (key) => `/dashboard/admin/home/?key=${key}`,
    },
    adminAPIStatus: {
        verifyKeyStatus: (key) => `/dashboard/admin/api/?key=${key}`,
        getYtVideoFetchStatus: `/get-videos-news/?content_name=Raju Srivastav&int_to_extract=10`,
        getCelebDetailAPIStatus: `/get-celeb-details/?person_id=1000340`,
        getNewsStatus: `/get-content-news/?content_name=Raju Srivastav`,
    },
    adminDashboardLogin: {
        validateAdminOnLogin: `/validate/site/admin/`,
    },
    adminUsers: {
        getUsersList: (key, pageNumber) => `/dashboard/admin/users/?key=${key}&page=${pageNumber}`,
    },
    adminBannedUsers: {
        getBannedUsers: (key) => `/dashboard/action/ban/user/?key=${key}`,
    },
    adminContent: {
        fetchSectionContentUrl: (key, sectionType, pageNumber) => `/dashboard/admin/content/?key=${key}&sectionType=${sectionType}&page=${pageNumber}`,
        sendMessageUrl: (key) => `/dashboard/admin/message/user/?key=${key}`,
        banUserUrl: (key) => `/dashboard/admin/message/user/?key=${key}`,
        contentReportFetchUrl: (key, contentId, contentType) => `/dashboard/action/report/list/${contentId}/?key=${key}&contentType=${contentType}`,
        hideContentUrl: (key, contentId, contentType) => `/dashboard/action/hide/${contentId}/?key=${key}&contentType=${contentType}`,
        deleteContentUrl: (key, contentId, contentType) => `/dashboard/action/delete/content/${contentId}/?key=${key}&contentType=${contentType}`,
    },
    defaultAdminSiteOriginBackend: `${defaultBackendOrigin}/admin/`
}