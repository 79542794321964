import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";

import "./styles/baseUpShotCreation.css";
import "./styles/creationTitleStyles.css";
import showToastError from '../../helpers/showToastError';
import UpShotVisibility from './creationTabs/UpShotVisibility';
import UpShotContentSelection from './creationTabs/UpShotContentSelection';
import UpShotDraftsModal from './UpShotDraftsModal';
import BannedPage from '../../Preventation/BannedPage';
import UpShotCreationAttachmentStep from './creationTabs/UpShotCreationAttachments';
import UpShotCreationActionButtons from './UpShotCreationActionButtons';
import UpShotCreationDropdown from './UpShotCreationDropdown';
import UpShotCreationTitleFirstStep from './creationTabs/TitleDescriptionFirst';
import returnARandomPlaceholderText from '../helpers/returnARandomPlaceholderText';
import generateRandomGradientBGColor from '../helpers/generateRandomGradientBGColor';
import { currentVisitiableTabs } from './upshotCreationTabsVar';
import { makeVisibilitySavable } from '../helpers/constHelpers';
import LayoutComponentForHelmet from '../../../MainInterface/SEO/LayoutComponentForHelmet';
import { useNavigate } from 'react-router';

function UpShotCreation() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // ! V2 designs state - START
    const [activeTabState, setActiveTabState] = useState("title");
    const [countdown, setCountdown] = useState(4);
    const [isUpShotCreateButtonDisabled, setUpShotCreateButtonDisabled] = useState(false);

    const [upShotDataState, setUpShotDataState] = useState({
        upShotTitleText: "",
        upShotDescriptionText: "",
        upShotTitlePlaceHolderText: "",

        titleFirstSectionErrorSeen: false,
        titleFirstSectionError: "",
    });

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    // ! V2 designs state - END

    const [currentUpShotDraftedId, setCurrentUpShotDraftedId] = useState(0);
    const [upShotUpdated, setUpShotUpdated] = useState(false);

    // * Users Tagging Container State - START
    const [fetchTagUserState, setFetchTagUserState] = useState({
        fetchedTagUsers: [],
        selectedTagUsers: [],
        isFetchUsersLoading: false,
        fetchUsersError: null,
        isMoreUserOpen: false,
    });
    const [tagsFlairState, setTagsFlairState] = useState("");
    // * Users Tagging Container State - END

    const [upShotVisibilityState, setUpShotVisibilityState] = useState({
        selectedVisibilityType: "public",
        includedUsers: [],
        excludedUsers: [],
    });

    // * Media upload - START
    const [upShotMediaState, setUpShotMediaState] = useState({
        uploadedMedia: [],
        fetchedUploadedMedia: [],
    })
    // * Media upload - END

    // * Content Selection - START
    const [upshotContentState, setUpShotContentState] = useState({
        isUpShotSelectionOpen: false,
        contentSearchValue: "",
        currentContentSelected: "movies",
        selectedContentMovieTv: [],
        selectedContentCeleb: [],
    });
    // * Content Selection - END

    const [isDraftModalOpen, setDraftModalOpen] = useState(false);
    const [currentSelectedDraft, setCurrentSelectedDraft] = useState({});

    const [backgroundGradientState, setBackgroundGradientState] = useState({
        backgroundColor: "",
        isBackgroundThemeOn: "enable",
    })

    const [isBanned, setIsBanned] = useState(false);

    const onOffBackgroundTheme = (value) => {
        setBackgroundGradientState(prevState => ({
            ...prevState,
            backgroundColor: generateRandomGradientBGColor(),
            isBackgroundThemeOn: value
        }))
        localStorage.setItem("uct", value)
    }

    useEffect(() => {
        setBackgroundGradientState(prevState => ({
            ...prevState,
            backgroundColor: generateRandomGradientBGColor(),
        }))
        const storedString = localStorage.getItem('uct');
        if (storedString === null) {
            localStorage.setItem("uct", "enable");
            setBackgroundGradientState(prevState => ({
                ...prevState,
                isBackgroundThemeOn: "disable"
            }))
        } else {
            setBackgroundGradientState(prevState => ({
                ...prevState,
                isBackgroundThemeOn: storedString
            }))
        }

        // * Choose a random placeholder title on page load.
        setUpShotDataState(prevState => ({
            ...prevState,
            upShotTitlePlaceHolderText: returnARandomPlaceholderText(),
        }))
        checkIfUserIsBanned();
        setDraftModalOpen(true);
    }, [])

    const checkIfUserIsBanned = () => {
        axios.get("/api/content/main/check/user-ban/").then((res) => {
            if (res.data.r) {
                setIsBanned(true);
            } else {
                setIsBanned(false);
            }
        }).catch(() => {
            setIsBanned(false);
        });
    }

    useEffect(() => {
        if (Object.keys(currentSelectedDraft).length !== 0) {
            // ! Also check if title and description is not empty. If not then ask user if user
            // ! wants to override the current UpShot.
            setDraftModalOpen(false);

            setCurrentUpShotDraftedId(currentSelectedDraft.id);
            setUpShotDataState(prevState => ({
                ...prevState,
                upShotTitleText: currentSelectedDraft.title,
                upShotDescriptionText: currentSelectedDraft.description,
            }))
            setFetchTagUserState(prevState => ({
                ...prevState,
                selectedTagUsers: currentSelectedDraft.tagged_users
            }))
            setTagsFlairState(currentSelectedDraft.tags_flair)
            setUpShotContentState(prevState => ({
                ...prevState,
                selectedContentMovieTv: currentSelectedDraft.tv_movie_data,
            }))
            setUpShotContentState(prevState => ({
                ...prevState,
                selectedContentCeleb: currentSelectedDraft.celeb_data
            }))
        }
    }, [currentSelectedDraft]);

    const uploadFiles = (event) => {
        const file = event.target.files;
        if (!file.length) {
            return;
        }
        setUpShotMediaState(prevState => ({
            ...prevState,
            uploadedMedia: [...prevState.uploadedMedia, ...file]
        }))
    }

    function handleInputChangeInDBLevel(event) {
        if (event !== "blank") {
            const { name, value } = event.target;
            setUpShotDataState(prevState => ({
                ...prevState,
                [name]: value,
            }))
        }
    }

    function createUpShot(drafted) {
        setUpShotCreateButtonDisabled(true);
        if (upShotDataState.upShotTitleText === "") {
            showToastError(t("Cannot create post because title or description is missing"), "warning")
            return;
        }
        const fixedForDBFieldOfMovies = upshotContentState?.selectedContentMovieTv?.filter(obj => obj.contentType === "movies" && "shows").map((data) => {
            return {
                id: data.id,
                original_title: data.original_title ? data.original_title : data.original_name,
                poster_path: data.poster_path,
                genreIds: data.genre_ids,
                contentType: data.contentType === "movies" ? "movies" : "shows",
                release_date: data.release_date ? data.release_date : data.first_air_date,
                content_plot: data.overview,
                adult: data.adult,
            };
        });

        const fixedForDBFieldOfCelebs = upshotContentState?.selectedContentCeleb?.filter(obj => obj.contentType === "celebs").map((data) => {
            return {
                id: data.id,
                original_title: data.original_title ? data.original_title : data.original_name,
                profile_path: data.profile_path,
                contentType: "celebs",
                known_for: data.known_for,
                adult: data.adult,
            };
        });

        const headers = {
            "Accept": "application/json",
            "Content-Type": "multipart/form-data"
        }

        const usernames = fetchTagUserState.selectedTagUsers.map(item => item);
        const usernamesStringified = JSON.stringify(usernames)
        const fixedForDBFieldOfMoviesStringified = JSON.stringify(fixedForDBFieldOfMovies);
        const fixedForDBFieldOfCelebsStringified = JSON.stringify(fixedForDBFieldOfCelebs);
        const formData = new FormData();
        formData.append("title", upShotDataState.upShotTitleText)
        formData.append("description", upShotDataState.upShotDescriptionText)
        formData.append("tagged_users", usernamesStringified)
        formData.append("tv_movie_data", fixedForDBFieldOfMoviesStringified)
        formData.append("celeb_data", fixedForDBFieldOfCelebsStringified)
        formData.append("upshot_visibility", makeVisibilitySavable(upShotVisibilityState.selectedVisibilityType))
        formData.append("tags_flair", tagsFlairState)
        formData.append("is_drafted", drafted)

        upShotMediaState.uploadedMedia.forEach((file, index) => {
            formData.append(`image${index}`, file);
        });

        axios.post("/api/content/main/upshot/create/", formData, { headers: headers }).then((response) => {
            showToastError("UpShot created successfully!", "success")
            setTimeout(() => {
                navigate(`/upshot/${response.data.id}/`)
            }, 1000);
        }).catch(() => {
            setUpShotCreateButtonDisabled(false);
        })
    }

    // ? Finalized
    const openCloseDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    }

    const saveCurrentPostAsDraft = (drafted) => {
        if (upShotDataState.upShotTitleText !== "") {
            createUpShot(drafted);
            setUpShotUpdated(true);
        } else {
            showToastError("At least, Give a title to your UpShot", "warning");
        }
    }

    const discardUpShot = () => {
        setDraftModalOpen(false);

        setCurrentSelectedDraft({});
        setCurrentUpShotDraftedId(0);
        setUpShotDataState({
            upShotTitleText: "",
            upShotDescriptionText: "",
            upShotTitlePlaceHolderText: "",

            titleFirstSectionErrorSeen: false,
            titleFirstSectionError: "",
        });
        setFetchTagUserState({
            selectedTagUsers: []
        })
        setTagsFlairState("")
        setUpShotContentState(prevState => ({
            ...prevState,
            selectedContentMovieTv: [],
        }))
        setUpShotContentState(prevState => ({
            ...prevState,
            selectedContentCeleb: []
        }))
    }

    if (isBanned) {
        return <BannedPage whoIsAccessing={"current"} page={"upshotCreation"} />
    }

    return (
        <LayoutComponentForHelmet pageTitle={"UpShot Creation - SpreeCine"}>
            <div className={`creation-container-wrapper`} style={{ background: `${backgroundGradientState.isBackgroundThemeOn === "enable" ? backgroundGradientState.backgroundColor : "none"}` }}>
                <div id="modal" className="ups-creation-modal">
                    <div className="ups-creation-modal-content">
                        <div className="ups-creation-header">
                            <h2>Create UpShot</h2>
                            <svg onClick={openCloseDropdown} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" /></svg>
                            <UpShotCreationDropdown isDropdownOpen={isDropdownOpen} setDraftModalOpen={setDraftModalOpen} saveCurrentPostAsDraft={saveCurrentPostAsDraft} discardUpShot={discardUpShot} onOffBackgroundTheme={onOffBackgroundTheme} isBackgroundThemeOn={backgroundGradientState.isBackgroundThemeOn} currentUpShotDraftedId={currentUpShotDraftedId} />
                        </div>
                        <div className="ups-creation-tabs">
                            {
                                currentVisitiableTabs.map(({ tabHTMLName, tabNameToDisplay }) =>
                                    <span data-tab={tabHTMLName} onClick={() => setActiveTabState(tabHTMLName)} className={`${activeTabState === tabHTMLName ? "active" : ""}`}>{t(tabNameToDisplay)}</span>
                                )
                            }
                        </div>
                        <div id="tabContent" className="ups-creation-tab-content">
                            <UpShotCreationTitleFirstStep
                                t={t}
                                activeTabState={activeTabState}
                                upShotDataState={upShotDataState}
                                handleInputChangeInDBLevel={handleInputChangeInDBLevel}
                            />

                            <UpShotContentSelection
                                activeTabState={activeTabState}
                                upshotContentState={upshotContentState}
                                setUpShotContentState={setUpShotContentState}
                            />

                            <UpShotCreationAttachmentStep
                                t={t}
                                activeTabState={activeTabState}
                                fetchTagUserState={fetchTagUserState}
                                setFetchTagUserState={setFetchTagUserState}
                                setUpShotMediaState={setUpShotMediaState}
                                upShotMediaState={upShotMediaState}
                                uploadFiles={uploadFiles}
                                setTagsFlairState={setTagsFlairState}
                                tagsFlairState={tagsFlairState}
                            />

                            <UpShotVisibility
                                activeTabState={activeTabState}
                                upShotVisibilityState={upShotVisibilityState}
                                setUpShotVisibilityState={setUpShotVisibilityState}
                            />
                            {
                                countdown <= 3 &&
                                <div className='redirecting-in-message'>Redirecting in {countdown}</div>
                            }

                            <UpShotCreationActionButtons
                                currentVisitiableTabs={currentVisitiableTabs}
                                activeTabState={activeTabState}
                                setActiveTabState={setActiveTabState}
                                upShotDataState={upShotDataState}
                                setUpShotDataState={setUpShotDataState}
                                upShotVisibilityState={upShotVisibilityState}
                                createUpShotFinal={createUpShot}
                                upshotContentState={upshotContentState}
                                isUpShotCreateButtonDisabled={isUpShotCreateButtonDisabled}
                            />
                            {upShotUpdated && <div className='clr-green'>Draft Saved!</div>}
                        </div>
                    </div>
                </div>

                <UpShotDraftsModal
                    isDraftModalOpen={isDraftModalOpen}
                    setDraftModalOpen={setDraftModalOpen}
                    setCurrentSelectedDraft={setCurrentSelectedDraft}
                    upShotDataState={upShotDataState}
                />
            </div>
        </LayoutComponentForHelmet>
    )
}

//* :-: User types first letter then request will be sent to backend
//* :-: it will save as a draft UpShot and will return the id of newly
//* :-: created UpShot like 39, then I will set this id in the state.
//* :-: and whenever user tries to upload the media/text then it
//* :-: will save in it and When user click on post then the drafted
//* :-: boolean will turn to True and UpShot will be li*
//* :-: And When user returns to UpShot creation page then a model
//* :-: will be prompted to user stating all the drafts user have.
//* :-: and If user clicks on one of them then pointed draft id will be attahced.


export default UpShotCreation;