
function textTrimmer(text, maxLength) {
    if (text?.length <= maxLength) {
        return text
    }

    const trimmedText = text?.substring(0, maxLength) + "...";
    return trimmedText;
}

// ? More ES6 Implemented function
// const textTrimmer = (text, maxLength) =>
//   text.length <= maxLength ? text : `${text.substring(0, maxLength)}...`;


export default textTrimmer;