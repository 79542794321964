import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";
import "./styles/discussionRules.css";

function TournamentRules() {

    return (
        <LayoutComponentForHelmet pageTitle={`Storyline Tournament Rules - SpreeCine`}>
            <div className="body-space bg-color-tournament-rules">
                <div className="rules-container">
                    <div className="rules-main">
                        <h2 className="heading">Storyline Tournament Rules</h2>
                        <hr />
                        <ul className="rules-list">
                            <span className="rule-number">1. Stay on Topic</span>
                            <li className="rule-item">
                                <h3 className="rule-subheading"></h3>
                                <span className="rule-text">Keep discussions relevant to the channel or topic. Avoid derailing conversations with unrelated subjects.</span>
                            </li>
                            <span className="rule-number">2. No Spam or Self-Promotion</span>
                            <li className="rule-item">
                                <h3 className="rule-subheading"></h3>
                                <span className="rule-text">Simply put, we don't want annoying or repetitive messages or constant self-advertising. Let's keep the conversation interesting and respectful without flooding with promotions, spams and unnecessary stuff.</span>
                            </li>
                            <span className="rule-number">3. Mind Language and Tone</span>
                            <li className="rule-item">
                                <h3 className="rule-subheading"></h3>
                                <span className="rule-text">Use appropriate language and tone. Avoid offensive, abusive, or derogatory remarks that could offend or provoke others. Treat others with respect and kindness. Avoid offensive language, personal attacks, or discrimination based on race, gender, ethnicity, religion, or other factors.</span>
                            </li>
                            <span className="rule-number">4. Stay Calm and Constructive</span>
                            <li className="rule-item">
                                <h3 className="rule-subheading"></h3>
                                <span className="rule-text">Keep conversations productive by managing anger and maintaining a composed, respectful tone. React thoughtfully, not impulsively. Encourage a positive atmosphere for meaningful discussions.</span>
                            </li>
                            <span className="rule-number">5. Respect Privacy</span>
                            <li className="rule-item">
                                <h3 className="rule-subheading"></h3>
                                <span className="rule-text">Do not share personal information, including phone numbers, addresses, or any private details about yourself or others.</span>
                            </li>
                            <span className="rule-number">6. Have Fun and Share your Thoughts</span>
                            <li className="rule-item">
                                <h3 className="rule-subheading"></h3>
                                <span className="rule-text">Enjoy the discussion, think before send, and contribute positively to the community.</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div className="rules-container">
                <h2 className="heading">Storyline Tournament Rules</h2>
                <hr />
                <ul className="rules-list">
                    <span className="rule-number">1. Stay on Topic</span>
                    <li className="rule-item">
                        <h3 className="rule-subheading"></h3>
                        <span className="rule-text">Keep discussions relevant to the channel or topic. Avoid derailing conversations with unrelated subjects.</span>
                    </li>
                    <span className="rule-number">2. No Spam or Self-Promotion</span>
                    <li className="rule-item">
                        <h3 className="rule-subheading"></h3>
                        <span className="rule-text">Simply put, we don't want annoying or repetitive messages or constant self-advertising. Let's keep the conversation interesting and respectful without flooding with promotions, spams and unnecessary stuff.</span>
                    </li>
                    <span className="rule-number">3. Mind Language and Tone</span>
                    <li className="rule-item">
                        <h3 className="rule-subheading"></h3>
                        <span className="rule-text">Use appropriate language and tone. Avoid offensive, abusive, or derogatory remarks that could offend or provoke others. Treat others with respect and kindness. Avoid offensive language, personal attacks, or discrimination based on race, gender, ethnicity, religion, or other factors.</span>
                    </li>
                    <span className="rule-number">4. Stay Calm and Constructive</span>
                    <li className="rule-item">
                        <h3 className="rule-subheading"></h3>
                        <span className="rule-text">Keep conversations productive by managing anger and maintaining a composed, respectful tone. React thoughtfully, not impulsively. Encourage a positive atmosphere for meaningful discussions.</span>
                    </li>
                    <span className="rule-number">5. Respect Privacy</span>
                    <li className="rule-item">
                        <h3 className="rule-subheading"></h3>
                        <span className="rule-text">Do not share personal information, including phone numbers, addresses, or any private details about yourself or others.</span>
                    </li>
                    <span className="rule-number">6. Have Fun and Share your Thoughts</span>
                    <li className="rule-item">
                        <h3 className="rule-subheading"></h3>
                        <span className="rule-text">Enjoy the discussion, think before send, and contribute positively to the community.</span>
                    </li>
                </ul>
            </div> */}
            </div>
        </LayoutComponentForHelmet>
    )
}

export default TournamentRules;