import { useState } from "react";
import axios from "axios";

import "../styles/creationAttachment.css";
import Capitalize from "../../../helpers/Capitalize";
import textTrimmer from "../../../helpers/stringCutter"
import { defaultPlaceholderImageIfNotPresentAny } from "../../../constants/constants";
import { defaultBackendOrigin } from "../../../constants/constants";

function UpShotCreationAttachmentStep({ activeTabState, t, fetchTagUserState, setFetchTagUserState, setUpShotMediaState, upShotMediaState, uploadFiles, tagsFlairState, setTagsFlairState }) {
    const [isTagFieldOpen, setTagFieldOpen] = useState(false);

    const runFunctionOnTagFieldOpen = () => {
        searchUsersAndUpdateDropdown("");
        setTagFieldOpen(!isTagFieldOpen)
    }
    const runFunctionOnTagFieldClose = () => {
        setTagFieldOpen(!isTagFieldOpen)
        setFetchTagUserState(prevState => ({
            ...prevState,
            fetchedTagUsers: [],
        }))
    }

    const updateSelectedUsers = (username, userImage) => {
        // ! User added user in tagged list. Trigger update function.
        let userData = {}
        if (userImage !== "") {
            userData = { "username": username, "userImage": `${defaultBackendOrigin}/media/${userImage}` }
        } else {
            userData = { "username": username, "userImage": defaultPlaceholderImageIfNotPresentAny }
        }

        if (!fetchTagUserState.selectedTagUsers.some(user => user.username === userData.username)) {
            setFetchTagUserState(prevState => ({
                ...prevState,
                selectedTagUsers: [...prevState.selectedTagUsers, userData]
            }))
        }
    }

    const searchUsersAndUpdateDropdown = (e) => {
        setFetchTagUserState(prevState => ({
            ...prevState,
            isFetchUsersLoading: true,
            fetchUsersError: null
        }))
        axios.get(`/api/search/search-users/?username=${e}`).then((res) => {
            setFetchTagUserState(prevState => ({
                ...prevState,
                fetchedTagUsers: res.data,
                isFetchUsersLoading: false,
                fetchUsersError: null
            }))
        }).catch(() => {
            setFetchTagUserState(prevState => ({
                ...prevState,
                fetchUsersError: "Somethig went Wrong!",
                isFetchUsersLoading: false,
            }))
        })
    }
    const removeSelectedUsers = (username) => {
        // ! Found a Bug. Not updating the state (in update function) but removing the user from state.
        // ! User remove user from tagged list. Trigger update function.
        // handleInputChangeInDBLevel("blank");
        setFetchTagUserState(prevState => ({
            ...prevState,
            selectedTagUsers: prevState.selectedTagUsers.filter(item => item.username !== username)
        }))
    }

    const showRemainingTaggedUsers = () => {
        setFetchTagUserState(prevState => ({
            ...prevState,
            isMoreUserOpen: true,
        }))
    }

    // Media upload
    const deleteUpShotMedia = (mediaId) => {
        axios.delete(`/api/content/main/upshot-media/${mediaId}/`).then((res) => {
            setUpShotMediaState(prevState => ({
                ...prevState,
                fetchedUploadedMedia: prevState.fetchedUploadedMedia.filter(item => item.id !== mediaId),
            }));
        }).catch(() => {
        })
    };

    if (activeTabState !== "attachments") {
        return null;
    }

    return (
        <div className="image-upload-container">
            <div className="flair-container" onClick={(e) => setTagsFlairState(prevState => (e.target.getAttribute("data-value") ? e.target.getAttribute("data-value") : prevState))}>
                <div className={`flair flair-blue ${tagsFlairState === "HP" && "selected"}`} data-value="HP">{t("I Have A Plot")}</div>
                <div className={`flair flair-green ${tagsFlairState === "NP" && "selected"}`} data-value="NP">{t("I Need A Plot")}</div>
                <div className={`flair flair-purple ${tagsFlairState === "WHP" && "selected"}`} data-value="WHP">{t("What Happens Next")}</div>
                <div className={`flair flair-orange ${tagsFlairState === "WM" && "selected"}`} data-value="WM">{t("I Watched A Movie")}</div>
                <div className={`flair flair-purple ${tagsFlairState === "WWH" && "selected"}`} data-value="WWH">{t("What Would Happen")}</div>
                <div className={`flair flair-red ${tagsFlairState === "HIML" && "selected"}`} data-value="HIML">{t("Happened In My Life")}</div>
                <div className={`flair flair-red ${tagsFlairState === "IWB" && "selected"}`} data-value="IWB">{t("It Would Be Better")}</div>
            </div>
            <hr />
            {
                isTagFieldOpen ?
                    <>
                        <div className="input-group">
                            <input type="text" className="form-control tag-btn dark-theme-search-users placeholder-text-light-grey" placeholder={t("Search users to Tag")} onInput={(e) => searchUsersAndUpdateDropdown(e.target.value)}
                                aria-label="Search users to Tag" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <span className="input-group-text" onClick={runFunctionOnTagFieldClose}>{t("Save")}</span>
                            </div>
                        </div>
                        {
                            fetchTagUserState.isFetchUsersLoading ?
                                <div className="user-list-wrapper">
                                    Loading...
                                </div>
                                :
                                <>
                                    {
                                        fetchTagUserState.fetchUsersError ?
                                            <>
                                                {fetchTagUserState.fetchUsersError}
                                            </>
                                            :
                                            <>
                                                {
                                                    fetchTagUserState.fetchedTagUsers.length > 0 &&
                                                    <div className="user-list-wrapper">
                                                        <ul className="tag-users-list">
                                                            {
                                                                fetchTagUserState.fetchedTagUsers?.map((user, index) =>
                                                                    <li key={index} onClick={() => updateSelectedUsers(user.username[0], user.username[1])}>
                                                                        {/* <div className="avatar"> */}
                                                                        {/* <img src={`http://${defaultBackendOrigin}/media/${user.username[1]}`} className="avatar" alt="User Image" /> */}
                                                                        {/* </div> */}
                                                                        <a href="#">{user.username[0]}</a>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                            </>
                                    }
                                </>
                        }
                    </>
                    :
                    <button type='button' className="tag-users-btn" onClick={runFunctionOnTagFieldOpen}>{t("Tag users")}</button>
            }

            <div className="avatar-container">
                {fetchTagUserState.selectedTagUsers?.slice(0, 3).map((user, index) =>
                    <div className="avatar-wrapper" key={index}>
                        <div className="user-v2">
                            <img src={user.userImage} alt="User 1" />
                        </div>
                        <div className="username-v2" title={user.username}>{textTrimmer(user.username, 6)}</div>
                        <button type='button' className="cross-button" onClick={() => removeSelectedUsers(user.username)}>&times;</button>
                    </div>
                )}
                {
                    fetchTagUserState.selectedTagUsers.length > 3 &&
                    <div className="avatar-wrapper" onClick={showRemainingTaggedUsers}>
                        {
                            fetchTagUserState.isMoreUserOpen ?
                                <>
                                    {fetchTagUserState.selectedTagUsers?.slice(3).map((user, index) =>
                                        <div className="avatar-wrapper" key={index}>
                                            <div className="user-v2">
                                                <img src={user.userImage} alt="User 1" />
                                            </div>
                                            <div className="username-v2" title={user.username}>{textTrimmer(user.username, 6)}</div>
                                            <button type='button' className="cross-button" onClick={() => removeSelectedUsers(user.username)}>&times;</button>
                                        </div>
                                    )}
                                </>
                                :
                                <div className="numberCircle">+{fetchTagUserState.selectedTagUsers.length - 3}</div>
                        }
                    </div>
                }
            </div>

            <hr />

            <div className='media-upload-main-label'>{t("Upload Images/Videos")}</div>

            <div className='upload-container'>
                <div className="file-input-container">
                    <input type="file" id="file-input" multiple title="Choose Image/Video" onChange={uploadFiles} accept="image/*, video/*" />
                    <label htmlFor="file-input" className="file-input-label">{t("Upload Images/Videos")}</label>
                </div>
                <div className='display-upload-progress-container'>
                    <ul className='display-upload-progress'>
                        {
                            upShotMediaState.fetchedUploadedMedia?.map((res, index) =>
                                <li key={index}>
                                    <svg className='upload-svg-img' xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m9.55 18-5.7-5.7 1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4Z" /></svg>
                                    <a>{res.img_vid_field}</a>
                                    <span className='file-type'>{Capitalize(res.media_type)}</span>
                                    <span className='file-size'>{res.media_size}</span>
                                    <svg className='upload-svg-remove flt-rgt make-text-cursor' onClick={() => deleteUpShotMedia(res.id)} xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M7.625 20q-.7 0-1.162-.462Q6 19.075 6 18.375V6H5V5h4v-.775h6V5h4v1h-1v12.375q0 .7-.462 1.163-.463.462-1.163.462ZM9.8 17h1V8h-1Zm3.4 0h1V8h-1Z" /></svg>
                                </li>
                            )
                        }
                    </ul>
                </div>

                {/* <div className='progress-description'>
                    {
                        mediaUploadProgress !== 0 &&
                        <>
                            <span>{upShotMediaState.currentUploadingFileName}</span>
                            <div className="progress">
                                <div className="progress-bar" style={{ width: `${mediaUploadProgress}%` }}></div>
                            </div>
                            {mediaUploadProgress}% / {estimatedTimeRemaining}
                        </>
                    }
                </div> */}
            </div>

        </div>
    )
}

export default UpShotCreationAttachmentStep;