import { useEffect } from 'react';

const SessionStorageExpirationCheck = () => {
    useEffect(() => {
        const creationTime = parseInt(localStorage.getItem('aamtTmstmp'), 10);
        if (creationTime) {
            const currentTime = new Date().getTime();
            const timeElapsed = currentTime - creationTime;
            const fiveHoursInMilliseconds = 5 * 60 * 60 * 1000; // 5 hours in milliseconds

            if (timeElapsed >= fiveHoursInMilliseconds) {
                // Remove the item from sessionStorage
                sessionStorage.removeItem('aamt');
                // Optionally, you can also remove the creation time from localStorage
                localStorage.removeItem('aamtTmstmp');
            }
        }
    }, []);

    return null; // This component doesn't render anything
};

export default SessionStorageExpirationCheck;