import { useState } from 'react';
import './styles/adminDashboard.css';
import { useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router';
import { Link, useNavigate } from "react-router-dom";
import { adminApiUrls } from './api/api';

function AdminDashboardLogin() {
    const [formFieldState, setFormFieldState] = useState({
        username: "",
        permId: "",
        id: 0,
    })
    const navigate = useNavigate();
    const adminDashboardUrls = adminApiUrls.adminDashboardLogin;
    const adminLoginUrl = adminDashboardUrls.validateAdminOnLogin;

    const submitLoginData = () => {
        const { username, permId, id } = formFieldState

        const dataToPost = ({
            username: username,
            uuid: permId,
            t_id: id
        })

        axios.post(adminLoginUrl, dataToPost).then((res) => {
            // setShowRedirectButton(false);
            console.log(res);
            if (res.status === 200) {
                navigate(`/admin/dashboard/${res.data.id}`);
                console.log(res.data.id);
                sessionStorage.setItem('aamtTmstmp', new Date().getTime());
                sessionStorage.setItem("aamt", res.data.id)
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <div className="login-box">
                <h2>Login</h2>
                <form>
                    <div className="user-box">
                        <input type="text" name="" required="" value={formFieldState.username} onInput={(e) => setFormFieldState(prevState => ({ ...prevState, username: e.target.value }))} />
                        <label>Username</label>
                    </div>
                    <div className="user-box">
                        <input type="text" name="" required="" value={formFieldState.permId} onInput={(e) => setFormFieldState(prevState => ({ ...prevState, permId: e.target.value }))} />
                        <label>Perm-Id</label>
                    </div>
                    <div className="user-box">
                        <input type="number" name="" required="" value={formFieldState.id} onInput={(e) => setFormFieldState(prevState => ({ ...prevState, id: e.target.value }))} />
                        <label>Id</label>
                    </div>
                    <a href="#" onClick={submitLoginData}>
                        Submit
                    </a>
                </form>

                {/* {
                    showRedirectButton ?
                        <Link to={redirectButtonValue}>Go to Admin</Link>
                        :
                        null
                } */}
            </div>
        </>
    )
}

export default AdminDashboardLogin;