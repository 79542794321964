import { react } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";

import pop from "../helpers/popAnimation";
import "./modals.css";
import textTrimmer from "../helpers/stringCutter";
import showToastError from "../helpers/showToastError";

function WatchLaterSelectionList({ data, show, onHide, handleClick, mediaType }) {
    const { t } = useTranslation();
    const [watchLaterList, setWatchLaterList] = useState([]);
    const [isSetDefault, setDefault] = useState(false);
    const [currentSelectionId, setCurrentSelectionId] = useState(0);
    const [currentSelectionName, setCurrentSelectionName] = useState("");
    const [selectedWtForSave, setSelectedWtForSave] = useState(0);

    useEffect(() => {
        if (show) {
            axios.get("/check_if_authenticated/").then((res) => {
                if (res.data.is_default_status) {
                    setSelectedWtForSave(res.data.id);
                }
                axios
                    .get(`/api/content/management/watch-later/list/${res.data.message.username}/`, { params: { requestingUser: res.data.message.username } })
                    .then(response => {
                        const watchLaterList = response.data;
                        setWatchLaterList(watchLaterList);
                    })
                    .catch(() => {
                        showToastError("Something went Wrong while fetching data! Try again later!", "error");
                    });
            });
        }
    }, [show])

    const makeCurrentSelection = (id, name) => {
        setCurrentSelectionId(id);
        setCurrentSelectionName(name);
    }

    const savePrefrencesAndSendToDB = (e) => {
        const filteredContent = {
            poster_path: data.poster_path
        }
        const dataToPost = {
            action: !isSetDefault ? "WT_NOT_SELECTED" : "SELECTING_NOW",
            defaultWtId: currentSelectionId !== 0 ? currentSelectionId : 0,
            content_name: data.original_title ? data.original_title.replace(/'/g, '') : data.name.replace(/'/g, ''),
            imdb_id_or_tmdb_id: data.id,
            content_data: filteredContent,
            media_type: data.media_type
        }
        axios.post("/api/content/management/add_to_wt/", dataToPost).then((res) => {
            pop(e);
            onHide();
            handleClick(data.original_title ? data.original_title.replace(/'/g, '') : data.name.replace(/'/g, ''));
        }).catch(() => {
        })
    }

    return (
        <Modal
            show={show}
        >
            <div id="wt-selection-creation">
                <div className="wt-selection-modal">
                    <div className="wt-radio-container">
                        {watchLaterList.map((data) =>
                            <label className="wt-list-radio" key={data.id}>
                                <input type="radio" name="option" onChange={() => makeCurrentSelection(data.id, data.list_name)} />
                                <span className="radio-label">{textTrimmer(data.list_name, 8)}</span>
                            </label>
                        )}
                    </div>

                    <div className="separator"></div>
                    <br />
                    {
                        !currentSelectionName == "" &&
                        <div>
                            <div className="flt-lft">Set "<b>{currentSelectionName}</b>" as default list</div>
                            <label className="switch ndko">
                                <input type="checkbox" checked={isSetDefault} onChange={e => setDefault(e.target.checked)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    }
                    <br />
                    <br />
                    <hr />

                    <span className="flt-rgt">
                        <button type="button" className="cancel-button" onClick={onHide}>{t("Discard")}</button>
                        <button type="button" className="save-button" onClick={(e) => savePrefrencesAndSendToDB(e)}>
                            {t("Save")}
                        </button>
                    </span>
                </div>
            </div>
        </Modal>
    )
}

export default WatchLaterSelectionList;