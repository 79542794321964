import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import showToastError from '../helpers/showToastError';
import MaintenanceMode from '../Preventation/Maintenance/maintenance';
import textTrimmer from '../helpers/stringCutter';
import CorrectIcon from '../../svgComponents/correctIcon';
import WrongIcon from '../../svgComponents/wrongIcon';
import WTShareIcon from '../../svgComponents/WTShare';
import SearchUserModal from '../modals/SearchUserServiceModal';
import LoadingIfElseErrorSvg from "../animations/LoadingIfElseErrorSvg";
import SomethingWentWrong from '../helpers/SomethingWentWrongMessage';
import InfoPopup from '../modals/InfoPopup';
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';
import { GlobalLoginRequiredModalContext } from '../../MainInterface/contexts/LoginRequired/LoginRequiredModal';

const customInfoPopupSteps = [
    { "title": "Share your Watch Laters", "description": "You can share your Watch Later List with other Users." },
    { "title": "Private Watch Later", "description": "Keep in Mind that If your List is Private and when you share it with someone then it will automatically be Public." },
];

function WatchLaterDetail({ currentUserUsername }) {
    const { t } = useTranslation();
    const { listId } = useParams();
    const navigate = useNavigate();
    const { openModal } = useContext(GlobalLoginRequiredModalContext);
    const [isUsernameFetched, setIsUsernameFetched] = useState(false);

    const [isMaintenanceOn, setMaintenanceIsOn] = useState(false);
    const [wtLoading, setWtLoading] = useState(false);
    const [currentWtId, setCurrentWtId] = useState(0);
    const [isEditWTVisibilityDropdownOpen, setIsEditWTVisibilityDropdownOpen] = useState(false);
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);

    const [watchLaterState, setWatchLaterState] = useState({
        isWLLoading: false,
        wlError: "",

        fetchedWTItems: [],
    });
    const [isDeleted, setDeleted] = useState({});

    const [isCurrentDefault, setCurrentDefault] = useState(false);
    const [currentWtData, setCurrentWtData] = useState({})
    const [isEditBarOpen, setEditBarOpen] = useState(false);

    const [postState, setPostState] = useState({
        isPostLoading: false,
        postError: "",
    });
    const [filterType, setFilterType] = useState("newest");
    const [isWTShareModalOpen, setWTShareModalOpen] = useState(false);

    useEffect(() => {
        if (currentUserUsername) {
            fetchWtDetails();
            setIsUsernameFetched(true)
        }
    }, [currentUserUsername])

    const fetchWtDetails = () => {
        setMaintenanceIsOn(false);
        setWatchLaterState(prevState => ({
            isWLLoading: true,
            wlError: "",
        }))
        axios
            .get(`/api/content/management/watch-later/${listId}/${currentUserUsername}/?filterType=${filterType}`)
            .then(response => {
                console.log(response)
                setCurrentWtId(listId)
                setMaintenanceIsOn(false);
                const items = response.data.wts
                setWatchLaterState(prevState => ({
                    ...prevState,
                    fetchedWTItems: items,
                    isWLLoading: false,
                    wlError: "",
                }));
                setCurrentWtData(response.data.additional_data);
                setCurrentDefault(response?.data?.additional_data?.default);
            })
            .catch(() => {
                setWatchLaterState({
                    isWLLoading: false,
                    wlError: "Something went wrong",
                })
                // if (err.response.request.status) {
                //     setMaintenanceIsOn(true);
                // } else {
                //     setMaintenanceIsOn(false);
                // }
            })
    }

    const deleteWtContent = (e) => {
        axios.delete(`/api/content/management/delete_wt_rec/${e}/`).then((res) => {
            deleteFromState(e);
            showToastError("Successfully deleted", "success");
        }).catch(() => {
            showToastError("Something went wrong! while deleting", "error");
        })
    };

    const deleteFromState = recId => {
        setDeleted(state => ({
            ...state, [recId]: !state[recId]
        }));
        setWatchLaterState(prevState => ({
            ...prevState,
            fetchedWTItems: prevState.fetchedWTItems.filter((item) => item.id !== recId),
        }));
    }

    const makeThisDefault = (wtId) => {
        const dataToPost = ({
            id: wtId
        })
        axios.post("/api/content/management/make_list_default/", dataToPost).then(() => {
            setCurrentDefault(true);
        }).catch(() => {
            showToastError("error", "Something went wrong! Try again later")
        })
    }

    const makeThisUnDefault = () => {
        axios.put("/undefault_list/").then(() => {
            setCurrentDefault(false);
        }).catch(() => {
            showToastError("error", "Something went wrong! Try again later")
        })
    }

    const openCloseEditBar = () => {
        setEditBarOpen(!isEditBarOpen);
    }

    const saveEditBarContent = () => {
        setPostState({
            isPostLoading: true,
            postError: "",
        })
        const dataToPut = ({
            list_name: currentWtData.wt_main_title,
            watch_later_visibility: currentWtData.visibility,
            save_in_default: currentWtData.default,
        })
        axios.put(`/api/content/management/wt-list/edit/${currentWtId}/`, dataToPut).then((res) => {
            setPostState({
                isPostLoading: false,
                postError: "",
            })
            openCloseEditBar();
        }).catch(() => {
            setPostState({
                isPostLoading: false,
                postError: "Something went wrong",
            })
        })
    }

    const openUserShareModal = () => {
        setWTShareModalOpen(true);
    }

    // if (isMaintenanceOn) {
    //     return <MaintenanceMode />
    // }

    // if (watchLaterState.isWLLoading) {
    //     return <LoadingIfElseErrorSvg isLoading={watchLaterState.isWLLoading} errorString={watchLaterState.wlError} loadingLogoBigOrSmall={"big"} retry={fetchWtDetails} />
    // }

    if (watchLaterState.wlError !== "") {
        return <SomethingWentWrong retry={fetchWtDetails} />
    }

    if (!isUsernameFetched) {
        return <div>Loading...</div>;
    }

    return (
        <LayoutComponentForHelmet pageTitle={`Watch Later Items - SpreeCine`}>
            <header className="wt-detail-header">
                <div className="wt-header-content">
                    {
                        isEditBarOpen ?
                            <div className='edit-bar'>
                                <input type='text' className='edit-bar-input' value={currentWtData.wt_main_title} onInput={(e) => setCurrentWtData(prevState => ({ ...prevState, wt_main_title: e.target.value }))} />
                                <div className="wt-visib-choice-dropdown" onClick={() => setIsEditWTVisibilityDropdownOpen(!isEditWTVisibilityDropdownOpen)}>
                                    <div className={`wt-visib-selected-option ${isEditWTVisibilityDropdownOpen ? "br-5-5-0-0" : ''}`}>{currentWtData.visibility === "P" ? "Public" : <>{currentWtData.visibility === "R" ? "Private" : "Only my Audience"}</>}</div>
                                    <ul className={`wt-visib-options ${isEditWTVisibilityDropdownOpen ? "active" : ''}`} onClick={(e) => setCurrentWtData(prevState => ({ ...prevState, visibility: e.target.getAttribute("data-value") }))}>
                                        <li name="visibility" id="P" data-value="P" className={`${currentWtData.visibility === "P" ? "selected-wt-visib" : ""}`}>{t("Public")}</li>
                                        <li name="visibility" id="R" data-value="R" className={`${currentWtData.visibility === "R" ? "selected-wt-visib" : ""}`}>{t("Private")}</li>
                                        <li name="visibility" id="AO" data-value="AO" className={`${currentWtData.visibility === "AO" ? "selected-wt-visib" : ""}`}>{t("Only my Audience")}</li>
                                    </ul>
                                </div>
                                <b onClick={saveEditBarContent} className='save-button'>
                                    {t("Save")}
                                    <LoadingIfElseErrorSvg isLoading={postState.isPostLoading} errorString={postState.postError} classNamesForLoading="error-retry retry-rotate fill-black" classNamesForError="svg-vertical-align fill-red" />
                                </b>
                            </div>
                            :
                            <div className='wt-detail-actions'>
                                {isCurrentDefault ? <span onClick={makeThisUnDefault} className='make-text-cursor'><CorrectIcon /></span> : <span onClick={() => makeThisDefault(currentWtId)} className='make-text-cursor'><WrongIcon /></span>}
                                <span onClick={openCloseEditBar} className='make-text-cursor'><b>{t("Edit")}</b></span>
                                <span className='make-text-cursor eff'>{t("Delete")}</span>
                                <span onClick={openUserShareModal} className='make-text-cursor'><WTShareIcon /></span>
                                <span>{currentWtData?.timestamp}</span>
                            </div>
                    }
                </div>
                <div className="wt-filter-dropdown">
                    <div className="wt-visib-choice-dropdown">
                        <div className={`wt-visib-selected-option ${isSortDropdownOpen ? "br-5-5-0-0" : ''}`} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)}>{filterType === "newest" ? <>{t("Newest")}</> : <>{t("Oldest")}</>}</div>
                        <ul className={`wt-visib-options ${isSortDropdownOpen ? "active" : ''}`} onClick={(e) => setFilterType(e.target.getAttribute("data-value"))}>
                            <li name="sortBy" id="newest" data-value="newest" className={`${filterType === "newest" ? "selected-wt-visib" : undefined}`}>{t("Newest")}</li>
                            <li name="sortBy" id="oldest" data-value="oldest" className={`${filterType === "oldest" ? "selected-wt-visib" : undefined}`}>{t("Oldest")}</li>
                        </ul>
                    </div>
                </div>
            </header>
            {/* <LoadingIfElseErrorSvg isLoading={watchLaterState.isWLLoading} errorString={watchLaterState.wlError} loadingLogoBigOrSmall={"big"} retry={fetchWtDetails} /> */}
            {
                watchLaterState?.fetchedWTItems?.length === 0 ?
                    <div className='no-content-found'>
                        No content in this List.
                    </div>
                    :
                    null
            }
            <ul className="wt-list">
                {watchLaterState?.fetchedWTItems?.map((res) =>
                    <li className='wt-detail-item' key={res.id}>
                        <img className='wt-item-image' src={`https://image.tmdb.org/t/p/w300/${res.content_data.poster_path}`} alt="Image 1" />
                        <div className="text-overlay">
                            {
                                !isDeleted[res.id] &&
                                <div className='wt-detail-item-title'>
                                    <Link to={`/content/${res.media_type}/${res.content_name.replace(/\s+/g, "-")}/${res.imdb_id_or_tmdb_id}`}>{textTrimmer(res.content_name, 20)}</Link>
                                    <span className='wt-delete-btn' onClick={() => deleteWtContent(res.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
                                    </span>
                                </div>
                            }
                        </div>
                    </li>
                )}
            </ul>
            <SearchUserModal
                isOpen={isWTShareModalOpen}
                close={() => setWTShareModalOpen(false)}
                sharedItemType={"WT"}
                contentId={currentWtId}
                contentName={currentWtData?.wt_main_title}
                wtVisibility={currentWtData?.visibility}

                contentData={currentWtData}
            />

            <InfoPopup
                steps={customInfoPopupSteps}
                browserStorageInfoValidationKey="wtinfstat"
            />
        </LayoutComponentForHelmet>
    )
}

export default WatchLaterDetail;