import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./styles/loginSignup.css";
import showToastError from "../helpers/showToastError";
import { setAxiosAuthToken } from "./LoginHelpers/LoginActionFunctions";
import LoadingLogo from "../animations/LoadingLogo";

import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";
import SpreeCineLoginSignupSVGLogo from "../../MainInterface/assets/SpreeCineLoginSignupLogoSVG";
import LoadingIfElseErrorSvg from "../animations/LoadingIfElseErrorSvg";

function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [userLoginDataState, setUserLoginDataState] = useState({
    username: "",
    password: "",
  });
  const [loginState, setLoginState] = useState({
    isLoginLoading: false,
    loginFetchError: "",
  });
  const [
    redirectToContentAfterLoginState,
    setRedirectToContentAfterLoginState,
  ] = useState({
    redirectToContentAfterLogin: false,
    redirectContentType: "",
    redirectContentId: 0,
    redirectContentAdditionalId: 0,
    redirectionAfterLoginUrl: "",
  });
  const [loginFetchedContent, setLoginFetchedContent] = useState({
    fetchedContent: {},
    typeOfFetchedContent: "",
    isFetchLoading: false,
    fetchError: "",
  });
  const [dataContentContainer, setDataContentContainer] = useState(true);
  const containerToShow = dataContentContainer ? 0 : 1;

  const setToken = (token) => {
    setAxiosAuthToken(token);
    localStorage.setItem("token", token);
    dispatch({
      type: "SET_TOKEN",
      payload: token,
    });
  };

  const unsetCurrentUser = () => {
    setAxiosAuthToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({
      type: "UNSET_CURRENT_USER",
    });
  };

  const finalLogin = async (username, password) => {
    setLoginState({
      isLoginLoading: true,
      loginFetchError: "",
    });
    const userData = {
      username: username,
      password: password,
    };

    // ! login function starts here-------------
    axios
      .post("/api/v1/token/login/", userData)
      .then((response) => {
        setLoginState({
          isLoginLoading: false,
          loginFetchError: "",
        });
        const { auth_token } = response.data;
        setAxiosAuthToken(auth_token);
        setToken(auth_token);
        // ! login function ends here-------------

        // ! getCurrentUser function starts here-------------
        axios
          .get("/api/v1/users/me/")
          .then((response) => {
            const user = {
              username: response.data.username,
              email: response.data.email,
            };
            // ! setCurrentUser function starts here---------------
            localStorage.setItem("user", JSON.stringify(user));
            if (redirectToContentAfterLoginState.redirectToContentAfterLogin) {
              navigate(
                redirectToContentAfterLoginState.redirectionAfterLoginUrl,
                { state: { username: response.data.username } }
              );
            } else {
              navigate("/", { state: { username: response.data.username } });
              window.location.reload();
            }
            localStorage.setItem("iLI", true);

            // ! setCurrentUser function ends here---------------
          })
          .catch((error) => {
            unsetCurrentUser();
            if (error.response) {
              showToastError("Something went wrong!", "warning");
            }
          });
        // ! getCurrentUser function starts here-------------
      })
      .catch(() => {
        setLoginState({
          isLoginLoading: false,
          loginFetchError: "Something went Wrong",
        });
        unsetCurrentUser();
        showToastError(
          "Unable to log in with provided Email and Password.",
          "error"
        );
      });
  };

  const onLoginClick = useCallback(() => {
    if (
      userLoginDataState.username.length <= 0 ||
      userLoginDataState.password.length
    ) {
      finalLogin(userLoginDataState.username, userLoginDataState.password);
    }
  });

  const redirectAfterLoginOnOff = (
    redirectContentType,
    redirectContentId,
    redirectContentAdditionalId
  ) => {
    setRedirectToContentAfterLoginState((prevState) => ({
      ...prevState,
      redirectToContentAfterLogin: !prevState.redirectToContentAfterLogin,
      redirectContentType: redirectContentType,
      redirectContentId: redirectContentId,
      redirectContentAdditionalId: redirectContentAdditionalId,
    }));
    let baseUrl;
    if (redirectContentType === "upshot") {
      baseUrl = `/upshot/${redirectContentId}/`;
    } else {
      baseUrl = `/upshot/${redirectContentId}/?r=${redirectContentAdditionalId}`;
    }
    setRedirectToContentAfterLoginState((prevState) => ({
      ...prevState,
      redirectionAfterLoginUrl: baseUrl,
    }));
  };

  const shuffleLoginContent = () => {
    fetchRandomContent();
  };

  const fetchRandomContent = () => {
    setLoginFetchedContent({
      isFetchLoading: true,
      fetchError: "",
    });
    axios
      .get("/api/content/main/fetch/login/random/content/")
      .then((res) => {
        setLoginFetchedContent({
          isFetchLoading: false,
          fetchError: "",
          fetchedContent: res.data.message,
          typeOfFetchedContent: res.data.t,
        });
      })
      .catch(() => {
        setLoginFetchedContent({
          isFetchLoading: false,
          fetchError: "Something went Wrong",
        });
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(loginFetchedContent?.fetchedContent)?.length === 0) {
      if (!isMobile) {
        fetchRandomContent();
      }
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      fetchRandomContent();
    }
  }, [isMobile]);

  // useEffect(() => {
  //     window.gapi.load('client:auth2', () => {
  //         window.gapi.client.init({
  //             clientId: '314866282024-t34iokc7dguc53ue33bmmteo73q42r8r',
  //             plugin_name: "chat",
  //             scope: "email"
  //         })
  //     })

  // }, []);

  useEffect(() => {
    const toggleContainers = () => {
      setDataContentContainer((prevShowContainer1) => !prevShowContainer1);
    };

    toggleContainers();

    const intervalId = setInterval(toggleContainers, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <LayoutComponentForHelmet pageTitle={`Login - SpreeCine`}>
      <div className="user-gettin-main-container">
        <div className="user-getting-left">
          <div className="left-container-data-login">
            <h1>{t("Picked from our Great content")}</h1>
            <div className="user-getting-login-side-content">
              <div className="main-side-content">
                <div className="single-content-title">
                  {loginFetchedContent?.typeOfFetchedContent === "upshot"
                    ? "UpShot"
                    : "Remark"}
                  {loginFetchedContent.isFetchLoading ? (
                    <svg
                      onClick={shuffleLoginContent}
                      className={`${
                        loginFetchedContent.isFetchLoading ? "retry-rotate" : ""
                      } svg-reload-btn make-text-cursor`}
                      xmlns="http://www.w3.org/2000/svg"
                      height="17"
                      viewBox="0 -960 960 960"
                      width="17"
                    >
                      <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
                    </svg>
                  ) : (
                    <>
                      {loginFetchedContent.fetchError !== "" ? (
                        <svg
                          className="svg-vertical-align fill-red"
                          xmlns="http://www.w3.org/2000/svg"
                          height="15"
                          viewBox="0 -960 960 960"
                          width="15"
                        >
                          <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                        </svg>
                      ) : (
                        <svg
                          onClick={shuffleLoginContent}
                          className={`svg-reload-btn make-text-cursor`}
                          xmlns="http://www.w3.org/2000/svg"
                          height="17"
                          viewBox="0 -960 960 960"
                          width="17"
                        >
                          <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
                        </svg>
                      )}
                    </>
                  )}
                  <span className="flt-rgt">
                    {loginFetchedContent.isFetchLoading ? (
                      <svg
                        className="retry-rotate svg-reload-btn"
                        xmlns="http://www.w3.org/2000/svg"
                        height="17"
                        viewBox="0 -960 960 960"
                        width="17"
                      >
                        <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
                      </svg>
                    ) : (
                      <>
                        {loginFetchedContent.fetchError !== "" ? (
                          <svg
                            className="svg-vertical-align fill-red"
                            xmlns="http://www.w3.org/2000/svg"
                            height="15"
                            viewBox="0 -960 960 960"
                            width="15"
                          >
                            <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                          </svg>
                        ) : (
                          <>
                            <span>{t("Posted")} </span>
                            <span
                              className="single-content-time"
                              title={
                                loginFetchedContent?.fetchedContent
                                  ?.date_published
                                  ? loginFetchedContent?.fetchedContent
                                      ?.date_published
                                  : loginFetchedContent?.fetchedContent
                                      ?.remark_timestamp
                              }
                            >
                              {loginFetchedContent?.fetchedContent
                                ?.date_published
                                ? loginFetchedContent?.fetchedContent
                                    ?.natural_time
                                : loginFetchedContent?.fetchedContent
                                    ?.remark_timestamp}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </span>
                </div>
              </div>
              {loginFetchedContent?.typeOfFetchedContent === "upshot" ? (
                <>
                  <div className="content-data-title">
                    {loginFetchedContent?.fetchedContent?.title}
                  </div>
                  <hr />
                </>
              ) : (
                <>
                  <div className="content-data-title">
                    {loginFetchedContent?.fetchedContent?.upshot_title}
                  </div>
                  <hr />
                </>
              )}
              <div className="if-content-data">
                {loginFetchedContent?.typeOfFetchedContent === "upshot" ? (
                  <>
                    {loginFetchedContent?.fetchedContent?.length !== 0 && (
                      <>
                        {loginFetchedContent?.fetchedContent?.tv_movie_data?.map(
                          (content, index) => (
                            <div
                              className={`content-data-content-container ${
                                containerToShow === index
                                  ? "display-block"
                                  : "display-none"
                              }`}
                              key={content.id}
                            >
                              <div className="container-content">
                                <img
                                  className="content-data-content-image"
                                  src={`https://image.tmdb.org/t/p/w300${content.poster_path}`}
                                  alt=""
                                />
                                <p>{content?.original_title}</p>
                              </div>
                            </div>
                          )
                        )}
                      </>
                    )}
                  </>
                ) : null}
                <div className="content-data-description">
                  {loginFetchedContent.isFetchLoading ? (
                    <LoadingLogo
                      type="processing"
                      moveToLeft={"250px"}
                      moveToTop={"250px"}
                    />
                  ) : (
                    <>
                      {loginFetchedContent?.fetchedContent?.description
                        ? loginFetchedContent?.fetchedContent?.description
                        : loginFetchedContent?.fetchedContent?.content}
                    </>
                  )}
                </div>
              </div>
              <span
                className="take-button"
                onClick={() =>
                  redirectAfterLoginOnOff(
                    loginFetchedContent?.typeOfFetchedContent,
                    loginFetchedContent?.typeOfFetchedContent === "upshot"
                      ? loginFetchedContent?.fetchedContent?.id
                      : loginFetchedContent?.fetchedContent?.upshot,
                    loginFetchedContent?.typeOfFetchedContent === "remark" &&
                      loginFetchedContent?.fetchedContent.id
                  )
                }
              >
                {t("Take me there after Login")}
                {redirectToContentAfterLoginState?.redirectToContentAfterLogin ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="15"
                    viewBox="0 -960 960 960"
                    width="15"
                  >
                    <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                  </svg>
                ) : null}
              </span>
            </div>
          </div>
        </div>
        <div className="user-getting-right">
          <form action="#" method="POST" className="login-form">
            <div className="login-container-v2">
              <SpreeCineLoginSignupSVGLogo
                svgSizeWidth={"60%"}
                bgcolor={"#3e2d3f"}
              />
              {/* <h1 className='login-brand-name'>SpreeCine</h1> */}
              <div className="login-content">
                {/* <h1 className="welcome-text">{t("Log in")}</h1> */}
                <input
                  type="text"
                  placeholder={t("Username")}
                  className="user-login-signup-input-field"
                  value={userLoginDataState.username}
                  onChange={(e) =>
                    setUserLoginDataState((prevState) => ({
                      ...prevState,
                      username: e.target.value,
                    }))
                  }
                />
                <input
                  type="password"
                  placeholder={t("Password")}
                  className="user-login-signup-input-field"
                  value={userLoginDataState.password}
                  onChange={(e) =>
                    setUserLoginDataState((prevState) => ({
                      ...prevState,
                      password: e.target.value,
                    }))
                  }
                />
                <div className="login-button-container">
                  <button
                    type="button"
                    className="main-button"
                    disabled={
                      userLoginDataState.username.length <= 0 ||
                      userLoginDataState.password.length <= 0
                        ? true
                        : false
                    }
                    onClick={onLoginClick}
                  >
                    {t("Login")}
                    <LoadingIfElseErrorSvg
                      isLoading={loginState.isLoginLoading}
                      errorString={loginState.loginFetchError}
                      loadingLogoBigOrSmall={"small"}
                      classNamesForLoading="svg-inline rotate flt-rgt"
                      classNamesForError="svg-inline flt-rgt svg-fill-red"
                    />
                  </button>
                  <div className="signup-secondary-buttons">
                    <Link to={"/signup/"}>
                      <button type="button" className="main-button">
                        {t("Sign up")}
                      </button>
                    </Link>
                  </div>
                </div>

                {/* <GoogleLogin
                                    clientId="314866282024-t34iokc7dguc53ue33bmmteo73q42r8r"
                                    buttonText="LOGIN WITH GOOGLE"
                                    onSuccess={(response) => handleGoogleLogin(response)}
                                    onFailure={(e) => console.log(e)}
                                /> */}

                {/* <GoogleLogin
                                    clientId={"314866282024-t34iokc7dguc53ue33bmmteo73q42r8r"}
                                    buttonText="LOGIN WITH GOssOGLE"
                                    onSuccess={(response) => handleGoogleLogin(response)}
                                    render={(renderProps) => (
                                        <button
                                            onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}
                                            type="button"
                                            className="login-with-google-btn"
                                        >
                                            Sign in with Google
                                        </button>
                                    )}
                                    onFailure={(err) => console.log("Google Login failed", err)}
                                /> */}

                {/* <GoogleLogin
                                    onSuccess={credentialResponse => {
                                        console.log(credentialResponse);
                                        console.log(credentialResponse.credential);
                                        googleLogin(credentialResponse.credential)
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                /> */}
              </div>
            </div>
          </form>
          {/* <GoogleLogin
                        clientId="720611629213-qbbtl0i6ltvm5n87se858fsakrat3dml"
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                    /> */}
          {/* <button onClick={handleSignIn}>Sign in with Google</button> */}
          {/* <GoogleLogin clientId="720611629213-qbbtl0i6ltvm5n87se858fsakrat3dml.apps.googleusercontent.com" buttonText="LOGIN WITH GOOGLE" onSuccess={responseGoogle} onFailure={responseGoogle} /> */}
        </div>
      </div>
    </LayoutComponentForHelmet>
  );
}

// We are just playing and when he will call we'll have to leave and go.

// /**
//  *
//  * @param {*} accesstoken This is the accesstoken of the user obtained from Google
//  */
// const googleLogin = async (accesstoken) => {
//     // Decode the JWT to obtain the access token
//     // const jwtToken = response.credential;
//     // const decodedToken = JSON.parse(atob(jwtToken.split('.')[1]));
//     const decodedToken = JSON.parse(atob(accesstoken.split('.')[1]));

//     // const accessToken = decodedToken.access_token;

//     let res = await axios.post(`${defaultBackendOrigin}/accounts/google/login/`, {
//         access_token: accesstoken,
//     });
//     return res.status;
// };

export default LoginPage;
