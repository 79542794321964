import React from "react";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import axios from "axios";

import rootReducer from "./components/users/LoginHelpers/Reducer";
import { isEmpty } from "./components/users/LoginHelpers/Utils";
import { setAxiosAuthToken } from "./components/users/LoginHelpers/LoginActionFunctions";
import {
  defaultBackendOrigin,
  defaultOrigin,
} from "./components/constants/constants";
import "@mantine/core/styles.css";

if (window.location.origin === defaultOrigin) {
  axios.defaults.baseURL = defaultBackendOrigin;
} else {
  axios.defaults.baseURL = window.location.origin;
}

const setToken = (token) => (dispatch) => {
  setAxiosAuthToken(token);
  localStorage.setItem("token", token);
  dispatch({
    type: "SET_TOKEN",
    payload: token,
  });
};

const setCurrentUser = (user, redirectTo) => (dispatch) => {
  localStorage.setItem("user", JSON.stringify(user));
  dispatch({
    type: "SET_CURRENT_USER",
    payload: user,
  });
};

const Root = ({ children, initialState = {} }) => {
  const history = createBrowserHistory();
  const middleware = [thunk, routerMiddleware(history)];
  const store = createStore(
    rootReducer(history),
    initialState,
    applyMiddleware(...middleware)
  );

  if (!isEmpty(localStorage.getItem("token"))) {
    store.dispatch(setToken(localStorage.getItem("token")));
  }
  if (!isEmpty(localStorage.getItem("user"))) {
    const user = JSON.parse(localStorage.getItem("user"));
    store.dispatch(setCurrentUser(user, ""));
  }

  return <Provider store={store}>{children}</Provider>;
};

export default Root;
