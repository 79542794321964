import { Helmet } from 'react-helmet';

const LayoutComponentForHelmet = ({ pageTitle, children }) => {
    const currentUrl = window.location.href

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={`${pageTitle}`} />
                <link rel="canonical" href={currentUrl} />
            </Helmet>
            {children}
        </>
    )
}

export default LayoutComponentForHelmet;