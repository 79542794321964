import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";

import './audienceRequest.css';
import showToastError from "../helpers/showToastError";
import MaintenanceMode from '../Preventation/Maintenance/maintenance';
// import FlowerBranch from '../../svgComponents/FlowerBranch';
// import PopcornWithGlass from '../../svgComponents/PopcornWithGlass';
// import CongratulationCracker from "../../svgComponents/CongratulationsIcon";
// import LoadingLogo from "../animations/LoadingLogo";
import LoadingIfElseErrorSvg from '../animations/LoadingIfElseErrorSvg';
import LayoutComponentForHelmet from '../../MainInterface/SEO/LayoutComponentForHelmet';


function AudienceRequest() {
    const { t } = useTranslation();
    const [isMaintenanceOn, setMaintenanceIsOn] = useState(false);
    const [audienceFetchState, setAudienceState] = useState({
        audienceRequests: [],

        requestFetchLoading: false,
        requestFetchError: "",
    })
    const [isRequestFocused, setRequestFocused] = useState(true);
    const [focusedRequestId, setFocusedRequestId] = useState(0);
    const [filterSelected, setFilterSelected] = useState("all");
    const [requestActionState, setRequestAction] = useState({
        currentActionRequestId: 0,
        currentActionRequestIdAction: "",

        isActionLoading: false,
        actionError: "",
    })

    const updateFilterSelection = (e) => {
        setFilterSelected(e)
    }

    // ? Remove the CSS class after 5 seconds if their is any hash.
    useEffect(() => {
        const hash = window.location.hash.substring(1);
        const integerValue = parseInt(hash, 10);
        setFocusedRequestId(integerValue);
        if (hash) {
            const timer = setTimeout(() => {
                setRequestFocused(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, []);

    useEffect(() => {
        loadAudienceRequests();
    }, [])

    const loadAudienceRequests = () => {
        setMaintenanceIsOn(false);
        setAudienceState(prevState => ({
            ...prevState,
            requestFetchLoading: true,
            requestFetchError: ""
        }))
        axios.get("/api/audience/audience-request/").then((res) => {
            setMaintenanceIsOn(false);
            setAudienceState(prevState => ({
                ...prevState,
                audienceRequests: res.data,
                requestFetchLoading: false,
                requestFetchError: ""
            }));
            markAllRequestsAsRead();
        }).catch((err) => {
            setAudienceState(prevState => ({
                ...prevState,
                requestFetchLoading: false,
                requestFetchError: "Something went wrong!"
            }));
            if (err.response.request.status) {
                setMaintenanceIsOn(true);
            } else {
                setMaintenanceIsOn(false);
            }
        })
    }

    const acceptRejectAction = (reqId, action) => {
        const data = {
            action: action
        }
        setRequestAction({
            isActionLoading: true,
            actionError: "",
            currentActionRequestId: reqId,
            currentActionRequestIdAction: action,
        });
        axios.delete(`/api/audience/audience-accept-reject/${reqId}/`, { data: data }).then((res) => {
            setAudienceState(prevState => ({
                ...prevState,
                audienceRequests: prevState.audienceRequests.filter(item => item.id !== reqId),
            }))
            if (action === "ACCEPT") {
                showToastError("Successfully added to the Audience", "success")
            } else {
                showToastError("Rejected the user", "success")
            }
            setRequestAction({
                isActionLoading: false,
                actionError: "",
                currentActionRequestId: 0,
                currentActionRequestIdAction: "",
            })
        }).catch(() => {
            setRequestAction({
                isActionLoading: false,
                actionError: "Something went wrong!",
                currentActionRequestIdAction: "",
                currentActionRequestId: 0,
            });
            showToastError("Something went wrong!", "error");
        })
    }

    const markAllRequestsAsRead = () => {
        axios.put("/api/audience/mark-all-requests-as-read/").then(() => {
        }).catch(() => {
        })
    }

    const filteredData = filterSelected === "all" ? audienceFetchState?.audienceRequests : audienceFetchState?.audienceRequests?.filter(req => req.sent_or_received === filterSelected)
    if (isMaintenanceOn) {
        return <MaintenanceMode />
    }

    if (audienceFetchState.requestFetchLoading) {
        return <LoadingIfElseErrorSvg isLoading={audienceFetchState.requestFetchLoading} errorString={audienceFetchState.requestFetchError} loadingLogoBigOrSmall={"big"} retry={loadAudienceRequests} />
    }

    return (
        <LayoutComponentForHelmet pageTitle={"Audience Requests - SpreeCine"}>
            <div className='audience-requests'>
                <div className="slide-buttons">
                    <div className="filter-slider-main">
                        <span
                            className={`filter__btn ${filterSelected === "all" && "filter-slider-active"}`}
                            role="button"
                            data-value="all"
                            onClick={(e) => updateFilterSelection(e.target.getAttribute("data-value"))}>
                            {t("All")}
                        </span>
                        <span
                            className={`filter__btn ${filterSelected === "sent" && "filter-slider-active"}`}
                            role="button"
                            data-value="sent"
                            onClick={(e) => updateFilterSelection(e.target.getAttribute("data-value"))}>
                            {t("Sent")}
                        </span>
                        <span
                            className={`filter__btn ${filterSelected === "received" && "filter-slider-active"}`}
                            role="button"
                            data-value="received"
                            onClick={(e) => updateFilterSelection(e.target.getAttribute("data-value"))}>
                            {t("Received")}
                        </span>
                    </div>
                </div>
                {/* {
                    filteredData?.length === 0 &&
                    <div className="main-nothing-found-container">
                        <div className="nothing-found-container">
                            <p>{t("No Audience Request Found")}</p>
                        </div>
                    </div>
                } */}

                <div className="request-cards">
                    {
                        filteredData?.map((res, index) =>
                            <div key={index} id={`request-${res.id}`} className={`audience-request-card card-disable-after-action ${!res.is_read && "unread-ar"} ${isRequestFocused && `${res.id === focusedRequestId ? `audience-request-card-url-user-focus` : ""}`}`}>
                                {
                                    res.isus ?
                                        <>
                                            <h3>{t("Sent to")}</h3> <b>{res.receiver}</b> <small className='req-timestamp flt-rgt' title={res.request_timestamp}>{res.natural_time}</small>
                                        </>
                                        :
                                        <>
                                            <h3>{t("Sent by")}</h3> <b>{res.sender}</b> <small className='req-timestamp flt-rgt' title={res.request_timestamp}>{res.natural_time}</small>
                                        </>
                                }

                                <hr className='style-two' />
                                <div className="audience-request-card-buttons">
                                    <span className="accept-button" onClick={() => acceptRejectAction(res.id, "ACCEPT")}>
                                        {t("Accept")}
                                        {
                                            requestActionState.currentActionRequestId === res.id &&
                                            <>
                                                {
                                                    requestActionState.currentActionRequestIdAction === "ACCEPT" &&
                                                    <LoadingIfElseErrorSvg isLoading={requestActionState.isActionLoading} errorString={requestActionState.actionError} classNamesForLoading={"error-retry retry-rotate fill-black svg-inline-v2"} classNamesForError={"svg-vertical-align fill-red"} loadingLogoBigOrSmall={"small"} />
                                                }
                                            </>
                                        }
                                    </span>
                                    <span className="reject-button" onClick={() => acceptRejectAction(res.id, "REJECT")}>
                                        {t("Reject")}
                                        {
                                            requestActionState.currentActionRequestId === res.id &&
                                            <>
                                                {
                                                    requestActionState.currentActionRequestIdAction === "REJECT" &&
                                                    <LoadingIfElseErrorSvg isLoading={requestActionState.isActionLoading} errorString={requestActionState.actionError} classNamesForLoading={"error-retry retry-rotate fill-black svg-inline-v2"} classNamesForError={"svg-vertical-align fill-red"} loadingLogoBigOrSmall={"small"} />
                                                }
                                            </>
                                        }
                                    </span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </LayoutComponentForHelmet>
    )
}

export default AudienceRequest;