import * as React from "react";
const AwardIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#FEF0AE"
      opacity={1}
      stroke="none"
      d=" M134.163330,13.995745  C133.086121,8.576447 136.570496,5.156219 139.309814,1.272629  C217.354233,1.000000 295.708466,1.000000 374.531342,1.000003  C375.921356,2.463272 377.099792,3.828295 377.705139,5.412327  C378.665863,7.926236 379.243683,10.586487 379.451813,13.592142  C297.334534,13.998219 215.748932,13.996982 134.163330,13.995745  z"
    />
    <path
      fill="#FEF0AE"
      opacity={1}
      stroke="none"
      d=" M13.945126,275.144806  C10.291628,265.847778 6.649674,256.546143 2.961493,247.262894  C2.683113,246.562180 2.075599,245.992233 1.310540,245.680756  C1.000000,240.977905 1.000000,235.955811 1.000000,230.466858  C1.622400,229.870544 2.545417,229.960526 2.823434,229.579681  C10.668568,218.832565 14.849232,217.497864 27.441450,220.097458  C47.705246,224.280869 66.893463,231.249969 85.087418,241.032593  C87.203896,242.170593 89.203842,243.525330 91.732559,245.113525  C92.793800,245.977768 93.380302,246.508057 93.792267,247.341751  C91.994797,248.407669 90.431900,249.354843 88.739899,249.904846  C65.863945,257.340942 42.961842,264.696686 20.094044,272.157654  C17.947977,272.857819 15.990279,274.135345 13.945126,275.144806  z"
    />
    <path
      fill="#FEF0AE"
      opacity={1}
      stroke="none"
      d=" M513.000000,245.533142  C512.326721,246.618515 511.337860,247.109528 511.028534,247.875092  C507.620392,256.310211 504.317871,264.788025 500.548401,273.565979  C475.207367,265.888580 450.296356,257.924805 425.420837,249.851852  C423.805145,249.327499 422.461853,247.963867 421.115540,246.725281  C421.508850,245.941299 421.778503,245.422729 422.048187,244.904160  C434.073059,239.264557 445.774506,232.726807 458.216705,228.245987  C469.910492,224.034683 482.307495,221.597473 494.551666,219.169006  C500.628876,217.963654 505.838806,220.824219 509.134308,226.486847  C510.012268,227.995544 511.327942,229.249557 512.721313,230.310242  C513.000000,235.022095 513.000000,240.044189 513.000000,245.533142  z"
    />
    <path
      fill="#DE8B59"
      opacity={1}
      stroke="none"
      d=" M331.137970,495.070007  C318.504150,502.675690 304.604187,506.825134 290.356689,510.040344  C287.413605,510.704498 284.498871,511.494537 281.785522,512.613281  C265.645782,513.000000 249.291550,513.000000 232.468658,513.000000  C231.428116,512.344238 230.920471,511.188995 230.274948,511.105927  C215.159286,509.162231 201.022690,504.118683 187.214737,497.052246  C189.332153,495.573547 191.515152,494.152527 193.726944,494.106171  C205.520569,493.859070 217.477631,495.165314 229.085114,493.665771  C249.282776,491.056458 269.536377,495.124969 289.901947,490.816345  C301.695801,488.321228 314.816559,492.025146 327.326691,493.096680  C328.643799,493.209503 329.869720,494.386658 331.137970,495.070007  z"
    />
    <path
      fill="#FEE9A3"
      opacity={1}
      stroke="none"
      d=" M372.046936,80.883308  C379.698517,86.466316 377.608551,99.020462 371.539246,105.035408  C354.533875,121.888313 337.657074,138.870911 320.069916,155.870209  C315.936707,152.289734 312.463165,148.641434 308.994873,144.746704  C309.000153,144.500305 309.013000,144.007431 309.305664,143.850464  C316.053192,137.306335 322.609314,131.016190 328.887238,124.459625  C330.343811,122.938400 330.851501,120.508591 331.795776,118.496857  C329.869720,117.997902 327.955933,117.443909 326.014923,117.012276  C322.668610,116.268135 319.305359,115.600105 316.078979,114.594131  C322.792389,107.820129 329.376038,101.352158 336.072693,95.276665  C345.006744,98.259689 353.637268,98.542130 361.063354,92.194786  C365.041199,88.794769 368.405670,84.677116 372.046936,80.883308  z"
    />
    <path
      fill="#FBC66E"
      opacity={1}
      stroke="none"
      d=" M159.934616,39.086197  C155.605286,39.056938 151.275940,39.029076 146.946625,38.998077  C141.516037,38.959194 137.313660,36.905113 135.552063,31.358809  C216.999771,30.994986 297.917114,30.993803 378.834473,30.992620  C378.170624,35.925804 372.755585,38.869930 367.897095,38.978882  C363.594879,39.075359 359.288971,39.005646 354.151154,38.875526  C350.784760,38.603271 348.251923,38.464790 345.268433,38.300423  C314.179657,38.250950 283.541504,38.223858 252.903336,38.219627  C251.918442,38.219490 250.933533,38.453476 249.495178,38.481194  C243.712921,38.450211 238.384125,38.516361 232.656372,38.450012  C231.153519,38.405884 230.049622,38.494244 228.491776,38.484554  C208.723938,38.475292 189.410049,38.564079 169.832397,38.513626  C169.172043,38.354347 168.775436,38.334316 167.934662,38.291019  C164.971863,38.540565 162.453247,38.813385 159.934616,39.086197  z"
    />
    <path
      fill="#F2C07D"
      opacity={1}
      stroke="none"
      d=" M347.046661,220.840576  C351.445801,224.144501 355.844971,227.448425 360.821259,231.062927  C362.918701,232.917130 364.439026,234.460770 365.976074,236.251801  C365.992798,236.499191 366.010956,236.994812 365.649292,237.044342  C356.365112,241.141434 347.435760,245.174133 338.529205,249.256531  C337.488464,249.733582 336.062317,250.264984 335.683350,251.145142  C333.271576,256.746979 327.538116,258.170135 323.205872,261.316315  C322.069183,262.141785 320.002380,263.133545 319.137115,262.653381  C313.064301,259.283508 307.894653,262.236145 302.701935,264.661621  C297.061798,267.296082 291.561279,270.229462 285.708191,272.872375  C283.636017,271.463196 281.855591,270.214050 279.994080,268.638489  C277.921265,265.891815 275.929535,263.471558 274.245667,260.882355  C281.039307,257.468719 287.525024,254.224060 294.256531,250.982697  C294.502350,250.986023 294.993988,250.993210 295.323700,251.142609  C298.576508,252.437653 301.587158,253.404160 304.398773,254.777969  C307.370483,256.230042 309.932037,256.349060 312.698364,254.248184  C315.188995,252.356689 317.982483,250.863907 320.643127,249.196457  C323.383392,247.479080 323.945221,245.661835 321.103241,243.425644  C318.101624,241.063797 318.666595,239.126480 321.941193,237.514664  C329.042572,234.019226 336.057281,230.343521 343.212219,226.963333  C345.982025,225.654800 347.420532,223.919617 347.046661,220.840576  z"
    />
    <path
      fill="#B63234"
      opacity={1}
      stroke="none"
      d=" M160.171051,69.982292  C160.123047,68.911896 160.075043,67.841499 160.430191,66.434753  C173.761292,77.436775 186.610718,88.866364 199.642242,100.084389  C209.921127,108.932823 220.386765,117.566704 230.892273,126.145454  C231.825943,126.907890 233.667175,126.558960 235.374313,126.859299  C236.012482,127.224075 236.363220,127.455757 236.861221,127.965469  C240.056046,131.308319 242.998169,134.487610 246.173477,137.413818  C252.514511,143.257431 261.294769,143.706802 267.374969,137.719177  C285.731964,119.641655 303.780090,101.250557 322.253845,82.843979  C332.682861,72.726944 342.806641,62.747612 352.930420,52.768284  C353.270081,55.217800 353.609711,57.667309 353.559296,60.609615  C350.674255,63.007488 347.942017,64.671188 345.720459,66.854485  C319.714020,92.413048 293.764557,118.029694 267.843903,143.675293  C257.050934,154.353745 256.789337,154.756485 245.367157,144.802963  C223.597534,125.832535 202.247177,106.381622 180.651947,87.209923  C176.604172,83.616409 172.225815,80.395287 167.848145,76.715561  C166.128143,74.954613 164.560028,73.480423 162.853043,71.732437  C161.866470,70.966530 161.018768,70.474411 160.171051,69.982292  z"
    />
    <path
      fill="#FDE8A1"
      opacity={1}
      stroke="none"
      d=" M134.086548,14.407345  C215.748932,13.996982 297.334534,13.998219 379.378387,13.997576  C379.884216,15.386641 379.931793,16.777584 379.447876,18.582405  C297.333466,18.995224 215.750580,18.994162 134.167694,18.993101  C134.115051,17.601715 134.062408,16.210331 134.086548,14.407345  z"
    />
    <path
      fill="#E7ECEE"
      opacity={1}
      stroke="none"
      d=" M353.149902,52.423889  C342.806641,62.747612 332.682861,72.726944 321.881104,82.856598  C319.510406,83.651985 317.674896,84.060623 316.149139,84.982109  C308.629211,89.523743 301.300232,94.390854 293.686768,98.765297  C288.476318,101.759048 282.909912,104.133286 277.678009,106.487595  C285.963135,97.898323 294.039093,89.572502 302.194214,81.324974  C307.894043,75.560555 313.702179,69.903252 319.677917,63.973587  C321.663788,62.001823 323.433685,60.254589 325.464752,58.281311  C327.749207,56.018414 329.772430,53.981556 332.082825,51.806892  C333.472992,50.437920 334.576050,49.206753 335.861023,47.709259  C336.975220,46.610435 337.907440,45.777939 339.126831,44.813454  C340.263824,43.757736 341.113617,42.834003 342.244049,41.802700  C343.589478,40.572193 344.654266,39.449249 345.719055,38.326309  C348.251923,38.464790 350.784760,38.603271 353.688965,38.919106  C353.829987,43.424141 353.599670,47.751816 353.149902,52.423889  z"
    />
    <path
      fill="#A52E2D"
      opacity={1}
      stroke="none"
      d=" M168.000000,77.002327  C172.225815,80.395287 176.604172,83.616409 180.651947,87.209923  C202.247177,106.381622 223.597534,125.832535 245.367157,144.802963  C256.789337,154.756485 257.050934,154.353745 267.843903,143.675293  C293.764557,118.029694 319.714020,92.413048 345.720459,66.854485  C347.942017,64.671188 350.674255,63.007488 353.501587,61.042564  C353.868591,63.023090 353.903290,65.063469 353.532074,67.555130  C351.511719,69.076996 349.671448,69.918892 348.318665,71.254501  C337.921539,81.519234 327.677887,91.939438 317.273712,102.196907  C301.042023,118.199730 284.704102,134.094788 268.469940,150.095139  C267.106598,151.438858 266.187469,153.233307 264.606903,154.843872  C261.429260,154.965057 258.706696,155.060684 255.543381,155.099747  C252.734802,155.013321 250.366989,154.983475 247.952301,154.614761  C246.064667,152.166107 244.408844,149.852921 242.351212,147.981567  C233.267685,139.720352 223.938049,131.726776 214.942261,123.372772  C202.052643,111.402733 189.312592,99.269073 176.653366,87.055290  C173.485077,83.998489 170.869568,80.368752 168.000000,77.002327  z"
    />
    <path
      fill="#FDDF94"
      opacity={1}
      stroke="none"
      d=" M134.090393,19.388401  C215.750580,18.994162 297.333466,18.995224 379.374329,18.994659  C379.881317,20.062031 379.930298,21.131027 379.447937,22.597994  C297.333557,22.994690 215.750504,22.993418 134.167435,22.992144  C134.115982,21.922663 134.064545,20.853182 134.090393,19.388401  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M134.090683,23.387600  C215.750504,22.993418 297.333557,22.994690 379.374603,22.994022  C379.881470,24.061335 379.930389,25.130587 379.447937,26.597672  C297.333679,26.994192 215.750763,26.992878 134.167847,26.991566  C134.116531,25.922062 134.065231,24.852560 134.090683,23.387600  z"
    />
    <path
      fill="#FBCF7D"
      opacity={1}
      stroke="none"
      d=" M134.092102,27.348900  C215.750763,26.992878 297.333679,26.994192 379.374573,26.993740  C379.602325,28.105059 379.372131,29.218143 378.988190,30.661922  C297.917114,30.993803 216.999771,30.994986 135.624390,30.994320  C134.783051,29.897060 134.399704,28.801645 134.092102,27.348900  z"
    />
    <path
      fill="#FEF0AE"
      opacity={1}
      stroke="none"
      d=" M381.887085,207.961792  C388.149170,203.238449 393.978821,197.713989 400.802307,194.030731  C406.886108,190.746796 416.541901,195.916550 418.091858,202.723999  C419.916077,210.736069 420.728119,218.978592 421.492462,227.559143  C419.850555,228.102173 418.587799,227.940582 417.557404,228.355713  C410.961273,231.013107 405.272461,229.202164 400.031830,225.004959  C398.610107,223.866272 397.238129,222.656906 395.917664,221.401794  C391.223480,216.940033 386.561340,212.444519 381.887085,207.961792  z"
    />
    <path
      fill="#EFB777"
      opacity={1}
      stroke="none"
      d=" M346.877777,220.510315  C347.420532,223.919617 345.982025,225.654800 343.212219,226.963333  C336.057281,230.343521 329.042572,234.019226 321.941193,237.514664  C318.666595,239.126480 318.101624,241.063797 321.103241,243.425644  C323.945221,245.661835 323.383392,247.479080 320.643127,249.196457  C317.982483,250.863907 315.188995,252.356689 312.698364,254.248184  C309.932037,256.349060 307.370483,256.230042 304.398773,254.777969  C301.587158,253.404160 298.576508,252.437653 295.539062,250.883499  C299.588440,247.791595 303.833557,245.224380 307.887085,242.383713  C310.562653,240.508698 310.735260,238.406662 307.481659,236.762451  C304.990021,235.503296 302.491638,234.257446 300.156830,232.704681  C301.826080,231.138245 303.167572,229.552673 304.872162,228.662445  C312.512787,224.672302 320.338654,221.030212 327.902771,216.905151  C329.674500,215.938934 330.686432,213.579437 332.047913,211.860962  C336.934906,214.633987 341.821899,217.407028 346.877777,220.510315  z"
    />
    <path
      fill="#EAAD73"
      opacity={1}
      stroke="none"
      d=" M331.887421,211.539993  C330.686432,213.579437 329.674500,215.938934 327.902771,216.905151  C320.338654,221.030212 312.512787,224.672302 304.872162,228.662445  C303.167572,229.552673 301.826080,231.138245 299.773804,232.693146  C297.485992,232.656693 295.741791,232.330780 293.733093,231.746735  C289.645020,230.327393 285.821472,229.166199 282.048767,227.656219  C282.699860,226.563797 283.147980,225.536789 283.923828,225.120285  C291.759369,220.913910 299.784332,217.040192 307.458771,212.563324  C310.721436,210.660065 315.841919,210.344147 316.040588,204.860947  C321.269379,206.980301 326.498138,209.099655 331.887421,211.539993  z"
    />
    <path
      fill="#D68D5E"
      opacity={1}
      stroke="none"
      d=" M294.053528,182.154037  C294.357147,185.476562 294.660736,188.799072 294.568054,192.612183  C293.482605,193.538239 292.684326,193.864136 292.120422,194.425446  C281.688446,204.809601 271.266479,215.204056 260.907166,225.660477  C260.036713,226.539078 259.626495,227.873611 258.552246,229.001617  C255.401810,229.007614 252.701553,229.008331 249.693832,228.868103  C248.323242,228.152649 247.260147,227.578156 246.535736,227.186676  C250.210007,222.240982 253.619751,217.651352 257.450439,213.053223  C270.654510,212.628036 277.874023,204.391022 278.394287,189.973053  C283.501160,186.104675 288.260895,182.288727 293.454193,178.125214  C293.692352,179.726273 293.872925,180.940155 294.053528,182.154037  z"
    />
    <path
      fill="#902A25"
      opacity={1}
      stroke="none"
      d=" M265.061981,154.818344  C266.187469,153.233307 267.106598,151.438858 268.469940,150.095139  C284.704102,134.094788 301.042023,118.199730 317.273712,102.196907  C327.677887,91.939438 337.921539,81.519234 348.318665,71.254501  C349.671448,69.918892 351.511719,69.076996 353.477264,67.989212  C353.866425,69.362007 353.904449,70.751999 353.566040,72.619072  C351.667450,74.356323 350.033203,75.504066 348.640106,76.893585  C328.942047,96.541061 309.285797,116.230453 289.587006,135.877182  C287.948853,137.511002 285.925720,138.767700 284.341705,140.445328  C279.482635,145.591553 274.750244,150.857437 269.600037,156.066818  C267.801147,155.752762 266.370789,155.448685 264.980103,155.066498  C265.019806,154.988388 265.061981,154.818344 265.061981,154.818344  z"
    />
    <path
      fill="#E6A56E"
      opacity={1}
      stroke="none"
      d=" M315.870422,204.533569  C315.841919,210.344147 310.721436,210.660065 307.458771,212.563324  C299.784332,217.040192 291.759369,220.913910 283.923828,225.120285  C283.147980,225.536789 282.699860,226.563797 281.672974,227.655487  C280.494720,228.006134 279.743073,228.008759 278.612366,227.998718  C276.171387,227.341492 274.109528,226.696930 272.395020,225.990204  C282.101990,221.215500 288.877441,214.172318 292.463684,204.203110  C293.232086,202.067032 294.228760,200.013077 295.120270,197.921280  C301.980286,200.016251 308.840271,202.111221 315.870422,204.533569  z"
    />
    <path
      fill="#FEEDA9"
      opacity={1}
      stroke="none"
      d=" M371.908875,80.557312  C368.405670,84.677116 365.041199,88.794769 361.063354,92.194786  C353.637268,98.542130 345.006744,98.259689 336.199219,94.970726  C342.153259,88.464912 348.093781,82.657509 354.034302,76.850113  C360.406464,75.564262 366.637177,75.020332 371.908875,80.557312  z"
    />
    <path
      fill="#77291E"
      opacity={1}
      stroke="none"
      d=" M354.023376,76.461578  C348.093781,82.657509 342.153259,88.464912 336.086212,94.578247  C329.376038,101.352158 322.792389,107.820129 315.774719,114.736603  C309.547485,121.094765 303.754211,127.004417 297.666016,133.037766  C296.227875,134.405411 295.084656,135.649384 293.648071,137.019791  C292.212402,138.390076 291.070038,139.633926 289.636780,141.008209  C288.206451,142.382355 287.067078,143.626053 285.636719,145.010269  C284.181610,146.412155 283.017456,147.673523 281.565674,149.068726  C280.135803,150.446503 278.993530,151.690445 277.571838,153.071960  C276.181030,154.425903 275.069672,155.642288 273.590637,156.912109  C272.480774,157.000397 271.738556,157.035233 270.813599,156.915176  C270.358246,156.582657 270.137512,156.354828 269.968597,156.076782  C274.750244,150.857437 279.482635,145.591553 284.341705,140.445328  C285.925720,138.767700 287.948853,137.511002 289.587006,135.877182  C309.285797,116.230453 328.942047,96.541061 348.640106,76.893585  C350.033203,75.504066 351.667450,74.356323 353.517578,73.034233  C353.901215,74.005890 353.956818,75.039459 354.023376,76.461578  z"
    />
    <path
      fill="#CF805E"
      opacity={1}
      stroke="none"
      d=" M295.071777,197.511871  C294.228760,200.013077 293.232086,202.067032 292.463684,204.203110  C288.877441,214.172318 282.101990,221.215500 272.050568,226.129608  C267.239960,227.219543 263.121216,228.107925 259.002441,228.996323  C259.626495,227.873611 260.036713,226.539078 260.907166,225.660477  C271.266479,215.204056 281.688446,204.809601 292.120422,194.425446  C292.684326,193.864136 293.482605,193.538239 294.501801,193.046631  C294.895660,194.361145 294.959442,195.731812 295.071777,197.511871  z"
    />
    <path
      fill="#DF9A63"
      opacity={1}
      stroke="none"
      d=" M273.958313,156.858673  C275.069672,155.642288 276.181030,154.425903 277.880463,153.228912  C281.696808,156.091614 285.114197,158.751663 288.098175,161.831650  C290.872650,164.695419 293.289764,167.929764 295.660553,171.150879  C297.015411,172.991699 297.963623,175.131882 299.094910,177.137283  C299.094910,177.137283 299.071533,177.056335 298.870300,177.172852  C298.465363,177.581329 298.261719,177.873322 298.058044,178.165298  C298.058044,178.165298 298.048889,178.046417 297.863159,178.169754  C297.469299,178.585342 297.261169,178.877594 297.053040,179.169830  C296.245911,180.089737 295.438782,181.009659 294.342590,182.041809  C293.872925,180.940155 293.692352,179.726273 293.454193,178.125214  C288.260895,182.288727 283.501160,186.104675 278.379425,189.585968  C277.680328,187.831970 277.343231,186.412628 276.992493,184.596909  C274.542694,178.888763 277.560944,175.646896 281.124207,172.276779  C286.392365,167.294220 285.838715,165.162888 278.841797,161.703354  C277.102448,159.892227 275.530396,158.375443 273.958313,156.858673  z"
    />
    <path
      fill="#FDE8A1"
      opacity={1}
      stroke="none"
      d=" M381.598633,208.061188  C386.561340,212.444519 391.223480,216.940033 395.917664,221.401794  C397.238129,222.656906 398.610107,223.866272 400.031830,225.004959  C405.272461,229.202164 410.961273,231.013107 417.557404,228.355713  C418.587799,227.940582 419.850555,228.102173 421.419342,227.994873  C421.881042,231.688171 421.929291,235.383682 421.524414,239.535797  C420.253021,239.898041 419.353607,239.992737 418.628387,239.681473  C403.238037,233.075531 389.680573,223.927979 378.950500,210.892242  C379.737061,209.981689 380.523621,209.071136 381.598633,208.061188  z"
    />
    <path
      fill="#F0BA79"
      opacity={1}
      stroke="none"
      d=" M364.883301,226.964462  C365.670502,225.710373 366.457733,224.456284 367.908173,223.132812  C369.700806,223.732361 370.903931,224.302979 371.947968,225.085770  C380.359131,231.392288 388.701813,237.790726 397.146973,244.051041  C401.350616,247.167114 405.725281,250.052475 409.905609,253.286469  C409.505402,253.992172 409.222168,254.453629 408.620850,254.971649  C407.198944,255.679047 406.095123,256.329895 404.826965,256.688080  C400.344666,253.340500 395.962036,250.372375 391.722168,247.212692  C382.736420,240.516220 373.825134,233.719849 364.883301,226.964462  z"
    />
    <path
      fill="#EAAD73"
      opacity={1}
      stroke="none"
      d=" M364.587219,227.080536  C373.825134,233.719849 382.736420,240.516220 391.722168,247.212692  C395.962036,250.372375 400.344666,253.340500 404.634766,256.937134  C403.411560,258.348602 402.216187,259.218353 400.779510,260.063751  C400.538239,260.039398 400.053528,260.022369 399.749268,259.864441  C391.166473,253.517883 382.937897,247.260574 374.580566,241.180206  C371.880707,239.215942 368.842682,237.716476 365.959351,236.004395  C364.439026,234.460770 362.918701,232.917130 361.144348,231.161789  C362.023865,229.698929 363.157471,228.447784 364.587219,227.080536  z"
    />
    <path
      fill="#F8DA99"
      opacity={1}
      stroke="none"
      d=" M378.643066,211.013855  C389.680573,223.927979 403.238037,233.075531 418.628387,239.681473  C419.353607,239.992737 420.253021,239.898041 421.453339,239.991272  C421.892853,241.354370 421.950317,242.718597 422.027985,244.493500  C421.778503,245.422729 421.508850,245.941299 420.864624,246.724991  C420.322815,246.986755 420.155518,246.983398 419.820251,246.683197  C418.713287,245.729721 417.825836,244.979431 416.827606,244.430573  C403.074432,236.868622 388.788025,230.108704 378.224304,217.865997  C377.293671,216.787460 375.354706,216.578934 373.884094,215.966309  C375.367920,214.356018 376.851776,212.745743 378.643066,211.013855  z"
    />
    <path
      fill="#F0C284"
      opacity={1}
      stroke="none"
      d=" M410.022583,253.042206  C405.725281,250.052475 401.350616,247.167114 397.146973,244.051041  C388.701813,237.790726 380.359131,231.392288 371.947968,225.085770  C370.903931,224.302979 369.700806,223.732361 368.215027,223.009766  C368.655334,221.700577 369.451996,220.445023 370.906616,219.124359  C372.281158,219.456604 373.154785,219.705719 373.690948,220.273468  C382.369476,229.463623 393.079346,235.787903 403.883026,242.044846  C407.795746,244.310883 411.290833,247.297958 414.688538,250.116821  C412.941254,251.199142 411.481903,252.120682 410.022583,253.042206  z"
    />
    <path
      fill="#F4CF8F"
      opacity={1}
      stroke="none"
      d=" M414.976501,249.956024  C411.290833,247.297958 407.795746,244.310883 403.883026,242.044846  C393.079346,235.787903 382.369476,229.463623 373.690948,220.273468  C373.154785,219.705719 372.281158,219.456604 371.213928,219.006592  C371.674957,218.027679 372.486572,217.101425 373.591156,216.070740  C375.354706,216.578934 377.293671,216.787460 378.224304,217.865997  C388.788025,230.108704 403.074432,236.868622 416.827606,244.430573  C417.825836,244.979431 418.713287,245.729721 419.536560,246.839844  C417.939392,248.180893 416.457947,249.068451 414.976501,249.956024  z"
    />
    <path
      fill="#F6CA82"
      opacity={1}
      stroke="none"
      d=" M289.927704,140.877777  C291.070038,139.633926 292.212402,138.390076 293.806763,137.322906  C297.841980,140.999634 301.425232,144.499695 304.862183,148.278458  C304.324768,150.046844 303.107910,152.199982 303.681824,152.914322  C306.563202,156.500595 309.912781,159.710693 313.097839,163.052963  C311.958435,164.310364 310.819000,165.567749 309.113922,166.732834  C306.427948,164.323120 304.468750,161.826721 302.144989,159.735428  C299.477966,157.335190 297.940186,155.009109 301.158051,151.624344  C297.267853,147.901718 293.597778,144.389755 289.927704,140.877777  z"
    />
    <path
      fill="#E6A56E"
      opacity={1}
      stroke="none"
      d=" M299.375916,177.013763  C297.963623,175.131882 297.015411,172.991699 295.660553,171.150879  C293.289764,167.929764 290.872650,164.695419 288.098175,161.831650  C285.114197,158.751663 281.696808,156.091614 278.159912,153.091370  C278.993530,151.690445 280.135803,150.446503 281.885193,149.212372  C285.971924,152.406784 291.187378,155.027573 292.455017,158.930557  C293.789062,163.038010 295.828705,165.231064 298.804047,167.610550  C300.567688,169.020966 301.675018,171.252060 303.077423,173.114151  C303.077423,173.114151 303.053192,173.040054 302.855865,173.167206  C302.458344,173.583694 302.258148,173.873032 302.057953,174.162354  C301.257599,175.071655 300.457245,175.980942 299.375916,177.013763  z"
    />
    <path
      fill="#F0BA79"
      opacity={1}
      stroke="none"
      d=" M289.636780,141.008209  C293.597778,144.389755 297.267853,147.901718 301.158051,151.624344  C297.940186,155.009109 299.477966,157.335190 302.144989,159.735428  C304.468750,161.826721 306.427948,164.323120 308.813599,166.845795  C308.262238,167.999390 307.445526,168.947678 306.279541,169.659424  C303.017303,166.845291 300.034027,164.340714 297.233917,161.645859  C296.389252,160.832916 295.277679,158.937271 295.605225,158.479233  C298.503662,154.426132 295.340973,153.086716 292.819244,151.143188  C290.367004,149.253220 288.212799,146.976593 285.927704,144.869766  C287.067078,143.626053 288.206451,142.382355 289.636780,141.008209  z"
    />
    <path
      fill="#ECB273"
      opacity={1}
      stroke="none"
      d=" M285.636719,145.010269  C288.212799,146.976593 290.367004,149.253220 292.819244,151.143188  C295.340973,153.086716 298.503662,154.426132 295.605225,158.479233  C295.277679,158.937271 296.389252,160.832916 297.233917,161.645859  C300.034027,164.340714 303.017303,166.845291 305.984375,169.775146  C305.238342,171.039001 304.438202,171.950577 303.357727,172.988159  C301.675018,171.252060 300.567688,169.020966 298.804047,167.610550  C295.828705,165.231064 293.789062,163.038010 292.455017,158.930557  C291.187378,155.027573 285.971924,152.406784 282.172821,149.078552  C283.017456,147.673523 284.181610,146.412155 285.636719,145.010269  z"
    />
    <path
      fill="#F8DA99"
      opacity={1}
      stroke="none"
      d=" M313.387299,162.950684  C309.912781,159.710693 306.563202,156.500595 303.681824,152.914322  C303.107910,152.199982 304.324768,150.046844 305.111389,148.278412  C305.506897,147.999649 306.005493,147.997971 306.160828,148.292847  C309.559021,152.427246 312.801880,156.266769 316.044708,160.106308  C315.255402,161.020355 314.466095,161.934372 313.387299,162.950684  z"
    />
    <path
      fill="#FDE299"
      opacity={1}
      stroke="none"
      d=" M316.346313,159.984787  C312.801880,156.266769 309.559021,152.427246 306.300354,148.018250  C307.186218,146.630234 308.087921,145.811676 308.989624,144.993118  C312.463165,148.641434 315.936707,152.289734 319.759521,155.990158  C318.955170,157.315948 317.801514,158.589615 316.346313,159.984787  z"
    />
    <path
      fill="#E6A56E"
      opacity={1}
      stroke="none"
      d=" M297.312469,179.067841  C297.261169,178.877594 297.469299,178.585342 297.922729,178.171143  C297.969360,178.354736 297.770630,178.660294 297.312469,179.067841  z"
    />
    <path
      fill="#E6A56E"
      opacity={1}
      stroke="none"
      d=" M298.316376,178.064575  C298.261719,177.873322 298.465363,177.581329 298.912231,177.169037  C298.961853,177.353775 298.768280,177.658813 298.316376,178.064575  z"
    />
    <path
      fill="#ECB273"
      opacity={1}
      stroke="none"
      d=" M302.313660,174.060074  C302.258148,173.873032 302.458344,173.583694 302.894836,173.168091  C302.943878,173.347153 302.756592,173.652466 302.313660,174.060074  z"
    />
    <path
      fill="#FEEFAD"
      opacity={1}
      stroke="none"
      d=" M153.086884,230.937790  C172.369644,215.226807 193.841309,203.836548 218.800873,198.160217  C220.291809,200.259216 221.103745,202.188812 221.940948,204.469193  C222.329742,206.175369 222.693253,207.530777 222.901245,209.188980  C212.822739,219.502609 202.852386,229.467407 193.030609,239.576553  C191.642136,241.005661 190.977829,243.138336 189.765381,245.206085  C182.512192,250.992905 174.556107,255.692078 168.645523,262.238159  C159.691803,272.154572 152.161041,283.355804 143.659500,294.015259  C141.542511,295.165985 139.540482,296.058197 138.080765,297.499573  C126.583321,308.852661 115.225365,320.347015 103.718369,331.690247  C102.277092,333.111023 100.217293,333.904358 98.323044,334.595398  C98.534279,331.820038 98.866539,329.437653 99.417191,326.744690  C100.379341,322.628723 101.123085,318.823273 101.950104,314.631531  C103.044319,310.489716 104.055244,306.734253 105.244591,302.664001  C106.661186,298.902161 107.899353,295.455048 109.385117,291.777954  C111.747215,287.030090 113.861710,282.512238 116.238708,277.779297  C118.395546,274.144928 120.289894,270.725586 122.452042,267.091919  C131.228607,256.705902 139.728561,246.526855 148.254166,236.369308  C149.811508,234.513855 151.473297,232.746048 153.086884,230.937790  z"
    />
    <path
      fill="#F8F0EE"
      opacity={1}
      stroke="none"
      d=" M159.961639,39.543037  C162.453247,38.813385 164.971863,38.540565 168.081604,38.467094  C169.128769,39.063667 169.584854,39.460892 170.180878,40.133137  C170.890732,40.934704 171.460632,41.461250 172.213043,42.241982  C173.334610,43.309437 174.273666,44.122704 175.326263,45.206043  C176.445572,46.375439 177.451355,47.274765 178.581940,48.477203  C179.649872,49.592525 180.592987,50.404739 181.672089,51.490730  C182.700302,52.567200 183.592514,53.369896 184.615662,54.494095  C185.763947,55.686848 186.781296,56.558098 187.924652,57.688637  C188.633804,58.437290 189.216949,58.926651 189.938446,59.689892  C190.988541,60.776871 191.900253,61.589966 192.961029,62.689522  C201.611710,71.372910 210.113312,79.769836 218.779999,88.468323  C221.948486,91.917999 224.951935,95.066132 227.883850,98.533447  C224.100220,101.792038 220.388107,104.731461 216.383972,107.483810  C210.139160,101.501068 204.186371,95.705406 198.088684,89.601044  C186.561920,77.906380 175.198441,66.501862 163.765121,55.167778  C162.794800,54.205872 161.358185,53.714336 160.138184,53.004280  C160.088333,48.669479 160.038498,44.334675 159.961639,39.543037  z"
    />
    <path
      fill="#FDDF94"
      opacity={1}
      stroke="none"
      d=" M208.961349,171.126312  C200.021347,162.305115 191.081345,153.483902 182.378159,144.334442  C192.700775,140.337799 194.416351,134.330658 187.493912,126.978073  C186.930939,126.380119 186.490143,125.667107 186.415375,125.020485  C192.607254,125.350380 198.374298,125.767555 204.147415,125.884338  C205.095428,125.903511 206.070145,124.601921 207.340271,124.047203  C214.715622,131.333176 221.783234,138.482681 228.404236,145.813232  C224.015839,146.330078 220.078918,146.748840 216.131012,146.979752  C211.611877,147.244080 210.630569,148.540863 213.514542,151.960007  C217.991638,157.267944 223.134460,162.014343 227.877151,167.281509  C227.174805,168.378326 226.590378,169.192429 225.546082,170.004913  C221.214172,169.702713 217.340775,169.185318 213.470840,169.210190  C211.963638,169.219864 210.464279,170.451355 208.961349,171.126312  z"
    />
    <path
      fill="#FEF0AE"
      opacity={1}
      stroke="none"
      d=" M92.166908,227.992813  C93.157028,219.476440 93.454353,210.801956 95.350616,202.492371  C96.944191,195.509171 110.927193,189.566681 117.469269,195.830704  C121.882278,200.056107 127.025208,203.519165 131.670868,207.793655  C126.431679,213.244217 121.456474,218.324051 116.268784,223.176712  C111.001381,228.103989 105.115166,231.268967 97.517632,228.724747  C95.840439,228.163116 93.955940,228.220490 92.166908,227.992813  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M185.992584,125.007545  C186.490143,125.667107 186.930939,126.380119 187.493912,126.978073  C194.416351,134.330658 192.700775,140.337799 182.243042,144.023865  C172.622513,134.917145 163.373932,125.792725 154.340271,116.306213  C158.045212,112.279396 161.535233,108.614685 165.350204,104.964195  C166.108063,105.004501 166.540970,105.030586 167.027069,105.392159  C173.384384,112.154282 179.688477,118.580910 185.992584,125.007545  z"
    />
    <path
      fill="#FEEDA9"
      opacity={1}
      stroke="none"
      d=" M166.973877,105.056671  C166.540970,105.030586 166.108063,105.004501 165.189819,104.676155  C157.122406,96.546494 149.540329,88.719093 141.958267,80.891693  C146.664246,75.207436 152.676422,75.289238 159.611328,77.349808  C168.722885,86.004539 177.284668,94.326736 185.400574,102.826462  C181.851822,103.336098 178.747070,103.651764 175.646622,104.005089  C172.753326,104.334801 169.864609,104.704552 166.973877,105.056671  z"
    />
    <path
      fill="#FBD282"
      opacity={1}
      stroke="none"
      d=" M141.629425,81.034805  C149.540329,88.719093 157.122406,96.546494 164.864868,104.661926  C161.535233,108.614685 158.045212,112.279396 154.203827,115.995636  C148.228088,109.882576 140.482086,105.592888 137.851028,96.791206  C136.043701,90.745163 137.187668,85.728172 141.629425,81.034805  z"
    />
    <path
      fill="#FBC56E"
      opacity={1}
      stroke="none"
      d=" M226.005951,170.006516  C226.590378,169.192429 227.174805,168.378326 228.173340,167.126556  C232.692398,163.568573 236.797363,160.448257 241.248199,157.301468  C242.421371,156.906311 243.248672,156.537643 244.420715,156.143051  C245.843353,155.729294 246.921265,155.341461 247.999161,154.953629  C250.366989,154.983475 252.734802,155.013321 255.538330,155.452286  C255.383133,157.004623 255.028976,158.381989 254.165222,159.255341  C243.989700,169.544098 233.771744,179.791840 223.455383,189.938980  C222.386490,190.990326 220.612854,191.325211 219.166214,191.992538  C219.439331,188.952469 219.712463,185.912415 220.201385,182.229752  C222.280106,177.726944 224.143036,173.866730 226.005951,170.006516  z"
    />
    <path
      fill="#CD383E"
      opacity={1}
      stroke="none"
      d=" M198.233582,89.909744  C204.186371,95.705406 210.139160,101.501068 216.542908,107.752762  C223.024857,114.381264 229.055878,120.553734 235.086899,126.726196  C233.667175,126.558960 231.825943,126.907890 230.892273,126.145454  C220.386765,117.566704 209.921127,108.932823 199.642242,100.084389  C186.610718,88.866364 173.761292,77.436775 160.501617,66.038399  C160.120926,64.589188 160.071991,63.199993 160.424423,61.456909  C168.808899,67.825333 176.527740,74.902771 184.885666,81.121284  C189.114151,84.267387 191.745087,90.148903 198.233582,89.909744  z"
    />
    <path
      fill="#E6A56E"
      opacity={1}
      stroke="none"
      d=" M153.014160,230.695953  C151.473297,232.746048 149.811508,234.513855 148.254166,236.369308  C139.728561,246.526855 131.228607,256.705902 122.171036,266.908875  C120.730621,266.334412 119.839027,265.728638 118.685211,264.967316  C117.617386,264.204315 116.811783,263.596893 115.806931,262.952271  C115.607674,262.915070 115.204727,262.870575 115.023956,262.615814  C113.915596,261.536774 112.988007,260.712494 112.045105,259.675751  C112.029785,259.463287 111.972633,259.041077 112.026054,258.693909  C112.406281,257.780792 112.598526,257.034790 113.079597,256.675323  C124.504951,248.138199 135.929260,239.598541 147.459381,231.204407  C148.674698,230.319641 150.546265,230.336288 152.113388,229.934753  C152.389404,230.107880 152.665421,230.281006 153.014160,230.695953  z"
    />
    <path
      fill="#822E23"
      opacity={1}
      stroke="none"
      d=" M244.075974,156.168976  C243.248672,156.537643 242.421371,156.906311 240.978958,157.088974  C236.526184,153.146027 232.688507,149.389099 228.850845,145.632172  C221.783234,138.482681 214.715622,131.333176 207.196503,123.740967  C204.177505,121.035835 201.478683,118.903999 199.070892,116.482765  C194.573517,111.960213 190.245148,107.269600 185.846451,102.648926  C177.284668,94.326736 168.722885,86.004539 160.029266,77.364822  C159.944458,76.587227 159.991516,76.127167 160.426849,75.383644  C168.836380,82.527573 176.973007,89.834900 184.853149,97.409073  C198.388901,110.419266 211.756989,123.603790 225.219635,136.690262  C226.521271,137.955551 228.123413,138.923203 229.362030,140.239792  C234.314743,145.504288 239.178986,150.852036 244.075974,156.168976  z"
    />
    <path
      fill="#F5B969"
      opacity={1}
      stroke="none"
      d=" M219.089142,192.409119  C220.612854,191.325211 222.386490,190.990326 223.455383,189.938980  C233.771744,179.791840 243.989700,169.544098 254.165222,159.255341  C255.028976,158.381989 255.383133,157.004623 255.979080,155.508865  C258.706696,155.060684 261.429260,154.965057 264.606903,154.843872  C265.061981,154.818344 265.019806,154.988388 264.944031,155.398499  C263.279175,157.672638 261.670959,159.520798 260.105408,161.404419  C257.724213,164.269409 255.372543,167.158981 252.647980,170.089935  C241.534454,173.477585 236.013657,180.960632 234.629395,192.040894  C230.164978,196.099564 226.040329,200.108994 221.915680,204.118423  C221.103745,202.188812 220.291809,200.259216 219.202988,198.111969  C218.954758,196.204788 218.983414,194.515244 219.089142,192.409119  z"
    />
    <path
      fill="#EFB777"
      opacity={1}
      stroke="none"
      d=" M151.990921,229.632446  C150.546265,230.336288 148.674698,230.319641 147.459381,231.204407  C135.929260,239.598541 124.504951,248.138199 113.079597,256.675323  C112.598526,257.034790 112.406281,257.780792 111.720291,258.557281  C109.634987,257.822723 107.908852,256.877625 106.043884,255.685883  C105.600258,254.964432 105.295471,254.489609 105.156822,253.716797  C118.154671,244.216232 130.972733,234.994415 143.848663,225.854141  C144.710678,225.242203 146.010849,225.247482 147.107239,224.965683  C148.694305,226.420517 150.281387,227.875336 151.990921,229.632446  z"
    />
    <path
      fill="#942E29"
      opacity={1}
      stroke="none"
      d=" M244.420715,156.143051  C239.178986,150.852036 234.314743,145.504288 229.362030,140.239792  C228.123413,138.923203 226.521271,137.955551 225.219635,136.690262  C211.756989,123.603790 198.388901,110.419266 184.853149,97.409073  C176.973007,89.834900 168.836380,82.527573 160.486633,75.041672  C160.117035,74.231461 160.075974,73.479767 160.447662,72.361771  C161.570923,71.999054 162.281403,72.002647 162.991898,72.006241  C164.560028,73.480423 166.128143,74.954613 167.848145,76.715561  C170.869568,80.368752 173.485077,83.998489 176.653366,87.055290  C189.312592,99.269073 202.052643,111.402733 214.942261,123.372772  C223.938049,131.726776 233.267685,139.720352 242.351212,147.981567  C244.408844,149.852921 246.064667,152.166107 247.952301,154.614761  C246.921265,155.341461 245.843353,155.729294 244.420715,156.143051  z"
    />
    <path
      fill="#FDE8A1"
      opacity={1}
      stroke="none"
      d=" M92.088593,228.450684  C93.955940,228.220490 95.840439,228.163116 97.517632,228.724747  C105.115166,231.268967 111.001381,228.103989 116.268784,223.176712  C121.456474,218.324051 126.431679,213.244217 131.803467,208.111511  C133.359421,209.093353 134.612015,210.227722 135.669876,211.800842  C127.697777,218.582001 120.279739,225.450272 112.023567,231.092010  C105.974640,235.225433 98.825371,237.748611 92.166519,240.989487  C92.114441,236.962509 92.062355,232.935547 92.088593,228.450684  z"
    />
    <path
      fill="#F0C284"
      opacity={1}
      stroke="none"
      d=" M147.053864,224.629257  C146.010849,225.247482 144.710678,225.242203 143.848663,225.854141  C130.972733,234.994415 118.154671,244.216232 104.826698,253.679321  C102.896179,252.942383 101.461937,251.944901 100.074631,250.603668  C101.026138,249.368652 101.801239,248.267273 102.854935,247.618103  C115.786903,239.651215 129.394623,232.670242 140.115631,221.517029  C141.020126,220.576111 142.755798,220.434158 144.105316,219.921021  C144.105316,219.921021 144.029221,219.969543 144.124878,220.146515  C144.360306,220.616165 144.573608,220.838211 144.860443,220.989624  C145.573792,222.090698 146.287140,223.191772 147.053864,224.629257  z"
    />
    <path
      fill="#F8DA99"
      opacity={1}
      stroke="none"
      d=" M92.087280,241.374420  C98.825371,237.748611 105.974640,235.225433 112.023567,231.092010  C120.279739,225.450272 127.697777,218.582001 135.788177,212.099854  C137.344894,213.092010 138.588577,214.223892 139.496582,215.731750  C132.200195,221.793274 125.539070,227.907120 118.194702,233.044128  C110.810745,238.208862 102.746780,242.401367 94.727234,247.020966  C94.304718,247.027466 94.135757,247.032898 93.966812,247.038345  C93.380302,246.508057 92.793800,245.977768 92.078629,245.175461  C91.969315,243.855408 91.988670,242.807388 92.087280,241.374420  z"
    />
    <path
      fill="#F4CF8F"
      opacity={1}
      stroke="none"
      d=" M94.980789,247.019897  C102.746780,242.401367 110.810745,238.208862 118.194702,233.044128  C125.539070,227.907120 132.200195,221.793274 139.496017,216.051147  C141.161697,217.099564 142.492279,218.204590 143.964081,219.615326  C142.755798,220.434158 141.020126,220.576111 140.115631,221.517029  C129.394623,232.670242 115.786903,239.651215 102.854935,247.618103  C101.801239,248.267273 101.026138,249.368652 99.778397,250.470245  C97.950417,249.460342 96.465607,248.240128 94.980789,247.019897  z"
    />
    <path
      fill="#DD3D46"
      opacity={1}
      stroke="none"
      d=" M198.088684,89.601044  C191.745087,90.148903 189.114151,84.267387 184.885666,81.121284  C176.527740,74.902771 168.808899,67.825333 160.494247,61.045052  C160.106216,58.617458 160.049744,56.247833 160.065720,53.441246  C161.358185,53.714336 162.794800,54.205872 163.765121,55.167778  C175.198441,66.501862 186.561920,77.906380 198.088684,89.601044  z"
    />
    <path
      fill="#F4CF8F"
      opacity={1}
      stroke="none"
      d=" M225.546082,170.004913  C224.143036,173.866730 222.280106,177.726944 220.147095,181.814423  C216.318497,178.609070 212.759995,175.176422 209.081421,171.435028  C210.464279,170.451355 211.963638,169.219864 213.470840,169.210190  C217.340775,169.185318 221.214172,169.702713 225.546082,170.004913  z"
    />
    <path
      fill="#A52E2D"
      opacity={1}
      stroke="none"
      d=" M162.853043,71.732445  C162.281403,72.002647 161.570923,71.999054 160.505356,71.988907  C160.116653,71.536308 160.083008,71.090271 160.110214,70.313263  C161.018768,70.474411 161.866470,70.966530 162.853043,71.732445  z"
    />
    <path
      fill="#F4CF8F"
      opacity={1}
      stroke="none"
      d=" M144.902176,220.720459  C144.573608,220.838211 144.360306,220.616165 144.139099,220.103699  C144.353088,220.073029 144.648499,220.262161 144.902176,220.720459  z"
    />
    <path
      fill="#E1925C"
      opacity={1}
      stroke="none"
      d=" M331.500061,495.043091  C329.869720,494.386658 328.643799,493.209503 327.326691,493.096680  C314.816559,492.025146 301.695801,488.321228 289.901947,490.816345  C269.536377,495.124969 249.282776,491.056458 229.085114,493.665771  C217.477631,495.165314 205.520569,493.859070 193.726944,494.106171  C191.515152,494.152527 189.332153,495.573547 187.047607,496.737854  C182.704926,494.979126 178.450516,492.842804 174.332321,490.165100  C176.901398,488.754059 179.283279,487.358368 181.776978,487.116791  C188.364395,486.478699 194.998489,486.299316 201.617233,486.012115  C217.677322,485.315338 233.744751,484.767456 249.797989,483.941437  C257.056213,483.567963 264.303711,482.802612 271.516296,481.898102  C272.778778,481.739746 273.843781,480.006897 275.424255,478.999634  C283.570251,477.002991 291.291962,475.007904 299.387085,473.009308  C300.507690,472.999542 301.254944,472.993256 302.270874,473.249542  C313.475433,475.008057 324.393127,476.662933 335.356384,477.920197  C339.620911,478.409241 344.016815,477.716766 348.294525,478.144928  C349.959839,478.311646 351.464478,480.083618 353.042297,481.124390  C345.982239,485.755005 338.922211,490.385620 331.500061,495.043091  z"
    />
    <path
      fill="#FEEAA6"
      opacity={1}
      stroke="none"
      d=" M94.727234,247.020966  C96.465607,248.240128 97.950417,249.460342 99.731461,250.813995  C101.461937,251.944901 102.896179,252.942383 104.660553,253.977310  C105.295471,254.489609 105.600258,254.964432 105.662361,255.751938  C103.461983,256.927368 101.562805,257.971222 99.537743,258.625458  C72.756264,267.277740 45.957081,275.875214 19.162327,284.486389  C17.442900,281.613159 15.723471,278.739960 13.974585,275.505768  C15.990279,274.135345 17.947977,272.857819 20.094044,272.157654  C42.961842,264.696686 65.863945,257.340942 88.739899,249.904846  C90.431900,249.354843 91.994797,248.407669 93.792267,247.341751  C94.135757,247.032898 94.304718,247.027466 94.727234,247.020966  z"
    />
    <path
      fill="#FDE59D"
      opacity={1}
      stroke="none"
      d=" M19.106153,284.864624  C45.957081,275.875214 72.756264,267.277740 99.537743,258.625458  C101.562805,257.971222 103.461983,256.927368 105.801193,255.998550  C107.908852,256.877625 109.634987,257.822723 111.666870,258.904480  C111.972633,259.041077 112.029785,259.463287 111.983780,260.032349  C107.770836,263.406921 103.967941,267.299652 99.376389,268.837006  C74.320259,277.226379 49.070129,285.036346 23.886723,293.045563  C22.274479,290.444672 20.662231,287.843781 19.106153,284.864624  z"
    />
    <path
      fill="#F0BA79"
      opacity={1}
      stroke="none"
      d=" M129.585052,449.865356  C128.479477,448.510956 127.373932,447.156525 125.976669,445.145416  C124.445969,442.344025 123.206978,440.199371 122.250351,437.781250  C127.602615,436.864929 132.709473,436.430298 137.735229,435.538635  C150.760635,433.227661 163.717621,430.188416 176.926987,434.662262  C177.960464,435.012299 180.685760,433.743225 180.721634,433.117554  C180.964249,428.884552 184.174576,428.769501 186.924210,428.361969  C184.561951,431.288300 184.208069,434.238770 186.568756,436.647400  C188.674286,438.795685 191.496277,440.241791 194.166473,442.293091  C197.558365,445.057404 200.785873,447.526428 203.647705,450.156494  C198.885483,450.211029 194.434708,450.501801 190.104248,449.910919  C182.386185,448.857758 174.194824,449.701782 167.444550,444.193970  C165.930313,442.958496 163.029327,442.886566 160.847214,443.100311  C152.332138,443.934296 143.818726,444.908905 135.376419,446.274536  C133.306503,446.609375 131.508209,448.623352 129.585052,449.865356  z"
    />
    <path
      fill="#ECB273"
      opacity={1}
      stroke="none"
      d=" M129.718033,450.095764  C131.508209,448.623352 133.306503,446.609375 135.376419,446.274536  C143.818726,444.908905 152.332138,443.934296 160.847214,443.100311  C163.029327,442.886566 165.930313,442.958496 167.444550,444.193970  C174.194824,449.701782 182.386185,448.857758 190.104248,449.910919  C194.434708,450.501801 198.885483,450.211029 204.020691,450.166260  C207.505463,451.008850 210.251602,452.002625 213.240952,453.251221  C217.993759,455.335266 222.503342,457.164459 226.630814,459.161560  C224.456482,459.552948 222.648453,460.054413 220.874680,459.953522  C211.310516,459.409576 201.758240,458.654480 192.193146,458.131531  C190.483063,458.038055 188.732086,458.692230 186.652725,458.953491  C184.806686,457.901550 183.432312,456.533051 181.782974,455.969971  C178.483154,454.843506 175.035889,453.464264 171.643906,453.449249  C166.243835,453.425262 160.791595,454.155090 155.448914,455.074524  C149.234161,456.144043 143.113647,457.761353 136.952240,459.140930  C134.585159,456.202667 132.218094,453.264435 129.718033,450.095764  z"
    />
    <path
      fill="#FEEFAD"
      opacity={1}
      stroke="none"
      d=" M23.960049,361.117279  C19.960472,355.723175 15.555428,350.576965 12.066179,344.870819  C7.907268,338.069458 14.482925,326.396881 23.135212,325.104828  C30.366972,324.024902 37.707031,323.670166 45.732319,323.097626  C47.327148,324.118408 48.188793,325.035828 48.883522,326.254761  C46.906387,329.115570 45.111073,331.685608 43.283260,334.232330  C36.848206,343.198303 30.402020,352.156281 23.960049,361.117279  z"
    />
    <path
      fill="#FDE096"
      opacity={1}
      stroke="none"
      d=" M24.026491,293.373108  C49.070129,285.036346 74.320259,277.226379 99.376389,268.837006  C103.967941,267.299652 107.770836,263.406921 111.999100,260.244812  C112.988007,260.712494 113.915596,261.536774 114.705154,262.786896  C112.258904,265.093567 109.977699,267.008789 107.636902,268.848145  C102.575485,272.825409 98.026024,278.077454 92.291534,280.445526  C80.214752,285.432770 67.477806,288.804565 55.074635,293.024048  C46.646854,295.891144 38.325874,299.072144 29.957138,302.112762  C28.026850,299.308716 26.096560,296.504669 24.026491,293.373108  z"
    />
    <path
      fill="#E4985F"
      opacity={1}
      stroke="none"
      d=" M275.000000,479.001221  C273.843781,480.006897 272.778778,481.739746 271.516296,481.898102  C264.303711,482.802612 257.056213,483.567963 249.797989,483.941437  C233.744751,484.767456 217.677322,485.315338 201.617233,486.012115  C194.998489,486.299316 188.364395,486.478699 181.776978,487.116791  C179.283279,487.358368 176.901398,488.754059 174.166962,489.848328  C171.003708,488.294739 168.142029,486.516510 164.994095,484.081635  C165.137238,482.304230 165.392303,480.335510 166.025345,480.205353  C169.251587,479.542145 172.567566,479.217072 175.866776,479.024536  C188.437485,478.290955 201.017578,477.712280 213.583115,476.903778  C215.089981,476.806854 216.529144,475.657562 218.397644,474.999115  C220.862320,475.337860 222.929047,475.673492 225.267548,476.268219  C234.509979,477.795502 243.447784,479.637634 252.461502,480.153748  C259.927856,480.581238 267.483765,479.444763 275.000000,479.001221  z"
    />
    <path
      fill="#FDDD92"
      opacity={1}
      stroke="none"
      d=" M30.087181,302.432343  C38.325874,299.072144 46.646854,295.891144 55.074635,293.024048  C67.477806,288.804565 80.214752,285.432770 92.291534,280.445526  C98.026024,278.077454 102.575485,272.825409 107.636902,268.848145  C109.977699,267.008789 112.258904,265.093567 114.885925,263.041656  C115.204727,262.870575 115.607674,262.915070 115.796509,263.320343  C115.406815,264.866455 115.096870,266.309174 114.207443,267.100616  C106.794876,273.696655 99.457565,280.399780 91.720993,286.600769  C86.993172,290.390228 81.988274,294.363953 76.432114,296.484344  C63.129555,301.561066 49.427528,305.591064 35.886742,310.043549  C33.996906,307.613007 32.107067,305.182465 30.087181,302.432343  z"
    />
    <path
      fill="#E69C60"
      opacity={1}
      stroke="none"
      d=" M217.999695,474.996033  C216.529144,475.657562 215.089981,476.806854 213.583115,476.903778  C201.017578,477.712280 188.437485,478.290955 175.866776,479.024536  C172.567566,479.217072 169.251587,479.542145 166.025345,480.205353  C165.392303,480.335510 165.137238,482.304230 164.835938,483.779175  C159.716827,480.004639 154.469604,475.875885 149.196365,471.055969  C149.987564,469.918976 150.779602,469.142242 151.625885,469.077759  C161.543671,468.321960 171.478760,467.781616 181.389328,466.947571  C185.947723,466.563965 190.462341,465.660034 195.348007,465.040497  C197.465439,465.730042 199.231674,466.371185 201.217545,467.275787  C206.958023,470.024841 212.478851,472.510437 217.999695,474.996033  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M35.934196,310.398560  C49.427528,305.591064 63.129555,301.561066 76.432114,296.484344  C81.988274,294.363953 86.993172,290.390228 91.720993,286.600769  C99.457565,280.399780 106.794876,273.696655 114.207443,267.100616  C115.096870,266.309174 115.406815,264.866455 115.995758,263.357544  C116.811783,263.596893 117.617386,264.204315 118.629616,265.284973  C103.381134,279.833435 87.967926,293.955139 72.447945,307.958496  C67.097290,312.786285 61.090363,316.496460 53.918636,318.273865  C51.117744,318.968018 48.599464,320.802521 45.952461,322.117615  C42.628857,318.329620 39.305248,314.541595 35.934196,310.398560  z"
    />
    <path
      fill="#E6A56E"
      opacity={1}
      stroke="none"
      d=" M194.996796,464.992065  C190.462341,465.660034 185.947723,466.563965 181.389328,466.947571  C171.478760,467.781616 161.543671,468.321960 151.625885,469.077759  C150.779602,469.142242 149.987564,469.918976 149.062622,470.749542  C145.042007,467.339752 141.129105,463.545227 137.084229,459.445831  C143.113647,457.761353 149.234161,456.144043 155.448914,455.074524  C160.791595,454.155090 166.243835,453.425262 171.643906,453.449249  C175.035889,453.464264 178.483154,454.843506 181.782974,455.969971  C183.432312,456.533051 184.806686,457.901550 186.817383,459.248230  C189.885147,461.391174 192.440979,463.191620 194.996796,464.992065  z"
    />
    <path
      fill="#F6CA82"
      opacity={1}
      stroke="none"
      d=" M45.956985,322.320740  C48.599464,320.802521 51.117744,318.968018 53.918636,318.273865  C61.090363,316.496460 67.097290,312.786285 72.447945,307.958496  C87.967926,293.955139 103.381134,279.833435 118.891838,265.440552  C119.839027,265.728638 120.730621,266.334412 121.903229,267.123230  C120.289894,270.725586 118.395546,274.144928 115.877335,277.789215  C109.501999,283.012329 103.750519,288.010437 97.657120,293.052582  C92.139320,297.438385 86.931984,301.743286 81.794418,306.129913  C72.504280,314.062134 63.255806,322.043182 53.703617,329.857880  C51.961681,328.459045 50.506062,327.206146 49.050442,325.953278  C48.188793,325.035828 47.327148,324.118408 46.179161,323.062683  C45.892815,322.924377 45.961514,322.523865 45.956985,322.320740  z"
    />
    <path
      fill="#FDE8A1"
      opacity={1}
      stroke="none"
      d=" M48.883522,326.254761  C50.506062,327.206146 51.961681,328.459045 53.841297,330.125214  C54.848499,331.347870 55.431702,332.157257 55.966904,333.321289  C49.662224,342.578369 43.504421,351.552917 37.091675,360.341431  C35.030193,363.166595 32.296276,365.501160 29.869987,368.060150  C27.978886,365.952026 26.087786,363.843933 24.078369,361.426544  C30.402020,352.156281 36.848206,343.198303 43.283260,334.232330  C45.111073,331.685608 46.906387,329.115570 48.883522,326.254761  z"
    />
    <path
      fill="#FDE299"
      opacity={1}
      stroke="none"
      d=" M29.953732,368.302032  C32.296276,365.501160 35.030193,363.166595 37.091675,360.341431  C43.504421,351.552917 49.662224,342.578369 56.246460,333.452454  C57.329506,333.516144 58.084991,333.803375 58.914085,334.418121  C60.332863,336.478638 61.678040,338.211670 62.864571,340.249603  C61.143589,342.877686 59.646370,345.247925 58.007523,347.515778  C51.348030,356.731323 44.645092,365.915436 37.958183,375.111145  C36.042988,373.657104 34.127789,372.203094 31.964375,370.209869  C31.437277,369.468475 31.158394,369.266296 30.879517,369.064087  C30.598843,368.890717 30.318161,368.717316 29.953732,368.302032  z"
    />
    <path
      fill="#FDE69F"
      opacity={1}
      stroke="none"
      d=" M34.881546,417.050262  C32.305599,414.930176 29.729650,412.810089 27.334488,410.230408  C36.689598,402.844818 45.863922,395.918793 55.378387,389.043152  C58.467659,390.745056 61.216789,392.396606 63.982292,394.275146  C63.998669,394.502106 64.029137,394.956268 63.870682,395.255615  C54.102001,402.720093 44.491776,409.885162 34.881546,417.050262  z"
    />
    <path
      fill="#FDDD92"
      opacity={1}
      stroke="none"
      d=" M35.026672,417.373962  C44.491776,409.885162 54.102001,402.720093 64.208473,395.307129  C66.480713,396.013214 68.256706,396.967133 70.233856,398.057312  C70.690979,398.401978 70.885757,398.657166 70.969299,399.307831  C64.862152,410.865540 53.395863,415.823853 43.889801,423.047333  C40.983795,421.264130 38.077793,419.480927 35.026672,417.373962  z"
    />
    <path
      fill="#FDDD92"
      opacity={1}
      stroke="none"
      d=" M38.079063,375.421478  C44.645092,365.915436 51.348030,356.731323 58.007523,347.515778  C59.646370,345.247925 61.143589,342.877686 63.107552,340.266724  C63.509174,339.978882 63.995781,340.008759 64.123390,340.273285  C64.825928,341.061798 65.400856,341.585754 65.920822,342.484497  C63.660362,349.903534 62.948322,357.407013 57.849995,363.478607  C53.178303,369.042084 49.227886,375.211243 44.959667,381.113525  C42.706432,379.319611 40.453190,377.525696 38.079063,375.421478  z"
    />
    <path
      fill="#FEEDA9"
      opacity={1}
      stroke="none"
      d=" M55.038246,388.992767  C45.863922,395.918793 36.689598,402.844818 27.196648,409.917877  C22.761715,400.521149 25.795116,393.435333 35.348999,389.982544  C39.348930,388.536987 43.256557,386.835999 47.908890,385.123627  C50.411427,385.988739 52.211224,386.981354 54.222210,388.099915  C54.696911,388.432709 54.898529,388.688324 55.038246,388.992767  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M54.011021,387.973938  C52.211224,386.981354 50.411427,385.988739 48.251251,384.972137  C46.984509,383.865845 46.078152,382.783539 45.065727,381.407379  C49.227886,375.211243 53.178303,369.042084 57.849995,363.478607  C62.948322,357.407013 63.660362,349.903534 66.209412,342.604858  C67.040634,343.227051 67.528297,344.103607 68.010857,345.417419  C67.667641,348.647430 67.427803,351.456299 66.973984,354.230194  C65.233513,364.868439 64.034622,375.702240 55.781918,383.934387  C54.812763,384.901093 54.583351,386.609406 54.011021,387.973938  z"
    />
    <path
      fill="#E69C60"
      opacity={1}
      stroke="none"
      d=" M95.997452,442.856140  C93.231995,442.305359 90.466522,441.754578 87.351669,440.632080  C87.966240,437.114288 88.645653,434.035034 89.950493,431.248596  C93.143105,424.431061 96.624664,417.748840 100.357788,411.057404  C103.209106,411.340149 105.692558,411.577667 108.386658,412.057251  C108.760094,413.120758 108.922874,413.942200 108.892059,415.058167  C104.464790,424.520477 100.231125,433.688324 95.997452,442.856140  z"
    />
    <path
      fill="#EBA664"
      opacity={1}
      stroke="none"
      d=" M99.989899,411.012146  C96.624664,417.748840 93.143105,424.431061 89.950493,431.248596  C88.645653,434.035034 87.966240,437.114288 86.998428,440.449036  C83.557022,439.954437 80.119469,439.071075 76.350502,437.647766  C77.668297,433.920319 79.123886,430.612976 81.007164,427.570343  C84.885727,421.304108 88.992775,415.179321 93.339722,408.996765  C94.119133,408.994446 94.564636,408.991730 95.317230,409.128662  C97.079514,409.849609 98.534698,410.430878 99.989899,411.012146  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M44.042549,423.371094  C53.395863,415.823853 64.862152,410.865540 71.304039,399.358368  C73.115707,399.722198 74.542587,400.384216 75.926727,401.390259  C74.237541,404.188965 72.737938,406.759094 70.916878,409.076599  C65.642830,415.788361 60.239582,422.398621 54.887047,429.048737  C51.323132,427.264099 47.759212,425.479462 44.042549,423.371094  z"
    />
    <path
      fill="#F0B36B"
      opacity={1}
      stroke="none"
      d=" M93.005798,408.996338  C88.992775,415.179321 84.885727,421.304108 81.007164,427.570343  C79.123886,430.612976 77.668297,433.920319 76.005173,437.472290  C73.451935,437.113831 70.912621,436.390869 68.191277,434.984436  C68.675781,433.012939 69.213882,431.636597 70.029510,430.451141  C75.646088,422.288055 81.320618,414.164856 87.230835,406.020142  C87.654625,406.010040 87.824089,406.008667 88.215683,406.250122  C89.960480,407.327362 91.483139,408.161835 93.005798,408.996338  z"
    />
    <path
      fill="#E1925C"
      opacity={1}
      stroke="none"
      d=" M96.410461,442.928223  C100.231125,433.688324 104.464790,424.520477 109.119385,415.123596  C109.540306,414.894531 110.001747,415.000641 110.007881,415.369202  C111.693573,419.162537 113.373123,422.587311 114.981300,425.994751  C114.909920,425.977417 114.763069,425.973328 114.432625,426.046265  C109.272041,431.164703 106.594147,437.193481 105.878876,444.118958  C102.860405,443.746063 99.841934,443.373199 96.410461,442.928223  z"
    />
    <path
      fill="#F8C97C"
      opacity={1}
      stroke="none"
      d=" M55.037453,429.370728  C60.239582,422.398621 65.642830,415.788361 70.916878,409.076599  C72.737938,406.759094 74.237541,404.188965 76.225693,401.503601  C77.372421,401.523804 78.177444,401.774658 79.118011,402.281097  C79.832726,403.031189 80.411888,403.525665 80.947083,404.370667  C76.895035,410.355347 72.799706,415.930054 68.910477,421.645050  C66.445763,425.266815 64.287971,429.097504 61.992531,432.834473  C59.724308,431.787231 57.456085,430.739960 55.037453,429.370728  z"
    />
    <path
      fill="#F5C074"
      opacity={1}
      stroke="none"
      d=" M62.324036,433.000183  C64.287971,429.097504 66.445763,425.266815 68.910477,421.645050  C72.799706,415.930054 76.895035,410.355347 81.300034,404.404358  C83.456795,404.734589 85.216652,405.381714 86.976501,406.028809  C81.320618,414.164856 75.646088,422.288055 70.029510,430.451141  C69.213882,431.636597 68.675781,433.012939 67.998657,434.669189  C66.210548,434.413605 64.433044,433.789734 62.324036,433.000183  z"
    />
    <path
      fill="#DE8B59"
      opacity={1}
      stroke="none"
      d=" M105.896255,444.100494  C106.594147,437.193481 109.272041,431.164703 114.545273,426.393188  C117.314903,430.463043 119.641449,434.258881 121.967995,438.054688  C123.206978,440.199371 124.445969,442.344025 125.803589,444.803680  C122.572746,445.076141 119.223274,445.033661 115.099808,444.682343  C111.506531,444.280945 108.687233,444.188385 105.867943,444.095825  C105.867943,444.095856 105.913635,444.082062 105.896255,444.100494  z"
    />
    <path
      fill="#E1925C"
      opacity={1}
      stroke="none"
      d=" M106.081894,444.465576  C108.687233,444.188385 111.506531,444.280945 114.645439,444.612457  C112.075325,444.846039 109.185585,444.840698 106.081894,444.465576  z"
    />
    <path
      fill="#FDE8A1"
      opacity={1}
      stroke="none"
      d=" M30.964447,369.323608  C31.158394,369.266296 31.437277,369.468475 31.837784,369.902771  C31.656063,369.950958 31.352722,369.767029 30.964447,369.323608  z"
    />
    <path
      fill="#FDE59D"
      opacity={1}
      stroke="none"
      d=" M401.020782,260.088104  C402.216187,259.218353 403.411560,258.348602 404.799133,257.229797  C406.095123,256.329895 407.198944,255.679047 408.801575,255.285583  C410.869019,256.410706 412.351288,257.533051 414.022064,258.099854  C419.677673,260.018524 425.406158,261.721161 431.095795,263.540924  C452.438293,270.367004 473.776428,277.206757 495.116211,284.041412  C492.974213,287.962524 490.832214,291.883636 488.192383,295.639435  C485.925720,294.638702 484.224518,293.593018 482.377899,292.999969  C460.701172,286.038605 438.956268,279.285553 417.337677,272.149353  C410.739502,269.971344 404.599274,266.619385 401.020782,260.088104  z"
    />
    <path
      fill="#FEEAA6"
      opacity={1}
      stroke="none"
      d=" M495.434326,283.892639  C473.776428,277.206757 452.438293,270.367004 431.095795,263.540924  C425.406158,261.721161 419.677673,260.018524 414.022064,258.099854  C412.351288,257.533051 410.869019,256.410706 409.119629,255.229034  C409.222168,254.453629 409.505402,253.992172 409.905609,253.286469  C411.481903,252.120682 412.941254,251.199142 414.688538,250.116806  C416.457947,249.068451 417.939392,248.180893 419.704529,247.136688  C420.155518,246.983398 420.322815,246.986755 420.740967,246.990387  C422.461853,247.963867 423.805145,249.327499 425.420837,249.851852  C450.296356,257.924805 475.207367,265.888580 500.471741,273.936646  C499.139740,277.244995 497.446136,280.494415 495.434326,283.892639  z"
    />
    <path
      fill="#FDDF94"
      opacity={1}
      stroke="none"
      d=" M400.779510,260.063751  C404.599274,266.619385 410.739502,269.971344 417.337677,272.149353  C438.956268,279.285553 460.701172,286.038605 482.377899,292.999969  C484.224518,293.593018 485.925720,294.638702 487.870850,295.791443  C486.278442,298.990265 484.509644,301.871735 482.030151,304.877197  C466.492432,300.271088 451.589325,295.762024 436.876617,290.699707  C431.168427,288.735657 425.647736,285.915222 420.497894,282.743988  C416.758484,280.441284 413.654633,277.037140 410.474457,273.913269  C406.531342,270.039978 402.802094,265.948944 399.091461,261.699158  C399.486145,260.971710 399.769836,260.497040 400.053528,260.022369  C400.053528,260.022369 400.538239,260.039398 400.779510,260.063751  z"
    />
    <path
      fill="#E69C60"
      opacity={1}
      stroke="none"
      d=" M353.393341,481.066406  C351.464478,480.083618 349.959839,478.311646 348.294525,478.144928  C344.016815,477.716766 339.620911,478.409241 335.356384,477.920197  C324.393127,476.662933 313.475433,475.008057 302.494110,472.984314  C305.968933,470.644470 309.489166,468.832489 313.365601,466.966980  C318.156921,464.282928 322.592102,461.652374 327.354919,459.011230  C328.119659,458.986084 328.556763,458.971466 329.084656,459.321472  C331.602570,460.457428 333.999207,461.766052 336.462128,461.905762  C346.359650,462.467133 356.282227,462.577911 366.183563,463.087677  C367.833893,463.172668 369.424774,464.412354 371.043091,465.118408  C365.276855,470.415100 359.510620,475.711761 353.393341,481.066406  z"
    />
    <path
      fill="#F0B067"
      opacity={1}
      stroke="none"
      d=" M388.053406,445.119659  C386.588989,447.354980 385.124542,449.590302 383.277161,451.582642  C381.441040,450.560516 380.039398,449.257477 378.524261,449.108490  C375.085571,448.770294 371.587677,449.097534 368.119843,448.979401  C359.731293,448.693634 351.346191,448.307770 343.119690,447.676910  C344.198303,445.953979 345.116882,444.516388 346.373505,443.011169  C350.798920,438.624878 354.886322,434.306244 359.444031,429.990753  C372.266022,429.993195 384.617676,429.992523 396.837311,430.311584  C393.821350,435.460754 390.937378,440.290192 388.053406,445.119659  z"
    />
    <path
      fill="#FEEFAD"
      opacity={1}
      stroke="none"
      d=" M468.107452,322.934814  C476.483856,323.951691 484.917603,324.638641 493.219757,326.082397  C500.827393,327.405426 505.685944,339.680664 501.420258,346.043488  C498.287354,350.716522 494.944153,355.248535 491.216370,359.678925  C489.747223,358.205475 488.765839,356.889160 487.763977,355.588684  C480.163544,345.722870 472.559082,335.860107 465.105774,325.695740  C466.206238,324.574951 467.156830,323.754883 468.107452,322.934814  z"
    />
    <path
      fill="#E9A362"
      opacity={1}
      stroke="none"
      d=" M342.959686,447.962280  C351.346191,448.307770 359.731293,448.693634 368.119843,448.979401  C371.587677,449.097534 375.085571,448.770294 378.524261,449.108490  C380.039398,449.257477 381.441040,450.560516 382.965637,451.734741  C379.245361,456.368683 375.453644,460.607544 371.352539,464.982422  C369.424774,464.412354 367.833893,463.172668 366.183563,463.087677  C356.282227,462.577911 346.359650,462.467133 336.462128,461.905762  C333.999207,461.766052 331.602570,460.457428 329.245117,459.030029  C332.218353,455.922363 335.121948,453.470795 338.312134,450.860046  C340.052368,449.788025 341.506042,448.875153 342.959686,447.962280  z"
    />
    <path
      fill="#FCDA8E"
      opacity={1}
      stroke="none"
      d=" M398.980438,261.951965  C402.802094,265.948944 406.531342,270.039978 410.474457,273.913269  C413.654633,277.037140 416.758484,280.441284 420.497894,282.743988  C425.647736,285.915222 431.168427,288.735657 436.876617,290.699707  C451.589325,295.762024 466.492432,300.271088 481.716553,305.022278  C480.305084,307.640442 478.496521,310.237488 476.195923,312.657471  C474.613617,311.751709 473.617462,310.742310 472.417755,310.339996  C464.846741,307.801056 457.269287,305.267181 449.618896,302.983093  C441.268311,300.489929 433.271881,297.566803 426.710541,291.400055  C423.927673,288.784546 420.265137,287.104980 416.793060,284.879089  C416.322479,284.556061 416.127502,284.302948 415.954224,283.663239  C414.939789,282.552216 413.969910,281.776703 412.838623,280.701660  C407.868103,276.187775 403.089874,271.937164 398.223297,267.790222  C397.265839,266.974335 395.967346,266.558655 394.989868,265.672424  C396.429199,264.241638 397.704834,263.096802 398.980438,261.951965  z"
    />
    <path
      fill="#FEE9A3"
      opacity={1}
      stroke="none"
      d=" M464.955872,325.996429  C472.559082,335.860107 480.163544,345.722870 487.763977,355.588684  C488.765839,356.889160 489.747223,358.205475 490.892883,359.821747  C488.927246,362.702942 486.806763,365.276794 484.216888,367.690491  C477.517914,359.192993 471.181641,350.932251 465.112518,342.479706  C462.759216,339.202240 461.010101,335.490906 458.999268,331.750366  C459.012756,331.523529 459.044647,331.069641 459.328339,330.906372  C461.393311,329.160889 463.174591,327.578644 464.955872,325.996429  z"
    />
    <path
      fill="#FDE299"
      opacity={1}
      stroke="none"
      d=" M458.985779,331.977234  C461.010101,335.490906 462.759216,339.202240 465.112518,342.479706  C471.181641,350.932251 477.517914,359.192993 483.901642,367.839539  C481.969604,370.099640 479.883362,372.050568 477.145813,373.868164  C475.449127,372.690948 474.250153,371.760468 473.382446,370.585327  C465.868042,360.408905 458.413239,350.188507 451.102051,339.697327  C452.501251,337.927979 453.739075,336.442535 455.266144,334.834167  C456.698853,333.799896 457.842316,332.888580 458.985779,331.977234  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M417.002441,285.000000  C420.265137,287.104980 423.927673,288.784546 426.710541,291.400055  C433.271881,297.566803 441.268311,300.489929 449.618896,302.983093  C457.269287,305.267181 464.846741,307.801056 472.417755,310.339996  C473.617462,310.742310 474.613617,311.751709 475.874695,312.795593  C474.274902,315.337128 472.504303,317.563446 470.049866,319.860260  C466.579254,318.906525 463.836334,317.735748 460.997314,316.886200  C453.183899,314.548004 445.776550,311.535278 439.658722,305.870239  C437.654480,304.014313 435.231506,302.610535 432.843872,300.705505  C427.459290,295.274017 422.230865,290.136993 417.002441,285.000000  z"
    />
    <path
      fill="#FDDD92"
      opacity={1}
      stroke="none"
      d=" M450.940704,339.981201  C458.413239,350.188507 465.868042,360.408905 473.382446,370.585327  C474.250153,371.760468 475.449127,372.690948 476.800598,373.892029  C474.631378,376.311523 472.156128,378.573792 469.207428,380.664734  C460.304138,368.505188 449.237457,357.938568 448.262299,341.904480  C449.000000,341.150940 449.491394,340.557129 450.222290,339.967041  C450.461761,339.970764 450.940704,339.981201 450.940704,339.981201  z"
    />
    <path
      fill="#FDE59D"
      opacity={1}
      stroke="none"
      d=" M487.128174,410.052460  C484.662689,412.300385 482.197235,414.548309 479.102173,416.779541  C469.979736,410.669067 461.437927,404.641205 453.043793,398.414215  C451.697266,397.415344 451.010895,395.526520 450.362366,393.987366  C453.463104,392.272156 456.220825,390.618713 459.121979,389.273743  C468.553009,396.405609 477.840607,403.229034 487.128174,410.052460  z"
    />
    <path
      fill="#FEEDA9"
      opacity={1}
      stroke="none"
      d=" M487.477478,409.863770  C477.840607,403.229034 468.553009,396.405609 459.243530,389.024506  C459.515228,387.995239 459.808807,387.523651 460.454590,386.990479  C462.571991,386.260925 464.337219,385.592957 466.102448,384.924957  C469.882782,386.548370 473.626129,388.265350 477.450500,389.777374  C488.269287,394.054749 490.637146,398.530182 487.477478,409.863770  z"
    />
    <path
      fill="#FDDF94"
      opacity={1}
      stroke="none"
      d=" M450.019348,394.049133  C451.010895,395.526520 451.697266,397.415344 453.043793,398.414215  C461.437927,404.641205 469.979736,410.669067 478.790283,416.906738  C475.970612,419.296967 472.833282,421.543365 469.294861,423.478088  C466.889618,421.288116 465.003906,419.260712 462.859802,417.558807  C455.725769,411.896057 446.954681,408.005249 443.101898,398.754517  C443.418671,398.279175 443.667145,398.077087 444.265656,397.809937  C446.048584,396.462433 447.532745,395.243103 449.267639,394.025635  C449.685333,394.034698 449.852325,394.041901 450.019348,394.049133  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M466.144623,384.617920  C464.337219,385.592957 462.571991,386.260925 460.178345,386.779114  C455.078278,379.952576 449.925781,373.655914 448.606201,365.323456  C447.530304,358.529816 446.209686,351.774902 445.137482,344.728516  C446.190247,343.657501 447.103119,342.860840 448.015991,342.064148  C449.237457,357.938568 460.304138,368.505188 468.889374,380.800598  C468.092102,382.175507 467.139465,383.243164 466.144623,384.617920  z"
    />
    <path
      fill="#E69C60"
      opacity={1}
      stroke="none"
      d=" M427.005127,440.836823  C423.944458,441.553375 420.883820,442.269928 417.412018,442.508728  C414.408539,435.175751 411.947754,428.266144 409.152039,421.494843  C408.182098,419.145691 406.381805,417.139343 404.973694,414.644226  C405.292816,413.516022 405.599060,412.719971 406.152130,412.180359  C408.936066,411.961975 411.473114,411.487152 414.053772,411.365326  C415.307495,413.861023 416.732086,415.912567 417.688568,418.163086  C420.884399,425.682617 423.912659,433.273376 427.005127,440.836823  z"
    />
    <path
      fill="#EBA664"
      opacity={1}
      stroke="none"
      d=" M427.402344,440.906799  C423.912659,433.273376 420.884399,425.682617 417.688568,418.163086  C416.732086,415.912567 415.307495,413.861023 414.351715,411.228943  C415.738190,410.161102 416.870300,409.582672 418.319763,408.969391  C419.427673,408.625854 420.218231,408.317108 421.015015,408.375854  C424.216858,413.868713 427.521057,418.930481 430.575012,424.138947  C433.201172,428.617920 435.541779,433.264343 438.008636,437.836731  C434.605591,438.883392 431.202545,439.930084 427.402344,440.906799  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M442.982300,398.964722  C446.954681,408.005249 455.725769,411.896057 462.859802,417.558807  C465.003906,419.260712 466.889618,421.288116 468.949951,423.499207  C465.950317,425.551147 462.894470,427.270294 459.203125,428.819489  C457.504883,427.392456 456.363190,426.193207 455.392334,424.868805  C449.583710,416.945007 443.814392,408.992371 438.334839,400.900269  C440.085968,400.155640 441.534119,399.560181 442.982300,398.964722  z"
    />
    <path
      fill="#FBD282"
      opacity={1}
      stroke="none"
      d=" M433.000000,301.000000  C435.231506,302.610535 437.654480,304.014313 439.658722,305.870239  C445.776550,311.535278 453.183899,314.548004 460.997314,316.886200  C463.836334,317.735748 466.579254,318.906525 469.739807,319.987000  C469.466614,320.805542 468.819580,321.567902 468.140015,322.632538  C467.156830,323.754883 466.206238,324.574951 465.105774,325.695740  C463.174591,327.578644 461.393311,329.160889 459.272156,330.557190  C456.360352,327.620026 453.902039,324.750671 451.191803,322.143127  C446.206451,317.346649 441.072968,312.704193 435.953949,307.651123  C434.937805,305.203156 433.968903,303.101562 433.000000,301.000000  z"
    />
    <path
      fill="#E1925C"
      opacity={1}
      stroke="none"
      d=" M404.960815,414.976410  C406.381805,417.139343 408.182098,419.145691 409.152039,421.494843  C411.947754,428.266144 414.408539,435.175751 417.002869,442.440063  C414.323853,443.239044 411.642883,443.629059 408.508911,443.669189  C405.902069,438.002289 403.809326,432.658600 401.520020,427.400513  C401.220764,426.713165 399.911865,426.465424 399.197449,425.691437  C401.203125,421.904633 403.081970,418.440521 404.960815,414.976410  z"
    />
    <path
      fill="#F5C074"
      opacity={1}
      stroke="none"
      d=" M452.007477,432.839844  C450.253357,433.556244 448.499237,434.272644 446.236450,434.699402  C443.140656,430.760742 440.437439,427.187469 437.993408,423.444946  C434.239288,417.696381 430.663361,411.831421 427.219269,405.894501  C427.685852,405.572113 427.880676,405.321136 428.377991,405.008972  C430.158813,404.658142 431.572815,404.318604 433.034668,404.332092  C435.979828,408.889496 438.918854,413.065918 441.763794,417.305450  C445.219818,422.455780 448.596466,427.659332 452.007477,432.839844  z"
    />
    <path
      fill="#F0B36B"
      opacity={1}
      stroke="none"
      d=" M427.011810,406.015869  C430.663361,411.831421 434.239288,417.696381 437.993408,423.444946  C440.437439,427.187469 443.140656,430.760742 445.867767,434.725098  C443.590820,436.017944 441.173920,436.995544 438.382812,437.904968  C435.541779,433.264343 433.201172,428.617920 430.575012,424.138947  C427.521057,418.930481 424.216858,413.868713 421.262146,408.371704  C421.503052,408.000092 421.997559,407.990387 422.335571,407.953979  C424.119629,407.283691 425.565704,406.649780 427.011810,406.015869  z"
    />
    <path
      fill="#F8C97C"
      opacity={1}
      stroke="none"
      d=" M452.357147,432.814758  C448.596466,427.659332 445.219818,422.455780 441.763794,417.305450  C438.918854,413.065918 435.979828,408.889496 433.219666,404.093323  C434.229248,402.991455 435.101654,402.481384 436.237335,401.869385  C437.011047,401.528107 437.521484,401.288757 438.031921,401.049408  C443.814392,408.992371 449.583710,416.945007 455.392334,424.868805  C456.363190,426.193207 457.504883,427.392456 458.838501,428.850983  C456.975220,430.298157 454.841034,431.543884 452.357147,432.814758  z"
    />
    <path
      fill="#DE8B59"
      opacity={1}
      stroke="none"
      d=" M399.070557,426.014099  C399.911865,426.465424 401.220764,426.713165 401.520020,427.400513  C403.809326,432.658600 405.902069,438.002289 408.088501,443.719177  C408.121033,444.118988 408.085968,444.081940 407.665222,444.058411  C404.505127,444.307007 401.765778,444.579163 399.026459,444.851379  C395.670654,444.906921 392.314880,444.962494 388.506226,445.068848  C390.937378,440.290192 393.821350,435.460754 396.982910,430.025391  C397.863892,428.284302 398.467224,427.149200 399.070557,426.014099  z"
    />
    <path
      fill="#E1925C"
      opacity={1}
      stroke="none"
      d=" M399.462646,444.919708  C401.765778,444.579163 404.505127,444.307007 407.688354,444.065216  C405.387787,444.393066 402.643341,444.690582 399.462646,444.919708  z"
    />
    <path
      fill="#FDE299"
      opacity={1}
      stroke="none"
      d=" M297.960938,132.914062  C303.754211,127.004417 309.547485,121.094765 315.644958,115.042633  C319.305359,115.600105 322.668610,116.268135 326.014923,117.012276  C327.955933,117.443909 329.869720,117.997902 331.795776,118.496857  C330.851501,120.508591 330.343811,122.938400 328.887238,124.459625  C322.609314,131.016190 316.053192,137.306335 309.145325,143.552826  C307.068451,142.023178 305.356110,140.723495 303.842438,139.223373  C301.801849,137.201065 299.914215,135.024445 297.960938,132.914062  z"
    />
    <path
      fill="#FCDA8E"
      opacity={1}
      stroke="none"
      d=" M297.666016,133.037750  C299.914215,135.024445 301.801849,137.201065 303.842438,139.223373  C305.356110,140.723495 307.068451,142.023178 308.852661,143.709808  C309.013000,144.007431 309.000153,144.500305 308.994873,144.746704  C308.087921,145.811676 307.186218,146.630234 306.145020,147.723389  C306.005493,147.997971 305.506897,147.999649 305.257690,147.999695  C301.425232,144.499695 297.841980,140.999634 294.100067,137.196472  C295.084656,135.649384 296.227875,134.405411 297.666016,133.037750  z"
    />
    <path
      fill="#77291E"
      opacity={1}
      stroke="none"
      d=" M172.030533,41.987801  C171.460632,41.461250 170.890732,40.934704 170.200409,39.932007  C170.104950,39.188591 170.110336,38.920925 170.096146,38.652870  C189.410049,38.564079 208.723938,38.475292 228.623016,38.743744  C230.490570,38.928158 231.772934,38.755329 233.055313,38.582504  C238.384125,38.516361 243.712921,38.450211 249.691864,38.792557  C251.607849,39.453388 252.872879,39.920803 254.139664,39.925575  C271.215912,39.989979 288.293518,39.877884 305.368469,40.045551  C316.656189,40.156391 327.940796,40.625854 339.224518,41.003498  C340.146545,41.034355 341.050873,41.595516 341.963409,41.910271  C341.113617,42.834003 340.263824,43.757736 338.680054,44.824261  C327.827728,44.645004 317.710938,44.259331 307.590698,44.015514  C291.552277,43.629120 275.511688,43.329708 259.471741,43.008129  C242.311859,42.664097 225.152710,42.252670 207.991196,42.031387  C196.006104,41.876854 184.017548,41.992714 172.030533,41.987801  z"
    />
    <path
      fill="#814227"
      opacity={1}
      stroke="none"
      d=" M342.244049,41.802704  C341.050873,41.595516 340.146545,41.034355 339.224518,41.003498  C327.940796,40.625854 316.656189,40.156391 305.368469,40.045551  C288.293518,39.877884 271.215912,39.989979 254.139664,39.925575  C252.872879,39.920803 251.607849,39.453388 250.145309,38.889687  C250.933533,38.453476 251.918442,38.219490 252.903336,38.219627  C283.541504,38.223858 314.179657,38.250950 345.268433,38.300423  C344.654266,39.449249 343.589478,40.572193 342.244049,41.802704  z"
    />
    <path
      fill="#814227"
      opacity={1}
      stroke="none"
      d=" M232.656372,38.450012  C231.772934,38.755329 230.490570,38.928158 229.076950,38.841797  C230.049622,38.494244 231.153519,38.405884 232.656372,38.450012  z"
    />
    <path
      fill="#814227"
      opacity={1}
      stroke="none"
      d=" M169.832397,38.513626  C170.110336,38.920925 170.104950,39.188591 170.060455,39.656990  C169.584854,39.460892 169.128769,39.063667 168.525757,38.490364  C168.775436,38.334316 169.172043,38.354347 169.832397,38.513626  z"
    />
    <path
      fill="#F6CA82"
      opacity={1}
      stroke="none"
      d=" M285.999908,273.032471  C291.561279,270.229462 297.061798,267.296082 302.701935,264.661621  C307.894653,262.236145 313.064301,259.283508 319.137115,262.653381  C320.002380,263.133545 322.069183,262.141785 323.205872,261.316315  C327.538116,258.170135 333.271576,256.746979 335.683350,251.145142  C336.062317,250.264984 337.488464,249.733582 338.529205,249.256531  C347.435760,245.174133 356.365112,241.141434 365.941376,237.201569  C370.739532,241.869034 374.883942,246.428802 378.618713,250.992493  C375.993347,251.572708 373.752655,252.067764 371.566162,252.739334  C361.844849,255.725098 354.099335,264.016632 342.946899,263.324066  C341.708588,263.247131 340.339325,264.629059 339.093262,265.431396  C331.510895,270.313965 325.689148,277.646759 316.735870,280.711456  C311.296814,282.573273 306.233398,285.532501 300.712067,287.846924  C298.615265,286.130463 296.807648,284.565857 294.843201,282.708435  C291.790924,279.287933 288.895416,276.160217 285.999908,273.032471  z"
    />
    <path
      fill="#FDE299"
      opacity={1}
      stroke="none"
      d=" M273.937775,261.051300  C275.929535,263.471558 277.921265,265.891815 279.690735,268.795471  C275.317963,272.850616 271.167511,276.422363 266.715363,279.836639  C263.268768,278.099365 260.123871,276.519562 256.591248,274.940918  C251.625351,274.964661 249.116287,277.984680 246.791183,281.351715  C240.204666,290.889893 233.577271,300.399811 226.602707,309.950195  C224.834946,310.344147 223.430450,310.708801 221.666718,311.194214  C217.208405,312.221100 213.109344,313.127289 209.051544,313.689087  C210.319839,311.805237 211.413315,310.132629 212.794296,308.746429  C229.370560,292.107391 246.005585,275.526794 262.567200,258.873199  C263.921387,257.511505 264.850769,255.727386 266.277649,254.266281  C269.031097,256.612457 271.484436,258.831879 273.937775,261.051300  z"
    />
    <path
      fill="#FDDD92"
      opacity={1}
      stroke="none"
      d=" M267.017059,279.994141  C271.167511,276.422363 275.317963,272.850616 279.771790,269.121887  C281.855591,270.214050 283.636017,271.463196 285.708191,272.872406  C288.895416,276.160217 291.790924,279.287933 294.835175,283.072144  C290.977600,287.823517 286.971252,291.918457 282.675903,295.853973  C278.602386,292.112122 274.817902,288.529694 271.014008,284.966431  C270.994629,284.985626 271.048950,284.989502 270.939880,284.758789  C270.556519,284.335083 270.282227,284.142120 270.002014,283.976044  C269.996033,284.002899 270.050140,283.992981 269.934113,283.750244  C269.547821,283.272491 269.277588,283.037476 268.874390,282.545471  C268.166687,281.523743 267.591858,280.758942 267.017059,279.994141  z"
    />
    <path
      fill="#EDAC69"
      opacity={1}
      stroke="none"
      d=" M293.997559,232.004868  C295.741791,232.330780 297.485992,232.656693 299.613220,232.994141  C302.491638,234.257446 304.990021,235.503296 307.481659,236.762451  C310.735260,238.406662 310.562653,240.508698 307.887085,242.383713  C303.833557,245.224380 299.588440,247.791595 295.209351,250.734100  C294.993988,250.993210 294.502350,250.986023 294.004028,250.727005  C288.335236,248.972549 283.164764,247.477112 278.062317,245.648972  C282.415710,242.379105 286.799530,239.571396 290.930634,236.431213  C292.289368,235.398361 292.993866,233.504822 293.997559,232.004868  z"
    />
    <path
      fill="#EFB777"
      opacity={1}
      stroke="none"
      d=" M277.994324,245.981689  C283.164764,247.477112 288.335236,248.972549 293.758179,250.723694  C287.525024,254.224060 281.039307,257.468719 274.245667,260.882355  C271.484436,258.831879 269.031097,256.612457 265.995422,254.078094  C262.558990,250.557587 259.704956,247.351959 257.243469,244.108643  C258.424194,244.043915 259.212372,244.016876 260.432007,243.995590  C266.573761,244.661453 272.284027,245.321579 277.994324,245.981689  z"
    />
    <path
      fill="#E49F6B"
      opacity={1}
      stroke="none"
      d=" M379.028351,250.988586  C374.883942,246.428802 370.739532,241.869034 366.303040,237.152039  C366.010956,236.994812 365.992798,236.499191 365.976074,236.251785  C368.842682,237.716476 371.880707,239.215942 374.580566,241.180206  C382.937897,247.260574 391.166473,253.517883 399.749268,259.864441  C399.769836,260.497040 399.486145,260.971710 399.091461,261.699158  C397.704834,263.096802 396.429199,264.241638 394.684174,265.720886  C393.470917,266.438934 392.727112,266.822540 391.698608,267.004791  C387.285400,261.531799 383.156860,256.260193 379.028351,250.988586  z"
    />
    <path
      fill="#E0E6EB"
      opacity={1}
      stroke="none"
      d=" M277.502625,106.784470  C282.909912,104.133286 288.476318,101.759048 293.686768,98.765297  C301.300232,94.390854 308.629211,89.523743 316.149139,84.982109  C317.674896,84.060623 319.510406,83.651985 321.575867,82.994316  C303.780090,101.250557 285.731964,119.641655 267.374969,137.719177  C261.294769,143.706802 252.514511,143.257431 246.173477,137.413818  C242.998169,134.487610 240.056046,131.308319 237.020477,127.606308  C240.866882,124.234680 244.701294,121.500237 248.815796,118.914574  C251.350281,121.872162 253.604645,124.680977 257.036316,128.956604  C264.774841,120.573082 271.138733,113.678772 277.502625,106.784470  z"
    />
    <path
      fill="#E7ECEE"
      opacity={1}
      stroke="none"
      d=" M248.535690,118.765793  C244.701294,121.500237 240.866882,124.234680 236.873230,127.328278  C236.363220,127.455757 236.012482,127.224075 235.374313,126.859299  C229.055878,120.553734 223.024857,114.381264 216.834930,107.939835  C220.388107,104.731461 224.100220,101.792038 228.166351,98.665146  C229.623123,99.444359 230.725861,100.411049 231.977325,101.664398  C236.067886,106.000458 240.009705,110.049866 244.087875,114.401375  C245.661377,116.057579 247.098541,117.411690 248.535690,118.765793  z"
    />
    <path
      fill="#C5353A"
      opacity={1}
      stroke="none"
      d=" M248.815796,118.914574  C247.098541,117.411690 245.661377,116.057579 244.244644,114.097122  C247.545898,110.458282 250.748337,107.335342 254.122787,104.410904  C263.006317,96.712059 272.009277,89.150726 280.874664,81.431290  C285.417755,77.475471 289.768188,73.298424 295.167297,68.337852  C291.264313,66.903374 288.759644,65.453568 286.107391,65.094772  C270.064087,62.924549 254.003220,60.859856 237.914917,59.056999  C229.210007,58.081535 220.447617,57.481663 211.694382,57.130947  C207.148407,56.948795 202.565964,57.676826 197.667236,57.994141  C196.890854,57.994774 196.447220,57.994949 195.897491,57.623539  C193.127151,57.311085 190.462891,57.370216 187.798645,57.429344  C186.781296,56.558098 185.763947,55.686848 185.120361,54.449951  C198.594421,54.389694 211.696686,54.629551 224.794067,55.029392  C230.914627,55.216248 237.031937,55.629242 243.139938,56.084385  C260.122955,57.349880 277.123322,58.454422 294.070221,60.102009  C302.586365,60.929951 311.001251,62.799568 319.461914,64.198120  C313.702179,69.903252 307.894043,75.560555 302.194214,81.324974  C294.039093,89.572502 285.963135,97.898323 277.677979,106.487587  C271.138733,113.678772 264.774841,120.573082 257.036316,128.956604  C253.604645,124.680977 251.350281,121.872162 248.815796,118.914574  z"
    />
    <path
      fill="#B73537"
      opacity={1}
      stroke="none"
      d=" M319.677917,63.973587  C311.001251,62.799568 302.586365,60.929951 294.070221,60.102009  C277.123322,58.454422 260.122955,57.349880 243.139938,56.084385  C237.031937,55.629242 230.914627,55.216248 224.794067,55.029392  C211.696686,54.629551 198.594421,54.389694 184.989441,54.128448  C183.592514,53.369896 182.700302,52.567200 182.099747,51.426750  C185.981979,51.057301 189.577225,50.891659 193.162338,51.016590  C210.724091,51.628548 228.282700,52.330639 245.843307,52.976395  C264.567261,53.664928 283.293915,54.282173 302.015503,55.028641  C306.973083,55.226303 311.941315,55.596367 316.856354,56.250294  C319.688690,56.627129 322.424377,57.730824 325.203552,58.507347  C323.433685,60.254589 321.663788,62.001823 319.677917,63.973587  z"
    />
    <path
      fill="#A52E2D"
      opacity={1}
      stroke="none"
      d=" M325.464752,58.281311  C322.424377,57.730824 319.688690,56.627129 316.856354,56.250294  C311.941315,55.596367 306.973083,55.226303 302.015503,55.028641  C283.293915,54.282173 264.567261,53.664928 245.843307,52.976395  C228.282700,52.330639 210.724091,51.628548 193.162338,51.016590  C189.577225,50.891659 185.981979,51.057301 181.963745,51.152977  C180.592987,50.404739 179.649872,49.592525 179.087555,48.432251  C230.244125,49.371025 281.019897,50.657860 331.795654,51.944695  C329.772430,53.981556 327.749207,56.018414 325.464752,58.281311  z"
    />
    <path
      fill="#942E29"
      opacity={1}
      stroke="none"
      d=" M332.082825,51.806892  C281.019897,50.657860 230.244125,49.371025 178.962738,48.129143  C177.451355,47.274765 176.445572,46.375439 175.781387,45.219086  C213.728561,45.307869 251.336319,45.506672 288.938629,46.070019  C304.524811,46.303528 320.099274,47.316879 335.679077,47.975578  C334.576050,49.206753 333.472992,50.437920 332.082825,51.806892  z"
    />
    <path
      fill="#822E23"
      opacity={1}
      stroke="none"
      d=" M335.861023,47.709259  C320.099274,47.316879 304.524811,46.303528 288.938629,46.070019  C251.336319,45.506672 213.728561,45.307869 175.667862,44.949020  C174.273666,44.122704 173.334610,43.309437 172.213043,42.241985  C184.017548,41.992714 196.006104,41.876854 207.991196,42.031387  C225.152710,42.252670 242.311859,42.664097 259.471741,43.008129  C275.511688,43.329708 291.552277,43.629120 307.590698,44.015514  C317.710938,44.259331 327.827728,44.645004 338.392853,44.956245  C337.907440,45.777939 336.975220,46.610435 335.861023,47.709259  z"
    />
    <path
      fill="#EBA664"
      opacity={1}
      stroke="none"
      d=" M278.062317,245.648987  C272.284027,245.321579 266.573761,244.661453 260.497437,243.661880  C262.912262,241.298203 265.794586,239.397629 268.449219,237.219498  C272.054535,234.261322 275.485626,231.090836 278.991455,228.011383  C279.743073,228.008759 280.494720,228.006134 281.622131,228.004257  C285.821472,229.166199 289.645020,230.327393 293.733063,231.746735  C292.993866,233.504822 292.289368,235.398361 290.930634,236.431213  C286.799530,239.571396 282.415710,242.379105 278.062317,245.648987  z"
    />
    <path
      fill="#F0B067"
      opacity={1}
      stroke="none"
      d=" M277.006104,184.993286  C277.343231,186.412628 277.680328,187.831970 278.032288,189.638397  C277.874023,204.391022 270.654510,212.628036 257.042419,213.032379  C254.145966,212.676468 252.078506,212.332886 250.053253,211.648315  C258.586700,202.645645 267.072357,193.978424 275.593048,185.345825  C275.884216,185.050797 276.527130,185.102966 277.006104,184.993286  z"
    />
    <path
      fill="#E69C60"
      opacity={1}
      stroke="none"
      d=" M278.612366,227.998718  C275.485626,231.090836 272.054535,234.261322 268.449219,237.219498  C265.794586,239.397629 262.912262,241.298203 260.065979,243.656143  C259.212372,244.016876 258.424194,244.043915 256.958496,244.049728  C255.897354,243.959229 255.513748,243.889938 255.041443,243.500290  C251.954971,239.821213 248.957153,236.462509 246.159210,232.835770  C247.573166,231.381500 248.787231,230.195267 250.001312,229.009033  C252.701553,229.008331 255.401810,229.007614 258.552246,229.001602  C263.121216,228.107925 267.239960,227.219543 271.703186,226.191757  C274.109528,226.696930 276.171387,227.341492 278.612366,227.998718  z"
    />
    <path
      fill="#DF9A63"
      opacity={1}
      stroke="none"
      d=" M249.693832,228.868103  C248.787231,230.195267 247.573166,231.381500 245.872528,232.663254  C242.935837,230.514221 240.485733,228.269669 237.849548,225.761780  C236.444656,224.324295 235.225861,223.150131 234.072876,221.647705  C236.443390,219.027847 238.950058,216.902008 241.001434,214.402512  C243.533035,211.317902 246.361908,210.388687 250.011032,211.989319  C252.078506,212.332886 254.145966,212.676468 256.621460,213.040878  C253.619751,217.651352 250.210007,222.240982 246.535736,227.186676  C247.260147,227.578156 248.323242,228.152649 249.693832,228.868103  z"
    />
    <path
      fill="#F0B36B"
      opacity={1}
      stroke="none"
      d=" M253.008331,170.038116  C255.372543,167.158981 257.724213,164.269409 260.105408,161.404419  C261.670959,159.520798 263.279175,157.672638 264.904358,155.476593  C266.370789,155.448685 267.801147,155.752762 269.600037,156.066803  C270.137512,156.354828 270.358246,156.582657 270.806152,157.273895  C269.057159,160.289185 267.042969,162.728180 265.239502,165.314148  C264.002991,167.087204 263.059601,169.064667 261.740784,170.961639  C261.496368,170.972504 261.007080,170.989868 260.613770,170.928040  C257.816406,170.590179 255.412369,170.314148 253.008331,170.038116  z"
    />
    <path
      fill="#EDAC69"
      opacity={1}
      stroke="none"
      d=" M261.985199,170.950760  C263.059601,169.064667 264.002991,167.087204 265.239502,165.314148  C267.042969,162.728180 269.057159,160.289185 270.988953,157.428802  C271.738556,157.035233 272.480774,157.000397 273.590637,156.912109  C275.530396,158.375443 277.102448,159.892227 278.546631,161.858246  C274.945801,165.872070 271.472900,169.436646 267.678772,172.856934  C265.566742,172.125351 263.775970,171.538055 261.985199,170.950760  z"
    />
    <path
      fill="#E6A566"
      opacity={1}
      stroke="none"
      d=" M268.000000,173.001221  C271.472900,169.436646 274.945801,165.872070 278.713867,162.152603  C285.838715,165.162888 286.392365,167.294220 281.124207,172.276779  C277.560944,175.646896 274.542694,178.888763 276.992493,184.596924  C276.527130,185.102966 275.884216,185.050797 275.593048,185.345825  C267.072357,193.978424 258.586700,202.645645 250.053253,211.648315  C246.361908,210.388687 243.533035,211.317902 241.001434,214.402512  C238.950058,216.902008 236.443390,219.027847 233.786102,221.477463  C230.890167,218.437454 228.346802,215.239395 226.163940,211.992538  C230.026901,208.961868 233.529312,205.979980 237.399017,203.049149  C239.763672,202.967819 242.517075,203.635056 243.637726,202.575455  C251.948029,194.717941 259.957733,186.540817 268.001801,178.404724  C269.662354,176.725128 270.437592,174.799255 268.000000,173.001221  z"
    />
    <path
      fill="#FBC66E"
      opacity={1}
      stroke="none"
      d=" M144.025726,294.011169  C152.161041,283.355804 159.691803,272.154572 168.645523,262.238159  C174.556107,255.692078 182.512192,250.992905 190.089691,245.084747  C202.081543,239.826233 213.536102,234.949783 224.984375,230.435242  C224.139542,232.539627 223.684219,234.662552 222.396805,235.959442  C216.487579,241.912170 210.281006,247.569107 204.343597,253.494995  C203.195572,254.640808 202.773590,256.514069 201.808929,258.311768  C194.251236,264.388000 186.332504,269.630219 179.715561,276.186279  C173.435226,282.408905 168.517609,290.006866 162.634659,297.008850  C161.377792,297.387634 160.252823,297.528534 159.632401,298.162903  C153.359100,304.577454 147.294983,311.202728 140.871399,317.460144  C139.115616,319.170502 136.350220,319.844452 134.169495,320.640015  C137.535812,311.527740 140.780777,302.769440 144.025726,294.011169  z"
    />
    <path
      fill="#FEEBA6"
      opacity={1}
      stroke="none"
      d=" M143.659515,294.015259  C140.780777,302.769440 137.535812,311.527740 134.086273,321.023529  C132.568817,329.837311 131.255951,337.913544 129.612610,346.064392  C121.725357,354.388702 114.277901,362.743195 106.539444,370.818848  C104.451500,372.997742 101.480927,374.330872 98.626900,375.750854  C98.124008,371.624756 97.909721,367.799988 97.725906,363.566284  C97.761948,355.781067 97.767517,348.404816 97.847748,340.600555  C98.096298,338.444489 98.270195,336.716431 98.444084,334.988403  C100.217293,333.904358 102.277092,333.111023 103.718369,331.690247  C115.225365,320.347015 126.583321,308.852661 138.080765,297.499573  C139.540482,296.058197 141.542511,295.165985 143.659515,294.015259  z"
    />
    <path
      fill="#FEEBA6"
      opacity={1}
      stroke="none"
      d=" M224.990662,230.073334  C213.536102,234.949783 202.081543,239.826233 190.302658,244.824020  C190.977829,243.138336 191.642136,241.005661 193.030609,239.576553  C202.852386,229.467407 212.822739,219.502609 223.134308,209.386993  C224.283081,210.201920 225.043259,211.121628 225.803421,212.041336  C228.346802,215.239395 230.890167,218.437454 233.720306,221.805756  C235.225861,223.150131 236.444656,224.324295 237.742493,226.123352  C233.544601,227.856628 229.267624,228.964996 224.990662,230.073334  z"
    />
    <path
      fill="#F1B871"
      opacity={1}
      stroke="none"
      d=" M63.995777,340.008759  C63.995781,340.008759 63.509174,339.978882 63.266197,339.961792  C61.678040,338.211670 60.332863,336.478638 59.228970,334.253204  C64.648743,329.172821 69.827240,324.584839 75.348053,319.950684  C83.455505,313.106567 91.157242,306.234131 99.016609,299.546967  C102.213814,296.826599 105.753601,294.508850 109.137527,292.007935  C107.899353,295.455048 106.661186,298.902161 104.873833,302.677979  C102.806107,303.914703 101.085846,304.600586 99.801605,305.766663  C91.192085,313.584137 82.613976,321.438751 74.157700,329.421173  C70.604828,332.774963 67.373352,336.469238 63.995777,340.008759  z"
    />
    <path
      fill="#EDAC69"
      opacity={1}
      stroke="none"
      d=" M64.123383,340.273285  C67.373352,336.469238 70.604828,332.774963 74.157700,329.421173  C82.613976,321.438751 91.192085,313.584137 99.801605,305.766663  C101.085846,304.600586 102.806107,303.914703 104.695412,302.992706  C104.055244,306.734253 103.044319,310.489716 101.598465,314.635101  C100.136719,315.494781 98.891228,315.734253 98.116508,316.469635  C89.322426,324.817383 80.577271,333.217316 71.893921,341.680145  C71.007118,342.544403 70.606544,343.907593 69.653015,345.026794  C68.889107,345.002991 68.452530,344.991608 68.015961,344.980194  C67.528297,344.103607 67.040634,343.227051 66.264374,342.230103  C65.400856,341.585754 64.825928,341.061798 64.123383,340.273285  z"
    />
    <path
      fill="#E9A362"
      opacity={1}
      stroke="none"
      d=" M69.980331,345.039246  C70.606544,343.907593 71.007118,342.544403 71.893921,341.680145  C80.577271,333.217316 89.322426,324.817383 98.116508,316.469635  C98.891228,315.734253 100.136719,315.494781 101.515182,315.021423  C101.123085,318.823273 100.379341,322.628723 99.045822,326.764343  C95.669113,329.337769 92.656693,331.355804 90.143227,333.872162  C85.240387,338.780701 80.616699,343.968048 75.532166,349.033203  C74.493240,349.000763 73.799545,348.975037 72.939636,348.681396  C71.842384,347.288757 70.911354,346.164001 69.980331,345.039246  z"
    />
    <path
      fill="#E4985F"
      opacity={1}
      stroke="none"
      d=" M75.877411,349.039917  C80.616699,343.968048 85.240387,338.780701 90.143227,333.872162  C92.656693,331.355804 95.669113,329.337769 98.827415,327.074890  C98.866539,329.437653 98.534279,331.820038 98.323059,334.595428  C98.270195,336.716431 98.096298,338.444489 97.502167,340.603638  C90.844673,344.372162 87.027657,349.825256 83.762695,355.986633  C83.341057,355.993317 83.172394,355.995453 82.845444,355.714264  C81.126465,354.286194 79.565781,353.141388 77.817551,351.747559  C77.045799,350.678986 76.461601,349.859467 75.877411,349.039917  z"
    />
    <path
      fill="#F2C07D"
      opacity={1}
      stroke="none"
      d=" M109.385117,291.777954  C105.753601,294.508850 102.213814,296.826599 99.016609,299.546967  C91.157242,306.234131 83.455505,313.106567 75.393280,319.606995  C81.518188,312.251465 87.973007,305.222809 94.336464,298.112366  C95.724854,296.561005 96.786766,294.717499 97.999046,293.008545  C103.750519,288.010437 109.501999,283.012329 115.614838,278.004272  C113.861710,282.512238 111.747215,287.030090 109.385117,291.777954  z"
    />
    <path
      fill="#F0B067"
      opacity={1}
      stroke="none"
      d=" M226.163940,211.992538  C225.043259,211.121628 224.283081,210.201920 223.289825,209.084198  C222.693253,207.530777 222.329742,206.175369 221.940948,204.469193  C226.040329,200.108994 230.164978,196.099564 234.650528,192.390106  C235.350464,193.787216 235.689484,194.884354 236.028839,196.377289  C236.363373,198.848114 236.697556,200.923111 237.031738,202.998108  C233.529312,205.979980 230.026901,208.961868 226.163940,211.992538  z"
    />
    <path
      fill="#D53A42"
      opacity={1}
      stroke="none"
      d=" M196.003571,57.995125  C196.447220,57.994949 196.890854,57.994774 197.961838,58.261398  C213.896774,60.018806 229.220627,61.363903 244.503906,63.072037  C250.569000,63.749901 256.603790,64.988976 262.537872,66.440582  C266.138611,67.321396 267.149963,69.678757 263.959778,72.520500  C259.997955,76.049637 255.936432,79.467468 251.994888,83.018715  C245.241364,89.103508 238.547501,95.254517 231.828598,101.377731  C230.725861,100.411049 229.623123,99.444359 228.237885,98.345963  C224.951935,95.066132 221.948486,91.917999 219.108307,88.315552  C224.214767,83.425255 229.210678,79.045952 234.072769,74.522774  C235.790253,72.924995 237.180878,70.975853 238.720734,69.187126  C236.554825,68.133835 234.487091,66.533562 232.205643,66.124130  C223.410446,64.545723 214.573792,63.108650 205.697189,62.112576  C201.466263,61.637814 197.110947,62.271481 192.811981,62.403061  C191.900253,61.589966 190.988541,60.776871 190.194519,59.365852  C192.209335,58.510326 194.106445,58.252724 196.003571,57.995125  z"
    />
    <path
      fill="#DD3D46"
      opacity={1}
      stroke="none"
      d=" M192.961029,62.689522  C197.110947,62.271481 201.466263,61.637814 205.697189,62.112576  C214.573792,63.108650 223.410446,64.545723 232.205643,66.124130  C234.487091,66.533562 236.554825,68.133835 238.720734,69.187126  C237.180878,70.975853 235.790253,72.924995 234.072769,74.522774  C229.210678,79.045952 224.214767,83.425255 218.943237,88.014000  C210.113312,79.769836 201.611710,71.372910 192.961029,62.689522  z"
    />
    <path
      fill="#CD383E"
      opacity={1}
      stroke="none"
      d=" M195.897491,57.623535  C194.106445,58.252724 192.209335,58.510326 190.056152,59.091972  C189.216949,58.926651 188.633804,58.437290 187.924652,57.688637  C190.462891,57.370216 193.127151,57.311085 195.897491,57.623535  z"
    />
    <path
      fill="#FDE69F"
      opacity={1}
      stroke="none"
      d=" M185.400574,102.826454  C190.245148,107.269600 194.573517,111.960213 199.070892,116.482765  C201.478683,118.903999 204.177505,121.035835 206.888748,123.604507  C206.070145,124.601921 205.095428,125.903511 204.147415,125.884338  C198.374298,125.767555 192.607254,125.350380 186.415375,125.020485  C179.688477,118.580910 173.384384,112.154282 167.027069,105.392159  C169.864609,104.704552 172.753326,104.334801 175.646622,104.005089  C178.747070,103.651764 181.851822,103.336098 185.400574,102.826454  z"
    />
    <path
      fill="#FCDA8E"
      opacity={1}
      stroke="none"
      d=" M228.404236,145.813232  C232.688507,149.389099 236.526184,153.146027 240.633087,157.115448  C236.797363,160.448257 232.692398,163.568573 228.291260,166.843842  C223.134460,162.014343 217.991638,157.267944 213.514542,151.960007  C210.630569,148.540863 211.611877,147.244080 216.131012,146.979752  C220.078918,146.748840 224.015839,146.330078 228.404236,145.813232  z"
    />
    <path
      fill="#D68D5E"
      opacity={1}
      stroke="none"
      d=" M236.028503,195.981476  C235.689484,194.884354 235.350464,193.787216 234.990311,192.340866  C236.013657,180.960632 241.534454,173.477585 252.647980,170.089935  C255.412369,170.314148 257.816406,170.590179 260.565338,171.270828  C253.497101,179.381287 246.102417,187.105133 238.637253,194.760254  C238.009903,195.403549 236.909256,195.585297 236.028503,195.981476  z"
    />
    <path
      fill="#E49F6B"
      opacity={1}
      stroke="none"
      d=" M275.424255,478.999664  C267.483765,479.444763 259.927856,480.581238 252.461502,480.153748  C243.447784,479.637634 234.509979,477.795502 225.562637,476.000488  C227.047867,475.334564 228.519363,475.010437 229.970200,475.085480  C241.478149,475.680725 252.979691,476.405792 264.490112,476.941864  C266.225037,477.022644 267.995972,476.330566 271.218323,475.716003  C267.711426,472.423431 265.355713,470.211731 262.850647,467.715210  C261.463318,465.957458 260.225372,464.484497 259.407684,462.992371  C265.883484,462.312927 271.939026,461.652649 278.008087,461.358368  C279.205353,462.909943 280.184448,464.520599 281.608856,465.206512  C287.335602,467.964050 293.200104,470.435577 299.013702,473.012787  C291.291962,475.007904 283.570251,477.002991 275.424255,478.999664  z"
    />
    <path
      fill="#E6A56E"
      opacity={1}
      stroke="none"
      d=" M299.387085,473.009308  C293.200104,470.435577 287.335602,467.964050 281.608856,465.206512  C280.184448,464.520599 279.205353,462.909943 278.192108,461.130920  C278.907990,460.356689 279.453369,460.175903 280.386963,459.941101  C285.513947,458.587738 290.252777,457.288391 295.043365,456.338318  C296.415161,457.801239 297.593414,459.178131 299.081635,459.979401  C303.660553,462.444702 308.358032,464.689819 313.009430,467.020508  C309.489166,468.832489 305.968933,470.644470 302.225433,472.721741  C301.254944,472.993256 300.507690,472.999542 299.387085,473.009308  z"
    />
    <path
      fill="#F5C57E"
      opacity={1}
      stroke="none"
      d=" M122.250359,437.781250  C119.641449,434.258881 117.314903,430.463043 114.875717,426.320282  C114.763069,425.973328 114.909920,425.977417 115.432343,425.990173  C124.249420,425.023834 132.556595,424.138977 140.835175,423.038940  C148.963455,421.958862 149.484726,420.701172 145.397293,413.944580  C151.526962,414.254517 157.262909,414.624817 163.378906,415.006653  C166.820328,416.008484 170.082367,418.231537 172.907776,417.771729  C184.042648,415.959534 195.040359,413.304443 206.335480,411.077209  C207.231659,411.484375 207.881332,411.776428 208.614624,412.420471  C210.089767,413.962524 211.481323,415.152618 212.943451,416.664093  C214.057770,418.646393 215.101547,420.307312 215.751556,422.026123  C205.905777,424.059265 196.453751,426.034515 187.001709,428.009766  C184.174576,428.769501 180.964249,428.884552 180.721634,433.117554  C180.685760,433.743225 177.960464,435.012299 176.926987,434.662262  C163.717621,430.188416 150.760635,433.227661 137.735229,435.538635  C132.709473,436.430298 127.602615,436.864929 122.250359,437.781250  z"
    />
    <path
      fill="#F1B871"
      opacity={1}
      stroke="none"
      d=" M186.924210,428.361969  C196.453751,426.034515 205.905777,424.059265 216.077362,422.080566  C220.530045,425.721313 224.263199,429.365479 227.806519,433.325256  C223.210556,434.761536 218.809845,435.903961 214.397079,436.997833  C207.603088,438.682007 200.800827,440.332794 194.002090,441.997772  C191.496277,440.241791 188.674286,438.795685 186.568756,436.647400  C184.208069,434.238770 184.561951,431.288300 186.924210,428.361969  z"
    />
    <path
      fill="#F0B36B"
      opacity={1}
      stroke="none"
      d=" M194.166473,442.293091  C200.800827,440.332794 207.603088,438.682007 214.397079,436.997833  C218.809845,435.903961 223.210556,434.761536 228.102722,433.478882  C232.054214,436.883148 235.519623,440.449371 238.929230,444.381378  C237.142197,445.787262 235.523376,447.384277 233.658234,447.760681  C228.540604,448.793365 223.282425,449.125305 218.159119,450.136261  C216.322052,450.498718 214.711609,452.009613 212.997742,452.996399  C210.251602,452.002625 207.505463,451.008850 204.386353,450.005280  C200.785873,447.526428 197.558365,445.057404 194.166473,442.293091  z"
    />
    <path
      fill="#EAAD73"
      opacity={1}
      stroke="none"
      d=" M258.987427,463.011536  C260.225372,464.484497 261.463318,465.957458 262.642456,468.048645  C260.894745,469.110992 259.195923,469.964172 257.518768,469.923584  C252.242722,469.795807 246.972458,469.364868 241.704239,468.991089  C233.314240,468.395874 224.934280,467.630676 216.537216,467.173767  C211.369812,466.892639 206.178482,467.051208 200.997894,467.012329  C199.231674,466.371185 197.465439,465.730042 195.347992,465.040497  C192.440979,463.191620 189.885147,461.391174 187.164658,459.295959  C188.732086,458.692230 190.483063,458.038055 192.193146,458.131531  C201.758240,458.654480 211.310516,459.409576 220.874680,459.953522  C222.648453,460.054413 224.456482,459.552948 227.045578,459.164856  C236.902405,460.321411 245.962341,461.642517 255.418549,462.970367  C256.872375,462.988586 257.929901,463.000061 258.987427,463.011536  z"
    />
    <path
      fill="#EBA664"
      opacity={1}
      stroke="none"
      d=" M255.022278,462.963623  C245.962341,461.642517 236.902405,460.321411 227.427689,458.996979  C222.503342,457.164459 217.993759,455.335266 213.240967,453.251221  C214.711609,452.009613 216.322052,450.498718 218.159119,450.136261  C223.282425,449.125305 228.540604,448.793365 233.658234,447.760681  C235.523376,447.384277 237.142197,445.787262 239.227417,444.529449  C243.962494,448.618805 248.485779,452.794952 252.640900,457.309937  C253.943970,458.725830 254.256393,461.053375 255.022278,462.963623  z"
    />
    <path
      fill="#E6A56E"
      opacity={1}
      stroke="none"
      d=" M201.217545,467.275787  C206.178482,467.051208 211.369812,466.892639 216.537216,467.173767  C224.934280,467.630676 233.314240,468.395874 241.704239,468.991089  C246.972458,469.364868 252.242722,469.795807 257.518768,469.923584  C259.195923,469.964172 260.894745,469.110992 262.791809,468.333435  C265.355713,470.211731 267.711426,472.423431 271.218323,475.716003  C267.995972,476.330566 266.225037,477.022644 264.490112,476.941864  C252.979691,476.405792 241.478149,475.680725 229.970200,475.085480  C228.519363,475.010437 227.047867,475.334564 225.290863,475.741455  C222.929047,475.673492 220.862320,475.337860 218.397629,474.999084  C212.478851,472.510437 206.958023,470.024841 201.217545,467.275787  z"
    />
    <path
      fill="#F5C074"
      opacity={1}
      stroke="none"
      d=" M97.657120,293.052582  C96.786766,294.717499 95.724854,296.561005 94.336464,298.112366  C87.973007,305.222809 81.518188,312.251465 75.050957,319.653168  C69.827240,324.584839 64.648743,329.172821 59.155365,333.925690  C58.084991,333.803375 57.329506,333.516144 56.294460,333.097778  C55.431702,332.157257 54.848499,331.347870 54.127613,330.271179  C63.255806,322.043182 72.504280,314.062134 81.794418,306.129913  C86.931984,301.743286 92.139320,297.438385 97.657120,293.052582  z"
    />
    <path
      fill="#FBCF7D"
      opacity={1}
      stroke="none"
      d=" M69.653008,345.026794  C70.911354,346.164001 71.842384,347.288757 72.654190,348.966248  C71.696915,353.527954 70.796059,357.525024 70.032936,361.548187  C67.979187,372.375549 65.984230,383.214081 63.965919,394.048157  C61.216789,392.396606 58.467659,390.745056 55.378387,389.043152  C54.898529,388.688324 54.696911,388.432709 54.222210,388.099915  C54.583351,386.609406 54.812763,384.901093 55.781918,383.934387  C64.034622,375.702240 65.233513,364.868439 66.973984,354.230194  C67.427803,351.456299 67.667641,348.647430 68.010849,345.417419  C68.452530,344.991608 68.889107,345.002991 69.653008,345.026794  z"
    />
    <path
      fill="#F5C074"
      opacity={1}
      stroke="none"
      d=" M63.982300,394.275146  C65.984230,383.214081 67.979187,372.375549 70.032936,361.548187  C70.796059,357.525024 71.696915,353.527954 72.820404,349.234131  C73.799545,348.975037 74.493240,349.000763 75.532166,349.033203  C76.461601,349.859467 77.045799,350.678986 77.756165,352.145660  C75.265785,367.835541 72.649239,382.878326 70.032700,397.921082  C68.256706,396.967133 66.480713,396.013214 64.366928,395.007751  C64.029137,394.956268 63.998669,394.502106 63.982300,394.275146  z"
    />
    <path
      fill="#F0B36B"
      opacity={1}
      stroke="none"
      d=" M70.233856,398.057312  C72.649239,382.878326 75.265785,367.835541 77.943710,352.394714  C79.565781,353.141388 81.126465,354.286194 82.844254,356.152039  C82.943985,358.191650 82.941948,359.515259 82.820709,360.827911  C81.552261,374.561462 80.264717,388.293213 78.982468,402.025482  C78.177444,401.774658 77.372421,401.523804 76.268433,401.159607  C74.542587,400.384216 73.115707,399.722198 71.354080,399.009613  C70.885757,398.657166 70.690979,398.401978 70.233856,398.057312  z"
    />
    <path
      fill="#FDDD92"
      opacity={1}
      stroke="none"
      d=" M110.001747,415.000610  C110.001747,415.000641 109.540306,414.894531 109.312981,414.829102  C108.922874,413.942200 108.760094,413.120758 108.334641,411.703918  C107.435631,409.062988 106.799286,407.017487 106.539192,404.914001  C108.036247,404.573761 109.159416,404.300568 110.277451,404.007782  C119.187843,401.674194 128.097260,399.336914 137.260895,397.238464  C139.337708,401.647919 141.160583,405.819702 142.595123,410.051270  C131.471771,411.740906 120.736755,413.370758 110.001747,415.000610  z"
    />
    <path
      fill="#E1925C"
      opacity={1}
      stroke="none"
      d=" M106.162933,404.971954  C106.799286,407.017487 107.435631,409.062988 108.123993,411.461853  C105.692558,411.577667 103.209106,411.340149 100.357773,411.057434  C98.534698,410.430878 97.079514,409.849609 95.313255,408.661255  C94.997780,393.038544 94.993370,378.022919 95.258095,363.121704  C96.249962,363.482513 96.972694,363.728882 97.695435,363.975220  C97.909721,367.799988 98.124008,371.624756 98.664001,376.172028  C101.380768,386.253693 103.771851,395.612823 106.162933,404.971954  z"
    />
    <path
      fill="#E69C60"
      opacity={1}
      stroke="none"
      d=" M94.988960,363.007263  C94.993370,378.022919 94.997780,393.038544 95.006165,408.521606  C94.564636,408.991730 94.119133,408.994446 93.339714,408.996765  C91.483139,408.161835 89.960480,407.327362 88.217484,405.780792  C88.171722,390.041016 86.337303,374.968506 89.339859,360.041077  C91.447891,361.059631 93.218430,362.033447 94.988960,363.007263  z"
    />
    <path
      fill="#EBA664"
      opacity={1}
      stroke="none"
      d=" M89.002365,359.996368  C86.337303,374.968506 88.171722,390.041016 87.995346,405.538025  C87.824089,406.008667 87.654625,406.010040 87.230835,406.020142  C85.216652,405.381714 83.456795,404.734589 81.343994,404.053772  C80.411888,403.525665 79.832726,403.031189 79.118011,402.281097  C80.264717,388.293213 81.552261,374.561462 82.820709,360.827911  C82.941948,359.515259 82.943985,358.191650 83.002548,356.435333  C83.172394,355.995453 83.341057,355.993317 84.048325,356.141663  C86.058746,357.526855 87.530556,358.761627 89.002365,359.996368  z"
    />
    <path
      fill="#F4CF8F"
      opacity={1}
      stroke="none"
      d=" M110.007881,415.369202  C120.736755,413.370758 131.471771,411.740906 142.730759,410.342621  C143.837677,411.717743 144.420639,412.861298 145.003601,414.004883  C149.484726,420.701172 148.963455,421.958862 140.835175,423.038940  C132.556595,424.138977 124.249420,425.023834 115.503723,426.007507  C113.373123,422.587311 111.693573,419.162537 110.007881,415.369202  z"
    />
    <path
      fill="#EAAD73"
      opacity={1}
      stroke="none"
      d=" M313.365601,466.967010  C308.358032,464.689819 303.660553,462.444702 299.081635,459.979401  C297.593414,459.178131 296.415161,457.801239 295.276276,456.091766  C297.639832,454.332153 299.822205,453.168457 302.373383,451.994171  C305.163879,450.990204 307.585602,449.996796 310.053772,449.350952  C314.360107,455.316193 320.688354,457.178680 327.027283,459.021820  C322.592102,461.652374 318.156921,464.282928 313.365601,466.967010  z"
    />
    <path
      fill="#F0BA79"
      opacity={1}
      stroke="none"
      d=" M327.354919,459.011230  C320.688354,457.178680 314.360107,455.316193 310.351166,449.192200  C313.069153,446.791931 315.536163,444.897919 318.344666,442.961060  C319.781342,442.600555 320.876587,442.282898 322.131989,442.265747  C326.158844,447.948425 332.275391,449.142853 338.025543,451.019226  C335.121948,453.470795 332.218353,455.922363 329.154297,458.665405  C328.556763,458.971466 328.119659,458.986084 327.354919,459.011230  z"
    />
    <path
      fill="#F5B969"
      opacity={1}
      stroke="none"
      d=" M399.197449,425.691437  C398.467224,427.149200 397.863892,428.284302 397.114929,429.705627  C384.617676,429.992523 372.266022,429.993195 359.583069,429.696533  C360.148926,427.924988 361.046051,426.450745 362.099701,424.673096  C363.882904,421.610016 365.509583,418.850342 367.376404,415.858917  C368.765747,413.428101 369.914948,411.228973 371.277222,409.012512  C371.490265,408.995117 371.915894,408.954773 372.389679,408.967468  C383.932129,408.999023 395.000793,409.017944 406.054626,409.398621  C405.994965,410.481598 405.950104,411.202759 405.905273,411.923920  C405.599060,412.719971 405.292816,413.516022 404.973694,414.644226  C403.081970,418.440521 401.203125,421.904633 399.197449,425.691437  z"
    />
    <path
      fill="#F4CF8F"
      opacity={1}
      stroke="none"
      d=" M361.943176,424.976501  C361.046051,426.450745 360.148926,427.924988 359.112762,429.693420  C354.886322,434.306244 350.798920,438.624878 346.160400,442.734558  C341.027344,439.672913 336.445435,436.820282 331.906708,433.651672  C331.985840,432.911896 332.021759,432.488037 332.343445,431.896149  C336.092407,427.826477 339.555542,423.924866 343.301147,419.861816  C345.007019,418.127502 346.430481,416.554626 348.163330,415.134705  C352.962891,418.517303 357.453033,421.746918 361.943176,424.976501  z"
    />
    <path
      fill="#F0C284"
      opacity={1}
      stroke="none"
      d=" M331.863525,433.967651  C336.445435,436.820282 341.027344,439.672913 345.822357,442.802185  C345.116882,444.516388 344.198303,445.953979 343.119690,447.676910  C341.506042,448.875153 340.052368,449.788025 338.312134,450.860046  C332.275391,449.142853 326.158844,447.948425 322.298859,441.975525  C325.491547,438.912354 328.677551,436.440002 331.863525,433.967651  z"
    />
    <path
      fill="#F6CA82"
      opacity={1}
      stroke="none"
      d=" M391.983276,267.206177  C392.727112,266.822540 393.470917,266.438934 394.520447,266.006836  C395.967346,266.558655 397.265839,266.974335 398.223297,267.790222  C403.089874,271.937164 407.868103,276.187775 412.883118,281.037201  C414.058929,282.447815 415.028839,283.223297 415.998779,283.998779  C416.127502,284.302948 416.322479,284.556061 416.793060,284.879089  C422.230865,290.136993 427.459290,295.274017 432.843872,300.705505  C433.968903,303.101562 434.937805,305.203156 435.610809,307.606384  C425.918396,299.813660 416.568115,291.664520 407.097321,283.657959  C404.233032,281.236511 401.034912,279.209900 397.721497,276.865936  C396.636353,276.151306 395.819397,275.573151 394.993286,274.629639  C393.983856,271.911560 392.983551,269.558868 391.983276,267.206177  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M415.954224,283.663239  C415.028839,283.223297 414.058929,282.447815 413.044495,281.336792  C413.969910,281.776703 414.939789,282.552216 415.954224,283.663239  z"
    />
    <path
      fill="#F5C57E"
      opacity={1}
      stroke="none"
      d=" M397.989716,277.002441  C401.034912,279.209900 404.233032,281.236511 407.097321,283.657959  C416.568115,291.664520 425.918396,299.813660 435.658081,307.952820  C441.072968,312.704193 446.206451,317.346649 451.191803,322.143127  C453.902039,324.750671 456.360352,327.620026 458.988464,330.720459  C459.044647,331.069641 459.012756,331.523529 458.999268,331.750397  C457.842316,332.888580 456.698853,333.799896 455.225952,334.494507  C454.474548,333.580322 454.190033,332.730408 453.610382,332.207947  C440.836823,320.695312 428.111023,309.127167 415.180176,297.793152  C411.432800,294.508514 407.079651,291.915070 402.856659,288.686951  C401.136017,284.580261 399.562866,280.791351 397.989716,277.002441  z"
    />
    <path
      fill="#F1B871"
      opacity={1}
      stroke="none"
      d=" M403.004181,289.004761  C407.079651,291.915070 411.432800,294.508514 415.180176,297.793152  C428.111023,309.127167 440.836823,320.695312 453.610382,332.207947  C454.190033,332.730408 454.474548,333.580322 454.936707,334.617432  C453.739075,336.442535 452.501251,337.927979 451.102051,339.697327  C450.940704,339.981201 450.461761,339.970764 450.179535,339.628845  C449.020813,338.113983 448.302155,336.769714 447.243591,335.794434  C435.481781,324.957733 423.683533,314.160217 411.819275,303.436127  C411.228516,302.902222 409.940582,303.139679 408.724884,302.772614  C406.649750,298.019745 404.826965,293.512268 403.004181,289.004761  z"
    />
    <path
      fill="#EDAC69"
      opacity={1}
      stroke="none"
      d=" M408.977264,303.017944  C409.940582,303.139679 411.228516,302.902222 411.819275,303.436127  C423.683533,314.160217 435.481781,324.957733 447.243591,335.794434  C448.302155,336.769714 449.020813,338.113983 449.940063,339.625122  C449.491394,340.557129 449.000000,341.150940 448.262268,341.904449  C447.103119,342.860840 446.190247,343.657501 444.883911,344.719971  C444.321533,344.976013 444.152649,344.966309 443.942017,344.619141  C443.699402,343.829346 443.610779,343.261505 443.281403,342.941498  C434.100220,334.022766 424.924316,325.097931 415.643036,316.284149  C414.798523,315.482178 413.251190,315.420288 411.987183,314.694946  C411.620911,313.580963 411.297729,312.786743 410.929230,311.617493  C410.248383,308.500946 409.612823,305.759430 408.977264,303.017944  z"
    />
    <path
      fill="#FBCF7D"
      opacity={1}
      stroke="none"
      d=" M443.983765,344.956573  C444.152649,344.966309 444.321533,344.976013 444.744019,344.994263  C446.209686,351.774902 447.530304,358.529816 448.606201,365.323456  C449.925781,373.655914 455.078278,379.952576 459.826172,386.840698  C459.808807,387.523651 459.515228,387.995239 459.100098,388.716064  C456.220825,390.618713 453.463104,392.272156 450.362366,393.987366  C449.852325,394.041901 449.685333,394.034698 449.262634,393.590179  C446.337402,378.101837 443.667877,363.050781 441.177673,347.751160  C442.232574,346.653900 443.108185,345.805237 443.983765,344.956573  z"
    />
    <path
      fill="#F0B36B"
      opacity={1}
      stroke="none"
      d=" M438.334839,400.900269  C437.521484,401.288757 437.011047,401.528107 436.074402,401.489288  C434.096375,386.138702 432.544525,371.066315 431.152496,355.711609  C432.874481,354.286224 434.436646,353.143127 436.013916,352.413696  C436.348114,354.439117 436.699432,356.045227 436.981049,357.663483  C439.316986,371.087097 441.640015,384.512909 443.966919,397.938080  C443.667145,398.077087 443.418671,398.279175 443.101898,398.754517  C441.534119,399.560181 440.085968,400.155640 438.334839,400.900269  z"
    />
    <path
      fill="#F5C074"
      opacity={1}
      stroke="none"
      d=" M444.265656,397.809937  C441.640015,384.512909 439.316986,371.087097 436.981049,357.663483  C436.699432,356.045227 436.348114,354.439117 436.201416,352.165710  C436.956970,350.687897 437.540222,349.871765 438.429596,349.014618  C439.489929,348.648987 440.244171,348.324371 440.998383,347.999756  C443.667877,363.050781 446.337402,378.101837 449.011902,393.588318  C447.532745,395.243103 446.048584,396.462433 444.265656,397.809937  z"
    />
    <path
      fill="#E1925C"
      opacity={1}
      stroke="none"
      d=" M406.152130,412.180359  C405.950104,411.202759 405.994965,410.481598 406.298523,409.138916  C411.756714,394.802368 415.000092,380.649841 416.443237,366.127747  C417.214905,367.920502 417.934937,369.592865 417.947845,371.270721  C418.044525,383.847961 418.001617,396.426270 418.002441,409.004211  C416.870300,409.582672 415.738190,410.161102 414.308136,410.875946  C411.473114,411.487152 408.936066,411.961975 406.152130,412.180359  z"
    />
    <path
      fill="#E4985F"
      opacity={1}
      stroke="none"
      d=" M418.319763,408.969421  C418.001617,396.426270 418.044525,383.847961 417.947845,371.270721  C417.934937,369.592865 417.214905,367.920502 416.428162,365.786530  C416.056976,364.873108 416.079956,364.418701 416.429260,363.930725  C418.171997,363.268707 419.588440,362.640259 421.019287,362.433075  C421.355804,365.798370 421.930359,368.740204 421.955872,371.686829  C422.060638,383.787231 421.998383,395.889069 421.997559,407.990387  C421.997559,407.990387 421.503052,408.000092 421.255920,408.004242  C420.218231,408.317108 419.427673,408.625854 418.319763,408.969421  z"
    />
    <path
      fill="#EBA664"
      opacity={1}
      stroke="none"
      d=" M430.992676,355.993896  C432.544525,371.066315 434.096375,386.138702 435.811157,401.591217  C435.101654,402.481384 434.229248,402.991455 433.171814,403.740295  C431.572815,404.318604 430.158813,404.658142 428.373657,404.538086  C428.001221,399.263397 428.205170,394.437714 427.956360,389.635468  C427.426819,379.414734 426.662048,369.206207 426.185913,358.746460  C427.579926,357.660034 428.781738,356.819855 430.235809,355.983765  C430.656250,355.989868 430.824463,355.991882 430.992676,355.993896  z"
    />
    <path
      fill="#E69C60"
      opacity={1}
      stroke="none"
      d=" M425.993683,358.992676  C426.662048,369.206207 427.426819,379.414734 427.956360,389.635468  C428.205170,394.437714 428.001221,399.263397 428.006805,404.549347  C427.880676,405.321136 427.685852,405.572113 427.219269,405.894470  C425.565704,406.649780 424.119629,407.283691 422.335571,407.953979  C421.998383,395.889069 422.060638,383.787231 421.955872,371.686829  C421.930359,368.740204 421.355804,365.798370 421.305511,362.281189  C423.049438,360.802917 424.521545,359.897797 425.993683,358.992676  z"
    />
    <path
      fill="#FBD282"
      opacity={1}
      stroke="none"
      d=" M391.698608,267.004791  C392.983551,269.558868 393.983856,271.911560 394.837891,274.924164  C387.128967,283.056061 379.566315,290.528015 371.739502,297.784851  C367.383575,290.937958 363.657532,284.042786 359.114410,277.736237  C352.282715,268.252838 352.039459,268.428070 342.507294,274.994141  C338.101379,278.029083 338.212433,279.468536 341.111542,283.746429  C346.013397,290.979523 350.400421,298.561584 354.653076,306.042358  C346.831329,313.148895 339.360382,320.210022 331.662689,327.097321  C331.057648,326.502167 330.679291,326.080841 330.226318,325.322510  C327.293427,318.157684 320.762024,318.372467 314.660828,316.914337  C311.219025,316.114197 308.128357,315.488831 304.825531,314.650085  C303.733643,314.008545 302.853882,313.580414 302.019165,312.800415  C305.027679,309.146698 307.895966,305.752167 310.988434,302.575867  C313.015686,300.493622 313.405945,299.120117 310.768677,297.054443  C307.285492,294.326172 304.236725,291.043213 301.001221,287.998718  C306.233398,285.532501 311.296814,282.573273 316.735870,280.711456  C325.689148,277.646759 331.510895,270.313965 339.093262,265.431396  C340.339325,264.629059 341.708588,263.247131 342.946899,263.324066  C354.099335,264.016632 361.844849,255.725098 371.566162,252.739334  C373.752655,252.067764 375.993347,251.572708 378.618713,250.992493  C383.156860,256.260193 387.285400,261.531799 391.698608,267.004791  z"
    />
    <path
      fill="#FBD689"
      opacity={1}
      stroke="none"
      d=" M300.712067,287.846893  C304.236725,291.043213 307.285492,294.326172 310.768677,297.054443  C313.405945,299.120117 313.015686,300.493622 310.988434,302.575867  C307.895966,305.752167 305.027679,309.146698 301.656494,312.762299  C300.223724,312.604980 298.960052,312.372528 298.212067,311.624451  C293.069366,306.481110 288.034485,301.229889 282.964935,296.013397  C286.971252,291.918457 290.977600,287.823517 294.991943,283.364899  C296.807648,284.565857 298.615265,286.130463 300.712067,287.846893  z"
    />
    <path
      fill="#FDE69F"
      opacity={1}
      stroke="none"
      d=" M255.130127,243.820663  C255.513748,243.889938 255.897354,243.959229 256.565948,244.087433  C259.704956,247.351959 262.558990,250.557587 265.695251,253.951385  C264.850769,255.727386 263.921387,257.511505 262.567200,258.873199  C246.005585,275.526794 229.370560,292.107391 212.794296,308.746429  C211.413315,310.132629 210.319839,311.805237 208.776672,313.936218  C203.345581,315.742188 198.218781,316.909454 193.119232,318.185669  C188.822403,319.261017 184.856537,320.821136 183.632248,326.172485  C183.226654,327.619354 183.053833,328.795044 182.665161,330.230835  C183.969299,333.987366 185.489288,337.483795 186.867676,341.288940  C186.121338,342.582581 185.679443,343.728973 184.887085,344.527771  C174.967346,354.528442 165.050064,364.533386 154.964233,374.364990  C153.849548,375.451599 151.697174,375.473724 150.005615,375.576324  C149.332687,367.773773 148.680206,360.384430 148.388718,352.988525  C150.377396,351.943665 152.251709,351.154907 153.594086,349.827972  C165.284698,338.272064 176.880020,326.619781 188.503677,314.996124  C209.693695,293.806091 230.844666,272.576660 252.129227,251.482025  C254.468307,249.163818 255.319748,247.030396 254.281723,244.013565  C254.755142,243.916122 254.942642,243.868393 255.130127,243.820663  z"
    />
    <path
      fill="#FBC56E"
      opacity={1}
      stroke="none"
      d=" M183.865036,325.901337  C184.856537,320.821136 188.822403,319.261017 193.119232,318.185669  C198.218781,316.909454 203.345581,315.742188 208.735443,314.280579  C213.109344,313.127289 217.208405,312.221100 221.659943,311.586334  C222.400101,313.605560 222.679565,315.386322 223.200073,317.093658  C224.049637,319.880432 225.205002,322.579163 225.964157,325.387268  C227.315887,330.387360 223.893341,333.995361 221.599976,338.019958  C218.464539,337.339966 215.724960,336.676483 212.728973,335.753357  C207.649048,334.000519 202.825546,332.507324 197.750839,330.751648  C192.954758,328.959839 188.409897,327.430573 183.865036,325.901337  z"
    />
    <path
      fill="#FBC56E"
      opacity={1}
      stroke="none"
      d=" M226.966003,309.920868  C233.577271,300.399811 240.204666,290.889893 246.791183,281.351715  C249.116287,277.984680 251.625351,274.964661 256.599670,275.415161  C257.003357,281.262726 257.010864,286.637207 257.013733,292.459961  C256.992401,295.598450 256.975739,298.288696 256.597778,300.992584  C253.568726,303.093048 250.810196,305.075073 248.252747,307.289307  C243.437698,311.458160 238.738602,315.760956 233.709900,319.852692  C232.286774,318.457642 231.145035,317.217987 229.957245,315.636810  C228.929443,313.503815 227.947723,311.712341 226.966003,309.920868  z"
    />
    <path
      fill="#F0B36B"
      opacity={1}
      stroke="none"
      d=" M257.018372,292.011658  C257.010864,286.637207 257.003357,281.262726 256.987427,275.414001  C260.123871,276.519562 263.268768,278.099365 266.715363,279.836670  C267.591858,280.758942 268.166687,281.523743 268.980713,282.799774  C269.496704,283.538330 269.773438,283.765656 270.050140,283.992981  C270.050140,283.992981 269.996033,284.002899 270.117249,284.202209  C270.508636,284.597504 270.778778,284.793518 271.048950,284.989502  C271.048950,284.989502 270.994629,284.985626 271.002747,285.338806  C274.009247,290.794006 277.007629,295.896057 279.831818,301.298096  C278.208374,301.779205 276.508698,302.532837 275.358795,302.029816  C272.378784,300.726105 269.664124,298.826874 266.800201,297.243835  C263.564270,295.455139 260.281036,293.752014 257.018372,292.011658  z"
    />
    <path
      fill="#F0B36B"
      opacity={1}
      stroke="none"
      d=" M226.602722,309.950195  C227.947723,311.712341 228.929443,313.503815 229.955688,316.075500  C230.998962,325.231903 231.997742,333.608063 232.663605,341.994507  C231.886673,342.010590 231.442627,342.016357 230.755081,341.757385  C227.669785,340.666321 224.827972,339.840027 221.987183,338.769409  C221.988205,338.525085 221.995819,338.036499 221.995819,338.036499  C223.893341,333.995361 227.315887,330.387360 225.964157,325.387268  C225.205002,322.579163 224.049637,319.880432 223.200073,317.093658  C222.679565,315.386322 222.400101,313.605560 222.019196,311.465637  C223.430450,310.708801 224.834946,310.344147 226.602722,309.950195  z"
    />
    <path
      fill="#DF9A63"
      opacity={1}
      stroke="none"
      d=" M280.006042,300.998108  C277.007629,295.896057 274.009247,290.794006 271.022125,285.319611  C274.817902,288.529694 278.602386,292.112122 282.675903,295.853973  C288.034485,301.229889 293.069366,306.481110 298.212067,311.624451  C298.960052,312.372528 300.223724,312.604980 301.611450,313.114136  C302.853882,313.580414 303.733643,314.008545 304.773621,315.006042  C301.024719,319.809174 297.194946,324.121368 293.145050,328.215881  C292.248749,329.122040 290.102448,330.025604 289.367218,329.573059  C288.214874,328.863770 287.117981,326.978119 287.161499,325.628204  C287.266724,322.362396 288.282440,319.130371 288.448242,315.860229  C288.528778,314.272491 287.525635,312.629791 286.962646,310.665405  C284.610138,307.211884 282.308105,304.105011 280.006042,300.998108  z"
    />
    <path
      fill="#DF9A63"
      opacity={1}
      stroke="none"
      d=" M269.934113,283.750244  C269.773438,283.765656 269.496704,283.538330 269.113647,283.056702  C269.277588,283.037476 269.547821,283.272491 269.934113,283.750244  z"
    />
    <path
      fill="#DF9A63"
      opacity={1}
      stroke="none"
      d=" M270.939880,284.758789  C270.778778,284.793518 270.508636,284.597504 270.123230,284.175354  C270.282227,284.142120 270.556519,284.335083 270.939880,284.758789  z"
    />
    <path
      fill="#CD383E"
      opacity={1}
      stroke="none"
      d=" M231.977325,101.664398  C238.547501,95.254517 245.241364,89.103508 251.994888,83.018715  C255.936432,79.467468 259.997955,76.049637 263.959778,72.520500  C267.149963,69.678757 266.138611,67.321396 262.537872,66.440582  C256.603790,64.988976 250.569000,63.749901 244.503906,63.072037  C229.220627,61.363903 213.896774,60.018806 198.294586,58.260944  C202.565964,57.676826 207.148407,56.948795 211.694382,57.130947  C220.447617,57.481663 229.210007,58.081535 237.914917,59.056999  C254.003220,60.859856 270.064087,62.924549 286.107391,65.094772  C288.759644,65.453568 291.264313,66.903374 295.167297,68.337852  C289.768188,73.298424 285.417755,77.475471 280.874664,81.431290  C272.009277,89.150726 263.006317,96.712059 254.122787,104.410904  C250.748337,107.335342 247.545898,110.458282 244.108292,113.795013  C240.009705,110.049866 236.067886,106.000458 231.977325,101.664398  z"
    />
    <path
      fill="#FBC974"
      opacity={1}
      stroke="none"
      d=" M255.041443,243.500290  C254.942642,243.868393 254.755142,243.916122 253.846939,243.994965  C248.741165,244.654343 244.356110,245.282608 239.544403,245.927277  C233.743103,247.282211 228.368454,248.620728 222.608200,249.975174  C215.487305,252.678009 208.752014,255.364914 202.016708,258.051819  C202.773590,256.514069 203.195572,254.640808 204.343597,253.494995  C210.281006,247.569107 216.487579,241.912170 222.396805,235.959442  C223.684219,234.662552 224.139542,232.539627 224.984375,230.435242  C229.267624,228.964996 233.544601,227.856628 237.928604,226.386688  C240.485733,228.269669 242.935837,230.514221 245.672638,232.931290  C248.957153,236.462509 251.954971,239.821213 255.041443,243.500290  z"
    />
    <path
      fill="#DF9A63"
      opacity={1}
      stroke="none"
      d=" M236.028839,196.377289  C236.909256,195.585297 238.009903,195.403549 238.637253,194.760254  C246.102417,187.105133 253.497101,179.381287 260.958679,171.332672  C261.007080,170.989868 261.496368,170.972504 261.740784,170.961639  C263.775970,171.538055 265.566742,172.125351 267.678772,172.856934  C270.437592,174.799255 269.662354,176.725128 268.001801,178.404724  C259.957733,186.540817 251.948029,194.717941 243.637726,202.575455  C242.517075,203.635056 239.763672,202.967819 237.399017,203.049149  C236.697556,200.923111 236.363373,198.848114 236.028839,196.377289  z"
    />
    <path
      fill="#FEEDA9"
      opacity={1}
      stroke="none"
      d=" M201.808960,258.311768  C208.752014,255.364914 215.487305,252.678009 222.602509,250.343246  C222.309860,251.974274 221.900146,253.514725 220.926086,254.493576  C200.154648,275.366974 179.342682,296.200409 158.421371,316.923248  C157.499039,317.836823 155.464935,317.627960 153.970398,317.563843  C156.994980,310.457184 159.997864,303.728607 163.000748,297.000000  C168.517609,290.006866 173.435226,282.408905 179.715561,276.186279  C186.332504,269.630219 194.251236,264.388000 201.808960,258.311768  z"
    />
    <path
      fill="#FBC974"
      opacity={1}
      stroke="none"
      d=" M162.634659,297.008850  C159.997864,303.728607 156.994980,310.457184 153.713226,317.826355  C152.257431,323.940094 151.080505,329.413208 149.658844,335.190369  C148.926102,339.324890 148.438095,343.155396 147.617081,347.060608  C146.264511,348.597900 145.379181,350.180206 144.200882,351.501221  C140.218170,355.966339 137.381989,362.033539 130.036407,361.518707  C129.998489,356.031891 129.970779,351.010834 129.943069,345.989807  C131.255951,337.913544 132.568817,329.837311 133.964920,321.377533  C136.350220,319.844452 139.115616,319.170502 140.871399,317.460144  C147.294983,311.202728 153.359100,304.577454 159.632401,298.162903  C160.252823,297.528534 161.377792,297.387634 162.634659,297.008850  z"
    />
    <path
      fill="#FDE59D"
      opacity={1}
      stroke="none"
      d=" M129.612610,346.064392  C129.970779,351.010834 129.998489,356.031891 130.020294,361.950562  C130.568222,367.071655 130.842911,371.355316 131.737762,375.505188  C133.292236,382.713959 135.229965,389.840027 137.006973,397.000793  C128.097260,399.336914 119.187843,401.674194 110.277451,404.007782  C109.159416,404.300568 108.036247,404.573761 106.539185,404.914001  C103.771851,395.612823 101.380768,386.253693 98.952591,376.473389  C101.480927,374.330872 104.451500,372.997742 106.539444,370.818848  C114.277901,362.743195 121.725357,354.388702 129.612610,346.064392  z"
    />
    <path
      fill="#DE8B59"
      opacity={1}
      stroke="none"
      d=" M89.339859,360.041077  C87.530556,358.761627 86.058746,357.526855 84.301300,356.137085  C87.027657,349.825256 90.844673,344.372162 97.427513,341.031677  C97.767517,348.404816 97.761948,355.781067 97.725906,363.566254  C96.972694,363.728882 96.249962,363.482513 95.258095,363.121704  C93.218430,362.033447 91.447891,361.059631 89.339859,360.041077  z"
    />
    <path
      fill="#E4985F"
      opacity={1}
      stroke="none"
      d=" M255.418549,462.970398  C254.256393,461.053375 253.943970,458.725830 252.640900,457.309937  C248.485779,452.794952 243.962494,448.618805 239.283234,444.163696  C235.519623,440.449371 232.054214,436.883148 228.292572,433.163269  C224.263199,429.365479 220.530045,425.721313 216.471100,422.022705  C215.101547,420.307312 214.057770,418.646393 213.311005,416.603699  C216.408737,415.824127 219.209457,415.426300 222.384583,415.019226  C225.502945,413.992096 228.246918,412.974243 231.361649,411.920715  C234.133347,411.199127 236.534302,410.513184 238.952362,410.278748  C238.370834,423.713745 244.971008,433.366058 254.356110,440.959442  C260.884918,446.241791 268.745209,449.852692 275.798767,454.529877  C277.610229,455.731079 278.620453,458.140656 279.998779,459.995117  C279.453369,460.175903 278.907990,460.356689 278.178589,460.764954  C271.939026,461.652649 265.883484,462.312927 259.407684,462.992371  C257.929901,463.000061 256.872375,462.988586 255.418549,462.970398  z"
    />
    <path
      fill="#E69C60"
      opacity={1}
      stroke="none"
      d=" M280.386963,459.941101  C278.620453,458.140656 277.610229,455.731079 275.798767,454.529877  C268.745209,449.852692 260.884918,446.241791 254.356110,440.959442  C244.971008,433.366058 238.370834,423.713745 239.135345,410.073486  C239.879761,408.967316 240.458313,408.517883 241.420166,408.045410  C246.539261,406.707886 251.275009,405.393372 256.008209,404.506409  C256.581787,407.988434 256.171387,411.876068 257.916901,413.942993  C263.405518,420.442352 269.664978,426.297119 275.714569,432.312408  C277.457611,434.045624 279.536469,435.435974 281.394989,437.059509  C283.600647,438.986267 285.557220,441.248596 287.937592,442.914062  C292.508942,446.112488 297.302948,448.992584 302.004608,452.004730  C299.822205,453.168457 297.639832,454.332153 295.224518,455.742432  C290.252777,457.288391 285.513947,458.587738 280.386963,459.941101  z"
    />
    <path
      fill="#FBCF7D"
      opacity={1}
      stroke="none"
      d=" M137.260895,397.238464  C135.229965,389.840027 133.292236,382.713959 131.737762,375.505188  C130.842911,371.355316 130.568222,367.071655 130.030518,362.416382  C137.381989,362.033539 140.218170,355.966339 144.200882,351.501221  C145.379181,350.180206 146.264511,348.597900 147.636932,347.490051  C148.002457,349.561584 148.015091,351.278351 148.027725,352.995117  C148.680206,360.384430 149.332687,367.773773 150.050446,375.961731  C151.409363,381.506927 152.703033,386.253479 153.701202,391.164978  C152.716309,392.236267 152.038437,393.151611 151.335709,394.047485  C146.549927,400.148560 146.946396,402.056091 153.116089,406.541565  C156.611481,409.082764 159.717499,412.159546 162.998871,414.995117  C157.262909,414.624817 151.526962,414.254517 145.397308,413.944580  C144.420639,412.861298 143.837677,411.717743 143.119110,410.282837  C141.160583,405.819702 139.337708,401.647919 137.260895,397.238464  z"
    />
    <path
      fill="#DF9A63"
      opacity={1}
      stroke="none"
      d=" M208.531006,412.068481  C207.881332,411.776428 207.231659,411.484375 206.241425,410.678894  C205.570679,406.770721 205.240494,403.375916 204.941025,399.558044  C205.314392,396.423706 205.657043,393.712463 206.363556,390.993500  C207.503952,390.753174 208.509201,390.754456 209.022400,390.252594  C215.633789,383.786804 222.229553,377.302765 228.706421,370.702789  C231.216217,368.145233 229.369980,366.668121 226.981812,365.513458  C225.256241,364.679108 223.566025,363.771606 220.672318,362.287659  C224.481277,360.780060 226.737640,359.886993 228.999161,358.998993  C229.004318,359.004089 229.014114,359.013428 229.063492,359.365784  C230.450943,360.776489 231.655792,362.109436 233.154556,362.836578  C236.361420,364.392395 239.720306,365.634888 242.964203,367.334930  C242.254303,369.047028 241.597137,370.429932 240.657318,372.006714  C236.541016,377.330505 232.707367,382.460419 228.560196,387.737366  C221.674774,395.945801 215.102890,404.007172 208.531006,412.068481  z"
    />
    <path
      fill="#FBD282"
      opacity={1}
      stroke="none"
      d=" M204.910309,399.981140  C205.240494,403.375916 205.570679,406.770721 205.994919,410.563812  C195.040359,413.304443 184.042648,415.959534 172.907776,417.771729  C170.082367,418.231537 166.820328,416.008484 163.378906,415.006653  C159.717499,412.159546 156.611481,409.082764 153.116089,406.541565  C146.946396,402.056091 146.549927,400.148560 151.335709,394.047485  C152.038437,393.151611 152.716309,392.236267 153.955215,391.401306  C156.002747,394.648895 157.500763,397.825043 159.044388,401.356750  C162.516602,409.356964 162.665253,409.469574 170.525162,407.883759  C175.562592,406.867401 180.588089,405.783325 185.594666,404.624725  C192.045898,403.131744 198.472885,401.533844 204.910309,399.981140  z"
    />
    <path
      fill="#A5553C"
      opacity={1}
      stroke="none"
      d=" M208.614624,412.420471  C215.102890,404.007172 221.674774,395.945801 228.597992,388.195465  C228.443573,391.062469 228.086212,393.658844 227.402893,396.166443  C225.683746,402.475159 223.817444,408.743774 222.010162,415.028503  C219.209457,415.426300 216.408737,415.824127 213.240448,416.282349  C211.481323,415.152618 210.089767,413.962524 208.614624,412.420471  z"
    />
    <path
      fill="#E9A362"
      opacity={1}
      stroke="none"
      d=" M302.373383,451.994171  C297.302948,448.992584 292.508942,446.112488 287.937592,442.914062  C285.557220,441.248596 283.600647,438.986267 281.394989,437.059509  C279.536469,435.435974 277.457611,434.045624 275.714569,432.312408  C269.664978,426.297119 263.405518,420.442352 257.916901,413.942993  C256.171387,411.876068 256.581787,407.988434 256.264893,404.472168  C256.695892,403.993195 256.867645,403.975952 257.435913,404.003113  C259.926849,404.507019 262.086395,404.774719 264.104279,405.459686  C272.099487,408.173645 280.050415,411.018158 288.215271,413.945862  C288.696503,414.234741 288.916809,414.455383 289.089600,415.098694  C293.137238,420.008789 296.871887,424.874695 301.277374,429.029236  C306.553467,434.004700 312.402374,438.372742 318.003174,443.003906  C315.536163,444.897919 313.069153,446.791931 310.304749,448.844666  C307.585602,449.996796 305.163879,450.990204 302.373383,451.994171  z"
    />
    <path
      fill="#EBA664"
      opacity={1}
      stroke="none"
      d=" M318.344666,442.961060  C312.402374,438.372742 306.553467,434.004700 301.277374,429.029236  C296.871887,424.874695 293.137238,420.008789 289.473480,415.165527  C298.335907,417.847168 302.068573,417.179626 306.191193,411.880554  C307.296814,410.789490 308.105988,409.820190 309.115021,409.127930  C314.607605,414.834747 319.868866,420.295746 325.208344,425.679199  C327.404663,427.893585 329.769501,429.940918 332.057678,432.064209  C332.021759,432.488037 331.985840,432.911896 331.906738,433.651672  C328.677551,436.440002 325.491547,438.912354 322.138672,441.674988  C320.876587,442.282898 319.781342,442.600555 318.344666,442.961060  z"
    />
    <path
      fill="#FBC56E"
      opacity={1}
      stroke="none"
      d=" M416.102936,363.964325  C416.079956,364.418701 416.056976,364.873108 416.049042,365.668701  C415.000092,380.649841 411.756714,394.802368 406.313354,408.777100  C395.000793,409.017944 383.932129,408.999023 372.454834,408.607361  C379.914398,390.261505 384.864746,371.662720 384.016907,351.846344  C383.805328,346.900360 383.405914,341.962433 383.441711,337.002380  C384.505859,336.634064 385.382019,336.443817 385.912079,335.909943  C392.817139,328.954620 399.636078,321.913391 406.575897,314.993286  C407.811188,313.761444 409.496857,312.981262 410.974548,311.992523  C411.297729,312.786743 411.620911,313.580963 412.036865,315.087646  C413.061951,319.538574 413.994263,323.277069 414.943909,327.460693  C415.331604,332.278198 415.701904,336.650543 416.058228,341.464417  C416.063782,349.258759 416.083374,356.611542 416.102936,363.964325  z"
    />
    <path
      fill="#FEEAA6"
      opacity={1}
      stroke="none"
      d=" M383.092834,337.020782  C383.405914,341.962433 383.805328,346.900360 384.016907,351.846344  C384.864746,371.662720 379.914398,390.261505 371.981018,408.594666  C371.915894,408.954773 371.490265,408.995117 370.926575,408.959778  C362.392761,403.501099 361.626312,400.384125 366.627991,393.904999  C369.854950,389.724792 372.623474,385.194122 375.728882,380.915924  C380.067505,374.938751 380.100494,374.852722 373.844360,369.706665  C371.156494,367.264374 368.625458,365.115387 366.073364,362.572510  C366.010803,361.127441 365.969299,360.076324 365.962860,358.574188  C366.040833,355.419952 366.083740,352.716736 366.453949,349.954956  C367.688416,348.989716 368.535461,348.011536 369.513062,347.188446  C373.965271,343.439789 378.454193,339.734711 382.961853,336.267090  C383.026825,336.687073 383.059845,336.853943 383.092834,337.020782  z"
    />
    <path
      fill="#FDE8A1"
      opacity={1}
      stroke="none"
      d=" M374.001221,370.000000  C380.100494,374.852722 380.067505,374.938751 375.728882,380.915924  C372.623474,385.194122 369.854950,389.724792 366.627991,393.904999  C361.626312,400.384125 362.392761,403.501099 370.713501,408.977142  C369.914948,411.228973 368.765747,413.428101 367.012451,415.790802  C364.640839,414.838196 362.586487,414.001251 361.168182,412.544922  C358.582642,409.890106 356.374146,406.868073 354.140320,403.669067  C356.524841,397.895355 358.777435,392.451843 361.371887,386.956177  C365.809601,381.269348 369.905396,375.634674 374.001221,370.000000  z"
    />
    <path
      fill="#F8DA99"
      opacity={1}
      stroke="none"
      d=" M354.008362,403.999237  C356.374146,406.868073 358.582642,409.890106 361.168182,412.544922  C362.586487,414.001251 364.640839,414.838196 366.772308,416.022522  C365.509583,418.850342 363.882904,421.610016 362.099731,424.673096  C357.453033,421.746918 352.962891,418.517303 348.250000,414.785828  C350.020966,410.855743 352.014648,407.427490 354.008362,403.999237  z"
    />
    <path
      fill="#F0B067"
      opacity={1}
      stroke="none"
      d=" M332.343445,431.896149  C329.769501,429.940918 327.404663,427.893585 325.208344,425.679199  C319.868866,420.295746 314.607605,414.834747 309.130249,408.675354  C308.625610,401.963501 308.305573,395.981293 308.011414,389.546906  C307.712921,385.398987 307.388550,381.703217 307.081665,377.545837  C307.142395,373.385498 307.185638,369.686798 307.459869,365.776855  C309.470764,363.050293 311.250671,360.534943 313.313721,357.846313  C315.452972,355.144531 317.309113,352.616058 319.440308,349.906982  C321.219452,347.624176 322.723572,345.521973 324.492401,343.199890  C328.617828,339.044617 331.992065,334.852661 332.481873,329.017944  C340.214600,336.375183 347.613892,343.678345 354.861816,351.319275  C353.025391,355.422913 351.680725,359.390350 349.591827,362.916901  C343.238831,373.642242 336.797089,384.327423 329.969574,394.752960  C327.338379,398.770752 327.978149,401.782135 330.675781,405.048950  C334.794495,410.036743 338.904846,415.031464 343.018738,420.023254  C339.555542,423.924866 336.092407,427.826477 332.343445,431.896149  z"
    />
    <path
      fill="#F5B969"
      opacity={1}
      stroke="none"
      d=" M343.301147,419.861816  C338.904846,415.031464 334.794495,410.036743 330.675781,405.048950  C327.978149,401.782135 327.338379,398.770752 329.969574,394.752960  C336.797089,384.327423 343.238831,373.642242 349.591827,362.916901  C351.680725,359.390350 353.025391,355.422913 355.202332,351.369507  C359.105438,353.729675 362.516632,356.377441 365.927826,359.025177  C365.969299,360.076324 366.010803,361.127441 366.018311,362.982605  C364.332855,371.527161 362.681427,379.267731 361.029999,387.008301  C358.777435,392.451843 356.524841,397.895355 354.140320,403.669067  C352.014648,407.427490 350.020966,410.855743 347.940613,414.632874  C346.430481,416.554626 345.007019,418.127502 343.301147,419.861816  z"
    />
    <path
      fill="#FBCC77"
      opacity={1}
      stroke="none"
      d=" M383.441711,337.002380  C383.059845,336.853943 383.026825,336.687073 382.982056,335.851562  C379.965118,324.460754 376.959900,313.738647 373.827698,302.703339  C373.135010,300.926788 372.569336,299.463379 372.003662,298.000000  C379.566315,290.528015 387.128967,283.056061 394.847046,275.289551  C395.819397,275.573151 396.636353,276.151306 397.721497,276.865936  C399.562866,280.791351 401.136017,284.580261 402.856659,288.686951  C404.826965,293.512268 406.649750,298.019745 408.724884,302.772583  C409.612823,305.759430 410.248383,308.500946 410.929260,311.617493  C409.496857,312.981262 407.811188,313.761444 406.575897,314.993286  C399.636078,321.913391 392.817139,328.954620 385.912079,335.909943  C385.382019,336.443817 384.505859,336.634064 383.441711,337.002380  z"
    />
    <path
      fill="#E9A362"
      opacity={1}
      stroke="none"
      d=" M414.926575,327.015564  C413.994263,323.277069 413.061951,319.538574 412.079956,315.407379  C413.251190,315.420288 414.798523,315.482178 415.643036,316.284149  C424.924316,325.097931 434.100220,334.022766 443.281403,342.941498  C443.610779,343.261505 443.699402,343.829346 443.942017,344.619141  C443.108185,345.805237 442.232574,346.653900 441.177673,347.751160  C440.244171,348.324371 439.489929,348.648987 438.117432,348.854431  C431.572144,342.561920 425.696503,336.337769 419.678009,330.254944  C418.354614,328.917480 416.523743,328.082153 414.926575,327.015564  z"
    />
    <path
      fill="#E4985F"
      opacity={1}
      stroke="none"
      d=" M414.943909,327.460693  C416.523743,328.082153 418.354614,328.917480 419.678009,330.254944  C425.696503,336.337769 431.572144,342.561920 437.811340,348.895447  C437.540222,349.871765 436.956970,350.687897 436.186249,351.752014  C434.436646,353.143127 432.874481,354.286224 431.152496,355.711609  C430.824463,355.991882 430.656250,355.989868 430.233398,355.621582  C426.675476,351.029785 423.475037,346.716400 419.992920,342.643829  C419.174133,341.686188 417.404480,341.541534 416.072235,341.022888  C415.701904,336.650543 415.331604,332.278198 414.943909,327.460693  z"
    />
    <path
      fill="#DE8B59"
      opacity={1}
      stroke="none"
      d=" M416.058228,341.464417  C417.404480,341.541534 419.174133,341.686188 419.992920,342.643829  C423.475037,346.716400 426.675476,351.029785 429.981140,355.617462  C428.781738,356.819855 427.579926,357.660034 426.185913,358.746460  C424.521545,359.897797 423.049438,360.802917 421.291107,361.859924  C419.588440,362.640259 418.171997,363.268707 416.429260,363.930725  C416.083374,356.611542 416.063782,349.258759 416.058228,341.464417  z"
    />
    <path
      fill="#FBC974"
      opacity={1}
      stroke="none"
      d=" M365.962860,358.574188  C362.516632,356.377441 359.105438,353.729675 355.353729,351.031708  C347.613892,343.678345 340.214600,336.375183 332.403015,328.741150  C331.956970,328.030579 331.923187,327.650848 331.889404,327.271149  C339.360382,320.210022 346.831329,313.148895 354.916992,306.269318  C356.991608,309.313873 358.451599,312.176910 359.948334,315.428558  C362.032257,327.215973 364.079468,338.614716 366.126678,350.013489  C366.083740,352.716736 366.040833,355.419952 365.962860,358.574188  z"
    />
    <path
      fill="#FDE096"
      opacity={1}
      stroke="none"
      d=" M359.911560,315.039917  C358.451599,312.176910 356.991608,309.313873 355.267761,306.223877  C350.400421,298.561584 346.013397,290.979523 341.111542,283.746429  C338.212433,279.468536 338.101379,278.029083 342.507294,274.994141  C352.039459,268.428070 352.282715,268.252838 359.114410,277.736237  C363.657532,284.042786 367.383575,290.937958 371.739502,297.784851  C372.569336,299.463379 373.135010,300.926788 373.490723,302.746002  C368.824341,307.081207 364.367950,311.060547 359.911560,315.039917  z"
    />
    <path
      fill="#D68D5E"
      opacity={1}
      stroke="none"
      d=" M287.013092,311.011993  C287.525635,312.629791 288.528778,314.272491 288.448242,315.860229  C288.282440,319.130371 287.266724,322.362396 287.161499,325.628204  C287.117981,326.978119 288.214874,328.863770 289.367218,329.573059  C290.102448,330.025604 292.248749,329.122040 293.145050,328.215881  C297.194946,324.121368 301.024719,319.809174 304.985718,315.219421  C308.128357,315.488831 311.219025,316.114197 314.607941,317.246887  C310.909668,322.031616 306.819489,326.227081 302.968567,330.631775  C301.658691,332.130035 300.951538,334.155273 299.989624,335.968781  C300.010254,335.997864 300.045441,336.059937 299.683533,336.166199  C293.879883,337.866180 288.438141,339.459869 282.936340,340.634277  C282.584229,338.090057 282.201233,335.972839 282.018890,333.838501  C281.624786,329.224609 281.333588,324.601959 281.033325,319.667480  C281.378021,318.569244 281.689697,317.786438 282.288696,316.843231  C284.055023,314.792572 285.534058,312.902283 287.013092,311.011993  z"
    />
    <path
      fill="#A5553C"
      opacity={1}
      stroke="none"
      d=" M331.662689,327.097321  C331.923187,327.650848 331.956970,328.030579 332.069641,328.687042  C331.992065,334.852661 328.617828,339.044617 324.243469,342.867737  C319.776611,341.832062 315.650055,341.345703 311.917938,339.865051  C308.020386,338.318787 302.689453,341.277130 300.045441,336.059937  C300.045441,336.059937 300.010254,335.997864 300.369202,335.953491  C310.585754,332.492615 320.443329,329.076080 330.300934,325.659546  C330.679291,326.080841 331.057648,326.502167 331.662689,327.097321  z"
    />
    <path
      fill="#CF805E"
      opacity={1}
      stroke="none"
      d=" M330.226318,325.322510  C320.443329,329.076080 310.585754,332.492615 300.348572,335.924438  C300.951538,334.155273 301.658691,332.130035 302.968567,330.631775  C306.819489,326.227081 310.909668,322.031616 314.959045,317.421692  C320.762024,318.372467 327.293427,318.157684 330.226318,325.322510  z"
    />
    <path
      fill="#FEE9A3"
      opacity={1}
      stroke="none"
      d=" M148.388718,352.988525  C148.015091,351.278351 148.002457,349.561584 147.969940,347.415344  C148.438095,343.155396 148.926102,339.324890 149.877869,335.431152  C152.276535,334.363068 154.611221,333.760468 156.086990,332.293640  C182.882874,305.660919 209.598892,278.947571 236.233780,252.153793  C237.893387,250.484238 238.744019,248.010468 239.971039,245.910873  C244.356110,245.282608 248.741165,244.654343 253.561020,244.044678  C255.319748,247.030396 254.468307,249.163818 252.129227,251.482025  C230.844666,272.576660 209.693695,293.806091 188.503677,314.996124  C176.880020,326.619781 165.284698,338.272064 153.594086,349.827972  C152.251709,351.154907 150.377396,351.943665 148.388718,352.988525  z"
    />
    <path
      fill="#FDE096"
      opacity={1}
      stroke="none"
      d=" M158.998779,401.001221  C157.500763,397.825043 156.002747,394.648895 154.250717,391.236389  C152.703033,386.253479 151.409363,381.506927 150.070877,376.374969  C151.697174,375.473724 153.849548,375.451599 154.964233,374.364990  C165.050064,364.533386 174.967346,354.528442 184.887085,344.527771  C185.679443,343.728973 186.121338,342.582581 187.159943,341.455017  C192.382248,347.225494 197.170670,353.138702 202.092560,359.337463  C202.519531,360.402710 202.813004,361.182404 202.795624,362.118835  C199.346771,365.660095 196.286804,369.121246 193.054092,372.412659  C186.817261,378.762726 180.483856,385.017944 174.188538,391.310547  C169.838852,395.658386 165.414764,399.891388 158.998779,401.001221  z"
    />
    <path
      fill="#F0B36B"
      opacity={1}
      stroke="none"
      d=" M203.106461,361.962067  C202.813004,361.182404 202.519531,360.402710 202.149567,358.987915  C203.061417,357.055634 203.943863,355.658478 205.057068,354.479156  C209.159561,350.133057 209.041840,346.484192 203.926712,343.056610  C197.068161,338.460815 189.910355,334.311646 182.881027,329.970734  C183.053833,328.795044 183.226654,327.619354 183.632248,326.172485  C188.409897,327.430573 192.954758,328.959839 197.816498,331.083221  C199.181595,332.701813 200.242569,333.713593 201.275925,334.752838  C206.920944,340.429962 212.765289,345.927429 218.129044,351.859253  C221.855591,355.980469 221.206909,359.774048 216.601929,362.540497  C213.083160,364.654388 208.991257,365.892029 207.067841,369.657715  C205.716232,366.841278 204.411346,364.401672 203.106461,361.962067  z"
    />
    <path
      fill="#FBC56E"
      opacity={1}
      stroke="none"
      d=" M182.665161,330.230835  C189.910355,334.311646 197.068161,338.460815 203.926712,343.056610  C209.041840,346.484192 209.159561,350.133057 205.057068,354.479156  C203.943863,355.658478 203.061417,357.055634 202.016068,358.702362  C197.170670,353.138702 192.382248,347.225494 187.301559,341.146240  C185.489288,337.483795 183.969299,333.987366 182.665161,330.230835  z"
    />
    <path
      fill="#E6A566"
      opacity={1}
      stroke="none"
      d=" M207.114532,370.034515  C208.991257,365.892029 213.083160,364.654388 216.601929,362.540497  C221.206909,359.774048 221.855591,355.980469 218.129044,351.859253  C212.765289,345.927429 206.920944,340.429962 201.275925,334.752838  C200.242569,333.713593 199.181595,332.701813 198.067719,331.345764  C202.825546,332.507324 207.649048,334.000519 212.512207,335.961884  C215.236023,339.986969 217.969162,343.508270 220.591141,347.110443  C223.445892,351.032440 226.197449,355.029541 228.994003,358.993896  C226.737640,359.886993 224.481277,360.780060 220.672318,362.287659  C223.566025,363.771606 225.256241,364.679108 226.981812,365.513458  C229.369980,366.668121 231.216217,368.145233 228.706421,370.702789  C222.229553,377.302765 215.633789,383.786804 209.022400,390.252594  C208.509201,390.754456 207.503952,390.753174 206.346252,390.536621  C206.348236,383.403137 206.731384,376.718842 207.114532,370.034515  z"
    />
    <path
      fill="#DF9A63"
      opacity={1}
      stroke="none"
      d=" M228.999161,358.998993  C226.197449,355.029541 223.445892,351.032440 220.591141,347.110443  C217.969162,343.508270 215.236023,339.986969 212.768616,336.221558  C215.724960,336.676483 218.464539,337.339966 221.599976,338.019958  C221.995819,338.036499 221.988205,338.525085 221.770355,338.980347  C222.714600,341.466858 223.699249,343.628418 225.075653,345.502197  C227.957748,349.425751 231.046219,353.197754 234.039490,357.275024  C234.029037,357.518066 233.999222,358.003662 233.636520,358.049500  C231.853928,358.401398 230.434021,358.707428 229.014114,359.013428  C229.014114,359.013428 229.004318,359.004089 228.999161,358.998993  z"
    />
    <path
      fill="#F5B969"
      opacity={1}
      stroke="none"
      d=" M233.991302,320.008087  C238.738602,315.760956 243.437698,311.458160 248.252747,307.289307  C250.810196,305.075073 253.568726,303.093048 256.620178,301.253113  C257.015106,301.673737 257.026337,301.847504 257.028503,302.479858  C257.022675,306.629669 257.025970,310.320862 257.018555,314.426086  C256.986420,316.220581 256.964966,317.601105 256.605469,319.034149  C251.841293,323.077698 247.415192,327.068695 242.707062,330.897705  C240.954132,329.448914 239.483215,328.162109 237.966553,326.541077  C236.610962,324.140594 235.301132,322.074341 233.991302,320.008087  z"
    />
    <path
      fill="#E6A566"
      opacity={1}
      stroke="none"
      d=" M257.037567,302.021301  C257.026337,301.847504 257.015106,301.673737 256.981445,301.239441  C256.975739,298.288696 256.992401,295.598450 257.013733,292.459961  C260.281036,293.752014 263.564270,295.455139 266.800201,297.243835  C269.664124,298.826874 272.378784,300.726105 275.358795,302.029816  C276.508698,302.532837 278.208374,301.779205 279.831818,301.298096  C282.308105,304.105011 284.610138,307.211884 286.962646,310.665405  C285.534058,312.902283 284.055023,314.792572 281.966431,316.697113  C279.749054,315.913422 278.065216,315.238037 276.546600,314.296295  C270.017120,310.247314 263.536194,306.120056 257.037567,302.021301  z"
    />
    <path
      fill="#E6A566"
      opacity={1}
      stroke="none"
      d=" M233.709900,319.852692  C235.301132,322.074341 236.610962,324.140594 237.961639,327.010162  C238.667252,333.195312 239.332016,338.577118 239.631805,343.914734  C237.176727,343.241760 235.086624,342.613007 232.996521,341.984253  C231.997742,333.608063 230.998962,325.231903 230.001755,316.417023  C231.145035,317.217987 232.286774,318.457642 233.709900,319.852692  z"
    />
    <path
      fill="#C88251"
      opacity={1}
      stroke="none"
      d=" M232.663620,341.994507  C235.086624,342.613007 237.176727,343.241760 239.636108,344.174286  C240.005737,344.650787 240.006104,344.823547 239.784897,345.219055  C240.227661,347.039764 240.934250,348.621857 241.542358,350.240936  C241.992828,351.440247 243.031082,353.162231 242.582336,353.839172  C238.733582,359.644714 243.676666,360.346008 247.015335,362.312012  C246.010620,363.795471 245.063217,364.953766 243.944931,365.805511  C241.837875,364.041168 239.893677,362.593964 237.968369,361.121979  C236.631821,360.100189 235.321350,359.044312 233.999222,358.003662  C233.999222,358.003662 234.029037,357.518066 234.397079,357.259766  C236.075256,356.539124 237.385406,356.076843 239.097885,355.472565  C236.177948,350.623474 233.588272,346.322815 230.998596,342.022156  C231.442627,342.016357 231.886673,342.010590 232.663620,341.994507  z"
    />
    <path
      fill="#D68D5E"
      opacity={1}
      stroke="none"
      d=" M230.755096,341.757385  C233.588272,346.322815 236.177948,350.623474 239.097885,355.472565  C237.385406,356.076843 236.075256,356.539124 234.407532,357.016724  C231.046219,353.197754 227.957748,349.425751 225.075653,345.502197  C223.699249,343.628418 222.714600,341.466858 221.769333,339.224670  C224.827972,339.840027 227.669785,340.666321 230.755096,341.757385  z"
    />
    <path
      fill="#FEEBA6"
      opacity={1}
      stroke="none"
      d=" M239.544403,245.927277  C238.744019,248.010468 237.893387,250.484238 236.233780,252.153793  C209.598892,278.947571 182.882874,305.660919 156.086990,332.293640  C154.611221,333.760468 152.276535,334.363068 150.122620,335.127136  C151.080505,329.413208 152.257431,323.940094 153.691528,318.204437  C155.464935,317.627960 157.499039,317.836823 158.421371,316.923248  C179.342682,296.200409 200.154648,275.366974 220.926086,254.493576  C221.900146,253.514725 222.309860,251.974274 222.988098,250.327316  C228.368454,248.620728 233.743103,247.282211 239.544403,245.927277  z"
    />
    <path
      fill="#B86B49"
      opacity={1}
      stroke="none"
      d=" M240.939972,371.812805  C241.597137,370.429932 242.254303,369.047028 243.214142,367.331787  C243.683578,367.000549 243.850327,367.001648 244.350464,367.158173  C248.779938,368.548889 252.876053,369.784210 256.975952,371.472595  C256.974579,374.956055 256.969421,377.986389 256.567444,380.998901  C252.240189,380.022217 248.126984,378.533844 246.712631,384.457275  C244.832718,392.330536 242.929855,400.198334 241.036850,408.068481  C240.458313,408.517883 239.879761,408.967316 239.118225,409.622009  C236.534302,410.513184 234.133347,411.199127 231.365326,411.505798  C232.515259,405.042419 234.015381,398.954041 235.553696,392.875305  C237.331558,385.850037 239.143082,378.833252 240.939972,371.812805  z"
    />
    <path
      fill="#B16245"
      opacity={1}
      stroke="none"
      d=" M240.657318,372.006714  C239.143082,378.833252 237.331558,385.850037 235.553696,392.875305  C234.015381,398.954041 232.515259,405.042419 230.994568,411.541473  C228.246918,412.974243 225.502945,413.992096 222.384567,415.019226  C223.817444,408.743774 225.683746,402.475159 227.402893,396.166443  C228.086212,393.658844 228.443573,391.062469 228.911530,388.048401  C232.707367,382.460419 236.541016,377.330505 240.657318,372.006714  z"
    />
    <path
      fill="#C27353"
      opacity={1}
      stroke="none"
      d=" M241.420166,408.045410  C242.929855,400.198334 244.832718,392.330536 246.712631,384.457275  C248.126984,378.533844 252.240189,380.022217 256.578003,381.465637  C257.003387,389.286346 257.021393,396.622528 257.039429,403.958679  C256.867645,403.975952 256.695892,403.993195 256.267456,404.044647  C251.275009,405.393372 246.539261,406.707886 241.420166,408.045410  z"
    />
    <path
      fill="#FCDA8E"
      opacity={1}
      stroke="none"
      d=" M207.067825,369.657715  C206.731384,376.718842 206.348236,383.403137 205.982391,390.544342  C205.657043,393.712463 205.314392,396.423706 204.941025,399.558044  C198.472885,401.533844 192.045898,403.131744 185.594666,404.624725  C180.588089,405.783325 175.562592,406.867401 170.525162,407.883759  C162.665253,409.469574 162.516602,409.356964 159.044388,401.356750  C165.414764,399.891388 169.838852,395.658386 174.188538,391.310547  C180.483856,385.017944 186.817261,378.762726 193.054092,372.412659  C196.286804,369.121246 199.346771,365.660095 202.795624,362.118835  C204.411346,364.401672 205.716232,366.841278 207.067825,369.657715  z"
    />
    <path
      fill="#D68D5E"
      opacity={1}
      stroke="none"
      d=" M244.017075,367.002777  C243.850327,367.001648 243.683578,367.000549 243.266876,367.002563  C239.720306,365.634888 236.361420,364.392395 233.154556,362.836578  C231.655792,362.109436 230.450943,360.776489 229.063492,359.365784  C230.434021,358.707428 231.853928,358.401398 233.636520,358.049500  C235.321350,359.044312 236.631821,360.100189 237.968369,361.121979  C239.893677,362.593964 241.837875,364.041168 243.919617,366.027710  C244.065186,366.556427 244.017075,367.002777 244.017075,367.002777  z"
    />
    <path
      fill="#A15139"
      opacity={1}
      stroke="none"
      d=" M257.435913,404.003113  C257.021393,396.622528 257.003387,389.286346 256.974854,381.483459  C256.969421,377.986389 256.974579,374.956055 256.990234,371.214508  C257.009521,370.331360 257.018280,370.159424 257.437439,369.991150  C264.577820,370.150146 269.321838,373.022797 272.273712,379.349670  C274.986206,385.163422 278.768982,390.479706 281.453979,396.303864  C284.056671,401.949463 285.862152,407.962585 288.017761,413.814270  C280.050415,411.018158 272.099487,408.173645 264.104279,405.459686  C262.086395,404.774719 259.926849,404.507019 257.435913,404.003113  z"
    />
    <path
      fill="#9B4B34"
      opacity={1}
      stroke="none"
      d=" M288.215271,413.945862  C285.862152,407.962585 284.056671,401.949463 281.453979,396.303864  C278.768982,390.479706 274.986206,385.163422 272.273712,379.349670  C269.321838,373.022797 264.577820,370.150146 257.430542,369.539185  C256.984039,362.174255 256.984039,362.174255 251.308624,357.780426  C252.227844,356.458099 253.483719,355.244324 254.018753,353.769043  C255.537033,349.582764 258.213562,349.947113 261.535034,351.278046  C264.276337,352.376465 267.160889,353.117432 269.988953,354.258820  C269.995972,354.500824 270.016357,354.984589 269.938416,355.325409  C264.024750,359.822113 263.999390,360.658264 269.563049,365.769073  C269.679535,365.876160 269.846527,365.928223 270.039520,366.351105  C276.389771,374.798340 282.690491,382.900421 289.134766,391.310852  C290.917450,393.861298 292.447937,396.193542 294.218079,398.326935  C298.045197,402.939423 301.995087,407.450043 305.894775,412.002319  C302.068573,417.179626 298.335907,417.847168 289.457581,414.806274  C288.916809,414.455383 288.696503,414.234741 288.215271,413.945862  z"
    />
    <path
      fill="#B16245"
      opacity={1}
      stroke="none"
      d=" M306.191193,411.880554  C301.995087,407.450043 298.045197,402.939423 294.218079,398.326935  C292.447937,396.193542 290.917450,393.861298 289.548706,391.246155  C295.874542,390.581726 301.930023,390.290405 307.985504,389.999084  C308.305573,395.981293 308.625610,401.963501 308.930420,408.398285  C308.105988,409.820190 307.296814,410.789490 306.191193,411.880554  z"
    />
    <path
      fill="#FDE69F"
      opacity={1}
      stroke="none"
      d=" M359.948303,315.428528  C364.367950,311.060547 368.824341,307.081207 373.617706,303.059174  C376.959900,313.738647 379.965118,324.460754 382.950104,335.598450  C378.454193,339.734711 373.965271,343.439789 369.513062,347.188446  C368.535461,348.011536 367.688416,348.989716 366.453949,349.954956  C364.079468,338.614716 362.032257,327.215973 359.948303,315.428528  z"
    />
    <path
      fill="#F8DA99"
      opacity={1}
      stroke="none"
      d=" M361.371887,386.956177  C362.681427,379.267731 364.332855,371.527161 366.039368,363.376495  C368.625458,365.115387 371.156494,367.264374 373.844360,369.706665  C369.905396,375.634674 365.809601,381.269348 361.371887,386.956177  z"
    />
    <path
      fill="#AC5B43"
      opacity={1}
      stroke="none"
      d=" M308.011414,389.546906  C301.930023,390.290405 295.874542,390.581726 289.405151,390.937744  C282.690491,382.900421 276.389771,374.798340 270.235229,366.031769  C275.867157,361.744690 280.211609,365.450623 283.666779,368.470367  C290.511078,374.452179 298.325745,377.139435 307.064148,378.007477  C307.388550,381.703217 307.712921,385.398987 308.011414,389.546906  z"
    />
    <path
      fill="#A5553C"
      opacity={1}
      stroke="none"
      d=" M307.081665,377.545837  C298.325745,377.139435 290.511078,374.452179 283.666779,368.470367  C280.211609,365.450623 275.867157,361.744690 270.185669,365.686584  C269.846527,365.928223 269.679535,365.876160 269.563049,365.769073  C263.999390,360.658264 264.024750,359.822113 270.308716,355.329102  C272.175598,355.320343 273.594238,355.648712 275.384277,356.036865  C279.474274,357.075134 283.192841,358.053589 287.158783,359.291931  C294.013733,361.697235 300.621307,363.842682 307.228882,365.988159  C307.185638,369.686798 307.142395,373.385498 307.081665,377.545837  z"
    />
    <path
      fill="#B16245"
      opacity={1}
      stroke="none"
      d=" M275.012848,355.977081  C273.594238,355.648712 272.175598,355.320343 270.386658,354.988281  C270.016357,354.984589 269.995972,354.500824 270.149536,353.961426  C272.412079,351.218933 274.609528,349.090332 276.570068,346.762238  C277.083435,346.152649 276.861267,344.923584 277.213226,343.733032  C279.298492,342.675873 281.147461,341.864716 282.996429,341.053528  C288.438141,339.459869 293.879883,337.866180 299.683533,336.166199  C302.689453,341.277130 308.020386,338.318787 311.917938,339.865051  C315.650055,341.345703 319.776611,341.832062 323.978760,343.087585  C322.723572,345.521973 321.219452,347.624176 318.995056,349.879761  C314.553558,349.317413 310.725098,348.944183 307.131744,347.819824  C297.538635,344.818085 288.450836,346.137817 279.809052,350.738770  C277.839050,351.787567 276.592407,354.194977 275.012848,355.977081  z"
    />
    <path
      fill="#B86B49"
      opacity={1}
      stroke="none"
      d=" M275.384277,356.036865  C276.592407,354.194977 277.839050,351.787567 279.809052,350.738770  C288.450836,346.137817 297.538635,344.818085 307.131744,347.819824  C310.725098,348.944183 314.553558,349.317413 318.720032,350.060364  C317.309113,352.616058 315.452972,355.144531 312.971558,357.689941  C303.662964,354.213562 295.069458,352.443695 286.911407,359.032074  C283.192841,358.053589 279.474274,357.075134 275.384277,356.036865  z"
    />
    <path
      fill="#C27353"
      opacity={1}
      stroke="none"
      d=" M287.158783,359.291931  C295.069458,352.443695 303.662964,354.213562 312.688416,357.863281  C311.250671,360.534943 309.470764,363.050293 307.459869,365.776886  C300.621307,363.842682 294.013733,361.697235 287.158783,359.291931  z"
    />
    <path
      fill="#DF9A63"
      opacity={1}
      stroke="none"
      d=" M257.028503,302.479858  C263.536194,306.120056 270.017120,310.247314 276.546600,314.296295  C278.065216,315.238037 279.749054,315.913422 281.679108,316.857483  C281.689697,317.786438 281.378021,318.569244 280.752777,319.819641  C279.287201,321.520416 278.135254,322.753693 276.596008,323.989807  C273.296539,322.654114 270.321594,321.434906 267.487946,319.946747  C263.941071,318.084045 260.510193,316.000397 257.029266,314.012085  C257.025970,310.320862 257.022675,306.629669 257.028503,302.479858  z"
    />
    <path
      fill="#C88251"
      opacity={1}
      stroke="none"
      d=" M276.983276,323.986938  C278.135254,322.753693 279.287201,321.520416 280.719727,320.135010  C281.333588,324.601959 281.624786,329.224609 282.018890,333.838501  C282.201233,335.972839 282.584229,338.090057 282.936340,340.634277  C281.147461,341.864716 279.298492,342.675873 276.841003,343.736084  C275.486847,343.994598 274.741272,344.004059 273.996521,343.554382  C273.327148,339.056641 272.656952,335.017975 272.142029,330.687500  C273.859283,328.259430 275.421295,326.123199 276.983276,323.986938  z"
    />
    <path
      fill="#EDAC69"
      opacity={1}
      stroke="none"
      d=" M242.989075,331.059662  C247.415192,327.068695 251.841293,323.077698 256.625885,319.480865  C257.004364,322.256134 257.024353,324.637238 257.031464,327.484863  C256.994690,334.955688 256.970795,341.960022 256.712585,348.995056  C256.478210,349.025726 256.006622,348.993286 255.863800,348.688904  C252.154388,344.215515 248.587784,340.046570 244.976929,335.545959  C244.284790,333.829437 243.636932,332.444550 242.989075,331.059662  z"
    />
    <path
      fill="#D68D5E"
      opacity={1}
      stroke="none"
      d=" M257.044342,327.018372  C257.024353,324.637238 257.004364,322.256134 256.963928,319.428314  C256.964966,317.601105 256.986420,316.220581 257.018555,314.426086  C260.510193,316.000397 263.941071,318.084045 267.487946,319.946747  C270.321594,321.434906 273.296539,322.654114 276.596008,323.989807  C275.421295,326.123199 273.859283,328.259430 271.885956,330.824005  C270.981445,331.832367 270.488281,332.412415 269.623901,332.985718  C267.493317,332.143982 265.725616,331.325958 263.976624,330.469788  C261.658203,329.334900 259.354401,328.170227 257.044342,327.018372  z"
    />
    <path
      fill="#D68D5E"
      opacity={1}
      stroke="none"
      d=" M242.707062,330.897705  C243.636932,332.444550 244.284790,333.829437 244.970749,335.992523  C245.339371,339.856781 245.669907,342.942841 245.617493,346.012024  C243.491867,345.662201 241.749161,345.329254 240.006470,344.996338  C240.006104,344.823547 240.005737,344.650787 240.001068,344.218506  C239.332016,338.577118 238.667252,333.195312 238.007416,327.344360  C239.483215,328.162109 240.954132,329.448914 242.707062,330.897705  z"
    />
    <path
      fill="#AC5B43"
      opacity={1}
      stroke="none"
      d=" M256.006622,348.993286  C256.006622,348.993286 256.478210,349.025726 257.016357,348.855774  C259.365204,346.786499 261.175934,344.887207 263.250183,343.208313  C266.662842,349.804901 270.422852,345.231750 273.995697,344.013519  C274.741272,344.004059 275.486847,343.994598 276.604675,343.982117  C276.861267,344.923584 277.083435,346.152649 276.570068,346.762238  C274.609528,349.090332 272.412079,351.218933 270.142517,353.719421  C267.160889,353.117432 264.276337,352.376465 261.535034,351.278046  C258.213562,349.947113 255.537033,349.582764 254.018753,353.769043  C253.483719,355.244324 252.227844,356.458099 251.308624,357.780426  C256.984039,362.174255 256.984039,362.174255 257.020081,369.535522  C257.018280,370.159424 257.009521,370.331360 256.986450,370.761414  C252.876053,369.784210 248.779938,368.548889 244.350464,367.158173  C244.017075,367.002777 244.065186,366.556427 244.090515,366.334229  C245.063217,364.953766 246.010620,363.795471 247.299530,362.156097  C249.402115,360.348969 250.451828,359.102356 248.555710,356.844757  C247.871262,356.029846 248.298813,354.195251 248.389343,352.837341  C248.497437,351.216095 248.788437,349.607056 249.398163,347.998718  C251.866287,348.334259 253.936447,348.663757 256.006622,348.993286  z"
    />
    <path
      fill="#B86B49"
      opacity={1}
      stroke="none"
      d=" M249.000214,347.992706  C248.788437,349.607056 248.497437,351.216095 248.389343,352.837341  C248.298813,354.195251 247.871262,356.029846 248.555710,356.844757  C250.451828,359.102356 249.402115,360.348969 247.356842,361.830933  C243.676666,360.346008 238.733582,359.644714 242.582336,353.839172  C243.031082,353.162231 241.992828,351.440247 241.542358,350.240936  C240.934250,348.621857 240.227661,347.039764 239.784897,345.219055  C241.749161,345.329254 243.491867,345.662201 245.818695,346.242432  C247.268631,346.990753 248.134430,347.491730 249.000214,347.992706  z"
    />
    <path
      fill="#B86B49"
      opacity={1}
      stroke="none"
      d=" M273.996521,343.554382  C270.422852,345.231750 266.662842,349.804901 263.299164,342.866760  C265.388092,339.200684 267.691589,336.096558 269.995087,332.992462  C270.488281,332.412415 270.981445,331.832367 271.730652,331.115845  C272.656952,335.017975 273.327148,339.056641 273.996521,343.554382  z"
    />
    <path
      fill="#C88251"
      opacity={1}
      stroke="none"
      d=" M269.623901,332.985718  C267.691589,336.096558 265.388092,339.200684 263.035645,342.646362  C261.175934,344.887207 259.365204,346.786499 257.250702,348.825073  C256.970795,341.960022 256.994690,334.955688 257.031464,327.484863  C259.354401,328.170227 261.658203,329.334900 263.976624,330.469788  C265.725616,331.325958 267.493317,332.143982 269.623901,332.985718  z"
    />
    <path
      fill="#C88251"
      opacity={1}
      stroke="none"
      d=" M249.398163,347.998718  C248.134430,347.491730 247.268631,346.990753 246.201630,346.259338  C245.669907,342.942841 245.339371,339.856781 245.015015,336.324158  C248.587784,340.046570 252.154388,344.215515 255.863800,348.688904  C253.936447,348.663757 251.866287,348.334259 249.398163,347.998718  z"
    />
  </svg>
);
export default AwardIcon;
