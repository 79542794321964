function MaintenanceMode() {
    return (
        <div className='maintenance-mode-container'>
            <article>
                <svg className='maintenance-mode-svg' xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 96 960 960" width="50"><path d="M764 936 523.666 695.666l66-65.999L830 870.001 764 936Zm-571.334 0-65.999-65.999L412 584.667l-94-94.001-24.667 24.667L247 469v84l-25.333 25.333L100 456.666l25.333-25.333H210l-48.667-48.667L296 248q18-18 39-25t45-7q24 0 45 8.667 21 8.666 38.999 26.666l-102 102.001L410.666 402l-25.333 25.333 92 92.001L588.667 408q-6.667-12.333-10.5-27.667-3.833-15.333-3.833-32 0-55 39.166-94.166Q652.667 215 707.667 215q15 0 26.5 3T755 226.333L665.334 316l74 74L829 300.333q5.667 10 8.833 22.167Q841 334.667 841 349.667q0 54.999-39.167 94.166Q762.667 483 707.667 483q-16 0-28.667-2.334-12.667-2.333-23.667-7.333L192.666 936Z" /></svg>
                <h1>We&rsquo;ll be back soon!</h1>
                <div>
                    <p>Sorry for the inconvenience. We&rsquo;re performing some maintenance at the moment. If you need to you can always follow us on for updates, otherwise we&rsquo;ll be back up shortly!</p>
                    <p>&mdash; The SpreeCine Team</p>
                </div>
            </article>

            <div className="inform-form">
                <div>
                    <p className="m1-txt1 p-b-36">
                        Follow us for update now!
                    </p>

                    <form>
                        <div className="wrap-input100 m-b-10">
                            <input className="s2-txt1 placeholder0 input100" type="text" name="name" placeholder="Your Name" />
                            <span className="focus-input100"></span>
                        </div>

                        <div className="wrap-input100 m-b-20">
                            <input className="s2-txt1 placeholder0 input100" type="text" name="email" placeholder="Email Address" />
                            <span className="focus-input100"></span>
                        </div>

                        <div>
                            <button type='button' className="s2-txt2 size4 bg1 bor1 hov1 trans-04">
                                Subscribe
                            </button>
                        </div>
                    </form>

                    <p className="s2-txt3 p-t-18">
                        Your email will only be used once just to inform you.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default MaintenanceMode;