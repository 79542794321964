import { Link } from "react-router-dom";
import { useState, useEffect } from "react";


import "./pageNotFound.css";
import PageNotFoundSVGIcon from "./PageNotFoundIcon";
import pageNotFoundLines from "./pageNotFoundLinesList";


function PageNotFound() {
    const [selectedCatchyLine, setSelectedCatchyLine] = useState("")

    const getRandomCatchyLine = () => {
        const randomIndex = Math.floor(Math.random() * pageNotFoundLines.length);
        setSelectedCatchyLine(pageNotFoundLines[randomIndex]);
    };

    useEffect(() => {
        getRandomCatchyLine()
    }, [])

    return (
        <div className="not-found--container">
            <PageNotFoundSVGIcon />
            <p className="error-message-not-found">{selectedCatchyLine}</p>
            <Link to="/" className="not-found-go-home">Back to Home</Link>
        </div>
    )
}

export default PageNotFound;