import React from "react";
import axios from 'axios';
import { t } from "i18next";
import { SegmentedControl, Skeleton } from '@mantine/core';


import './upshot.css';
import MaintenanceMode from '../Preventation/Maintenance/maintenance';
import withRouter from "../helpers/withRouter";
import { GlobalModalContext } from "../../MainInterface/contexts/FeedbackForEverything/FeedbackForEverythingProvider";
import UpShotListDetailContainerBox from "./UpShotList/UpShotListDetailContainerBox";
import RemarkContainer from "./RemarkContainer";
import LayoutComponentForHelmet from "../../MainInterface/SEO/LayoutComponentForHelmet";
import { defaultBackendOrigin } from "../constants/constants";

class UpShot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchedUpShot: [],
            likedUpShots: new Array(0).fill(false),
            likedRemarks: new Array(0).fill(false),
            replyRemarkInputOpen: new Array(0).fill(false),
            remarkInUpShot: new Array(0).fill(false),
            addedAsFavoriteUpShot: new Array(0).fill(false),

            isRemarkOneLoading: false,
            remarkOneFetchError: null,
            fetchedRemarkList: [],
            remarkSortDropdownValue: "popular",

            fetchError: false,
            fetchLoading: false,
            hasMore: true,
            offset: 0,
            limit: 10,
            height: window.innerHeight,
            isRemarkContainerVisible: false,

            filterByCategory: "upshots",
            filterByFrom: "everywhere",
            sortBy: "newest",

            isMaintenanceOn: false,
            addedUserInAudience: new Array(0).fill(false),

            trendingContentData: [],
            isRequestUserAuthenticated: false,
        }
        window.onscroll = (e) => {
            const {
                loadUpShots,
                state: { fetchError, fetchLoading, hasMore, sortBy, filterByFrom, filterByCategory }
            } = this;
            if (fetchError || fetchLoading || !hasMore) return;
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) {
                loadUpShots(sortBy, filterByFrom, filterByCategory);
            }
        }
        this.handleScroll = this.handleScroll.bind(this);
    };

    handleScroll() {
        const { sortBy, filterByFrom, filterByCategory, fetchLoading } = this.state;

        // * Calculating the distance from the bottom
        const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
        const distanceFromBottom = scrollableHeight - window.scrollY;
        const threshold = 700; // * Adjust this value based on desired distance in pixels. It was 300 but it was not very much space from bottom so changed to 700.

        // * Check if the distance is less than or equal to the threshold.
        if (distanceFromBottom <= threshold && !fetchLoading) {
            if (this.state.hasMore) {
                this.loadUpShots(sortBy, filterByFrom, filterByCategory);
            }
        }
    }

    // content_sort_by=${value}&popular_since=month
    loadUpShots = (sortBy, filterByFrom, filterByCategory) => {
        this.setState({ fetchLoading: true }, () => {
            const { offset, limit } = this.state;
            axios.get(`/api/content/main/upshot/?content_type=${filterByCategory}&content_sort_by=${sortBy}&get_from=${filterByFrom}&limit=${limit}&offset=${offset}`
            ).then((res) => {
                const newupshots = res?.data?.content;
                const hasMore = res.data.has_more;
                if (res.data.is_r_u_athtcted) {
                    this.setState({
                        isRequestUserAuthenticated: res.data.is_r_u_athtcted
                    })
                };
                // this.setState(prevState => ({
                //     hasMore,
                //     fetchLoading: false,
                //     fetchedUpShot: [...new Set([...prevState.fetchedUpShot, ...newupshots])],
                //     offset: offset + limit,
                // }))
                if (filterByCategory === "upshots") {
                    this.setState(prevState => {
                        const fetchedUpshots = prevState.fetchedUpShot || [];
                        const newDataUpShots = res.data?.content || [];
                        const uniqueFetchedContent = [
                            ...fetchedUpshots,
                            ...newDataUpShots.filter(newUpShot =>
                                !fetchedUpshots.some(oldBlog => oldBlog.id === newUpShot.id)
                            )
                        ]
                        return {
                            fetchLoading: false,
                            hasMore: hasMore,
                            fetchedUpShot: uniqueFetchedContent,
                            offset: offset + limit
                        };
                    });
                } else {
                    this.setState(prevState => {
                        const fetchedRemarks = prevState.fetchedRemarkList || [];
                        const newDataRemarks = res.data?.content || [];
                        const uniqueFetchedContent = [
                            ...fetchedRemarks,
                            ...newDataRemarks.filter(newRemark =>
                                !fetchedRemarks.some(oldRemark => oldRemark.id === newRemark.id)
                            )
                        ];
                        return {
                            fetchLoading: false,
                            hasMore: hasMore,
                            fetchedRemarkList: uniqueFetchedContent,
                            offset: offset + limit,
                        };
                    });
                }
                const haveRemarkedUpShots = res.data.upshots.filter(upshot => upshot.if_user_have_any_remark).map(upshot => upshot.id);
                this.setState({
                    remarkInUpShot: haveRemarkedUpShots,
                })
            })
                .catch(() => {
                    this.setState({
                        fetchError: "Something went Wrong",
                        fetchLoading: false,
                    })
                })
        })
    }


    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);

        const searchParams = new URLSearchParams(this.props.router.location.search);
        const sortBy = searchParams.get("by");
        const filterByFrom = searchParams.get("from");
        const filterByCategory = searchParams.get("byCategory");
        this.updateFilterByCategory(filterByCategory === "remarks" ? "remarks" : "upshots");
        this.updateSortBy(sortBy === "trending" ? "trending" : "newest");
        this.updateFilterByFrom(filterByFrom === "audience" ? "audience" : "everywhere");
    }

    updateSortBy(value) {
        this.setState(prevState => ({
            ...prevState,
            sortBy: value,
            offset: 0,
            fetchedUpShot: [],
        }));
        this.loadUpShots(value, this.state.filterByFrom, this.state.filterByCategory);
        this.props.router.navigate(`/upshots/?by=${value}&from=${this.state.filterByFrom}&byCategory=${this.state.filterByCategory}`);
    }

    updateFilterByFrom(value) {
        this.setState(prevState => ({
            ...prevState,
            filterByFrom: value,
            offset: 0,
            fetchedUpShot: [],
        }));
        this.loadUpShots(this.state.sortBy, value, this.state.filterByCategory);
        this.props.router.navigate(`/upshots/?by=${this.state.sortBy}&from=${value}&byCategory=${this.state.filterByCategory}`);
    }

    updateFilterByCategory(value) {
        this.setState(prevState => ({
            ...prevState,
            filterByCategory: value,
            offset: 0,
            fetchedUpShot: [],
        }));
        this.loadUpShots(this.state.sortBy, this.state.filterByFrom, value);
        this.props.router.navigate(`/upshots/?by=${this.state.sortBy}&from=${this.state.filterByFrom}&byCategory=${value}`);
    }

    openRemarkReplyInput(id) {
        const { replyRemarkInputOpen } = this.state;
        const remark = [...replyRemarkInputOpen];
        remark[id] = !remark[id];
        this.setState({ replyRemarkInputOpen: remark });
    }

    render() {
        const { isMaintenanceOn, remarkInUpShot, filterByCategory, isRequestUserAuthenticated } = this.state;

        const contentFilterOptions = ['UpShots', 'Remarks'].map(option => t(option));
        const contentSortingOptions = ['Trending', 'Newest'].map(option => t(option));
        const contentFilterFromOptions = ['From Everywhere', 'Audience'].map(option => t(option));

        if (isMaintenanceOn) {
            return <MaintenanceMode />;
        }
        return (
            <LayoutComponentForHelmet pageTitle={"UpShots - SpreeCine"}>
                <div className="body-space body-space-margin-top">
                    <div className="filter-header">
                        <SegmentedControl fullWidth radius="md" data={contentFilterOptions} onChange={(e) => this.updateFilterByCategory(e.toLowerCase())} />
                        <br />
                        <SegmentedControl fullWidth radius="md" data={contentSortingOptions} onChange={(e) => this.updateSortBy(e.toLowerCase())} />
                        <br />
                        <SegmentedControl fullWidth radius="md" data={contentFilterFromOptions} onChange={(e) => this.updateFilterByFrom(e === "From Everywhere" ? "everywhere" : "audience")} />
                        {/* <div className=" make-text-cursor" onClick={openModal}><FeedbackForEverythingIcon /></div> */}
                    </div>
                    {/* <Skeleton height={8} radius="xl" />
                    <Skeleton height={15} mt={6} radius="sm" />
                    <Skeleton height={8} mt={6} width="70%" radius="xl" /> */}

                    {/* <Skeleton height={50} circle mb="15px" />
                    <Skeleton height={10} radius="15px" />
                    <Skeleton height={20} mt={6} radius="15px" /> */}

                    {filterByCategory === "upshots" ?
                        this.state.fetchedUpShot?.map((res, index) =>
                            <div className="upshots-list-v2">
                                <UpShotListDetailContainerBox
                                    index={index}
                                    res={res}
                                    currentUserUsername={this.props.currentUserUsername}
                                    remarkInUpShot={remarkInUpShot}
                                    currentUserProfileImage={this.props.currentUserProfileImage}
                                />
                            </div>
                        )
                        :
                        this.state.fetchedRemarkList?.map((res) =>
                            <div className="remark-list" key={res.id}>
                                <RemarkContainer remark={res} />
                            </div>
                        )
                    }
                </div>
            </LayoutComponentForHelmet>
        )
    }
}


export default withRouter(UpShot);