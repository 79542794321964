import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import '../styles/adminDashboardUsers.css';
import { useSearchParams, createSearchParams, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { adminApiUrls } from "../api/api";

function DashboardUsers() {
    const params = useParams();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const [isFurtherAvailable, setIsFurtherAvailable] = useState(true);
    const [isBackAvailable, setIsBackAvailable] = useState(false);

    const [totalUsers, setTotalUsers] = useState(0);

    const [isUserDropdownOpen, setUserDropdownOpen] = useState(false);
    const [userDropdownOpenList, setUserDropdownList] = useState(new Array(0).fill(false));

    const [currentPage, setCurrentPage] = useState(1);
    const [usersFetchState, setUsersFetchState] = useState({
        isUsersFetchLoading: false,
        usersFetchError: "",
        totalNumberOfUsers: 0,

        fetchedUsers: [],
    })
    const adminDashboardUrls = adminApiUrls.adminUsers;

    const fetchUsers = (pageNumber) => {
        const usersListUrl = adminDashboardUrls.getUsersList(params.id, pageNumber);

        axios.get(usersListUrl).then((res) => {
            console.log(res);
            console.log(res.data.results);

            if (res.data.next !== null) {
                setIsFurtherAvailable(true);
            } else {
                setIsFurtherAvailable(false);
            }
            if (res.data.previous !== null) {
                setIsBackAvailable(true);
            } else {
                setIsBackAvailable(false);
            }
            console.log(res.data.results.length)
            setTotalUsers(res.data.results.length);

            setUsersFetchState(prevState => ({
                ...prevState,
                isUsersFetchLoading: false,
                usersFetchError: "",
                fetchedUsers: res.data.results,
                totalNumberOfUsers: res.data.count
            }))
        }).catch((err) => {
            console.log(err);
        })
    }

    const urlParams = {
        page: currentPage + 1,
    };
    const options = {
        pathname: `/admin/dashboard/users/${params.id}/`,
        search: `?${createSearchParams(urlParams)}`
    }
    const goToNextPage = () => {
        setCurrentPage(currentPage + 1)
        fetchUsers(currentPage + 1)
        navigate(options, { replace: true });
    }



    const urlPrevParams = {
        page: currentPage - 1,
    };
    const prevOptions = {
        pathname: `/admin/dashboard/users/${params.id}/`,
        search: `?${createSearchParams(urlPrevParams)}`
    }
    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1)
        fetchUsers(currentPage - 1)
        navigate(prevOptions, { replace: true });
    }

    const openUserDropdown = (userId) => {
        const user = [...userDropdownOpenList];
        const updatedOpenMenu = userDropdownOpenList.map(() => false);
        updatedOpenMenu[userId] = !user[userId];
        setUserDropdownList(updatedOpenMenu)
    }

    useEffect(() => {
        const currentPageFromUrl = searchParams.get("page");
        fetchUsers(currentPageFromUrl);
    }, [])

    return (
        <div className="text-clr-white">
            {
                usersFetchState.fetchedUsers?.map((user, index) =>
                    <div key={user.id}>
                        <b className="user-dropdown-toggle"><svg xmlns="http://www.w3.org/2000/svg" onClick={() => openUserDropdown(user.id)} height="24" viewBox="0 96 960 960" width="24"><path d="M480 896q-33 0-56.5-23.5T400 816q0-33 23.5-56.5T480 736q33 0 56.5 23.5T560 816q0 33-23.5 56.5T480 896Zm0-240q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm0-240q-33 0-56.5-23.5T400 336q0-33 23.5-56.5T480 256q33 0 56.5 23.5T560 336q0 33-23.5 56.5T480 416Z" /></svg></b>
                        <div className={`admin-user-dropdown ${userDropdownOpenList[user.id] ? `svg-active` : `display-none`}`}>
                            <a target="_blank" href={`/admin/mains/profile/${user.id}/`}>Go to User Profile in Admin</a>
                            <a target="_blank" href={`/admin/auth/user/${user.id}/`}>Go to User in Admin</a>
                            <Link to={`/admin/dashboard/user/${sessionStorage.getItem("aamt")}/${user.id}/${user.username}`} className="blue-background-on-hover">View Activity/Reports</Link>
                            {/* <a href="#">{user.last_login_naturaltime}(Last Login)</a> */}
                        </div>
                        {index + 1}.
                        {user.username} <small>({user.id})</small> <span className="user-joined-data">{user.natural_time}</span> <span className="user-email-data">{user.email}</span>
                        <br />
                        <br />
                    </div>
                )
            }
            {
                isFurtherAvailable &&
                <Button variant="secondary" onClick={goToNextPage}>Go to next page</Button>
            }
            <br />
            {
                isBackAvailable &&
                <Button variant="secondary" onClick={goToPreviousPage}>Go to previous page</Button>
            }
        </div>
    )
}

export default DashboardUsers;