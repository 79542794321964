import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { Button, Modal } from "@mantine/core";

function ImpersonateReportModal({ isImpersonationReportModalOpen, setImpersonationReportModalOpen, setMainReportShow, setSelectedReasonStepTwo, openAdditionalInfo }) {
    const { t } = useTranslation();
    const [selectedImpersonateReason, setSelectedImpersonateReason] = useState("");

    const backToMainReportModal = () => {
        setImpersonationReportModalOpen(false);
        setMainReportShow(true);
    }

    const updateSelectedReportReason = (value) => {
        if (value !== undefined && value !== null) {
            setSelectedImpersonateReason(value);
            setSelectedReasonStepTwo(value);
        }
    }

    return (
        <>
            <Modal opened={isImpersonationReportModalOpen} onClose={backToMainReportModal} radius="10px" size="xl" title={t("Impersonation or Fake profiles")} centered>
                <div className="report-area">
                    <form onClick={(e) => updateSelectedReportReason(e.target.getAttribute("data-value"))}>
                        <ul>
                            <li className={`${selectedImpersonateReason === "INCIFO" && `selected`}`} data-value="INCIFO">{t("Inaccurate information")}</li>
                            <li className={`${selectedImpersonateReason === "FLSIDNTYMSRPSTN" && `selected`}`} data-value="FLSIDNTYMSRPSTN">{t("False identity or misrepresentation")}</li>
                            <li className={`${selectedImpersonateReason === "FRDBHVR" && `selected`}`} data-value="FRDBHVR">{t("Fraudulent or deceptive behavior")}</li>
                            <li className={`${selectedImpersonateReason === "OTRFRMOMPRPRFLE" && `selected`}`} data-value="OTRFRMOMPRPRFLE">{t("Other forms of impersonation or fake profiles")}</li>
                            <Button variant='filled' color='rgb(103, 103, 222)' fullWidth onClick={openAdditionalInfo} disabled={selectedImpersonateReason === "" ? true : false}>{t("I'm sure")}</Button>
                        </ul>
                    </form>
                </div>
            </Modal>
        </>
    )
}

export default ImpersonateReportModal;