import "./popoverCustom.css";
import { Group, HoverCard, Text } from "@mantine/core";


function PopoverCustom({ t, messageTitle, messageBody }) {

    return (
        <HoverCard width={280} shadow="md">
            <HoverCard.Target>
                <svg className='popup-button svg-inline' xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15">
                    <path d="M444-288h72v-240h-72v240Zm35.789-312Q495-600 505.5-610.289q10.5-10.29 10.5-25.5Q516-651 505.711-661.5q-10.29-10.5-25.5-10.5Q465-672 454.5-661.711q-10.5 10.29-10.5 25.5Q444-621 454.289-610.5q10.29 10.5 25.5 10.5Zm.487 504Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z" />
                </svg>
            </HoverCard.Target>
            <HoverCard.Dropdown>
                <Text size="sm" c={"white"}>
                    {t(messageBody)}
                </Text>
            </HoverCard.Dropdown>
        </HoverCard>
    )
}

export default PopoverCustom;