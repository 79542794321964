
function BigTextField({ inputValue, setInputValue }) {

    const updateTextAreaHeight = (event) => {
        event.target.style.height = "auto";
        event.target.style.height = `${event.target.scrollHeight}px`;
    }

    return (
        <div className='big-text-field-container'>
            <textarea
                className="big-text-field-with-hgt-adjstng"
                placeholder="..."
                defaultValue={inputValue}
                // // onChange={handleInputChange}
                // onInput={(e) => setInputValue(e)}
                onChange={(e) => {
                    setInputValue(e);
                    updateTextAreaHeight(e);
                }}
                onInput={updateTextAreaHeight}
            />
        </div>
    )
}

export default BigTextField;