// const DEBUG = process.env.REACT_APP_DEBUG === "true" ? true : false;
const DEBUG = true;

let defaultOrigin;
let defaultBackendOrigin;
let defaultBackendWebSocketOrigin;
let gRecaptchaPublicKey;

if (DEBUG) {
    defaultOrigin = "http://localhost:3000";
    defaultBackendOrigin = "http://127.0.0.1:8000";
    defaultBackendWebSocketOrigin = "127.0.0.1:8000";
    gRecaptchaPublicKey = "6Ld62H8oAAAAACSIwUPrk9VnzOSDzn_gtwZ6DZr0"
} else {
    defaultOrigin = "https://www.spreecine.com"
    defaultBackendOrigin = "https://api.spreecine.com";
    defaultBackendWebSocketOrigin = "api.spreecine.com";
    gRecaptchaPublicKey = process.env.REACT_APP_GRECAPTCHA_PUBLIC_KEY_PROD
}

export const isPremiumEnabled = true;

export const changeImageSlideshowInSeconds = 10000 // 10 Seconds

export const defaultPlaceholderImageIfNotPresentAny = "https://spreeproductionbucket.s3.ap-south-1.amazonaws.com/media/constants/f/Placeholder_icon_cropped_center.png"

export const pagesToHideHeaderOn = ["/help", "/contact", "/login", "/signup"];

export const pagesToCollapseSidebar = ["/upshot"];

export const randomNamesForSearchInputPlaceholderList = ["The Mist", "The Shawshank Redemption", "Finch"];

export const SpreeCinePremiumLemonSqueenzyProductId = "6c456dc6-50da-421f-9f61-3c010ed0f916"
export const SpreeCinePremiumLemonSqueenzyButtonLink = `https://live.lemonsqueezy.com/checkout/buy/${SpreeCinePremiumLemonSqueenzyProductId}`

export { defaultOrigin, defaultBackendOrigin, defaultBackendWebSocketOrigin, DEBUG, gRecaptchaPublicKey };