import { useEffect, useState } from "react";
import axios from "axios";

import BigTextField from "../../HTMLComponents/BigTextField";
import CustomButton from "../../HTMLComponents/Button";
import LoadingIfElseErrorSvg from "../../animations/LoadingIfElseErrorSvg";
import extractAppropriateIconFromKeyWord from "../../helpers/extractAppropriateIconFromKeyWord";
import pluralize from "../../helpers/pluralize";
import ConfirmationDialog from "../../modals/ConfirmationModal";
import RemarkContextMenu from "../RemarkContextMenu";
import RemarkAwardPopover from "../RemarkAwardPopover"
import showToastError from "../../helpers/showToastError";
import { defaultPlaceholderImageIfNotPresentAny } from "../../constants/constants";
import { obfuscateData } from "../helpers/obfuscateData";
import UpShotRemarkCommentList from "./UpShotRemarkCommentList";
import UserOnPremiumBadgeIcon from "../../Profile/icons/UserOnPremiumBadgeIcon"

function SingleRemarkFetch({
    t,
    singleRemarkFetchData,
    state,
    sendAwardOnRemark,
    openShareModal,
    openReportModal,
    fetchedUpShotDetails,
    addMeInUserAudience,
    updateRemarkListAfterRemarkEdit,
    removeRemarkFromRemarkListAfterDeletion,
    scrollToRemarkMainCommentRef,
    handleDoubleClick,

    isForComment,
    commentHighlightId,
}) {
    const [remarkCommentFetchState, setRemarkCommentFetchState] = useState({
        fetchRemarkComments: [],
        openRemarkCommentsSections: [],
        whichRemarkCommentFetchIsLoading: [],
    });
    const [likedRemarksState, setLikedRemarksState] = useState({});
    const [remarkCommentState, setRemarkCommentState] = useState({
        openedRemarkCommentInputBoxes: {},
        commentTextValue: "",

        isCommentPostLoading: false,
        commentPostError: "",
    });
    const [openedRemarkRewardOverlays, setOpenedRemarkRewardOverlays] = useState([]);
    const [remarkEditState, setRemarkEditState] = useState({
        isRemarkEditSpaceOpen: false,
        editRemarkOpenId: 0,
        editRemarkValue: ""
    });
    const [remarkDeleteState, setRemarkDeleteState] = useState({
        isRemarkDeletionDialogOpen: false,

        isDeleteLoading: false,
        deleteError: "",
        currentDeletionId: 0,
    });
    const [remarkAwardState, setRemarkAwardState] = useState({
        rewardedAwardModalOpenState: [],
        fetchedAwardsList: [],
    })

    useEffect(() => {
        if (isForComment) {
            openRemarkCommentSection(singleRemarkFetchData.id);
        }
    }, [isForComment])


    const deleteRemarkConfirmationDialog = (remarkId) => {
        setRemarkDeleteState({
            isRemarkDeletionDialogOpen: true,
            currentDeletionId: remarkId
        })
    }
    const confirmDeletion = () => {
        setRemarkDeleteState({
            isDeleteLoading: true,
            deleteError: "",
        })
        axios.delete(`/api/content/main/remark/delete/${remarkDeleteState.currentDeletionId}/`).then(() => {
            setRemarkDeleteState({
                isDeleteLoading: false,
                deleteError: "",
                isRemarkDeletionDialogOpen: false,
                deleteError: "",
            })
            // ? Delay some time to completely delete the element from state.
            removeRemarkFromRemarkListAfterDeletion(remarkDeleteState.currentDeletionId)
            showToastError("Remark successfully deleted", "success")
        }).catch(() => {
            setRemarkDeleteState({
                isDeleteLoading: false,
                deleteError: "Something went Wrong!",
            })
        })
    }


    const openRemarkDialog = (remarkId, remarkContent) => {
        setRemarkEditState({
            isRemarkEditSpaceOpen: true,
            editRemarkOpenId: remarkId,
            editRemarkValue: remarkContent,
        })
    }
    const discardMainRemarkEditChanges = () => {
        setRemarkEditState({
            isRemarkEditSpaceOpen: false,
            editRemarkOpenId: 0,
            editRemarkValue: "",
        })
    }
    const updateRemarkEditValue = (event) => {
        setRemarkEditState(prevState => ({
            ...prevState,
            editRemarkValue: event.target.value
        }))
    }
    const saveEditedRemark = (remarkId) => {
        const headersList = {
            "Accept": "application/json",
            "Content-Type": "multipart/form-data"
        }
        const dataToPut = {
            content: remarkEditState.editRemarkValue,
        }
        const data = obfuscateData(dataToPut);
        axios.put(`/api/content/main/update/remark/${remarkId}/`, { data }, { headers: headersList }).then((res) => {
            setRemarkEditState(prevState => ({
                ...prevState,
                isRemarkEditSpaceOpen: false,
                editRemarkOpenId: 0,
            }))
            updateRemarkListAfterRemarkEdit(remarkId, remarkEditState.editRemarkValue)
        }).catch(() => {
            showToastError("Failed to edit Remark. Try again", "error")
        })
    }



    const openCloseRemarkRewardOverlay = (remarkId) => {
        const remark = [...openedRemarkRewardOverlays];
        remark[remarkId] = !remark[remarkId];
        setOpenedRemarkRewardOverlays(remark);
    }

    const makeRemarkLikeAnimation = (id) => {
        setLikedRemarksState(prevLikedComments => ({
            ...prevLikedComments,
            [id]: !prevLikedComments[id],
        }));
    };
    const likedRemarks = (id) => {
        makeRemarkLikeAnimation(id);
        axios.post(`/api/content/main/remark/${id}/like/`).then((res) => {
            // todo: Save taste.
            // this.saveTaste("liked")
        }).catch((err) => {
            this.makeRemarkLikeAnimation(id);
            if (err.response.status === 401) {
                showToastError("You're Banned. You cannot like Remarks.", "error")
            }
        })
    }

    const openedCloseRemarkCommentForm = (remarkId) => {
        const { openedRemarkCommentInputBoxes } = remarkCommentState;
        const updatedOpenMenu = { ...openedRemarkCommentInputBoxes };
        updatedOpenMenu[remarkId] = !openedRemarkCommentInputBoxes[remarkId];
        setRemarkCommentState({
            ...remarkCommentState,
            openedRemarkCommentInputBoxes: updatedOpenMenu,
            commentTextValue: "",
        });
    }

    const handleRemarkCommentTextFieldChange = (e) => {
        setRemarkCommentState(prevState => ({
            ...prevState,
            commentTextValue: e.target.value
        }))
    }

    const submitSaveRemarkComment = (remarkId) => {
        const { commentTextValue } = remarkCommentState;
        if (commentTextValue !== "") {
            setRemarkCommentState({
                isCommentPostLoading: true,
                commentPostError: "",
            })
            const dateToPost = ({
                comment_body: commentTextValue,
                remark_comment: remarkId,
            })
            axios.post(`/api/content/main/c/u/d/g/remark-comment/${remarkId}/`, dateToPost).then((res) => {
                // todo: Check if it is working and setting state for another class component.
                // this.setState(prevState => ({
                //     ...prevState,
                //     fetchedRemarks: prevState.fetchedRemarks.map((remark) => {
                //         if (remark.id === remarkId) {
                //             return { ...remark, count_remark_comment: remark.count_remark_comment + 1 }
                //         }
                //         return remark
                //     }),
                //     fetchRemarkComments: [res.data, ...prevState.fetchRemarkComments],
                // }))

                setRemarkCommentState({
                    isCommentPostLoading: false,
                    commentPostError: "",
                    commentTextValue: "",
                })
            }).catch((err) => {
                setRemarkCommentState({
                    isCommentPostLoading: false,
                    commentPostError: "Something went Wrong!",
                })
                if (err.response.status === 401) {
                    showToastError("You're Banned. You cannot post comment.", "error")
                }
            })
        }
    }

    const openRemarkCommentSection = (id) => {
        setRemarkCommentFetchState((prevComState) => {
            const updatedCommentSec = [...prevComState.openRemarkCommentsSections];
            updatedCommentSec[id] = !updatedCommentSec[id];
            return {
                ...prevComState,
                openRemarkCommentsSections: updatedCommentSec,
            };
        });

        // fetchRemarkCommentFromServer(remarkId, updatedCommentSection[remarkId]);
        fetchRemarkCommentFromServer(id, id);
    }
    const fetchRemarkCommentFromServer = (remarkId, openRemarkCommentsSections) => {
        if (openRemarkCommentsSections) {
            setRemarkCommentFetchState(prevState => ({
                ...prevState,
                whichRemarkCommentFetchIsLoading: [...prevState.whichRemarkCommentFetchIsLoading, remarkId]
            }))
            axios.get(`/api/content/main/c/u/d/g/remark-comment/${remarkId}/`).then((res) => {
                if (Array.isArray(res.data) && res.data.length > 0) {
                    setRemarkCommentFetchState(prevState => ({
                        ...prevState,
                        fetchRemarkComments: res.data,
                        whichRemarkCommentFetchIsLoading: prevState.whichRemarkCommentFetchIsLoading.filter((id) => id !== remarkId)
                    }))
                }
            }).catch(() => {
                setRemarkCommentFetchState(prevState => ({
                    whichRemarkCommentFetchIsLoading: prevState.whichRemarkCommentFetchIsLoading.filter((id) => id !== remarkId)
                }))
            })
        }
    }

    const openRemarkRewardedAwardModal = (remarkId) => {
        const remark = [...remarkAwardState.rewardedAwardModalOpenState];
        remark[remarkId] = !remark[remarkId];
        setRemarkAwardState({ rewardedAwardModalOpenState: remark });
        // ! Fetch all awards of a remark for the container.
        fetchAwardsList(remarkId, remarkAwardState.rewardedAwardModalOpenState[remarkId]);
    }
    const fetchAwardsList = (remarkId, rewardedAwardModalOpenState) => {
        if (!rewardedAwardModalOpenState) {
            axios.get(`/api/content/main/remark-award/${remarkId}/`).then((res) => {
                setRemarkAwardState(prevState => ({
                    ...prevState,
                    fetchedAwardsList: res.data
                }))
            }).catch(() => {
                showToastError("Unable to fetch awards", "error")
            })
        }
    }
    const removeFromState = (awardId) => {
        const newState = remarkAwardState.fetchedAwardsList.filter(item => item.rewarded_description[0].award_id !== awardId);
        setRemarkAwardState(prevState => ({
            ...prevState,
            fetchedAwardsList: newState
        }))
    }
    const removeRemarkAward = (awardId) => {
        axios.delete(`/api/content/main/remove-remark-award/${awardId}/`).then(() => {
            removeFromState(awardId);
        }).catch(() => {
            showToastError("Something went wrong! Try again later", "error")
        })
    }

    return (
        <div className="upshot-comment" data-remark-id={singleRemarkFetchData.id}>
            {
                singleRemarkFetchData.user_profile_image ?
                    <img className="comment-user-avatar" src={`${singleRemarkFetchData.user_profile_image}`} alt={`${singleRemarkFetchData.posted_by} Remark Profile`} />
                    :
                    <img className="comment-user-avatar" src={defaultPlaceholderImageIfNotPresentAny} alt={`${singleRemarkFetchData.posted_by} Remark Profile`} />
            }
            <div className="remark-body">
                <span onClick={(event) => handleDoubleClick(event, singleRemarkFetchData.id, "remark")}>
                    <div className="comment-content">
                        <b className="remark-user-main">
                            {singleRemarkFetchData.profile_name} <small>@{singleRemarkFetchData.user_username}</small>
                            {singleRemarkFetchData.show_premium_badge_on_profile ?
                                <span className='premium-profile-badge'><UserOnPremiumBadgeIcon /></span>
                                :
                                null
                            }
                        </b>
                        <div className="comment-header">
                            <span className="comment-author">
                                <span className='awards-inline-list'>
                                    {
                                        state.remarkAwardState?.map((award) =>
                                            <span key={award?.id}>
                                                {
                                                    singleRemarkFetchData?.id === award?.remark_id &&
                                                    <span key={award?.id}>
                                                        <span className='make-text-cursor' title={"Awarded by " + award?.awarded_by?.map((username) => username).join(', ')} onClick={() => openRemarkRewardedAwardModal(singleRemarkFetchData?.id)}>
                                                            {extractAppropriateIconFromKeyWord(award.award_type)}
                                                        </span>
                                                    </span>
                                                }
                                            </span>
                                        )
                                    }
                                </span>
                            </span>
                            <span className="comment-time">{singleRemarkFetchData?.remark_timestamp}</span>
                        </div>
                        {
                            remarkEditState.isRemarkEditSpaceOpen && remarkEditState.editRemarkOpenId === singleRemarkFetchData.id ?
                                <>
                                    <BigTextField inputValue={remarkEditState.editRemarkValue} setInputValue={updateRemarkEditValue} />
                                    <div className='button-wrapper'>
                                        <CustomButton onClick={discardMainRemarkEditChanges} label={t("Discard")} customclassnames={"discard-button"} />
                                        <CustomButton onClick={() => saveEditedRemark(singleRemarkFetchData.id)} label={t("Save")} customclassnames={"edit-save-button"} />
                                    </div>
                                </>
                                :
                                <p>{singleRemarkFetchData.content}</p>
                        }
                    </div>
                    <div className="comment-actions">
                        <span>
                            {
                                singleRemarkFetchData?.remark_like?.some((like) => like.liked_to === singleRemarkFetchData.id)
                                    ?
                                    <>
                                        {
                                            likedRemarksState[singleRemarkFetchData.id] ?
                                                <>
                                                    <svg onClick={() => likedRemarks(singleRemarkFetchData.id)} className={`make-text-cursor svg-inline`} xmlns="http://www.w3.org/2000/svg" height="20"
                                                        viewBox="0 -960 960 960" width="20">
                                                        <path
                                                            d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Zm0-97q93-83 153-141.5t95.5-102Q764-528 778-562t14-67q0-59-40-99t-99-40q-35 0-65.5 14.5T535-713l-35 41h-40l-35-41q-22-26-53.5-40.5T307-768q-59 0-99 40t-40 99q0 33 13 65.5t47.5 75.5q34.5 43 95 102T480-241Zm0-264Z" />
                                                    </svg>
                                                    {likedRemarksState[singleRemarkFetchData.id] ? <>{singleRemarkFetchData.count_remark_like - 1} {t("Like")} {pluralize(singleRemarkFetchData.count_remark_like - 1)}</> : <>{singleRemarkFetchData.count_remark_like} {t("Like")}{pluralize(singleRemarkFetchData.count_remark_like)}</>}
                                                </>
                                                :
                                                <>
                                                    <svg onClick={() => likedRemarks(singleRemarkFetchData.id)} className={`make-text-cursor svg-inline fill-purple`} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Z" /></svg>
                                                    {likedRemarksState[singleRemarkFetchData.id] ? <>{singleRemarkFetchData.count_remark_like + 1} {t("Like")} {pluralize(singleRemarkFetchData.count_remark_like + 1)})</> : <>{singleRemarkFetchData.count_remark_like} {t("Like")}{pluralize(singleRemarkFetchData.count_remark_like)}</>}
                                                </>
                                        }
                                    </>
                                    :
                                    <>
                                        {likedRemarksState[singleRemarkFetchData.id] ?
                                            <>
                                                <svg onClick={() => likedRemarks(singleRemarkFetchData.id)} className={`make-text-cursor svg-inline fill-purple`} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Z" /></svg>
                                                {likedRemarksState[singleRemarkFetchData.id] ? <>{singleRemarkFetchData.count_remark_like + 1} {t("Like")} {pluralize(singleRemarkFetchData.count_remark_like + 1)}</> : <>{singleRemarkFetchData.count_remark_like} {t("Like")}{pluralize(singleRemarkFetchData.count_remark_like)}</>}
                                            </>
                                            :
                                            <>
                                                <svg onClick={() => likedRemarks(singleRemarkFetchData.id)} className={`make-text-cursor svg-inline`} xmlns="http://www.w3.org/2000/svg" height="20"
                                                    viewBox="0 -960 960 960" width="20">
                                                    <path
                                                        d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Zm0-97q93-83 153-141.5t95.5-102Q764-528 778-562t14-67q0-59-40-99t-99-40q-35 0-65.5 14.5T535-713l-35 41h-40l-35-41q-22-26-53.5-40.5T307-768q-59 0-99 40t-40 99q0 33 13 65.5t47.5 75.5q34.5 43 95 102T480-241Zm0-264Z" />
                                                </svg>
                                                {state.likedRemarks[singleRemarkFetchData.id] ? <>{singleRemarkFetchData.count_remark_like + 1} {t("Like")} {pluralize(singleRemarkFetchData.count_remark_like + 1)}</> : <>{singleRemarkFetchData.count_remark_like} {t("Like")}{pluralize(singleRemarkFetchData.count_remark_like)}</>}
                                            </>
                                        }
                                    </>
                            }
                        </span>
                        <span>
                            <svg onClick={() => openedCloseRemarkCommentForm(singleRemarkFetchData.id)} className="svg-vertical-middle cursor-pointer" xmlns="http://www.w3.org/2000/svg" height="20"
                                viewBox="0 -960 960 960" width="20">
                                <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
                            </svg>
                            <small onClick={() => openedCloseRemarkCommentForm(singleRemarkFetchData.id)}>{t("Comment")}</small>
                        </span>
                        <span className="award">
                            <svg className="svg-vertical-middle" xmlns="http://www.w3.org/2000/svg" height="20"
                                viewBox="0 -960 960 960" width="20">
                                <path
                                    d="m347-72-75-124-141-32 13-144-96-108 96-108-13-144 141-32 75-124 133 57 133-57 75 124 141 32-13 144 96 108-96 108 13 144-141 32-75 124-133-57-133 57Zm29-91 104-44 104 44 58-97 110-25-10-111 74-84-74-84 10-111-110-25-58-97-104 44-104-44-58 97-110 24 10 112-74 84 75 84-11 112 110 25 58 96Zm104-317Zm-51 144 238-237-51-51-187 186-85-84-51 51 136 135Z" />
                            </svg>
                            <small>{t("Award")} ({singleRemarkFetchData.count_remark_award})</small>
                            <svg onClick={() => openCloseRemarkRewardOverlay(singleRemarkFetchData.id)} className="svg-vertical-middle cursor-pointer" xmlns="http://www.w3.org/2000/svg" height="20"
                                viewBox="0 -960 960 960" width="20">
                                <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
                            </svg>
                        </span>
                        {
                            singleRemarkFetchData.count_remark_comment > 0 &&
                            <span className="comment-actions cursor-pointer" onClick={() => openRemarkCommentSection(singleRemarkFetchData.id)}>
                                <svg className="svg-vertical-middle" xmlns="http://www.w3.org/2000/svg" height="20"
                                    viewBox="0 -960 960 960" width="20">
                                    <path
                                        d="M306-300 96-510l210-210 51 51-159 159 159 159-51 51Zm438 90v-144q0-50-35-85t-85-35H426l123 123-51 51-210-210 210-210 51 51-123 123h198q80 0 136 56t56 136v144h-72Z" />
                                </svg>
                                <small className="reply-button">{singleRemarkFetchData.count_remark_comment} {t("Comment")}{pluralize(singleRemarkFetchData.count_remark_comment)}</small>
                            </span>
                        }
                    </div>
                </span>

                <div className={`reply-form ${remarkCommentState.openedRemarkCommentInputBoxes?.[singleRemarkFetchData?.id] ? `show` : `display-none`}`}>
                    <input type='text' className='comment-input-field remark-place-input' placeholder='Type comment here.....' value={remarkCommentState.commentTextValue} onInput={(e) => handleRemarkCommentTextFieldChange(e)} />
                    <button type='button' className='place-comment-button' onClick={() => submitSaveRemarkComment(singleRemarkFetchData.id)}>
                        {t("Comment")}
                        <LoadingIfElseErrorSvg isLoading={remarkCommentState.isCommentPostLoading} errorString={remarkCommentState.commentPostError} classNamesForLoading={`error-retry retry-rotate fill-black`} classNamesForError={`svg-vertical-align fill-red`} />
                    </button>
                    <span className='err-message'>{remarkCommentState.commentPostError}</span>
                </div>

                <div className="remark-award-list" style={{ float: "right", marginTop: "-0.7em" }}>
                    <ul className={`dropdown-menu-award-list ${remarkAwardState.rewardedAwardModalOpenState[singleRemarkFetchData.id] ? `show` : `display-none`}`} aria-labelledby="dropdownMenuButton1">
                        <li>
                            {
                                remarkAwardState?.fetchedAwardsList?.map((award) => <>
                                    <div className="awards-separator" key={award.award_type}>{award.award_type}</div>
                                    {
                                        award.rewarded_description?.map((awardDetail, index) =>
                                            <div className="avatar-wrapper" key={awardDetail.awarded_by}>
                                                <img className='award-list-user-img' title={`${awardDetail.awarded_by} at ${awardDetail.timestamp}`} src={awardDetail.awarded_by_profile_picture} alt={`${award.awarded_by} Profile`} />
                                                {/* <img className='award-list-user-img' title={`${awardDetail.awarded_by} at ${awardDetail.timestamp}`} src={"https://www.w3schools.com/howto/img_avatar.png"} alt={`${award.awarded_by} Profile`} /> */}
                                                {awardDetail.is_current_user_same &&
                                                    <span type='button' className="cross-button-award" onClick={() => removeRemarkAward(awardDetail.award_id, awardDetail)}>&times;</span>
                                                }
                                            </div>
                                        )
                                    }
                                </>)
                            }
                        </li>
                    </ul>
                </div>

                <div className={`replies ${remarkCommentFetchState?.openRemarkCommentsSections?.[singleRemarkFetchData?.id] && "box-shadow-white-thin margin-top-15px"}`} ref={scrollToRemarkMainCommentRef} >
                    {
                        remarkCommentFetchState?.openRemarkCommentsSections?.[singleRemarkFetchData.id] && <>
                            {remarkCommentFetchState.whichRemarkCommentFetchIsLoading.includes(singleRemarkFetchData.id) && <p>Loading replies.....</p>}
                            {
                                remarkCommentFetchState?.fetchRemarkComments?.map((comment) => <>
                                    {comment.remark_comment === singleRemarkFetchData.id &&
                                        <UpShotRemarkCommentList
                                            t={t}
                                            comment={comment}
                                            fetchRemarkComments={remarkCommentFetchState?.fetchRemarkComments}
                                            setRemarkCommentFetchState={setRemarkCommentFetchState}
                                            remarkCommentFetchState={remarkCommentFetchState}
                                            openReportModal={openReportModal}
                                            openShareModal={openShareModal}
                                            addMeInUserAudience={addMeInUserAudience}
                                            upshotId={fetchedUpShotDetails.id}
                                            remarkId={singleRemarkFetchData.id}
                                            handleDoubleClick={handleDoubleClick}

                                            isForComment={isForComment}
                                            commentHighlightId={commentHighlightId}
                                        />
                                    }
                                </>)
                            }
                        </>
                    }
                    <div className="remark-award-dropdown" style={{ float: "right", marginTop: "-0.7em" }}>
                        <RemarkAwardPopover
                            remarkId={singleRemarkFetchData.id}
                            openedRemarkRewardOverlays={openedRemarkRewardOverlays}
                            sendAwardOnRemark={sendAwardOnRemark}
                            openCloseRemarkRewardOverlay={openCloseRemarkRewardOverlay}
                            isUserOnPremium={state.fetchedUpShotDetails.is_current_user_on_premium}
                            addMeInUserAudience={addMeInUserAudience}
                        />
                    </div>
                </div>
            </div>
            <RemarkContextMenu
                id={singleRemarkFetchData.id}
                userId={singleRemarkFetchData.remarked_by}
                userUsername={singleRemarkFetchData.posted_by}
                data={singleRemarkFetchData}
                isContentUserRequestUser={singleRemarkFetchData.is_remark_user_is_request_user}
                openShareModal={() => openShareModal(singleRemarkFetchData.id, fetchedUpShotDetails.id, singleRemarkFetchData.is_remark_user_is_request_user, "UR")}
                openReportModal={() => openReportModal(singleRemarkFetchData.id, "remark")}
                addMeInUserAudience={() => addMeInUserAudience(singleRemarkFetchData.remarked_by)}
                editContent={() => openRemarkDialog(singleRemarkFetchData.id, singleRemarkFetchData.content)}
                deleteContent={() => deleteRemarkConfirmationDialog(singleRemarkFetchData.id)}
                menuContentType={"remark"}

                isQuickShareDisabled={true}

                editLabel={"Remark"}
                isEditDisabled={false}

                deleteLabel={"Remark"}
            />
            <ConfirmationDialog
                isOpen={remarkDeleteState.isRemarkDeletionDialogOpen}
                close={() => setRemarkDeleteState({ isRemarkDeletionDialogOpen: false })}
                confirm={confirmDeletion}
                deleteState={remarkDeleteState}
                confirmationPageFor={"Delete Remark"}
            />
        </div>
    )
}

export default SingleRemarkFetch;