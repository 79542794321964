export const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9@/./+/-/_]+$/;
    if (!usernameRegex.test(username)) {
        return "Invalid characters in the username";
    } else {
        return "";
    }
};

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};