import React from "react";

class Sketch extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.points = [];
        this.stepsPerFrame = 5;
        this.state = {
            showCanvas: true,
        }
    }

    componentDidMount() {
        this.setupCanvas();
        this.reset();
        this.draw();
        setTimeout(() => {
            this.hideCanvas();
        }, 2000); // Hide the canvas after 3 seconds
    }

    setupCanvas() {
        const canvas = this.canvasRef.current;
        this.canvasWidth = window.innerWidth;
        this.canvasHeight = window.innerHeight;
        canvas.width = this.canvasWidth;
        canvas.height = this.canvasHeight;
        canvas.style.position = 'absolute';
        canvas.style.top = '0';
        canvas.style.left = '0';
        canvas.style.overflow = "hidden";
        canvas.style.zIndex = "99"
        canvas.style.width = '100%'; // Set the width to 100%
        canvas.style.height = '100%'; // Set the height to 100%
        // canvas.styyle.maxHeight = "100%"
        this.ctx = canvas.getContext('2d');
        this.ctx.strokeStyle = 'black';
        this.ctx.lineWidth = 3;
        this.ctx.fillStyle = 'white';
        this.ctx.position =
            this.ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
    }

    draw() {
        for (let i = 0; i < this.stepsPerFrame; i++) {
            this.ctx.strokeStyle = `rgba(0, 0, 0, ${1 - (i / this.stepsPerFrame)})`;
            this.drawLine();
        }

        requestAnimationFrame(this.draw.bind(this));
    }

    reset() {
        this.points = [];
        for (let i = 0; i < this.canvasWidth; i++) {
            this.points.push([i, 0]);
        }
    }

    drawLine() {
        this.ctx.beginPath();
        let atLeastOneOnScreen = false;

        this.points.forEach(p => {
            this.ctx.lineTo(p[0], p[1]);
            p[1] += Math.random() * 5;
            if (p[1] < this.canvasHeight * 1.1) {
                atLeastOneOnScreen = true;
            }
        });

        this.ctx.stroke();
        this.ctx.closePath();

        if (!atLeastOneOnScreen) {
            this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
            this.reset();
        }
    }

    hideCanvas() {
        this.setState({ showCanvas: false });
    }

    render() {
        return (
            <>
                {this.state.showCanvas && (
                    <canvas ref={this.canvasRef} />
                )}
            </>
        );
    }
}

export default Sketch;