import * as React from "react";
const Replied = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="4%"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="#49ACE9"
            opacity={1}
            stroke="none"
            d=" M359.006348,161.166382  C360.088196,161.445770 361.182373,162.008041 362.250031,161.961563  C375.535614,161.383133 388.660339,162.623428 401.686005,165.854126  C402.445587,168.117874 402.795654,170.173904 403.997925,171.381332  C437.529846,205.056793 471.135010,238.659378 504.801270,272.200562  C506.109161,273.503601 508.139923,274.081116 509.833832,274.996704  C510.691040,277.557465 511.548279,280.118225 512.702759,282.339478  C513.000000,295.354218 513.000000,308.708466 512.666321,322.454773  C510.786621,321.628723 509.104797,320.547455 507.716309,319.170441  C497.813232,309.349396 488.003723,299.433777 478.071167,289.642914  C476.593658,288.186493 474.626404,287.226898 472.552429,285.836304  C470.347656,279.466187 469.075348,273.033051 466.493042,267.177612  C458.613159,249.309769 447.638214,233.669479 431.562744,222.084564  C420.986176,214.462494 409.656860,208.316132 396.977386,204.484177  C395.769958,202.394287 394.826355,200.421478 393.374695,198.949814  C383.125275,188.559097 372.694550,178.346008 362.526947,167.876892  C360.839386,166.139267 360.154572,163.427811 359.006348,161.166382  z"
        />
        <path
            fill="#5163AB"
            opacity={1}
            stroke="none"
            d=" M143.005569,387.832947  C120.170120,371.622955 97.238358,355.546417 74.530022,339.160248  C56.735683,326.319977 39.020676,313.351624 21.661203,299.934540  C13.076873,293.299713 6.973062,284.422058 3.459172,273.964691  C3.061295,272.780579 2.299754,271.718658 1.353425,270.800049  C1.000000,262.645752 1.000000,254.291534 1.000000,245.468658  C1.187617,245.197281 1.375234,245.394547 2.187545,245.392792  C3.877482,245.965973 5.070416,246.610336 5.989631,247.528687  C50.751972,292.249268 95.506691,336.977570 140.162094,381.804749  C141.638885,383.287201 142.081100,385.800293 143.005569,387.832947  z"
        />
        <path
            fill="#4B9ADA"
            opacity={1}
            stroke="none"
            d=" M513.000000,402.541931  C512.357483,403.268494 511.517761,403.363220 511.103851,403.833069  C507.913940,407.454224 504.806580,411.148071 501.278778,414.631958  C499.444366,412.651489 498.157410,410.706146 496.542664,409.087921  C467.655212,380.138062 438.708679,351.247101 409.859344,322.259369  C408.204346,320.596405 407.272400,318.213898 406.395325,316.113647  C407.377899,316.317230 407.946533,316.666656 408.563568,316.815948  C423.153748,320.346008 437.078491,325.691498 449.384521,334.274811  C458.341339,340.522125 466.461395,347.969055 475.127991,355.173492  C486.733978,367.004913 498.149933,378.560577 509.631653,390.050507  C510.517548,390.937073 511.866333,391.361023 513.000000,392.000000  C513.000000,395.361298 513.000000,398.722565 513.000000,402.541931  z"
        />
        <path
            fill="#4A9EDD"
            opacity={1}
            stroke="none"
            d=" M513.000000,391.531342  C511.866333,391.361023 510.517548,390.937073 509.631653,390.050507  C498.149933,378.560577 486.733978,367.004913 475.148376,354.712799  C475.051910,343.304871 475.108521,332.649933 475.486847,322.080750  C477.337036,323.409149 478.983124,324.533234 480.374878,325.913727  C489.557709,335.022461 498.652954,344.219727 507.865143,353.298462  C509.350281,354.762085 511.277252,355.777435 513.000000,357.000000  C513.000000,368.354218 513.000000,379.708466 513.000000,391.531342  z"
        />
        <path
            fill="#4AA5E3"
            opacity={1}
            stroke="none"
            d=" M513.000000,356.531372  C511.277252,355.777435 509.350281,354.762085 507.865143,353.298462  C498.652954,344.219727 489.557709,335.022461 480.374878,325.913727  C478.983124,324.533234 477.337036,323.409149 475.418243,321.616882  C474.313843,309.390686 473.599823,297.714050 472.885773,286.037415  C474.626404,287.226898 476.593658,288.186493 478.071167,289.642914  C488.003723,299.433777 497.813232,309.349396 507.716309,319.170441  C509.104797,320.547455 510.786621,321.628723 512.666321,322.923462  C513.000000,334.020905 513.000000,345.041779 513.000000,356.531372  z"
        />
        <path
            fill="#5068AF"
            opacity={1}
            stroke="none"
            d=" M143.316132,387.992432  C142.081100,385.800293 141.638885,383.287201 140.162094,381.804749  C95.506691,336.977570 50.751972,292.249268 5.989631,247.528687  C5.070416,246.610336 3.877482,245.965973 2.489771,245.118164  C5.887303,237.426834 9.607301,229.811096 13.814129,222.334793  C15.639274,223.921387 16.946999,225.398331 18.321003,226.810806  C25.885687,234.587265 33.466557,242.347961 41.023830,250.526459  C38.526485,257.917450 40.192547,264.254028 45.553074,268.820343  C52.497299,274.735748 60.192726,279.766968 67.541214,285.210632  C83.701561,297.182007 99.847107,309.173340 116.026672,321.509552  C119.111580,325.483521 121.929230,329.342834 125.262497,332.688507  C158.186142,365.734528 191.212219,398.678497 224.130600,431.729767  C225.790955,433.396851 226.730011,435.782379 228.004898,437.833374  C219.672348,433.882996 210.940140,430.594452 203.085846,425.851990  C183.417297,413.975952 164.145630,401.442474 144.722458,389.160553  C144.309662,388.899536 143.989883,388.491425 143.316132,387.992432  z"
        />
        <path
            fill="#4A9EDD"
            opacity={1}
            stroke="none"
            d=" M198.950150,90.887016  C207.692520,86.250595 216.434891,81.614174 225.641815,77.265724  C226.841446,78.637070 227.425629,79.870888 228.333786,80.781693  C248.237000,100.743141 268.143921,120.701317 288.179657,140.529266  C289.542450,141.877930 291.924469,142.196747 293.832458,142.994537  C293.832458,148.434479 293.832458,153.874405 293.832458,159.482452  C300.192963,160.016205 306.166809,160.517487 312.656067,161.414551  C314.408295,163.334122 315.525787,164.976242 316.901062,166.362625  C326.094635,175.630234 335.396698,184.790970 344.520996,194.125702  C346.039398,195.679153 346.861359,197.913361 347.541321,199.906891  C333.719604,199.597275 320.361725,199.213776 306.975830,198.481445  C305.880554,196.503632 305.053101,194.636765 303.709259,193.282196  C289.049561,178.505310 274.325104,163.792450 259.561920,149.118866  C258.304901,147.869461 256.643219,147.027176 255.100067,145.536728  C255.679626,136.260040 254.455841,127.863319 248.871078,120.675484  C245.534866,116.381660 239.288925,111.954063 234.806122,114.995224  C232.009262,116.892647 228.967255,118.428680 225.957718,119.784851  C224.558395,117.767616 223.366943,115.966713 221.892975,114.438278  C214.285217,106.549438 206.605057,98.730408 198.950150,90.887016  z"
        />
        <path
            fill="#48B0ED"
            opacity={1}
            stroke="none"
            d=" M509.903320,274.600403  C508.139923,274.081116 506.109161,273.503601 504.801270,272.200562  C471.135010,238.659378 437.529846,205.056793 403.997925,171.381332  C402.795654,170.173904 402.445587,168.117874 401.898010,166.204147  C410.378235,168.630569 418.905121,170.728500 426.888916,174.108994  C444.384918,181.517136 459.646393,192.493378 472.645447,206.275864  C489.378571,224.017502 502.112122,244.224823 508.076691,268.220306  C508.580444,270.246796 509.334839,272.210968 509.903320,274.600403  z"
        />
        <path
            fill="#4AA5E3"
            opacity={1}
            stroke="none"
            d=" M348.005157,199.833008  C346.861359,197.913361 346.039398,195.679153 344.520996,194.125702  C335.396698,184.790970 326.094635,175.630234 316.901062,166.362625  C315.525787,164.976242 314.408295,163.334122 313.088348,161.488464  C328.029694,161.116776 343.054199,161.066956 358.542542,161.091766  C360.154572,163.427811 360.839386,166.139267 362.526947,167.876892  C372.694550,178.346008 383.125275,188.559097 393.374695,198.949814  C394.826355,200.421478 395.769958,202.394287 396.592468,204.425903  C389.905151,203.478455 383.622467,201.701111 377.231750,201.127762  C367.528931,200.257248 357.751129,200.222672 348.005157,199.833008  z"
        />
        <path
            fill="#4AA5E3"
            opacity={1}
            stroke="none"
            d=" M293.901093,142.535034  C291.924469,142.196747 289.542450,141.877930 288.179657,140.529266  C268.143921,120.701317 248.237000,100.743141 228.333786,80.781693  C227.425629,79.870888 226.841446,78.637070 226.024460,77.211884  C240.567108,72.025833 254.029831,75.878639 266.068726,83.883064  C278.443359,92.110687 287.464996,103.662209 290.945618,118.442169  C292.756287,126.130943 293.011749,134.185959 293.901093,142.535034  z"
        />
        <path
            fill="#4E7DC1"
            opacity={1}
            stroke="none"
            d=" M63.889465,182.961090  C72.815956,176.280899 81.742447,169.600723 91.339050,162.908051  C101.350426,171.969620 110.691689,181.043686 119.872040,190.417999  C118.893349,191.225693 118.046951,191.692642 117.262177,192.246841  C108.871948,198.171890 100.491470,204.110748 91.796982,209.895569  C90.259117,208.923920 88.855568,208.278793 87.834152,207.253113  C79.811333,199.196854 71.861671,191.067749 63.889465,182.961090  z"
        />
        <path
            fill="#4F74B9"
            opacity={1}
            stroke="none"
            d=" M63.576378,183.097687  C71.861671,191.067749 79.811333,199.196854 87.834152,207.253113  C88.855568,208.278793 90.259117,208.923920 91.651886,210.207748  C81.877121,218.061768 71.936096,225.453400 61.968750,232.487747  C61.298725,230.628738 61.022694,228.762375 59.952656,227.683472  C52.020782,219.685852 43.931034,211.844818 35.890884,203.954605  C43.172226,198.338867 50.441559,192.707443 57.743492,187.118591  C59.528633,185.752228 61.420494,184.525330 63.576378,183.097687  z"
        />
        <path
            fill="#4B95D6"
            opacity={1}
            stroke="none"
            d=" M198.595413,90.949219  C206.605057,98.730408 214.285217,106.549438 221.892975,114.438278  C223.366943,115.966713 224.558395,117.767616 225.793701,120.095322  C216.483505,126.209442 207.259781,131.671066 197.958069,136.790436  C196.558563,134.772339 195.367737,132.970444 193.893585,131.441742  C186.284546,123.551285 178.602936,115.730827 170.946640,107.885941  C180.044647,102.261101 189.142670,96.636253 198.595413,90.949219  z"
        />
        <path
            fill="#4D86C8"
            opacity={1}
            stroke="none"
            d=" M120.032951,190.117767  C110.691689,181.043686 101.350426,171.969620 91.622360,162.751816  C100.075874,156.344238 108.916199,150.080383 118.337341,143.849777  C126.808769,151.618301 134.732544,159.320297 142.558456,167.120483  C143.917908,168.475479 144.863831,170.245377 145.689087,171.981934  C136.929260,178.132736 128.481110,184.125259 120.032951,190.117767  z"
        />
        <path
            fill="#4C8ECF"
            opacity={1}
            stroke="none"
            d=" M170.628250,108.029572  C178.602936,115.730827 186.284546,123.551285 193.893585,131.441742  C195.367737,132.970444 196.558563,134.772339 197.793549,137.096573  C196.292007,138.509262 194.831879,139.201157 193.469559,140.050034  C186.123398,144.627518 178.801514,149.243988 171.280029,153.503662  C163.656815,145.538254 156.191422,137.948257 148.821198,130.266937  C147.361267,128.745377 146.266830,126.873131 145.003845,125.162613  C153.439178,119.499474 161.874527,113.836334 170.628250,108.029572  z"
        />
        <path
            fill="#4D8ACC"
            opacity={1}
            stroke="none"
            d=" M144.662567,125.197906  C146.266830,126.873131 147.361267,128.745377 148.821198,130.266937  C156.191422,137.948257 163.656815,145.538254 171.085464,153.760880  C162.721664,160.182175 154.361221,166.002899 146.000763,171.823639  C144.863831,170.245377 143.917908,168.475479 142.558456,167.120483  C134.732544,159.320297 126.808769,151.618301 118.597122,143.635300  C126.957825,137.336105 135.639557,131.284653 144.662567,125.197906  z"
        />
        <path
            fill="#506DB4"
            opacity={1}
            stroke="none"
            d=" M35.538200,203.974030  C43.931034,211.844818 52.020782,219.685852 59.952656,227.683472  C61.022694,228.762375 61.298725,230.628738 61.661789,232.643372  C54.601391,238.808868 47.821636,244.461472 41.041882,250.114075  C33.466557,242.347961 25.885687,234.587265 18.321003,226.810806  C16.946999,225.398331 15.639274,223.921387 14.125551,222.183167  C21.028601,215.925888 28.107058,209.959671 35.538200,203.974030  z"
        />
        <path
            fill="#4B95D6"
            opacity={1}
            stroke="none"
            d=" M406.003937,316.166290  C407.272400,318.213898 408.204346,320.596405 409.859344,322.259369  C438.708679,351.247101 467.655212,380.138062 496.542664,409.087921  C498.157410,410.706146 499.444366,412.651489 500.961914,414.787048  C489.487335,422.187561 477.033112,421.729553 467.046082,413.305328  C464.427368,411.096375 462.586975,407.724548 460.991516,404.581055  C449.168518,381.286835 431.130493,365.169830 406.346191,356.719147  C404.508148,356.092438 402.637299,355.561859 400.365509,354.561218  C398.769562,352.394043 397.824921,350.420288 396.372284,348.947662  C386.116760,338.550812 375.732147,328.281403 365.460205,317.900635  C364.101624,316.527649 363.147705,314.754303 362.470459,313.099731  C377.383026,312.812744 391.827911,312.641724 406.003937,316.166290  z"
        />
        <path
            fill="#506DB4"
            opacity={1}
            stroke="none"
            d=" M228.428452,437.900513  C226.730011,435.782379 225.790955,433.396851 224.130600,431.729767  C191.212219,398.678497 158.186142,365.734528 125.262497,332.688507  C121.929230,329.342834 119.111580,325.483521 116.362999,321.556519  C139.117798,336.833832 161.346817,352.743561 184.083984,367.890533  C200.382294,378.748169 217.360565,388.585083 234.099060,399.220215  C235.465393,401.232391 236.628998,403.036560 238.093857,404.548950  C246.705856,413.440186 255.388107,422.263428 264.046692,431.109589  C253.238129,438.132355 241.569962,440.743683 228.428452,437.900513  z"
        />
        <path
            fill="#4E7DC1"
            opacity={1}
            stroke="none"
            d=" M294.105164,354.050476  C294.070587,361.352722 293.996735,368.655090 294.011261,375.957214  C294.029633,385.220551 292.314087,394.127075 288.124115,402.685059  C278.923309,394.208466 270.385986,385.651825 261.673859,377.277008  C259.832886,375.507385 257.352203,374.403229 255.102509,372.528137  C255.024658,359.966675 255.168060,347.866394 254.926941,335.773804  C254.814240,330.122284 256.108307,325.195129 260.223206,321.045288  C261.527863,321.737915 262.644836,322.200623 263.430695,322.992126  C273.682831,333.317749 283.888092,343.689972 294.105164,354.050476  z"
        />
        <path
            fill="#4C8ECF"
            opacity={1}
            stroke="none"
            d=" M362.006378,313.166412  C363.147705,314.754303 364.101624,316.527649 365.460205,317.900635  C375.732147,328.281403 386.116760,338.550812 396.372284,348.947662  C397.824921,350.420288 398.769562,352.394043 399.978333,354.484711  C384.923004,351.421509 369.721649,351.070099 354.405518,352.915344  C351.615112,353.251526 348.749603,352.964355 345.385132,352.565735  C343.560150,350.270386 342.509308,348.140961 340.935455,346.518982  C334.456390,339.841766 327.738892,333.395752 321.268677,326.710236  C317.363434,322.675018 313.751526,318.355835 310.471924,314.099213  C327.959503,313.744904 344.982941,313.455658 362.006378,313.166412  z"
        />
        <path
            fill="#4D86C8"
            opacity={1}
            stroke="none"
            d=" M310.007812,314.164276  C313.751526,318.355835 317.363434,322.675018 321.268677,326.710236  C327.738892,333.395752 334.456390,339.841766 340.935455,346.518982  C342.509308,348.140961 343.560150,350.270386 344.929321,352.500031  C328.329315,353.222748 311.651489,353.611938 294.539398,354.025787  C283.888092,343.689972 273.682831,333.317749 263.430695,322.992126  C262.644836,322.200623 261.527863,321.737915 260.382080,320.729248  C264.862549,316.248444 270.228790,314.717743 276.464294,314.916748  C286.259918,315.229462 296.073669,314.976593 306.075073,314.847687  C306.530029,314.573334 306.764893,314.383820 307.350739,314.091553  C308.486908,314.067719 309.247375,314.115997 310.007812,314.164276  z"
        />
        <path
            fill="#4F74B9"
            opacity={1}
            stroke="none"
            d=" M255.166260,372.992462  C257.352203,374.403229 259.832886,375.507385 261.673859,377.277008  C270.385986,385.651825 278.923309,394.208466 287.813416,402.876343  C283.572449,415.071106 275.302765,423.933929 264.360596,430.957642  C255.388107,422.263428 246.705856,413.440186 238.093857,404.548950  C236.628998,403.036560 235.465393,401.232391 234.483154,399.288269  C239.570526,400.693237 245.450455,398.251190 248.980774,393.218536  C253.245575,387.138885 255.472183,380.458679 255.166260,372.992462  z"
        />
        <path
            fill="#4B9ADA"
            opacity={1}
            stroke="none"
            d=" M306.975830,198.481445  C295.358185,198.883194 283.710571,199.088562 272.067474,198.938522  C263.478424,198.827850 255.087112,190.035355 255.029953,181.372681  C254.954178,169.890198 255.019455,158.406769 255.096832,146.459747  C256.643219,147.027176 258.304901,147.869461 259.561920,149.118866  C274.325104,163.792450 289.049561,178.505310 303.709259,193.282196  C305.053101,194.636765 305.880554,196.503632 306.975830,198.481445  z"
        />
        <path
            fill="#4C8ECF"
            opacity={1}
            stroke="none"
            d=" M306.075073,314.847687  C306.072083,314.685913 306.264435,314.408600 306.715881,314.147461  C306.764893,314.383820 306.530029,314.573334 306.075073,314.847687  z"
        />
    </svg>
);
export default Replied;
